import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'
// import formatdate from '../../../uioscar/formatdate'


// pagina apeleaza pentru detaliere managementOperation
export default ({params, events}) => {
    const {dbkey, toggleHelp, selyearmng: selyear, selmonthmng: selmonth, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    // console.log(`[raportIncasariDetails.component] - {selyear, selmonth}: ${JSON.stringify({selyear, selmonth})}`)
    const variables = { pRefresh: refresh, 
        pMonth:  parseInt(selmonth.selmonthid), pYear: parseInt(selyear),
        // condition: { year: parseInt(selyear), month: parseInt(selmonth.selmonthid), //level: 0,
        // // condition: { year: parseInt(selyear), month: parseInt(selmonth.selmonthid), //level: 0,
        // }, 
        conditionyear:{year: parseInt(selyear)} 
    }
    // console.log(`[raportIncasariDetails.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[raportIncasariDetails.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[raportIncasariDetails.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh, }} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
