import * as pdfstyles from '../../../../uioscar/pdf/pdflib/pdfstyles'

export default () => {   

    const headertableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }
    const width = 2.5

    const bodytableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }
    return [
        {
            code: {field: 'code', values: ['Cod'], width: 8.5, isLastRow : false, ...pdfstyles.styleLeft({headertableCell, bodytableCell, }), },
            collection: {field: 'collection', values: ['Coll'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            percent: {field: 'percent', values: ['%'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            // nbr: {field: 'nbr', values: ['nbr', 'nbr', 'nbr'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi01: {field: 'zi01', values: ['1'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi02: {field: 'zi02', values: ['2'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi03: {field: 'zi03', values: ['3'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi04: {field: 'zi04', values: ['4'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi05: {field: 'zi05', values: ['5'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi06: {field: 'zi06', values: ['6'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi07: {field: 'zi07', values: ['7'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi08: {field: 'zi08', values: ['8'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi09: {field: 'zi09', values: ['9'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi10: {field: 'zi10', values: ['10'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },

            zi11: {field: 'zi11', values: ['11'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi12: {field: 'zi12', values: ['12'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi13: {field: 'zi13', values: ['13'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi14: {field: 'zi14', values: ['14'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi15: {field: 'zi15', values: ['15'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi16: {field: 'zi16', values: ['16'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi17: {field: 'zi17', values: ['17'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi18: {field: 'zi18', values: ['18'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi19: {field: 'zi19', values: ['19'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi20: {field: 'zi20', values: ['20'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },

            zi21: {field: 'zi21', values: ['21'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi22: {field: 'zi22', values: ['22'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi23: {field: 'zi23', values: ['23'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi24: {field: 'zi24', values: ['24'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi25: {field: 'zi25', values: ['25'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi26: {field: 'zi26', values: ['26'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi27: {field: 'zi27', values: ['27'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi28: {field: 'zi28', values: ['28'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi29: {field: 'zi29', values: ['29'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi30: {field: 'zi30', values: ['30'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            zi31: {field: 'zi31', values: ['31'], width: width, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },

            total: {field: 'total', values: ['Total'], width:4.5, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            totalcode: {field: 'totalcode', values: ['T. code'], width:4.5, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
        }, 
    ]
}

/*
month
year
monthname
code
symbol
collection
percent
nbr
collectionid
*/
