import enummapps from '../enums/mapps'

  export default (appid, apps, menuapps=[]) => {
    const _menuapp = apps !== undefined ? appid === enummapps.mhappyworld.key ? 
      apps.map(edge => ({app: edge.app, link: edge.link}))
      : menuapps.map(item =>({app: item, link: ''}))
      : []
    // console.log(`[menuappOscars] - aa: ${aa}, appid: ${appid}, apps: ${JSON.stringify(apps)}, apps.length: ${apps !== undefined ? apps.length : 0}`)
    // console.log(`[menuappOscars] - aa: ${aa}, appid: ${appid}, typeof(apps):  ${typeof(apps)}, apps: ${JSON.stringify(apps)}, _menuapp: ${JSON.stringify(_menuapp)}, _menuapp.length: ${_menuapp !== undefined ? _menuapp.length : 0}`)
    return [..._menuapp]
  }
