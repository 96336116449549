import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeltherapisttherapypriceInput!)
{
  fnDeltherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
`
