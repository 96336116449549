export default [
    {
        data: {field: 'data', values: ['', '', 'dt'], width: 3, isLastRow : false},
        symbolroom: {field: 'symbolroom', values: ['', '', 'sl'], width: 3, isLastRow : false},
        h0800: {field: 'h0800', values: ['', '8', '-'], width: 1.88, isLastRow : false},
        h0815: {field: 'h0815', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h0830: {field: 'h0830', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h0845: {field: 'h0845', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h0900: {field: 'h0900', values: ['', '9', '-'], width: 1.88, isLastRow : false},
        h0915: {field: 'h0915', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h0930: {field: 'h0930', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h0945: {field: 'h0945', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1000: {field: 'h1000', values: ['', '10', '-'], width: 1.88, isLastRow : false},
        h1015: {field: 'h1015', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1030: {field: 'h1030', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1045: {field: 'h1045', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1100: {field: 'h1100', values: ['', '11', '-'], width: 1.88, isLastRow : false},
        h1115: {field: 'h1115', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1130: {field: 'h1130', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1145: {field: 'h1145', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1200: {field: 'h1200', values: ['', '12', '-'], width: 1.88, isLastRow : false},
        h1215: {field: 'h1215', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1230: {field: 'h1230', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1245: {field: 'h1245', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1300: {field: 'h1300', values: ['', '13', '-'], width: 1.88, isLastRow : false},
        h1315: {field: 'h1315', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1330: {field: 'h1330', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1345: {field: 'h1345', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1400: {field: 'h1400', values: ['', '14', '-'], width: 1.88, isLastRow : false},
        h1415: {field: 'h1415', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1430: {field: 'h1430', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1445: {field: 'h1445', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1500: {field: 'h1500', values: ['', '15', '-'], width: 1.88, isLastRow : false},
        h1515: {field: 'h1515', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1530: {field: 'h1530', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1545: {field: 'h1545', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1600: {field: 'h1600', values: ['', '16', '-'], width: 1.88, isLastRow : false},
        h1615: {field: 'h1615', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1630: {field: 'h1630', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1645: {field: 'h1645', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1700: {field: 'h1700', values: ['', '17', '-'], width: 1.88, isLastRow : false},
        h1715: {field: 'h1715', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1730: {field: 'h1730', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1745: {field: 'h1745', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1800: {field: 'h1800', values: ['', '18', '-'], width: 1.88, isLastRow : false},
        h1815: {field: 'h1815', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1830: {field: 'h1830', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1845: {field: 'h1845', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h1900: {field: 'h1900', values: ['', '19', '-'], width: 1.88, isLastRow : false},
        h1915: {field: 'h1915', values: ['', '', '¼'], width: 1.88, isLastRow : false},
        h1930: {field: 'h1930', values: ['', '', '½'], width: 1.88, isLastRow : false},
        h1945: {field: 'h1945', values: ['', '', '¾'], width: 1.88, isLastRow : false},
        h2000: {field: 'h2000', values: ['', '20', '-'], width: 1.88, isLastRow : false},
        h2015: {field: 'h2015', values: ['', '', '¼'], width: 1.88, isLastRow : false},
    }, 
]

