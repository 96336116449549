import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import enumtherapistreport from '../../../enums/therapistreport'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyear, selmonth, setreportid, } = params
  
  // const _perioadatherapistreport = params.perioadatherapistreport.key
  // console.log(`[raportTherapistsessions.page] - _perioadatherapistreport: ${JSON.stringify(_perioadatherapistreport)}` )
  const _dbvdata = dbdata.vrtherapistsessions === undefined || dbdata.vrtherapistsessions.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: selmonth.monthname, selmonthid: '',  }]
    : dbdata.vrtherapistsessions.edges
    .filter(item => 
      params.perioadatherapistreport.key === enumtherapistreport.perioada_1_15.key ? new Date(item.node.date).getDate() < enumtherapistreport.perioada_16_30.key
      : params.perioadatherapistreport.key === enumtherapistreport.perioada_16_30.key? new Date(item.node.date).getDate() >= params.perioadatherapistreport.key
      : true
      ) 
    .map((edge, index) => {
      return{
        ...edge.node, //date: edge.node.nameoperationcode === 'Sold final' ? edge.node.date : '',
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? selmonth.monthname : '', selmonthid: '',
        therapistreport: index === 0 ? params.perioadatherapistreport.value : '', therapistreportid: params.perioadatherapistreport.key,
        ora: `${edge.node.sfrom} - ${edge.node.sto}`,
        }
      })
  const reducer = (accumulator, currentValue) => accumulator + currentValue
  // const reducer = (accumulator, currentValue) => {
  // console.log(`[raportTherapistsessions.page.reducer] - accumulator: ${accumulator}, currentValue: ${currentValue}`)
  //   return accumulator + currentValue //!Number.isNaN(currentValue) ? currentValue : 0
  // }
  const total = [{ selyear: '', selyearid: '', selmonth: '', selmonthid: '',
    date: null, patient: null, nametherapyprice : 'Total',
    sfrom: null, sto: null, 
    percent: null, ora: null,
    value: _dbvdata.map(item => item.value??0).reduce(reducer, 0), 
    namerocollection: null, 
    therapistvalue: _dbvdata.map(item => parseFloat(item.therapistvalue??0)).reduce(reducer, 0),
  }]
  // console.log(`[raportTherapistsessions.page] - _dbvdata: `, _dbvdata )
  // console.log(`[raportTherapistsessions.page] - total: ${JSON.stringify(total)}` )
  const dbvdata = [..._dbvdata, ...total]
  // console.log(`[raportTherapistsessions.page] - dbvdata: `, dbvdata )

  const dbtherapist = dbdata.vtherapists === undefined || dbdata.vtherapists.edges.length === 0 ? ''
    : `${dbdata.vtherapists.edges[0].node.firstname} ${dbdata.vtherapists.edges[0].node.lastname}`
  // console.log(`[raportTherapistsessions.page] - dbtherapist: ${JSON.stringify(dbtherapist)}` )

  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[raportTherapistsessions.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[raportTherapistsessions.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  // const dbOptions2 = [
  //   ...dbdata.vmonthkindofs.edges.map(edge => {
  //     const { monthkindofid, name, } = edge.node
  //     return {id: 'monthkindofid', monthkindofid: monthkindofid, namemonthkindof: name}
  // })
  // ]
  // console.log(`[raportTherapistsessions.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  const dbOptions3 = [
      {id: 'therapistreportid', therapistreportid: enumtherapistreport.perioada_1_15.key, therapistreport: enumtherapistreport.perioada_1_15.value},
      {id: 'therapistreportid', therapistreportid: enumtherapistreport.perioada_16_30.key, therapistreport: enumtherapistreport.perioada_16_30.value},
      {id: 'therapistreportid', therapistreportid: enumtherapistreport.lunar.key, therapistreport: enumtherapistreport.lunar.value},
    ]
  // console.log(`[raportTherapistsessions.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions3, }} params = {{...params, titles, dbtherapist, setreportid}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VrtherapistsessionCondition, defaultValue: {}, }
        conditiontherapist: {type: VtherapistCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
    ) 
    {
      vrtherapistsessions(condition: $condition) {
        edges {
          node {
            month
            monthname
            year
            patient
            nametherapyprice
            date
            sfrom
            sto
            percent
            namerocollection
            value
            therapistvalue
            therapistid
          }
        }
      }
      vtherapists(condition: $conditiontherapist, orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            # extensive
            # symbol
            # code
            # phone
            # email
            # cnp
            # percent
            # calendarcss
          }
        }
      } 
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      # vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
      #   edges {
      #     node {
      #       monthkindofid
      #       name
      #       # symbol
      #     }
      #   }
      # }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

