
export default {
    AddDialogtitle: ['Adauga terapii', ''],
    columns: [
      {header: 'nameRo', accessor: 'nameRo', type: 'text', show: 'true', },
      {header: 'nameEn', accessor: 'nameEn', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'shortsymbol', accessor: 'shortsymbol', type: 'text', show: 'true', },
      {header: 'description', accessor: 'description', type: 'text', show: 'true', },
      {header: 'norder', accessor: 'norder', type: 'number', show: 'true', },
    ],
    initialdata: {
      nameRo: '',
      nameEn: '',
      symbol: '',
      shortsymbol: '',
      description: '',
      norder: '',
      subRows: undefined,
    },    
  }

