import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdpatientInput!)
{
  fnUpdpatient(input: $input) {
    patient {
      birthyear
      cnp
      email
      extensive
      firstname
      height
      lastname
      patientid
      phone
      weight
    }
  }
}
`

