// import React from 'react'

export default ({dbOptions}) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'operationcodes',
      columns: [
        { id: 'name', Header: 'name', accessor: 'name', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 30, type: 'text',},
        { id: 'nameoperationkindof', Header: 'nameoperationkindof', accessor: 'nameoperationkindof', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege fel operatiune ..'},},
        // { id: 'nameoperationkindof', Header: 'nameoperationkindof', accessor: 'nameoperationkindof', width: 30, type: 'text',},
        ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'norderoperationkindof', Header: 'norderoperationkindof', accessor: 'norderoperationkindof', width: 30, type: 'text',},
    { id: 'operationcodeid', Header: 'operationcodeid', accessor: 'operationcodeid', width: 30,  },
    { id: 'operationkindofid', Header: 'operationkindofid', accessor: 'operationkindofid', width: 30,  },
  ],
},
])
