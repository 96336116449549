import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddappmenuInput!)
{
  fnAddappmenu(input: $input) {
    appmenu {
      appid
      appmenuid
      denumire
      ordonare
      simbol
    }
  }
}
`
