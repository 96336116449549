/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpsextrascontcodificareInput = {|
  clientMutationId?: ?string,
  pExtrascontcodificareid: number,
  pKey: string,
  pOperationcodeid: number,
  pCollectionid: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpsextrascontcodificareInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpsextrascontcodificare: ?{|
    +extrascontcodificare: ?{|
      +collectionid: number,
      +extrascontcodificareid: any,
      +key: string,
      +operationcodeid: ?number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpsextrascontcodificareInput!
) {
  fnUpsextrascontcodificare(input: $input) {
    extrascontcodificare {
      collectionid
      extrascontcodificareid
      key
      operationcodeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpsextrascontcodificarePayload",
    "kind": "LinkedField",
    "name": "fnUpsextrascontcodificare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Extrascontcodificare",
        "kind": "LinkedField",
        "name": "extrascontcodificare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extrascontcodificareid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "efd75354c884238ea64d0b6b3a50c2ef",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpsextrascontcodificareInput!\n) {\n  fnUpsextrascontcodificare(input: $input) {\n    extrascontcodificare {\n      collectionid\n      extrascontcodificareid\n      key\n      operationcodeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a5224d631b250bb4889ec740c5bab51';

module.exports = node;
