/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDeltherapisttherapypriceInput = {|
  clientMutationId?: ?string,
  pTherapisttherapypriceid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDeltherapisttherapypriceInput
|};
export type mutationDelMutationResponse = {|
  +fnDeltherapisttherapyprice: ?{|
    +therapisttherapyprice: ?{|
      +percent: any,
      +therapistid: number,
      +therapisttherapypriceid: any,
      +therapypriceid: number,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDeltherapisttherapypriceInput!
) {
  fnDeltherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDeltherapisttherapypricePayload",
    "kind": "LinkedField",
    "name": "fnDeltherapisttherapyprice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapisttherapyprice",
        "kind": "LinkedField",
        "name": "therapisttherapyprice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapisttherapypriceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapypriceid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ce671ad47da68ab7322d27205768f20",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDeltherapisttherapypriceInput!\n) {\n  fnDeltherapisttherapyprice(input: $input) {\n    therapisttherapyprice {\n      percent\n      therapistid\n      therapisttherapypriceid\n      therapypriceid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88107e72c931999d26807d389bafbeea';

module.exports = node;
