import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapistInput!)
{
  fnUpstherapist(input: $input) {
    therapist {
      cnp
      code
      email
      extensive
      firstname
      lastname
      percent
      phone
      symbol
      therapistid
      activ
      calendarcss
    }
  }
}
`
 