import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare fel incasare terapeui, campurile atribut fiind: <b>therapistpatientId, therapistId, patientId</b> </li>
            <li>pentru campurile <u>therapistId, patientId</u> exista unicitate in tabela</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga pacient terapeut nou [<b>+</b>], actualizare oricare dintre campurile <i>therapistId, patientId</i>, sterge fel operatiune sedinte si refresh date </li>
                <li><b>stergerea</b> este posibila doar daca pacient terapeut nu este utilizat</li>
                <li> pentru campurile <b>therapistId, patientId</b> se foloseste autocomplete care se populeaza fiecare cu o lista de valori preluata din views. </li>
            </ul>
            <li>este posibil export in fisier xls <b>Therapistpatients.xls</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.dbTherapistpatients</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.therapistpatients</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                        </tr>
                        <tr>
                            <td className='td'>therapistpatientId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapistId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                        </tr>
                        <tr>
                            <td className='td'>patientId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapistpatients(orderBy: THERAPISTPATIENTID_ASC, condition: $condition) (therapistpatientid, <b>therapistid</b>, firstname, lastname, extensive, symbol, code, phone, email, cnp, percenttherapists, <b>patientid</b>, firstnamepatient, lastnamepatient, extensivepatient, birthyearpatient, weightpatient, heightpatient, phonepatient, emailpatient, cnppatient)</td>
                            <td className='td'>, unde &#x0007B; condition: therapistId !== undefined ? &#x0007B; therapistId: parseInt(therapistId) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapists(orderBy: THERAPISTID_ASC) (therapistid, firstname, lastname, extensive, symbol, code)</td>
                            <td className='td'>lista de valori [therapistId]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vpatients(orderBy: PATIENTID_ASC) (patientid, firstname, lastname, extensive)</td>
                            <td className='td'>lista de valori </td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_addtherapistpatient, (p_therapistId, p_patientId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_updtherapistpatient(p_keyfield, p_value, p_therapistpatientId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_deltherapistpatient(p_therapistpatientId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_upstherapistpatient (p_patientId, p_therapistId, p_patientId)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


