import React from 'react'
import { Link } from 'react-router-dom' 
import enumAuth from '../../../../enums/authenticated'
import enummapps from '../../../../enums/mapps'

import Icon from '@mdi/react'
import { mdiMagnify, } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/

export default ({params, events}) => {
    const {appid, } = params
    const { utilizatorOscar: { utilizatormodelat: { isauthenticated, }}, } = params

    // console.log(`[header.searchicon.component] - isauthenticated: ${isauthenticated}`)
    const visible = appid !== enummapps.mhappyworld.key &&
        (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
    const rotating = false 
    
    return (
        <>
            {visible ? 
                <>
                    <Link to='/search' title='search'>
                        {!rotating ? <Icon path={mdiMagnify} title="search" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiMagnify} title="search" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 
                </>
                : <> </>
            } 
        </> 
    )
}