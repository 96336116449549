import React from 'react'
import enummapps from '../../../enums/mapps'
import enumRols from '../../../enums/rols'

export default ({params: {appid, utilizatorOscar, utilizatorOscar: {utilizatormodelat: { rols, }}}}) => {
    const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
    // console.log(`[home.help] - isadminApp: ${isadminApp}, utilizatorOscar: ${JSON.stringify(utilizatorOscar)}, rols: ${JSON.stringify(rols)}`)
    return (
        <>
            {appid === enummapps.mhappyworld.key ?
                !isadminApp ? 
                <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                    <ul>
                        <li>home amos page</li>
                        <li>baza de date are dupa instantiere un singur utilizator amos cu drepturi adminApp in toate microapplicatiile</li>
                        <ul>
                            <li>acorda drepturi microapplicatii</li>
                            <li>export : »xls</li>
                            <li>microapplicatie: import utilizatori in restul microapplicatiilor</li>
                            <li>obs: pentru toate microapplicatiile exista posibilitate adminisitare utilizatori si drepturi care insa nu vor putea fi importati in restul microapplicatiilor</li>
                        </ul>
                        <li>click link  <b>help</b> pentru a ascunde help text </li>
                    </ul>
                </div>
                : 
                <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                    <ul>
                        <li>home amos page</li>
                        <li>click link  <b>help</b> pentru a ascunde help text </li>
                    </ul>
                </div>
            : appid === enummapps.utilizatoriapp.key ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>home utilizatori page</li>
                    <li>baza de date dupa instantiere contine un singur utilizator amos cu drepturi adminApp in toate microapplicatiile, restul utilizatorilor vor trebui adugati fie prin import, schimb date fie manual</li>
                        <ul>
                            <li>acorda drepturi microapplicatii</li>
                            <li>export : »xls</li>
                            <li>microapplicatie: import utilizatori in restul microapplicatiilor</li>
                            <li>obs: pentru toate microapplicatiile exista posibilitate adminsitare utilizatori si drepturi care insa nu vor putea fi importati in restul microapplicatiilor</li>
                        </ul>
                    <li>click link  <b>help</b> pentru a ascunde help text </li>
                </ul>
            </div>
            : <></>
    }
        </>
    )
}