
export default [
    {
        operationcodeid: {field: 'operationcodeid', values: ['', '', 'operationcodeid'], width: 10, isLastRow : false},
        operationkindofid: {field: 'operationkindofid', values: ['', '', 'operationkindofid'], width: 10, isLastRow : false},
        name: {field: 'name', values: ['', '', 'name'], width: 20, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        code: {field: 'code', values: ['', '', 'code'], width: 10, isLastRow : false},
        norder: {field: 'norder', values: ['', '', 'norder'], width: 10, isLastRow : false},
        nameoperationkindof: {field: 'nameoperationkindof', values: ['', '', 'nameoperationkindof'], width: 10, isLastRow : false},
        operationkindofsymbol: {field: 'operationkindofsymbol', values: ['', '', 'operationkindofsymbol'], width: 10, isLastRow : false},
        norderoperationkindof: {field: 'norderoperationkindof', values: ['', '', 'norderoperationkindof'], width: 10, isLastRow : false},
    }, 
]
