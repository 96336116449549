import React from 'react'

const expandedcolumn = 
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    }

const columns = (dbOptions) => [  
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'operationkindofs',
      columns: [
        { id: 'name', Header: 'name', accessor: 'name', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 30, type: 'text',},
        { id: 'namekindof', Header: 'namekindof', accessor: 'namekindof', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '20.3rem', text: 'Alege fel operatiune ..'},},
        // { id: 'symbolkindof', Header: 'symbolkindof', accessor: 'symbolkindof', width: 30, type: 'text',},
      ]
    },
    {
    Header: 'db info', columns: [     
      { id: 'operationkindofid', Header: 'operationkindofid', accessor: 'operationkindofid', width: 30,  },
      { id: 'kindofid', Header: 'kindofid', accessor: 'kindofid', width: 30, type: 'text',},
  ],
},
]

export default ({isresponsabilmanagement, dbOptions}) => isresponsabilmanagement ? [ expandedcolumn, ...columns(dbOptions)] : columns(dbOptions)
