// import React from 'react'

export default ({dbOptions, dbOptions1, }) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'codificare extras cont',
      columns: [
        { id: 'key', Header: 'key word', accessor: 'key', width: 30, type: 'text',},
        { id: 'codeoperationcode', Header: 'cod', accessor: 'codeoperationcode', width: 30, type: 'text',},
        { id: 'nameoperationcode', Header: 'cod operatie', accessor: 'nameoperationcode', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '24.3rem', text: 'Alege operation code ..'},},
        // { id: 'symbolcollection', Header: 'fel cont', accessor: 'symbolcollection', width: 30, type: '',
        //   autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege fel cont ..'},},
        { id: 'nameRocollection', Header: 'fel cont', accessor: 'nameRocollection', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege fel cont ..'},},
        ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'extrascontcodificareid', Header: 'extrascontcodificareid', accessor: 'extrascontcodificareid', width: 30, align: 'right',  },
    { id: 'operationcodeid', Header: 'operationcodeid', accessor: 'operationcodeid', width: 30, type: 'text', align: 'right',},
    { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'text', align: 'right',},
  ],
},
])


// symboloperationcode
// 
// norderoperationcode
// 
// keyRocollection
// keyEncollection
// 