/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vextrasconts: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +extrascontid: ?any,
        +date: ?any,
        +procesat: ?boolean,
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
        +operatiune: ?string,
        +norder: ?number,
        +debit: ?number,
        +credit: ?number,
        +beneficiar: ?string,
        +ordonator: ?string,
        +contul: ?string,
        +banca: ?string,
        +detalii: ?string,
        +referinta: ?string,
        +detalii2: ?string,
        +operationcodeid: ?number,
        +nameoperationcode: ?string,
        +symboloperationcode: ?string,
        +codeoperationcode: ?string,
        +norderoperationcode: ?number,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +symboloperationkindof: ?string,
        +norderoperationkindof: ?number,
        +kindofid: ?number,
        +namekindofs: ?string,
        +symbolkindofs: ?string,
        +collectionid: ?number,
        +nameRocollection: ?string,
        +nameEncollection: ?string,
        +symbolcollection: ?string,
        +mngmentoperationdetailid: ?number,
        +ndocument: ?string,
        +description: ?string,
        +value: ?any,
      |}
    |}>
  |},
  +vmngmentoperationyears: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any
      |}
    |}>
  |},
  +vmngmentoperationmonths: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
      |}
    |}>
  |},
  +voperationcodes: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationcodeid: ?any,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +operationkindofsymbol: ?string,
        +norderoperationkindof: ?number,
        +name: ?string,
        +symbol: ?string,
        +code: ?string,
        +norder: ?number,
      |}
    |}>
  |},
  +vcollections: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +collectionid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortmonthname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norder",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationkindofid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationkindof",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norderoperationkindof",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionyear"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "condition"
        }
      ],
      "concreteType": "VextrascontsConnection",
      "kind": "LinkedField",
      "name": "vextrasconts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VextrascontsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vextrascont",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "extrascontid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "procesat",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operatiune",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "debit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "credit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "beneficiar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordonator",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contul",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "banca",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "detalii",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "referinta",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "detalii2",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameoperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "codeoperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norderoperationcode",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationkindof",
                  "storageKey": null
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kindofid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namekindofs",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolkindofs",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRocollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEncollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolcollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mngmentoperationdetailid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ndocument",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "YEAR_DESC"
        }
      ],
      "concreteType": "VmngmentoperationyearsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationyears",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationyearsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationyear",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionyear"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "MONTH_DESC"
        }
      ],
      "concreteType": "VmngmentoperationmonthsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationmonths",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationmonthsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationmonth",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "OPERATIONCODEID_ASC"
        }
      ],
      "concreteType": "VoperationcodesConnection",
      "kind": "LinkedField",
      "name": "voperationcodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationcodesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationcode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationkindofsymbol",
                  "storageKey": null
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "COLLECTIONID_ASC"
        }
      ],
      "concreteType": "VcollectionsConnection",
      "kind": "LinkedField",
      "name": "vcollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcollectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vcollections(orderBy:\"COLLECTIONID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e15949dae3add3736f36e8a59d17ce8a';

module.exports = node;
