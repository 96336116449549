import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdmonthkindofInput!)
{
  fnUpdmonthkindof(input: $input) {
    monthkindof {
      monthkindofid
      name
      symbol
    }
  }
}
`

