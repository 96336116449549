import React from 'react'
import { useTable, useRowSelect, 
  useResizeColumns, useFlexLayout, usePagination, useExpanded,
  useSortBy, useFilters, useGroupBy, } from 'react-table'
import {matchSorter} from 'match-sorter'
//import {stringify} from 'flatted'

import Pagination from './pagination'
import TableToolbar from './tableToolbar'

// https://www.npmjs.com/package/react-table
// https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

const headerProps = (props, { column }) => getStyles(props, column.align)
// const footerProps = (props, { column }) => getStyles(props, column.align)
const cellProps = (props, { cell }) => getStyles(props, cell.column.align)
const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]
// const styleMedia = {color:'red'}


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.IndeterminateCheckbox] - { indeterminate, ...rest }: ${stringify({ indeterminate, ...rest })}`)
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    // const checked = true
    // const onChange = e => {
    //   e.preventDefault()
    // console.log('checkbox')
    // }
    // const Checkbox = props => (
    //   <input type="checkbox"  ref={resolvedRef} {...rest} />
    // )
    // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.IndeterminateCheckbox] - indeterminate: ${JSON.stringify({indeterminate, ...rest})}`)
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
        {/* <Checkbox checked={checked} onChange={onChange}/> */}
      </>
    )
  }
)
// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length
  const style={width: '99%', height:'1.5rem', color:'	midnightblue', border: '1px solid gainsboro'}
  // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.DefaultColumnFilter] - DefaultColumnFilter`)
  let Filter
  const onkeyDown = e => {
    switch (e.key) {
      case 'Enter':
      case 'ArrowDown':
        e.preventDefault()
        // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble] - e.key(Enter, ArrowDown) : ${stringify(e.key)}, Filter.value: ${Filter.value}`)
        setFilter(Filter.value || undefined) // Set undefined to remove the filter entirely
        // Filter.value = ''
        break;  
      case 'ArrowUp':
          e.preventDefault()
          // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble] - e.key(ArrowUp) : ${stringify(e.key)}, Filter.value: ${Filter.value}`)
          setFilter(Filter.value || undefined) // Set undefined to remove the filter entirely
          // Filter.value = ''
          break;
      default:
        break;    
    }
  }
  const onBlur = (e) => {
      e.preventDefault()
      // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.onBlur] - Filter.value: ${stringify(Filter.value)}`)
      setFilter(Filter.value || undefined) // Set undefined to remove the filter entirely
      // Filter.value = ''
    }
  // const onChange=(e) => {
  //   e.preventDefault()
  //   setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
  // }

  return (
    <input style={style}
      ref = {input => {Filter = input}}
      onFocus={input => input.target.value = ''} // onFocus={input => input.target.select()}
      defaultValue={filterValue || ''}
      onKeyDown = {onkeyDown} onBlur={onBlur} // onChange={onChange}
      placeholder={`Search ${count} records...`}
    />
  )
}

// // This is a custom filter UI for selecting a unique option from a list
// function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set()
//     preFilteredRows.forEach(row => {
//       options.add(row.values[id])
//     })
//     return [...options.values()]
//   }, [id, preFilteredRows])

//   // Render a multi-select box
//   return (
//     <select
//       value={filterValue}
//       onChange={e => {
//         setFilter(e.target.value || undefined)
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   )
// }

// // This is a custom filter UI that uses a slider to set the filter value between a column's min and max values
// function SliderColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) {
//   // Calculate the min and max
//   // using the preFilteredRows

//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min)
//       max = Math.max(row.values[id], max)
//     })
//     return [min, max]
//   }, [id, preFilteredRows])

//   return (
//     <>
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={filterValue || min}
//         onChange={e => {
//           setFilter(parseInt(e.target.value, 10))
//         }}
//       />
//       <button onClick={() => setFilter(undefined)}>Off</button>
//     </>
//   )
// }

// // This is a custom UI for our 'between' or number range filter. It uses two number boxes and filters rows to ones that have values between the two
// function NumberRangeColumnFilter({
//   column: { filterValue = [], preFilteredRows, setFilter, id },
// }) {
//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min)
//       max = Math.max(row.values[id], max)
//     })
//     return [min, max]
//   }, [id, preFilteredRows])

//   return (
//     <div
//       style={{
//         display: 'flex',
//       }}
//     >
//       <input
//         value={filterValue[0] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
//         }}
//         placeholder={`Min (${min})`}
//         style={{
//           width: '70px',
//           marginRight: '0.5rem',
//         }}
//       />
//       to
//       <input
//         value={filterValue[1] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
//         }}
//         placeholder={`Max (${max})`}
//         style={{
//           width: '70px',
//           marginLeft: '0.5rem',
//         }}
//       />
//     </div>
//   )
// }

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Be sure to pass our updateMyData and the skipPageReset option
export default ({ columns, data, setData, nonApiFields, }) => {
  const {skipPageReset, setSkipPageReset, 
    hiddenColumns, EditableCell, onrowSelected, //editableColumns, colorColumns,  
    ondbAdd, ondbUpd, ondbDel, ondbDelAll, 
    ondbRefresh, ondbDragandDropdb, //validation, refsArray, maxindex, 
    renderRowSubComponent, visibles, 
  } = nonApiFields
  const {visiblePaginationTable, visibleFooterTable, visibleToolbar, visibleGroup, visibleFilter = false, 
  visibleSelectedrow = true} = visibles
   // visibleselectedFlatRows = false,
  // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble] - importxlsfields: ${JSON.stringify(importxlsfields)}`)
  
  // When our cell renderer calls updateMyData, we'll use the rowIndex, columnId and new value to update the original data
  const updateMyData = (rowIndex, columnId, value, objvalue={}) => {
    // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.updateMyData] - rowIndex: ${rowIndex}, columnId: ${columnId}, value: ${JSON.stringify(value)}`)
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) { 
          // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble.updateMyData] - rowIndex: ${rowIndex}, columnId: ${columnId}, value[${columnId}]: ${value[columnId]}`)
          return { ...old[rowIndex], [columnId]: value, }
        }
        return row
      })
    )
    data.filter((row, index) => index === rowIndex).map(row => ondbUpd({...row, columnId, value, objvalue, rowIndex}))
  }
 
  
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 1, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 2000, // maxWidth is only used as a limit for resizing
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      Cell: EditableCell,// Set our editableColumns cell renderer as the default Cell renderer
    }),
    [EditableCell]
  )    
  // For this example, we're using pagination to illustrate how to stop the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, //flatColumns, //footerGroups, 
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize,
    // preGlobalFilteredRows, setGlobalFilter, initialState, 
    setHiddenColumns, state: { pageIndex, pageSize, selectedRowIds }, //, expanded, globalFilter
  } = useTable(
      { columns, data, defaultColumn, filterTypes, updateMyData, autoResetPage: !skipPageReset, //validation, refsArray, maxindex,  
        autoResetSelectedRows: !skipPageReset, disableMultiSort: true,},
        useFilters, useGroupBy, useSortBy, useExpanded, usePagination,
        useResizeColumns, useFlexLayout, useRowSelect,
        // use the skipPageReset option to disable page resetting temporarily
        // updateMyData isn't part of the API, but anything we put into these options will automatically be available on the instance.
        // That way we can call this function from our cell renderer!
        // We can useExpanded to track the expanded state for sub components too!
        visibleSelectedrow ? hooks => {
          hooks.columns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
              // Make this column a groupByBoundary. This ensures that groupBy columns are placed after it
              groupByBoundary: true,
              disableResizing: true,
              minWidth: 1,
              width: 25,
              maxWidth: 35,
              // The header can use the table's getToggleAllRowsSelectedProps method// to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div key={`selection`} >
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
              Cell: ({ row }) => (
                <div key={`cell`} >
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
          hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
            // fix the parent group of the selection button to not be resizable
            const selectionGroupHeader = headerGroups[0].headers[0]
            selectionGroupHeader.canResize = false
          })
        } : () => {} //: hooks => {}
    )

    React.useEffect(() => {
      setHiddenColumns(hiddenColumns)
    }, [setHiddenColumns, hiddenColumns])

    
    // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble] - 
    //       initialState: ${JSON.stringify(initialState)}, 
    //       hiddenColumns: ${hiddenColumns}`)
    const removeByIndexs = (array, indexs) =>
      array.filter((_, i) => !indexs.includes(i))

    const deleteHandler = () => {
      const newData = removeByIndexs(
        data,
        Object.keys(selectedRowIds).map(x => parseInt(x, 10))
      )
      setData(newData)
      ondbDel(selectedFlatRows)
    }
    const refreshHandler = () => {
      ondbRefresh()
    }
    const dragdbHandler = () => {
      ondbDragandDropdb()
    }
    const deleteallHandler = () => {
      ondbDelAll()
    }

    const addHandler = item => {
      const newData = data.concat([item])
      setData(newData)
      ondbAdd(item)
    }

    // Render the UI for your table
    return (
      <React.Fragment key={`tablelib.tableComponentExpandedEditableCellsPaginationResizeble`} >
      {visibleToolbar ?
        <TableToolbar numSelected={Object.keys(selectedRowIds).length} 
          deleteHandler={deleteHandler} deleteallHandler={deleteallHandler} 
          refreshHandler={refreshHandler} dragdbHandler={dragdbHandler}
          addHandler={addHandler} 
          nonApiFields={nonApiFields} />
        : <></>
      }      
        {/* <pre> <code>{JSON.stringify({ expanded: expanded }, null, 2)}</code> </pre> */}
        <div key={`table`}  {...getTableProps()} className="table">
          <div key={`thead`} className="thead"> {/*  */}
            {headerGroups.map(headerGroup => (
              <div key={`tr`}  {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div key={`th`}  {...column.getHeaderProps(headerProps)} className="th">
                    <div key={`canGroupBy`} >
                      {visibleGroup ? column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span 
                        // {...column.getGroupByToggleProps()}
                        >
                          {column.isGrouped ? '🛑 ' : '👊 '}
                        </span>
                      ) : null : null}
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      {column.canResize && (
                        <div key={`canResize`} 
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      )}
                      {/* Render the columns filter UI */}
                      {visibleFilter ? <div>{column.canFilter ? column.render('Filter') : null}</div> : <></>}
                    </div>
                  </div> 
                ))}
              </div>
            ))}
          </div>

          <div key={`tbody`} {...getTableBodyProps()} className="tbody">
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment key={`row${i}`}  >
                  <div key={`tr${i}`}  {...row.getRowProps()} className="tr">
                    {row.cells.map((cell, j) => {
                      // const cellkeys = Object.keys(cell)
                      // const {column, row} = cell
                      // const columnkeys = Object.keys(column)
                      // console.log(`[tablelib.tableComponentExpandedEditableCellsPaginationResizeble] - 
                      //           cellkeys: ${JSON.stringify(cellkeys)},
                      //           row: ${stringify(row)},
                      //           columnkeys: ${stringify(columnkeys)},
                      //           Header: ${stringify(column.Header)},
                      //           id: ${stringify(column.id)},
                      //           cell.value: ${stringify(cell.value)},
                      //           cell.getCellProps: ${stringify(cell.getCellProps)},
                      //           `)
                      return (
                        <React.Fragment key={`cell${i}${j}`}>
                          <div key={`td${i}${j}`}  {...cell.getCellProps(cellProps)} className="td">
                            {/* {cell.render('Cell')} */}
                            { cell.isGrouped ? (
                              // If it's a grouped cell, add an expander and row count
                              <React.Fragment key={`isExpanded${i}${j}`} >
                                <span {...row.getToggleRowExpandedProps()}>
                                  {row.isExpanded ? '👇' : '👉'}
                                </span>{' '}
                                {cell.render('Cell', { editableColumns: [] })} (
                                {row.subRows.length})
                              </React.Fragment>
                            ) : cell.isAggregated ? (
                              // If the cell is aggregated, use the Aggregated renderer for cell
                              cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                              // Otherwise, just render the regular cell
                              cell.render('Cell', { nonApiFields, })
                            )}   
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {/*  If the row is in an expanded state, render a row with a column that fills the entire length of the table. */}
                  {row.isExpanded ? (
                    <div key={`isExpanded${i}${i}`} >
                      <>
                        <div key={`renderRowSubComponent${i}${i}`} >
                          {/* colSpan={flatColumns.length} - !! flatColumns is undefined */}
                          {/* Inside it, call our renderRowSubComponent function. 
                              In reality, you could pass whatever you want as props to a component like this, including the entire table instance. 
                              But for this example, we'll just pass the row */}
                          {renderRowSubComponent({ row })}
                        </div>
                      </>
                    </div>
                  ) : null}
                </React.Fragment>                
              )
            })}
          </div>
          
          {visibleFooterTable ? 
              <div  key={`tfoot`} className="tfoot"> {/* style={styleMedia}*/}
                {headerGroups.map(headerGroup => (
                  <div key={`tr`}  {...headerGroup.getHeaderGroupProps()} className="tr"> 
                    {headerGroup.headers.map(column => (
                      <div key={`th`}  {...column.getHeaderProps()} className="th">
                        <div style={{fontWeight: 'bold', color: 'blue', alignContent:'right'}} > {column.render('Footer')}</div>
                      </div> 
                    ))}
                  </div>
                ))}
              </div>
            : <> </>}

          {/* {visibleFooterTable ? 
            <div key={`tfoot`} className="tfoot" > 
                {footerGroups.map(footergroup => (
                  <div key={`tr`}  {...footergroup.getFooterGroupProps()}  className="tr">
                    {footergroup.headers.map(column => (
                      <div key={`Footer`}  {...column.getFooterProps(footerProps)} className="th">{column.render('Footer')}</div>
                    ))}
                  </div>
                  ))
                }
            </div>
          : <> </>} */}
        </div>
        <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
        {visiblePaginationTable ? <Pagination props={{rows, pageIndex, pageSize, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize}} />: <></>}
        {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p> */}
        
        {/* { visibleselectedFlatRows 
          ? <pre>
              <code>
                {JSON.stringify(
                  {
                    selectedRowIds: selectedRowIds, // dupa stare selectedRowId => NU pricep de ce se sterge valoarea is state!!
                    'selectedFlatRows[].original': selectedFlatRows.map(
                      d => ({utilizatorid: d.original.utilizatorid, username: d.original.username})
                    ),
                  },
                  null,
                  2
                )}
              </code>
            </pre>
          : <></>
        } */}
        {/* <pre>
            <code>
              {JSON.stringify(
                {
                  pageIndex,
                  pageSize,
                  pageCount,
                  canNextPage,
                  canPreviousPage,
                  // sortBy,
                  // groupBy,
                  // expanded: expanded,
                  // filters,
                  selectedRowIds: selectedRowIds,
                },
                null,
                2
              )}
            </code>
          </pre> */}
          <pre>
            <code>
              {onrowSelected(selectedFlatRows)}
            </code>
          </pre>      
      </React.Fragment>
    )
  }


  