/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddutilizatorsInput = {|
  clientMutationId?: ?string,
  pUtilizatorid: number,
|};
export type mutationAddsMutationVariables = {|
  input: FnAddutilizatorsInput
|};
export type mutationAddsMutationResponse = {|
  +fnAddutilizators: ?{|
    +utilizators: ?$ReadOnlyArray<{|
      +activ: boolean,
      +cnp: string,
      +email: string,
      +parola: string,
      +telefon: ?string,
      +username: string,
      +utilizator: string,
      +utilizatorid: any,
    |}>
  |}
|};
export type mutationAddsMutation = {|
  variables: mutationAddsMutationVariables,
  response: mutationAddsMutationResponse,
|};
*/


/*
mutation mutationAddsMutation(
  $input: FnAddutilizatorsInput!
) {
  fnAddutilizators(input: $input) {
    utilizators {
      activ
      cnp
      email
      parola
      telefon
      username
      utilizator
      utilizatorid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddutilizatorsPayload",
    "kind": "LinkedField",
    "name": "fnAddutilizators",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Utilizator",
        "kind": "LinkedField",
        "name": "utilizators",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parola",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "telefon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatorid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0e2a86ed0379813f493a21e494f44e6f",
    "id": null,
    "metadata": {},
    "name": "mutationAddsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddsMutation(\n  $input: FnAddutilizatorsInput!\n) {\n  fnAddutilizators(input: $input) {\n    utilizators {\n      activ\n      cnp\n      email\n      parola\n      telefon\n      username\n      utilizator\n      utilizatorid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9778a9c4e75148d7a7e8c4daad2b08ad';

module.exports = node;
