import React from 'react'
import PdfDocument from './pdfDocument'
import enumfeldocumentpdf from '../../enums/feldocumentpdf'

export default ({params}) => {
  const {feldocumentpdf, dbdata} = params
  const _feldocumentpdf = feldocumentpdf ?? enumfeldocumentpdf.multiple.key
  // console.log(`[uioscar.pdf.pdf] - params: ${JSON.stringify(params)}, dbdata: ${JSON.stringify(dbdata)}`)
  // console.log(`[uioscar.pdf.pdf] - dbdata: `)
  // console.log(dbdata)
  // console.log(`[uioscar.pdf.pdf] - _feldocumentpdf: ${JSON.stringify(_feldocumentpdf)}`)
  return(
    <>
    {
      _feldocumentpdf === enumfeldocumentpdf.multiple.key ?
      <>
        {dbdata.data.map((data, index) => 
          <div key = {`pdf${index}`} style={{float: 'left'}}>
            <PdfDocument params={{...params, index}} data= {data} data0 = {dbdata.data0 !== undefined ? dbdata.data0[index] : undefined} />
          </div>
        )}
      </>
      : <>
          <div key = {`pdf1`} style={{float: 'left'}}>
            <PdfDocument params={{...params, index: 0, }} data= {dbdata} />
          </div>
      </>
    }
    </>
  )}

