
export default ({therapyid, dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4, }) => ({
  AddDialogtitle: ['Adauga pret terapie', ''],
    columns: [
      {header: 'therapy', accessor: 'therapy', accessorid: 'therapyid', type: '', show: therapyid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege terapie ..',}},

      {header: 'therapy2', accessor: 'therapy2', accessorid: 'therapy2Id', type: '', show: true, 
      autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege terapie 2..',}},

      // {header: 'therapytypeid', accessor: 'therapytypeid', type: 'text', show: 'true', },
      {header: 'therapytype', accessor: 'therapytype', accessorid: 'therapytypeid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions2, widthautocomplete: '33.7rem', text: 'Alege fel terapie ..',}},

      // {header: 'timeid', accessor: 'timeid', type: 'text', show: 'true', },
      {header: 'dtime', accessor: 'dtime', accessorid: 'timeid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions3, widthautocomplete: '33.7rem', text: 'Alege durata terapie 1 ..',}},

      // {header: 'time2Id', accessor: 'time2Id', type: 'text', show: 'true', },
      {header: 'dtime2', accessor: 'dtime2', accessorid: 'time2id', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions4, widthautocomplete: '33.7rem', text: 'Alege durata terapie 2 ..',}},

      {header: 'time', accessor: 'time', type: 'text', show: 'true', },
      {header: 'price', accessor: 'price', type: 'number', show: 'true', },
      {header: 'name', accessor: 'name', type: 'text', show: 'true', },
      {header: 'norder', accessor: 'norder', type: 'number', show: 'true', },
      {header: 'description', accessor: 'description', type: 'text', show: 'true', },
    ],
    initialdata: {
      therapyid: '', 
      therapy2Id: '', 
      therapytypeid: '',
      timeid: '', 
      time2Id: '',
      time: '', 
      price: '', 
      name: '', 
      norder: '',
      description: '',
      subRows: undefined,
    },    
  })
