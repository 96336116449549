import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  // const {selyear, selmonth, } = params
  // const {search, } = params
  const arrdate = params.search.data.split('-')
  const [selyear, selmonth, ] = arrdate

  const dbvdata = dbdata.vtherapists === undefined || dbdata.vtherapists.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }]
    : dbdata.vtherapists.edges.map((edge, index) => {
      return{
        ...edge.node, 
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
      })
  // console.log(`[configTherapistDialogReports.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  
  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[configTherapistDialogReports.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[configTherapistDialogReports.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vmonthkindofs.edges.map(edge => {
      const { monthkindofid, name, } = edge.node
      return {id: 'monthkindofid', monthkindofid: monthkindofid, namemonthkindof: name}
  })
  ]
  // console.log(`[configTherapistDialogReports.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        # condition: {type: VmngmentoperationCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
    ) 
    {
      vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percent
            activ
          }
        }
      }
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
        edges {
          node {
            monthkindofid
            name
            # symbol
          }
        }
      }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

