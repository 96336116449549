import React from 'react'
import { Link } from 'react-router-dom' 

import enumAuth from '../../../../enums/authenticated'
import enumRols from '../../../../enums/rols'
import Icon from '@mdi/react'
import { mdiDotsHorizontal, } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/
import enummapps from '../../../../enums/mapps'

export default ({params, events}) => {
    const {appid, } = params
    const { utilizatorOscar : {utilizatormodelat : {isauthenticated, rols}}, } = params
    // console.log(`[administrare]- {appid, isauthenticated, rols}: ${JSON.stringify({appid, isauthenticated, rols})}`)
    const visible = appid !== enummapps.mhappyworld.key 
        && (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
        && (rols.filter((item) => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||  // care are rol 'AdminApp'
            rols.filter((item) => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 ||  // care are rol 'superadmin'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0 ||  // care are rol 'responsabil'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 ||  // care are rol 'responsabil'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0)  // care are rol 'responsabilmanagement'
    // console.log(`[administrare]- visible: ${JSON.stringify(visible)}, rols: ${JSON.stringify(rols)}`)
    const rotating = false 

    return (
        <>
            {visible ? 
                <>
                    <Link to='/administrare' title='administrare'>
                        {!rotating ? <Icon path={mdiDotsHorizontal} title="administrare" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiDotsHorizontal} title="administrare" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 
                </>
                : <> </>
            } 
        </> 
    )

}
