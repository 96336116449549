import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddoperationcodespecificraportInput!)
{
  fnAddoperationcodespecificraport(input: $input) {
    operationcodespecificraport {
      feloperationcodeid
      operationcodeid
      operationcodespecificraportid
    }
  }
}
`
