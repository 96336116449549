import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isresponsabilutilizatori, isDeveloper }}) => { 
    // console.log(`[utilizatorModelats.help] - isresponsabilutilizatori: ${isresponsabilutilizatori}, `)
    return (
    <>
        { isresponsabilutilizatori ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>administrare utilizatori modelati</li>
                    <li>vizualizeaza lista utilizatorilor modelati</li>
                    <li>sunt posibile urmatoarele operatii: </li>
                    <ul>
                        <li>pentru a elimina unul sau mai multi utilizatori modelati din lista, click optiunea <b>da</b> radiobutton din coloana <b>elimina</b></li> pentru toti utilizatorii, urmat de Refresh
                        <li>pentru a adauga utilizator modelat in lista click buton ADAUGA UTILIZATOR DIN LISTA CELOR NEMODELATI</li>
                        <ul>
                            <li>un utilizator care are rol responsabilutilizatori poate modela orice utilizator. Prin urmare click buton ADAUGA UTILIZATOR DIN LISTA CELOR NEMODELATI nu este permis</li>
                        </ul>
                    </ul>
                    <li>este posibil export in fisier xls <b>utilizatorModelats.xls</b> si listare in format pdf</li>
                    <li>pentru a vizualiza help utilizatori modelati, click meniu <b>help</b> inainte de click buton <b>LISTA UTILIZATORILOR MODELATI</b></li>
                    <li>click link <b>help</b> pentru a ascunde help text </li>
                </ul>
            </div>
        : 
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>administrare utilizatori modelati</li>
                    <li>vizualizeaza lista utilizatorilor modelati</li>
                    <li>pentru a vizualiza help utilizatori modelati, click meniu <b>help</b> inainte de click buton <b>LISTA UTILIZATORILOR MODELATI</b></li>
                    <li>click link <b>help</b> pentru a ascunde help text </li>
                </ul>
            </div>
        }
            { isDeveloper ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.modelareUtilizators</b>, subcomponent: components.<b>utilizatorcomponents.utilizatori(utilizatorid)</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.ModelareUtilizators</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>ModelareUtilizatorId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorModeleazaId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorModelatId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vUtilizatorModelats(orderBy: UTILIZATORID_ASC, condition: $condition) (utilizatormodeleazaid, utilizatorid, utilizator, username, email, cnp, telefon, datainceput, datasfarsit, activ, parola)</td>
                        <td className='td'>, unde &#x0007B; condition: &#x0007B; utilizatormodeleazaid: utilizatormodeleazaid &#x0007D;  &#x0007D; </td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vutilizatorapprols(orderBy: UTILIZATORID_ASC, condition: $condition) (utilizatorid, appid, rolid)</td>
                        <td className='td'>, unde &#x0007B; condition: &#x0007B; utilizatormodeleazaid: utilizatormodeleazaid, appid: appid &#x0007D;  &#x0007D; </td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addModelareUtilizator(p_utilizatorModeleazaId, p_UtilizatorModelatId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_delModelareUtilizatorr(p_utilizatorModeleazaId, p_UtilizatorModelatId)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </div>
            : <></>
            }
    </>)
}