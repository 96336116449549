
export default [
    [
        {field: 'month', value: 'month'},
        {field: 'monthname', value: 'monthname'},
        {field: 'year', value: 'year'},
        {field: 'Data', value: 'date'},
        {field: 'Beneficiar', value: 'patient'},
        {field: 'Terapie', value: 'nametherapyprice'},
        {field: 'ora', value: 'ora'},
        {field: 'De la', value: 'sfrom'},
        {field: 'Pana la', value: 'sto'},
        {field: 'Procent', value: 'percent'},
        {field: 'mod incasare', value: 'namerocollection'},
        {field: 'Valoare', value: 'value'},
        {field: 'Factura', value: 'therapistvalue'},
        {field: 'therapistid', value: 'therapistid'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)











