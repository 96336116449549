import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare sali terapie, campurile atribut fiind: <b>collectionid, nameRo, nameEn, symbol</b> </li>
            <li>pentru campurile <u>nameRo, nameEn, symbol</u> exista unicitate in tabela, campul symbol este optional</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga collection nou [<b>+</b>], actualizare oricare dintre campurile <i>nameRo, nameEn, symbol</i>, sterge collection si refresh date </li>
                <li><b>stergerea</b> este posibila doar daca collection nu este utilizata</li>
            </ul>
            <li>este posibil export in fisier xls <b>collections.xls</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            <li>adaugarea de noi collections presupune actualzari in soft</li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.adminCollections</b></li>
                    <HelpTableStyle>
            <table className='table'>
                <caption><b><u>table: admin.collections</u></b></caption>
                <tr>
                    <th className='th'>column</th>
                    <th className='th'>datatype</th>
                    <th className='th'>uk</th>
                    <th className='th'>checks</th>
                </tr>
                <tr>
                    <td className='td'>collectionid</td>
                    <td className='td'>BIGINT</td>
                    <td className='td'>Pk</td>
                    <td className='td'></td>
                </tr>
                <tr>
                    <td className='td'>name_ro</td>
                    <td className='td'>VARCHAR(50)</td>
                    <td className='td'>uk1</td>
                    <td className='td'>NOT NULL</td>
                </tr>
                <tr>
                    <td className='td'>name_en</td>
                    <td className='td'>VARCHAR(50)</td>
                    <td className='td'>uk2</td>
                    <td className='td'>NOT NULL</td>
                </tr>
                <tr>
                    <td className='td'>symbol</td>
                    <td className='td'>VARCHAR(25)</td>
                    <td className='td'>uk3</td>
                    <td className='td'>NULL</td>
                </tr>
            </table>
            <hr className='hr' />
            <table className='table'>
                <caption><b><u>views, functions</u></b></caption>
                <tr>
                    <th className='th'>facility</th>
                    <th className='th'>fn</th>
                    <th className='th'>scheme</th>
                    <th className='th'>function</th>
                </tr>
                    <tr>
                    <td className='td'>UI</td>
                    <td className='td'>VIEW</td>
                    <td className='td'>db, mainview</td>
                    <td className='td'>vcollections (collectionId, name_ro, name_en, symbol)</td>
                </tr>
                    <tr>
                    <td className='td'>UI</td>
                    <td className='td'>add</td>
                    <td className='td'>admin, main</td>
                    <td className='td'>fn_addcollection(p_name_ro, p_name_en, p_symbol)</td>
                </tr>
                <tr>
                    <td className='td'>UI</td>
                    <td className='td'>upd</td>
                    <td className='td'>admin, main</td>
                    <td className='td'>fn_updcollection(p_keyfield, p_value, p_patientid)</td>
                </tr>
                <tr>
                    <td className='td'>UI</td>
                    <td className='td'>del</td>
                    <td className='td'>admin, main</td>
                    <td className='td'>fn_delcollection(p_patientid)</td>
                </tr>
                <tr>
                    <td className='td'>UI[xls]</td>
                    <td className='td'>ups</td>
                    <td className='td'>admin, main</td>
                    <td className='td'>fn_upscollection(p_collectionid, p_name_ro, p_name_en, p_symbol)</td>
                </tr>
            </table>
        </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>
			
				
