import React from 'react'
import Pdf from '../pdf'
// import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import Button from '@material-ui/core/Button';

export default ({params, }) => {
    // const {dbdata, } = params
    
    // console.log(`[pdflib.subComponentpdf] dbdata: ${JSON.stringify(params.dbdata)}`)
    // console.log(`[pdflib.subComponentpdf] params: `)
    // console.log(params)
   return (
        <Button  variant="outlined" color="secondary" >
            <Pdf key={`pdf`} params = {params} />
        </Button>
    )
}