/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnExtrascontintomngmentInput = {|
  clientMutationId?: ?string,
  pExtrascontid: number,
|};
export type mutationAddsMutationVariables = {|
  input: FnExtrascontintomngmentInput
|};
export type mutationAddsMutationResponse = {|
  +fnExtrascontintomngment: ?{|
    +boolean: ?boolean
  |}
|};
export type mutationAddsMutation = {|
  variables: mutationAddsMutationVariables,
  response: mutationAddsMutationResponse,
|};
*/


/*
mutation mutationAddsMutation(
  $input: FnExtrascontintomngmentInput!
) {
  fnExtrascontintomngment(input: $input) {
    boolean
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnExtrascontintomngmentPayload",
    "kind": "LinkedField",
    "name": "fnExtrascontintomngment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dfca11e271ce43b28ccf2b7f946ad829",
    "id": null,
    "metadata": {},
    "name": "mutationAddsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddsMutation(\n  $input: FnExtrascontintomngmentInput!\n) {\n  fnExtrascontintomngment(input: $input) {\n    boolean\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d3f10d6a939f77269671c6df6fd5a7c';

module.exports = node;
