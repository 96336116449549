/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type queryQueryVariables = {|
  pRefresh: boolean
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery {
  ...page_dbdata_4636sT
}

fragment page_dbdata_4636sT on Query {
  vextrascontcodificares(orderBy: NAME_ROCOLLECTION_ASC) {
    edges {
      node {
        extrascontcodificareid
        key
        operationcodeid
        nameoperationcode
        symboloperationcode
        codeoperationcode
        norderoperationcode
        collectionid
        nameRocollection
        nameEncollection
        symbolcollection
      }
    }
  }
  voperationcodes(orderBy: OPERATIONCODEID_ASC) {
    edges {
      node {
        operationcodeid
        operationkindofid
        nameoperationkindof
        operationkindofsymbol
        norderoperationkindof
        name
        symbol
        code
        norder
      }
    }
  }
  vcollections(orderBy: COLLECTIONID_ASC) {
    edges {
      node {
        collectionid
        nameRo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pRefresh"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "NAME_ROCOLLECTION_ASC"
          }
        ],
        "concreteType": "VextrascontcodificaresConnection",
        "kind": "LinkedField",
        "name": "vextrascontcodificares",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VextrascontcodificaresEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vextrascontcodificare",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extrascontcodificareid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameoperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboloperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "codeoperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norderoperationcode",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRocollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEncollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolcollection",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vextrascontcodificares(orderBy:\"NAME_ROCOLLECTION_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "OPERATIONCODEID_ASC"
          }
        ],
        "concreteType": "VoperationcodesConnection",
        "kind": "LinkedField",
        "name": "voperationcodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationcodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationcode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameoperationkindof",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofsymbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norderoperationkindof",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "COLLECTIONID_ASC"
          }
        ],
        "concreteType": "VcollectionsConnection",
        "kind": "LinkedField",
        "name": "vcollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VcollectionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vcollection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vcollections(orderBy:\"COLLECTIONID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "f8bfeac669d9281916786b3982730f7e",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery {\n  ...page_dbdata_4636sT\n}\n\nfragment page_dbdata_4636sT on Query {\n  vextrascontcodificares(orderBy: NAME_ROCOLLECTION_ASC) {\n    edges {\n      node {\n        extrascontcodificareid\n        key\n        operationcodeid\n        nameoperationcode\n        symboloperationcode\n        codeoperationcode\n        norderoperationcode\n        collectionid\n        nameRocollection\n        nameEncollection\n        symbolcollection\n      }\n    }\n  }\n  voperationcodes(orderBy: OPERATIONCODEID_ASC) {\n    edges {\n      node {\n        operationcodeid\n        operationkindofid\n        nameoperationkindof\n        operationkindofsymbol\n        norderoperationkindof\n        name\n        symbol\n        code\n        norder\n      }\n    }\n  }\n  vcollections(orderBy: COLLECTIONID_ASC) {\n    edges {\n      node {\n        collectionid\n        nameRo\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6865bf31adf37cd021b46b8072738e21';

module.exports = node;
