import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelmngmentoperationInput!)
{
  fnDelmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
    }
  }
}
`
