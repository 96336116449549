
export default  [
    {label: 'sessions', text: 'sessions', groupcol: [ 'therapy', 'date', 'therapist', 'ntherapysession', 'ntherapysession1', 'mofntherapysession', 'nbmonths', 'finalizat', 'time', 'nameRocollection', 'value', 'description', 'fromhour', 'tohour', ] }, 
    {label: 'abonamente', text: 'abonamente', groupcol: [ 'subscriptioncount', 'subscriptionnotfull', 'namesubscriptionmodes', 'abonamentfinalizat', ] }, 
    {label: 'pacient', text: 'pacient', groupcol: [ 'firstnamepatient', 'lastnamepatient', 'extensivepatient', 'birthyearpatient', 'weightpatient', 'heightpatient', 'phonepatient', 'emailpatient', 'cnppatient', ] }, 
    {label: 'terapeut', text: 'terapeut', groupcol: [ 'firstname', 'lastname', 'extensive', 'symbol', 'code', 'phone', 'email', 'cnp', 'percenttherapists', ]},
    {label: 'terapie1', text: 'terapie 1', groupcol: ['nameRotherapy', 'nameEntherapy', 'symboltherapy', 'nordertherapy', 'descriptiontherapy', ]},
    {label: 'terapie2', text: 'terapie 2', groupcol: ['nameRo2Therapy', 'nameEn2Therapy', 'symbol2Therapy', 'norder2Therapy', 'description2Therapy', ]},
    {label: 'felterapie', text: 'fel terapie', groupcol: [ 'nameRotherapytype', 'nameEntherapytype',  'symboltherapytype', 'ntherapysessiontherapytype', 'ntherapysession1Therapytype', ]},
    {label: 'perioada1', text: 'perioada 1', groupcol: [ 'symboltime', 'minutestime', 'unitstime',  ]},
    {label: 'perioada2', text: 'perioada 2', groupcol: [ 'symboltime2', 'minutestime2', 'unitstime2',  ]},
    {label: 'dbinfo', text: 'db info', groupcol: [ 'subscriptionid', 'therapysessionid', 'patientid', 'therapypriceid', 'therapyid', 'therapy2Id', 'therapytypeid',  ]},
    {label: 'dbinfo2', text: 'db info 2', groupcol: [ 'collectionid', 'timeid', 'time2Id', 'subscriptionmodeid', 'fromid', 'toid',  ]},
    {label: 'toate', text: 'toate', groupcol: []},
  ]
