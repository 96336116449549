import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeltherapypriceInput!)
{
  fnDeltherapyprice(input: $input) {
    therapyprice {
      description
      name
      norder
      price
      therapy2Id
      therapyid
      therapypriceid
      therapytypeid
      time
      time2Id
      timeid
      activ
    }
  }
}
`
