import React from 'react'
import {ThemeProvider} from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'

import Theme from '../../styles/themes/defaultTheme/theme'
import Header from '../../components/basecomponents/header/component'
import Footer from '../../components/basecomponents/footer/component'
import { ContainerStyle, HeaderStyle, ContentStyle, FooterStyle, } from '../../styles/styles/StandardLayoutStyle'

const Empty = () => (
  <div> 
      <p>No <b>empty for bodyComponent</b> provided. </p>
  </div>
)

export default ({params, bodyComponent: BodyComponent = Empty, events }) => 
  <>
    <CssBaseline />
    <ThemeProvider theme={Theme}>
      <ContainerStyle>
        <HeaderStyle>
          <Header params = {params} events = {events} />
        </HeaderStyle>
        
        <ContentStyle>
          <BodyComponent params = {{theme: Theme, ...params}} events = {events} / >
        </ContentStyle>

        <FooterStyle>
            <Footer params={params} />
        </FooterStyle>
      </ContainerStyle>
    </ThemeProvider>
  </>

          
   
