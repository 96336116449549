import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

import Amur from '../../../uioscar/amur/amur'
import Icon from '@mdi/react'
import React from 'react'
import {backgrdStyle,} from '../../../styles/styles/StandardLayoutStyle'
import enumRols from '../../../enums/rols'
import nimages from '../../../app/nimages'

// https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units

const margin = '10vw'
const position = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '78vh',
    left: '66vw',
}

const galeries = [
    {key:1, value: 'galery 1'}, 
] 

const randombackgrd = 'random'
const txtbackgrd = 'AMOS Center'
export default ({params }) => {
    const {utilizatorOscar: {utilizatormodelat: {rols, }}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false

    const {backup, setbackup} = params
    const [backgrd, setbackgrd] = React.useState(txtbackgrd)
    const themeonClick = e => {
        e.preventDefault()
        setbackgrd(backgrd === randombackgrd ? txtbackgrd : randombackgrd)
    }
    const backuponClick = e => {
        e.preventDefault()
        setbackup(!backup)
    }

    const [togglebkg, settogglebkg] = React.useState(true)
    const bgrdonClick = e => {
        e.preventDefault()
        settogglebkg(!togglebkg)
    }

    const [index, setindex] = React.useState(0)
    // const n = 36
    // const prefiximage='https://s3-us-west-2.amazonaws.com/s.cdpn.io/2017/17_05_a_amur_leopard_'
    const n = nimages
    const prefiximage='/images/_ATO'
    const sufiximage = '.jpg'
    const images = []
    for (let index = 0; index < n; index++) 
        images.push(`${prefiximage}${index<10 ? index : index}${sufiximage}`)
    // console.log(`[home] - images: ${JSON.stringify(images)}`)
    const handleonclickimages = (pindex) => {
        switch (pindex) {
            case -2: // first
                setindex(0) 
                break;
                case -1: // prev
                index === 0 ? setindex(galeries.length-1) : setindex(index-1)
                break;
                case 1: // next
                index === galeries.length-1 ? setindex(0) : setindex(index+1)
                break;
                case 2: // last
                setindex(galeries.length-1) 
                break;        
            default:
                break;
        }
    }
        
    return(
        <div>
            {isDeveloper ?
                <>
                    <h3 style={{margin: '1.875rem'}} onClick={backuponClick}>{params.backup ? 'derulare procedura backup' : 'comuta la procedura backup'}</h3>
                    <span onClick={themeonClick}>{backgrd}</span>
                </>
            : <></>}
            { backgrd !== randombackgrd ?
                <div style={{marginLeft:margin, marginRight:margin}} onClick={bgrdonClick}>
                    <div style={backgrdStyle} >
                    </div>
                </div>
            : 
                <>
                    <div >
                        <div style={{marginLeft:margin, marginRight:margin}}>
                            <Amur images={images} events={{onClick:handleonclickimages}} />
                        </div>
                        <div style={position}>
                            <span style={{color:'white'}}>{galeries[index].value}</span>
                            <Icon path={mdiChevronDoubleLeft} onClick={() => handleonclickimages(-2)} title="first" size={1} horizontal vertical rotate={180} color="white"/>
                            <Icon path={mdiChevronLeft} onClick={() => handleonclickimages(-1)} title="prev" size={1} horizontal vertical rotate={180} color="white"/>
                            <Icon path={mdiChevronRight} onClick={() => handleonclickimages(1)} title="next" size={1} horizontal vertical rotate={180} color="white"/>
                            <Icon path={mdiChevronDoubleRight} onClick={() => handleonclickimages(2)} title="last" size={1} horizontal vertical rotate={180} color="white"/>                
                        </div>

                    </div>
                </>
            }
        </div>
    )
}
