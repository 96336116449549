import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import groupcols from './groupcols'
import headers from './xls/headers'
import initiall from './initiall'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUps from './mutationUps'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'

// import AddDialog from '../../../uioscar/tablelib/addDialog'
// import Readxlsfile from '../../../uioscar/readxlsfile'








// import mutationAdds from './mutationAdds'



// import SubComponent from '../utilizatoridrepturi/component'
// import nulldate from '../../../enums/nulldate'
// import nullstring from '../../../enums/nullstring'
// import nullint from '../../../enums/nullint'














// import subcomponenttitle from './subcomponenttitle'



//import {stringify} from 'flatted'


export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, } = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[adminTimes.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns, [])     
  const initialAddDialog = React.useMemo( () => initiall, []) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[adminTimes.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[adminTimes.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {timeid} = d.original
    //     // console.log(`[adminTimes.table.onrowSelected.map] - timeid: ${timeid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({timeid})
    //   })
    // console.log(`[adminTimes.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       <Dialog key={`SubComponent`} 
  //         params = {{...params, ...subcomponenttitle, utilizatorid: row.values.utilizatorid, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} />
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[adminTimes.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[adminTimes.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[adminTimes.table] - format: ${format}`)

  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls) => {
    // console.log(`[adminTimes.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[adminTimes.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }

  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[adminTimes.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[adminTimes.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          return {input: {pTimeid: parseInt(item.timeid), pMinutes: parseInt(item.minutes), pUnits: parseInt(item.units), pSymbol: item.symbol, }}
          })
      default:
    }     
  }


  // const ondbDragandDropdb = () => {
  //   const input = {input: {pTimeid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[adminTimes.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {pMinutes: parseInt(item.minutes), pUnits: parseInt(item.units), pSymbol: item.symbol, 
    },}
    // console.log(`[adminTimes.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[adminTimes.table.ondbUpd] - db Upd: field: ${JSON.stringify({field.timeid, field.columnId, field.value})}`)
    const input = {input: {pKeyfield: field.columnId, pValue: field.value, pTimeid: parseInt(field.timeid)},}
    // console.log(`[adminTimes.table.ondbUpd]- timeid: ${field.timeid}, input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))   
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {timeid}} = selectedFlatRow
      const input = {input: {pTimeid: parseInt(timeid)},}
      // console.log(`[adminTimes.table.ondbDel]- timeid: ${timeid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[adminTimes.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ndefaultgroup = 0
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[adminTimes.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[adminTimes.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const issuperadmin = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 : false
  const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
  // console.log(`[adminTimes.table] - issuperadmin: ${issuperadmin}, rols: ${JSON.stringify(rols)}`) 

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      <h3 style={{margin: '1.875rem'}}>administrare durate terapie</h3>
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns, onrowSelected,
          EditableCell: EditableCell, editabledata: false && issuperadmin && appid === enummapps.utilizatoriapp.key, //editabledata: issuperadmin ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: undefined, //(appid === enummapps.utilizatoriapp.key && issuperadmin) ? AddDialog : undefined, 
          importxlsfields: (issuperadmin) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          exportxlsfields: (isadminApp || issuperadmin) ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: (isadminApp || issuperadmin) ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: false && issuperadmin && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[02]-Times', wsnames: wsnames}} headers={headers} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.portrait.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
