import Amosapp from './app/amosapp'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'

export default () => 
  <BrowserRouter basename="/">
    <React.StrictMode>
      <Amosapp />
    </React.StrictMode>
  </BrowserRouter>
  
