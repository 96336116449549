import React from 'react'

import { Menuitem, MenuCenterStyle, UsernameColumnStyle, usernameinputstyle, passwdinputstyle, } from '../../../styles/styles/headerstyle'

import Icon from '@mdi/react'
import { mdiLogin } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/

export default ({params, events}) => {
  const {Username, Parola, dbservice, dbcount, } = params
  const {onLogin, onNextdbservice} = events
  let username, parola 

  const onKeyDownUsername = e => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault()
        //username.value = ''    
        parola.focus()
        break;  
      default:
        break;     
      }
  }
  const onKeyDownParola = e => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        e.preventDefault()
        // console.log(`[LoginForm.onKeyDownParola.Enter] - username: ${username.value}, parola: ${parola.value}` )
        onLogin({username: username.value.toLowerCase(), parola: parola.value} )
        //parola.value = ''    
        parola.focus()
        break;  
      default:
        break;     
      }
  }
  const onBlur = e => {
    e.preventDefault()
    // // console.log(`[LoginForm.onBlur] - username: ${username.value}, parola: ${parola.value}` )
    // onLogin({username: username.value.toLowerCase(), parola: parola.value} )
  }
  const onClick = e => {
    e.preventDefault()
    // // console.log(`[LoginForm.onBlur] - username: ${username.value}, parola: ${parola.value}` )
    onLogin({username: username.value.toLowerCase(), parola: parola.value} )
  }
  const ondbClick = e => {
    e.preventDefault()
    onNextdbservice()
  }
  const dbservicevisible = dbcount > 1 && process.env.NODE_ENV !== 'production'

  return (
    <UsernameColumnStyle> 
      <Menuitem>
        <MenuCenterStyle>
        {dbservicevisible ? <div><span style={{color:"red"}} onClick={ondbClick}>{dbservice}</span></div> : <></>}
          <input ref = {input => username = input} type = 'text' style={usernameinputstyle} 
            defaultValue = {Username} onKeyDown={onKeyDownUsername}
            placeholder='username' required size='1' maxLength='50' />
          <input ref = {input => parola = input} type = 'password' style={passwdinputstyle} 
            defaultValue  = {Parola} onKeyDown={onKeyDownParola}
            placeholder='parola' required size='1' maxLength='20' onBlur={onBlur} />
          <Icon path={mdiLogin} title="login" onClick={onClick} size={1} style={{marginTop:'-0.6rem'}} horizontal vertical rotate={180} color="black" />
        </MenuCenterStyle>
      </Menuitem>
    </UsernameColumnStyle>
  )  
}