import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnDelmodelareutilizatorInput!)
{
  fnDelmodelareutilizator(input: $input) {
    modelareutilizator {
      modelareutilizatorid
      utilizatormodelatid
      utilizatormodeleazaid
    }
  }
}
`

