
export default {
    AddDialogtitle: ['Adauga terapeut', ''],
    columns: [
      {header: 'firstname', accessor: 'firstname', type: 'text', show: 'true', },
      {header: 'lastname', accessor: 'lastname', type: 'text', show: 'true', },
      {header: 'extensive', accessor: 'extensive', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'code', accessor: 'code', type: 'text', show: 'true', },
      {header: 'phone', accessor: 'phone', type: 'text', show: 'true', },
      {header: 'email', accessor: 'email', type: 'text', show: 'true', },
      {header: 'cnp', accessor: 'cnp', type: 'text', show: 'true', },
      {header: 'percent', accessor: 'percent', type: 'number', show: 'true', },
      {header: 'calendarcss', accessor: 'calendarcss', type: 'number', show: 'true', },
    ],
    initialdata: {
      firstname: '',
      lastname: '',
      extensive: '',
      symbol: '',
      code: '',
      phone: '',
      email: '',
      cnp: '',
      percent: '',
      calendarcss: '',
      subRows: undefined,
    },    
  }
