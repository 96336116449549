import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddtimeInput!)
{
  fnAddtime(input: $input) {
    dtime {
      minutes
      symbol
      timeid
      units
    }
  }
}
`
