import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare3, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabilbusiness = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0) 
        : false
    const visible = isresponsabilbusiness && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuAdministrare3>
                {
                    visible ?
                        <> 
                            <Link to='/calendarsessions'>
                                <TextIcon params = {{ text: 'calendar saptamanal terapeut[-]', title: 'subscriptions',
                                    width: theme.theme_main.widthTextIconAdminMenu, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare3>
        </>
    )
}


