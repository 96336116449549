/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VoperationcodeCondition = {|
  operationcodeid?: ?any,
  operationkindofid?: ?number,
  nameoperationkindof?: ?string,
  operationkindofsymbol?: ?string,
  norderoperationkindof?: ?number,
  name?: ?string,
  symbol?: ?string,
  code?: ?string,
  norder?: ?number,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VoperationcodeCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VoperationcodeCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  voperationcodes(orderBy: OPERATIONCODEID_ASC, condition: $condition) {
    edges {
      node {
        operationcodeid
        operationkindofid
        nameoperationkindof
        operationkindofsymbol
        norderoperationkindof
        name
        symbol
        code
        norder
      }
    }
  }
  voperationkindofs(orderBy: OPERATIONKINDOFID_ASC) {
    edges {
      node {
        operationkindofid
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationkindofid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "OPERATIONCODEID_ASC"
          }
        ],
        "concreteType": "VoperationcodesConnection",
        "kind": "LinkedField",
        "name": "voperationcodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationcodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationcode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationcodeid",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameoperationkindof",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofsymbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norderoperationkindof",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "OPERATIONKINDOFID_ASC"
          }
        ],
        "concreteType": "VoperationkindofsConnection",
        "kind": "LinkedField",
        "name": "voperationkindofs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationkindofsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationkindof",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "voperationkindofs(orderBy:\"OPERATIONKINDOFID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "9729805e2d80dcabaf8a8c11aec2141a",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VoperationcodeCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  voperationcodes(orderBy: OPERATIONCODEID_ASC, condition: $condition) {\n    edges {\n      node {\n        operationcodeid\n        operationkindofid\n        nameoperationkindof\n        operationkindofsymbol\n        norderoperationkindof\n        name\n        symbol\n        code\n        norder\n      }\n    }\n  }\n  voperationkindofs(orderBy: OPERATIONKINDOFID_ASC) {\n    edges {\n      node {\n        operationkindofid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a2b99165b8308ef6b30cee9618b3242';

module.exports = node;
