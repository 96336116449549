import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <>  
        <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
            <ul>
                <li>administrare meniu micro aplicatii</li>
                <li>campurile <b>idserver, denumire, simbol</b>, pentru campurile <u>denumire, simbol</u> exista unicitate in tabela</li>
                <li>tabela este completata odata cu instantierea bazei de date, fara ca uterior sa poata fi actualizata</li>
                <li>datele din tabela se refera la: </li>
                <ul>
                    <li>data instantierii</li>
                    <li>identificarea server-ului de baza de date, a portului si respectiv cluster-ului asociat, DB: numele si parola bazei de date</li>
                    <li>port graphQL</li>
                    <li style={{color:'green',}}>indicativ global idServerid specific fiecarei baze de date si microaplicatie</li>
                </ul>
                <li>este posibil export in fisier xls si listare in format pdf</li>
                <li>click link <b>help</b> pentru a ascunde help text </li>
                { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminidServer</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.idServers</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>idServerId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Denumire</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Simbol</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vidServers(orderBy: IDSERVERID_ASC, condition: {}) (idServerId, Denumire, Simbol)</td>
                        <td className='td'>-</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addidServer(p_denumire, p_simbol)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updidServer(p_keyfield, p_value, p_idServerId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_idServerId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI[xls]</td>
                        <td className='td'>ups</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_upsidServer(p_idServerId, p_denumire, p_simbol)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </>
            : <></>
            }
            </ul>
        </div>
    </>
