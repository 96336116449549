import { StyleAdministrare, StyleMenuAdmin1, StyleMenuAdmin2, StyleMenuAdmin3, StyleMenuAdmin4, StyleMenuAdministrare } from '../../../styles/styles/adminstyle'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import Adminlinks100 from './adminlinks1/adminlinks'
import Adminlinks101 from './adminlinks1/adminlinks1'
import Adminlinks102 from './adminlinks1/adminlinks2'
import Adminlinks103 from './adminlinks1/adminlinks3'
import Adminlinks104 from './adminlinks1/adminlinks4'
import Adminlinks105 from './adminlinks1/adminlinks5'
import Adminlinks106 from './adminlinks1/adminlinks6'
import Adminlinks200 from './adminlinks2/adminlinks'
import Adminlinks201 from './adminlinks2/adminlinks1'
import Adminlinks202 from './adminlinks2/adminlinks2'
import Adminlinks203 from './adminlinks2/adminlinks3'
import Adminlinks300 from './adminlinks3/adminlinks'
import Adminlinks301 from './adminlinks3/adminlinks1'
import Adminlinks400  from './adminlinks4/adminlinks'
import Adminlinks401 from './adminlinks4/adminlinks1'
import Adminlinks402 from './adminlinks4/adminlinks2'
import Diagram302 from './adminlinks3/diagram2'
import Diagram303 from './adminlinks3/diagram3'
import Diagram304 from './adminlinks3/diagram4'
import Diagram305 from './adminlinks3/diagram5'
import Diagram306 from './adminlinks3/diagram6'
import Diagram307 from './adminlinks3/diagram7'
import Diagram308 from './adminlinks3/diagram8'
import Diagram309 from './adminlinks3/diagram9'
import Diagram310 from './adminlinks3/diagram10'
import Diagram311 from './adminlinks3/diagram11'
import Diagram312 from './adminlinks3/diagram12'
import React from 'react'
import TODO403 from './adminlinks4/todo3'

// import backgrd from '../../../static/backgrd/oscar5-backgrd.jpg'




// componente coloana 1








// componente coloana 2





// componente coloana 3














// componente coloana 4






export default ({params, events}) => {
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
                <h3 style={{margin: '1.875rem'}}>menu administrare page</h3>
                <StyleAdministrare>
                    <StyleMenuAdministrare><StyleMenuAdmin1>
                    <fieldset>
                        <legend>therapies:</legend>
                            <Adminlinks100 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>pacienti:</legend>
                            <Adminlinks101 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>abonamente:</legend>
                            <Adminlinks102 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>calendar:</legend>
                            <Adminlinks103 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>management:</legend>
                            <Adminlinks104 params={params} events={events} />
                            <Adminlinks105 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>extras cont:</legend>
                            <Adminlinks106 params={params} events={events} />
                        </fieldset>
                    </StyleMenuAdmin1></StyleMenuAdministrare>
                    <StyleMenuAdministrare><StyleMenuAdmin2>
                        <fieldset>
                        <legend>config therapies:</legend>
                            <Adminlinks200 params={params} events={events} />
                            <Adminlinks201 params={params} events={events} />
                            <Adminlinks202 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>config management:</legend>
                            <Adminlinks203 params={params} events={events} />
                        </fieldset>
                    </StyleMenuAdmin2></StyleMenuAdministrare>
                    <StyleMenuAdministrare><StyleMenuAdmin3>
                        <fieldset>
                        <legend>Admin:</legend>
                            <Adminlinks300 params={params} events={events} />
                            <Adminlinks301 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>Diagrams:</legend>
                                <Diagram302 params={params} events={events} />
                                <Diagram303 params={params} events={events} />
                                <Diagram304 params={params} events={events} />
                                <Diagram305 params={params} events={events} />
                                <Diagram306 params={params} events={events} />
                                <Diagram307 params={params} events={events} />
                                <Diagram308 params={params} events={events} />
                                <Diagram309 params={params} events={events} />
                                <Diagram310 params={params} events={events} />
                                <Diagram311 params={params} events={events} />
                                <Diagram312 params={params} events={events} />
                            </fieldset>
                    </StyleMenuAdmin3></StyleMenuAdministrare>
                    <StyleMenuAdministrare><StyleMenuAdmin4>
                        <fieldset>
                        <legend>Utilizatori:</legend>
                            <Adminlinks400 params={params} events={events} />
                            <Adminlinks401 params={params} events={events} />
                            <Adminlinks402 params={params} events={events} />
                        </fieldset>
                        <fieldset>
                            <legend>TODO:</legend>
                            <TODO403 params={params} events={events} />
                        </fieldset>
                    </StyleMenuAdmin4></StyleMenuAdministrare>
                </StyleAdministrare>
            </div>
        </div>
    )
}

