import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare2, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}}, events}) => {
    const issuperadmin = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 : false
    const visible = issuperadmin && appid === enummapps.utilizatoriapp.key
    const visibleidservers = issuperadmin && appid === enummapps.utilizatoriapp.key

    return (
        <>
            <StyleMenuAdministrare2>
                {
                    visible ?
                        <Link to='/adminmenuapps'>
                            <TextIcon params = {{ text: "meniuri principale[001]", title: 'adminmenuapps', 
                                width: theme.theme_main.widthTextIconAdminMenu*(visibleidservers ? 25/41 : 1), 
                                fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                color: theme.theme_main.headercolor}} />
                        </Link>
                    : <></>
                }
                {
                    visibleidservers ?
                        <Link to='/adminidservers'>
                            <TextIcon params = {{ text: "idservers[-]", title: 'adminidservers', 
                                width: theme.theme_main.widthTextIconAdminMenu*16/41, 
                                fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                color: theme.theme_main.headercolor}} />
                        </Link>
                    : <> </>
                }
            </StyleMenuAdministrare2>
        </>
    )
}