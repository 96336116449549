import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelappmenuInput!)
{
  fnDelappmenu(input: $input) {
    appmenu {
      appid
      appmenuid
      denumire
      ordonare
      simbol
    }
  }
}
`
