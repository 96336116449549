/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddrolInput = {|
  clientMutationId?: ?string,
  pDenumire: string,
  pSimbol: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddrolInput
|};
export type mutationAddMutationResponse = {|
  +fnAddrol: ?{|
    +rol: ?{|
      +rolid: any,
      +denumire: string,
      +simbol: ?string,
      +observatie: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddrolInput!
) {
  fnAddrol(input: $input) {
    rol {
      rolid
      denumire
      simbol
      observatie
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddrolPayload",
    "kind": "LinkedField",
    "name": "fnAddrol",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Rol",
        "kind": "LinkedField",
        "name": "rol",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rolid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "denumire",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "observatie",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6ff8385d02254e96b8ea512033c5be9",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddrolInput!\n) {\n  fnAddrol(input: $input) {\n    rol {\n      rolid\n      denumire\n      simbol\n      observatie\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'baf61de10108694dac0e53c21a9384b0';

module.exports = node;
