import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpsmngmentoperationInput!)
{
  fnUpsmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
      zndocument
    }
  }
}
`
 