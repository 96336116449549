import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyearmng: selyear, selmonthmng: selmonth, } = params

  // console.log(`[dbMngementOperationDetails.page] - dbdata.vmngmentoperationdetails: ${JSON.stringify(dbdata.vmngmentoperationdetails)}` )
  const dbvdata = dbdata.vmngmentoperationdetails === undefined || dbdata.vmngmentoperationdetails.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }] 
    : dbdata.vmngmentoperationdetails.edges
    .map((edge, index) => {
      return{
        ...edge.node, 
        // code: `${edge.node.nameoperationcode}`,  // [${edge.node.namekindofs}
        code: `${edge.node.kindofid === 1 ? edge.node.nameoperationcode : edge.node.description}`,  // [${edge.node.namekindofs}
        kindof: `${edge.node.namekindofs}`,
        operationkindof: `${edge.node.nameoperationkindof}`,
        collection: `${edge.node.nameRocollection}`,
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
    })
  // console.log(`[dbMngementOperationDetails.page] - dbvdata: ${JSON.stringify(dbvdata)}` )

  const dbvdata1 = dbdata.vrcashflows === undefined || dbdata.vrcashflows.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }] 
    : dbdata.vrcashflows.edges
    .map((edge, index) => {
      return{
        ...edge.node, 
        }
    })
  // console.log(`[dbMngementOperationDetails.page] - dbvdata1: ${JSON.stringify(dbvdata1)}` )

  const dbvdata2 = dbdata.vrvenituricheltuielidescs === undefined || dbdata.vrvenituricheltuielis.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }] 
    : dbdata.vrvenituricheltuielidescs.edges
    .map((edge, index)  => {
      return{
        ...edge.node, 
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
    })
  // console.log(`[dbMngementOperationDetails.page] - dbvdata2: ${JSON.stringify(dbvdata2)}` )

  const dbvdata3 = dbdata.vrvenituricheltuielis === undefined || dbdata.vrvenituricheltuielis.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }] 
    : dbdata.vrvenituricheltuielis.edges
    .map((edge, index)  => {
      return{
        ...edge.node, 
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
    })
  // console.log(`[dbMngementOperationDetails.page] - dbvdata3 ${JSON.stringify(dbvdata3)}` )

  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[dbMngementOperationDetails.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...[{id: 'selmonthid', selmonthid: 0, selmonth: 'intregul an',  }], 
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[dbMngementOperationDetails.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vcollections.edges.map(edge => {
      const {collectionid, nameRo, } = edge.node
      return {id: 'collectionid', collectionid, collection: `${nameRo}`}
  })
]
// console.log(`[dbMngementOperationDetails.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  const dbOptions3 = [
    ...dbdata.voperationcodes.edges.map(edge => {
      const {operationcodeid, name, symbol, code, nameoperationkindof, } = edge.node
      return {id: 'operationcodeid', operationcodeid, code: `${nameoperationkindof}: ${name}(${symbol !== null ? symbol : ''})(${code})`}
  })
]
// console.log(`[dbMngementOperationDetails.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )

  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbvdata1, dbvdata2, dbvdata3, dbOptions, dbOptions1, dbOptions2, dbOptions3, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VmngmentoperationdetailCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
        conditionVenituriIncasariyear: {type: VrvenituricheltuieliCondition, defaultValue: {}, }
        conditionVenituriIncasariyeardesc: {type: VrvenituricheltuielidescCondition, defaultValue: {}, }
        conditioncashflow: {type: VrcashflowCondition, defaultValue: {}, }
    ) 
    {
      vmngmentoperationdetails(
        condition: $condition
      ) {
        edges {
          node {
            mngmentoperationdetailid
            operatiune,
            norder
            mngmentoperationid
            date
            month
            year
            monthname
            shortmonthname
            houseregisterprevious
            houseregistercurrent
            operationcodeid
            nameoperationcode
            symboloperationcode
            codeoperationcode
            norderoperationcode
            operationkindofid
            nameoperationkindof
            symboloperationkindof
            norderoperationkindof
            kindofid
            namekindofs
            symbolkindofs
            collectionid
            nameRocollection
            nameEncollection
            symbolcollection
            ndocument
            description
            value
            debit
            credit
            businessid
          }
        }
      }
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname
          }
        }
      }
      vcollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }           
      voperationcodes(orderBy: OPERATIONCODEID_ASC) {
        edges {
          node {
            operationcodeid
            operationkindofid
            nameoperationkindof
            operationkindofsymbol
            norderoperationkindof
            name
            symbol
            code
            norder
          }
        }
      }
      vrcashflows(condition: $conditioncashflow) {
        edges {
          node {
            year
            codeoperationcode
            nameoperationcode
            norderoperationcode
            operationkindofid
            nameoperationkindof
            norderoperationkindof
            kindofid
            namekindofs
            sumjan
            sumfeb
            summar
            sumapr
            summay
            sumjun
            sumjul
            sumaug
            sumsep
            sumoct
            sumnov
            sumdec
            total
          }
        }
      }
      vrvenituricheltuielis(condition: $conditionVenituriIncasariyear) {
        edges {
          node {
            date
            month
            year
            codeoperationcode
            nameoperationcode
            cash
            pos
            contcurent
            wallet
            none
            description
          }
        }
      }
      vrvenituricheltuielidescs(condition: $conditionVenituriIncasariyeardesc) {
        edges {
          node {
            date
            month
            year
            codeoperationcode
            nameoperationcode
            cash
            pos
            contcurent
            wallet
            none
            description
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

