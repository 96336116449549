
export default [
    [
        {field: 'rolid', value: 'rolid'},
        {field: 'denumire', value: 'denumire'},
        {field: 'simbol', value: 'simbol'},
        {field: 'observatie', value: 'observatie'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
