
export default [
    {
        therapyid: {field: 'therapyid', values: ['', '', 'therapyid'], width: 10, isLastRow : false},
        nameRo: {field: 'nameRo', values: ['', '', 'nameRo'], width: 25, isLastRow : false},
        nameEn: {field: 'nameEn', values: ['', '', 'nameEn'], width: 25, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        shortsymbol: {field: 'shortsymbol', values: ['', '', 'shortsymbol'], width: 10, isLastRow : false},
        description: {field: 'description', values: ['', '', 'description'], width: 10, isLastRow : false},
        norder: {field: 'norder', values: ['', '', 'norder'], width: 10, isLastRow : false},
    }, 
]

