/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VtherapistcollectionCondition = {|
  therapistcollectionid?: ?any,
  therapistid?: ?number,
  firstname?: ?string,
  lastname?: ?string,
  extensive?: ?string,
  symbol?: ?string,
  code?: ?string,
  phone?: ?string,
  email?: ?string,
  cnp?: ?string,
  percenttherapists?: ?any,
  collectionid?: ?number,
  nameRo?: ?string,
  nameEn?: ?string,
  symbolcollections?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VtherapistcollectionCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vtherapistpatients(orderBy: THERAPISTPATIENTID_ASC) {
    edges {
      node {
        therapistpatientid
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
        phone
        email
        cnp
        percenttherapists
        patientid
        firstnamepatient
        lastnamepatient
        extensivepatient
        birthyearpatient
        weightpatient
        heightpatient
        phonepatient
        emailpatient
        cnppatient
      }
    }
  }
  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {
    edges {
      node {
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
      }
    }
  }
  vpatients {
    edges {
      node {
        patientid
        firstname
        lastname
        extensive
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "condition"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTPATIENTID_ASC"
          }
        ],
        "concreteType": "VtherapistpatientsConnection",
        "kind": "LinkedField",
        "name": "vtherapistpatients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistpatientsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapistpatient",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapistpatientid",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percenttherapists",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstnamepatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastnamepatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extensivepatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthyearpatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weightpatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "heightpatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phonepatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailpatient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnppatient",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapistpatients(orderBy:\"THERAPISTPATIENTID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "activ": true
            }
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTID_ASC"
          }
        ],
        "concreteType": "VtherapistsConnection",
        "kind": "LinkedField",
        "name": "vtherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VpatientsConnection",
        "kind": "LinkedField",
        "name": "vpatients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VpatientsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vpatient",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db0776e3a57822033c9b47141e7f7457",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vtherapistpatients(orderBy: THERAPISTPATIENTID_ASC) {\n    edges {\n      node {\n        therapistpatientid\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n        phone\n        email\n        cnp\n        percenttherapists\n        patientid\n        firstnamepatient\n        lastnamepatient\n        extensivepatient\n        birthyearpatient\n        weightpatient\n        heightpatient\n        phonepatient\n        emailpatient\n        cnppatient\n      }\n    }\n  }\n  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {\n    edges {\n      node {\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n      }\n    }\n  }\n  vpatients {\n    edges {\n      node {\n        patientid\n        firstname\n        lastname\n        extensive\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2bbf74093df25276d260ede6de40f50';

module.exports = node;
