import React from 'react'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

export default ({dbdata, params}) => {
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
                <h3 style={{margin: '1.875rem'}}>Amis component page</h3>
            </div>
        </div>
    )
}
