
export default [
    [
        {field: 'extrascontcodificareid', value: 'extrascontcodificareid'},
        {field: 'key', value: 'key'},
        {field: 'operationcodeid', value: 'operationcodeid'},
        {field: 'nameoperationcode', value: 'nameoperationcode'},
        {field: 'symboloperationcode', value: 'symboloperationcode'},
        {field: 'codeoperationcode', value: 'codeoperationcode'},
        {field: 'norderoperationcode', value: 'norderoperationcode'},
        {field: 'collectionid', value: 'collectionid'},
        {field: 'nameRocollection', value: 'nameRocollection'},
        {field: 'nameEncollection', value: 'nameEncollection'},
        {field: 'symbolcollection', value: 'symbolcollection'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)







