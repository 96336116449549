import React from 'react'
import {backgrdStyle, transboxadminappdiagram} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
// import titles from './pdf/titles'


export default ({dbdata, params, events}) => {
  // console.log(`[entitiesdiagram.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
    
  // backgroundImage: `url(${backgrd})`
    return(
        <div style={backgrdStyle}>
            <div style={transboxadminappdiagram} >
              <Table params = {{...params, }} events={events} />
            </div>
        </div>
    )
}
