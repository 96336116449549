/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelapprolutilizatorInput = {|
  clientMutationId?: ?string,
  pUtilizatorid: number,
  pAppid: number,
  pRolid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelapprolutilizatorInput
|};
export type mutationDelMutationResponse = {|
  +fnDelapprolutilizator: ?{|
    +approlutilizator: ?{|
      +appid: any,
      +approlutilizatorid: any,
      +rolid: any,
      +utilizatorid: any,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelapprolutilizatorInput!
) {
  fnDelapprolutilizator(input: $input) {
    approlutilizator {
      appid
      approlutilizatorid
      rolid
      utilizatorid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelapprolutilizatorPayload",
    "kind": "LinkedField",
    "name": "fnDelapprolutilizator",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Approlutilizator",
        "kind": "LinkedField",
        "name": "approlutilizator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approlutilizatorid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rolid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatorid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5fe44e203fb45a5cf944a920350e9d92",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelapprolutilizatorInput!\n) {\n  fnDelapprolutilizator(input: $input) {\n    approlutilizator {\n      appid\n      approlutilizatorid\n      rolid\n      utilizatorid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a24c0ac82feac92b5864d1e5c1bede16';

module.exports = node;
