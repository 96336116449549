import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelcollectionInput!)
{
  fnDelcollection(input: $input) {
    collection {
      collectionid
      nameEn
      nameRo
      symbol
    }  }
}
`
