import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isadminApp = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0) 
        : false
    const visible = isadminApp && appid === enummapps.utilizatoriapp.key

    return (
        <>
            <StyleMenuAdministrare>
                {
                    visible ?
                        <> 
                            <Link to='/rooms'>
                                <TextIcon params = {{ text: visible ? 'rooms[2]': '', title: 'rooms', 
                                    width: theme.theme_main.widthTextIconAdminMenu* 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/times'>
                                <TextIcon params = {{ text: 'times[02]', title: 'times', 
                                    width: theme.theme_main.widthTextIconAdminMenu* 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare>
        </>
    )
}

