
export default [
    {
        therapistid: {field: 'therapistid', values: ['', '', 'therapistid'], width: 10, isLastRow : false},
        firstname: {field: 'firstname', values: ['', '', 'firstname'], width: 10, isLastRow : false},
        lastname: {field: 'lastname', values: ['', '', 'lastname'], width: 10, isLastRow : false},
        extensive: {field: 'extensive', values: ['', '', 'extensive'], width: 10, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        code: {field: 'code', values: ['', '', 'code'], width: 10, isLastRow : false},
        phone: {field: 'phone', values: ['', '', 'phone'], width: 10, isLastRow : false},
        email: {field: 'email', values: ['', '', 'email'], width: 10, isLastRow : false},
        cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
        percent: {field: 'percent', values: ['', '', 'percent'], width: 10, isLastRow : false},
    }, 
]
