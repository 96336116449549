
export default [
    [
        {field: 'date', value: 'Data'},
        {field: 'month', value: 'Luna'},
        {field: 'monthname', value: 'Luna'},
        {field: 'shortmonthname', value: 'Luna'},
        {field: 'year', value: 'An'},
        {field: 'codeoperationcode', value: 'Code'},
        {field: 'nameoperationcode', value: 'Operation'},
        {field: 'level', value: 'Level'},
        {field: 'feloperationcodeid', value: 'FelId'},
        {field: 'value', value: 'Valoare'},
    ],
    [
        {field: 'operationcodespecificraportid', value: 'operationcodespecificraportid'},
        {field: 'operationcodeid', value: 'operationcodeid'},
        {field: 'nameoperationcode', value: 'nameoperationcode'},
        {field: 'symboloperationcode', value: 'symboloperationcode'},
        {field: 'feloperationcodeid', value: 'feloperationcodeid'},
        {field: 'namefeloperationcode', value: 'namefeloperationcode'},
        {field: 'symbolfeloperationcode', value: 'symbolfeloperationcode'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

