import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>therapist dialog report, campurile atribut fiind: <b>mngmentoperationId, date, houseregisterprevious, houseregistercurrent, <span style={{color: 'red'}}>monthkindofId</span> </b> </li>
            <li>pentru campul <u>date</u> exista unicitate in tabela</li>
            <li>registru de casa: houseregisterprevious(sold precedent), houseregistercurrent (sold final al zilei)</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga inregistrare noua [<b>+</b>], actualizare oricare dintre campurile <i>date, monthkindofId</i>, sterge inregistrare si refresh date </li>
            </ul>
            <li>este posibil export in fisier xls <b>Mngmentoperations.xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.configTherapistDialogReports</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.mngmentoperations</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>mngmentoperationId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>date</td>
                            <td className='td'>DATE</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>houseregisterprevious</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>registru de casa: sold ziua precedenta</td>
                        </tr>
                        <tr>
                            <td className='td'>houseregistercurrent</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>registru de casa: sold ziua curenta</td>
                        </tr>
                        <tr>
                            <td className='td'>monthkindofId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>luna istorica, luna precedenta, luna curenta, luna urmatoare, luna viitoare</td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperations(orderBy: DATE_ASC, condition: $condition) (mngmentoperationId, date, houseregisterprevious, houseregistercurrent, monthkindofId, namemonthkindof, symbolmonthkindof, year, MonthName, shortMonthName</td>
                            <td className='td'>, unde condition: year, month </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationyears(orderBy: YEAR_DESC) (year)</td>
                            <td className='td'>lista de valori [year]</td>
                        </tr> 
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationmonths(orderBy: MONTH_DESC, condition(year)) (month, year, monthname, shortmonthname)</td>
                            <td className='td'>lista de valori [month ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmonthkindofs(orderBy: MONTHKINDOFID_ASC, ) (monthkindofid, name)</td>
                            <td className='td'>lista de valori [monthkindofid, name ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addmngmentoperation(p_date, p_houseregisterprevious, p_houseregistercurrent, p_monthkindofId)</td>
                            <td className='td'>nu intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_updmngmentoperation(p_keyfield, p_value, mngmentoperationId)</td>
                            <td className='td'>NU intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_delmngmentoperation(mngmentoperationId)</td>
                            <td className='td'>NU intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_upsmngmentoperation(p_mngmentoperationId, p_date, p_houseregisterprevious, p_houseregistercurrent, p_monthkindofId)</td>
                            <td className='td'>NU intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


