import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery($pRefresh: Boolean!,
  $putilizatormodeleazaid: BigInt!,
  $pAppid: BigInt!
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(putilizatormodeleazaid: $putilizatormodeleazaid, pAppid: $pAppid, pRefresh: $pRefresh) 
}
`

