/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VrtherapistsessionCondition = {|
  month?: ?any,
  year?: ?any,
  monthname?: ?string,
  patient?: ?string,
  nametherapyprice?: ?string,
  date?: ?any,
  sfrom?: ?string,
  sto?: ?string,
  percent?: ?any,
  namerocollection?: ?string,
  value?: ?any,
  therapistvalue?: ?any,
  therapistid?: ?number,
|};
export type VtherapistCondition = {|
  therapistid?: ?any,
  firstname?: ?string,
  lastname?: ?string,
  extensive?: ?string,
  symbol?: ?string,
  code?: ?string,
  phone?: ?string,
  email?: ?string,
  cnp?: ?string,
  percent?: ?any,
  activ?: ?boolean,
  calendarcss?: ?string,
  therapistoperationcode?: ?boolean,
|};
export type VmngmentoperationmonthCondition = {|
  year?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VrtherapistsessionCondition,
  conditiontherapist?: ?VtherapistCondition,
  conditionyear?: ?VmngmentoperationmonthCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VrtherapistsessionCondition
  $conditiontherapist: VtherapistCondition
  $conditionyear: VmngmentoperationmonthCondition
) {
  ...page_dbdata_26OO8h
}

fragment page_dbdata_26OO8h on Query {
  vrtherapistsessions(condition: $condition) {
    edges {
      node {
        month
        monthname
        year
        patient
        nametherapyprice
        date
        sfrom
        sto
        percent
        namerocollection
        value
        therapistvalue
        therapistid
      }
    }
  }
  vtherapists(condition: $conditiontherapist, orderBy: THERAPISTID_ASC) {
    edges {
      node {
        therapistid
        firstname
        lastname
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
    edges {
      node {
        month
        year
        monthname
        shortmonthname
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditiontherapist"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v4 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "conditiontherapist",
            "variableName": "conditiontherapist"
          },
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "VrtherapistsessionsConnection",
        "kind": "LinkedField",
        "name": "vrtherapistsessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VrtherapistsessionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vrtherapistsession",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "patient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nametherapyprice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sfrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sto",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "namerocollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapistvalue",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditiontherapist"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTID_ASC"
          }
        ],
        "concreteType": "VtherapistsConnection",
        "kind": "LinkedField",
        "name": "vtherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTH_DESC"
          }
        ],
        "concreteType": "VmngmentoperationmonthsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortmonthname",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03b5bb5289804df92e531d30a56c53fa",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VrtherapistsessionCondition\n  $conditiontherapist: VtherapistCondition\n  $conditionyear: VmngmentoperationmonthCondition\n) {\n  ...page_dbdata_26OO8h\n}\n\nfragment page_dbdata_26OO8h on Query {\n  vrtherapistsessions(condition: $condition) {\n    edges {\n      node {\n        month\n        monthname\n        year\n        patient\n        nametherapyprice\n        date\n        sfrom\n        sto\n        percent\n        namerocollection\n        value\n        therapistvalue\n        therapistid\n      }\n    }\n  }\n  vtherapists(condition: $conditiontherapist, orderBy: THERAPISTID_ASC) {\n    edges {\n      node {\n        therapistid\n        firstname\n        lastname\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        shortmonthname\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b291e9efe717eedde4893ba15c8fd8d6';

module.exports = node;
