
export default  [
    {label: 'yearmonth', text: 'saptamana', groupcol: [ 'selyear', 'selmonth', 'selweek', 'firstdayweek', 'lastdayweek', 'date', 'month', 'year', 'dow', 'monthname', 'shortmonthname', 'therapist', ] }, 
    // {label: 'calendarsession', text: 'calendarsession', groupcol: [ ] }, 
    {label: 'calendar', text: 'calendar', groupcol: [ 'h0700', 'h0715', 'h0730', 'h0745', 'h0800', 'h0815', 'h0830', 'h0845', 'h0900', 'h0915', 'h0930', 'h0945', 'h1000', 'h1015', 'h1030', 'h1045', 'h1100', 'h1115', 'h1130', 'h1145',   
     'h1200', 'h1215', 'h1230', 'h1245', 'h1300', 'h1315', 'h1330', 'h1345', 'h1400', 'h1415', 'h1430', 'h1445', 'h1500', 'h1515', 'h1530', 'h1545', 'h1600', 'h1615', 'h1630', 'h1645',  
     'h1700', 'h1715', 'h1730', 'h1745', 'h1800', 'h1815', 'h1830', 'h1845', 'h1900', 'h1915', 'h1930', 'h1945', 'h2000', 'h2015', 'h2030', 'h2045', 'h2100', 'h2115', 'h2130', 'h2145' ] }, 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'norder', 'roomid', 'calendarid', 'therapistid', ]},
    {label: 'toate', text: 'toate', groupcol: []},
]


