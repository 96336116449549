import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import enumtherapistreport from '../../../enums/therapistreport'
import formatdate from '../../../uioscar/formatdate'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'




// pagina este apelata din configTherapistDialogReports(pentru responsabil cu selectie terapeut) sau direct din menu pentru terapeut
export default ({params, events}) => {
    const {dbkey, toggleHelp, therapistid: businesstherapistid, selyear, selmonth, utilizatorOscar: {utilizatormodelat: {rols, therapistid}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // console.log(`[raportTherapistsessions.component] - {selyear, selmonth}: ${JSON.stringify({selyear, selmonth})}`)
    // const date = '05-jul-2020'
    // const date = '2020-07-05'
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    const _data = formatdate(new Date()).split('-')
    const _day = parseInt(_data[0])
    const _perioadatherapistreport = _day > 1 && _day <= 16 ? enumtherapistreport.perioada_1_15 : enumtherapistreport.perioada_16_30
    // const perioadatherapistreport = enumtherapistreport.perioada_1_15
    // const perioadatherapistreport = enumtherapistreport.lunar

    // console.log(`[raportTherapistsessions.page] - _date: ${JSON.stringify(_data)}, _day: ${_day}` )
    const [perioadatherapistreport, setreportid] = React.useState(_perioadatherapistreport)
    // console.log(`[raportTherapistsessions.page] - perioadatherapistreport: ${JSON.stringify(perioadatherapistreport)}` )

    const variables = { pRefresh: refresh, 
        condition: { year: parseInt(selyear), month: parseInt(selmonth.selmonthid),
            therapistid: businesstherapistid !== undefined ? parseInt(businesstherapistid) : parseInt(therapistid),
            // ...therapistid !== undefined ? {therapistid: parseInt(therapistid)} : {},
            // ...date !== undefined ? {date: date} : {},
        }, 
        conditionyear:{year: parseInt(selyear)}, 
        conditiontherapist:{therapistid: businesstherapistid !== undefined ? parseInt(businesstherapistid) : parseInt(therapistid),} 
    }
    // console.log(`[raportTherapistsessions.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[raportTherapistsessions.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[raportTherapistsessions.component] - therapistid: ${therapistid}, businesstherapistid: ${businesstherapistid}, variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, setreportid, perioadatherapistreport, onRefresh, refresh}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
