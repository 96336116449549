import React from 'react'
import DialogErrorComponent from './fullscreenErrorDialog'
import ErrorComponent from './tablelib/errorComponent'

export default ({params: {errors, setvisibleerror, seterrors, }}) => {
    const oncloseDialog = () => {
        setvisibleerror(false)
        seterrors('')
    }

    return(
        <DialogErrorComponent 
            params = {{title:'FULL SCREEN ERROR DIALOG', buttontitle:'OPEN ERROR DIALOG', errors}} 
            events={{oncloseDialog: oncloseDialog }} subComponent={ErrorComponent} />
    )
}