
export default [
    [
        {field: 'patientid', value: 'patientid'},
        {field: 'firstname', value: 'firstname'},
        {field: 'lastname', value: 'lastname'},
        {field: 'extensive', value: 'extensive'},
        {field: 'birthyear', value: 'birthyear'},
        {field: 'weight', value: 'weight'},
        {field: 'height', value: 'height'},
        {field: 'phone', value: 'phone'},
        {field: 'email', value: 'email'},
        {field: 'cnp', value: 'cnp'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
