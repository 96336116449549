// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2}) => ([
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'code', Header: 'Cod', accessor: 'code', width: 35, type: '',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 20, type: 'number', align: 'left', },
        { id: 'collection', Header: 'collection', accessor: 'collection', width: 20, type: 'text',align: 'left', },
        { id: 'percent', Header: '%', accessor: 'percent', width: 10, type: 'number', align: 'right', },
        { id: 'nbr', Header: 'nbr', accessor: 'nbr', width: 10, type: 'number', align: 'right', },
        { id: 'zi01', Header: '01', accessor: 'zi01', width: 10, type: 'number', align: 'right', },
        { id: 'zi02', Header: '02', accessor: 'zi02', width: 10, type: 'number', align: 'right', },
        { id: 'zi03', Header: '03', accessor: 'zi03', width: 10, type: 'number', align: 'right', },
        { id: 'zi04', Header: '04', accessor: 'zi04', width: 10, type: 'number', align: 'right', },
        { id: 'zi05', Header: '05', accessor: 'zi05', width: 10, type: 'number', align: 'right', },
        { id: 'zi06', Header: '06', accessor: 'zi06', width: 10, type: 'number', align: 'right', },
        { id: 'zi07', Header: '07', accessor: 'zi07', width: 10, type: 'number', align: 'right', },
        { id: 'zi08', Header: '08', accessor: 'zi08', width: 10, type: 'number', align: 'right', },
        { id: 'zi09', Header: '09', accessor: 'zi09', width: 10, type: 'number', align: 'right', },
        { id: 'zi10', Header: '10', accessor: 'zi10', width: 10, type: 'number', align: 'right', },
        { id: 'zi11', Header: '11', accessor: 'zi11', width: 10, type: 'number', align: 'right', },
        { id: 'zi12', Header: '12', accessor: 'zi12', width: 10, type: 'number', align: 'right', },
        { id: 'zi13', Header: '13', accessor: 'zi13', width: 10, type: 'number', align: 'right', },
        { id: 'zi14', Header: '14', accessor: 'zi14', width: 10, type: 'number', align: 'right', },
        { id: 'zi15', Header: '15', accessor: 'zi15', width: 10, type: 'number', align: 'right', },
        { id: 'zi16', Header: '16', accessor: 'zi16', width: 10, type: 'number', align: 'right', },
        { id: 'zi17', Header: '17', accessor: 'zi17', width: 10, type: 'number', align: 'right', },
        { id: 'zi18', Header: '18', accessor: 'zi18', width: 10, type: 'number', align: 'right', },
        { id: 'zi19', Header: '19', accessor: 'zi19', width: 10, type: 'number', align: 'right', },
        { id: 'zi20', Header: '20', accessor: 'zi20', width: 10, type: 'number', align: 'right', },
        { id: 'zi21', Header: '21', accessor: 'zi21', width: 10, type: 'number', align: 'right', },
        { id: 'zi22', Header: '22', accessor: 'zi22', width: 10, type: 'number', align: 'right', },
        { id: 'zi23', Header: '23', accessor: 'zi23', width: 10, type: 'number', align: 'right', },
        { id: 'zi24', Header: '24', accessor: 'zi24', width: 10, type: 'number', align: 'right', },
        { id: 'zi25', Header: '25', accessor: 'zi25', width: 10, type: 'number', align: 'right', },
        { id: 'zi26', Header: '26', accessor: 'zi26', width: 10, type: 'number', align: 'right', },
        { id: 'zi27', Header: '27', accessor: 'zi27', width: 10, type: 'number', align: 'right', },
        { id: 'zi28', Header: '28', accessor: 'zi28', width: 10, type: 'number', align: 'right', },
        { id: 'zi29', Header: '29', accessor: 'zi29', width: 10, type: 'number', align: 'right', },
        { id: 'zi30', Header: '30', accessor: 'zi30', width: 10, type: 'number', align: 'right', },
        { id: 'zi31', Header: '31', accessor: 'zi31', width: 10, type: 'number', align: 'right', },
        { id: 'total', Header: 'Total incasari', accessor: 'total', width: 20, type: 'number', align: 'right', },
        { id: 'totalcode', Header: 'Grand Total', accessor: 'totalcode', width: 20, type: 'number', align: 'right', },
      ]
    },
    {
      Header: 'management - procent',
      columns: [
        { id: 'pzi01', Header: '01', accessor: 'pzi01', width: 10, type: 'number', align: 'right', },
        { id: 'pzi02', Header: '02', accessor: 'pzi02', width: 10, type: 'number', align: 'right', },
        { id: 'pzi03', Header: '03', accessor: 'pzi03', width: 10, type: 'number', align: 'right', },
        { id: 'pzi04', Header: '04', accessor: 'pzi04', width: 10, type: 'number', align: 'right', },
        { id: 'pzi05', Header: '05', accessor: 'pzi05', width: 10, type: 'number', align: 'right', },
        { id: 'pzi06', Header: '06', accessor: 'pzi06', width: 10, type: 'number', align: 'right', },
        { id: 'pzi07', Header: '07', accessor: 'pzi07', width: 10, type: 'number', align: 'right', },
        { id: 'pzi08', Header: '08', accessor: 'pzi08', width: 10, type: 'number', align: 'right', },
        { id: 'pzi09', Header: '09', accessor: 'pzi09', width: 10, type: 'number', align: 'right', },
        { id: 'pzi10', Header: '10', accessor: 'pzi10', width: 10, type: 'number', align: 'right', },
        { id: 'pzi11', Header: '11', accessor: 'pzi11', width: 10, type: 'number', align: 'right', },
        { id: 'pzi12', Header: '12', accessor: 'pzi12', width: 10, type: 'number', align: 'right', },
        { id: 'pzi13', Header: '13', accessor: 'pzi13', width: 10, type: 'number', align: 'right', },
        { id: 'pzi14', Header: '14', accessor: 'pzi14', width: 10, type: 'number', align: 'right', },
        { id: 'pzi15', Header: '15', accessor: 'pzi15', width: 10, type: 'number', align: 'right', },
        { id: 'pzi16', Header: '16', accessor: 'pzi16', width: 10, type: 'number', align: 'right', },
        { id: 'pzi17', Header: '17', accessor: 'pzi17', width: 10, type: 'number', align: 'right', },
        { id: 'pzi18', Header: '18', accessor: 'pzi18', width: 10, type: 'number', align: 'right', },
        { id: 'pzi19', Header: '19', accessor: 'pzi19', width: 10, type: 'number', align: 'right', },
        { id: 'pzi20', Header: '20', accessor: 'pzi20', width: 10, type: 'number', align: 'right', },
        { id: 'pzi21', Header: '21', accessor: 'pzi21', width: 10, type: 'number', align: 'right', },
        { id: 'pzi22', Header: '22', accessor: 'pzi22', width: 10, type: 'number', align: 'right', },
        { id: 'pzi23', Header: '23', accessor: 'pzi23', width: 10, type: 'number', align: 'right', },
        { id: 'pzi24', Header: '24', accessor: 'pzi24', width: 10, type: 'number', align: 'right', },
        { id: 'pzi25', Header: '25', accessor: 'pzi25', width: 10, type: 'number', align: 'right', },
        { id: 'pzi26', Header: '26', accessor: 'pzi26', width: 10, type: 'number', align: 'right', },
        { id: 'pzi27', Header: '27', accessor: 'pzi27', width: 10, type: 'number', align: 'right', },
        { id: 'pzi28', Header: '28', accessor: 'pzi28', width: 10, type: 'number', align: 'right', },
        { id: 'pzi29', Header: '29', accessor: 'pzi29', width: 10, type: 'number', align: 'right', },
        { id: 'pzi30', Header: '30', accessor: 'pzi30', width: 10, type: 'number', align: 'right', },
        { id: 'pzi31', Header: '31', accessor: 'pzi31', width: 10, type: 'number', align: 'right', },
        { id: 'totaltherapist', Header: 'Total Procent', accessor: 'totaltherapist', width: 20, type: 'number', align: 'right', },
        { id: 'totaltherapistcode', Header: 'Grand Total Procent', accessor: 'totaltherapistcode', width: 20, type: 'number', align: 'right', },
      ]
    },
    {
      Header: 'db info', columns: [     
      { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'number', align: 'right',  },
    ],
    },
])

/*
// month
// year
// monthname
*/
