
export default [
    [
        {field: 'calendarid', value: 'calendarid'},
        {field: 'nameRo', value: 'nameRo'},
        {field: 'nameEn', value: 'nameEn'},
        {field: 'symbol', value: 'symbol'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

