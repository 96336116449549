import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddsMutation($input: FnAddutilizatorsInput!)
{
  fnAddutilizators(input: $input) {
    utilizators {
      activ
      cnp
      email
      parola
      telefon
      username
      utilizator
      utilizatorid
    }
  }
}
`
