import Component from './utilizatorlogin'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import query from './query'

export default ({params, events, }) => {
    const {existaDB, dbkey} = params 
    const {appid, utilizatorOscar: { utilizatorlogin: { username, parola, }}, usernamemodelat, } = params
    // console.log(`[utilizatorlogin.component] - { username, usernamemodelat, parola, }: ${JSON.stringify({ username, usernamemodelat, parola, })}`)
    const variables = {pUsername: username, pParola: parola, pUsernamermodelat: usernamemodelat === null ? username : usernamemodelat, pAppid: appid}  
    // console.log(`[utilizatorlogin.component] - variables: ${JSON.stringify(variables)}`)

    return (
        <>
            { !existaDB ? <></>
            : <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} params = {params} events={events} / > }
            </QueryComponent>}
        </>
    )
}

