// import React from 'react'

export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'Meniu app',
      columns: [
        { id: 'denumire', Header: 'denumire', accessor: 'denumire', width: 30,},
        { id: 'simbol', Header: 'simbol', accessor: 'simbol', width: 30,},
        { id: 'ordonare', Header: 'ordonare', accessor: 'ordonare', width: 30,},
      ]
    },      
    { 
      Header: 'db info', columns: [
        { id: 'appmenuid', Header: 'appmenuid', accessor: 'appmenuid', width: 30,  },
        { id: 'appid', Header: 'appid', accessor: 'appid', width: 30,  },
     ],
    },
  ]   