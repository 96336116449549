import React from 'react'
import { View, Text,  } from '@react-pdf/renderer'

// https://react-pdf.org/repl?code=3187b0760ce02e004d09e01b02984605e18194a4e5600b6592803a6002764043299002806f00a0618a6a0231402e19198acd8c0026012c2000744d5e1f00441dbb27900690709801dcc48a80417500ae5041a8dc33880a239051c781441089779a16cacdbb01d577ebe0046750f182f5b0a002531007302283f3d0318000610cd70bb0021102853005b4480d48b005f74f62e1448902d3e0150fcea0a18b1304313330ab6003314000f0011312a60283170050a5af9328aa5140061177a8b361d2485002600560052770ceb08875e187967571143cb63df7f64e0b5b0bb8a0019641e8487be34e7cc8a00054409222b2452730b02d908b6422110ab46b355aed73b194c37361345a6d60648f83b6e8c07ae028160c4002f64104214252b314a004a003733198a0482c0008a46313f572c80c0c1e88c8c000f98542000f20c40c0233e590602818b42528002b506282840a1d08c5d6a14856113c14aaacd1b0a5003531320b4705c1ea0d4ea3743cdcf4d0daed0ec37eb0d10523426a5a0f6852d306f7db1d787f6bb03d0e5a20c31188d4b01c87eac0fd2ebc026aa30b8726c5eae98888c6329401e83359897872dd5db7dbeba9e11477daeb8de68305a4ca6dbeddaf66bbb9943e794b0f879b01f049321abc3d6e0e9b3ee5db63b31e74077b939700f0791e1d6f90ddf1eee9645f37aaec131122f332acf636abcdad3af539b9ccef13fb8fc6f8f6fde30bd0b69c4b0a0c4600171ad1f7fc5f37ce0ab55f35c1b2b4df13ccf3740b10c0f4fdd0a027b5fd8b67cd3403471fcfb2bcc548868318005a75506000c41f3ac48f6d9096dd8b427d0c2c72c2f762350c6cc8d8df889d2f30200661800055080ec48c60b6384a4210ee3237c3c8e0288f3554a1d1f3e228c9ca8ad852408004e3a2522d8e8b32603a260332cceb36ccb358a7df4d5c5b2f2b4b138ca58ff0d2ad513b71d328b02764006a083cc4238f5384ef3df67c928b438cd5b534bab595e5455955545936468ea0c646298d20a8300227a0a51e4f9014602acc51648000

import styles from './stylepdf'

const Row = ({params, data}) =>{
  // render header or body row
  const {nsubrow} = params // daca rand este format din rand0, rand1, .. // nsubrow === 0 => (pentru body - un singur rand)
  const {header, body, } = params
  const {items} = data
  // const nrowitem = items.length
  // console.log(`[pdflib.pdfTable.Row] - items: `)
  // console.log(items)
  const maxheaderrows = (items !== undefined) ? items.reduce(( accumulator, item ) => Math.max( accumulator, item.values.length ), 0) : 0
  // console.log(`[pdflib.pdfTable.Row] - nsubrow: ${nsubrow}. maxheaderrows: ${maxheaderrows}, items: ${JSON.stringify(items)}`)


  return (
    <View key={Math.random()} style={styles.tableRow} wrap={false}> 
      { items.map(item => {
        const index = !item.isLastRow ? (header ? (nsubrow === maxheaderrows-1 ? 2 : nsubrow) : nsubrow)  : (body ? 2 : nsubrow)
        const headertableCell = item.headertableCell !== undefined ? item.headertableCell : {}
        const bodytableCell = item.bodytableCell !== undefined ? item.bodytableCell : {}
        // console.log(`[pdfTable.Row] - bodytableCell: ${JSON.stringify(bodytableCell)}`)
        return (<View key={Math.random()} 
          style={[
            styles.tableCol, styles.borders[index], 
            {width: `"${item.width}%"`}, 
            header ? (item.headerstyles !== undefined ? {...item.headerstyles[nsubrow], } : '') 
            : body ? (item.bodystyles !== undefined ? item.bodystyles[nsubrow] : '')
            : ''
          ]}> 
          {/* {console.log(`[pdfTable.Row] - item.isLastRow: ${item.isLastRow}. header: ${header}, nsubrow: ${JSON.stringify(nsubrow)}`)}
          {console.log(`[pdfTable.Row] - item: ${JSON.stringify(item)}`)} */}
          {
              !Array.isArray(item.values[nsubrow]) ?
              <Text key={Math.random()} style={[styles.tableCell, header ? headertableCell : bodytableCell, ]}>{item.values[nsubrow]}</Text> 
            :
              <>
                {item.values[nsubrow].map(item => <Text key={Math.random()} style={[styles.tableCell, header ? headertableCell : bodytableCell,]}>{item}</Text>)}
              </>
          }
        </View>) }
      )}
      </View>
  )}

export default ({params, data, }) => {
  // console.log(`[pdflib.pdfTable] - params: `)
  // console.log(params)
  const {header0,  header1, header, } = params 
  const {raportitems, raportitems0, } = data
  // const ndatarow = raportitems.length
  let nrcrt = 1
  // console.log(`[pdflib.pdfTable] - header: `)
  // console.log(header)

  // console.log(`[pdflib.pdfTable] - ndatarow: ${raportitems.length}, header: ${JSON.stringify(header)}, raportitems: ${JSON.stringify(raportitems)}`)
  // maxheaderrows: header este considerat sa acopere inclusiv elementul care are cel mai mare values.length
  const headeritems0 = header0 !== undefined ? Object.values(header0) : undefined // transforma Object into Array
  const headeritems1 = header1 !== undefined ? Object.values(header1) : undefined // transforma Object into Array
  const headeritems = Object.values(header) // transforma Object into Array
  // console.log(`[pdflib.pdfTable] - headeritems: ${JSON.stringify(headeritems)}, `)
  // console.log(`[pdflib.pdfTable] - headeritems1: ${JSON.stringify(headeritems1)}, `)

  const dataitems = (isLastRow, edge) => [].concat(
    // la rapoartele la care nrcrt nu vine din baza de date - nu exista in linia 87 pentru edge.node aceasta coloana => se genereaza un nrcrt default
    // [{values: [nrcrt++], field: 'nrcrt', width: 5, isLastRow: isLastRow}].concat(  
    headeritems.filter(item => 
      Object.keys(edge)
      .filter(field => item.field === field).length === 0) // console.log(`[pdfTable.dataitems]- filteredArray: ${JSON.stringify(filteredArray)}`)
      .map((item) => ({values: [nrcrt++], field: item.field, width: item.width, isLastRow: isLastRow, bodystyles: item.bodystyles, headerstyles: item.headerstyles, bodytableCell: item.bodytableCell, }))
  ).concat(
    headeritems.filter(item => 
      Object.keys(edge)
      .filter(field => item.field === field).length !== 0) // console.log(`[pdfTable.dataitems]- filteredArray: ${JSON.stringify(filteredArray)}`)
      .map((item) => ({values: [edge[item.field]], field: item.field, width: item.width, isLastRow: isLastRow, bodystyles: item.bodystyles, headerstyles: item.headerstyles, bodytableCell: item.bodytableCell, }))
  )
  // console.log(`[pdflib.pdfTable] - dataitems: `)
  // console.log(dataitems)


  const indexmaxValues = 0
  let {nrowheader0, nrowheader1, nrowheader, nrowbody0, nrowbody} = {nrowheader0: 0, nrowheader1: 0, nrowheader: 0, nrowbody0: 0, nrowbody: 0}

  return (
    <>
      <View key={Math.random()} style={{...styles.table, marginBottom: 0}} fixed> 
        {headeritems0 !== undefined ? headeritems0[indexmaxValues].values.map(() => 
          <Row key = {Math.random()} 
            params = {{header: true, body: false, nsubrow: nrowheader0++}} 
            data = {{items: headeritems0}} /> ) : <></> }

        {headeritems1 !== undefined ? headeritems1[indexmaxValues].values.map(() => 
          <Row key = {Math.random()} 
            params = {{header: true, body: false, nsubrow: nrowheader1++}} 
            data = {{items: headeritems1}} /> ) 
          : <></>
          }

        {headeritems[indexmaxValues].values.map(() => 
          <Row key = {Math.random()} 
            params = {{header: true, body: false, nsubrow: nrowheader++}} 
            data = {{items: headeritems}} /> ) }
      </View>

      <View key={Math.random()} style={{...styles.table, marginBottom: 0}} fixed> 
        { raportitems0 !== undefined ?
          raportitems0.map(edge => 
            <Row key = {Math.random()} 
              params = {{header: true, body: false, nsubrow: 0, }}
              data = {{ items: Object.values(dataitems(raportitems0.length === ++nrowbody0, edge))}} />)
          : <></>
        }
      </View>

      <View key={Math.random()} style={styles.table}> 
        {raportitems.map(edge => 
          <Row key = {Math.random()} 
            params = {{header: false, body: true, nsubrow: 0}} 
            data = {{ items: Object.values(dataitems(raportitems.length === ++nrowbody, edge))}} />)}
      </View>
    </>
  )
}
