/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +voperationcodes: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationcodeid: ?any,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +operationkindofsymbol: ?string,
        +norderoperationkindof: ?number,
        +name: ?string,
        +symbol: ?string,
        +code: ?string,
        +norder: ?number,
      |}
    |}>
  |},
  +voperationkindofs: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationkindofid: ?any,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationkindofid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "condition"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "OPERATIONCODEID_ASC"
        }
      ],
      "concreteType": "VoperationcodesConnection",
      "kind": "LinkedField",
      "name": "voperationcodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationcodesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationcode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationcodeid",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameoperationkindof",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationkindofsymbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norderoperationkindof",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norder",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "OPERATIONKINDOFID_ASC"
        }
      ],
      "concreteType": "VoperationkindofsConnection",
      "kind": "LinkedField",
      "name": "voperationkindofs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationkindofsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationkindof",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "voperationkindofs(orderBy:\"OPERATIONKINDOFID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '74391582614086e4aad5c41f0feb0b52';

module.exports = node;
