import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelhourInput!)
{
  fnDelhour(input: $input) {
    hour {
      hourid
      name
      symbol
    }
  }
}
`
