import React from 'react'

import TextIcon from '../../../../static/svgr/icontext'
import { HelpColumnStyle, MenuCenterStyle, Menuitem } from '../../../../styles/styles/headerstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params, }) => {
    const {toggleHelp, setToggleHelp } = params
    const visible = true
    const onClick = (e) => {
        e.preventDefault()
        setToggleHelp(!toggleHelp);
    }
    return (
        <HelpColumnStyle>
            <MenuCenterStyle><Menuitem>
                {visible ?
                    <b><TextIcon params = {{ text: `help`, title: 'help', 
                        width: '50', 
                        color: theme.theme_main.headercolor, 
                        fill: theme.theme_main.backgroundTextMenuUnterminated, }} 
                        onClick={onClick} /></b>
                    : <></>
                }
            </Menuitem></MenuCenterStyle>
        </HelpColumnStyle>
    )
}
