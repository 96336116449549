/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddextrascontcodificareInput = {|
  clientMutationId?: ?string,
  pKey: string,
  pOperationcodeid: number,
  pCollectionid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddextrascontcodificareInput
|};
export type mutationAddMutationResponse = {|
  +fnAddextrascontcodificare: ?{|
    +extrascontcodificare: ?{|
      +collectionid: number,
      +extrascontcodificareid: any,
      +key: string,
      +operationcodeid: ?number,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddextrascontcodificareInput!
) {
  fnAddextrascontcodificare(input: $input) {
    extrascontcodificare {
      collectionid
      extrascontcodificareid
      key
      operationcodeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddextrascontcodificarePayload",
    "kind": "LinkedField",
    "name": "fnAddextrascontcodificare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Extrascontcodificare",
        "kind": "LinkedField",
        "name": "extrascontcodificare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extrascontcodificareid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "622d88d55c99b83cc9aabbb0bb151b4f",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddextrascontcodificareInput!\n) {\n  fnAddextrascontcodificare(input: $input) {\n    extrascontcodificare {\n      collectionid\n      extrascontcodificareid\n      key\n      operationcodeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb4f2011844a4736e22e997446becffb';

module.exports = node;
