/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelsubscriptionInput = {|
  clientMutationId?: ?string,
  pSubscriptionid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelsubscriptionInput
|};
export type mutationDelMutationResponse = {|
  +fnDelsubscription: ?{|
    +subscription: ?{|
      +finalizat: boolean,
      +mofntherapysession: number,
      +name: string,
      +nbmonths: number,
      +ntherapysession: number,
      +ntherapysession1: ?number,
      +subscriptionid: any,
      +therapysessionid: number,
      +time: string,
      +time2Id: ?number,
      +timeid: number,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelsubscriptionInput!
) {
  fnDelsubscription(input: $input) {
    subscription {
      finalizat
      mofntherapysession
      name
      nbmonths
      ntherapysession
      ntherapysession1
      subscriptionid
      therapysessionid
      time
      time2Id
      timeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelsubscriptionPayload",
    "kind": "LinkedField",
    "name": "fnDelsubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mofntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nbmonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapysessionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c3c9677d537b9546f330c98a9e706c93",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelsubscriptionInput!\n) {\n  fnDelsubscription(input: $input) {\n    subscription {\n      finalizat\n      mofntherapysession\n      name\n      nbmonths\n      ntherapysession\n      ntherapysession1\n      subscriptionid\n      therapysessionid\n      time\n      time2Id\n      timeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3c837056898cdcb65b39aedcd756026';

module.exports = node;
