import {Route, Routes,} from 'react-router-dom'

import AdminIdServerRoute from '../routes/routes/utilizatoriroutes/adminIdServerRoute'
import AdminMenuAppsRoute from '../routes/routes/utilizatoriroutes/adminMenuAppsRoute'
import AdminOscarRoute from '../routes/routes/adminmapproutes/adminOscarRoute'
import AdminappdiagramRoute from '../routes/routes/adminmapproutes/diagramadminappRoute'
import AdministrareRoute from '../routes/routes/administrareRoute'
import AppsRoute from '../routes/routes/utilizatoriroutes/appsRoute'
import CalendarsRoute from '../routes/routes/adminmapproutes/calendarsRoute'
import CollectionsRoute from '../routes/routes/adminmapproutes/collectionsRoute'
import ConfigurareaplicatiediagramRoute from '../routes/routes/adminmapproutes/diagramconfigurareaplicatieRoute'
import ContabildiagramRoute from '../routes/routes/adminmapproutes/diagramcontabilRoute'
import DBCalendarsessionsRoute from '../routes/routes/adminmapproutes/calendarsessionsRoute'
import DBCalendarsessionsresponsabilbusinessRoute from '../routes/routes/adminmapproutes/calendarsessionsresponsabilbusinessRoute' // dbCalendar - calendar terapii/terapeuti/pacienti
import DeveloperdiagramRoute from '../routes/routes/adminmapproutes/diagramdeveloperRoute'
import EntitiesdiagramRoute from '../routes/routes/adminmapproutes/diagramentitiesRoute'
import ExtrascontsCodificare from '../routes/routes/adminmapproutes/extrascontsCodificareRoute'
import ExtrascontsRoute from '../routes/routes/adminmapproutes/extrascontsRoute'
import HomeRoute from '../routes/routes/homeRoute'
import HoursRoute from '../routes/routes/adminmapproutes/hoursRoute'
import KindofsRoute from '../routes/routes/adminmapproutes/kindofsRoute'
import MngementDetailOperationsRoute from '../routes/routes/adminmapproutes/mngementdetailoperationsRoute'
import MngementOperationsRoute from '../routes/routes/adminmapproutes/mngementoperationsRoute'
import ModelareUtilizatoriRoute from '../routes/routes/modelareUtilizatoriRoute'
import MonthkindofsRoute from '../routes/routes/adminmapproutes/monthkindofsRoute'
import NotFoundRoute from '../routes/routes/notfoundRoute'
import OperationcodesRoute from '../routes/routes/adminmapproutes/operationcodesRoute'
import OperationkindofsRoute from '../routes/routes/adminmapproutes/operationkindofsRoute'
import Osca3Route from '../routes/routes/approutes/oscar3Route'
import Oscar1Route from '../routes/routes/approutes/oscar1Route'
import Oscar2Route from '../routes/routes/approutes/oscar2Route'
import OscarRoute from '../routes/routes/approutes/oscarRoute'
import PacientdiagramRoute from '../routes/routes/adminmapproutes/diagrampacientRoute'
import PatientsRoute from '../routes/routes/adminmapproutes/patientsRoute'
import RaportIncasariDetailRoute from '../routes/routes/reportmappsroutes/raportIncasariDetailRoute'
import RaportIncasariPivotRoute from '../routes/routes/reportmappsroutes/raportIncasariPivotRoute'
import RaportLorenaRoute from '../routes/routes/reportmappsroutes/raportLorenaiRoute'
import RaportRegistruCasaRoute from '../routes/routes/reportmappsroutes/raportRegistruCasaRoute'
import RaportSingleTherapistsessionsRoute from '../routes/routes/reportmappsroutes/raportSingleTherapistsessionsRoute'
import RaportTherapistsessionsRoute from '../routes/routes/reportmappsroutes/raportTherapistsessionsRoute'
import React from 'react'
import ReportOscarsRoute from '../routes/routes/reportmappsroutes/reportOscarRoute'
import ReportsRoute from '../routes/routes/reportsRoute'
import ResponsabilbusinessdiagramRoute from '../routes/routes/adminmapproutes/diagramresponsabilbusinessRoute'
import ResponsabilmanagementdiagramRoute from '../routes/routes/adminmapproutes/diagramresponsabilmanagementRoute'
import ResponsabilutilizatoridiagramRoute from '../routes/routes/adminmapproutes/diagramresponsabilutilizatoriRoute'
import RolsRoute from '../routes/routes/utilizatoriroutes/rolsRoute'
import RoomsRoute from '../routes/routes/adminmapproutes/roomsRoute'
import SearchRoute from '../routes/routes/searchRoute'
import SiteMapdiagramRoute from '../routes/routes/adminmapproutes/sitemapdiagramRoute'
import SubscriptionsRoute from '../routes/routes/adminmapproutes/subscriptionsRoute'
import SuperadmindiagramRoute from '../routes/routes/adminmapproutes/diagramsuperadminRoute'
import TODosRoute from '../routes/routes/adminmapproutes/TODosRoute'
import TerapeutdiagramRoute from '../routes/routes/adminmapproutes/terapeutdiagramRoute'
import TherapiesRoute from '../routes/routes/adminmapproutes/therapiesRoute'
import TherapistcollectionsRoute from '../routes/routes/adminmapproutes/therapistcollectionsRoute'
import TherapistpatientsRoute from '../routes/routes/adminmapproutes/therapistpatientsRoute'
import TherapistsRoute from '../routes/routes/adminmapproutes/therapistsRoute'
import TherapisttherapypricesRoute from '../routes/routes/adminmapproutes/therapisttherapypricesRoute'
import TherapypricesRoute from '../routes/routes/adminmapproutes/therapypricesRoute'
import TherapysessionsRoute from  '../routes/routes/adminmapproutes/therapysessionsRoute'
import TherapytypesRoute from '../routes/routes/adminmapproutes/therapytypesRoute'
import TimesRoute from '../routes/routes/adminmapproutes/timesRoute'
import UtilizatoriRoute from '../routes/routes/utilizatoriroutes/utilizatoriRoute'

export default ({params, events}) =>{
    // console.log(`[menu] - params: ${JSON.stringify(params)}`)
    return(
    <>
        <Routes>
            {/* -- home ---------------------------------------------------------------- */}
            
            {/* // <Route exact path='/' component={SearchResults}/>
            // <Route exact path='/?username=ghita' component={SearchResults}
            // <Route exact path="/" render={() => (window.location = "http://google.com")} /> */}

            <Route exact path='/' 
            element={(props => (<HomeRoute {...props} params = {{title: 'home', ...params}} events = {events} />))()} />

            {/* -- oscar ---------------------------------------------------------------- */}
            <Route exact path='/oscar'
            element={(props => (<OscarRoute {...props} params = {{title: 'oscar', ...params}} events = {events} />))()} />
            {/* -- oscar1 ---------------------------------------------------------------- */}
            <Route exact path='/cashflow' 
            element={(props => (<Oscar1Route {...props} params = {{title: 'oscar1', ...params}} events = {events} />))()} />
            {/* -- oscar2 ---------------------------------------------------------------- */}
            <Route exact path='/oscar2' 
            element={(props => (<Oscar2Route {...props} params = {{title: 'cash flow', ...params}} events = {events} />))()} />
            {/* -- oscar3 ---------------------------------------------------------------- */}
            <Route exact path='/oscar3' 
            element={(props => (<Osca3Route {...props} params = {{title: 'oscar3', ...params}} events = {events} />))()} />

            {/* -- search ---------------------------------------------------------------- */}
            <Route exact path='/search' 
            element={(props => (<SearchRoute {...props} params = {{title: 'search', ...params}} events = {events} />))()} />
            {/* -- reports ---------------------------------------------------------------- */}
            <Route exact path='/reports' 
            element={(props => (<ReportsRoute {...props} params = {{title: 'rapoarte', ...params}} events = {events} />))()} />
           
            {/* -- administrare ---------------------------------------------------------------- */}
            <Route exact path='/administrare' 
            element={(props => (<AdministrareRoute {...props} params = {{ title: 'administrare', ...params}} events = {events} />))()} />
            {/* -- modelareutilizator pentru adminApp ---------------------------------------------------------------- */}
            <Route exact path='/modelareutilizatoradminApp' 
            element={(props => (<UtilizatoriRoute {...props} params = {{ title: 'modelare utilizator', modelareUtilizatori: true, ...params}} events = {events} />))()} />
            {/* -- modelareutilizator ---------------------------------------------------------------- */}
            <Route exact path='/modelareutilizator' 
            element={(props => (<ModelareUtilizatoriRoute {...props} params = {{ title: 'modelare utilizator', modelareUtilizatori: true, ...params}} events = {events} />))()} />

            
            {/* --  administrare mapps Utilizatori---------------------------------------------------------------- */}
            {/* -- utilizatori ---------------------------------------------------------------- */}
            <Route exact path='/adminUtilizatori' 
            element={(props => (<UtilizatoriRoute {...props} params = {{title: 'administrare utilizatori Oscar', modelareUtilizatori: false, ...params}} events = {events} />))()} />
            
            {/* --  adminapps ---------------------------------------------------------------- */}
            <Route exact path='/adminapps' 
            element={(props => (<AppsRoute {...props} params = {{title: 'administrare apps, meniuri principale ', ...params}} events = {events} />))()} />

            {/* --  adminrols ---------------------------------------------------------------- */}
            <Route exact path='/adminrols' 
            element={(props => (<RolsRoute {...props} params = {{title: 'administrare drepturi oscar ', ...params}} events = {events} />))()} />

            {/* --  adminmenuapps ---------------------------------------------------------------- */}
            <Route exact path='/adminmenuapps' 
            element={(props => (<AdminMenuAppsRoute {...props} params = {{title: 'administrare meniuri portal', ...params}} events = {events} />))()} />

            {/* --  adminidserver ---------------------------------------------------------------- */}
            <Route exact path='/adminidservers' 
            element={(props => (<AdminIdServerRoute {...props} params = {{title: 'administrare servere ', ...params}} events = {events} />))()} />

            {/* --  administrare mapps ---------------------------------------------------------------- */}
            {/* --  therapysessions ---------------------------------------------------------------- */}
            <Route exact path='/therapysessions' 
            element={(props => (<TherapysessionsRoute {...props} params = {{ title: 'Therapysessions', ...params}} events = {events} />))()} />
            {/* --  Collections ---------------------------------------------------------------- */}
            <Route exact path='/collections' 
            element={(props => (<CollectionsRoute {...props} params = {{ title: 'fel incasare', ...params}} events = {events} />))()} />
            {/* --  Patients ---------------------------------------------------------------- */}
            <Route exact path='/patients' 
            element={(props => (<PatientsRoute {...props} params = {{ title: 'Patients', ...params}} events = {events} />))()} />
            {/* --  therapistpatients ---------------------------------------------------------------- */}
            <Route exact path='/therapistpatients' 
            element={(props => (<TherapistpatientsRoute {...props} params = {{ title: 'Patients terapeut', ...params}} events = {events} />))()} />
            {/* --  Subscriptins ---------------------------------------------------------------- */}
            <Route exact path='/subscriptions' 
            element={(props => (<SubscriptionsRoute {...props} params = {{ title: 'Subscriptins', ...params}} events = {events} />))()} />
            {/* --  calendarsessionstherapist ---------------------------------------------------------------- */}
            <Route exact path='/calendarsessions' 
            element={(props => (<DBCalendarsessionsRoute {...props} params = {{ title: 'calendar terapii/terapeuti', ...params}} events = {events} />))()} />
            {/* --  calendarsessionsresponsabilbusiness ---------------------------------------------------------------- */}
            <Route exact path='/calendarsessionsresponsabilbusiness' 
            element={(props => (<DBCalendarsessionsresponsabilbusinessRoute {...props} params = {{ title: 'calendar terapii/responsabil business', ...params}} events = {events} />))()} />
            {/* --  extrascont ---------------------------------------------------------------- */}
            <Route exact path='/extrascont' 
            element={(props => (<ExtrascontsRoute {...props} params = {{ title: 'extrascont', ...params}} events = {events} />))()} />
            {/* --  codificareextrascont ---------------------------------------------------------------- */}
            <Route exact path='/codificareextrascont' 
            element={(props => (<ExtrascontsCodificare {...props} params = {{ title: 'codificare extras cont', ...params}} events = {events} />))()} />
            {/* --  management ---------------------------------------------------------------- */}
            <Route exact path='/management' 
            element={(props => (<MngementOperationsRoute {...props} params = {{ title: 'management', ...params}} events = {events} />))()} />
            {/* --  managementdetail ---------------------------------------------------------------- */}
            <Route exact path='/managementdetail' 
            element={(props => (<MngementDetailOperationsRoute {...props} params = {{ title: 'cash flow', ...params}} events = {events} />))()} />
            {/* --  Therapies ---------------------------------------------------------------- */}
            <Route exact path='/therapies' 
            element={(props => (<TherapiesRoute {...props} params = {{ title: 'Therapies', ...params}} events = {events} />))()} />
            {/* --  therapyprices ---------------------------------------------------------------- */}
            <Route exact path='/therapyprices' 
            element={(props => (<TherapypricesRoute {...props} params = {{ title: 'therapyprices', ...params}} events = {events} />))()} />
            {/* --  therapists ---------------------------------------------------------------- */}
            <Route exact path='/therapists' 
            element={(props => (<TherapistsRoute {...props} params = {{ title: 'Therapists', ...params}} events = {events} />))()} />
            {/* --  therapisttherapyprices ---------------------------------------------------------------- */}
            <Route exact path='/therapisttherapyprices' 
            element={(props => (<TherapisttherapypricesRoute {...props} params = {{ title: 'therapyprices terapeut', ...params}} events = {events} />))()} />
            {/* --  Therapytypes ---------------------------------------------------------------- */}
            <Route exact path='/therapytypes' 
            element={(props => (<TherapytypesRoute {...props} params = {{ title: 'fel terapii', ...params}} events = {events} />))()} />
            {/* --  therapistcollections ---------------------------------------------------------------- */}
            <Route exact path='/therapistcollections' 
            element={(props => (<TherapistcollectionsRoute {...props} params = {{ title: 'fel incasari terapeut', ...params}} events = {events} />))()} />
            {/* --  operationcodes ---------------------------------------------------------------- */}
            <Route exact path='/operationcodes' 
            element={(props => (<OperationcodesRoute {...props} params = {{ title: 'codificare operatii management', ...params}} events = {events} />))()} />
            {/* --  rooms ---------------------------------------------------------------- */}
            <Route exact path='/rooms' 
            element={(props => (<RoomsRoute {...props} params = {{ title: 'rooms', ...params}} events = {events} />))()} />
            {/* --  times ---------------------------------------------------------------- */}
            <Route exact path='/times' 
            element={(props => (<TimesRoute {...props} params = {{ title: 'times', ...params}} events = {events} />))()} />
            {/* --  calendar ---------------------------------------------------------------- */}
            <Route exact path='/calendars' 
            element={(props => (<CalendarsRoute {...props} params = {{ title: 'calendar', ...params}} events = {events} />))()} />
            {/* --  hours ---------------------------------------------------------------- */}
            <Route exact path='/hours' 
            element={(props => (<HoursRoute {...props} params = {{ title: 'ore', ...params}} events = {events} />))()} />
            {/* --  monthkindofs ---------------------------------------------------------------- */}
            <Route exact path='/monthkindofs' 
            element={(props => (<MonthkindofsRoute {...props} params = {{ title: 'ore', ...params}} events = {events} />))()} />
            {/* --  feloperatiuni ---------------------------------------------------------------- */}
            <Route exact path='/operationkindofs' 
            element={(props => (<OperationkindofsRoute {...props} params = {{ title: 'fel operatiuni', ...params}} events = {events} />))()} />
            {/* --  kindofs ---------------------------------------------------------------- */}
            <Route exact path='/kindofs' 
            element={(props => (<KindofsRoute {...props} params = {{ title: 'tip operatiuni', ...params}} events = {events} />))()} />

            {/* --  entitiesdiagram ---------------------------------------------------------------- */}
            <Route exact path='/entitiesdiagram' 
            element={(props => (<EntitiesdiagramRoute {...props} params = {{ title: 'entities diagram', ...params}} events = {events} />))()} />
            {/* --  sitemapdiagram ---------------------------------------------------------------- */}
            <Route exact path='/sitemapdiagram' 
            element={(props => (<SiteMapdiagramRoute {...props} params = {{ title: 'sitemap diagram', ...params}} events = {events} />))()} />
            {/* --  developerdiagram ---------------------------------------------------------------- */}
            <Route exact path='/developerdiagram' 
            element={(props => (<DeveloperdiagramRoute {...props} params = {{ title: 'developer diagram', ...params}} events = {events} />))()} />
            {/* --  adminappdiagram ---------------------------------------------------------------- */}
            <Route exact path='/adminappdiagram' 
            element={(props => (<AdminappdiagramRoute {...props} params = {{ title: 'adminapp diagram', ...params}} events = {events} />))()} />

            {/* --  configurareaplicatiediagram ---------------------------------------------------------------- */}
            <Route exact path='/configurareaplicatiediagram' 
            element={(props => (<ConfigurareaplicatiediagramRoute {...props} params = {{ title: 'configurareaplicatie diagram', ...params}} events = {events} />))()} />

            {/* --  contabildiagram ---------------------------------------------------------------- */}
            <Route exact path='/contabildiagram' 
            element={(props => (<ContabildiagramRoute {...props} params = {{ title: 'contabil diagram', ...params}} events = {events} />))()} />

            {/* --  pacientdiagram ---------------------------------------------------------------- */}
            <Route exact path='/pacientdiagram' 
            element={(props => (<PacientdiagramRoute {...props} params = {{ title: 'pacient diagram', ...params}} events = {events} />))()} />

            {/* --  responsabilbusinessdiagram ---------------------------------------------------------------- */}
            <Route exact path='/responsabilbusinessdiagram' 
            element={(props => (<ResponsabilbusinessdiagramRoute {...props} params = {{ title: 'responsabilbusiness diagram', ...params}} events = {events} />))()} />

            {/* --  responsabilmanagementdiagram ---------------------------------------------------------------- */}
            <Route exact path='/responsabilmanagementdiagram' 
            element={(props => (<ResponsabilmanagementdiagramRoute {...props} params = {{ title: 'responsabilmanagement diagram', ...params}} events = {events} />))()} />

            {/* --  responsabilutilizatoridiagram ---------------------------------------------------------------- */}
            <Route exact path='/responsabilutilizatoridiagram' 
            element={(props => (<ResponsabilutilizatoridiagramRoute {...props} params = {{ title: 'responsabilutilizatori diagram', ...params}} events = {events} />))()} />
            {/* --  superadmindiagram ---------------------------------------------------------------- */}
            <Route exact path='/superadmindiagram' 
            element={(props => (<SuperadmindiagramRoute {...props} params = {{ title: 'superadmin diagram', ...params}} events = {events} />))()} />

            {/* --  terapeutdiagram ---------------------------------------------------------------- */}
            <Route exact path='/terapeutdiagram' 
            element={(props => (<TerapeutdiagramRoute {...props} params = {{ title: 'terapeut diagram', ...params}} events = {events} />))()} />

            {/* --  TODOs ---------------------------------------------------------------- */}
            <Route exact path='/TODOs' 
            element={(props => (<TODosRoute {...props} params = {{ title: 'TODOs', ...params}} events = {events} />))()} />

            {/* --  adminmappOscar ---------------------------------------------------------------- */}
            <Route exact path='/adminmappOscar' 
            element={(props => (<AdminOscarRoute {...props} params = {{ title: 'administrare test', ...params}} events = {events} />))()} />


            {/* -- rapoarte mapps ------------------------------------------------------------- */}
            {/* -- reportmappOscar ---------------------------------------------------------------- */}
            <Route exact path='/reportmappOscar' 
            element={(props => (<ReportOscarsRoute {...props} params = {{title: 'raport Oscar', ...params}} events = {events} />))()} />
            {/* -- reportregistrucasa ---------------------------------------------------------------- */}
            <Route exact path='/reportregistrucasa' 
            element={(props => (<RaportRegistruCasaRoute {...props} params = {{title: 'raport registru casa', ...params}} events = {events} />))()} />
            {/* -- raporttherapistsessions ---------------------------------------------------------------- */}
            <Route exact path='/raporttherapistsessions' 
            element={(props => (<RaportTherapistsessionsRoute {...props} params = {{title: 'raport  terapeut selectat', ...params}} events = {events} />))()} />
            {/* -- raportsingletherapistsessions ---------------------------------------------------------------- */}
            <Route exact path='/raportsingletherapistsessions' 
            element={(props => (<RaportSingleTherapistsessionsRoute {...props} params = {{title: 'raport terapeut', ...params}} events = {events} />))()} />
            {/* -- raportincasaripivot ---------------------------------------------------------------- */}
            <Route exact path='/raportincasaripivot' 
            element={(props => (<RaportIncasariPivotRoute {...props} params = {{title: 'raport incasari pivot', ...params}} events = {events} />))()} />
            {/* -- raportincasaridetail ---------------------------------------------------------------- */}
            <Route exact path='/raportincasaridetail' 
            element={(props => (<RaportIncasariDetailRoute {...props} params = {{title: 'raport incasari', ...params}} events = {events} />))()} />
            {/* -- raportLorena ---------------------------------------------------------------- */}
            <Route exact path='/raportLorena' 
            element={(props => (<RaportLorenaRoute {...props} params = {{title: 'raport Lorena', ...params}} events = {events} />))()} />

            {/* -- notfound ---------------------------------------------------------------- */}
            <Route //exact path='/notfound' 
            element={(props => (<NotFoundRoute {...props} params = {{ title: 'notfound', ...params}} events = {events} />))()} />
        </Routes>
    </>
  )
}