/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelextrascontInput = {|
  clientMutationId?: ?string,
  pExtrascontid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelextrascontInput
|};
export type mutationDelMutationResponse = {|
  +fnDelextrascont: ?{|
    +extrascont: ?{|
      +banca: ?string,
      +beneficiar: ?string,
      +collectionid: number,
      +contul: ?string,
      +credit: ?number,
      +date: any,
      +debit: ?number,
      +detalii: ?string,
      +detalii2: ?string,
      +extrascontid: any,
      +mngmentoperationdetailid: ?number,
      +operationcodeid: ?number,
      +operatiune: string,
      +norder: number,
      +ordonator: ?string,
      +procesat: boolean,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelextrascontInput!
) {
  fnDelextrascont(input: $input) {
    extrascont {
      banca
      beneficiar
      collectionid
      contul
      credit
      date
      debit
      detalii
      detalii2
      extrascontid
      mngmentoperationdetailid
      operationcodeid
      operatiune
      norder
      ordonator
      procesat
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelextrascontPayload",
    "kind": "LinkedField",
    "name": "fnDelextrascont",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Extrascont",
        "kind": "LinkedField",
        "name": "extrascont",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banca",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beneficiar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contul",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detalii",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detalii2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extrascontid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationdetailid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatiune",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordonator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "procesat",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7ca0bf86781e726a85879d85fff958d",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelextrascontInput!\n) {\n  fnDelextrascont(input: $input) {\n    extrascont {\n      banca\n      beneficiar\n      collectionid\n      contul\n      credit\n      date\n      debit\n      detalii\n      detalii2\n      extrascontid\n      mngmentoperationdetailid\n      operationcodeid\n      operatiune\n      norder\n      ordonator\n      procesat\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11bd3fd2aa630bf7e2f76e3b8fc309b6';

module.exports = node;
