import React from 'react'

export default [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'therapists',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
        { id: 'percent', Header: 'percent', accessor: 'percent', width: 30, type: 'number',},
        { id: 'calendarcss', Header: 'calendarcss', accessor: 'calendarcss', width: 130, type: 'text',},
        { id: 'activ', Header: 'activ', accessor: 'activ', width: 30, radiobuttondanu: true, type: '', align: 'left',},
        { id: 'therapistoperationcode', Header: 'exista code terapeut asociat?', accessor: 'therapistoperationcode', width: 30, radiobuttondanu: true, type: '', align: 'left',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30,  },
  ],
},
]
