import React from 'react'
import { View, StyleSheet, Image, Text } from '@react-pdf/renderer'
// import logo from '../../../static/logo/logo.jpg'
import logo from '../../../static/logo/logo.jpg'
// import CNCFRlogo from '../../../static/logo/CNCFR-logo.jpg'
// import SNTFClogo from '../../../static/logo/SNTFC-logo.jpg'
// import antets from '../../../app/antets'

// import styled from '@react-pdf/styled-components'

// const Heading = styled.Text`
//   margin: 10px
//   font-size: 22px
//   font-family: 'Helvetica'
// `


export default ({params: {pdflogo, antets}}) => (
    <>
      <View key={Math.random()} style={styles.header} fixed>
        <Image style={pdflogo === undefined ? styles.image : {}} src={pdflogo ?? logo} />
        {antets !== undefined ? antets.map(item => <Text key={Math.random()}>{item.antet}</Text>) : <></>}
      </View>
    </>    
  )
  
  const styles = StyleSheet.create({
    header: {
      fontSize: 10,
      marginBottom: 1,
      textAlign: 'left',
      color: 'grey',
      // height:20,
      // width:20,
    },
    // image: { width:'auto', height: 'auto',  },
    image: { width:'93px', height: '38px',  },
})

  