import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>raport incasari </li>
            <li>raport incasari, luna conform cash flow, permite selectarea <b>an, luna</b></li>
            <li>click pe <span role="img" aria-label="point_right">👉</span> permite vizualizare raprot incasari pentru data astfel selectata</li>
            <li>este posibil export in fisier xls <b>Raportincasari.xlsx</b> si listare in format pdf.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>reportcomponents.raportIncasaris</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vrincasaris(condition: $condition) (month, year, monthname, date, code, collection, percent, value, therapistvalue, level</td>
                            <td className='td'>, unde condition: year, month </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationyears(orderBy: YEAR_DESC) (year)</td>
                            <td className='td'>lista de valori [year]</td>
                        </tr> 
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationmonths(orderBy: MONTH_DESC, condition(year)) (month, year, monthname, shortmonthname)</td>
                            <td className='td'>lista de valori [month ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmonthkindofs(orderBy: MONTHKINDOFID_ASC, ) (monthkindofid, name)</td>
                            <td className='td'>lista de valori [monthkindofid, name ]</td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


