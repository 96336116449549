
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'management', text: 'cash flow', groupcol: [ 'date', 'codeoperationcode', 'operatiune', 'code', 'collection', 'ndocument', 'value', 'debit', 'credit', 'description', ] }, 
    {label: 'soldregistrucasa', text: 'sold registru casa', groupcol: [ 'houseregisterprevious', 'houseregistercurrent', ] }, 
    {label: 'code', text: 'code', groupcol: [ 'nameoperationcode', 'symboloperationcode', 'norderoperationcode', ] }, 
    {label: 'operationkindof', text: 'operationkindof', groupcol: [ 'nameoperationkindof', 'symboloperationkindof', 'norderoperationkindof', ] }, 
    {label: 'kindof', text: 'kindof', groupcol: [ 'namekindofs', 'symbolkindofs', ] }, 
    {label: 'collection', text: 'collection', groupcol: [ 'nameEncollection', 'nameRocollection', 'symbolcollection', 'nameoperationkindof', 'norderoperationkindof', 'kindof', 'operationkindof', ] }, 
    {label: 'year-month', text: 'luna,anul', groupcol: [ 'year', 'month', 'monthname', 'shortmonthname', ] }, 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'mngmentoperationdetailid', 'norder', 'mngmentoperationid', 'operationcodeid', 'operationkindofid', 'kindofid', 'collectionid', 'businessid', ]},
    {label: 'toate', text: 'toate', groupcol: []},
  ]
