import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdextrascontcodificareInput!)
{
  fnUpdextrascontcodificare(input: $input) {
    extrascontcodificare {
      collectionid
      extrascontcodificareid
      key
      operationcodeid
    }
  }
}
`

