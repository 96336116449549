
export default ({dbdataOptions}) => {
  // console.log(`[utilizatoridrepturi.initiall] - dbdataOptions: ${JSON.stringify(dbdataOptions)}`)
  return (
    {
    AddDialogtitle: ['Adauga utilizator', 'Adauga inregistrare in tabela utilizatori.'],
    columns: [
      {header: 'app', accessor: 'app', accessorid: 'appid', type: '', show: 'true', autoFocus: true, 
        autocomplete: {dbOptions: dbdataOptions, widthautocomplete: '33.7rem', text: 'Alege micro aplicatie ..',}
    },
      {header: 'utilizator', accessor: 'utilizator', type: 'text', show: 'true', },
      {header: 'username', accessor: 'username', type: 'text', show: 'true', },
      {header: 'email', accessor: 'email', type: 'text', show: 'true', },
      {header: 'cnp', accessor: 'cnp', type: 'text', show: 'true', },
      {header: 'telefon', accessor: 'telefon', type: 'text', show: 'true', },
      {header: 'parola', accessor: 'parola', type: 'text', show: 'true', },
    ],
    initialdata: {
      app:'',
      utilizator: '',
      username: '',
      email: '',
      cnp: '',
      telefon: '',
      parola: '',
      subRows: undefined,
    },    
  })
}

// adminapp
// contabil
// developer
// liber
// responsabilutilizatori
// modelare
// pacient
// responsabilbusiness
// responsabilmanagement
// superadmin
// terapeut
// tester
