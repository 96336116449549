import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapisttherapypriceInput!)
{
  fnUpstherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
`
 