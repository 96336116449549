import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdcurrentmonthMutation($input: FnUpdcurrentmonthInput!)
{
  fnUpdcurrentmonth(input: $input) {
    currentmonth {
      currentmonthid
      day
      month
      year
    }
  }
}
`
 