
export default [
    {
        patientid: {field: 'patientid', values: ['', '', 'patientid'], width: 10, isLastRow : false},
        firstname: {field: 'firstname', values: ['', '', 'firstname'], width: 10, isLastRow : false},
        lastname: {field: 'lastname', values: ['', '', 'lastname'], width: 10, isLastRow : false},
        extensive: {field: 'extensive', values: ['', '', 'extensive'], width: 10, isLastRow : false},
        birthyear: {field: 'birthyear', values: ['', '', 'birthyear'], width: 10, isLastRow : false},
        weight: {field: 'weight', values: ['', '', 'weight'], width: 10, isLastRow : false},
        height: {field: 'height', values: ['', '', 'height'], width: 10, isLastRow : false},
        phone: {field: 'phone', values: ['', '', 'phone'], width: 10, isLastRow : false},
        email: {field: 'email', values: ['', '', 'email'], width: 10, isLastRow : false},
        cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
    }, 
]

