
export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'Utilizator',
      columns: [
        { id: 'utilizator', Header: 'utilizator', accessor: 'utilizator', width: 30},
     ],
    },
    {
      Header: 'Date utilizator',
      columns: [
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30,},
        { id: 'username', Header: 'username', accessor: 'username', width: 30,},
        { id: 'email', Header: 'email', accessor: 'email', width: 30,},
        { id: 'telefon', Header: 'telefon', accessor: 'telefon', width: 30,},
        { id: 'datainceput', Header: 'datainceput', accessor: 'datainceput', width: 30,},
        { id: 'datasfarsit', Header: 'datasfarsit', accessor: 'datasfarsit', width: 30,},
        { id: 'activ', Header: 'activ', accessor: 'activ', width: 30, align:'center', radiobuttondanu: true},
        { id: 'parola', Header: 'parola', accessor: 'parola', width: 30,},
        { id: 'adauga', Header: 'adauga', accessor: 'adauga', width: 30, align:'center', radiobuttondanu: true},
      ]
    },      
    { 
      Header: 'db info', columns: [
        { id: 'utilizatormodeleazaid', Header: 'utilizatormodeleazaid', accessor: 'utilizatormodeleazaid', width: 30,  },
        { id: 'utilizatormodelatid', Header: 'utilizatormodelatid', accessor: 'utilizatormodelatid', width: 30,  },
      ],
    },
  ]

  