/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpstherapyInput = {|
  clientMutationId?: ?string,
  pTherapyid: number,
  pNameRo: string,
  pNameEn: string,
  pSymbol: string,
  pShortsymbol: string,
  pNorder: number,
  pDescription: string,
  pActiv: boolean,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpstherapyInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpstherapy: ?{|
    +therapy: ?{|
      +description: ?string,
      +nameEn: string,
      +nameRo: string,
      +norder: ?number,
      +symbol: ?string,
      +shortsymbol: ?string,
      +therapyid: any,
      +activ: boolean,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpstherapyInput!
) {
  fnUpstherapy(input: $input) {
    therapy {
      description
      nameEn
      nameRo
      norder
      symbol
      shortsymbol
      therapyid
      activ
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpstherapyPayload",
    "kind": "LinkedField",
    "name": "fnUpstherapy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapy",
        "kind": "LinkedField",
        "name": "therapy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameRo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortsymbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapyid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03aaea16341b137e3c7b6a92f61f8bed",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpstherapyInput!\n) {\n  fnUpstherapy(input: $input) {\n    therapy {\n      description\n      nameEn\n      nameRo\n      norder\n      symbol\n      shortsymbol\n      therapyid\n      activ\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81a886d43e747ea934ea51017c7ae486';

module.exports = node;
