/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdextrascontInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pExtrascontid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdextrascontInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdextrascont: ?{|
    +extrascont: ?{|
      +banca: ?string,
      +beneficiar: ?string,
      +collectionid: number,
      +contul: ?string,
      +credit: ?number,
      +date: any,
      +debit: ?number,
      +detalii: ?string,
      +detalii2: ?string,
      +extrascontid: any,
      +mngmentoperationdetailid: ?number,
      +operationcodeid: ?number,
      +operatiune: string,
      +norder: number,
      +ordonator: ?string,
      +procesat: boolean,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdextrascontInput!
) {
  fnUpdextrascont(input: $input) {
    extrascont {
      banca
      beneficiar
      collectionid
      contul
      credit
      date
      debit
      detalii
      detalii2
      extrascontid
      mngmentoperationdetailid
      operationcodeid
      operatiune
      norder
      ordonator
      procesat
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdextrascontPayload",
    "kind": "LinkedField",
    "name": "fnUpdextrascont",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Extrascont",
        "kind": "LinkedField",
        "name": "extrascont",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banca",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beneficiar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contul",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detalii",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detalii2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extrascontid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationdetailid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatiune",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordonator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "procesat",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a2035d2a334c5c21bcfebc02f1ae516",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdextrascontInput!\n) {\n  fnUpdextrascont(input: $input) {\n    extrascont {\n      banca\n      beneficiar\n      collectionid\n      contul\n      credit\n      date\n      debit\n      detalii\n      detalii2\n      extrascontid\n      mngmentoperationdetailid\n      operationcodeid\n      operatiune\n      norder\n      ordonator\n      procesat\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a08a78ce272b813101a5287cda714ba1';

module.exports = node;
