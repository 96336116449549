import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import configxlsreport from '../../../uioscar/xls/configxlsreport'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enumcollections from '../../../enums/collections'
import enummapps from '../../../enums/mapps'
import formatdate from '../../../uioscar/formatdate'
import formatdateymd from "../../../uioscar/formatdateymd"
import groupcols from './groupcols'
import initiall from './initiall'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationAdds from './mutationAdds'
import mutationDel from './mutationDel'
import mutationDelAll from './mutationDelAll'
import mutationImpING from './mutationImpING'
import mutationUpd from './mutationUpd'
import mutationUpdcurrentmonth from '../dbTherapysessions/mutationUpdcurrentmonth'
import mutationUps from './mutationUps'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import search from './search'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'
import xlsheaders from '../../../uioscar/xls/xlsheaders'

// import headers from './xls/headers'
// import Readxlsfile from '../../../uioscar/readxlsfile'
// import SubComponent from '../utilizatoridrepturi/component'
// import nulldate from '../../../enums/nulldate'
// import subcomponenttitle from './subcomponenttitle'
//import {stringify} from 'flatted'
// import months from '../../../enums/months'
// import { mdiConsoleNetwork } from '@mdi/js'

export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, } = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[importextrasconts.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({dbOptions: dbdata.dbOptions, dbOptions1: dbdata.dbOptions1, 
    dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, }), 
    [dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, dbdata.dbOptions3, ])   
  const searchAddDialog = React.useMemo(() => search(), [])
  const configreportAddDialog = React.useMemo(() => configxlsreport(xlsheaders([dbdata.dbvdata[0]])), [dbdata.dbvdata, ])
  const initialAddDialog = React.useMemo( () => initiall({dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, }), 
    [dbdata.dbOptions2, dbdata.dbOptions3, ]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[importextrasconts.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[importextrasconts.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {extrascontid} = d.original
    //     // console.log(`[importextrasconts.table.onrowSelected.map] - extrascontid: ${extrascontid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({extrascontid})
    //   })
    // console.log(`[importextrasconts.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       <Dialog key={`SubComponent`} 
  //         params = {{...params, ...subcomponenttitle, utilizatorid: row.values.utilizatorid, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} />
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[importextrasconts.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[importextrasconts.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[importextrasconts.table] - format: ${format}`)

    const onSearch = (item) => {
   
    const _date = new Date(item.data)
    const arrdate = formatdateymd(new Date(_date.getFullYear(), _date.getMonth()+1, 0)).split('-')
    // console.log(`[dbmngementOperations.table.onSearch] - arrdate: `, arrdate)
    params.setselmonthmng({selmonthid: parseInt(arrdate[1]), monthname: months[parseInt(arrdate[1])+1]})
    const input = {input: {  pKeyfield: 'monthmng', pValue: arrdate[1], pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    
    const {selyearmng} = params
    if(selyearmng !== parseInt(arrdate[0])) { // anul selectat
      params.setselyearmng(arrdate[0])
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', pValue: arrdate[0], pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.onSearch] - nextyear: ${arrdate[0]}`)
    }
  }

  const [newxlsheaders, setnewxlsheaders] = React.useState(xlsheaders([dbdata.dbvdata[0]]))
  const onConfigReport = (item) => {
    setnewxlsheaders([xlsheaders([dbdata.dbvdata[0]])[0].filter(header => item[header.field])])
  }

  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  
  const ondbExcellAdd = (xls) => {
    // import fisier extras banca generat din db
    // console.log(`[importextrasconts.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // const inputs = _inputs.filter((item, index) => index <3)
    // console.log(`[importextrasconts.table.ondbExcellAdd] - appid: ${appid}, inputs: `, inputs)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }
  const ondbPOSINGBankExcellAdd = (xls) => {
    // import POS extras banca ING
    const collectionid = enumcollections.POS.key //2 // POS
    ondbINGBankExcellAdd(xls, collectionid)
  }
  const ondbextrascontINGBankExcellAdd = (xls) => {
    // import extras banca ING
    const collectionid = enumcollections.contcurent.key //3 // cont curent
    ondbINGBankExcellAdd(xls, collectionid)
  }

  const ondbINGBankExcellAdd = (xls, collectionid) => {
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - collectionid: ${collectionid}, `)
    // import extras banca ING
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - xls: ${JSON.stringify(xls)}`)
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - xls: ${xls}`)
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - xls: `)
    // console.log(xls)
  
    const inputs = xls.map (item => getINGimpinputs(item, collectionid)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)
    // console.log(`[importextrasconts.table.ondbextrascontINGBankExcellAdd] - appid: ${appid}, inputs: `)
    // console.log(inputs)

    const mutationxlss = [mutationImpING, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }

  const sheettodb = (inputs, mutationxlss, i = 0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[importextrasconts.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[importextrasconts.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          // console.log(`[importextrasconts.table.getinputs.map] - item: `)
          // console.log(item)
          return {input: {
            pExtrascontid: parseInt(item.extrascontid), pDate: item.date, 
            pOperatiune: item.operatiune !== undefined && item.operatiune !== '' ? item.operatiune : nullstring,
            pNorder: parseInt(item.norder),
            pDebit: item.debit !== undefined && item.debit !== '' ? parseFloat(item.debit) : parseInt(nullint), 
            pCredit: item.credit !== undefined && item.credit !== '' ? parseFloat(item.credit) : parseInt(nullint),       
            pBeneficiar: item.beneficiar !== undefined && item.beneficiar !== '' ? item.beneficiar : nullstring,
            pOrdonator: item.ordonator !== undefined && item.ordonator !== '' ? item.ordonator : nullstring,
            pContul: item.contul !== undefined && item.contul !== '' ? item.contul : nullstring,
            pBanca: item.banca !== undefined && item.banca !== '' ? item.banca : nullstring,
            pDetalii: item.detalii !== undefined && item.detalii !== '' ? item.detalii : nullstring,
            pReferinta: item.referinta !== undefined && item.referinta !== '' ? item.referinta : nullstring,
            pDetalii2: item.detalii2 !== undefined && item.detalii2 !== '' ? item.detalii2 : nullstring,
            pCollectionid: parseInt(item.collectionid),
            pOperationcodeid: item.operationcodeid !== undefined && item.operationcodeid !== '' ? parseInt(item.operationcodeid) : parseInt(nullint), 
            pMngmentoperationdetailid: item.mngmentoperationdetailid !== undefined && item.mngmentoperationdetailid !== '' ? parseInt(item.mngmentoperationdetailid) : parseInt(nullint), 
            pProcesat : item.procesat, //pProcesat : item.procesat,
            }}
          })
      default:
    }     
  }

  const months = ['ianuarie', 'februarie', 'martie', 'aprilie', 'mai', 'iunie', 'iulie', 'august', 'septembrie', 'octombrie', 'noiembrie', 'decembrie']
  // const transactions = []
  const getINGimpinputs = ({sheet, xls}, collectionid) => {
    // console.log(`[importextrasconts.table.getINGimpinputs] - collectionid: ${collectionid}, `)
    // const collectionid = '3'
    // console.log(`[importextrasconts.table.getINGimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
        // let rowtransaction = 0
        // let transaction = null
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[importextrasconts.table.getINGimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        // console.log(`[importextrasconts.table.getINGimpinputs] - sheet: ${sheet}, xls: `)
        // console.log(xls)

        return xls
        // .filter(item => item.__EMPTY_2 === undefined)
        .map(item => {
          // console.log(`[importextrasconts.table.getINGimpinputs.map] - item: ${JSON.stringify(item)}`)
          // console.log(`[importextrasconts.table.getINGimpinputs.map] - item: `)
          // console.log(item)
          const {suma, 'data procesarii': data,  
                 'tip tranzactie ' : operatiune, 
                 'nume beneficiar/ordonator' : beneficiarOrdonator, 
                //  'nume beneficiar/ordonator' : ordonator, 
                 'cont beneficiar/ordonator' : cont,
                 'banca beneficiar/ordonator' : banca,
                 'detalii tranzactie': DetaliiTranzactie,
                 'adresa beneficiar/ordonator': adresa,
                //  ['numar cont']: numarcont, // cont cirent sau POS
          } = item
          // const {suma, ['data procesarii']: data,  
          //        ['tip tranzactie '] : operatiune, 
          //        ['nume beneficiar/ordonator'] : beneficiarOrdonator, 
          //       //  ['nume beneficiar/ordonator'] : ordonator, 
          //        ['cont beneficiar/ordonator'] : cont,
          //        ['banca beneficiar/ordonator'] : banca,
          //        ['detalii tranzactie']: DetaliiTranzactie,
          //        ['adresa beneficiar/ordonator']: adresa,
          //       //  ['numar cont']: numarcont, // cont cirent sau POS
          // } = item

          //console.log(`[importextrasconts.table.getINGimpinputs.map] - data: ${data}, day: ${data.substring(6, 8)}, month: ${parseInt(data.substring(4, 6))}, month: ${months[parseInt(data.substring(4, 6))-1]}, year: ${data.substring(0, 4)}`)
          // const pDate = new Date(data.substring(0, 4), parseInt(data.substring(4, 6))-1, data.substring(6, 8))
          // const _suma = parseFloat(suma.replace('.', '').replace(',', '.'))
          
          // console.log(`[importextrasconts.table.getINGimpinputs.map] - data: ${data}`)
          const pDate = data
          const _suma = suma
          
          const Debit_Incasare = _suma < 0 ? -_suma : parseFloat(nullint)
          const Credit_Plata   = _suma > 0 ?  _suma : parseFloat(nullint)
          //console.log(`[importextrasconts.table.getINGimpinputs.map] - collectionid: ${collectionid}, pDate: ${formatdate(pDate)}, operatiune: ${operatiune}, Debit_Incasare: ${Debit_Incasare}, Credit_Plata: ${Credit_Plata}, DetaliiTranzactie: ${DetaliiTranzactie}`)
          // beneficiar: ${beneficiar}, ordonator: ${ordonator}, cont: ${cont}, 

          return {input: {
            pCollectionid: parseInt(collectionid), pDate: formatdate(pDate), 
            pOperatiune: operatiune !== undefined ? operatiune : nullstring, 
            pDebit: Debit_Incasare, pCredit: Credit_Plata, 
            pField1: beneficiarOrdonator !== undefined ? beneficiarOrdonator : nullstring, 
            pField2: cont !== undefined ? cont : nullstring,
            pField3: banca !== undefined ? banca : nullstring,
            pField4: DetaliiTranzactie !== undefined ? DetaliiTranzactie : nullstring, 
            pField5: nullstring, 
            pField6: adresa !== undefined ? adresa : nullstring, 
          }}
        })
      default:
    }     
  }
  // const getINGimpinputs.old = ({sheet, xls}, collectionid) => {
  // console.log(`[importextrasconts.table.getINGimpinputs] - collectionid: ${collectionid}, `)
  //   // const collectionid = '3'
  // console.log(`[importextrasconts.table.getINGimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
  //   // fiecare sheet devine un inputs array pentru inregistrare in baza de date
  //       let rowtransaction = 0
  //       let transaction = null
  //   switch(sheet) {
  //     case wsnames[0]:
  // console.log(`[importextrasconts.table.getINGimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
  //       return xls
  //       .filter(item => item.__EMPTY_2 === undefined)
  //       .map(item => {
  //         // console.log(`[importextrasconts.table.getINGimpinputs.map] - item: ${JSON.stringify(item)}`)
  //         // const {__EMPTY_1: Data, __EMPTY_6: DetaliiTranzactie, __EMPTY_15: Debit_Incasare, __EMPTY_19: Credit_Plata, } = item
  //         const {__EMPTY_1: Data, __EMPTY_7: DetaliiTranzactie, __EMPTY_15: Debit_Incasare, __EMPTY_17: Credit_Plata, } = item
  //         while (Data === undefined ) {
  //           // randuri date suplimentare
  //           transaction = {...transaction, [`pField${++rowtransaction}`]: DetaliiTranzactie !== undefined ? DetaliiTranzactie.trim() : DetaliiTranzactie, final: false, }
  //           return transaction  
  //         }
  //         // primul rand[date, detalii tranzactie, debit, credit]
  //         const month = transaction !== null ? transaction.pDate.split(' ')[1] : 'null'
  //         const finaltransaction = months.find(item => item === month) !== undefined ? {...transaction, final: true} : {final: false}
  //         // console.log(`[importextrasconts.table.getINGimpinputs.map] - finaltransaction: ${JSON.stringify(finaltransaction)}`)
  //         transaction = {pDate: Data, pOperatiune: DetaliiTranzactie !== undefined ? DetaliiTranzactie.trim() : DetaliiTranzactie, pDebit: Debit_Incasare, pCredit: Credit_Plata}
  //         rowtransaction=0
  //         return finaltransaction
  //         }).filter(item => item.final)
  //         .map(item => {
  //           // console.log(`[importextrasconts.table.getINGimpinputs.map] - item: ${JSON.stringify(item)}, `)
  //           const arrdate = item.pDate.split(' ')
  //           // console.log(`[importextrasconts.table.getINGimpinputs.map] - arrdate: ${JSON.stringify(arrdate)}, `)
  //           // const pDate = new Date(arrdate[2], months.findIndex(item => item === arrdate[1]), arrdate[0]) 
  //           const pDate = `${arrdate[2]}-${months.findIndex(item => item === arrdate[1])+1}-${arrdate[0]}) `
  //           // console.log(`[importextrasconts.table.getINGimpinputs.map] - arrdate: ${JSON.stringify(arrdate)}, pDate: ${pDate}`)
  //           return {input: {
  //           pCollectionid: parseInt(collectionid), pDate: pDate, 
  //           pOperatiune: item.pOperatiune !== undefined ? item.pOperatiune : nullstring, 
  //           pDebit: item.pDebit !== undefined ? parseFloat(item.pDebit.replace('.', '').replace(',', '.')) : parseFloat(nullint), 
  //           pCredit: item.pCredit !== undefined ? parseFloat(item.pCredit.replace('.', '').replace(',', '.')) : parseFloat(nullint), 
  //           pField1: item.pField1 !== undefined ? item.pField1 : nullstring, 
  //           pField2: item.pField2 !== undefined ? item.pField2 : nullstring, 
  //           pField3: item.pField3 !== undefined ? item.pField3 : nullstring, 
  //           pField4: item.pField4 !== undefined ? item.pField4 : nullstring, 
  //           pField5: item.pField5 !== undefined ? item.pField5 : nullstring, 
  //           pField6: item.pField6 !== undefined ? item.pField6 : nullstring
  //         }}
  //       })
  //     default:
  //   }     
  // }


  const ondbDragandDropdb = () => {
    const input = {input: {pExtrascontid: parseInt(nullint)},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  }

  const ondbAdd = (item) => {
    //console.log(`[importextrasconts.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pDate: formatdate(item.date), 
      pOperatiune: item.operatiune !== undefined && item.operatiune !== '' ? item.operatiune : nullstring,
      pDebit: item.debit !== undefined && item.debit !== '' ? parseFloat(item.debit) : parseInt(nullint), 
      pCredit: item.credit !== undefined && item.credit !== '' ? parseFloat(item.credit) : parseInt(nullint),       
      pBeneficiar: item.beneficiar !== undefined && item.beneficiar !== '' ? item.beneficiar : nullstring,
      pOrdonator: item.ordonator !== undefined && item.ordonator !== '' ? item.ordonator : nullstring,
      pContul: item.contul !== undefined && item.contul !== '' ? item.contul : nullstring,
      pBanca: item.banca !== undefined && item.banca !== '' ? item.banca : nullstring,
      pDetalii: item.detalii !== undefined && item.detalii !== '' ? item.detalii : nullstring,
      pReferinta: item.referinta !== undefined && item.referinta !== '' ? item.referinta : nullstring,
      pDetalii2: item.detalii2 !== undefined && item.detalii2 !== '' ? item.detalii2 : nullstring,
      pCollectionid: parseInt(item.collectionid),
      pOperationcodeid: item.operationcodeid !== undefined && item.operationcodeid !== '' ? parseInt(item.operationcodeid) : parseInt(nullint), 
      pMngmentoperationdetailid: item.mngmentoperationdetailid !== undefined && item.mngmentoperationdetailid !== '' ? parseInt(item.mngmentoperationdetailid) : parseInt(nullint), 
    },}
    // console.log(`[importextrasconts.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[importextrasconts.table.ondbUpd] - db Upd: {extrascontid, columnId, value}: ${JSON.stringify({extrascontid: field.extrascontid, columnId: field.columnId, value: field.value})},`)
    // console.log(`[importextrasconts.table.ondbUpd] - db Upd: {field}: ${JSON.stringify(field)},`)
    // console.log(`[importextrasconts.table.ondbUpd] - db Upd: {mngmentoperationid, columnId, value}: ${JSON.stringify({mngmentoperationid: field.mngmentoperationid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId === 'selyear'  || field.columnId === 'selmonth') return
    if(field.columnId === 'selyearid') {
      params.setselyearmng(field.value.toString())
      params.setselmonthmng({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'selmonthid') {
      params.setselmonthmng({selmonthid: field.value.toString(), monthname: months[ field.value]})
      const input = {input: {  pKeyfield: 'monthmng', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    // else if(field.procesat  === true && field.columnId !== 'procesat') 
    // else if(field.finalizat  === true && field.columnId !== 'finalizat') 
    //     ondbRefresh()
    else if(field.columnId  !== 'collection' && field.columnId  !== 'operationcode'){
      const input = {input: {  pKeyfield: field.columnId, 
        pValue: (field.columnId === 'ndocument' || field.columnId === 'debit' || field.columnId === 'credit') && field.value === '' ? nullint.toString() 
          // : field.columnId === 'procesat' ? field.value.toString() : field.value, 
          : field.columnId === 'finalizat' ? field.value.toString() : field.value, 
        pExtrascontid: parseInt(field.extrascontid)},}
      // console.log(`[importextrasconts.table.ondbUpd]- extrascontid: ${field.extrascontid}, input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))  
    } 
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {extrascontid}} = selectedFlatRow
      const input = {input: {pExtrascontid: parseInt(extrascontid)},}
      // console.log(`[importextrasconts.table.ondbDel]- extrascontid: ${extrascontid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  const ondbDelAll = () => {
    const input = {input: {pYear: parseInt(params.selyear), pMonth: parseInt(params.selmonth.selmonthid), },}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  }
  const ondbRefresh = () => { 
    // console.log(`[importextrasconts.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }
  const ondbNext = () => { 
    const {selmonthmng: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const nextmonth = month < 12 ? month + 1 : 1
    // params.setselmonth(nextmonth.toString())
    params.setselmonthmng({selmonthid: nextmonth, monthname: months[ nextmonth+1]})
    const input = {input: {  pKeyfield: 'monthmng', pValue: nextmonth.toString(), pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  

    if(month >= 12) { // anul urmator
      const {selyearmng: selyear} = params
      const nextyear = parseInt(selyear) + 1
      params.setselyear(nextyear.toString())
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', pValue: nextyear.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbNext] - nextyear: ${nextyear}`)
    }

  }
  const ondbPrev = () => { 
    const {selmonthmng: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const {selyearmng: selyear} = params
    if((selyear === 2021 && month > 1) || selyear > 2021){
      const prevmonth = month > 1 ? month - 1 : 12
      // params.setselmonth(prevmonth.toString())
      params.setselmonthmng({selmonthid: prevmonth, monthname: months[ prevmonth+1]})
      const input = {input: {  pKeyfield: 'monthmng', pValue: prevmonth.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  
    
      if(month === 1) { // anul precedent
        const prevyear = parseInt(selyear) - 1
        params.setselyear(prevyear.toString())
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: { pKeyfield: 'yearmng', pValue: prevyear.toString(), pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbNext] - prevyear: ${prevyear}`)
      }
    }
  }


  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[importextrasconts.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[importextrasconts.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isresponsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false
  const visible = params.subcompomponent === undefined
  // console.log(`[importextrasconts.table] - isresponsabilmanagement: ${isresponsabilmanagement}, rols: ${JSON.stringify(rols)}`) 

  const {selmonthmng: {monthname}} = params

  const issearch = true
  const isconfigreport = visible && isresponsabilmanagement

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>extras cont</h 3> */}
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title(params.selyearmng, monthname), groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(), onrowSelected,
          EditableCell: EditableCell, editabledata: isresponsabilmanagement && appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilmanagement ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilmanagement) ? AddDialog : undefined, 
          searchDialogComponent: issearch ? {AddDialog, onSearch, searchAddDialog: searchAddDialog, 
            // handlenextPage: handlenextSearchPage, handleprevPage: handleprevSearchPage 
          } : undefined, 
          configreportDialogComponent: isconfigreport ? {AddDialog, onConfigReport, configreportAddDialog: configreportAddDialog, 
            // handlenextPage: handlenextReportConfigPage, handleprevPage: handleprevReportConfigPage 
          } : undefined, 
          importxlsfields: isresponsabilmanagement ? {Readxlsfile, ondbExcellAdd: ondbExcellAdd, title:'xls »', n, header:0} : undefined, 
          importxlsextrasconts: isresponsabilmanagement ? {Readxlsfile, ondbExcellAdd: ondbextrascontINGBankExcellAdd, title:'ING.xls»', n, header:0} : undefined, 
          importxlsPOSconts: isresponsabilmanagement ? {Readxlsfile, ondbExcellAdd: ondbPOSINGBankExcellAdd, title:'POS.xls»', n, header:5} : undefined, 
          exportxlsfields: isresponsabilmanagement ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isresponsabilmanagement ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbNext, ondbPrev, ondbDelAll, ondbDragandDropdb, 
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: isresponsabilmanagement && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: true, visibledragDB: true, visibleRefresh: true, visiblenexprev: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[15]-Extrasconts', wsnames: wsnames}} 
          headers={newxlsheaders} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
