// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2, dbOptions3, }) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'date', Header: 'Data', accessor: 'date', width: 20, type: 'date',},
        { id: 'codeoperationcode', Header: 'Code', accessor: 'codeoperationcode', width: 30, type: 'text', align: 'left', },
        { id: 'code', Header: 'Venituri/Cheltuieli', accessor: 'code', width: 70, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '25rem', text: 'Alege cod operatiune..'},},
        { id: 'description', Header: 'descriere', accessor: 'description', width: 50, type: 'text',},
        { id: 'collection', Header: 'fel', accessor: 'collection', width: 15, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '12.3rem', text: 'Alege tip operatiune ..'},},

        // { id: 'ndocument', Header: 'nr. act casa', accessor: 'ndocument', width: 20, type: 'text',align: 'right', },
        { id: 'debit', Header: 'plata', accessor: 'debit', width: 15, type: 'number', align: 'right', },
        { id: 'credit', Header: 'incasare', accessor: 'credit', width: 15, type: 'number', align: 'right', },
        { id: 'operatiune', Header: 'operatiune', accessor: 'operatiune', width: 50, type: 'text', align: 'right',},
        { id: 'value', Header: 'value', accessor: 'value', width: 15, type: 'number', align: 'right', },
        { id: 'symbolcollection', Header: 'symbolcollection', accessor: 'symbolcollection', width: 30, type: 'text',},
        // { id: 'finalizat', Header: 'finalizat', accessor: 'finalizat', width: 15, radiobuttondanu: true, type: '', align: 'left',},
        ]
    },
    {
      Header: 'sold registru casa',
      columns: [
        { id: 'houseregisterprevious', Header: 'houseregisterprevious', accessor: 'houseregisterprevious', width: 15, type: 'date',},
        { id: 'houseregistercurrent', Header: 'houseregistercurrent', accessor: 'houseregistercurrent', width: 15, type: 'date',},
      ]
    },
    {
      Header: 'code',
      columns: [
        { id: 'nameoperationcode', Header: 'nameoperationcode', accessor: 'nameoperationcode', width: 10, type: 'number',align: 'right', },
        { id: 'symboloperationcode', Header: 'symboloperationcode', accessor: 'symboloperationcode', width: 30, type: 'text', align: 'left', },
        { id: 'norderoperationcode', Header: 'norderoperationcode', accessor: 'norderoperationcode', width: 30, type: 'text', align: 'left', },
        ]
    },
    {
      Header: 'operationkindof',
      columns: [
        { id: 'kindof', Header: 'kindof', accessor: 'kindof', width: 15, type: 'text',},
        { id: 'nameoperationkindof', Header: 'nameoperationkindof', accessor: 'nameoperationkindof', width: 30, type: 'text',},
        { id: 'symboloperationkindof', Header: 'symboloperationkindof', accessor: 'symboloperationkindof', width: 30, type: 'text',},
        { id: 'norderoperationkindof', Header: 'norderoperationkindof', accessor: 'norderoperationkindof', width: 30, type: 'text',},
        { id: 'operationkindof', Header: 'operationkindof', accessor: 'operationkindof', width: 30, type: 'text',},
      ]
    },
    {
      Header: 'kindof',
      columns: [
        { id: 'namekindofs', Header: 'namekindofs', accessor: 'namekindofs', width: 30, type: 'text',},
        { id: 'symbolkindofs', Header: 'symbolkindofs', accessor: 'symbolkindofs', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'collection',
      columns: [
        { id: 'nameRocollection', Header: 'nameRocollection', accessor: 'nameRocollection', width: 30, type: 'text',},
        { id: 'nameEncollection', Header: 'nameEncollection', accessor: 'nameEncollection', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'luna,anul', columns: [     
      { id: 'year', Header: 'year', accessor: 'year', width: 30, type: 'number', align: 'right',},
      { id: 'month', Header: 'month', accessor: 'month', width: 30, type: 'number', align: 'right',},
      { id: 'monthname', Header: 'monthname', accessor: 'monthname', width: 30, type: 'number', align: 'right',},
      { id: 'shortmonthname', Header: 'shortmonthname', accessor: 'shortmonthname', width: 30, type: 'number', align: 'right',},
    ],
  },
    {
      Header: 'db info', columns: [     
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 10, type: 'number', align: 'right',},
      { id: 'mngmentoperationdetailid', Header: 'mngmentoperationdetailid', accessor: 'mngmentoperationdetailid', width: 30, type: 'number', align: 'right',  },
      { id: 'mngmentoperationid', Header: 'mngmentoperationid', accessor: 'mngmentoperationid', width: 30, type: 'number', align: 'right',  },
      { id: 'operationcodeid', Header: 'operationcodeid', accessor: 'operationcodeid', width: 30, type: 'number', align: 'right',},
      { id: 'operationkindofid', Header: 'operationkindofid', accessor: 'operationkindofid', width: 30, type: 'number', align: 'right',},
      { id: 'kindofid', Header: 'kindofid', accessor: 'kindofid', width: 30, type: 'number', align: 'right',},
      { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'number', align: 'right',},
      { id: 'businessid', Header: 'businessid', accessor: 'businessid', width: 30, type: 'number', align: 'right',},
    ],
  },
])




