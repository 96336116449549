
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'extrascont', text: 'extrascont', groupcol: [  'beneficiar', 'contul', 'collection', 'finalizat', ] }, 
    {label: 'extrascontdetail', text: 'extrascont detail', groupcol: [  'banca', 'detalii', 'referinta', 'detalii2', 'ordonator', ] }, 
    {label: 'management', text: 'management', groupcol: [ 'value', 'description', 'ndocument', ] }, 
    {label: 'operationcode', text: 'operationcode', groupcol: [ 'nameoperationcode',   'symboloperationcode', 'codeoperationcode', 'norderoperationcode',  ] }, 
    {label: 'operationkindof', text: 'operationkindof', groupcol: [ 'nameoperationkindof', 'symboloperationkindof', 'norderoperationkindof', ] }, 
    {label: 'kindof', text: 'kindof', groupcol: [ 'namekindofs', 'symbolkindofs', ] }, 
    {label: 'collection', text: 'collection', groupcol: [ 'nameEncollection', 'nameRocollection', 'symbolcollection', 'nameoperationkindof', 'norderoperationkindof', ] }, 
    {label: 'year-month', text: 'luna,anul', groupcol: [ 'year', 'month', 'monthname', 'shortmonthname', ] }, 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'extrascontid', 'operationcodeid', 'operationkindofid', 'kindofid', 'collectionid', 'mngmentoperationdetailid', ]},
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  // 'date', 'operationcode', 'operatiune', 'debit', 'credit',