
    const backgroundColorHeader = 'white' //'aliceblue'
    const backgroundColorBody = 'white' //'linen'
    const defaultpdffontsize= '10'

    const cellheaderborder = {borderTopWidth: 1, borderLeftWidth: 0, borderBottomWidth: 1, borderRightWidth: 1, } 
    const headerstyle = {textAlign:'center', fontSize: defaultpdffontsize, backgroundColor: backgroundColorHeader, ...cellheaderborder}

    // const bodytableCell = { 
    //     marginTop: 1, //4, 
    //     marginLeft: 1, //4, 
    //     marginRight: 4, //12, 
    //     //margin: "auto", 
    //     color:'black',
    //     // borderTop: 0, // borderTopColor: 'red'
    //     // justifyContent: 'center',
    //     // fontweight: 'bolder',
    //     // textAlign: 'center'
    // }
    const cellbodyborder = {borderTopWidth: 0, borderLeftWidth: 0, borderBottomWidth: 1, borderRightWidth: 1, } 
    const cellbodystyle = {textAlign:'right', fontSize: defaultpdffontsize, backgroundColor: backgroundColorBody, ...cellbodyborder}

    const styleLeft = ({headertableCell, bodytableCell}) => {
        return {
        headerstyles: [{...headerstyle, borderLeftWidth: 1, }, ], headertableCell: headertableCell, 
        bodystyles: [{...cellbodystyle, borderLeftWidth: 1, },], bodytableCell: bodytableCell, 
    }}

    const styleAlignLeft = ({headertableCell, bodytableCell}) => {
        return {
        headerstyles: [{...headerstyle, }, ], headertableCell: headertableCell,
        bodystyles: [{...cellbodystyle, textAlign:'left', },], bodytableCell: bodytableCell,
    }}
    const styleAlignCenter = ({headertableCell, bodytableCell}) => {
        return {
        headerstyles: [{...headerstyle, }, ], headertableCell: headertableCell,
        bodystyles: [{...cellbodystyle, textAlign:'center', },], bodytableCell: bodytableCell,
    }}
    const styleAlignRight = ({headertableCell, bodytableCell}) => {
        return {
        headerstyles: [{...headerstyle, }, ], headertableCell: headertableCell,
        bodystyles: [{...cellbodystyle, },], bodytableCell: bodytableCell,
    }}

    export {styleLeft, styleAlignLeft, styleAlignCenter, styleAlignRight}