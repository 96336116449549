/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VmngmentoperationmonthCondition = {|
  year?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  pMonth: number,
  pYear: number,
  conditionyear?: ?VmngmentoperationmonthCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $pMonth: Int!
  $pYear: Int!
  $conditionyear: VmngmentoperationmonthCondition
) {
  ...page_dbdata_3jc8cv
}

fragment page_dbdata_3jc8cv on Query {
  vfrincasaris(pMonth: $pMonth, pYear: $pYear) {
    edges {
      node {
        month
        year
        monthname
        code
        symbol
        collection
        percent
        nbr
        collectionid
        zi01
        zi02
        zi03
        zi04
        zi05
        zi06
        zi07
        zi08
        zi09
        zi10
        zi11
        zi12
        zi13
        zi14
        zi15
        zi16
        zi17
        zi18
        zi19
        zi20
        zi21
        zi22
        zi23
        zi24
        zi25
        zi26
        zi27
        zi28
        zi29
        zi30
        zi31
        pzi01
        pzi02
        pzi03
        pzi04
        pzi05
        pzi06
        pzi07
        pzi08
        pzi09
        pzi10
        pzi11
        pzi12
        pzi13
        pzi14
        pzi15
        pzi16
        pzi17
        pzi18
        pzi19
        pzi20
        pzi21
        pzi22
        pzi23
        pzi24
        pzi25
        pzi26
        pzi27
        pzi28
        pzi29
        pzi30
        pzi31
        total
        totalcode
        totaltherapist
        totaltherapistcode
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
    edges {
      node {
        month
        year
        monthname
        shortmonthname
      }
    }
  }
  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
    edges {
      node {
        monthkindofid
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pMonth"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pYear"
},
v4 = {
  "kind": "Variable",
  "name": "pMonth",
  "variableName": "pMonth"
},
v5 = {
  "kind": "Variable",
  "name": "pYear",
  "variableName": "pYear"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          },
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "concreteType": "VfrincasaristypesConnection",
        "kind": "LinkedField",
        "name": "vfrincasaris",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VfrincasaristypesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vfrincasaristype",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nbr",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collectionid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi01",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi02",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi03",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi04",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi05",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi06",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi07",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi08",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi09",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi10",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi11",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi12",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi13",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi14",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi15",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi16",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi17",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi18",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi19",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi20",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi21",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi22",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi23",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi24",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi25",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi26",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi27",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi28",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi29",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi30",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zi31",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi01",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi02",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi03",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi04",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi05",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi06",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi07",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi08",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi09",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi10",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi11",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi12",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi13",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi14",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi15",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi16",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi17",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi18",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi19",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi20",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi21",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi22",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi23",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi24",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi25",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi26",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi27",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi28",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi29",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi30",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pzi31",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totaltherapist",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totaltherapistcode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTH_DESC"
          }
        ],
        "concreteType": "VmngmentoperationmonthsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortmonthname",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTHKINDOFID_ASC"
          }
        ],
        "concreteType": "VmonthkindofsConnection",
        "kind": "LinkedField",
        "name": "vmonthkindofs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmonthkindofsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmonthkindof",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthkindofid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmonthkindofs(orderBy:\"MONTHKINDOFID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "ee7ba6c186be2edaa835585e1784ae91",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $pMonth: Int!\n  $pYear: Int!\n  $conditionyear: VmngmentoperationmonthCondition\n) {\n  ...page_dbdata_3jc8cv\n}\n\nfragment page_dbdata_3jc8cv on Query {\n  vfrincasaris(pMonth: $pMonth, pYear: $pYear) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        code\n        symbol\n        collection\n        percent\n        nbr\n        collectionid\n        zi01\n        zi02\n        zi03\n        zi04\n        zi05\n        zi06\n        zi07\n        zi08\n        zi09\n        zi10\n        zi11\n        zi12\n        zi13\n        zi14\n        zi15\n        zi16\n        zi17\n        zi18\n        zi19\n        zi20\n        zi21\n        zi22\n        zi23\n        zi24\n        zi25\n        zi26\n        zi27\n        zi28\n        zi29\n        zi30\n        zi31\n        pzi01\n        pzi02\n        pzi03\n        pzi04\n        pzi05\n        pzi06\n        pzi07\n        pzi08\n        pzi09\n        pzi10\n        pzi11\n        pzi12\n        pzi13\n        pzi14\n        pzi15\n        pzi16\n        pzi17\n        pzi18\n        pzi19\n        pzi20\n        pzi21\n        pzi22\n        pzi23\n        pzi24\n        pzi25\n        pzi26\n        pzi27\n        pzi28\n        pzi29\n        pzi30\n        pzi31\n        total\n        totalcode\n        totaltherapist\n        totaltherapistcode\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        shortmonthname\n      }\n    }\n  }\n  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {\n    edges {\n      node {\n        monthkindofid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bbda5f35aa7a996ec88a2562d66203b4';

module.exports = node;
