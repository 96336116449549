/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type utilizatorlogin_dbdata$ref: FragmentReference;
declare export opaque type utilizatorlogin_dbdata$fragmentType: utilizatorlogin_dbdata$ref;
export type utilizatorlogin_dbdata = {|
  +loginutilizators: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +username: string
      |}
    |}>
  |},
  +vutilizators: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +username: ?string,
        +utilizatorid: ?any,
      |}
    |}>
  |},
  +vutilizatorapps: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +appid: ?any,
        +app: ?string,
        +link: ?string,
      |}
    |}>
  |},
  +vutilizatorapprols: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +username: ?string,
        +appid: ?any,
        +rolid: ?any,
      |}
    |}>
  |},
  +vutilizatortherapists: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +utilizatorid: ?any,
        +username: ?string,
        +therapistid: ?any,
        +lastname: ?string,
        +firstname: ?string,
      |}
    |}>
  |},
  +vappmenus: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +denumire: ?string
      |}
    |}>
  |},
  +vcurrentmonths: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +currentmonthid: ?any,
        +year: ?number,
        +month: ?number,
        +week: ?number,
        +day: ?number,
        +yearmng: ?number,
        +monthmng: ?number,
        +weekmng: ?number,
        +daymng: ?number,
      |}
    |}>
  |},
  +$refType: utilizatorlogin_dbdata$ref,
|};
export type utilizatorlogin_dbdata$data = utilizatorlogin_dbdata;
export type utilizatorlogin_dbdata$key = {
  +$data?: utilizatorlogin_dbdata$data,
  +$fragmentRefs: utilizatorlogin_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "username",
  "variableName": "pUsernamermodelat"
},
v2 = [
  {
    "fields": [
      (v1/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utilizatorid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appid",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "appid",
  "variableName": "pAppid"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pAppid"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "pParola"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "pUsername"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "pUsernamermodelat"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "utilizatorlogin_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "pParola",
          "variableName": "pParola"
        },
        {
          "kind": "Variable",
          "name": "pUsername",
          "variableName": "pUsername"
        }
      ],
      "concreteType": "UtilizatorsConnection",
      "kind": "LinkedField",
      "name": "loginutilizators",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UtilizatorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Utilizator",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "VutilizatorsConnection",
      "kind": "LinkedField",
      "name": "vutilizators",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizator",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "VutilizatorappsConnection",
      "kind": "LinkedField",
      "name": "vutilizatorapps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatorappsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizatorapp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "app",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "link",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            (v5/*: any*/),
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "VutilizatorapprolsConnection",
      "kind": "LinkedField",
      "name": "vutilizatorapprols",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatorapprolsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizatorapprol",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rolid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "VutilizatortherapistsConnection",
      "kind": "LinkedField",
      "name": "vutilizatortherapists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatortherapistsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizatortherapist",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            (v5/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "condition"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ORDONARE_ASC"
        }
      ],
      "concreteType": "VappmenusConnection",
      "kind": "LinkedField",
      "name": "vappmenus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VappmenusEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vappmenu",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "denumire",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VcurrentmonthsConnection",
      "kind": "LinkedField",
      "name": "vcurrentmonths",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcurrentmonthsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcurrentmonth",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currentmonthid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "year",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "week",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "day",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "yearmng",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthmng",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "weekmng",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "daymng",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd28fc0e23579ce92cbf5a0159cb1eee0';

module.exports = node;
