// import formatdateymd from "../../../uioscar/formatdateymd"

export default () => {
  const columnDatas = [
    { header: 'year', accessor: 'year', prefix: 'year: ', show: 'true', type: 'number', },
  ]
  // const nextpagescolumnOperatiunes = [
  //   [
  //     {header: 'unitateid', accessor: 'unitateid', type: 'text', show: 'true', tree:true, },
  //   ],
  // ]

  return ({
  SearchDialogtitle: ['An', 'An'],  
  columns: columnDatas, //nextpagescolumns: raport === constraport ? nextpagescolumnOperatiunes : [],
  initialdata: {
    year: new Date().getFullYear(),
    // unitateid: '1',
    subRows: undefined,
    },
  })
}
