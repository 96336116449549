import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddsubscriptionInput!)
{
  fnAddsubscription(input: $input) {
    subscription {
      finalizat
      mofntherapysession
      name
      nbmonths
      ntherapysession
      ntherapysession1
      subscriptionid
      therapysessionid
      time
      time2Id
      timeid
    }
  }
}
`
