import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdcollectionInput!)
{
  fnUpdcollection(input: $input) {
    collection {
      collectionid
      nameEn
      nameRo
      symbol
    }  }
}
`

