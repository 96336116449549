
export default [
    [
        {field: 'timeid', value: 'timeid'},
        {field: 'symbol', value: 'symbol'},
        {field: 'minutes', value: 'minutes'},
        {field: 'units', value: 'units'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

