
export default ({therapistid, dbOptions, dbOptions1, }) => {
  // console.log(`[dbTherapistpatients.initiall] - dbOptions: ${JSON.stringify(dbOptions)}`)
  // console.log(`[dbTherapistpatients.initiall] - dbOptions1: ${JSON.stringify(dbOptions1)}`)
  return({
  AddDialogtitle: ['Adauga pacient terapeut', ''],
    columns: [
      {header: 'therapist', accessor: 'therapist', accessorid: 'therapistid', type: '', show: therapistid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'patient', accessor: 'patient', accessorid: 'patientid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege pacient ..',}},
    ],
    initialdata: {
      therapistid: '',
      patientid: '',
      subRows: undefined,
    },    
  })}
