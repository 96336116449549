
export default  [
    {label: 'therapyprices', text: 'therapyprices', groupcol: [ 'therapy', 'therapy2', 'therapytype', 'dtime', 'dtime2', 'time', 'price', 'norder', 'activ', ] }, 
    {label: 'description', text: 'description', groupcol: [ 'description', ] }, 
    {label: 'therapy1', text: 'therapy 1', groupcol: ['nameRotherapy', 'nameEntherapy', 'symboltherapy', 'nordertherapy', 'descriptiontherapy', ]},
    {label: 'therapy2', text: 'therapy 2', groupcol: ['nameRo2Therapy', 'nameEn2Therapy', 'symbol2Therapy', 'norder2Therapy', 'description2Therapy', ]},
    {label: 'therapytype', text: 'therapytype', groupcol: [ 'nameRotherapytype', 'nameEntherapytype',  'symboltherapytype', 'ntherapysessiontherapytype', 'ntherapysession1Therapytype', ]},
    {label: 'time1', text: 'time 1', groupcol: [ 'symboltime', 'minutestime', 'unitstime',  ]},
    {label: 'time2', text: 'time 2', groupcol: [ 'symboltime2', 'minutestime2', 'unitstime2',  ]},
    {label: 'dbinfo', text: 'db info', groupcol: ['therapypriceid', 'therapyid', 'therapy2Id', 'therapytypeid', 'timeid', 'time2Id',  ]},
    {label: 'toate', text: 'toate', groupcol: []},
  ]
