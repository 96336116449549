import React from 'react'
// import HelpTableStyle from '../../../styles/styles/helptable'
import Icon from '@mdi/react'
import { mdiCheckboxBlankOutline, mdiDelete} from '@mdi/js';

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D


export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>calendar saptamanal terapeut - vizualizeaza sedintele terapiilor din ziua precedenta, ziua curenta si zilele urmatoare - (prin raportare la CURRENT_DATE) </li>
            <ul>
                <li>vor fi vizualizate sedintele pentru fiecare zi, sala si calendar cu o rezolutie de 15 min prin vizualizare cod terapeut in celulele corespunzatoare. Pentru sedintele unde nu s-a completat terapeut in celula se va vizualiza <b>-?</b></li>
                <li>se va vizualiza in primul rand al fiecarei zile(valoarea 'terapeut' in coloana fel) sedintele terapeutilor</li>
                <li>se va vizualiza in primul rand al fiecarei zile(valoarea 'lipsa sala' in coloana fel) sedintele pentru care nu sau completat sala </li>
                <li>Nu este permisa actualizare date din interfata</li>
            </ul>
            <li>click [<b>+</b>]adauga sedinta noua</li>
            <li>click pe buton <Icon path={mdiCheckboxBlankOutline} title="select" size={1} horizontal vertical rotate={180} color="black"/> urmat de click pe buton <Icon path={mdiDelete} title="delete all" size={1} horizontal vertical rotate={180} color="black"/> permite selectia doar a serviciilor terapeutice din ziua in acest fel selectata </li>
            <li>este posibil export in fisier xls <b>xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminApp</b></li>
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vcalendarsessions(condition: $conditioncalendarsessions)  (norder, date, terapeut, therapistid, therapistsymbol, roomid, calendarid, symbolroom, nameRocalendar, week, dow, doy, day, h0700, h0715, h0730, h0745, h0800, h0815, h0830, h0845, h0900, h0915, h0930, h0945, h1000, h1015, h1030, h1045, h1100, h1115, h1130, h1145, h1200, h1215, h1230, h1245, h1300, h1315, h1330, h1345, h1400, h1415, h1430, h1445, h1500, h1515, h1530, h1545, h1600, h1615, h1630, h1645, h1700, h1715, h1730, h1745, h1800, h1815, h1830, h1845, h1900, h1915, h1930, h1945, h2000, h2015, h2030, h2045, h2100, h2115, h2130, h2145)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vpatients(orderBy: PATIENTID_ASC)  (patientid, firstname, lastname, extensive, birthyear, weight, height, phone, email, CNP)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>config, mainview</td>
                            <td className='td'>vtherapists (therapistid, firstname, lastname, extensive, symbol, code, phone, email, CNP, percent)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vhours (hourId, name, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>config, mainview</td>
                            <td className='td'>vtherapisttherapyprices(orderBy: THERAPISTTHERAPYPRICEID_ASC, condition: $condition) (therapisttherapypriceid, therapistid, firstname, lastname, extensive, symbol, code, phone, email, cnp, percenttherapists, therapypriceid, therapyid, therapy2Id, therapytypeid, timeid, time2Id, time, price, nametherapyprice, descriptiontherapyprices, nordertherapyprices, percent)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vcalendars (calendarId, name_ro, name_en, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vrooms (roomId, name_ro, name_en, symbol, etaj)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vbusinesscollections(orderBy: COLLECTIONID_ASC) (collectionid, name_ro, name_en, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapies (therapyid, name_ro, name_en, symbol, shortsymbol, norder, description)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addtherapysession(p_patientId, p_therapistId, p_date, p_fromid, p_toid, p_therapypriceId, p_therapyId, p_therapy2Id, p_therapytypeId, p_percent, p_calendarid, p_roomid, p_patientNotes, p_therapistNotes, p_value, p_collectionId, p_price)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </>
            : <></>
            }
        </ul>
    </div>


