/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdpatientInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pPatientid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdpatientInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdpatient: ?{|
    +patient: ?{|
      +birthyear: ?number,
      +cnp: ?string,
      +email: ?string,
      +extensive: ?string,
      +firstname: string,
      +height: ?number,
      +lastname: string,
      +patientid: any,
      +phone: ?string,
      +weight: ?number,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdpatientInput!
) {
  fnUpdpatient(input: $input) {
    patient {
      birthyear
      cnp
      email
      extensive
      firstname
      height
      lastname
      patientid
      phone
      weight
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdpatientPayload",
    "kind": "LinkedField",
    "name": "fnUpdpatient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Patient",
        "kind": "LinkedField",
        "name": "patient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthyear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6777d87549689abded03bd86405b009f",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdpatientInput!\n) {\n  fnUpdpatient(input: $input) {\n    patient {\n      birthyear\n      cnp\n      email\n      extensive\n      firstname\n      height\n      lastname\n      patientid\n      phone\n      weight\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5d2d744112ae377572ade5bae582c15';

module.exports = node;
