
export default [
    {
        nrcrt: {field: 'nrcrt', values: ['', '', 'nrcrt'], width: 5, isLastRow : false},
        utilizator: {field: 'utilizator', values: ['', '', 'utilizator'], width: 12, isLastRow : false},
        app: {field: 'app', values: ['', '', 'app'], width: 13, isLastRow : false},
        responsabilbusiness: {field: 'responsabilbusiness', values: ['', '', 'responsabilbusiness'], width: 12, isLastRow : false},    
        responsabilmanagement: {field: 'responsabilmanagement', values: ['', '', 'responsabilmanagement'], width: 10, isLastRow : false},    
        contabil: {field: 'contabil', values: ['', '', 'contabil'], width: 12, isLastRow : false},    
        terapeut: {field: 'terapeut', values: ['', '', 'terapeut'], width: 12, isLastRow : false},    
        superadmin: {field: 'superadmin', values: ['', '', 'superadmin'], width: 12, isLastRow : false},    
        adminapp: {field: 'adminapp', values: ['', '', 'adminapp'], width: 12, isLastRow : false},    
    },
]

