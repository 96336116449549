
export default [
    {
        subscriptionid: {field: 'subscriptionid', values: ['', '', 'subscriptionid'], width: 10, isLastRow : false},
        therapyid: {field: 'therapyid', values: ['', '', 'therapyid'], width: 10, isLastRow : false},
        patientid: {field: 'patient', values: ['', '', 'patient'], width: 10, isLastRow : false},
        // firstnamepatient: {field: 'firstnamepatient', values: ['', '', 'firstnamepatient'], width: 10, isLastRow : false},
        // lastnamepatient: {field: 'lastnamepatient', values: ['', '', 'lastnamepatient'], width: 10, isLastRow : false},
        // extensivepatient: {field: 'extensivepatient', values: ['', '', 'extensivepatient'], width: 10, isLastRow : false},
        // birthyearpatient: {field: 'birthyearpatient', values: ['', '', 'birthyearpatient'], width: 10, isLastRow : false},
        // weightpatient: {field: 'weightpatient', values: ['', '', 'weightpatient'], width: 10, isLastRow : false},
        // heightpatient: {field: 'heightpatient', values: ['', '', 'heightpatient'], width: 10, isLastRow : false},
        // phonepatient: {field: 'phonepatient', values: ['', '', 'phonepatient'], width: 10, isLastRow : false},
        // emailpatient: {field: 'emailpatient', values: ['', '', 'emailpatient'], width: 10, isLastRow : false},
        // cnppatient: {field: 'cnppatient', values: ['', '', 'cnppatient'], width: 10, isLastRow : false},
        date: {field: 'date', values: ['', '', 'date'], width: 10, isLastRow : false},
        nameRotherapy: {field: 'nameRotherapy', values: ['', '', 'nameRotherapy'], width: 30, isLastRow : false},
        ntherapysession: {field: 'ntherapysession', values: ['', '', 'ntherapysession'], width: 10, isLastRow : false},
        mofntherapysession: {field: 'mofntherapysession', values: ['', '', 'mofntherapysession'], width: 10, isLastRow : false},
        nbmonths: {field: 'nbmonths', values: ['', '', 'nbmonths'], width: 10, isLastRow : false},
    }, 
]

// subscriptionid
// therapysessionid
// date
// patientid
// firstnamepatient
// lastnamepatient
// extensivepatient
// birthyearpatient
// weightpatient
// heightpatient
// phonepatient
// emailpatient
// cnppatient
// therapistid
// firstname
// lastname
// extensive
// symbol
// code
// phone
// email
// cnp
// percenttherapists
// therapypriceid
// nametherapyprice
// therapyid
// nameRotherapy
// nameEntherapy
// symboltherapy
// nordertherapy
// descriptiontherapy
// therapy2Id
// nameRo2Therapy
// nameEn2Therapy
// symbol2Therapy
// norder2Therapy
// description2Therapy
// therapytypeid
// nameRotherapytype
// nameEntherapytype
// symboltherapytype
// ntherapysessiontherapytype
// ntherapysession1Therapytype
// timeid
// symboltime
// minutestime
// unitstime
// time2Id
// symboltime2
// minutestime2
// unitstime2
// time
// name
// collectionid
// nameRocollection
// nameEncollection
// symbolcollection
// ntherapysession
// ntherapysession1
// mofntherapysession
// nbmonths
// finalizat
