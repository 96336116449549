import React from 'react'

export default () => 
<div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
    <ul>
        <li>administrare page</li>
        <li>vizualizeaza lista serviciilor de administrare organizata pe patru coloane</li>
        <ul>
            <li>coloanele 1, 2 si 3 - servicii specifice aplicatie</li>
            <li>coloana 4 - servicii specifice utilizatori</li>
        </ul>
        <li>click link <b>help</b> pentru a ascunde help text </li>
    </ul>
</div>