/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddpatientInput = {|
  clientMutationId?: ?string,
  pFirstname: string,
  pLastname: string,
  pTherapistid: number,
  pExtensive: string,
  pBirthyear: number,
  pWeight: number,
  pHeight: number,
  pPhone: string,
  pEmail: string,
  pCnp: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddpatientInput
|};
export type mutationAddMutationResponse = {|
  +fnAddpatient: ?{|
    +patient: ?{|
      +birthyear: ?number,
      +cnp: ?string,
      +email: ?string,
      +extensive: ?string,
      +firstname: string,
      +height: ?number,
      +lastname: string,
      +patientid: any,
      +phone: ?string,
      +weight: ?number,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddpatientInput!
) {
  fnAddpatient(input: $input) {
    patient {
      birthyear
      cnp
      email
      extensive
      firstname
      height
      lastname
      patientid
      phone
      weight
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddpatientPayload",
    "kind": "LinkedField",
    "name": "fnAddpatient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Patient",
        "kind": "LinkedField",
        "name": "patient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthyear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c5a5b9101ab4a0eeaf8b633b2cd4dc3",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddpatientInput!\n) {\n  fnAddpatient(input: $input) {\n    patient {\n      birthyear\n      cnp\n      email\n      extensive\n      firstname\n      height\n      lastname\n      patientid\n      phone\n      weight\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4821f8d6b4dfddd89ee689dec99c99b1';

module.exports = node;
