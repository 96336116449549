import Menu from './menu'
import React from 'react'
import dbnextkey from './dbnextkey'
import enumAuth from '../enums/authenticated'
import enummapps from '../enums/mapps'
import months from '../enums/months'

// import {Navigate,} from 'react-router-dom'

export default () =>{

  // const renderRedirect = (redirect) => redirect ? <Navigate to='/' /> : <></>
  const renderRedirect = (redirect) => redirect ? <></> : <></>
  const [redirect, setRedirect] = React.useState(true)
  const [languageId, setLanguageId] = React.useState(1)   
  const [toggleHelp, setToggleHelp] = React.useState(false)
  // const [formatfisier, setFormatfisier] = React.useState('pdf')
  const existaDB = true
  // appid = enummapps.mhappyworld.key: (-1) => site === Oscar2.0 => NU actualizeaza appid, links meniu vor permite apel microapp
  // appid = enummapps.mapps.key: (0) => site !== Oscar2.0 =>    actualizeaza appid, links meniu vor permite apel pagini site
  // const [appid, setappid] = React.useState(enummapps.mhappyworld.key) // appid = -1 => site Oscar2.0 => NU actualizeaza appid, links meniu vor permite apel microapp
  // const [appid, setappid] = React.useState(enummapps.mapps.key)  // appid =  0 => site !== Oscar2.0 =>    actualizeaza appid, links meniu vor permite apel pagini site
  const [appid, setappid] = React.useState(!existaDB ? enummapps.utilizatoriapp.key : enummapps.mapps.key)  // appid =  3 =>simulare direct fara DB
  // console.log(`[oscarapp] - appid: ${appid}, existaDB: ${existaDB}`)
  const [dbkey, setdbkey] = React.useState(0) // dbkey => indicator serviciu db (prin backend)

  // hook state: utilizatorinitial, utilizatorOscar -----------------------------------
  const utilizatorinitial = {username: '', parola: '', modeleaza: false, isauthenticated: enumAuth.unauthenticated.value, apps: []}
  // const utilizatorinitial = {username: 'amos', parola: '210987', modeleaza: false, isauthenticated: enumAuth.unauthenticated.value, apps: []}
  // const utilizatorinitial = {username: 'lorenaiulia', parola: 'AdminManagement13#', modeleaza: false, isauthenticated: enumAuth.unauthenticated.value, apps: []}
  // const [utilizatorOscar, setutilizatorOscar] = React.useState({utilizatorlogin: utilizatorinitial, utilizatormodelat: utilizatorinitial})
  const [utilizatorOscar, setutilizatorOscar] = React.useState(
    !existaDB ? 
      {utilizatorlogin:{username:"amos",isauthenticated:"authenticated"},
      utilizatormodelat:{username:"amos",utilizatorid:"0",isauthenticated:"authenticated",rols:[{username:"amos",appid:appid.tostring,rolid:"9"}],apps:[],menuapps:[{denumire:"Utilizatori"}], therapistid: -1,modeleaza:false}}
    : {utilizatorlogin: utilizatorinitial, utilizatormodelat: utilizatorinitial}
  )
  // console.log(`[oscarapp] - utilizatorOscar: ${JSON.stringify(utilizatorOscar)}`)

  const [selyear, setselyear] = React.useState('2020')
  const month = 12
  const [selmonth, setselmonth] = React.useState({selmonthid: month.toString(), monthname: months[month]})
  const [selweek, setselweek] = React.useState('0')
  // console.log(`[oscarapp] - {selyear, selmonth, selweek}: ${JSON.stringify({selyear, selmonth, selweek})}`)

  const [selyearmng, setselyearmng] = React.useState('2020')
  const monthmng = 12
  const [selmonthmng, setselmonthmng] = React.useState({selmonthid: monthmng.toString(), monthname: months[monthmng]})
  const [selweekmng, setselweekmng] = React.useState('0')
  // console.log(`[oscarapp] - {selyearmng, selmonthmng, selweekmng}: ${JSON.stringify({selyearmng, selmonthmng, selweekmng})}`)

  const [businesstherapistid, setbusinesstherapistid] = React.useState(utilizatorOscar.utilizatormodelat.therapistid)
  
  // console.log(`[oscarapp.component] - {selyear, selmonth, selweek, businesstherapistid}: ${JSON.stringify({selyear, selmonth, selweek, businesstherapistid})}`)
  // console.log(`[oscarapp.component] - {selyearmng, selmonthmng, selweekmng, businesstherapistid}: ${JSON.stringify({selyearmng, selmonthmng, selweekmng, businesstherapistid})}`)

  const [errors, seterrors] = React.useState('') 
  const [visibleerror, setvisibleerror] = React.useState(false) 
  // console.log(`[oscarapp.table] - visibleerror: ${visibleerror}`)
  // onRefresh, refresh, -------------------------------------------------------------------------------
  const [refresh, setrefresh] = React.useState(true)
  const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
  }
  // console.log(`[oscarapp] - refresh : ${refresh}`)

  // onLogin, onLogout, -------------------------------------------------------------------------------
  const [usernamemodelat, setusernamemodelat] = React.useState(null)
  const onUtilizatorModelat = usernamemodelat => {
    setRedirect(true)  // redirect home // lucreaza impreuna cu onUtilizatormodelat
    setusernamemodelat(usernamemodelat)
  }
  const onUtilizatormodelat = () => setRedirect(false)  // lucreaza impreuna cu onUtilizatorModelat // apelat din modelareutilizator.component

  // console.log(`[oscarapp] - usernamemodelat: ${usernamemodelat}`)
  const onLogin = utilizator => {
  // console.log(`[oscarapp.onLogin] - utilizator : ${JSON.stringify(utilizator)}`)
    setusernamemodelat(null)
    setRedirect(false) // lucreaza impreuna cu logout
    setutilizatorOscar({utilizatorlogin:utilizator, utilizatormodelat: {...utilizator, isauthenticated: enumAuth.isauthenticated.value, rols: []}})
  }
  const onLogout = () => {
    setusernamemodelat(null)
    setRedirect(true)  // redirect home // lucreaza impreuna cu logout
    setutilizatorOscar({utilizatorlogin: utilizatorinitial, utilizatormodelat: utilizatorinitial}) 
  }  
  const onNextdbservice = () => {
    setdbkey(dbnextkey(dbkey))
  }  

  const [backup, setbackup] = React.useState(false)

  // params, events -------------------------------------------------------------------------------
  const params = { existaDB, dbkey, 
    // formatfisier, setFormatfisier, 
    languageId, setLanguageId,
    toggleHelp, setToggleHelp, appid, setappid,
    utilizatorOscar, setutilizatorOscar, usernamemodelat, 
    onRefresh, refresh,
    visibleerror, setvisibleerror, errors, seterrors, 
    selyear, setselyear, selmonth, setselmonth, selweek, setselweek, 
    selyearmng, setselyearmng, selmonthmng, setselmonthmng, selweekmng, setselweekmng,
    businesstherapistid, setbusinesstherapistid,
    backup, setbackup
  }
  const events = { onLogin, onLogout, onNextdbservice, onUtilizatorModelat, onUtilizatormodelat, }

  
  // // A custom hook that builds on useLocation to parse
  // // the query string for you.
  // const useQuery = () => {
  //   return new URLSearchParams(useLocation().search)
  // }
  // const query = useQuery()
  // const Child = ({ username }) => {
  //   return (
  //     <div>
  //       {username ? (
  //         <h3>
  //           The <code>username</code> in the query string is &quot;{username}
  //           &quot;
  //         </h3>
  //       ) : (
  //         <h3>There is no username in the query string</h3>
  //       )}
  //     </div>
  //   )
  // }

  // render component -----------------------------------------------------------------------------
  return(
    <>
      {renderRedirect(redirect)}
      <Menu params = {params} events = {events} />
      {/* <Child username={query.get("username")} /> */}
    </>
  )
}
