import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery($pUtilizatorid: BigInt!, $pRefresh: Boolean!)
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pUtilizatorid: $pUtilizatorid, pRefresh: $pRefresh)
}
`

