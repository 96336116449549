import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
// import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import * as XLSX from 'xlsx'
import sdbtnstyle from '../styles/styles/sdbtnstyle'

const useStyles = makeStyles(() => ({
    // root: {
    //   '& > *': {
    //     margin: theme.spacing(1),
    //   },
    // },
    input: {
      display: 'none',
    },
  }))
 
// https://stackoverflow.com/questions/46909260/reading-excel-file-in-reactjs
export default ({importxlsfields: {n, title, ondbExcellAdd, }}) => {
  const classes = useStyles()
  // console.log(`[readxlsfile] - {n, title, ondbExcellAdd, }: ${JSON.stringify({n, title, ondbExcellAdd, })}`)

  const [m, setm] = React.useState(0)
  const onImportxls = e => {
      e.preventDefault()
      const reader = new FileReader()
      reader.onload = function (e) {
          const data = e.target.result
          const readedData = XLSX.read(data, {type: 'binary', cellDates:true, cellNF: false, cellText:false})

          // trimite la eveniment ondbExcellAdd un array xls ce contine cate un obiect {sheet, xls:datapParse} pentru fiecare shhet
          let nreg = 0
          const xls = readedData.SheetNames.map(sheet => {
            /* Convert array to json*/
            // console.log(`[readxlsfile.wsnames.map] - sheet: ${sheet}`)
            // const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[sheet], {header:1})
            // const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[sheet], {dateNF:"YYYY-MMM-DD"})
            // const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[sheet], {dateNF:"DD-MMM-YYYY"})
            // const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[sheet], {dateNF:"MMM-DD-YYYY"})
            const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[sheet], {dateNF:"yyyy-mm-dd h:mm:ss"})
            nreg += dataParse.length-1
            return {sheet: sheet, xls: dataParse}
            // console.log(`[readxlsfile] - sheet: ${sheet}, dataParse: ${JSON.stringify(dataParse)}`)
          })
          setm(nreg)
          // console.log(`[readxlsfile] - nreg: ${nreg}, readedData.SheetNames.length: ${readedData.SheetNames.length}, xls: ${JSON.stringify(xls)}`)
          ondbExcellAdd(xls, readedData.SheetNames.length)             
      }

      const files = e.target.files, filename = files[0]
      reader.readAsBinaryString(filename)
  }

  const id= `contained-button-file${Math.random()}`

  return(
      <>
          <input accept=".xls,.xlsx," className={classes.input} id={id} multiple type="file" onChange={onImportxls} />
          <label htmlFor={id}>
              <Button size="small" variant="contained" className={sdbtnstyle().importxls} color="primary" component="span" >
              {title} {m-n > 0 ? [m-n] : ''}
              </Button>
          </label>
      </>
  )
}    
