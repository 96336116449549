import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdcalendarInput!)
{
  fnUpdcalendar(input: $input) {
    calendar {
      calendarid
      nameEn
      nameRo
      symbol
    }
  }
}
`

