import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare codificare extras cont, campurile atribut fiind: <b>extrascontcodificareid, key, operationcodeid, collectionid</b> </li>
            <li>pentru campurile <u>key, operationcodeid, collectionid</u> exista unicitate in tabela, campul therapytypeId este optional</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga codificare extras cont noua [<b>+</b>], actualizare oricare dintre campurile <i>>key, operationcodeid, collectionid</i>, sterge codificare extras cont si refresh date </li>
                <li> pentru campurile <b>>operationcodeid, collectionid</b> se foloseste cate un autocomplete care se populeaza fiecare cu o  lista de valori. </li>
            </ul>
            <li>este posibil export in fisier xls <b>extrasContCodificare.xls</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.ExtrasContCodificares</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: config.ExtrasContCodificares</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>ExtrasContCodificareId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>key</td>
                            <td className='td'>VARCHAR</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>operationcodeId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>collectionId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>2 - POS, 3 - cont curent</td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vextrascontcodificares(orderBy: NORDER_ASC, condition: $condition) (extrascontcodificareid, key, operationcodeid, nameoperationcode, symboloperationcode, codeoperationcode, norderoperationcode, collectionid, nameRocollection, nameEncollection, symbolcollection)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>voperationcodes(orderBy: OPERATIONCODEID_ASC) (operationcodeid, nameoperationkindof, name, symbol, code)</td>
                            <td className='td'>lista de valori [cod operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vcollections(orderBy: COLLECTIONID_ASC) (collectionid, nameRo)</td>
                            <td className='td'>lista de valori [cod operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_addextrascontcodificare(p_key, p_operationcodeid, p_collectionId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_updextrascontcodificare(p_keyfield, p_value, p_extrasContCodificareId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_delextrascontcodificare(p_extrasContCodificareId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_upsextrascont(p_extrasContCodificareId, p_key, p_operationcodeid, p_collectionId)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


