import React from 'react'

import { SearchColumnStyle, MenuitemIcon} from '../../../../styles/styles/headerstyle'

import Searchicon from './searchicon'
import Utilizatoricon from './utilizatoricon'
import Reportsicon from './reportsicon'

export default ({params, events}) => {
    
    return (
        <SearchColumnStyle><MenuitemIcon>
            <Searchicon params = {params} events={events} />
            <Reportsicon params = {params} events={events} />
            <Utilizatoricon params = {params} events={events} />
        </MenuitemIcon></SearchColumnStyle> 
    )
}