/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddmodelareutilizatorInput = {|
  clientMutationId?: ?string,
  pUtilizatormodeleazaid: number,
  pUtilizatormodelatid: number,
|};
export type mutationAddModelareUtilizatoriMutationVariables = {|
  input: FnAddmodelareutilizatorInput
|};
export type mutationAddModelareUtilizatoriMutationResponse = {|
  +fnAddmodelareutilizator: ?{|
    +modelareutilizator: ?{|
      +modelareutilizatorid: any,
      +utilizatormodelatid: ?any,
      +utilizatormodeleazaid: ?any,
    |}
  |}
|};
export type mutationAddModelareUtilizatoriMutation = {|
  variables: mutationAddModelareUtilizatoriMutationVariables,
  response: mutationAddModelareUtilizatoriMutationResponse,
|};
*/


/*
mutation mutationAddModelareUtilizatoriMutation(
  $input: FnAddmodelareutilizatorInput!
) {
  fnAddmodelareutilizator(input: $input) {
    modelareutilizator {
      modelareutilizatorid
      utilizatormodelatid
      utilizatormodeleazaid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddmodelareutilizatorPayload",
    "kind": "LinkedField",
    "name": "fnAddmodelareutilizator",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Modelareutilizator",
        "kind": "LinkedField",
        "name": "modelareutilizator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modelareutilizatorid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatormodelatid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatormodeleazaid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddModelareUtilizatoriMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddModelareUtilizatoriMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04c470d912f88d90b97ff43565b5201b",
    "id": null,
    "metadata": {},
    "name": "mutationAddModelareUtilizatoriMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddModelareUtilizatoriMutation(\n  $input: FnAddmodelareutilizatorInput!\n) {\n  fnAddmodelareutilizator(input: $input) {\n    modelareutilizator {\n      modelareutilizatorid\n      utilizatormodelatid\n      utilizatormodeleazaid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77c4f642c74c1d5e7a06096aac33ad9b';

module.exports = node;
