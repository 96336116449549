import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>sedinte terapie, campurile atribut fiind: <b>therapyessionId, patientId, therapistId, therapypriceId, therapyId, therapy2Id, therapytypeId, date, fromid, toid, percent, calendarid, roomid, patientNotes, therapistNotes, value, collectionId, finalizat</b> </li>
            <li>pentru campurile <u>patientId, therapistId, date, fromid</u> exista unicitate in tabela, campurile roomid, patientNotes, therapistNotes sunt optionale</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga sedinta terapie noua [<b>+</b>], actualizare oricare dintre campurile <i>therapyessionId, patientId, therapistId, therapypriceId, therapyId, date, fromid, toid, percent, calendarid, roomid, patientNotes, therapistNotes, value, collectionId, finalizat</i>, sterge sedinta terapie si refresh date </li>
                <li> pentru campurile <b>therapyessionId, patientId, therapistId, therapypriceId, therapyId, therapy2Id, therapytypeId, fromid, toid, calendarid, roomid, collectionId</b> se foloseste cate un autocomplete care se populeaza fiecare cu o lista de valori. Deoarece campul roomid este nullable la lista se adauga o prima inregistare cu valoarea <b>null</b> care permite stergerea valorii campului din baza de date. </li>
                <li> actualizare camp <b>finalizat = true</b> are drept efect inregistrarea incasarii in managementDetail si reflectarea in registru de casa si cashflow, iar <b>finalizat = false</b> rollback. Valoarea <b>collectionId = none[5]</b> nu se salveaza in cashflow</li>
            </ul>
            <li>este posibil export in fisier xls <b>Therapyessions.xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.dbTherapysessions</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.therapysessions</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>therapyessionId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>patientId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1, uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapistId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapypriceId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>therapyId, therapy2Id, timeid, time2id, price</td>
                        </tr>
                        <tr>
                            <td className='td'>therapyId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapy2Id</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapytypeId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>date</td>
                            <td className='td'>DATE</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>fromid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>toid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>percent</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>calendarid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>roomid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>patientNotes</td>
                            <td className='td'>TEXT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapistNotes</td>
                            <td className='td'>TEXT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>value</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>collectionId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>finalizat</td>
                            <td className='td'>BOOLEAN</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapysessions(orderBy: THERAPYSESSIONID_ASC, condition: $condition) (therapysessionId, 
                                <div>patientId, firstnamepatient, lastnamepatient, extensivepatient, birthyearpatient, weightpatient, heightpatient, phonepatient, emailpatient, CNPpatient,</div>
                                <div>therapistId, firstname, lastname, extensive, symbol, code, phone, email, CNP, percenttherapists,</div>
                                <div>date</div>
                                <div>fromid, fromhour, symbolfromhour,</div>
                                <div>toid, tohour, symboltohour, </div>
                                <div>therapypriceId, therapyIdtherapyprices, therapy2Idtherapyprices, therapytypeidtherapyprices, </div>
                                <div>timeid, symboltime, minutestime, unitstime, </div>
                                <div>time2id, symboltime2, minutestime2, unitstime2, time, </div>
                                <div>price, nametherapyprice, </div> {/* <div>descriptiontherapyprices, norder,</div> */}
                                <div>therapyId, name_rotherapy, name_entherapy, symboltherapy, nordertherapy, descriptiontherapy, </div>
                                <div>therapy2Id, name_rotherapy2, name_entherapy2, symboltherapy2, nordertherapy2, descriptiontherapy2, </div>
                                <div>therapytypeid, nameRotherapytype, nameEntherapytype, symboltherapytype, </div>
                                <div>ntherapysessiontherapytype, ntherapysession1Therapytype, namesubscription, ntherapysessionsubscription, mofntherapysessionsubscription, nbmonthssubscription, finalizatsubscription, mofntherapysession, subscriptionsessionid, timesubscription, subscriptionid,</div>
                                <div>percent, calendarid, name_rocalendar, name_encalendar, symbolcalendar,</div>
                                <div>roomid, name_roroom, name_enroom, symbolroom, etaj,</div>
                                <div>patientNotes, therapistNotes, </div>
                                <div>collectionId, name_rocollection, name_encollection, symbolcollection, </div>
                                <div>value, finalizat</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: therapyid !== undefined ? &#x0007B; therapyid: parseInt(therapyid) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapies(orderBy: THERAPYID_ASC) (therapyid, nameRo)</td>
                            <td className='td'>lista de valori [therapyId, therapy2Id]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vpatients(orderBy: PATIENTID_ASC)  (patientid, firstname, lastname, extensive, birthyear, weight, height, phone, email, CNP)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>config, mainview</td>
                            <td className='td'>vtherapists (therapistid, firstname, lastname, extensive, symbol, code, phone, email, CNP, percent)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vhours (hourId, name, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>config, mainview</td>
                            <td className='td'>vtherapisttherapyprices(orderBy: THERAPISTTHERAPYPRICEID_ASC, condition: $condition) (therapisttherapypriceid, therapistid, firstname, lastname, extensive, symbol, code, phone, email, cnp, percenttherapists, therapypriceid, therapyid, therapy2Id, therapytypeid, timeid, time2Id, time, price, nametherapyprice, descriptiontherapyprices, nordertherapyprices, percent)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vcalendars (calendarId, name_ro, name_en, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vrooms (roomId, name_ro, name_en, symbol, etaj)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>admin, mainview</td>
                            <td className='td'>vbusinesscollections(orderBy: COLLECTIONID_ASC) (collectionid, name_ro, name_en, symbol)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapies (therapyid, name_ro, name_en, symbol, shortsymbol, norder, description)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addtherapysession(p_patientId, p_therapistId, p_date, p_fromid, p_toid, p_therapypriceId, p_therapyId, p_therapy2Id, p_therapytypeId, p_percent, p_calendarid, p_roomid, p_patientNotes, p_therapistNotes, p_value, p_collectionId, p_price)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_updtherapysession(p_keyfield, p_value, p_therapyId)</td>
                            <td className='td'>finalizat = true : inregistrare in dbmanagementdetail incasarea, finalizat = false : rollback</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_deltherapysession(p_therapyId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_upstherapysession(p_therapysessionId, p_patientId, p_therapistId, p_date, p_fromid, p_toid, p_therapypriceId, p_therapyId, , p_therapy2Id, , p_therapytypeId, p_percent, p_calendarid, p_roomid, p_patientNotes, p_therapistNotes, p_value, p_collectionId, p_finalizat)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


