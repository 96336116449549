import styled from 'styled-components'
// import gridrows from '../styles/themes/defaultTheme/gridrows'

export default styled.div`
  display: grid;
  grid-template-columns: repeat(22, 1fr);
`
export const Menuitem = styled.div`
  padding-top: 1.5rem;
`
export const MenuitemIcon = styled.div`
  padding-top: 2.3rem;
`
// const height = '5.6rem'
export const LogoStyle = {
  height: '4.5rem', 
  marginTop: '1.1rem',
  marginLeft: '0.8rem',
  // background: 'rgba(240, 10, 10, 0.05)',
  //opacity: '0.5',
}

export const logocontainer = {
  transition: 'all 0.4s ease-in-out'
}

export const usernameinputstyle = {
  width: '50%',
  height: '24px',
  fontSize: '14px',
  lineHeight: '1.42857143',
}

export const passwdinputstyle = {
  width: '25%',
  height: '24px',
  fontSize: '14px',
  lineHeight: '1.42857143',
}

// center menu items
export const MenuCenterStyle = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.2rem;
  // margin-bottom: auto;
  // width: 50%
  `

export const LogoColumnStyle = styled.div`
  grid-column: 1 / 2;
`
export const HomeColumnStyle = styled.div`
  grid-column: 3 / 4;
`
export const OscarColumnStyle = styled.div`
  grid-column: 5 / 7;
`
export const Oscar1ColumnStyle = styled.div`
  grid-column: 7 / 9;
`
export const Oscar2ColumnStyle = styled.div`
  grid-column: 9 / 11
`
export const Oscar3ColumnStyle = styled.div`
  grid-column: 11 / 13
`
export const HelpColumnStyle = styled.footer`
  grid-column: 13 / 14;
`
export const UtilizatorColumnStyle = styled.div`
  grid-column: 14 / 18;
  background:'gray';
`
export const UsernameColumnStyle = styled.div`
  grid-column: 14 / 18;
`
// export const LoginoutColumnStyle = styled.div`
//   grid-column: 17 / 18;
//   background:'aqua';
// `
export const SearchColumnStyle = styled.div`
  grid-column: 18 / 20;
`
export const AdministrareColumnStyle = styled.div`
  grid-column: 20 / 22;
`