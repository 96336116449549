import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeltherapistcollectionInput!)
{
  fnDeltherapistcollection(input: $input) {
    therapistcollection {
      collectionid
      therapistcollectionid
      therapistid
    }
  }
}
`
