import React from 'react'

export default ({params: {image, index, nimg, setnimg, }, }) => {
    const id = `gallery__item--${index}`

    return(
        < >
            <input key = {`rdbtn${id}`} id={id} type="radio" name="gallery__items" value = {index} checked={nimg === index? true : null} />
            <label className="gallery__item" htmlFor={id} onClick={() => setnimg(index)}>gallery item 0
                <img className='imgclass' src={image} alt={`images[${index}]`}/>
            </label>
        </>
    )
}
