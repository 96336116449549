
export default [
    {
        mngmentoperationid: {field: 'mngmentoperationid', values: ['', '', 'mngmentoperationid'], width: 10, isLastRow : false},
        date: {field: 'date', values: ['', '', 'date'], width: 10, isLastRow : false},
        houseregisterprevious: {field: 'houseregisterprevious', values: ['', '', 'houseregisterprevious'], width: 20, isLastRow : false},
        houseregistercurrent: {field: 'houseregistercurrent', values: ['', '', 'houseregistercurrent'], width: 20, isLastRow : false},
        monthkindofid: {field: 'monthkindofid', values: ['', '', 'monthkindofid'], width: 10, isLastRow : false},
        zndocument: {field: 'zndocument', values: ['', '', 'zndocument'], width: 10, isLastRow : false},
        namemonthkindof: {field: 'namemonthkindof', values: ['', '', 'namemonthkindof'], width: 20, isLastRow : false},
    }, 
]
