/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +voperationcodespecificraports: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationcodespecificraportid: ?any,
        +operationcodeid: ?number,
        +nameoperationcode: ?string,
        +symboloperationcode: ?string,
        +code: ?string,
        +feloperationcodeid: ?number,
        +namefeloperationcode: ?string,
        +symbolfeloperationcode: ?string,
      |}
    |}>
  |},
  +vmngmentoperationyears: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any
      |}
    |}>
  |},
  +vrraportlorenas: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +date: ?any,
        +month: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
        +year: ?any,
        +codeoperationcode: ?string,
        +nameoperationcode: ?string,
        +level: ?number,
        +feloperationcodeid: ?number,
        +value: ?any,
      |}
    |}>
  |},
  +voperationcodes: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationcodeid: ?any,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +operationkindofsymbol: ?string,
        +norderoperationkindof: ?number,
        +name: ?string,
        +symbol: ?string,
        +code: ?string,
        +norder: ?number,
      |}
    |}>
  |},
  +vfeloperationcodes: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +feloperationcodeid: ?any,
        +name: ?string,
        +symbol: ?string,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationcode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feloperationcodeid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionyear"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VoperationcodespecificraportsConnection",
      "kind": "LinkedField",
      "name": "voperationcodespecificraports",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationcodespecificraportsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationcodespecificraport",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationcodespecificraportid",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationcode",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namefeloperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolfeloperationcode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "YEAR_DESC"
        }
      ],
      "concreteType": "VmngmentoperationyearsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationyears",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationyearsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationyear",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionyear"
        }
      ],
      "concreteType": "VrraportlorenasConnection",
      "kind": "LinkedField",
      "name": "vrraportlorenas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VrraportlorenasEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vrraportlorena",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shortmonthname",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "codeoperationcode",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "OPERATIONCODEID_ASC"
        }
      ],
      "concreteType": "VoperationcodesConnection",
      "kind": "LinkedField",
      "name": "voperationcodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationcodesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationcode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationkindofid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameoperationkindof",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationkindofsymbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norderoperationkindof",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norder",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "FELOPERATIONCODEID_ASC"
        }
      ],
      "concreteType": "VfeloperationcodesConnection",
      "kind": "LinkedField",
      "name": "vfeloperationcodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VfeloperationcodesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vfeloperationcode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vfeloperationcodes(orderBy:\"FELOPERATIONCODEID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca10d0b930c16453ed0e8df852056e0f';

module.exports = node;
