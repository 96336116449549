/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdcurrentmonthInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pCurrentmonthid: number,
|};
export type mutationUpdcurrentmonthMutationVariables = {|
  input: FnUpdcurrentmonthInput
|};
export type mutationUpdcurrentmonthMutationResponse = {|
  +fnUpdcurrentmonth: ?{|
    +currentmonth: ?{|
      +currentmonthid: any,
      +day: number,
      +month: number,
      +year: number,
    |}
  |}
|};
export type mutationUpdcurrentmonthMutation = {|
  variables: mutationUpdcurrentmonthMutationVariables,
  response: mutationUpdcurrentmonthMutationResponse,
|};
*/


/*
mutation mutationUpdcurrentmonthMutation(
  $input: FnUpdcurrentmonthInput!
) {
  fnUpdcurrentmonth(input: $input) {
    currentmonth {
      currentmonthid
      day
      month
      year
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdcurrentmonthPayload",
    "kind": "LinkedField",
    "name": "fnUpdcurrentmonth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Currentmonth",
        "kind": "LinkedField",
        "name": "currentmonth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentmonthid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "day",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdcurrentmonthMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdcurrentmonthMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ddd55a9ac1cfd0ade3d5d9e1595e500a",
    "id": null,
    "metadata": {},
    "name": "mutationUpdcurrentmonthMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdcurrentmonthMutation(\n  $input: FnUpdcurrentmonthInput!\n) {\n  fnUpdcurrentmonth(input: $input) {\n    currentmonth {\n      currentmonthid\n      day\n      month\n      year\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a45c2724cc95fe6845e66bf980d9606';

module.exports = node;
