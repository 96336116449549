
export default xlsheaders => {
  const columnDatas = xlsheaders[0].map(header =>{
    return { header: header.field, accessor: header.field, prefix: `${header.field}: `, show: 'true', radiobuttondanu: true, fullWidth: true, }
  })

  // const nextpagescolumnOperatiunes = [
  //   [
  //     {header: 'unitateid', accessor: 'unitateid', type: 'text', show: 'true', tree:true, },
  //   ],
  // ]

  // const nextpagescolumnOperatiunes = xlsheaders[1]
  //   .map(header =>{
  //     return { header: header.field, accessor: header.field, prefix: `${header.field}: `, show: 'true', radiobuttondanu: true, fullWidth: true, }
  //   })

  let initialdata = {}
  for (const property in xlsheaders[0]) {
    // console.log(`${property}: ${JSON.stringify(xlsheaders[0][property].field)}`);
    initialdata = {...initialdata, [xlsheaders[0][property].field]: true}
  }
  // console.log(initialdata)

  return ({
  ConfigReportDialogtitle: ['configurare lista campuri fisier xls', 'select da / nu'],  
  columns: columnDatas, //nextpagescolumns: raport === constraport ? nextpagescolumnOperatiunes : [],
  initialdata: initialdata,
  })
}
