
export default {
    AddDialogtitle: ['Adauga app', 'Adauga inregistrare in tabela apps.'],
    columns: [
      {header: 'denumire', accessor: 'denumire', type: 'text', show: 'true', },
      {header: 'simbol', accessor: 'simbol', type: 'text', show: 'true', },
      {header: 'link', accessor: 'link', type: 'text', show: 'true', },
      {header: 'ordonare', accessor: 'ordonare', type: 'text', show: 'true', },
    ],
    initialdata: {
      utilizator: '',
      simbol: '',
      link: '',
      ordonare: '',
      grefresh: '',
      subRows: undefined,
    },    
  }
