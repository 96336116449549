import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vtherapistpatients === undefined ? null : dbdata.vtherapistpatients.edges
  .map(edge => {
    return{
      ...edge.node, 
      patient: `${edge.node.firstnamepatient} ${edge.node.lastnamepatient} ${edge.node.extensivepatient !== null ? edge.node.extensivepatient : ''}`,
      therapist: `${edge.node.firstname} ${edge.node.lastname} ${edge.node.extensive !== null ? edge.node.extensive : ''}`,
      }
  })
  // console.log(`[dbTherapistpatients.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
      ...dbdata.vtherapists.edges.map(edge => {
        const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
        return {id: 'therapistid', therapistid, therapist: `${firstname} ${lastname} ${extensive !== null ? extensive : ''}[${symbol}, ${code}]`}
    })
  ]
  // console.log(`[dbTherapistpatients.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
  const dbOptions1 = [
      ...dbdata.vpatients.edges.map(edge => {
        const {patientid, firstname, lastname, extensive, } = edge.node
        return {id: 'patientid', patientid, patient: `${firstname} ${lastname} ${extensive !== null ? extensive : ''}`}
    })
  ]
  // console.log(`[dbTherapistpatients.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VtherapistcollectionCondition, defaultValue: {}, }
    ) 
    {
      vtherapistpatients(orderBy: THERAPISTPATIENTID_ASC) {
        edges {
          node {
            therapistpatientid
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percenttherapists
            patientid
            firstnamepatient
            lastnamepatient
            extensivepatient
            birthyearpatient
            weightpatient
            heightpatient
            phonepatient
            emailpatient
            cnppatient
          }
        }
      }    
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            # phone
            # email
            # cnp
            # percent
          }
        }
      } 
      vpatients { #(orderBy: PATIENTID_ASC)
        edges {
          node {
            patientid
            firstname
            lastname
            extensive
            # birthyear
            # weight
            # height
            # phone
            # email
            # cnp
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

