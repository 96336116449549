// import React from 'react'

export default ({dbOptions, dbOptions1}) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'therapistpatient',
      columns: [
        { id: 'therapist', Header: 'therapist', accessor: 'therapist', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege terapeut ..'},},
        { id: 'patient', Header: 'patient', accessor: 'patient', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},
        ]
    },
    {
      Header: 'therapist',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege terapeut ..'},},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
        { id: 'percenttherapists', Header: 'percenttherapists', accessor: 'percenttherapists', width: 30, align: 'right', type: 'number',},
        ]
    },
    {
      Header: 'patient',
      columns: [
        { id: 'firstnamepatient', Header: 'firstnamepatient', accessor: 'firstnamepatient', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},
        { id: 'lastnamepatient', Header: 'lastnamepatient', accessor: 'lastnamepatient', width: 30, type: 'text',},
        { id: 'extensivepatient', Header: 'extensivepatient', accessor: 'extensivepatient', width: 30, type: 'text',},
        { id: 'birthyearpatient', Header: 'birthyearpatient', accessor: 'birthyearpatient', width: 30, align: 'right', type: 'number',},
        { id: 'weightpatient', Header: 'weightpatient', accessor: 'weightpatient', width: 30, align: 'right', type: 'number',},
        { id: 'heightpatient', Header: 'heightpatient', accessor: 'heightpatient', width: 30, align: 'right', type: 'number',},
        { id: 'phonepatient', Header: 'phonepatient', accessor: 'phonepatient', width: 30, type: 'text',},
        { id: 'emailpatient', Header: 'emailpatient', accessor: 'emailpatient', width: 30, type: 'text',},
        { id: 'cnppatient', Header: 'cnppatient', accessor: 'cnppatient', width: 30, type: 'text',},
        ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapistpatientid', Header: 'therapistpatientid', accessor: 'therapistpatientid', width: 30, type: 'text',},
    { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30,  },
    { id: 'patientid', Header: 'patientid', accessor: 'patientid', width: 30,  },
  ],
},
])

// birthyearpatient
// weightpatient
// heightpatient
// phonepatient
// emailpatient
// cnppatient