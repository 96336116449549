
export default [
    [
        // {field: 'year', value: 'year'},
        {field: 'nameoperationcode', value: 'Venituri/Cheltuieli'},
        {field: 'codeoperationcode', value: 'Cod'},
        // {field: 'norderoperationcode', value: 'norderoperationcode'},
        // {field: 'operationkindofid', value: 'operationkindofid'},
        // {field: 'nameoperationkindof', value: 'nameoperationkindof'},
        // {field: 'norderoperationkindof', value: 'norderoperationkindof'},
        // {field: 'kindofid', value: 'kindofid'},
        // {field: 'namekindofs', value: 'namekindofs'},
        {field: 'sumjan', value: 'Ianuarie'},
        {field: 'sumfeb', value: 'Februarie'},
        {field: 'summar', value: 'Martie'},
        {field: 'sumapr', value: 'Aprilie'},
        {field: 'summay', value: 'Mai'},
        {field: 'sumjun', value: 'Iunie'},
        {field: 'sumjul', value: 'Iulie'},
        {field: 'sumaug', value: 'August'},
        {field: 'sumsep', value: 'Septembrie'},
        {field: 'sumoct', value: 'Octombrie'},
        {field: 'sumnov', value: 'Noiembrie'},
        {field: 'sumdec', value: 'Decembrie'},
        {field: 'total', value: 'Total'},
        // {field: '', value: ''},
    ],
    [
        // {field: 'year', value: 'year'},
        {field: 'date', value: 'Data'},
        {field: 'codeoperationcode', value: 'Cod'},
        {field: 'nameoperationcode', value: 'Venituri/Cheltuieli'},
        // {field: 'description', value: 'description'},
        {field: 'cash', value: 'Casa'},
        {field: 'pos', value: 'POS'},
        {field: 'contcurent', value: 'Cont curent'},
        {field: 'wallet', value: 'Wallet'},
        {field: 'none', value: 'None'},
    ],      
    [
        {field: 'mngmentoperationdetailid', value: 'mngmentoperationdetailid'},
        {field: 'operatiune', value: 'operatiune'},
        {field: 'norder', value: 'norder'},
        {field: 'mngmentoperationid', value: 'mngmentoperationid'},
        {field: 'date', value: 'date'},
        {field: 'year', value: 'year'},
        {field: 'month', value: 'month'},
        {field: 'monthname', value: 'monthname'},
        {field: 'shortmonthname', value: 'shortmonthname'},
        {field: 'houseregisterprevious', value: 'houseregisterprevious'},
        {field: 'houseregistercurrent', value: 'houseregistercurrent'},
        {field: 'ndocument', value: 'ndocument'},
        {field: 'description', value: 'description'},
        {field: 'value', value: 'value'},
        {field: 'debit', value: 'debit'},
        {field: 'credit', value: 'credit'},
        {field: 'operationcodeid', value: 'operationcodeid'},
        {field: 'nameoperationcode', value: 'nameoperationcode'},
        {field: 'symboloperationcode', value: 'symboloperationcode'},
        {field: 'codeoperationcode', value: 'codeoperationcode'},
        {field: 'norderoperationcode', value: 'norderoperationcode'},
        {field: 'operationkindofid', value: 'operationkindofid'},
        {field: 'kindofid', value: 'kindofid'},
        {field: 'namekindofs', value: 'namekindofs'},
        {field: 'symbolkindofs', value: 'symbolkindofs'},
        {field: 'collectionid', value: 'collectionid'},
        {field: 'nameRocollection', value: 'nameRocollection'},
        {field: 'symbolcollection', value: 'symbolcollection'},
        {field: 'nameEncollection', value: 'nameEncollection'},
        {field: 'nameoperationkindof', value: 'nameoperationkindof'},
        {field: 'symboloperationkindof', value: 'symboloperationkindof'},
        {field: 'norderoperationkindof', value: 'norderoperationkindof'},
        {field: 'businessid', value: 'businessid'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
