
import * as pdfstyles from '../../../../uioscar/pdf/pdflib/pdfstyles'

export default () => {   

    const headertableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }
    const bodytableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }

    return [
        {
            date: {field: 'date', values: ['Data'], width: 8, isLastRow : false, ...pdfstyles.styleLeft({headertableCell, bodytableCell, }), },
            patient: {field: 'patient', values: ['Beneficiar'], width: 21, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            nametherapyprice: {field: 'nametherapyprice', values: ['Terapie'], width: 32, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            sfrom: {field: 'ora', values: ['Ora'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            value: {field: 'value', values: ['Valoare'], width: 7, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            percent: {field: 'percent', values: ['Procent'], width: 7, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            namerocollection: {field: 'namerocollection', values: ['Mod Incasare'], width: 8, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            therapistvalue: {field: 'therapistvalue', values: ['Factura'], width: 7, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
        }, 
    ]}
