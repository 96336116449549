
export default [
    {
        therapypriceid: {field: 'therapypriceid', values: ['', '', 'therapypriceid'], width: 10, isLastRow : false},
        therapyid: {field: 'therapyid', values: ['', '', 'therapyid'], width: 10, isLastRow : false},
        therapy2Id: {field: 'therapy2Id', values: ['', '', 'therapy2Id'], width: 10, isLastRow : false},
        nameRotherapy: {field: 'nameRotherapy', values: ['', '', 'nameRotherapy'], width: 20, isLastRow : false},
        nameEntherapy: {field: 'nameEntherapy', values: ['', '', 'nameEntherapy'], width: 20, isLastRow : false},
        // nameRo2Therapy: {field: 'nameRo2Therapy', values: ['', '', 'nameRo2Therapy'], width: 20, isLastRow : false},
        // nameEn2Therapy: {field: 'nameEn2Therapy', values: ['', '', 'nameEn2Therapy'], width: 20, isLastRow : false},
        // symboltherapy: {field: 'symboltherapy', values: ['', '', 'symboltherapy'], width: 10, isLastRow : false},
        // symbol2Therapy: {field: 'symbol2Therapy', values: ['', '', 'symbol2Therapy'], width: 10, isLastRow : false},
        norder: {field: 'norder', values: ['', '', 'norder'], width: 10, isLastRow : false},
        // norder2Therapy: {field: 'norder2Therapy', values: ['', '', 'norder2Therapy'], width: 10, isLastRow : false},
        price: {field: 'price', values: ['', '', 'price'], width: 10, isLastRow : false},
        // timeid: {field: 'timeid', values: ['', '', 'timeid'], width: 10, isLastRow : false},
        // time2id: {field: 'time2id', values: ['', '', 'time2id'], width: 10, isLastRow : false},
        time: {field: 'time', values: ['', '', 'time'], width: 10, isLastRow : false},
        // therapytypeid: {field: 'therapytypeid', values: ['', '', 'therapytypeid'], width: 10, isLastRow : false},
        // descriptiontherapy: {field: 'descriptiontherapy', values: ['', '', 'descriptiontherapy'], width: 10, isLastRow : false},
        // name: {field: 'name', values: ['', '', 'name'], width: 20, isLastRow : false},
        // nordertherapy: {field: 'nordertherapy', values: ['', '', 'nordertherapy'], width: 20, isLastRow : false},
        // description: {field: 'description', values: ['', '', 'description'], width: 20, isLastRow : false},
        // description2Therapy: {field: 'description2Therapy', values: ['', '', 'description2Therapy'], width: 20, isLastRow : false},
    }, 
]

// nameRotherapytype
// nameEntherapytype
// symboltherapytype
// ntherapysessiontherapytype
// ntherapysession1Therapytype
// 
// symboltime
// minutestime
// unitstime
// 
// symboltime2
// minutestime2
// unitstime2
