import React from 'react'
import Component from './header'
import HeaderStyle from '../../../styles/styles/headerstyle'


export default ({params, events, }) => 
    <HeaderStyle>
        <Component params = {params} events={events} / >
    </HeaderStyle>

