/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddcalendarInput = {|
  clientMutationId?: ?string,
  pNameRo: string,
  pNameEn: string,
  pSymbol: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddcalendarInput
|};
export type mutationAddMutationResponse = {|
  +fnAddcalendar: ?{|
    +calendar: ?{|
      +calendarid: any,
      +nameEn: string,
      +nameRo: string,
      +symbol: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddcalendarInput!
) {
  fnAddcalendar(input: $input) {
    calendar {
      calendarid
      nameEn
      nameRo
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddcalendarPayload",
    "kind": "LinkedField",
    "name": "fnAddcalendar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Calendar",
        "kind": "LinkedField",
        "name": "calendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameRo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8444244d1d9835a6617887b9323b37c",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddcalendarInput!\n) {\n  fnAddcalendar(input: $input) {\n    calendar {\n      calendarid\n      nameEn\n      nameRo\n      symbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '625466e1fe69cb7ae38449400ca7aa30';

module.exports = node;
