/* eslint-disable no-use-before-define */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel';

const filter = createFilterOptions()

// https://material-ui.com/components/autocomplete/
export default ({data, defaultValue, onChangeAutocomplete, placeholder, readOnly, id, text='', width, addnewitem}) => { //onInputChange
  const [value, setValue] = React.useState(defaultValue)
  // console.log(`[tablelib.AutoComplete] - text: ${text}, value: ${value}, defaultValue: ${defaultValue}, id: ${id}, `)
  // console.log(`[tablelib.AutoComplete] - data: ${JSON.stringify(data)}`)
  // // // const [autocomplete, setAutocomplete] = React.useRef(null)
  // // // console.log(`[tablelib.AutoComplete] - autocomplete: ${JSON.stringify(autocomplete)}`)
  return (
    <Autocomplete
      value={value} 
      onOpen={() => {
      // onOpen={(event, value) => {
        // event.preventDefault()
        // console.log(`[tablelib.AutoComplete.onOpen] - `)
        //    if(onInputChange !== undefined) onInputChange(value)
        //   // console.log(`[tablelib.AutoComplete] - id: ${id}, value: ${JSON.stringify(value)}`)
        }
      }
      onClose={() => {
      // onClose={(event, value) => {
        // event.preventDefault()
        // console.log(`[tablelib.AutoComplete.onClose] - value: ${value}`)
        //    if(onInputChange !== undefined) onInputChange(value)
        //   // console.log(`[tablelib.AutoComplete] - id: ${id}, value: ${JSON.stringify(value)}`)
        }
      }
      // onInputChange={(event, value) => {
      //   // event.preventDefault()
      //    if(onInputChange !== undefined) onInputChange(value)
      //   // console.log(`[tablelib.AutoComplete] - id: ${id}, value: ${JSON.stringify(value)}`)
      // }
      // }
      onChange={(event, newValue) => {
        // console.log(`tablelib.AutoComplete.onChange - readOnly: ${readOnly}, newValue: ${JSON.stringify(newValue)}, newValue[id]: ${newValue[id]}`)
        if(readOnly) return
        if (newValue && newValue[id]) {
          if(onChangeAutocomplete !== undefined) onChangeAutocomplete(newValue)
          setValue(newValue[id],)
          return
        }
        setValue(newValue)
      }}
      filterOptions={(options, params) => {
        // console.log(`[tablelib.AutoComplete.filterOptions] - params: ${JSON.stringify(params)}, options: ${JSON.stringify(options)}`)
        const filtered = filter(options, params)
        // adauga valoare la lista filtered
        if (addnewitem !== undefined &&  params['inputValue'] !== '' && filtered.length === 0) {
          filtered.push(addnewitem(params['inputValue']))
          // console.log(`[tablelib.AutoComplete.filterOptions] - id: ${id}, filtered.length: ${filtered.length-1}, inputValue: ${params['inputValue']}, params: ${JSON.stringify(params)}, filtered: ${JSON.stringify(filtered)}`)
          //  filtered.push({
          //   inputValue: params[id],
          //   item: `Add "${params[id]}"`,
          // })
       }
        return filtered
      }}
      // id={`autocomplete${index}`}
      options={data}
      getOptionSelected={option => {
        // e.g value selected with enter, right from the input
        if(readOnly) return
        if (typeof option === 'string') {
          return option
        }
        if (option[id]) {
          return option[id]
        }
        // console.log(`[tablelib.AutoComplete.getOptionSelected] - option: ${JSON.stringify(option)}, value: ${value}, defaultValue: ${defaultValue}, id: ${id}`)
        return option[id]
      }}
      getOptionLabel={option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        if (option[id]) {
          return option[id]
        }
        // console.log(`[tablelib.AutoComplete.getOptionLabel] - option: ${option}, value: ${value}, defaultValue: ${defaultValue}, id: ${id}`)
        return option[id]
      }}
      renderOption={option => option[id]}
      // style={{ width: '98%' }}     
      renderInput={params => (
        <>
        <InputLabel style={{paddingBottom:'0.0001rem'}} >{text}</InputLabel>
        <TextField {...params} placeholder={placeholder} variant = 'outlined' style= {{width: width, height:'3.5rem'}} 
          //fullWidth style={{width:width}} //label={text} variant="outlined" 
          // ref = {input => autocomplete = input}
          // InputProps={{}}
          // InputProps={{style: { width: width, height:'2.5rem',top:'0.5rem'}}}
          // InputProps={!searching ? {style: {height:'2.5rem',}} : {}} open = {true}
          />
        </>
      )}
    />
  )
}

