export default [
  {label: 'Date utilizator', text: 'Date utilizator', groupcol: ['cnp', 'username', 'email', 'telefon', 'activ', 'parola', ] },
  {label: 'Drepturi', text: 'Drepturi', groupcol: ['responsabilbusiness', 'responsabilmanagement', 'responsabilutilizatori',
    'contabil', 'superadmin', 'pacient', 'terapeut', //'liber', 
    'adminapp', 'tester', 'developer', 'modelare', ]},
  {label: 'dbinfo', text: 'db info', groupcol: ['utilizatorid', 'appid', ]},
  {label: 'toate', text: 'toate', groupcol: []},
]














