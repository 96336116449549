// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2}) => ([
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'date', Header: 'Data', accessor: 'date', width: 15, type: 'date',},
        { id: 'day', Header: 'day', accessor: 'day', width: 10, type: 'number', align: 'left', },
        { id: 'month', Header: 'month', accessor: 'month', width: 10, type: 'number', align: 'left', },
        { id: 'monthname', Header: 'monthname', accessor: 'monthname', width: 10, type: 'number', align: 'left', },
        { id: 'year', Header: 'year', accessor: 'year', width: 10, type: 'number', align: 'left', },
        { id: 'ncode', Header: 'ncode', accessor: 'ncode', width: 10, type: 'number', align: 'left', },
        { id: 'code', Header: 'code', accessor: 'code', width: 10, type: 'number', align: 'left', },
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 10, type: 'number', align: 'left', },
        { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 10, type: 'number', align: 'left', },
        { id: 'collection', Header: 'collection', accessor: 'collection', width: 10, type: 'number', align: 'left', },
        { id: 'percent', Header: 'percent', accessor: 'percent', width: 10, type: 'number', align: 'left', },
        { id: 'value', Header: 'value', accessor: 'value', width: 10, type: 'number', align: 'left', },
        { id: 'therapistvalue', Header: 'therapistvalue', accessor: 'therapistvalue', width: 10, type: 'number', align: 'left', },
      ]
    },
    {
      Header: 'db info', columns: [     
      { id: 'level', Header: 'level', accessor: 'level', width: 30, type: 'number', align: 'right',  },
      ],
    },
])

