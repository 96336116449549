import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeloperationcodespecificraportInput!)
{
  fnDeloperationcodespecificraport(input: $input) {
    operationcodespecificraport {
      feloperationcodeid
      operationcodeid
      operationcodespecificraportid
    }
  }
}
`
