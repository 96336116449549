import React from 'react'
import colorTextIcon from '../../styles/themes/defaultTheme/theme'

export default ({ params, ...props}) => {
  const {width, height, dytext, rx, ry, fontSize, fontStyle, color, fill, title, text} = params

  const FILL = 'white'
  const COLORTEXT = colorTextIcon.theme_main.colorTextIconLabel
  // console.log(`[TextIcon] - COLORTEXT: ${COLORTEXT}`)
  const HEIGHTICON = '40'
  const FONTSIZETEXT = 'large'
  const DYTEXT = 18
  const RXYRECT = 12
  const XTEXT = '4px'
  const FONTFAMILY= 'font-family: "Open Sans", Arial, sans-serif'
  // const FONTFAMILY = 'Verdana, Arial, Helvetica, sans-serif'
  // const FONTFAMILY= 'Times New Roman, Times, serif'
  // const FONTFAMILY = fontWeight= 'bolder'
  // const FONTFAMILY= 'font-family: Lucida Console, Courier, monospace'
  // const FONTFAMILY= 'Hawaii 5-0, sans-serif'
  // const FONTFAMILY= 'Arial, Helvetica, sans-serif'
  // const OPACITY = '0.25'

  const heighticon = height === undefined ? HEIGHTICON : height
  // console.log(`height: ${height}, heighticon: ${heighticon}`)
  const ytext = `${heighticon - (dytext === undefined ? DYTEXT : dytext)}px`
  const fontSizetext = `${fontSize === undefined ? FONTSIZETEXT : fontSize}px`
  const fontStyletext = `${fontStyle === undefined ? 'normal' : fontStyle}`
  const colortext = `${color === undefined ? COLORTEXT : color}`
  const rxrect = `${rx === undefined ? RXYRECT : rx}px`
  const ryrect = `${ry === undefined ? RXYRECT : ry}px`

  return (
    <svg {...props} //version='1.1' id='Layer_1' //xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'  
      x='0px' y='0px' width={`${width}px`} height={`${heighticon}px`} viewBox={`0 0 ${width} ${heighticon}`}  >
      <g>
        {/* <rect x='1px' y='4px' rx={rxrect} ry={ryrect} width={`${width-12}px`} height={`${heighticon-14}px`}  
          fill={fill === undefined ? FILL : fill} stroke='black' strokeWidth='1' opacity={OPACITY} /> */}
        <rect x='1px' y='4px' rx={rxrect} ry={ryrect} width={`${width-12}px`} height={`${heighticon-14}px`}  
          fill={fill === undefined ? FILL : fill} stroke='black' strokeWidth='1' >
            <title>{`${title !== undefined ? title : ''}`}</title>
          </rect>

        <text x={XTEXT} y={ytext} fill={colortext} fontWeight= 'normal' 
          fontSize = {fontSizetext} fontStyle={fontStyletext} 
          fontFamily = {FONTFAMILY}> {text}</text>
        Sorry, your browser does not support inline SVG.
      </g>
    </svg>
  )
}



// import React from 'react'

// export default ({ params, ...props}) => {
//   const {width, height, dytext, rx, ry, fontSize, fontStyle, color, fill, title} = params

//   const heighticon = height === undefined ? '26' : height
//   // console.log(`height: ${height}, heighticon: ${heighticon}`)
//   const ytext = `${heighticon - (dytext === undefined ? 12 : dytext)}px`
//   const fontSizetext = `${fontSize === undefined ? 'small' : fontSize}px`
//   const fontStyletext = `${fontStyle === undefined ? 'italic' : fontStyle}`
//   const colortext = `${color === undefined ? 'black' : color}`
//   const rxrect = `${rx === undefined ? 8 : rx}px`
//   const ryrect = `${ry === undefined ? 8 : ry}px`
//   return (
//     <svg {...props} //version='1.1' id='Layer_1' //xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'  
//       x='0px' y='0px' width={`${width}px`} height={`${heighticon}px`} viewBox={`0 0 ${width} ${heighticon}`}  >
//       <g>
//         <rect x='1px' y='4px' rx={rxrect} ry={ryrect} width={`${width-12}px`} height={`${heighticon-14}px`}  
//           fill={fill === undefined ? 'Cornsilk' : fill} stroke='black' strokeWidth='1' opacity='0.25' />

//         <text x='4px' y={ytext} fill={colortext} 
//           fontSize = {fontSizetext} fontStyle={fontStyletext} fontWeight= 'bolder' 
//           fontFamily = 'Times New Roman, Times, serif'>{title}</text>
//         Sorry, your browser does not support inline SVG.
//       </g>
//     </svg>
//   )
// }


// import React from 'react'

// export default ({ params, ...props}) => {
//   const {width, height, dytext, rx, ry, fontSize, fontStyle, color, fill, title} = params

//   const FILL = 'black' 
//   const COLORTEXT = 'red'
//   const HEIGHTICON = '26'
//   const FONTSIZETEXT = '16'
//   const DYTEXT = 12
//   const RXYRECT = 8
//   const XTEXT = '4px'
//   const FONTFAMILY = 'Verdana, Arial, Helvetica, sans-serif'
//   // const FONTFAMILY = fontFamily = 'Times New Roman, Times, serif'>
//   // const FONTFAMILY = fontWeight= 'bolder'
//   // const FONTFAMILY = fontFamily = 'font-family: Lucida Console, Courier, monospace'>
//   // const FONTFAMILY = fontFamily = 'Hawaii 5-0, sans-serif'>
//   // const FONTFAMILY = fontFamily = 'Arial, Helvetica, sans-serif'>
//   const OPACITY = '0.05'

//   const heighticon = height === undefined ? HEIGHTICON : height
//   // console.log(`height: ${height}, heighticon: ${heighticon}`)
//   const ytext = `${heighticon - (dytext === undefined ? DYTEXT : dytext)}px`
//   const fontSizetext = `${fontSize === undefined ? FONTSIZETEXT : fontSize}px`
//   const fontStyletext = `${fontStyle === undefined ? 'normal' : fontStyle}`
//   const colortext = `${color === undefined ? COLORTEXT : color}`
//   const rxrect = `${rx === undefined ? RXYRECT : rx}px`
//   const ryrect = `${ry === undefined ? RXYRECT : ry}px`
  
//   return (
//     <svg {...props} //version='1.1' id='Layer_1' //xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'  
//       x='0px' y='0px' width={`${width}px`} height={`${heighticon}px`} viewBox={`0 0 ${width} ${heighticon}`}  >
//       <g>
//         <rect x='1px' y='4px' rx={rxrect} ry={ryrect} width={`${width-12}px`} height={`${heighticon-14}px`}  
//           fill={fill === undefined ? FILL : fill} stroke='black' strokeWidth='1' opacity={OPACITY} />

//         <text x={XTEXT} y={ytext} fill={colortext} 
//           fontSize = {fontSizetext} fontStyle={fontStyletext} 
//           fontFamily = {FONTFAMILY}>
//           {title}</text>
//         Sorry, your browser does not support inline SVG.
//       </g>
//     </svg>
//   )
// }


