import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare drepturi amos</li>
            <li>campurile <b>rolid, denumire, simbol, observatie</b> </li>
            <li>pentru campul <u>denumire</u> exista unicitate in tabela, iar campurile simbol si observatie sunt optional</li>
            <li>sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga rol nou [<b>+</b>], actualizeaza campuri, sterge rol, refresh date </li>
                <li><b>stergerea</b> este posibila doar daca rolul nu este deja utilizat</li>
                <li style={{color:'violet', }}>schimb date: Adaugarea unui nou rol este o operatie extrem de rara, cel mai probabil, implicand si actualizari in soft. Daca totusi are loc exista posibilitatea unui export a tabelei in format .xls din microaplicatia de utilizatori urmat de import din .xls in restul servciilor</li>
            </ul>
            <li>este posibil export in fisier xls <b>Rols.xls</b> si listare in format pdf</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminRols</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.Rols</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>RolId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Denumire</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Simbol</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>-</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Observatie</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vRols(orderBy: ROLID_ASC, condition: {}) (RolId, Denumire, Simbol, Observatie)</td>
                        <td className='td'>-</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addRol(p_denumire, p_simbol)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updRol(p_keyfield, p_value, p_rolId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_RolId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI[xls]</td>
                        <td className='td'>ups</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_upsRol(p_RolId, p_denumire, p_simbol, p_Observatie)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>
