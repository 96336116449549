/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddsubscriptionInput = {|
  clientMutationId?: ?string,
  pPatientid: number,
  pFirstname: string,
  pLastname: string,
  pTherapisttherapypriceid: number,
  pDate: any,
  pFromid: number,
  pCalendarid: number,
  pRoomid: number,
  pPatientnotes: string,
  pTherapistnotes: string,
  pCollectionid: number,
  pNtherapysession: number,
  pNbmonths: number,
  pPrice: number,
  pSubscriptionmodeid: number,
  pDescription: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddsubscriptionInput
|};
export type mutationAddMutationResponse = {|
  +fnAddsubscription: ?{|
    +subscription: ?{|
      +finalizat: boolean,
      +mofntherapysession: number,
      +name: string,
      +nbmonths: number,
      +ntherapysession: number,
      +ntherapysession1: ?number,
      +subscriptionid: any,
      +therapysessionid: number,
      +time: string,
      +time2Id: ?number,
      +timeid: number,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddsubscriptionInput!
) {
  fnAddsubscription(input: $input) {
    subscription {
      finalizat
      mofntherapysession
      name
      nbmonths
      ntherapysession
      ntherapysession1
      subscriptionid
      therapysessionid
      time
      time2Id
      timeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddsubscriptionPayload",
    "kind": "LinkedField",
    "name": "fnAddsubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mofntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nbmonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapysessionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "648ba29c3fac3cd43badb1e0664b0be4",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddsubscriptionInput!\n) {\n  fnAddsubscription(input: $input) {\n    subscription {\n      finalizat\n      mofntherapysession\n      name\n      nbmonths\n      ntherapysession\n      ntherapysession1\n      subscriptionid\n      therapysessionid\n      time\n      time2Id\n      timeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d0f7cf7d2ddffaa22f0e3a048d5ff50';

module.exports = node;
