
// /https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
export default async (array, callback, feedback, final) => {
    // console.log(`[asyncForEach] - array: ${JSON.stringify(array)}, callback: ${callback}, feedback: ${feedback}`)
    for (let index = 0; index < array.length; index++) {
        // console.log(`[asyncForEach.for] - index: ${index}, array[index]: ${JSON.stringify(array[index])}, callback: ${callback}, feedback: ${feedback}`)
        await callback(array[index], index, array).then(() => feedback !== undefined ? feedback(index) : index)
    }
    final !== undefined ? final() : Math.random()
}

  