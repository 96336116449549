
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'incasari', text: 'incasari', groupcol: [ 'zi01', 'zi02', 'zi03', 'zi04', 'zi05', 'zi06', 'zi07', 'zi08', 'zi09', 'zi10', 'zi11', 'zi12', 'zi13', 'zi14', 'zi15', 'zi16', 'zi17', 'zi18', 'zi19', 'zi20',  'zi21', 'zi22', 'zi23', 'zi24', 'zi25', 'zi26', 'zi27', 'zi28', 'zi29', 'zi30', 'zi31', 'total', 'totalcode',  ] }, //, 'month', 'monthname', 'year' 
    {label: 'procent incasari', text: 'procent', groupcol: [ 'pzi01', 'pzi02', 'pzi03', 'pzi04', 'pzi05', 'pzi06', 'pzi07', 'pzi08', 'pzi09', 'pzi10', 'pzi11', 'pzi12', 'pzi13', 'pzi14', 'pzi15', 'pzi16', 'pzi17', 'pzi18', 'pzi19', 'pzi20',  'pzi21', 'pzi22', 'pzi23', 'pzi24', 'pzi25', 'pzi26', 'pzi27', 'pzi28', 'pzi29', 'pzi30', 'pzi31', 'totaltherapist', 'totaltherapistcode',  ] }, //, 'month', 'monthname', 'year' 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'collectionid',   ]}, 
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  /*
// month
// year
// monthname
// 


*/
