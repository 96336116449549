// import React from 'react'

export default ({dbOptions, dbOptions1}) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'therapisttherapyprice',
      columns: [
        { id: 'therapist', Header: 'therapist', accessor: 'therapist', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '30rem', text: 'Alege terapeut ..'},},

        { id: 'therapyprice', Header: 'therapyprice', accessor: 'therapyprice', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '30rem', text: 'Alege terapie, pret ..'},},
        { id: 'percent', Header: 'percent', accessor: 'percent', width: 30, type: 'text',},
        { id: 'nordertherapyprice', Header: 'nordertherapyprice', accessor: 'nordertherapyprice', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'therapist',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
        { id: 'percenttherapists', Header: 'percenttherapists', accessor: 'percenttherapists', width: 30, type: 'number',},
        ]
    },
    {
      Header: 'therapyprice',
      columns: [
        { id: 'nametherapyprice', Header: 'nametherapyprice', accessor: 'nametherapyprice', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege terapie, pret ..'},},
        { id: 'descriptiontherapyprices', Header: 'descriptiontherapyprices', accessor: 'descriptiontherapyprices', width: 30, type: 'text',},
        { id: 'nordertherapyprices', Header: 'nordertherapyprices', accessor: 'nordertherapyprices', width: 30, type: 'text',},
        { id: 'time', Header: 'time', accessor: 'time', width: 30, type: 'text',},
        { id: 'price', Header: 'price', accessor: 'price', width: 30, type: 'text',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapisttherapypriceid', Header: 'therapisttherapypriceid', accessor: 'therapisttherapypriceid', width: 30, type: 'text',},
    { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30,  },
    { id: 'therapypriceid', Header: 'therapypriceid', accessor: 'therapypriceid', width: 30,  },
    { id: 'therapyid', Header: 'therapyid', accessor: 'therapyid', width: 30,  },
    { id: 'therapy2Id', Header: 'therapy2Id', accessor: 'therapy2Id', width: 30,  },
    { id: 'therapytypeid', Header: 'therapytypeid', accessor: 'therapytypeid', width: 30,  },
    { id: 'timeid', Header: 'timeid', accessor: 'timeid', width: 30,  },
    { id: 'time2Id', Header: 'time2Id', accessor: 'time2Id', width: 30,  },
  ],
},
])
