import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdextrascontInput!)
{
  fnUpdextrascont(input: $input) {
    extrascont {
      banca
      beneficiar
      collectionid
      contul
      credit
      date
      debit
      detalii
      detalii2
      extrascontid
      mngmentoperationdetailid
      operationcodeid
      operatiune
      norder
      ordonator
      procesat
    }
  }
}
`

