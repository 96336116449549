// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2, dbOptions3, }) => ([
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 20, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'cash flow',
      columns: [
        { id: 'date', Header: 'Data', accessor: 'date', width: 15, type: 'date',},
        // { id: 'year', Header: 'Data', accessor: 'year', width: 15, type: 'year',},
        { id: 'codeoperationcode', Header: 'Code', accessor: 'codeoperationcode', width: 30, type: 'text', align: 'left', },
        { id: 'description', Header: 'Venituri-cheltuieli', accessor: 'description', width: 60, type: 'text',},
        { id: 'cash', Header: 'CASA', accessor: 'cash', width: 15, type: 'text',},
        { id: 'pos', Header: 'POS', accessor: 'pos', width: 15, type: 'number', align: 'right', },
        { id: 'contcurent', Header: 'Cont curent', accessor: 'contcurent', width: 15, type: 'number', align: 'right', },
        { id: 'wallet', Header: 'Wallet', accessor: 'wallet', width: 15, type: 'text', align: 'right',},
        { id: 'none', Header: 'None', accessor: 'none', width: 15, type: 'number', align: 'right', },
        ]
    },
    {
      Header: 'db info', columns: [     
        { id: 'nameoperationcode', Header: 'nameoperationcode', accessor: 'nameoperationcode', width: 60, type: 'text', align: 'left', },
    ],
  },
])




