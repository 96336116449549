// import React from 'react'

export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'collectionss',
      columns: [
        { id: 'nameRo', Header: 'nameRo', accessor: 'nameRo', width: 30, type: 'text',},
        { id: 'nameEn', Header: 'nameEn', accessor: 'nameEn', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30,  },
  ],
},
]
