/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpsmngmentoperationInput = {|
  clientMutationId?: ?string,
  pMngmentoperationid: number,
  pDate: any,
  pHouseregisterprevious: number,
  pHouseregistercurrent: number,
  pMonthkindofid: number,
  pZndocument: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpsmngmentoperationInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpsmngmentoperation: ?{|
    +mngmentoperation: ?{|
      +date: any,
      +houseregistercurrent: any,
      +houseregisterprevious: any,
      +mngmentoperationid: any,
      +monthkindofid: number,
      +zndocument: number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpsmngmentoperationInput!
) {
  fnUpsmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
      zndocument
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpsmngmentoperationPayload",
    "kind": "LinkedField",
    "name": "fnUpsmngmentoperation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperation",
        "kind": "LinkedField",
        "name": "mngmentoperation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregistercurrent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregisterprevious",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthkindofid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zndocument",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "830c3a24cf9c2fc579ead9f366f79b16",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpsmngmentoperationInput!\n) {\n  fnUpsmngmentoperation(input: $input) {\n    mngmentoperation {\n      date\n      houseregistercurrent\n      houseregisterprevious\n      mngmentoperationid\n      monthkindofid\n      zndocument\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6507b300ede862c2907e5fe2d1ecda17';

module.exports = node;
