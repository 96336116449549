import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddappInput!)
{
  fnAddapp(input: $input) {
    app {
      appid
      denumire
      link
      ordonare
      simbol
    }
  }
}
`
