import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import formatdate from '../../../uioscar/formatdate'
import formatdateymd from "../../../uioscar/formatdateymd"
import groupcols from './groupcols'
import headers from './xls/headers'
import initiall from './initiall/initiall'
import months from '../../../enums/months'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUpdcurrentmonth from './mutationUpdcurrentmonth'
import mutationUps from './mutationUps'
import nulldate from '../../../enums/nulldate'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import search from './search'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'

// import configxlsreport from '../../../uioscar/xls/configxlsreport'



























// import xlsheaders from '../../../uioscar/xls/xlsheaders'

// import ToPdf from '../../../uioscar/pdf'
// import Readxlsfile from '../../../uioscar/readxlsfile'
// import mutationImp from './mutationImp'
// import mutationAdds from './mutationAdds'
// import SubComponent from '../utilizatoridrepturi/component'
// import subcomponenttitle from './subcomponenttitle'
// import wsnameimps from './xls/wsnameimps'
//import {stringify} from 'flatted'

export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, mngmentoperationid} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[dbMngementOperationDetails.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({dbOptions: dbdata.dbOptions, dbOptions1: dbdata.dbOptions1, 
    dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, }), 
    [dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, dbdata.dbOptions3, ])   
    const searchAddDialog = React.useMemo(() => search(), [])
    // const configreportAddDialog = React.useMemo(() => configxlsreport(xlsheaders([dbdata.dbvdata[0]])), [xlsheaders, ])  
    const initialAddDialog = React.useMemo( () => initiall({mngmentoperationid, dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, }), 
    [mngmentoperationid, dbdata.dbOptions2, dbdata.dbOptions3, ]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[dbMngementOperationDetails.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[dbMngementOperationDetails.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {mngmentoperationdetailid} = d.original
    //     // console.log(`[dbMngementOperationDetails.table.onrowSelected.map] - mngmentoperationdetailid: ${mngmentoperationdetailid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({mngmentoperationdetailid})
    //   })
    // console.log(`[dbMngementOperationDetails.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       <Dialog key={`SubComponent`} 
  //         params = {{...params, ...subcomponenttitle, utilizatorid: row.values.utilizatorid, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} />
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[dbMngementOperationDetails.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[dbMngementOperationDetails.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  // const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[dbMngementOperationDetails.table] - format: ${format}`)

  const onSearch = (item) => {
   
    const _date = new Date(item.data)
    const arrdate = formatdateymd(new Date(_date.getFullYear(), _date.getMonth()+1, 0)).split('-')
    // console.log(`[dbmngementOperations.table.onSearch] - arrdate: `, arrdate)
    params.setselmonthmng({selmonthid: parseInt(arrdate[1]), monthname: months[parseInt(arrdate[1])+1]})
    const input = {input: {  pKeyfield: 'monthmng', pValue: arrdate[1], pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    
    const {selyearmng} = params
    if(selyearmng !== parseInt(arrdate[0])) { // anul selectat
      params.setselyearmng(arrdate[0])
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', pValue: arrdate[0], pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.onSearch] - nextyear: ${arrdate[0]}`)
    }
  }

  // const [newxlsheaders, setnewxlsheaders] = React.useState(xlsheaders([dbdata.dbvdata[0]]))
  // const onConfigReport = (item) => {
  //   setnewxlsheaders([xlsheaders([dbdata.dbvdata[0]])[0].filter(header => item[header.field])])
  // }

  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
//   // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls) => {
    // console.log(`[dbMngementOperationDetails.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)).filter(item => item !== undefined) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[dbMngementOperationDetails.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }
//   const ondbCashflowExcellAdd = (xls, ) => {
//     // console.log(`[dbMngementOperationDetails.table.ondbCashflowExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
//     const inputs = xls
//     // .filter(item => item.sheet === wsnameimps[0] || item.sheet  === wsnameimps[1])
//     .filter(item => item.sheet  === wsnameimps[1])
//     .map (item => getimpinputs(item) )
//     .map(item => {
//       // console.log(`[dbMngementOperationDetails.table.ondbCashflowExcellAdd] - 1 - item: ${JSON.stringify(item)}`)
//       return item
//     })
//     // .filter(item => item !== undefined) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
//     // console.log(`[dbMngementOperationDetails.table.ondbCashflowExcellAdd] - 2 - appid: ${appid}, inputs[0]: ${JSON.stringify(inputs[0])}`)
//     // console.log(`[dbMngementOperationDetails.table.ondbCashflowExcellAdd] - 2 - appid: ${appid}, inputs[1]: ${JSON.stringify(inputs[1])}`)

//     const mutationxlss = [mutationImp, ] // list mutations pentru tratare sheets
//     // sheettodb([inputs[0]], mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
//     // sheettodb([inputs[1]], mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
//     sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
//  }

  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[2]:
        // console.log(`[dbMngementOperationDetails.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[dbMngementOperationDetails.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          return {input: {
            pMngmentoperationdetailid: parseInt(item.mngmentoperationdetailid), 
            pMngmentoperationid: parseInt(item.mngmentoperationid), 
            pOperatiune: item.operatiune !== undefined && item.operatiune !== '' ? item.operatiune : nullstring, 
            pNorder: parseInt(item.norder), 
            pOperationcodeid: parseInt(item.operationcodeid), 
            pCollectionid: parseInt(item.collectionid), 
            pNdocument: item.ndocument, 
            pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring, 
            pValue: parseFloat(item.value),  
            pDebit: item.debit !== undefined && item.debit !== '' ? parseInt(item.debit) : parseInt(nullint),  
            pCredit: item.credit !== undefined && item.credit !== '' ? parseInt(item.credit) : parseInt(nullint),  
          }}
        })
      default:
    }     
  }

//   // const transactions = []
//   const getimpinputs = ({sheet, xls}, ) => {
//     // console.log(`[dbMngementOperationDetails.table.getimpinputs] `)
//     // console.log(`[dbMngementOperationDetails.table.getimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
//     // fiecare sheet devine un inputs array pentru inregistrare in baza de date
//     let n = 0
//     switch(sheet) {
//       case wsnameimps[0]:
//         // console.log(`[dbMngementOperationDetails.table.getimpinputs] , sheet: ${sheet}, `)
//         // console.log(`[dbMngementOperationDetails.table.getimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
//         return xls
//           .map(item => {
//             // console.log(`[dbMngementOperationDetails.table.getimpinputs.map] - item: ${JSON.stringify(item)}`)
//             return {input: {
//             pDate: formatdate(item['Data']),                      // pDate: pDate, 
//             pOperatiune: item['Venituri/Cheltuieli'],             // pOperatiune: item.operatiune, 
//             pNorder: ++n, 
//             pOperationcode: item['Cod'] !== undefined ? item['Cod'].toString() : '',          // pOperationcodeid: parseInt(item.operationcodeid), 
//             pCollectionid: item['Cash'] !== undefined ? 1
//               : item['POS'] !== undefined ? 2
//               : item['Card'] !== undefined ? 2
//               : item['Cont crt.'] !== undefined ? 3
//               : item['Nedeductibil'] !== undefined ? 4
//               : 5,
//             pNdocument: 1, 
//             pDescription: nullstring, 
//             pValue: item['Cash'] !== undefined ? parseFloat(item['Cash'])
//               : item['POS'] !== undefined ? parseFloat(item['POS'])
//               : item['Card'] !== undefined ? parseFloat(item['Card'])
//               : item['Cont crt.'] !== undefined ? parseFloat(item['Cont crt.'])
//               : item['Nedeductibil'] !== undefined ? parseFloat(item['Nedeductibil'])
//               : 0,
//           }}
//         })
//         .filter(item => item.input.pOperationcode !== undefined)
//         .filter(item => item.input.pOperationcode !== '')
//         .filter(item => item.input.pOperationcode !== '1')
//         // .filter((item, index) => index < 3)
//         .map(item => {
//           // console.log(`[dbMngementOperationDetails.table.getimpinputs.map] - sheet: ${sheet}, item: ${JSON.stringify(item)}`)
//           return item
//         })
  
//         case wsnameimps[1]:
//           // console.log(`[dbMngementOperationDetails.table.getimpinputs] , sheet: ${sheet}, `)
//           // console.log(`[dbMngementOperationDetails.table.getimpinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
//             return xls
//               .map(item => {
//                 // console.log(`[dbMngementOperationDetails.table.getimpinputs.map] - item: ${JSON.stringify(item)}`)
//                 // {"__EMPTY":"Data","__EMPTY_1":"Cod","__EMPTY_2":"Venituri/Cheltuieli","__EMPTY_3":"Casa","__EMPTY_4":"POS","__EMPTY_5":"Cont crt.","__EMPTY_6":"Wallet","__EMPTY_8":"Luna"},
//                 return {input: {
//                 pDate: formatdate(item['__EMPTY']),                      // pDate: pDate, 
//                 pOperatiune: item['__EMPTY_2'],             // pOperatiune: item.operatiune, 
//                 pNorder: ++n, 
//                 pOperationcode: item['__EMPTY_1'] !== undefined ? item['__EMPTY_1'].toString() : '',          // pOperationcodeid: parseInt(item.operationcodeid), 
//                 pCollectionid: item['__EMPTY_3'] !== undefined ? 1 // Casa
//                   : item['__EMPTY_4'] !== undefined ? 2 //POS
//                   // : item['Card'] !== undefined ? 2
//                   : item['__EMPTY_5'] !== undefined ? 3 //Cont crt.
//                   : item['__EMPTY_6'] !== undefined ? 4 // Nedeductibil, Wallet
//                   : 5,
//                 pNdocument: 1, 
//                 pDescription: nullstring, 
//                 pValue: item['__EMPTY_3'] !== undefined ? parseFloat(item['__EMPTY_3'])// casa
//                   : item['__EMPTY_4'] !== undefined ? parseFloat(item['__EMPTY_4']) // POS
//                   // : item['Card'] !== undefined ? parseFloat(item['Card'])
//                   : item['__EMPTY_5'] !== undefined ? parseFloat(item['__EMPTY_5']) //Cont crt.
//                   : item['__EMPTY_6'] !== undefined ? parseFloat(item['__EMPTY_6']) // Nedeductibil, Wallet
//                   : 0,
//               }}
//             })
//             .filter(item => item.input.pOperationcode !== undefined)
//             .filter(item => item.input.pOperationcode !== '')
//             // .filter(item => item.input.pOperationcode !== '1')
//             .filter((item, index) => index > 0)
//             // .filter((item, index) => index < 3)
//             .map(item => {
//               // console.log(`[dbMngementOperationDetails.table.getimpinputs.map] - sheet: ${sheet}, item: ${JSON.stringify(item)}`)
//               return item
//             })
//           default:
//     }     
//   }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pMngmentoperationdetailid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }


  const ondbAdd = (item) => {
    // console.log(`[dbMngementOperationDetails.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pMngmentoperationid: params.mngmentoperationid !== undefined ? parseInt(params.mngmentoperationid) : parseInt(nullint) , 
      pDate: params.mngmentoperationid !== undefined ? nulldate : formatdate(item.date), 
      pOperatiune: item.operatiune !== undefined && item.operatiune !== '' ? item.operatiune : nullstring,
      // pNorder: item.norder !== '' ? parseInt(item.norder) : parseInt(nullint), 
      pOperationcodeid: parseInt(item.operationcodeid), 
      pCollectionid: parseInt(item.collectionid), 
      pNdocument: item.ndocument, 
      pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring, 
      pValue: parseFloat(item.value), pBusinessid: parseInt(nullint)
    },}
    // console.log(`[dbMngementOperationDetails.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError), ondbRefresh)
  }

  const ondbUpd = (field) => {
    // console.log(`[dbMngementOperationDetails.table.ondbUpd] - db Upd: {mngmentoperationdetailid, columnId, value}: ${JSON.stringify({mngmentoperationdetailid: field.mngmentoperationdetailid, columnId: field.columnId, value: field.value})},`)
    // console.log(`[dbMngementOperationDetails.table.ondbUpd] - db Upd: {mngmentoperationid, columnId, value}: ${JSON.stringify({mngmentoperationid: field.mngmentoperationid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId === 'selyear'  || field.columnId === 'selmonth') return
    if(field.columnId === 'selyearid') {
      params.setselyearmng(field.value.toString())
      params.setselmonthmng({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'selmonthid') {
      params.setselmonthmng({selmonthid: field.value.toString(), monthname: months[ field.value]})
      const input = {input: {  pKeyfield: 'monthmng', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else {
      if(field.columnId  !== 'fromhour' && field.columnId  !== 'tohour' && field.columnId  !== 'therapyprice'){
        const input = {input: {  pKeyfield: field.columnId, 
          pValue: field.columnId === 'activ' ? field.value.toString() : field.value, 
          pMngmentoperationdetailid: parseInt(field.mngmentoperationdetailid)},}
        // console.log(`[dbMngementOperationDetails.table.ondbUpd]- mngmentoperationdetailid: ${field.mngmentoperationdetailid}, input: ${JSON.stringify(input)}`)  
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError), )  
      } 
    } 
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {mngmentoperationdetailid}} = selectedFlatRow
      const input = {input: {pMngmentoperationdetailid: parseInt(mngmentoperationdetailid)},}
      // console.log(`[dbMngementOperationDetails.table.ondbDel]- mngmentoperationdetailid: ${mngmentoperationdetailid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError), ondbRefresh)    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[dbMngementOperationDetails.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ondbNext = () => { 
    const {selmonthmng: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const nextmonth = month < 12 ? month + 1 : 1
    // params.setselmonth(nextmonth.toString())
    params.setselmonthmng({selmonthid: nextmonth, monthname: months[ nextmonth+1]})
    const input = {input: {  pKeyfield: 'monthmng', pValue: nextmonth.toString(), pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  

    if(month >= 12) { // anul urmator
      const {selyearmng: selyear} = params
      const nextyear = parseInt(selyear) + 1
      params.setselyear(nextyear.toString())
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'yearmng', pValue: nextyear.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbNext] - nextyear: ${nextyear}`)
    }

  }
  const ondbPrev = () => { 
    const {selmonthmng: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const {selyearmng: selyear} = params
    if((selyear === 2021 && month > 1) || selyear > 2021){
      const prevmonth = month > 1 ? month - 1 : 12
      // params.setselmonth(prevmonth.toString())
      params.setselmonthmng({selmonthid: prevmonth, monthname: months[ prevmonth+1]})
      const input = {input: {  pKeyfield: 'monthmng', pValue: prevmonth.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  
    
      if(month === 1) { // anul precedent
        const prevyear = parseInt(selyear) - 1
        params.setselyear(prevyear.toString())
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: { pKeyfield: 'yearmng', pValue: prevyear.toString(), pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbNext] - prevyear: ${prevyear}`)
      }
    }
  }


  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[dbMngementOperationDetails.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[dbMngementOperationDetails.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  // const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
  const isresponsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false
  // const visible = params.subcompomponent === undefined
  // console.log(`[dbMngementOperationDetails.table] - isresponsabilmanagement: ${isresponsabilmanagement}, rols: ${JSON.stringify(rols)}`) 
  const {selmonthmng: {monthname}} = params
  
  const issearch = true
  // const isconfigreport = visible && isresponsabilmanagement

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      <h3 style={{margin: '1.875rem'}}>management operations</h3>
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title(params.selyearmng, monthname), groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(mngmentoperationid), onrowSelected,
          EditableCell: EditableCell, editabledata: appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilmanagement ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilmanagement) ? AddDialog : undefined, 
          searchDialogComponent: issearch ? {AddDialog, onSearch, searchAddDialog: searchAddDialog, 
            // handlenextPage: handlenextSearchPage, handleprevPage: handleprevSearchPage 
          } : undefined, 
          // configreportDialogComponent: isconfigreport ? {AddDialog, onConfigReport, configreportAddDialog: configreportAddDialog, 
          //   // handlenextPage: handlenextReportConfigPage, handleprevPage: handleprevReportConfigPage 
          // } : undefined, 
          importxlsfields: (isresponsabilmanagement) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          // importxlsextrasconts: (isresponsabilmanagement) ? {Readxlsfile, ondbExcellAdd: ondbCashflowExcellAdd, title:'2020.xls»', n, header:0} : undefined, 
          exportxlsfields: isresponsabilmanagement ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          // pdf: isresponsabilmanagement ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbNext, ondbPrev, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: isresponsabilmanagement && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true, visiblenexprev: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[14]-MngmentoperationDetails', wsnames: wsnames}} 
            headers={headers} dbdata ={{data: [dbdata.dbvdata1, dbdata.dbvdata2, dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
