import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'


const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vmonthkindofs === undefined ? null : dbdata.vmonthkindofs.edges.map(edge => {return{...edge.node, }})
  // console.log(`[adminMonthkindofs.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
      vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
        edges {
          node {
            monthkindofid
            name
            symbol
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

