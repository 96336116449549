import styled from 'styled-components'

export default styled.table`
color: blue;
hr {
    display: block;
    margin-top: 1.875em;
    margin-bottom: 1.875em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    color:red;    
    background-color:red;    
    width: 200%;
}
th, td {
    border-bottom: 1px solid black;
  }
.table {
    width: 100%;
}
.th {
    text-align: left;
    padding: 15px 10px 5px 5px;
    color: red;
}
.td {
    text-align: left;
    padding: 15px 10px 5px 5px;
    color: gray;
}
`
