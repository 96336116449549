/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VtherapistcollectionCondition = {|
  therapistcollectionid?: ?any,
  therapistid?: ?number,
  firstname?: ?string,
  lastname?: ?string,
  extensive?: ?string,
  symbol?: ?string,
  code?: ?string,
  phone?: ?string,
  email?: ?string,
  cnp?: ?string,
  percenttherapists?: ?any,
  collectionid?: ?number,
  nameRo?: ?string,
  nameEn?: ?string,
  symbolcollections?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VtherapistcollectionCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VtherapistcollectionCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vtherapistcollections(orderBy: THERAPISTCOLLECTIONID_ASC, condition: $condition) {
    edges {
      node {
        therapistcollectionid
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
        phone
        email
        cnp
        percenttherapists
        collectionid
        nameRo
        nameEn
        symbolcollections
      }
    }
  }
  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {
    edges {
      node {
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
        activ
      }
    }
  }
  vcollections(orderBy: COLLECTIONID_ASC) {
    edges {
      node {
        collectionid
        nameRo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTCOLLECTIONID_ASC"
          }
        ],
        "concreteType": "VtherapistcollectionsConnection",
        "kind": "LinkedField",
        "name": "vtherapistcollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistcollectionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapistcollection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapistcollectionid",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percenttherapists",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolcollections",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "activ": true
            }
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTID_ASC"
          }
        ],
        "concreteType": "VtherapistsConnection",
        "kind": "LinkedField",
        "name": "vtherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "COLLECTIONID_ASC"
          }
        ],
        "concreteType": "VcollectionsConnection",
        "kind": "LinkedField",
        "name": "vcollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VcollectionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vcollection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vcollections(orderBy:\"COLLECTIONID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "5d6cc41081ef8e0a52afb883057e4dce",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VtherapistcollectionCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vtherapistcollections(orderBy: THERAPISTCOLLECTIONID_ASC, condition: $condition) {\n    edges {\n      node {\n        therapistcollectionid\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n        phone\n        email\n        cnp\n        percenttherapists\n        collectionid\n        nameRo\n        nameEn\n        symbolcollections\n      }\n    }\n  }\n  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {\n    edges {\n      node {\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n        activ\n      }\n    }\n  }\n  vcollections(orderBy: COLLECTIONID_ASC) {\n    edges {\n      node {\n        collectionid\n        nameRo\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2bbf74093df25276d260ede6de40f50';

module.exports = node;
