import React from 'react'

import Logo from './header/logo'
import Home from './header/home'
import Help from './header/help'
import Oscar from './header/oscar'
import Oscar1 from './header/oscar1'
import Oscar2 from './header/oscar2'
import Oscar3 from './header/oscar3'
import Icons from './header/icons'
import Administrareicons from './header/administrareicons'
import Utilizatorlogin from '../utilizatorlogin/component'

export default ({ params, events }) => {

    return (
        <>
            <Logo />
            <Home params = {params} />
            <Oscar  params = {{...params, menuorder: 0}} events = {events} />
            <Oscar1 params = {{...params, menuorder: 1}} events = {events} />
            <Oscar2 params = {{...params, menuorder: 2}} events = {events} />
            <Oscar3 params = {{...params, menuorder: 3}} events = {events} />
            
            <Help params = {params} />
            <Utilizatorlogin params = {params} events = {events} />
            <Icons params = {params} events={events} />
            <Administrareicons params = {params} events={events} />                  
        </>
    )
}