/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VmngmentoperationmonthCondition = {|
  year?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  conditionyear?: ?VmngmentoperationmonthCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $conditionyear: VmngmentoperationmonthCondition
) {
  ...page_dbdata_HYQTE
}

fragment page_dbdata_HYQTE on Query {
  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {
    edges {
      node {
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
        phone
        email
        cnp
        percent
        activ
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
    edges {
      node {
        month
        year
        monthname
        shortmonthname
      }
    }
  }
  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
    edges {
      node {
        monthkindofid
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "activ": true
            }
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTID_ASC"
          }
        ],
        "concreteType": "VtherapistsConnection",
        "kind": "LinkedField",
        "name": "vtherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapistid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extensive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTH_DESC"
          }
        ],
        "concreteType": "VmngmentoperationmonthsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortmonthname",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTHKINDOFID_ASC"
          }
        ],
        "concreteType": "VmonthkindofsConnection",
        "kind": "LinkedField",
        "name": "vmonthkindofs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmonthkindofsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmonthkindof",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthkindofid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmonthkindofs(orderBy:\"MONTHKINDOFID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "5149255a87186ec489316887a787c462",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $conditionyear: VmngmentoperationmonthCondition\n) {\n  ...page_dbdata_HYQTE\n}\n\nfragment page_dbdata_HYQTE on Query {\n  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {\n    edges {\n      node {\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n        phone\n        email\n        cnp\n        percent\n        activ\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        shortmonthname\n      }\n    }\n  }\n  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {\n    edges {\n      node {\n        monthkindofid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ee3872a17ec96395d87263e06f0973e';

module.exports = node;
