import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare terapeuti, campurile atribut fiind: <b>therapistid, firstname, lastname, extensive, symbol, code, phone, email, cnp, percent, calendarcss</b> </li>
            <li>pentru campurile <u>firstname, lastname, extensive, symbol, code, phone, email, cnp</u> exista unicitate in tabela, campurile email si extensive sunt optionale</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga tip terapie noua [<b>+</b>], actualizare oricare dintre campurile <i>firstname, lastname, extensive, symbol, percent, calendarcss, </i>, sterge tip terapie si refresh date </li>
                <li><b>stergerea</b> este posibila doar daca terapistId nu este utilizat</li>
            </ul>
            <li>este posibil export in fisier xls <b>Therapists.xls</b> si listare in format pdf, cu recomandarea ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click pe <span role="img" aria-label="point_right">👉</span> permite <b>administrare lista de preturi terapeuti</b></li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.configTherapists</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: admin.therapists</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>other checks</th>
                        </tr>
                        <tr>
                            <td className='td'>therapistid</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>firstname</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk0</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>lastname</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk0</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>extensive</td>
                            <td className='td'>VARCHAR(10)</td>
                            <td className='td'>uk0</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>symbol</td>
                            <td className='td'>VARCHAR(25)</td>
                            <td className='td'>uk1</td>
                            <td className='td'>NULL</td>
                            <td className='td'>cod</td>
                        </tr>
                        <tr>
                            <td className='td'>code</td>
                            <td className='td'>VARCHAR(10)</td>
                            <td className='td'>uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>phone</td>
                            <td className='td'>VARCHAR(25)</td>
                            <td className='td'>uk3</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>email</td>
                            <td className='td'>VARCHAR(50)</td>
                            <td className='td'>uk4</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>percent</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>calendarcss</td>
                            <td className='td'>TEXT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                        </tr>
                            <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapists (therapistid, firstname, lastname, extensive, symbol, code, phone, email, CNP, percent, calendarcss)</td>
                        </tr>
                            <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>params: fn_addtherapist(p_firstname, p_lastname, p_symbol, p_code, p_phone, p_email, p_CNP, p_percent, p_calendarcss)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_updtherapist(p_keyfield, p_value, p_therapistid)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_deltherapist(p_therapistid)</td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>params: fn_upstherapist(p_therapistId, p_firstname, p_lastname, p_symbol, p_code, p_phone, p_email, p_CNP, p_percent, p_calendarcss)</td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>