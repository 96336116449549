
export default {
    AddDialogtitle: ['Adauga meniu App', 'Adauga inregistrare in tabela appmenus.'],
    columns: [
      {header: 'denumire', accessor: 'denumire', type: 'text', show: 'true', },
      {header: 'simbol', accessor: 'simbol', type: 'text', show: 'true', },
      {header: 'ordonare', accessor: 'ordonare', type: 'text', show: 'true', },
    ],
    initialdata: {
      simbol: '',
      ordonare: '',
      denumire: '',
      subRows: undefined,
    },    
  }