import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'


const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.voperationcodes === undefined ? null : dbdata.voperationcodes.edges.map(edge => {return{...edge.node, }})
  // console.log(`[configOperationcodes.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
      ...dbdata.voperationkindofs.edges.map(edge => {
        const {operationkindofid, name, } = edge.node
        return {id: 'operationkindofid', operationkindofid, nameoperationkindof: `${name}`}
    })
  ]
  //console.log(`[configOperationcodes.page] - dbOptions1: ${JSON.stringify(dbOptions)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VoperationcodeCondition, defaultValue: {}, }
    ) 
    {
      voperationcodes(orderBy: OPERATIONCODEID_ASC, condition: $condition) {
        edges {
          node {
            operationcodeid
            operationkindofid
            nameoperationkindof
            operationkindofsymbol
            norderoperationkindof
            name
            symbol
            code
            norder
          }
        }
      }
      voperationkindofs(orderBy: OPERATIONKINDOFID_ASC) {
        edges {
          node {
            operationkindofid
            name
            # symbol
            # norder
            # kindofid
            # namekindof
            # symbolkindof
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

