// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2, dbOptions3, }) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'extrascont',
      columns: [
        { id: 'date', Header: 'data procesarii', accessor: 'date', width: 10, type: 'date',},
        { id: 'operatiune', Header: 'operatiune [tip tranzactie]', accessor: 'operatiune', width: 20, type: 'text', align: 'left', },
        { id: 'debit', Header: 'debit', accessor: 'debit', width: 5, type: 'number', align: 'right', },
        { id: 'credit', Header: 'credit', accessor: 'credit', width: 5, type: 'number', align: 'right', },
        { id: 'operationcode', Header: 'code', accessor: 'operationcode', width: 20, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '30rem', text: 'Alege cod operatiune ..'},},
        { id: 'beneficiar', Header: 'beneficiar [plati]', accessor: 'beneficiar', width: 20, type: 'text', align: 'left', },
        { id: 'contul', Header: 'contul', accessor: 'contul', width: 20, type: 'text', align: 'left', },
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 5, type: 'text', align: 'left', },
        { id: 'collection', Header: 'collection', accessor: 'collection', width: 10, type: 'text', align: 'left',},
        // { id: 'procesat', Header: 'procesat', accessor: 'procesat', width: 10, radiobuttondanu: true, type: '', align: 'left',},
        { id: 'finalizat', Header: 'finalizat', accessor: 'finalizat', width: 10, radiobuttondanu: true, type: '', align: 'left',},
        // { id: 'banca', Header: 'banca', accessor: 'banca', width: 20, type: 'text', align: 'left', },
        // { id: 'detalii', Header: 'detalii', accessor: 'detalii', width: 20, type: 'text', align: 'left', },
        // { id: 'referinta', Header: 'referinta', accessor: 'referinta', width: 10, type: 'text', align: 'left', },
        // { id: 'detalii2', Header: 'detalii2', accessor: 'detalii2', width: 10, type: 'text', align: 'left', },
    ]
    },
    {
      Header: 'extrascont detail',
      columns: [
        { id: 'banca', Header: 'banca', accessor: 'banca', width: 20, type: 'text', align: 'left', },
        { id: 'detalii', Header: 'detalii tranzactie', accessor: 'detalii', width: 20, type: 'text', align: 'left', },
        // { id: 'referinta', Header: 'referinta', accessor: 'referinta', width: 10, type: 'text', align: 'left', },
        { id: 'detalii2', Header: 'adresa', accessor: 'detalii2', width: 10, type: 'text', align: 'left', },
        { id: 'ordonator', Header: 'ordonator [incasari]', accessor: 'ordonator', width: 20, type: 'text', align: 'left', },
      ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'value', Header: 'value', accessor: 'value', width: 10, type: 'number',align: 'right', },
        { id: 'ndocument', Header: 'ndocument', accessor: 'ndocument', width: 10, type: 'number',align: 'right', },
        { id: 'description', Header: 'description', accessor: 'description', width: 20, type: 'text',align: 'left', },
    ]
    },
    {
      Header: 'operationcode',
      columns: [
        { id: 'nameoperationcode', Header: 'nameoperationcode', accessor: 'nameoperationcode', width: 10, type: 'text',align: 'left', },
        { id: 'symboloperationcode', Header: 'symboloperationcode', accessor: 'symboloperationcode', width: 30, type: 'text', align: 'left', },
        { id: 'codeoperationcode', Header: 'codeoperationcode', accessor: 'codeoperationcode', width: 30, type: 'text', align: 'left', },
        { id: 'norderoperationcode', Header: 'norderoperationcode', accessor: 'norderoperationcode', width: 30, type: 'text', align: 'left', },
        ]
    },
    {
      Header: 'operationkindof',
      columns: [
        { id: 'nameoperationkindof', Header: 'nameoperationkindof', accessor: 'nameoperationkindof', width: 30, type: 'text',},
        { id: 'symboloperationkindof', Header: 'symboloperationkindof', accessor: 'symboloperationkindof', width: 30, type: 'text',},
        { id: 'norderoperationkindof', Header: 'norderoperationkindof', accessor: 'norderoperationkindof', width: 30, type: 'text',},
      ]
    },
    {
      Header: 'kindof',
      columns: [
        { id: 'namekindofs', Header: 'namekindofs', accessor: 'namekindofs', width: 30, type: 'text',},
        { id: 'symbolkindofs', Header: 'symbolkindofs', accessor: 'symbolkindofs', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'collection',
      columns: [
        { id: 'nameRocollection', Header: 'nameRocollection', accessor: 'nameRocollection', width: 30, type: 'text',},
        { id: 'nameEncollection', Header: 'nameEncollection', accessor: 'nameEncollection', width: 30, type: 'text',},
        { id: 'symbolcollection', Header: 'symbolcollection', accessor: 'symbolcollection', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'luna,anul', columns: [     
      { id: 'year', Header: 'year', accessor: 'year', width: 30, type: 'number', align: 'right',},
      { id: 'month', Header: 'month', accessor: 'month', width: 30, type: 'number', align: 'right',},
      { id: 'monthname', Header: 'monthname', accessor: 'monthname', width: 30, type: 'number', align: 'right',},
      { id: 'shortmonthname', Header: 'shortmonthname', accessor: 'shortmonthname', width: 30, type: 'number', align: 'right',},
    ],
  },
    {
      Header: 'db info', columns: [     
      { id: 'extrascontid', Header: 'extrascontid', accessor: 'extrascontid', width: 30, type: 'number', align: 'right',  },
      { id: 'operationcodeid', Header: 'operationcodeid', accessor: 'operationcodeid', width: 30, type: 'number', align: 'right',},
      { id: 'operationkindofid', Header: 'operationkindofid', accessor: 'operationkindofid', width: 30, type: 'number', align: 'right',},
      { id: 'kindofid', Header: 'kindofid', accessor: 'kindofid', width: 30, type: 'number', align: 'right',},
      { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'number', align: 'right',},
      { id: 'mngmentoperationdetailid', Header: 'mngmentoperationdetailid', accessor: 'mngmentoperationdetailid', width: 30, type: 'number', align: 'right',},
    ],
  },
])


