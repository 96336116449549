import React from 'react'
import Icon from '@mdi/react'
import { mdiDrag, mdiDeleteForever} from '@mdi/js';
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>extras cont, campurile atribut fiind: <b>extrasContId, Date, operatiune, norder, Debit, Credit, beneficiar, ordonator, contul, banca, detalii, referinta, detalii2, collectionid, operationcodeId, mngmentoperationdetailId, procesat</b> </li>
            <li>an, luna conform cash flow, </li>
            <li>campul norder este stabilit automat la momentul adaugarii in baza de date prin incrementarea valorii precedente pentru a realiza unicitatea <b>date, operatiune, norder, collectionid</b>. aceasta unicitate este utilizata pentru importul in tabela <i>mngementoperationdetails</i></li>
            <li>campurile operatiune, Debit, Credit, beneficiar, ordonator, contul, banca, detalii, referinta, detalii2, operationcodeId, mngmentoperationdetailId sunt optionale</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <li>procedura manuala prelucrare extras</li>
            <ul>
                <li>exista si posibilitatea de a adauga extras de cont manual [<b>+</b>] situatie in care se vor actualiza campurile <i>Date, operatiune, Debit, Credit, beneficiar, ordonator, contul, banca, detalii, referinta, detalii2, operationcodeId</i></li>
                <li>de asemenea exista si posibilitatea de a sterge inregistrare din extrasul de cont sau refresh date </li>
                <li style={{color: 'red'}}> pentru campul <b>operationcodeId</b> se foloseste un autocomplete care se populeaza cu o lista de valori. pentru popularea acestuia este necesara o analiza mai atenta a codului scris, ceva WHERE</li>
            </ul>
            <li>procedura specializata prelucrare extras de cont</li>
            <ul>
                <li>click buton <b>ING.xls</b> pentru a importa extras cont curent. Procedura seteaza automat campurile <b>collectionid = 3, avand semnificatia : cont curent si procesat = false</b>, avand semnificatia: NU este importat in tabela <i>mngementoperationdetails</i></li>
                <li>click buton <b>POS.xls</b> pentru a importa extras cont POS. Procedura seteaza automat campurile <b>collectionid = 2, avand semnificatia : POS si procesat = false</b>, avand semnificatia: NU este importat in tabela <i>mngementoperationdetails</i></li>
                <li>completarea corecta a campului <b>operationcodeId</b> este in sarcina operatorului. Dupa completarea acestui camp pentru toate operatiunile un click pe buton <Icon path={mdiDrag} title="adauga in DB" size={1} horizontal vertical rotate={180} color="black"/> va permite importul automat a tuturor inregistrarilor where procesat = false AND operationcodeid IS NOT NULL</li>
                <li>repetarea click pe butoanele <b>ING.xls</b> sau <b>POS.xls</b> permite actualizarea extrasului de cont, se pot adauga sau actualiza inregistari daca nu exista deja alte inregistari cu aceeasi data actualizate in tabela <i>mngementoperationdetails</i> : WHERE collectionid AND procesat = TRUE</li>
                <li>inregistarile avand <b>procesat = true nu pot fi actualizate sau sterse din baza de date</b></li>
                <li>este permisa procesarea individuala a inregistarilor din extras prin click pe radiobutton procesat: <b>da</b>: salveaza in tabela <i>mngementoperationdetails</i>, <b>nu</b>: sterge din tabela <i>mngementoperationdetails</i>. facilitatea este prevazuta pentru scenariul in care s-a gresit completarea camului <b>operationcodeid</b> </li>
                <li>click pe buton <Icon path={mdiDeleteForever} title="delete all" size={1} horizontal vertical rotate={180} color="black"/> permite stergerea tutoror inregistrarilor WHERE procesat = false </li>
            </ul>
            <li>este posibil export in fisier xls <b>ExtrasConts.xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.dbextrasconts</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.extrasConts</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>extrasContId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>date</td>
                            <td className='td'>DATE</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>camp component pentru unicitatea tranzactiilor</td>
                        </tr>
                        <tr>
                            <td className='td'>operatiune</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>camp component pentru unicitatea tranzactiilor</td>
                        </tr>
                        <tr>
                            <td className='td'>norder</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>camp completat automat de sistemul de import pentru unicitate in lista tranzactiilor</td>
                        </tr>
                        <tr>
                            <td className='td'>debit</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>plata</td>
                        </tr>
                        <tr>
                            <td className='td'>credit</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>incasare</td>
                        </tr>
                        <tr>
                            <td className='td'>beneficiar</td>
                            <td className='td'>VARCHAR(255)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>Beneficiar:</i> in coloana <i>Detalii tranzactii</i></td>
                        </tr>
                        <tr>
                            <td className='td'>ordonator</td>
                            <td className='td'>VARCHAR(255)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>Ordonator:</i> in coloana <i>Detalii tranzactii</i></td>
                        </tr>
                        <tr>
                            <td className='td'>contul</td>
                            <td className='td'>VARCHAR(50)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>in contul: / Din contul:</i> in coloana <i>Detalii tranzactii</i></td>
                        </tr>
                        <tr>
                            <td className='td'>banca</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>Banca:</i> in coloana <i>Detalii tranzactii</i></td>
                        </tr>
                        <tr>
                            <td className='td'>detalii</td>
                            <td className='td'>text</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>Detalii:</i> in coloana <i>Detalii tranzactii</i> din extras</td>
                        </tr>
                        <tr>
                            <td className='td'>referinta</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>prefix <i>Referinta:</i> in coloana <i>Detalii tranzactii</i> din extras</td>
                        </tr>
                        <tr>
                            <td className='td'>detalii2</td>
                            <td className='td'>text</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>fara prefix in coloana <i>Detalii tranzactii</i> din extras</td>
                        </tr>
                        <tr>
                            <td className='td'>collectionid</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>&#x0007B; cash, POS, cont curent, walletplata &#x0007D;, camp component pentru unicitatea tranzactiilor</td>
                        </tr>
                        <tr>
                            <td className='td'>operationcodeId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>code + &#x0007B;incasare, plata &#x0007D;  operationcodeId IS NOT NULL / IS NULL: se va importa / NU se va importa importat in tabela <i>mngementoperationdetails</i></td>
                        </tr>
                        <tr>
                            <td className='td'>mngmentoperationdetailId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>inregistarea corespunzatopare din tabela <i>mngementoperationdetails</i></td>
                        </tr>
                        <tr>
                            <td className='td'>procesat</td>
                            <td className='td'>BOOLEAN</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>procesat: true/false - a fost / Nu a fost importat in tabela <i>mngementoperationdetails</i></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vextrasConts(orderBy: date, extrasContid, condition: $condition) (extrasContid,  
                                <div>date, norder,</div>
                                <div>month, year, monthname, shortmonthname,</div>
                                <div>operatiune, Debit, Credit, beneficiar, ordonator, contul, banca, detalii, referinta, detalii2,</div>
                                <div>operationcodeId, nameoperationcode, symboloperationcode, codeoperationcode, norderoperationcode, </div>
                                <div>operationkindofId, nameoperationkindof, symboloperationkindof, norderoperationkindof,</div>
                                <div>kindofId, namekindofs, symbolkindofs,</div>
                                <div>mngmentoperationdetailId, ndocument, description, value</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: &#x0007B;year: year&#x0007D;, &#x0007B;month: month&#x0007D;, date !== undefined ? &#x0007B; date: date &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationyears(orderBy: YEAR_DESC) (year)</td>
                            <td className='td'>lista de valori [year]</td>
                        </tr> 
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationmonths(orderBy: MONTH_DESC, condition(year)) (month, year, monthname, shortmonthname)</td>
                            <td className='td'>lista de valori [month ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>voperationcodes(orderBy: OPERATIONCODEID_ASC) (operationcodeid, nameoperationkindof, name, symbol, code)</td>
                            <td className='td'>lista de valori [cod operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vcollections(orderBy: COLLECTIONID_ASC) (collectionid, nameRo)</td>
                            <td className='td'>lista de valori [cod operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addextrascont(p_date, p_operatiune, p_Debit, p_Credit, p_beneficiar, p_ordonator, p_contul, p_banca, p_detalii, p_referinta, p_detalii2, p_operationcodeId, p_mngmentoperationdetailId)</td>
                            <td className='td'>WHERE nu exista deja alte inregistari cu aceeasi data</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>xls</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_upsextrascont(p_extrasContId, p_date, p_operatiune, p_norder, p_Debit, p_Credit, p_beneficiar, p_ordonator, p_contul, p_banca, p_detalii, p_referinta, p_detalii2, p_operationcodeId, p_mngmentoperationdetailId, p_Procesat)</td>
                            <td className='td'>WHERE procesat = FALSE, cu alte cuvinte nu pot fi suprascrise daca sunt deja adaugate in mngmentOperationdetail SI nu exista deja alte inregistari cu aceeasi data</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_updextrascont(p_keyfield, p_value, p_extrascontId)</td>
                            <td className='td'>WHERE procesat = FALSE, cu alte cuvinte nu pot fi actualizate daca sunt deja adaugate in mngmentOperationdetail SI nu exista deja alte inregistari cu aceeasi data</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_delextrascont(p_extrascontId)</td>
                            <td className='td'>WHERE procesat = FALSE, cu alte cuvinte nu pot fi sterse daca sunt deja adaugate in mngmentOperationdetail</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_delextrasconts(p_year, p_month)</td>
                            <td className='td'>WHERE procesat = FALSE, cu alte cuvinte nu pot fi sterse daca sunt deja adaugate in mngmentOperationdetail</td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>imp</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_impextrascont(p_date, p_operatiune, p_Debit, p_Credit, p_field1, p_field2, p_field3, p_field4, p_field5, p_field6)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


