
import gridrows from './gridrows'

const theme_main = {        // structura pagina principala 
  bodycolor: "#666",        
  headercolor: '#d0007b',   
  headerbg:  "#cfffff",       
  contentbg: "#eff",        
  footerbg:  "#cfffff",       

  withTextIconMenu: '110',
  withTextIconReportMenu: '300',
  widthTextIconAdminMenu: '300',
  colorTextIconMenu: '#000',
  backgroundTextMenu: '#fff',
  backgroundTextMenuWorking: '#77ccdf',
  backgroundTextMenuUnterminated: '#ddd',
  // backgroundTextIconMenu: '#ddd',
  // backgroundTextIconMenuconst: '#eee',
  colorTextIconLabel: "white",
}

// defaultTheme
export default {
  theme: `defaultTheme`,
  theme_main: theme_main,
  gridrows : gridrows,
};





