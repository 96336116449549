
export default [
    [
        {field: 'therapistpatientid', value: 'therapistpatientid'},
        {field: 'therapistid', value: 'therapistid'},
        {field: 'firstname', value: 'firstname'},
        {field: 'lastname', value: 'lastname'},
        {field: 'extensive', value: 'extensive'},
        {field: 'symbol', value: 'symbol'},
        {field: 'code', value: 'code'},
        {field: 'phone', value: 'phone'},
        {field: 'email', value: 'email'},
        {field: 'cnp', value: 'cnp'},
        {field: 'percenttherapists', value: 'percenttherapists'},
        {field: 'patientid', value: 'patientid'},
        {field: 'firstnamepatient', value: 'firstnamepatient'},
        {field: 'lastnamepatient', value: 'lastnamepatient'},
        {field: 'extensivepatient', value: 'extensivepatient'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

// birthyearpatient
// weightpatient
// heightpatient
// phonepatient
// emailpatient
// cnppatient