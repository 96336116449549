import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddmngmentoperationdetailInput!)
{
  fnAddmngmentoperationdetail(input: $input) {
    mngmentoperationdetail {
      collectionid
      description
      mngmentoperationdetailid
      mngmentoperationid
      ndocument
      operatiune
      norder
      operationcodeid
      value
      debit
      credit  
      businessid
    }
  }
}
`
