import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddroomInput!)
{
  fnAddroom(input: $input) {
    room {
      etaj
      nameEn
      nameRo
      roomid
      symbol
    }
  }
}
`
