import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdkindofInput!)
{
  fnUpdkindof(input: $input) {
    kindof {
      kindofid
      name
      symbol
    }
  }
}
`

