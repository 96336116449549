

  // dbvdatas: arrays of sheets
  // every dbvdata in a sheet
  export default dbvdatas =>  ([Object.entries(dbvdatas[0])
    .map( item => ({field: item[0], value: item[0]}))])


// export default dbvdatas => {
//   // dbvdatas: arrays of sheets
//   // every dbvdata in a sheet
//   // console.log(`[xlsheaders] - dbvdatas: `, dbvdatas)
//   return ([Object.entries(dbvdatas[0])
//   .map( item => ({field: item[0], value: item[0]}))])
//   // console.log(`[xlsheaders] - aheaders: `, aheaders)

//   // const headers = []
//   // dbvdatas.map(dbvdata => {
//   //   const headerssheet = []
//   //   for (const property in dbvdata) {
//   //     headerssheet.push({field: property, value: property})
//   //   }
//   //   headers.push(headerssheet)
//   // })
//   // console.log(`[xlsheaders] - headers: `, headers)
//   // return headers
// }
