import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import {CompanyColumnStyle, DateColumnStyle, UsermodelatColumnStyle, VersionColumnStyle, DBColumnStyle} from '../../../styles/styles/footerstyle'
import packageJson from '../../../../package.json'
import enummapps from '../../../enums/mapps'
import company from '../../../static/company/company'
import enumRols from '../../../enums/rols'

const Footer = ({dbdata, params}) => {
    // const {utilizatorOscar: {utilizatorlogin: {modeleaza, }, utilizatormodelat: { isauthenticated, username: usernamemodelat, } }, } = params
    const {utilizatorOscar: {utilizatorlogin: {username}, }, } = params
    const { appid, setappid} = params
    const idservers = dbdata.vidservers.edges.map(edge => edge.node)
    // console.log(`[footer] - dbdata.vidservers.edges: ${JSON.stringify(dbdata.vidservers.edges)}` )
    const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const issuperadmin = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 : false
    const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    const visible = isadminApp || issuperadmin || isDeveloper
          
    const idserverelem = (elem) =>  idservers.filter(item => item.simbol === elem)
    const idserveritem = (elem) =>  elem.length > 0 ? elem[0].denumire: ''
    const host = idserveritem(idserverelem('host'))
    const hostIP = idserveritem(idserverelem('hostIP'))
    const port = idserveritem(idserverelem('port'))
    const cluster = idserveritem(idserverelem('cluster'))
    const DB = idserveritem(idserverelem('DB'))
    const felclona = idserveritem(idserverelem('clona'))
    // const passwd = idserveritem(idserverelem('passwd'))
    const portgraphQL = idserveritem(idserverelem('portgraphQL'))
    const _appid = appid === enummapps.mhappyworld.key ? appid : parseInt(idserveritem(idserverelem('appid')))

    React.useEffect(() => {
        // console.log(`[footer] - gappid: ${appid}: ${typeof(appid)}, _appid[DB]: ${_appid}: ${typeof(_appid)}, enummapps.mhappyworld.key: ${enummapps.mhappyworld.key} ${typeof(enummapps.mhappyworld.key)}, enummapps.mapps.key: ${enummapps.mapps.key} ${typeof(enummapps.mapps.key)}` )
        if(appid === enummapps.mapps.key) setappid(parseInt(_appid))
    },
    [_appid, appid, setappid])      

    // console.log(`[footermocha] company: ${company}`)
    return(
    <>
        <CompanyColumnStyle><span>{company}.</span></CompanyColumnStyle>
        {/* <span>FAQ</span> */}
        { visible ?
        <>
            <>
                {process.env.NODE_ENV !== 'production' ?
                    <DBColumnStyle>
                        <span>db: [<b>{felclona}</b>/{hostIP}:{port}] <b>{host}:{cluster}@{DB}:{portgraphQL}</b>, appid:<b>{_appid}</b> </span>
                    </DBColumnStyle>
                : <></>
                }
            </>

            <UsermodelatColumnStyle><span>{`user: ${username}`} </span></UsermodelatColumnStyle>
            {/* <UsermodelatColumnStyle><span>{`user: ${username} ${modeleaza ? `/: ${usernamemodelat}`: ''}`} </span></UsermodelatColumnStyle> */}

            <VersionColumnStyle>
                <span>{packageJson.status}: </span> 
                <span>{packageJson.version} </span>
            </VersionColumnStyle>
            <DateColumnStyle>
                <span>{packageJson.date} </span>
            </DateColumnStyle>
        </> : <></>
        }
    </>
    )
}

const fragments = {
    dbdata: graphql`
        # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
        fragment footer_dbdata on Query
        {
            vidservers 
            {
                edges {
                    node {
                    idserverid
                    denumire
                    simbol
                    }
                }
            }
        }
        `
    }

export default Relay.createFragmentContainer(Footer, fragments)
