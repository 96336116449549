import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $pWeek: Int!
  $pYear: Int!
  $pMonth: Int!
  $pDay: Int!
  $pTherapistid: Int!
  $pIsresponsabilbusiness: Int!
  # $conditioncalendarsessions: VcalendarsessionCondition
  # $conditionpos: VtotalpoCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh
    pWeek: $pWeek
    pYear: $pYear
    pMonth: $pMonth
    pDay: $pDay
    pTherapistid: $pTherapistid
    pIsresponsabilbusiness: $pIsresponsabilbusiness
    # conditioncalendarsessions: $conditioncalendarsessions
    # conditionpos: $conditionpos
  )  
}
`

