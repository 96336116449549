
export default ({operationkindofid, dbOptions, }) => ({
  AddDialogtitle: ['Adauga operation codes', ''],
    columns: [
      {header: 'name', accessor: 'name', type: 'text', show: 'true', },
      {header: 'nameoperationkindof', accessor: 'nameoperationkindof', accessorid: 'operationkindofid', type: '', show: operationkindofid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege fel operatiune ..',}},
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'code', accessor: 'code', type: 'text', show: 'true', },
      {header: 'norder', accessor: 'norder', type: 'text', show: 'true', },
    ],
    initialdata: {
      name: '',
      nameoperationkindof: '',
      operationkindofid: '',
      symbol: '',
      code: '',
      norder: '',
      subRows: undefined,
    },    
  })
