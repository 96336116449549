import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vutilizatorpivotrols === undefined ? null : dbdata.vutilizatorpivotrols.edges.map(edge => {return{...edge.node, }})
  // console.log(`[utilizatoridrepturi.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  const dbmappOptions = dbdata.vapps === undefined ? null : dbdata.vapps.edges.map(edge => {return{...edge.node, }})
      .map(item => {return {id: 'appid', appid: item.appid, app: `${item.denumire} - [${item.link}]`}})
  // console.log(`[utilizatoridrepturi.page] - dbmappOptions: ${JSON.stringify(dbmappOptions)}` )
  const dbvrols = dbdata.vrols === undefined ? null : dbdata.vrols.edges.map(edge => { return {rolid: edge.node.rolid, rol:edge.node.simbol.toLowerCase()}})
  // console.log(`[utilizatoridrepturi.page] - dbvrols: ${JSON.stringify(dbvrols)}` )
  
  return(
      <div style={backgrdStyle}>
          <div style={transbox}>
            <Table dbdata={dbvdata} dbdataOptions = {dbmappOptions}  params = {{...params, dbvrols, titles}} events={events} />
          </div>
      </div>
  )
}
const fragments = {
  dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
      pRefresh: {type: Boolean, defaultValue: false, } 
      pUtilizatorid: {type: BigInt, defaultValue: -1, } 
    )
    {
    vutilizatorpivotrols(condition: {utilizatorid: $pUtilizatorid}) {
      edges {
        node {
          utilizatorid
          utilizator
          username
          email
          cnp
          telefon
          datainceput
          datasfarsit
          activ
          parola
          app
          appid
          adminapp
          contabil
          developer
          liber
          responsabilutilizatori
          modelare
          pacient
          responsabilbusiness
          responsabilmanagement
          superadmin
          terapeut
          tester
          # grefresh
        }
      }
    }
    vapps {
      edges {
        node {
          appid
          denumire
          simbol
          link
          ordonare
        }
      }
    }  
    vrols {
      edges {
        node {
          rolid
          denumire
          simbol
          observatie
        }
      }
    }              
  }
  `
}

export default Relay.createFragmentContainer(Page, fragments)

