/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelhourInput = {|
  clientMutationId?: ?string,
  pHourid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelhourInput
|};
export type mutationDelMutationResponse = {|
  +fnDelhour: ?{|
    +hour: ?{|
      +hourid: any,
      +name: string,
      +symbol: ?string,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelhourInput!
) {
  fnDelhour(input: $input) {
    hour {
      hourid
      name
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelhourPayload",
    "kind": "LinkedField",
    "name": "fnDelhour",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Hour",
        "kind": "LinkedField",
        "name": "hour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4faf2c969ca459fa05461c5ad6a155f1",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelhourInput!\n) {\n  fnDelhour(input: $input) {\n    hour {\n      hourid\n      name\n      symbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1814e29c02b7e4fffd41f2d98d4096b';

module.exports = node;
