import React from 'react'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import query from './query'
import Component from './page'
// import enumRols from '../../../enums/rols'
import enumRols from '../../../enums/rols'

export default ({params, events}) => {
    // scenariul modelarii - neaplicabil pentyru ca acesta poate modela toti utilizatorii
    // in scenariul modelarii de catre utilizator utilizatorid din utilizatorOscar
    const {toggleHelp, utilizatormodeleazaid, dbkey, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
      const _refresh = !refresh
      setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }
        
    // const condition = {condition: {utilizatormodeleazaid: utilizatorid}}
    const condition = {condition: {utilizatormodeleazaid: utilizatormodeleazaid}}
    const variables = {...{pRefresh: refresh},  ...condition }
    // console.log(`[utilizatorNemodelats.component] - {utilizatorid, isresponsabilutilizatori, rols}: ${JSON.stringify({utilizatorid, isresponsabilutilizatori, rols})}`)
    // console.log(`[utilizatorNemodelats.component] - utilizatorid: ${utilizatorid}`)
    // console.log(`[utilizatorNemodelats.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
