
export default ['key', 'nameoperationcode', 'symbolcollection', 
]


// extrascontcodificareid
// 
// operationcodeid
// 
// symboloperationcode
// codeoperationcode
// norderoperationcode
// collectionid
// nameRocollection
// nameEncollection
// 