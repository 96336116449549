// import React from 'react'

export default ({dbOptions, dbOptions1}) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'therapistcollection',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege terapeut ..'},},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        // { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        // { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        // { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
        // { id: 'percenttherapists', Header: 'percenttherapists', accessor: 'percenttherapists', width: 30, type: 'number',},
        { id: 'nameRo', Header: 'nameRo', accessor: 'nameRo', width: 30, type: 'text',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},
        // { id: 'nameEn', Header: 'nameEn', accessor: 'nameEn', width: 30, type: 'text',},
        // { id: 'symbolcollections', Header: 'symbolcollections', accessor: 'symbolcollections', width: 30, type: 'text',},
        ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapistcollectionid', Header: 'therapistcollectionid', accessor: 'therapistcollectionid', width: 30, type: 'text',},
    { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30,  },
    { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30,  },
  ],
},
])

