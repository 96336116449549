
// export const styleformControl = {
//     display: 'block',
//     width: '95%',
//     height: '34px',
//     fontSize: '14px',
//     lineHeight: '1.42857143',
//   }
//   export const styleformControlMesaj = {
//     display: 'block',
//     width: '97%',
//     height: '160px',
//     fontSize: '14px',
//     lineHeight: '1.42857143',
//     padding: '10px 10px 10px 10px',
//   }
//   export const styleformControlBookPrivate = {
//     display: 'block',
//     width: '97%',
//     height: '60px',
//     fontSize: '14px',
//     lineHeight: '1.42857143',
//     padding: '10px 10px 10px 10px',
//   }
  export const styleformControltextarea = {
    display: 'block',
    width: '97%',
    height: '700px',
    fontSize: '14px',
    lineHeight: '1.42857143',
    padding: '10px 10px 10px 10px',
  }
  export const inputstyle =  {width: '99%', height:'1.5rem', border: '1px solid gainsboro', textAlign:'left', background: 'aqua'} 
  export const editablecolumnstyle =  {color: 'green'} 
  export const uneditablecolumnstyle =  {color: 'black'} 
 
//   export const styleformControlparola = {
//     // display: 'block',
//     width: '10%',
//     height: '30px',
//     fontSize: '14px',
//     lineHeight: '1.42857143',
//   }