/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vtherapysessions: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapysessionid: ?any,
        +patientid: ?number,
        +firstnamepatient: ?string,
        +lastnamepatient: ?string,
        +extensivepatient: ?string,
        +birthyearpatient: ?number,
        +weightpatient: ?number,
        +heightpatient: ?number,
        +phonepatient: ?string,
        +emailpatient: ?string,
        +cnppatient: ?string,
        +therapistid: ?number,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +symbol: ?string,
        +code: ?string,
        +phone: ?string,
        +email: ?string,
        +cnp: ?string,
        +percenttherapists: ?any,
        +therapypriceid: ?number,
        +therapyidtherapyprice: ?number,
        +therapy2Idtherapyprice: ?number,
        +timeid: ?number,
        +symboltime: ?string,
        +minutestime: ?string,
        +unitstime: ?number,
        +time2Id: ?number,
        +symboltime2: ?string,
        +minutestime2: ?string,
        +unitstime2: ?number,
        +time: ?string,
        +price: ?number,
        +nametherapyprice: ?string,
        +date: ?any,
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
        +fromid: ?number,
        +fromhour: ?string,
        +symbolfromhour: ?string,
        +toid: ?number,
        +tohour: ?string,
        +symboltohour: ?string,
        +therapyid: ?number,
        +nameRotherapy: ?string,
        +nameEntherapy: ?string,
        +symboltherapy: ?string,
        +nordertherapy: ?number,
        +descriptiontherapy: ?string,
        +therapy2Id: ?number,
        +nameRotherapy2: ?string,
        +nameEntherapy2: ?string,
        +symboltherapy2: ?string,
        +nordertherapy2: ?number,
        +descriptiontherapy2: ?string,
        +therapytypeid: ?number,
        +nameRotherapytype: ?string,
        +nameEntherapytype: ?string,
        +symboltherapytype: ?string,
        +ntherapysessiontherapytype: ?number,
        +ntherapysession1Therapytype: ?number,
        +namesubscription: ?string,
        +ntherapysessionsubscription: ?number,
        +mofntherapysessionsubscription: ?number,
        +nbmonthssubscription: ?number,
        +finalizatsubscription: ?boolean,
        +timesubscription: ?string,
        +subscriptionid: ?any,
        +percent: ?any,
        +calendarid: ?number,
        +nameRocalendar: ?string,
        +nameEncalendar: ?string,
        +symbolcalendar: ?string,
        +roomid: ?number,
        +nameRoroom: ?string,
        +nameEnroom: ?string,
        +symbolroom: ?string,
        +etaj: ?number,
        +patientnotes: ?string,
        +therapistnotes: ?string,
        +collectionid: ?number,
        +nameRocollection: ?string,
        +nameEncollection: ?string,
        +symbolcollection: ?string,
        +value: ?any,
        +finalizat: ?boolean,
        +thereistherapistpatient: ?boolean,
        +therapistoperationcode: ?boolean,
        +operationcodeid: ?number,
        +nameoperationcode: ?string,
        +symboloperationcode: ?string,
        +codeoperationcode: ?string,
        +sessionsubscriptionid: ?number,
        +sessiontherapysessionid: ?number,
        +namesessionsubscription: ?string,
        +descriptionsessionsubscription: ?string,
      |}
    |}>
  |},
  +vtherapysessionyears: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any
      |}
    |}>
  |},
  +vtherapysessionmonths: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
      |}
    |}>
  |},
  +vpatients: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +patientid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
      |}
    |}>
  |},
  +vhours: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +hourid: ?any,
        +name: ?string,
        +symbol: ?string,
      |}
    |}>
  |},
  +vtherapisttherapyprices: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapisttherapypriceid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +symbol: ?string,
        +therapypriceid: ?number,
        +nameRotherapy: ?string,
        +therapytypeid: ?number,
        +time: ?string,
        +price: ?number,
        +nametherapyprice: ?string,
        +percent: ?any,
      |}
    |}>
  |},
  +vcalendars: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +calendarid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vrooms: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +roomid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vbusinesscollections: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +collectionid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vtherapies: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapyid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vsubscriptions: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +subscriptionid: ?any,
        +therapysessionid: ?number,
        +firstnamepatient: ?string,
        +lastnamepatient: ?string,
        +extensivepatient: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +nametherapyprice: ?string,
        +nameRotherapy: ?string,
        +description: ?string,
        +subscriptioncount: ?any,
      |}
    |}>
  |},
  +vtotalpos: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +date: ?any,
        +collectionid: ?number,
        +value: ?any,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapysessionid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstnamepatient",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastnamepatient",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensivepatient",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapypriceid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nametherapyprice",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortmonthname",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapyid",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRotherapy",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapytypeid",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionid",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percent",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarid",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomid",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRo",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionpos"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditiontherapysession"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionyear"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditiontherapysession"
        }
      ],
      "concreteType": "VtherapysessionsConnection",
      "kind": "LinkedField",
      "name": "vtherapysessions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapysessionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapysession",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "birthyearpatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "weightpatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "heightpatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phonepatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emailpatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cnppatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistid",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cnp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percenttherapists",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapyidtherapyprice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapy2Idtherapyprice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutestime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unitstime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time2Id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltime2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutestime2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unitstime2",
                  "storageKey": null
                },
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fromid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fromhour",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolfromhour",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "toid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tohour",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltohour",
                  "storageKey": null
                },
                (v18/*: any*/),
                (v19/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEntherapy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltherapy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nordertherapy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "descriptiontherapy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapy2Id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRotherapy2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEntherapy2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltherapy2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nordertherapy2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "descriptiontherapy2",
                  "storageKey": null
                },
                (v20/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRotherapytype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEntherapytype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboltherapytype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ntherapysessiontherapytype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ntherapysession1Therapytype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namesubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ntherapysessionsubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mofntherapysessionsubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nbmonthssubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "finalizatsubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timesubscription",
                  "storageKey": null
                },
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRocalendar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEncalendar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolcalendar",
                  "storageKey": null
                },
                (v24/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRoroom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEnroom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolroom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "etaj",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "patientnotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistnotes",
                  "storageKey": null
                },
                (v25/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRocollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEncollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolcollection",
                  "storageKey": null
                },
                (v26/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "finalizat",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thereistherapistpatient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistoperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationcodeid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameoperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "codeoperationcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sessionsubscriptionid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sessiontherapysessionid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namesessionsubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "descriptionsessionsubscription",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "YEAR_DESC"
        }
      ],
      "concreteType": "VtherapysessionyearsConnection",
      "kind": "LinkedField",
      "name": "vtherapysessionyears",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapysessionyearsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapysessionyear",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v15/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapysessionyears(orderBy:\"YEAR_DESC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionyear"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "MONTH_DESC"
        }
      ],
      "concreteType": "VtherapysessionmonthsConnection",
      "kind": "LinkedField",
      "name": "vtherapysessionmonths",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapysessionmonthsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapysessionmonth",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VpatientsConnection",
      "kind": "LinkedField",
      "name": "vpatients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VpatientsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vpatient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "HOURID_ASC"
        }
      ],
      "concreteType": "VhoursConnection",
      "kind": "LinkedField",
      "name": "vhours",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VhoursEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vhour",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hourid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vhours(orderBy:\"HOURID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "activtherapists": true,
            "therapytypeid": 1
          }
        }
      ],
      "concreteType": "VtherapisttherapypricesConnection",
      "kind": "LinkedField",
      "name": "vtherapisttherapyprices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapisttherapypricesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapisttherapyprice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapisttherapypriceid",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v22/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapisttherapyprices(condition:{\"activtherapists\":true,\"therapytypeid\":1})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CALENDARID_ASC"
        }
      ],
      "concreteType": "VcalendarsConnection",
      "kind": "LinkedField",
      "name": "vcalendars",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcalendarsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcalendar",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v23/*: any*/),
                (v27/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vcalendars(orderBy:\"CALENDARID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ROOMID_ASC"
        }
      ],
      "concreteType": "VroomsConnection",
      "kind": "LinkedField",
      "name": "vrooms",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VroomsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vroom",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v24/*: any*/),
                (v27/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vrooms(orderBy:\"ROOMID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "COLLECTIONID_ASC"
        }
      ],
      "concreteType": "VbusinesscollectionsConnection",
      "kind": "LinkedField",
      "name": "vbusinesscollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VbusinesscollectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vbusinesscollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v25/*: any*/),
                (v27/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vbusinesscollections(orderBy:\"COLLECTIONID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPYID_ASC"
        }
      ],
      "concreteType": "VtherapiesConnection",
      "kind": "LinkedField",
      "name": "vtherapies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapiesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapy",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v18/*: any*/),
                (v27/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapies(orderBy:\"THERAPYID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "subscriptionnotfull": true
          }
        }
      ],
      "concreteType": "VsubscriptionsConnection",
      "kind": "LinkedField",
      "name": "vsubscriptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VsubscriptionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vsubscription",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v21/*: any*/),
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v12/*: any*/),
                (v19/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriptioncount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vsubscriptions(condition:{\"subscriptionnotfull\":true})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionpos"
        }
      ],
      "concreteType": "VtotalposConnection",
      "kind": "LinkedField",
      "name": "vtotalpos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtotalposEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtotalpo",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v13/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfdd0d0082ac894ca285904a7c8c7f33';

module.exports = node;
