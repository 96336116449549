/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VtherapisttherapypriceCondition = {|
  therapisttherapypriceid?: ?any,
  therapistid?: ?number,
  firstname?: ?string,
  lastname?: ?string,
  extensive?: ?string,
  symbol?: ?string,
  code?: ?string,
  phone?: ?string,
  email?: ?string,
  cnp?: ?string,
  percenttherapists?: ?any,
  activtherapists?: ?boolean,
  therapypriceid?: ?number,
  therapyid?: ?number,
  therapy2Id?: ?number,
  therapytypeid?: ?number,
  timeid?: ?number,
  symboltime?: ?string,
  minutestime?: ?string,
  unitstime?: ?number,
  time2Id?: ?number,
  time?: ?string,
  price?: ?number,
  nametherapyprice?: ?string,
  descriptiontherapyprices?: ?string,
  nordertherapyprices?: ?number,
  nameRotherapy?: ?string,
  percent?: ?any,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VtherapisttherapypriceCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VtherapisttherapypriceCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vtherapisttherapyprices(orderBy: THERAPISTTHERAPYPRICEID_ASC, condition: $condition) {
    edges {
      node {
        therapisttherapypriceid
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
        phone
        email
        cnp
        percenttherapists
        therapypriceid
        therapyid
        therapy2Id
        therapytypeid
        timeid
        time2Id
        time
        price
        nametherapyprice
        descriptiontherapyprices
        nordertherapyprices
        percent
      }
    }
  }
  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {
    edges {
      node {
        therapistid
        firstname
        lastname
        extensive
        symbol
        code
      }
    }
  }
  vtherapyprices(orderBy: THERAPYPRICEID_ASC) {
    edges {
      node {
        therapypriceid
        nameRotherapy
        time
        price
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapypriceid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTTHERAPYPRICEID_ASC"
          }
        ],
        "concreteType": "VtherapisttherapypricesConnection",
        "kind": "LinkedField",
        "name": "vtherapisttherapyprices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapisttherapypricesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapisttherapyprice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapisttherapypriceid",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percenttherapists",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapyid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapy2Id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapytypeid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time2Id",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nametherapyprice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "descriptiontherapyprices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nordertherapyprices",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "activ": true
            }
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPISTID_ASC"
          }
        ],
        "concreteType": "VtherapistsConnection",
        "kind": "LinkedField",
        "name": "vtherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPYPRICEID_ASC"
          }
        ],
        "concreteType": "VtherapypricesConnection",
        "kind": "LinkedField",
        "name": "vtherapyprices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapypricesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapyprice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRotherapy",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapyprices(orderBy:\"THERAPYPRICEID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "57e6c19d4011a53857fec7092636f243",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VtherapisttherapypriceCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vtherapisttherapyprices(orderBy: THERAPISTTHERAPYPRICEID_ASC, condition: $condition) {\n    edges {\n      node {\n        therapisttherapypriceid\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n        phone\n        email\n        cnp\n        percenttherapists\n        therapypriceid\n        therapyid\n        therapy2Id\n        therapytypeid\n        timeid\n        time2Id\n        time\n        price\n        nametherapyprice\n        descriptiontherapyprices\n        nordertherapyprices\n        percent\n      }\n    }\n  }\n  vtherapists(condition: {activ: true}, orderBy: THERAPISTID_ASC) {\n    edges {\n      node {\n        therapistid\n        firstname\n        lastname\n        extensive\n        symbol\n        code\n      }\n    }\n  }\n  vtherapyprices(orderBy: THERAPYPRICEID_ASC) {\n    edges {\n      node {\n        therapypriceid\n        nameRotherapy\n        time\n        price\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d333dc98a7a5e4be8cee3b34b50531f';

module.exports = node;
