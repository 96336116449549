import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import formatdate from '../../../uioscar/formatdate'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  // const {isresponsabilbusiness, date} = params
  const {selyear, selmonth, selweek, isresponsabilbusiness, date} = params
  // console.log(`[dbCalendarsessions.page] - selweek: ${selweek}`)

  // JS dow [0, 1, 2, 3, 4, 5, 6] : [D, L, Ma, Mi, J, V, S]
  const firstdayyeardate = new Date(selyear, 0, 1)
  const firstdayfirstweek = new Date(firstdayyeardate.setDate(firstdayyeardate.getDate() + 7+1-firstdayyeardate.getDay()))
  // console.log(`[dbCalendarsessions.page] - week: [${firstdayfirstweek.getDay()} ${formatdate(firstdayfirstweek)}`)
  // const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const firstdayweek = new Date(firstdayfirstweek.setDate(firstdayfirstweek.getDate() + (selweek-1) * 7))
  const lastdayweek = new Date(firstdayfirstweek.setDate(firstdayfirstweek.getDate() + 7-1))
  // console.log(`[dbCalendarsessions.page] - selweek: ${selweek}:  [${firstdayweek.getDay()} ${formatdate(firstdayweek)} - ${lastdayweek.getDay()} ${formatdate(lastdayweek)}]`)

  // params.isTherapysession: false: calendar saptmanal terapeut / true - calendar terapeut la data ...
  const _therapist = dbdata.vtherapists.edges.filter(edge => parseInt(edge.node.therapistid) === params.businesstherapistid)[0]
  const therapist = _therapist !== undefined ?  `${_therapist.node.lastname} ${_therapist.node.firstname}${_therapist.node.extensive !==  null ? _therapist.node.nodeextensive : ''}` : ''  // [${_therapist.node.symbol}, ${_therapist.node.code}]
  // console.log(`[dbCalendarsessions.page] - therapist: ${therapist}, params.businesstherapistid: ${params.businesstherapistid}, _therapist: `, _therapist)

  const _dbdata = !params.isTherapysession ? dbdata.vcalendarfreeroomsessionsfunction : dbdata.vcalendarsessionsfunction
  // console.log(`[dbCalendarsessions.page] - _dbdata: `, _dbdata )
  const dbvdata = _dbdata === undefined || _dbdata.edges.length === 0 ? 
  [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '', selweek: selweek, date,
    firstdayweek: formatdate(firstdayweek), lastdayweek: formatdate(lastdayweek), therapist: '', therapistid: params.businesstherapistid,}]    
  : _dbdata.edges
  .map((edge, index) => {
  // console.log(`[dbCalendarsessions.page.dbvdata.map] - selweek: ${selweek}`)
  const {date, } = edge.node
  const dateitems = date.split('-')

  // console.log(`[dbCalendarsessions.page.dbvdata.map] - dateitems: ${dateitems}`)
  // console.log(`[dbCalendarsessions.page.dbvdata.map] - date: ${date}, typeof(date): ${typeof(date)}` )
  return{
    ...edge.node, 
    monthday: `${dateitems[1]}-${dateitems[2]}`,
    selyear: index === 0 ? selyear : '', selyearid: '', 
    selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
    week: index === 0 ? selweek : '', 
    firstdayweek: index === 0 ? formatdate(firstdayweek) : '', 
    lastdayweek: index === 0 ? formatdate(lastdayweek) : '', 
    therapist: index === 0 ? therapist : '', therapistid: index === 0 ? params.businesstherapistid: '',
    // patient: `${edge.node.firstnamepatient} ${edge.node.lastnamepatient} ${edge.node.extensivepatient !== null ? edge.node.extensivepatient : ''}`,
    // therapyprice: `${edge.node.nametherapyprice}, ${edge.node.time}, ${edge.node.price}RON [${edge.node.therapypriceid}]`,
    }
  })
  // console.log(`[dbCalendarsessions.page] - params.isTherapysession: ${params.isTherapysession}, dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbdatavpatients = isresponsabilbusiness ? dbdata.vpatients : dbdata.vtherapistpatients
  const dbOptions = [
    ...dbdatavpatients.edges.map(edge => {
      const {patientid, firstname, firstnamepatient, lastname, lastnamepatient, extensivepatient, extensive, } = edge.node
      return {id: 'patientid', patientid, 
        patient: `${isresponsabilbusiness ? firstname : firstnamepatient} 
        ${isresponsabilbusiness ? lastname : lastnamepatient} 
        ${isresponsabilbusiness ? (extensive !==  null ? extensive : '') : (extensivepatient !==  null ? extensivepatient : '')}`}
  })
]
//   const dbOptions = [
//     ...dbdata.vpatients.edges.map(edge => {
//       const {patientid, firstname, lastname, extensive, } = edge.node
//       return {id: 'patientid', patientid, patient: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}`}
//   })
// ]
// console.log(`[dbCalendarsessions.page] - isresponsabilbusiness: ${isresponsabilbusiness}, dbOptions: ${JSON.stringify(dbOptions)}` )
const dbvdata1 = [
  ...dbdata.vtherapists.edges.map(edge => {
    const {symbol, calendarcss, } = edge.node
    return {symbol, therapiststyle: calendarcss }
})
]
// console.log(`[dbCalendarsessions.page] - dbvdata1: ${JSON.stringify(dbvdata1)}` )
const dbOptions1 = [
  ...dbdata.vtherapists.edges.map(edge => {
    const {therapistid, firstname, lastname, extensive, symbol, code, calendarcss, } = edge.node
    return {id: 'therapistid', therapistid, 
      therapist: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}[${symbol}, ${code}]`,
      symbol, calendarcss}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
const dbOptions2 = [
  ...dbdata.vhours.edges.map(edge => {
    const {hourid, name, symbol, } = edge.node
    return {id: 'fromid', fromid: hourid, fromhour: `${name} ${symbol !== null ? `[${symbol}]` : ''}`}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
const dbOptions3 = [
  ...dbdata.vhours.edges.map(edge => {
    const {hourid, name, symbol, } = edge.node
    return {id: 'toid', toid: hourid, tohour: `${name} ${symbol !== null ? `[${symbol}]` : ''}`}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )
const dbOptions4 = [
  ...dbdata.vtherapisttherapyprices.edges.filter(edge => isresponsabilbusiness || edge.node.therapistid === params.pTherapistid)
  .map(edge => {
    const {therapisttherapypriceid, therapypriceid, nametherapyprice, nameRotherapy, time, price, firstname, lastname, symbol, percent, } = edge.node
    return {id: 'therapisttherapypriceid', therapisttherapypriceid, therapyprice: `${lastname}, ${firstname}, ${symbol}, ${nametherapyprice}, ${price}RON, ${time}, ${nameRotherapy}, ${percent}% [${therapypriceid}]`, }  //
    // const {therapypriceid, nametherapyprice, nameRotherapy, time, price, } = edge.node
    // return {id: 'therapypriceid', therapypriceid, therapyprice: `${nametherapyprice}, ${price}RON, ${time}, ${nameRotherapy}[${therapypriceid}]`, }  //
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions4: ${JSON.stringify(dbOptions4)}` )
const dbOptions5 = [
  ...dbdata.vcalendars.edges.map(edge => {
    const {calendarid, nameRo, } = edge.node
    return {id: 'calendarid', calendarid, calendar: `${nameRo}`}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions5: ${JSON.stringify(dbOptions5)}` )
const dbOptions6 = [
  // {id: 'roomid', roomid: 0, room: `null`},
  ...dbdata.vrooms.edges.map(edge => {
    const {roomid, nameRo, } = edge.node
    return {id: 'roomid', roomid, room: `${nameRo}`}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions6: ${JSON.stringify(dbOptions6)}` )
const dbOptions7 = [
  ...dbdata.vbusinesscollections.edges.map(edge => {
    const {collectionid, nameRo, } = edge.node
    return {id: 'collectionid', collectionid, nameRocollection: `${nameRo}`}
})
]
// // console.log(`[dbCalendarsessions.page] - dbOptions7: ${JSON.stringify(dbOptions7)}` )
// const dbOptions8 = [
//   ...dbdata.vtherapies.edges
//   // .filter(edge => edge.node.therapyid === 2)
//   .map(edge => {
//     const {therapyid, nameRo, } = edge.node
//     return {id: 'therapyid', therapyid, therapy: `${nameRo}[${therapyid}]`}
// })
// ]
// console.log(`[dbCalendarsessions.page] - dbOptions8: ${JSON.stringify(dbOptions8)}` )
const dbOptions9 = [
  ...dbdata.vtherapysessionyears.edges.map(edge => {
    const {year, } = edge.node
    return {id: 'selyearid', selyearid: year, selyear: year.toString()}
})
]
// console.log(`[dbCalendarsessions.page] - dbOptions9: ${JSON.stringify(dbOptions9)}` )

// const dbOptions10 = [
//   ...dbdata.vtherapysessionmonths.edges.map(edge => {
//     const { month, monthname, } = edge.node
//     return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
// })
// ]
// // console.log(`[dbCalendarsessions.page] - dbOptions10: ${JSON.stringify(dbOptions10)}` )

const dbOptions11 = [
  ...dbdata.vsubscriptions.edges.map(edge => {
    const { subscriptionid, firstnamepatient, lastnamepatient, //therapysessionid, extensivepatient, 
      // firstname, lastname, extensive, description, 
      nametherapyprice, nameRotherapy, subscriptioncount, } = edge.node
    return {id: 'subscriptionid', subscriptionid: subscriptionid, subscription: `${nametherapyprice}, ${nameRotherapy}, ${firstnamepatient} ${lastnamepatient} [${subscriptioncount}]`}
})
]
// console.log(`[dbTherapysessions.page] - dbOptions11: ${JSON.stringify(dbOptions11)}` )


// const dbvtotalpos = dbdata.vtotalpos === undefined ? null : dbdata.vtotalpos.edges.map(edge => {return{...edge.node, }})
// console.log(`[dbTherapysessions.page] - dbvtotalpos: ${JSON.stringify(dbvtotalpos)}` )
// console.log(dbvtotalpos)

  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbvdata1, dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4, dbOptions5, 
          dbOptions6, dbOptions7, dbOptions9, dbOptions11, }} params = {{...params, therapist, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        pWeek: {type: Int, defaultValue: 0}
        pYear: {type: Int, defaultValue: 2021}
        pMonth: {type: Int, defaultValue: 1}
        pDay: {type: Int, defaultValue: 1}
        pTherapistid: {type: Int, defaultValue: 0}
        pIsresponsabilbusiness: {type: Int, defaultValue: 0}
        # conditioncalendarsessions: {type: VcalendarsessionCondition, defaultValue: {}, }
        # conditionpos: {type: VtotalpoCondition, defaultValue: {}, }
    ) 
    {
      vcalendarfreeroomsessionsfunction(
        pWeek: $pWeek
        pYear: $pYear
        pTherapistid: $pTherapistid
      ) {
        edges {
          node {
            monthdayroomcalendarid
            date
            year
            month
            week
            day
            dow
            h0700
            h0715
            h0730
            h0745
            h0800
            h0815
            h0830
            h0845
            h0900
            h0915
            h0930
            h0945
            h1000
            h1015
            h1030
            h1045
            h1100
            h1115
            h1130
            h1145
            h1200
            h1215
            h1230
            h1245
            h1300
            h1315
            h1330
            h1345
            h1400
            h1415
            h1430
            h1445
            h1500
            h1515
            h1530
            h1545
            h1600
            h1615
            h1630
            h1645
            h1700
            h1715
            h1730
            h1745
            h1800
            h1815
            h1830
            h1845
            h1900
            h1915
            h1930
            h1945
            h2000
            h2015
            h2030
            h2045
            h2100
            h2115
            h2130
            h2145
          }
        }
      }
      # vcalendarsessions(condition: $conditioncalendarsessions) {
      #   edges {
      #     node {
      #       norder
      #       date
      #       terapeut
      #       therapistid
      #       therapistsymbol
      #       roomid
      #       calendarid
      #       symbolroom
      #       nameRocalendar
      #       month
      #       year
      #       week
      #       dow
      #       doy
      #       day
      #       monthname
      #       shortmonthname
      #       h0700
      #       h0715
      #       h0730
      #       h0745
      #       h0800
      #       h0815
      #       h0830
      #       h0845
      #       h0900
      #       h0915
      #       h0930
      #       h0945
      #       h1000
      #       h1015
      #       h1030
      #       h1045
      #       h1100
      #       h1115
      #       h1130
      #       h1145
      #       h1200
      #       h1215
      #       h1230
      #       h1245
      #       h1300
      #       h1315
      #       h1330
      #       h1345
      #       h1400
      #       h1415
      #       h1430
      #       h1445
      #       h1500
      #       h1515
      #       h1530
      #       h1545
      #       h1600
      #       h1615
      #       h1630
      #       h1645
      #       h1700
      #       h1715
      #       h1730
      #       h1745
      #       h1800
      #       h1815
      #       h1830
      #       h1845
      #       h1900
      #       h1915
      #       h1930
      #       h1945
      #       h2000
      #       h2015
      #       h2030
      #       h2045
      #       h2100
      #       h2115
      #       h2130
      #       h2145
      #     }
      #   }
      # }
      vcalendarsessionsfunction(
        pDay: $pDay
        pIsresponsabilbusiness: $pIsresponsabilbusiness
        pMonth: $pMonth
        pTherapistid: $pTherapistid
        pYear: $pYear
      ) {
        edges {
          node {
            norder
            date
            terapeut
            therapistid
            therapistsymbol
            roomid
            calendarid
            symbolroom
            nameRocalendar
            month
            year
            monthname
            shortmonthname
            week
            dow
            doy
            day
            h0700
            h0715
            h0730
            h0745
            h0800
            h0815
            h0830
            h0845
            h0900
            h0915
            h0930
            h0945
            h1000
            h1015
            h1030
            h1045
            h1100
            h1115
            h1130
            h1145
            h1200
            h1215
            h1230
            h1245
            h1300
            h1315
            h1330
            h1345
            h1400
            h1415
            h1430
            h1445
            h1500
            h1515
            h1530
            h1545
            h1600
            h1615
            h1630
            h1645
            h1700
            h1715
            h1730
            h1745
            h1800
            h1815
            h1830
            h1845
            h1900
            h1915
            h1930
            h1945
            h2000
            h2015
            h2030
            h2045
            h2100
            h2115
            h2130
            h2145
          }
        }
      }
      vtherapysessionyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vpatients { #(orderBy: PATIENTID_ASC)
        edges {
          node {
            patientid
            firstname
            lastname
            extensive
            # birthyear
            # weight
            # height
            # phone
            # email
            # cnp
          }
        }
      }
      vtherapistpatients(condition: {therapistid: $pTherapistid}) {
        edges {
          node {
            # therapistpatientid
            # therapistid
            # firstname
            # lastname
            # extensive
            # symbol
            # code
            # phone
            # email
            # cnp
            # percenttherapists
            patientid
            firstnamepatient
            lastnamepatient
            extensivepatient
            # birthyearpatient
            # weightpatient
            # heightpatient
            # phonepatient
            # emailpatient
            # cnppatient
          }
        }
      }
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            # phone
            # email
            # cnp
            # percent
            calendarcss
          }
        }
      } 
      vhours(orderBy: HOURID_ASC) {
        edges {
          node {
            hourid
            name
            symbol
          }
        }
      }
      vtherapisttherapyprices(condition: {activtherapists: true therapytypeid: 1}) {# therapistid: $pTherapistid
        edges {
          node {
            therapisttherapypriceid
            therapistid
            firstname
            lastname
            # extensive
            symbol
            # code
            # phone
            # email
            # cnp
            # percenttherapists
            therapypriceid
            # therapyid
            nameRotherapy
            # therapy2Id
            therapytypeid
            # timeid
            # symboltime
            # minutestime
            # unitstime
            # time2Id
            time
            price
            nametherapyprice
            # descriptiontherapyprice
            # nordertherapyprice
            percent
          }
        }
      }      
      # vtherapisttherapyprices {
      #   edges {
      #     node {
      #       # therapisttherapypriceid
      #       # therapistid
      #       # firstname
      #       # lastname
      #       # extensive
      #       # symbol
      #       # code
      #       # phone
      #       # email
      #       # cnp
      #       # percenttherapists
      #       therapypriceid
      #       # therapyid
      #       nameRotherapy
      #       # therapy2Id
      #       # therapytypeid
      #       # timeid
      #       # symboltime
      #       # minutestime
      #       # unitstime
      #       # time2Id
      #       time
      #       price
      #       nametherapyprice
      #       # descriptiontherapyprices
      #       # nordertherapyprices
      #       # percent
      #     }
      #   }
      # }      
      vcalendars(orderBy: CALENDARID_ASC) {
        edges {
          node {
            calendarid
            nameRo
            # nameEn
            # symbol
          }
        }
      }
      vrooms(orderBy: ROOMID_ASC) {
        edges {
          node {
            roomid
            nameRo
            # nameEn
            # symbol
            # etaj
          }
        }
      }
      vbusinesscollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }

      vtherapies(orderBy: THERAPYID_ASC) {
        edges {
          node {
            therapyid
            nameRo
            # nameEn
            # symbol
            # norder
            # description
          }
        }
      }
      vsubscriptions(condition: {subscriptionnotfull: true}) {
          edges {
            node {
              subscriptionid
              therapysessionid
              # date
              # patientid
              firstnamepatient
              lastnamepatient
              extensivepatient
              # birthyearpatient
              # weightpatient
              # heightpatient
              # phonepatient
              # emailpatient
              # cnppatient
              # therapistid
              firstname
              lastname
              extensive
              # symbol
              # code
              # phone
              # email
              # cnp
              # percenttherapists
              # therapypriceid
              nametherapyprice
              # therapyid
              nameRotherapy
              # nameEntherapy
              # symboltherapy
              # nordertherapy
              # descriptiontherapy
              # therapy2Id
              # nameRo2Therapy
              # nameEn2Therapy
              # symbol2Therapy
              # norder2Therapy
              # description2Therapy
              # therapytypeid
              # nameRotherapytype
              # nameEntherapytype
              # symboltherapytype
              # ntherapysessiontherapytype
              # ntherapysession1Therapytype
              # fromid
              # fromhour
              # symbolfromhour
              # toid
              # tohour
              # symboltohour
              # timeid
              # symboltime
              # minutestime
              # unitstime
              # time2Id
              # symboltime2
              # minutestime2
              # unitstime2
              # time
              # name
              # collectionid
              # nameRocollection
              # nameEncollection
              # symbolcollection
              # ntherapysession
              # ntherapysession1
              # mofntherapysession
              # nbmonths
              # finalizat
              # abonamentfinalizat
              # value
              # subscriptionmodeid
              # namesubscriptionmodes
              description
              subscriptioncount
            }
          }
        }
        # vtotalpos(condition: $conditionpos) {
        #   edges {
        #     node {
        #       date
        #       collectionid
        #       value
        #     }
        #   }
        # }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

