
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'management', text: 'management', groupcol: [ 'date', 'ndocument', 'nameoperationcode', 'debit', 'credit', 'norder', ] }, //, 'month', 'monthname', 'year' 
    // {label: 'soldregistrucasa', text: 'sold registru casa', groupcol: [ 'houseregisterprevious', 'houseregistercurrent', ] }, 
    // {label: 'code', text: 'code', groupcol: [ 'nameoperationcode',  'codeoperationcode', 'norderoperationcode', ] }, 
    // {label: 'operationkindof', text: 'operationkindof', groupcol: [ 'nameoperationkindof', 'norderoperationkindof', ] }, 
    // {label: 'kindof', text: 'kindof', groupcol: [ 'namekindofs', ] }, 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'mngmentoperationid',   ]}, //'selyearid', 'selmonthid', 'operationkindofid', 'kindofid',
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  
  
  
  
  
  