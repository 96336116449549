import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeloperationkindofInput!)
{
  fnDeloperationkindof(input: $input) {
    operationkindof {
      name
      norder
      operationkindofid
      symbol
      kindofid
    }
  }
}
`
