import React from 'react'

export default ({params: {isDeveloper}}) => 
<div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
    <ul>
    <li>amos3 page</li>
        <li>componenta amos3 page </li>
        <li>help va fi completat la momentul implementarii</li>
        <li>click link <b>help</b> pentru a ascunde help text </li>
        { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminApp</b></li>
                </>
            : <></>
            }
    </ul>
</div>