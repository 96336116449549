/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { utilizatorlogin_dbdata$ref } from "./utilizatorlogin_dbdata.graphql";
export type queryQueryVariables = {|
  pUsername?: ?string,
  pParola?: ?string,
  pUsernamermodelat?: ?string,
  pAppid: any,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: utilizatorlogin_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $pUsername: String
  $pParola: String
  $pUsernamermodelat: String
  $pAppid: BigInt!
) {
  ...utilizatorlogin_dbdata_wZkoY
}

fragment utilizatorlogin_dbdata_wZkoY on Query {
  loginutilizators(pUsername: $pUsername, pParola: $pParola) {
    edges {
      node {
        username
      }
    }
  }
  vutilizators(condition: {username: $pUsernamermodelat}) {
    edges {
      node {
        username
        utilizatorid
      }
    }
  }
  vutilizatorapps(condition: {username: $pUsernamermodelat}) {
    edges {
      node {
        appid
        app
        link
      }
    }
  }
  vutilizatorapprols(condition: {username: $pUsernamermodelat, appid: $pAppid}) {
    edges {
      node {
        username
        appid
        rolid
      }
    }
  }
  vutilizatortherapists(condition: {username: $pUsernamermodelat}) {
    edges {
      node {
        utilizatorid
        username
        therapistid
        lastname
        firstname
      }
    }
  }
  vappmenus(condition: {appid: $pAppid}, orderBy: ORDONARE_ASC) {
    edges {
      node {
        denumire
      }
    }
  }
  vcurrentmonths {
    edges {
      node {
        currentmonthid
        year
        month
        week
        day
        yearmng
        monthmng
        weekmng
        daymng
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pAppid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pParola"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pUsername"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pUsernamermodelat"
},
v4 = {
  "kind": "Variable",
  "name": "pParola",
  "variableName": "pParola"
},
v5 = {
  "kind": "Variable",
  "name": "pUsername",
  "variableName": "pUsername"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "username",
  "variableName": "pUsernamermodelat"
},
v8 = [
  {
    "fields": [
      (v7/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utilizatorid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appid",
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "appid",
  "variableName": "pAppid"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "pAppid",
            "variableName": "pAppid"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "pUsernamermodelat",
            "variableName": "pUsernamermodelat"
          }
        ],
        "kind": "FragmentSpread",
        "name": "utilizatorlogin_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "concreteType": "UtilizatorsConnection",
        "kind": "LinkedField",
        "name": "loginutilizators",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UtilizatorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Utilizator",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "VutilizatorsConnection",
        "kind": "LinkedField",
        "name": "vutilizators",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizator",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "VutilizatorappsConnection",
        "kind": "LinkedField",
        "name": "vutilizatorapps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorappsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatorapp",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "app",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              (v11/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "VutilizatorapprolsConnection",
        "kind": "LinkedField",
        "name": "vutilizatorapprols",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorapprolsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatorapprol",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "VutilizatortherapistsConnection",
        "kind": "LinkedField",
        "name": "vutilizatortherapists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatortherapistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatortherapist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapistid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              (v11/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "condition"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "ORDONARE_ASC"
          }
        ],
        "concreteType": "VappmenusConnection",
        "kind": "LinkedField",
        "name": "vappmenus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VappmenusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vappmenu",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "denumire",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VcurrentmonthsConnection",
        "kind": "LinkedField",
        "name": "vcurrentmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VcurrentmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vcurrentmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentmonthid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "year",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "week",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "day",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "yearmng",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthmng",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weekmng",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "daymng",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "168c847c213ff40b848c1eca93a3538e",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $pUsername: String\n  $pParola: String\n  $pUsernamermodelat: String\n  $pAppid: BigInt!\n) {\n  ...utilizatorlogin_dbdata_wZkoY\n}\n\nfragment utilizatorlogin_dbdata_wZkoY on Query {\n  loginutilizators(pUsername: $pUsername, pParola: $pParola) {\n    edges {\n      node {\n        username\n      }\n    }\n  }\n  vutilizators(condition: {username: $pUsernamermodelat}) {\n    edges {\n      node {\n        username\n        utilizatorid\n      }\n    }\n  }\n  vutilizatorapps(condition: {username: $pUsernamermodelat}) {\n    edges {\n      node {\n        appid\n        app\n        link\n      }\n    }\n  }\n  vutilizatorapprols(condition: {username: $pUsernamermodelat, appid: $pAppid}) {\n    edges {\n      node {\n        username\n        appid\n        rolid\n      }\n    }\n  }\n  vutilizatortherapists(condition: {username: $pUsernamermodelat}) {\n    edges {\n      node {\n        utilizatorid\n        username\n        therapistid\n        lastname\n        firstname\n      }\n    }\n  }\n  vappmenus(condition: {appid: $pAppid}, orderBy: ORDONARE_ASC) {\n    edges {\n      node {\n        denumire\n      }\n    }\n  }\n  vcurrentmonths {\n    edges {\n      node {\n        currentmonthid\n        year\n        month\n        week\n        day\n        yearmng\n        monthmng\n        weekmng\n        daymng\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a44f5629a1abbed8e4bd1aaf89a50df';

module.exports = node;
