import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
    table: { 
      fontSize: 12, 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, // tabela are border {left, top}
      borderRightWidth: 0, 
      borderTopWidth: 0, 
      borderBottomWidth: 0, 
      marginBottom: 20,
    }, 
    tableRow: { 
      // margin: 1, //"auto", 
      flexDirection: "row",
      // textAlign: 'left',
      // justifyContent: 'flex-start' 
    }, 
    tableCol: { 
      // width: "15%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      // borderLeftWidth: 0, 
      // borderRightWidth: 0, 
      // borderTopWidth: 0, 
      // borderBottomWidth: 0, 
      // textAlign: 'left',
    }, 
    tableCell: { 
      marginTop: 1, //4, 
      marginLeft: 1, //4, 
      marginRight: 1, //12, 
      //margin: "auto", 
      // borderTop: 0, // borderTopColor: 'red'
      color:'black',
      // justifyContent: 'center',
      // fontweight: 'bolder',
      // textAlign: 'center'
    },
    borders: 
      [
        {borderBottomWidth: 0, },
        {borderTopWidth: 0, borderBottomWidth: 0, },
        {borderLeftWidth: 0, }
      ]
    
  })
  
  