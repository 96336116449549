
export default [
    [
        {field: 'appid', value: 'appid'},
        {field: 'denumire', value: 'denumire'},
        {field: 'simbol', value: 'simbol'},
        {field: 'link', value: 'link'},
        {field: 'ordonare', value: 'ordonare'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
