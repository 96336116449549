import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery($pRefresh: Boolean!,
  # $condition: VappCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(#condition: $condition, 
  pRefresh: $pRefresh) 
}
`

