/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddtherapistpatientInput = {|
  clientMutationId?: ?string,
  pTherapistid: number,
  pPatientid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddtherapistpatientInput
|};
export type mutationAddMutationResponse = {|
  +fnAddtherapistpatient: ?{|
    +therapistpatient: ?{|
      +therapistpatientid: any,
      +therapistid: number,
      +patientid: number,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddtherapistpatientInput!
) {
  fnAddtherapistpatient(input: $input) {
    therapistpatient {
      therapistpatientid
      therapistid
      patientid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddtherapistpatientPayload",
    "kind": "LinkedField",
    "name": "fnAddtherapistpatient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapistpatient",
        "kind": "LinkedField",
        "name": "therapistpatient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistpatientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e8fc63472c07d1e5e437232fe48690e",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddtherapistpatientInput!\n) {\n  fnAddtherapistpatient(input: $input) {\n    therapistpatient {\n      therapistpatientid\n      therapistid\n      patientid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76f07911f29830fde92f3120a61306d8';

module.exports = node;
