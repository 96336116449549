
export default  [
    {label: 'Codificare extras cont', text: 'Codificare extras cont', groupcol: [ 'key', 'codeoperationcode', 'nameoperationcode', 'symbolcollection', 'nameRocollection', ] }, 
    {label: 'dbinfo', text: 'db info', groupcol: ['extrascontcodificareid', 'operationcodeid', 'collectionid',  ]},
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  
// 
// key
// 
// 
// symboloperationcode
// 
// norderoperationcode
// 
// nameRocollection
// nameEncollection
// 