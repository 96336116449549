import React from 'react'
import { Link } from 'react-router-dom' 
import enumRols from '../../../../enums/rols'

import enumAuth from '../../../../enums/authenticated'
import Icon from '@mdi/react'
import { mdiAccountConvert, } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/
import enummapps from '../../../../enums/mapps'

export default ({params, events}) => {
    const {appid, } = params
    const { utilizatorOscar : {utilizatormodelat : {isauthenticated, modeleaza = false, rols}}, } = params
    // const visible = appid !== enummapps.mhappyworld.key && modeleaza &&
    //     (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat

    const visible = appid !== enummapps.mhappyworld.key && modeleaza 
        && (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
        && (rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 ||  // care are rol 'responsabilbusiness'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 ||  // care are rol 'responsabilmanagement'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0 ||  // care are rol 'responsabilutilizatori'
            rols.filter((item) => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||  // care are rol 'AdminApp'
            rols.filter((item) => parseInt(item.rolid) === enumRols.superadmin.key).length > 0)  // care are rol 'superadmin'

    const rotating = false 

    const isadminApp = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||
        // rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0
    ) 
    : false
    // console.log(`[header.modelareicon] - isadminApp: ${isadminApp}, rols: ${JSON.stringify(rols)}`)
    return (
        <>
            {visible ? 
                <>
                 {isadminApp ?
                    <Link to='/modelareutilizatoradminApp' title='modelare utilizator pentru adminApp'>
                        {!rotating ? <Icon path={mdiAccountConvert} title="modelare utilizator" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiAccountConvert} title="modelare utilizator" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 
                : 
                    <Link to='/modelareutilizator' title='modelare utilizator'>
                        {!rotating ? <Icon path={mdiAccountConvert} title="modelare utilizator" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiAccountConvert} title="modelare utilizator" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 

                 }
                </>
                : <> </>
            } 
        </> 
    )

}
