
export default [
    [
        {field: 'therapytypeid', value: 'therapytypeid'},
        {field: 'nameRo', value: 'nameRo'},
        {field: 'nameEn', value: 'nameEn'},
        {field: 'symbol', value: 'symbol'},
        {field: 'ntherapysession', value: 'ntherapysession'},
        {field: 'ntherapysession1', value: 'ntherapysession1'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
