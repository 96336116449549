/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type queryQueryVariables = {|
  pRefresh: boolean
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery {
  ...page_dbdata_4636sT
}

fragment page_dbdata_4636sT on Query {
  vrols(condition: {}, orderBy: ROLID_ASC) {
    edges {
      node {
        rolid
        denumire
        simbol
        observatie
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pRefresh"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {}
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "ROLID_ASC"
          }
        ],
        "concreteType": "VrolsConnection",
        "kind": "LinkedField",
        "name": "vrols",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VrolsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vrol",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "denumire",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "simbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "observatie",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vrols(condition:{},orderBy:\"ROLID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "76c311dca9cba6ad6f8264c61ce503b4",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery {\n  ...page_dbdata_4636sT\n}\n\nfragment page_dbdata_4636sT on Query {\n  vrols(condition: {}, orderBy: ROLID_ASC) {\n    edges {\n      node {\n        rolid\n        denumire\n        simbol\n        observatie\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6865bf31adf37cd021b46b8072738e21';

module.exports = node;
