/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VmngmentoperationCondition = {|
  mngmentoperationid?: ?any,
  date?: ?any,
  houseregisterprevious?: ?any,
  houseregistercurrent?: ?any,
  zndocument?: ?number,
  month?: ?any,
  year?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
  monthkindofid?: ?number,
  namemonthkindof?: ?string,
  symbolmonthkindof?: ?string,
|};
export type VmngmentoperationmonthCondition = {|
  year?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VmngmentoperationCondition,
  conditionyear?: ?VmngmentoperationmonthCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VmngmentoperationCondition
  $conditionyear: VmngmentoperationmonthCondition
) {
  ...page_dbdata_3PFP5r
}

fragment page_dbdata_3PFP5r on Query {
  vmngmentoperations(condition: $condition, orderBy: DATE_ASC) {
    edges {
      node {
        mngmentoperationid
        date
        houseregisterprevious
        houseregistercurrent
        zndocument
        monthkindofid
        namemonthkindof
        symbolmonthkindof
        month
        monthname
        shortmonthname
        year
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
    edges {
      node {
        month
        year
        monthname
        shortmonthname
      }
    }
  }
  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
    edges {
      node {
        monthkindofid
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v3 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthkindofid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortmonthname",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "DATE_ASC"
          }
        ],
        "concreteType": "VmngmentoperationsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mngmentoperationid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "houseregisterprevious",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "houseregistercurrent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zndocument",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "namemonthkindof",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolmonthkindof",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTH_DESC"
          }
        ],
        "concreteType": "VmngmentoperationmonthsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTHKINDOFID_ASC"
          }
        ],
        "concreteType": "VmonthkindofsConnection",
        "kind": "LinkedField",
        "name": "vmonthkindofs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmonthkindofsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmonthkindof",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmonthkindofs(orderBy:\"MONTHKINDOFID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "61370586e556f36302e69489d44ddc82",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VmngmentoperationCondition\n  $conditionyear: VmngmentoperationmonthCondition\n) {\n  ...page_dbdata_3PFP5r\n}\n\nfragment page_dbdata_3PFP5r on Query {\n  vmngmentoperations(condition: $condition, orderBy: DATE_ASC) {\n    edges {\n      node {\n        mngmentoperationid\n        date\n        houseregisterprevious\n        houseregistercurrent\n        zndocument\n        monthkindofid\n        namemonthkindof\n        symbolmonthkindof\n        month\n        monthname\n        shortmonthname\n        year\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        shortmonthname\n      }\n    }\n  }\n  vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {\n    edges {\n      node {\n        monthkindofid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f8ad182aea8a51757663d9b9ca33edb';

module.exports = node;
