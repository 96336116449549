import React from 'react'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import query from './query'
import Component from './page'
import enumRols from '../../../enums/rols'

export default ({params, events}) => {
    const {dbkey, toggleHelp, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
// onRefresh, refresh, -------------------------------------------------------------------------------
const [refresh, setrefresh] = React.useState(true)
const onRefresh = () => {
  const _refresh = !refresh
  setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
}

    const condition = {condition:{}}
    const variables = {...{pRefresh: refresh},  ...condition }

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
