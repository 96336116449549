/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpstherapytypeInput = {|
  clientMutationId?: ?string,
  pTherapytypeid: number,
  pNameRo: string,
  pNameEn: string,
  pSymbol: string,
  pNtherapysession: number,
  pNtherapysession1: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpstherapytypeInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpstherapytype: ?{|
    +therapytype: ?{|
      +nameEn: string,
      +nameRo: string,
      +ntherapysession: number,
      +ntherapysession1: ?number,
      +symbol: ?string,
      +therapytypeid: any,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpstherapytypeInput!
) {
  fnUpstherapytype(input: $input) {
    therapytype {
      nameEn
      nameRo
      ntherapysession
      ntherapysession1
      symbol
      therapytypeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpstherapytypePayload",
    "kind": "LinkedField",
    "name": "fnUpstherapytype",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapytype",
        "kind": "LinkedField",
        "name": "therapytype",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameRo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapytypeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04fcb21da9e9bf2d6dca7bb80b7e7e35",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpstherapytypeInput!\n) {\n  fnUpstherapytype(input: $input) {\n    therapytype {\n      nameEn\n      nameRo\n      ntherapysession\n      ntherapysession1\n      symbol\n      therapytypeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b879a2d5f13c4c5cc1d040e7a6ba2fc7';

module.exports = node;
