import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddtherapysessionInput!)
{
  fnAddtherapysession(input: $input) {
    therapysession {
      calendarid
      collectionid
      date
      finalizat
      fromid
      patientid
      patientnotes
      percent
      roomid
      therapistid
      therapistnotes
      therapy2Id
      therapyid
      therapypriceid
      therapysessionid
      therapytypeid
      toid
      value
    }
  }
}
`
