import React from 'react'
import { Link } from 'react-router-dom' 
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuAdministrare1, } from '../../../../styles/styles/adminstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabilbusiness = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0) 
        : false
    const visible = isresponsabilbusiness && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuAdministrare1>
                {
                    visible ?
                        <> 
                            <Link to='/patients'>
                                <TextIcon params = {{ text: visible ? 'pacienti[9]'   : '', title:'patients',
                                    width: theme.theme_main.widthTextIconAdminMenu * 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/therapistpatients'>
                                <TextIcon params = {{ text: 'pacienti terapeut[10]', title:'therapistpatients', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare1>
        </>
    )
}



