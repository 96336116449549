import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddtherapistcollectionInput!)
{
  fnAddtherapistcollection(input: $input) {
    therapistcollection {
      collectionid
      therapistcollectionid
      therapistid
    }
  }
}
`
