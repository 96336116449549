import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddkindofInput!)
{
  fnAddkindof(input: $input) {
    kindof {
      kindofid
      name
      symbol
    }
  }
}
`
