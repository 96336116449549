/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddapprolutilizatorInput = {|
  clientMutationId?: ?string,
  pUtilizatorid: number,
  pAppid: number,
  pRolid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddapprolutilizatorInput
|};
export type mutationAddMutationResponse = {|
  +fnAddapprolutilizator: ?{|
    +approlutilizator: ?{|
      +appid: any,
      +approlutilizatorid: any,
      +rolid: any,
      +utilizatorid: any,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddapprolutilizatorInput!
) {
  fnAddapprolutilizator(input: $input) {
    approlutilizator {
      appid
      approlutilizatorid
      rolid
      utilizatorid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddapprolutilizatorPayload",
    "kind": "LinkedField",
    "name": "fnAddapprolutilizator",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Approlutilizator",
        "kind": "LinkedField",
        "name": "approlutilizator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approlutilizatorid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rolid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatorid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a54eae9e2b43c995e8d22e99c4323b7c",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddapprolutilizatorInput!\n) {\n  fnAddapprolutilizator(input: $input) {\n    approlutilizator {\n      appid\n      approlutilizatorid\n      rolid\n      utilizatorid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de3bc5d69b5ea886a081a3c3b334be34';

module.exports = node;
