import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare microplicatii si meniu portal</li>
            <li>campurile <b>denumire, simbol, link, ordonare</b> definesc complet o microaplicatie. </li>
            <li>pentru campul <u>denumire</u> exista unicitate in tabela, cu alte cuvinte nu pot exista doua microaplicatii cu acelasi nume</li>
            <li>campurile simbol si link sunt optionale. In situatia in care sunt definite vor fi utilizate de sistem drept text in  meniul portalului amos 2.0 si respectinv link la microaplicatia referita</li>
            {/* {false ? 
            <>
                <li>sunt posibile urmatoarele operatii: </li>
                <ul>
                    <li>adauga microaplicatie noua [<b>+</b>], actualizeaza campuri microaplicatie, sterge microaplicatie, refresh date microaplicatie</li>
                    <li>preluare date microaplicatii din tabela de import. </li>
                    <li><b>odata cu stergerea unei microaplicatii</b> vor fi sterse si drepturilor utilizatorilor la aceasta</li>
                </ul>
            </> 
            : <></>} */}
            {/* {false ? 
            <>
                <li>schimb date cu alte microaplicatii sau aplicatii externe(doar pentru superadmin): </li>
                <ul>
                    <li>1. microaplicatie utilizatori(appid: 3) : export date in fisier apps.xls : click buton xls » </li>
                    <li>2. respul microaplicatiilor(appid!==3) import fisier <b>apps.xls</b> - direct in tabela <b>apps</b> cu pastrare appid si actualizare sequence</li>
                </ul>
            </> 
            : <></>} */}
            <li>este posibil export in fisier xls <b>apps.xls</b> utilizat in schimbul de date de catre restul microaplicatiilor si listare in format pdf</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminApp</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.Apps</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>AppId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Denumire</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Simbol</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>-</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>link</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>ordonare</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vapps(orderBy: ORDONARE_ASC, condition: $condition) (AppId, Denumire, Simbol, link, ordonare, FALSE grefresh)</td>
                        <td className='td'>-</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addapp(p_denumire, p_simbol, p_link, p_ordonare)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updApp(p_keyfield, p_value, p_appId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_appId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI[xls]</td>
                        <td className='td'>ups</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_upsApp(p_AppId, p_denumire, p_simbol, p_link, p_ordonare)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>

