import React from 'react'
import { Link } from 'react-router-dom' 
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuAdministrare1, } from '../../../../styles/styles/adminstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isadminApp = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0) 
        : false
    const visible = isadminApp && appid === enummapps.utilizatoriapp.key

    return (
        <>
            <StyleMenuAdministrare1>
                {
                    visible ?
                        <> 
                            <Link to='/therapisttherapyprices'>
                                <TextIcon params = {{ text: 'servicii terapeut[7]', title: 'therapisttherapyprices', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 3/5, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/therapists'>
                                <TextIcon params = {{ text: visible ? 'terapeuti[5]'   : '', title: 'therapists', 
                                    width: theme.theme_main.widthTextIconAdminMenu*2/5, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare1>
        </>
    )
}

