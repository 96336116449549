
export default [
    [
        {field: 'operationkindofid', value: 'operationkindofid'},
        {field: 'name', value: 'name'},
        {field: 'symbol', value: 'symbol'},
        {field: 'namekindof', value: 'namekindof'},
        {field: 'symbolkindof', value: 'symbolkindof'},
        {field: 'kindofid', value: 'kindofid'},
        {field: 'norder', value: 'norder'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

