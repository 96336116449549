import formatdateymd from "../../../../uioscar/formatdateymd"

export default ({dbOptions2, dbOptions3, }) => ({
  AddDialogtitle: ['Adaugare cash flow', ''],
    columns: [
      {header: 'date', accessor: 'date', type: 'date', show: 'true', },
      // {header: 'operatiune', accessor: 'operatiune', type: 'operatiune', show: 'true', },

      {header: 'code', accessor: 'code', accessorid: 'operationcodeid', type: '', show: true,
      autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '33.3rem', text: 'Alege cod operatiune ..'},},

      {header: 'collection', accessor: 'collection', accessorid: 'collectionid', type: '', show: true,
      autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '33.3rem', text: 'Alege tip operatiune ..'},},
      
      {header: 'description', accessor: 'description', type: 'text', show: 'true', },
      {header: 'ndocument', accessor: 'ndocument', type: 'text', show: 'true', },
      {header: 'suma', accessor: 'value', type: 'number', show: 'true', },
    ],
    initialdata: {
      date: formatdateymd(new Date()), 
      // operatiune: '', 
      operationcodeid: '', 
      collectionid: '', 
      ndocument: '',  
      description: '', 
      value: '', 
      subRows: undefined,
    },    
  })

