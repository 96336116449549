import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper }}) =>  
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>isDeveloper: {isDeveloper}</li>
            <li>adauga la lista utilizatori modelati, utilizatori care nu sunt inca in lista respectiva</li>
            <li>vizualizeaza lista utilizatorilor nemodelati</li>
            <li>pentru a adauga unul sau mai multi utilizatori la lista utilizatori celor modelati, click optiunea <b>da</b> radiobutton din coloana <b>adauga</b></li> pentru toti utilizatorii, urmat de Refresh
            <li>pentru a vizualiza help utilizatori modelati, click meniu <b>help</b> inainte de click buton <b>LISTA UTILIZATORILOR MODELATI</b></li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
        </ul>
        { isDeveloper ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.UtilizatoriNemodelats</b>, subcomponent: components.<b>utilizatorcomponents.utilizatorModelats(utilizatorModelatid)</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.ModelareUtilizators</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>ModelareUtilizatorId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorModeleazaId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorModelatId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vutilizatornemodelats(orderBy: UTILIZATORMODELATID_ASC, condition: $condition) (utilizatormodeleazaid, utilizatormodelatid, utilizator, username, email, cnp, telefon, datainceput, datasfarsit, activ, parola)</td>
                        <td className='td'>, unde &#x0007B; condition: &#x0007B; utilizatormodeleazaid: utilizatormodeleazaid &#x0007D;  &#x0007D; </td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addModelareUtilizator(p_utilizatorModeleazaId, p_UtilizatorModelatId)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </div>
            : <></>
            }
    </div>
