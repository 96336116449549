
export default [
    {
        mngmentoperationdetailid: {field: 'mngmentoperationdetailid', values: ['', '', 'mngmentoperationdetailid'], width: 10, isLastRow : false},
        date: {field: 'date', values: ['', '', 'date'], width: 10, isLastRow : false},
        operationcodeid: {field: 'operationcodeid', values: ['', '', 'operationcodeid'], width: 10, isLastRow : false},
        nameoperationcode: {field: 'nameoperationcode', values: ['', '', 'Venituri/Cheltuieli'], width: 10, isLastRow : false},
        codeoperationcode: {field: 'codeoperationcode', values: ['', '', 'codeoperationcode'], width: 10, isLastRow : false},
        kindofid: {field: 'kindofid', values: ['', '', 'kindofid'], width: 10, isLastRow : false},
        namekindofs: {field: 'namekindofs', values: ['', '', 'namekindofs'], width: 10, isLastRow : false},
        ndocument: {field: 'ndocument', values: ['', '', 'ndocument'], width: 10, isLastRow : false},
        value: {field: 'value', values: ['', '', 'value'], width: 10, isLastRow : false},
        collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
    }, 
]

// norder
// mngmentoperationid
// 
// houseregisterprevious
// houseregistercurrent
// 
// 
// symboloperationcode
// 
// norderoperationcode
// operationkindofid
// 
// 
// symbolkindofs
// 
// 
// description
// 

// month
// monthname
// shortmonthname
// nameEncollection
// nameRocollection
// nameoperationkindof
// norderoperationkindof