/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VrraportlorenaCondition = {|
  date?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
  year?: ?any,
  codeoperationcode?: ?string,
  nameoperationcode?: ?string,
  level?: ?number,
  feloperationcodeid?: ?number,
  value?: ?any,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  conditionyear?: ?VrraportlorenaCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $conditionyear: VrraportlorenaCondition
) {
  ...page_dbdata_HYQTE
}

fragment page_dbdata_HYQTE on Query {
  voperationcodespecificraports {
    edges {
      node {
        operationcodespecificraportid
        operationcodeid
        nameoperationcode
        symboloperationcode
        code
        feloperationcodeid
        namefeloperationcode
        symbolfeloperationcode
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vrraportlorenas(condition: $conditionyear) {
    edges {
      node {
        date
        month
        monthname
        shortmonthname
        year
        codeoperationcode
        nameoperationcode
        level
        feloperationcodeid
        value
      }
    }
  }
  voperationcodes(orderBy: OPERATIONCODEID_ASC) {
    edges {
      node {
        operationcodeid
        operationkindofid
        nameoperationkindof
        operationkindofsymbol
        norderoperationkindof
        name
        symbol
        code
        norder
      }
    }
  }
  vfeloperationcodes(orderBy: FELOPERATIONCODEID_ASC) {
    edges {
      node {
        feloperationcodeid
        name
        symbol
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationcode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feloperationcodeid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VoperationcodespecificraportsConnection",
        "kind": "LinkedField",
        "name": "voperationcodespecificraports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationcodespecificraportsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationcodespecificraport",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationcodespecificraportid",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboloperationcode",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "namefeloperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolfeloperationcode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          }
        ],
        "concreteType": "VrraportlorenasConnection",
        "kind": "LinkedField",
        "name": "vrraportlorenas",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VrraportlorenasEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vrraportlorena",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortmonthname",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "codeoperationcode",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "level",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "OPERATIONCODEID_ASC"
          }
        ],
        "concreteType": "VoperationcodesConnection",
        "kind": "LinkedField",
        "name": "voperationcodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationcodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationcode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameoperationkindof",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofsymbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norderoperationkindof",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "FELOPERATIONCODEID_ASC"
          }
        ],
        "concreteType": "VfeloperationcodesConnection",
        "kind": "LinkedField",
        "name": "vfeloperationcodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VfeloperationcodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vfeloperationcode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vfeloperationcodes(orderBy:\"FELOPERATIONCODEID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "6b7d93a3d72224577b786f62b9c5d30d",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $conditionyear: VrraportlorenaCondition\n) {\n  ...page_dbdata_HYQTE\n}\n\nfragment page_dbdata_HYQTE on Query {\n  voperationcodespecificraports {\n    edges {\n      node {\n        operationcodespecificraportid\n        operationcodeid\n        nameoperationcode\n        symboloperationcode\n        code\n        feloperationcodeid\n        namefeloperationcode\n        symbolfeloperationcode\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vrraportlorenas(condition: $conditionyear) {\n    edges {\n      node {\n        date\n        month\n        monthname\n        shortmonthname\n        year\n        codeoperationcode\n        nameoperationcode\n        level\n        feloperationcodeid\n        value\n      }\n    }\n  }\n  voperationcodes(orderBy: OPERATIONCODEID_ASC) {\n    edges {\n      node {\n        operationcodeid\n        operationkindofid\n        nameoperationkindof\n        operationkindofsymbol\n        norderoperationkindof\n        name\n        symbol\n        code\n        norder\n      }\n    }\n  }\n  vfeloperationcodes(orderBy: FELOPERATIONCODEID_ASC) {\n    edges {\n      node {\n        feloperationcodeid\n        name\n        symbol\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8228da7b27333e2d292d5595a3ad2d3d';

module.exports = node;
