import React from 'react'
import DeniReactTreeView from 'deni-react-treeview'
import getTreeFromFlatData from './getTreeFromFlatData'
import { red } from '@material-ui/core/colors'
import './ThemeCustomization.scss'
// import { FaTrash, FaEdit, FaExternalLinkAlt,   } from 'react-icons/fa';
// import { FcExternal } from 'react-icons/fc';
import { FaExternalLinkAlt,  } from 'react-icons/fa';

export default ({dbdata, events: {onsetdata}}) => {

  const rootKey = dbdata.dbvdata.length !== 0 ? dbdata.dbvdata[0].parentid : 1
  // console.log(`[unitatetrees.tree] - rootKey: ${rootKey}` )
  const _treeData =  getTreeFromFlatData({
    flatData: dbdata.dbvdata.map(node => ({ ...node, 
      id: node.unitateid, 
      parentid: node.level === 1 ? rootKey : node.parentid, 
      text: node.simbol, text1: node.denumire, 
      // text: `[${node.ordine}] ${node.simbol}`, 
      // text1: `[${node.ordine}] ${node.denumire}`, 
      isLeaf: node.isleaf })),
    getKey: node => node.id, // resolve a node's key
    getParentKey: node => node.parentid, // resolve a node's parent's key
    rootKey: rootKey, // The value of the parent key when there is no parent (i.e., at root level)
    })
  // console.log(`[unitatetrees.tree] - dbdata.dbvdata: ${JSON.stringify(dbdata.dbvdata)}` )
  // console.log(`[unitatetrees.tree] - dbdata.dbvdata: ` )
  // console.log(dbdata.dbvdata)

  const [treeData, ] = React.useState(_treeData)
  // console.log(`[unitatetrees.tree] - treeData: ${JSON.stringify(treeData)}`)
  // console.log(`[unitatetrees.tree] - treeData: `)
  // console.log(treeData)
 
  const onSelectItemHandler = item => {
    // console.log(`[unitatetrees.tree.onSelectItem] - item : ${JSON.stringify(item)}`)
    onsetdata(item)
  }
  const onExpandedHandler = item => {
    // console.log(`[unitatetrees.tree.onExpandedHandler] - item : ${JSON.stringify(item)}`)
    onsetdata(item)
  }
  const onColapsedHandler = item => {
    // console.log(`[unitatetrees.tree.onColapsedHandler] - item : ${JSON.stringify(item)}`)
    onsetdata(item)
  }
  // const onCheckItemHandler = item => {
  // console.log(`[unitatetrees.tree.onCheckItemHandler] - item : ${JSON.stringify(item)}`)
  //   onsetdata(item)
  // }

  // const deleteItemClick = () => {
  //   alert('deleting routine here...')
  //   // this.refs.treeview.api.removeItem(id);
  // }

  // const editItemClick = () => {
  //   alert('editing routine here...')
  // }

  const onRenderItem = item => {
    // alert(`[onRenderItem] - item: ${JSON.stringify(item)}`)
    return (
      <>
      <div className="treeview-item-example">
        {/* {item.nivelunitateid === 3 ? <span>[Ext] <FaExternalLinkAlt size="15" /> </span> : <span> <FaEdit size="15" ></FaEdit> </span>} */}
        {item.nivelunitateid === 3 ? <span>[Ext] <FaExternalLinkAlt size="15" /> </span> : <span> </span>}
        {/* {item.nivelunitateid === 3 ? <span><FaExternalLinkAlt size="15" /> </span> : <span> </span>} */}
        <span className="treeview-item-example-text">{item.text}</span>
        {/* <span className="treeview-item-example-text">[{item.ordine}] {item.text}</span> */}
        {/* <span className={{...itemstyle, fontColor: 'green'}}>AA {item.nivelunitateid === 2 ? item.text : item.text}</span> */}
        {/* {/* <span className="actionButton trash" onClick={deleteItemClick}> <FaTrashO size="15" /></span> */}
        {/* <span className="actionButton edit" onClick={editItemClick}> <FaEdit size="15" /></span>  */}
        {/* <span className="actionButton trash" onClick={deleteItemClick}> <FaTrash size="15" /></span> */}
        {/* <span className="actionButton trash" onClick={deleteItemClick}> <FcExternal size="15" /></span> */}
       </div>
      </>
    )
  }

  return (
    <>
      {/* <button onClick={ removeSelectedItem }>Click to remove the select item</button> */}
      <div className="theme-customization">
        <DeniReactTreeView className="treeview-teste" 
          items={ treeData } onSelectItem={ onSelectItemHandler }
          onExpanded={onExpandedHandler} onColapsed={onColapsedHandler}
          onRenderItem = {onRenderItem}
          // onCheckItem={onCheckItemHandler}
          // marginItems="20" // showCheckbox={ true } // showRoot={ true } // showIcon={ false } // theme="metro" 
          style={{ color: red, marginRight: '.10rem', marginBottom: '.10rem', width: '80rem', height: '30rem' }}
        />
      </div>
    </>
  )    
}


