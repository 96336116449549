import React from 'react'
import { QueryRenderer } from 'react-relay'
import environment from '../../uioscar/environment'

const QueryComponent = ({ children, query = null, variables = {}, dbkey }) => {
  // console.log(`[QueryComponent] - variables: ${JSON.stringify({variables})}`)
  // console.log(`[QueryComponent] - query: ${JSON.stringify({query})}`)
  // console.log(`[QueryComponent] - query: ${JSON.stringify({query})}`)

  const render = ({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>
        }
        if (!props) {
          return <div>Loading...</div>
        }
        // console.log(`[QueryComponent.QueryRenderer.render] - props: ${JSON.stringify(props)}, variables: ${JSON.stringify({variables})}`)

        return children(props)
  }
  return (
    <QueryRenderer
      environment={environment(dbkey)}
      query={query}
      variables = {variables}
      render={render}
    />
  )
}

export default QueryComponent
