
export default ({dbOptions1, dbOptions2}) => ({
  AddDialogtitle: ['Adauga NU E CAZUL', ''],
    columns: [
      {header: 'nameoperationcode', accessor: 'nameoperationcode', accessorid: 'operationcodeid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege cod operatiune ..',}},
        {header: 'namefeloperationcode', accessor: 'namefeloperationcode', accessorid: 'feloperationcodeid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions2, widthautocomplete: '33.7rem', text: 'Alege fel (suma/luna, detail/data) ..',}},
      // {header: 'houseregistercurrent', accessor: 'houseregistercurrent', type: 'houseregistercurrent', show: 'true', },
    ],
    initialdata: {
      operationcodeid: '', 
      feloperationcodeid: '', 
      subRows: undefined,
    },    
  })

  

// 
// 
// symboloperationcodes
// 
// 
// symbolfeloperationcode
