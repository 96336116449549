
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'incasari', text: 'incasari', groupcol: [ 'day', 'year', 'monthname', 'date', 'ncode', 'code', 'symbol', 'collectionid', 'collection', 'percent', 'value', 'therapistvalue',  ] }, //, 'month', 'monthname', 'year' 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'level',   ]}, //'selyearid', 'selmonthid', 'operationkindofid', 'kindofid',
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  
 
