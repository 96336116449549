
export default ({dbOptions}) => ({
    AddDialogtitle: ['Adauga Operation kind of', ''],
    columns: [
      {header: 'name', accessor: 'name', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'norder', accessor: 'norder', type: 'text', show: 'true', },
      {header: 'namekindof', accessor: 'namekindof', accessorid: 'kindofid', type: 'text', show: 'true', 
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege fel operatiune ..'},},
    ],
    initialdata: {
      name: '',
      symbol: '',
      norder: '',
      kindofid: '',
      subRows: undefined,
    },    
  })
