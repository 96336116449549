import React from 'react'
import { Link } from 'react-router-dom' 
import enumAuth from '../../../../enums/authenticated'
import enummapps from '../../../../enums/mapps'
import enumRols from '../../../../enums/rols'

import Icon from '@mdi/react'
import { mdiScript } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/

export default ({params, events}) => {
    const {appid, } = params
    const { utilizatorOscar: {utilizatormodelat: { isauthenticated, rols}}, } = params

    const visible = appid !== enummapps.mhappyworld.key  
        && (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
        && (rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 ||  // care are rol 'responsabilbusiness'
            rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 ||  // care are rol 'responsabilmanagement'
            // rols.filter((item) => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0 ||  // care are rol 'responsabilutilizatori'
            rols.filter((item) => parseInt(item.rolid) === enumRols.contabil.key).length > 0 ||  // care are rol 'contabil'
            // rols.filter((item) => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||  // care are rol 'AdminApp'
            // rols.filter((item) => parseInt(item.rolid) === enumRols.superadmin.key).length > 0
            rols.filter((item) => parseInt(item.rolid) === enumRols.terapeut.key).length > 0
            )  // care are rol 'superadmin'

    const rotating = false 
    
    return (
        <>
            {visible ? 
                <>
                    <Link to='/reports' title='rapoarte'>
                        {!rotating ? <Icon path={mdiScript} title="rapoarte" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiScript} title="rapoarte" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 
                </>
                : <> </>
            } 
        </>
    )
}