import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery($pRefresh: Boolean!
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh) 
}
`

