
export default [
    [
        {field: 'therapypriceid', value: 'therapypriceid'},
        {field: 'therapyid', value: 'therapyid'},
        {field: 'nameRotherapy', value: 'nameRotherapy'},
        {field: 'nameEntherapy', value: 'nameEntherapy'},
        {field: 'symboltherapy', value: 'symboltherapy'},
        {field: 'descriptiontherapy', value: 'descriptiontherapy'},
        {field: 'nordertherapy', value: 'nordertherapy'},
        {field: 'therapy2Id', value: 'therapy2Id'},
        {field: 'nameRo2Therapy', value: 'nameRo2Therapy'},
        {field: 'nameEn2Therapy', value: 'nameEn2Therapy'},
        {field: 'symbol2Therapy', value: 'symbol2Therapy'},
        {field: 'description2Therapy', value: 'description2Therapy'},
        {field: 'norder2Therapy', value: 'norder2Therapy'},
        {field: 'timeid', value: 'timeid'},
        {field: 'symboltime', value: 'symboltime'},
        {field: 'minutestime', value: 'minutestime'},
        {field: 'unitstime', value: 'unitstime'},
        {field: 'therapytypeid', value: 'therapytypeid'},
        {field: 'nameRotherapytype', value: 'nameRotherapytype'},
        {field: 'nameEntherapytype', value: 'nameEntherapytype'},
        {field: 'symboltherapytype', value: 'symboltherapytype'},
        {field: 'ntherapysessiontherapytype', value: 'ntherapysessiontherapytype'},
        {field: 'ntherapysession1Therapytype', value: 'ntherapysession1Therapytype'},
        {field: 'time2Id', value: 'time2Id'},
        {field: 'symboltime2', value: 'symboltime2'},
        {field: 'minutestime2', value: 'minutestime2'},
        {field: 'unitstime2', value: 'unitstime2'},
        {field: 'time', value: 'time'},
        {field: 'price', value: 'price'},
        {field: 'name', value: 'name'},
        {field: 'norder', value: 'norder'},
        {field: 'description', value: 'description'},
        {field: 'activ', value: 'activ'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)


