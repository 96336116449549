/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VextrascontCondition = {|
  extrascontid?: ?any,
  date?: ?any,
  norder?: ?number,
  procesat?: ?boolean,
  month?: ?any,
  year?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
  operatiune?: ?string,
  debit?: ?number,
  credit?: ?number,
  beneficiar?: ?string,
  ordonator?: ?string,
  contul?: ?string,
  banca?: ?string,
  detalii?: ?string,
  referinta?: ?string,
  detalii2?: ?string,
  operationcodeid?: ?number,
  nameoperationcode?: ?string,
  symboloperationcode?: ?string,
  codeoperationcode?: ?string,
  norderoperationcode?: ?number,
  operationkindofid?: ?number,
  nameoperationkindof?: ?string,
  symboloperationkindof?: ?string,
  norderoperationkindof?: ?number,
  kindofid?: ?number,
  namekindofs?: ?string,
  symbolkindofs?: ?string,
  collectionid?: ?number,
  nameRocollection?: ?string,
  nameEncollection?: ?string,
  symbolcollection?: ?string,
  mngmentoperationdetailid?: ?number,
  ndocument?: ?string,
  description?: ?string,
  value?: ?any,
|};
export type VmngmentoperationmonthCondition = {|
  year?: ?any,
  month?: ?any,
  monthname?: ?string,
  shortmonthname?: ?string,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VextrascontCondition,
  conditionyear?: ?VmngmentoperationmonthCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VextrascontCondition
  $conditionyear: VmngmentoperationmonthCondition
) {
  ...page_dbdata_3PFP5r
}

fragment page_dbdata_3PFP5r on Query {
  vextrasconts(condition: $condition) {
    edges {
      node {
        extrascontid
        date
        procesat
        month
        year
        monthname
        shortmonthname
        operatiune
        norder
        debit
        credit
        beneficiar
        ordonator
        contul
        banca
        detalii
        referinta
        detalii2
        operationcodeid
        nameoperationcode
        symboloperationcode
        codeoperationcode
        norderoperationcode
        operationkindofid
        nameoperationkindof
        symboloperationkindof
        norderoperationkindof
        kindofid
        namekindofs
        symbolkindofs
        collectionid
        nameRocollection
        nameEncollection
        symbolcollection
        mngmentoperationdetailid
        ndocument
        description
        value
      }
    }
  }
  vmngmentoperationyears(orderBy: YEAR_DESC) {
    edges {
      node {
        year
      }
    }
  }
  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
    edges {
      node {
        month
        year
        monthname
        shortmonthname
      }
    }
  }
  voperationcodes(orderBy: OPERATIONCODEID_ASC) {
    edges {
      node {
        operationcodeid
        operationkindofid
        nameoperationkindof
        operationkindofsymbol
        norderoperationkindof
        name
        symbol
        code
        norder
      }
    }
  }
  vcollections(orderBy: COLLECTIONID_ASC) {
    edges {
      node {
        collectionid
        nameRo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conditionyear"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v3 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortmonthname",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norder",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationkindofid",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationkindof",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norderoperationkindof",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "conditionyear",
            "variableName": "conditionyear"
          },
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": "VextrascontsConnection",
        "kind": "LinkedField",
        "name": "vextrasconts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VextrascontsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vextrascont",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extrascontid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "procesat",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operatiune",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "debit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "credit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "beneficiar",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ordonator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contul",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banca",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "detalii",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referinta",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "detalii2",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameoperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboloperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "codeoperationcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norderoperationcode",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboloperationkindof",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kindofid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "namekindofs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolkindofs",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRocollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEncollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbolcollection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mngmentoperationdetailid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ndocument",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "YEAR_DESC"
          }
        ],
        "concreteType": "VmngmentoperationyearsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationyears",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationyearsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationyear",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "condition",
            "variableName": "conditionyear"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "MONTH_DESC"
          }
        ],
        "concreteType": "VmngmentoperationmonthsConnection",
        "kind": "LinkedField",
        "name": "vmngmentoperationmonths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VmngmentoperationmonthsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vmngmentoperationmonth",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "OPERATIONCODEID_ASC"
          }
        ],
        "concreteType": "VoperationcodesConnection",
        "kind": "LinkedField",
        "name": "voperationcodes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoperationcodesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Voperationcode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "operationkindofsymbol",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "COLLECTIONID_ASC"
          }
        ],
        "concreteType": "VcollectionsConnection",
        "kind": "LinkedField",
        "name": "vcollections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VcollectionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vcollection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vcollections(orderBy:\"COLLECTIONID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "3e7b86f83afdf328f3da494939aa8392",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VextrascontCondition\n  $conditionyear: VmngmentoperationmonthCondition\n) {\n  ...page_dbdata_3PFP5r\n}\n\nfragment page_dbdata_3PFP5r on Query {\n  vextrasconts(condition: $condition) {\n    edges {\n      node {\n        extrascontid\n        date\n        procesat\n        month\n        year\n        monthname\n        shortmonthname\n        operatiune\n        norder\n        debit\n        credit\n        beneficiar\n        ordonator\n        contul\n        banca\n        detalii\n        referinta\n        detalii2\n        operationcodeid\n        nameoperationcode\n        symboloperationcode\n        codeoperationcode\n        norderoperationcode\n        operationkindofid\n        nameoperationkindof\n        symboloperationkindof\n        norderoperationkindof\n        kindofid\n        namekindofs\n        symbolkindofs\n        collectionid\n        nameRocollection\n        nameEncollection\n        symbolcollection\n        mngmentoperationdetailid\n        ndocument\n        description\n        value\n      }\n    }\n  }\n  vmngmentoperationyears(orderBy: YEAR_DESC) {\n    edges {\n      node {\n        year\n      }\n    }\n  }\n  vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {\n    edges {\n      node {\n        month\n        year\n        monthname\n        shortmonthname\n      }\n    }\n  }\n  voperationcodes(orderBy: OPERATIONCODEID_ASC) {\n    edges {\n      node {\n        operationcodeid\n        operationkindofid\n        nameoperationkindof\n        operationkindofsymbol\n        norderoperationkindof\n        name\n        symbol\n        code\n        norder\n      }\n    }\n  }\n  vcollections(orderBy: COLLECTIONID_ASC) {\n    edges {\n      node {\n        collectionid\n        nameRo\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e04cf6af50b779c52763a325a599305';

module.exports = node;
