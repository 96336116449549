import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import SubComponent from '../dbTherapysessions/component'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enumcalendars from '../../../enums/calendars'
import enumcollections from '../../../enums/collections'
import enummapps from '../../../enums/mapps'
import groupcols from './groupcols'
import headers from './xls/headers'
import initiall from './initiall'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUps from './mutationUps'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import subcomponenttitle from './subcomponenttitle'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'

// import Readxlsfile from '../../../uioscar/readxlsfile'








// import mutationAdds from './mutationAdds'




// import nulldate from '../../../enums/nulldate'






















//import {stringify} from 'flatted'


export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, therapyid} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[dbSubscriptions.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({dbOptions: dbdata.dbOptions, 
    // dbOptions1: dbdata.dbOptions1, dbOptions2: dbdata.dbOptions2, 
    dbOptions3: dbdata.dbOptions3, 
    // dbOptions4: dbdata.dbOptions4, 
    dbOptions5: dbdata.dbOptions5,  }), 
    [dbdata.dbOptions, dbdata.dbOptions3, dbdata.dbOptions5, ])   
  const initialAddDialog = React.useMemo( () => initiall({therapyid, dbOptions: dbdata.dbOptions, 
    dbOptions1: dbdata.dbOptions1, dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, 
    dbOptions4: dbdata.dbOptions4, dbOptions5: dbdata.dbOptions5, }), 
    [therapyid, dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, dbdata.dbOptions3, 
      dbdata.dbOptions4, dbdata.dbOptions5, ]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[dbSubscriptions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[dbSubscriptions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {subscriptionid} = d.original
    //     // console.log(`[dbSubscriptions.table.onrowSelected.map] - subscriptionid: ${subscriptionid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({subscriptionid})
    //   })
    // console.log(`[dbSubscriptions.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
        <Dialog key={`SubComponent`} 
          params = {{...params, ...subcomponenttitle, ptherapysessionid: row.values.therapysessionid, psubscriptionid: undefined, }} 
          events={{onCloseDialog, ...events}} subComponent={SubComponent} />
      </>
    ),
    [params, events]
  )

  const onError = errors => {
      // console.log(`[dbSubscriptions.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[dbSubscriptions.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) {
        return response 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[dbSubscriptions.table] - format: ${format}`)

  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls) => {
    // console.log(`[dbSubscriptions.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[dbSubscriptions.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }

  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[dbSubscriptions.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[dbSubscriptions.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          return {input: {
            pSubscriptionid: parseInt(item.subscriptionid), pTherapistid: parseInt(item.therapistid), 
            pPatientid: parseInt(item.patientid), pTherapypriceid: parseInt(item.therapypriceid), 
            pDate: item.date, pTherapyid: parseInt(item.therapyid), 
            pTherapy2Id: item.therapy2Id !== undefined && item.therapy2Id !== null ? parseInt(item.therapy2Id) : parseInt(nullint), 
            pTherapytypeid: parseInt(item.therapytypeid), pTimeid: parseInt(item.timeid), 
            pTime2Id: item.time2Id !== undefined && item.time2Id !== '' ? parseInt(item.time2Id) : parseInt(nullint), 
            pTime: parseInt(item.time), pPrice: parseInt(item.price), pName: item.nametherapyprice, 
            pNtherapysession: parseInt(item.ntherapysession), 
            pNtherapysession1: item.ntherapysession1 !== undefined && item.ntherapysession1 !== '' ? parseInt(item.ntherapysession1) : parseInt(nullint), 
            pMofntherapysession: parseInt(item.mofntherapysession), pNbmonths: parseInt(item.nbmonths), 
            pCollectionid: parseInt(item.collectionid), pFinalizat: item.finalizat,
            pSubscriptionmodeid: item.subscriptionmodeid !== undefined && item.subscriptionmodeid !== '' ? parseInt(item.subscriptionmodeid) : parseInt(nullint),  
            pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring,  
          }}
        })
      default:
    }     
  }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pSubscriptionid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }


  const ondbAdd = (item) => {
    // console.log(`[dbSubscriptions.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pPatientid: parseInt(item.patientid), 
      pFirstname: item.firstname !== undefined && item.firstname !== '' ? item.firstname : nullstring, 
      pLastname: item.lastname !== undefined && item.lastname !== '' ? item.lastname : nullstring, 
      pTherapisttherapypriceid: item.therapisttherapypriceid !== undefined && item.therapisttherapypriceid !== '' ? parseInt(item.therapisttherapypriceid) : parseInt(nullint), 
      // pTherapistid: item.therapistid !== undefined && item.therapistid !== '' ? parseInt(item.therapistid) : parseInt(nullint), 
      pDate: item.date, 
      pFromid: item.fromid !== undefined && item.fromid !== '' ? parseInt(item.fromid) : 33, // default ora 8:00  //pTherapypriceid: parseInt(item.therapypriceid), 
      // pTherapy2id: item.therapy2id !== undefined && item.therapy2id !== '' ? parseInt(item.therapy2id) : parseInt(nullint), 
      // pTherapytypeid: item.therapytypeid !== undefined && item.therapytypeid !== '' ? parseInt(item.therapytypeid) : parseInt(nullint), 
      pCalendarid: item.calendarid !== undefined && item.calendarid && item.calendarid !== '' ? parseInt(item.calendarid) : enumcalendars.sedinta.key , 
      pRoomid: item.roomid !== undefined && item.roomid !== '' ? parseInt(item.roomid) : parseInt(nullint),  
      pPatientnotes: item.patientnotes !== undefined && item.patientnotes !== '' ? item.patientnotes : nullstring, 
      pTherapistnotes: item.therapistnotes !== undefined && item.therapistnotes !== '' ? item.therapistnotes : nullstring, 
      pCollectionid: item.collectionid !== undefined && item.collectionid !== '' ? parseInt(item.collectionid) : enumcollections.cash.key, 
      pNtherapysession: item.ntherapysession !== undefined && item.ntherapysession !== '' ? parseInt(item.ntherapysession) : parseInt(nullint), 
      pNbmonths: parseInt(item.nbmonths), // pMofntherapysession: parseInt(nullint), 
      pSubscriptionmodeid: item.subscriptionmodeid !== undefined && item.subscriptionmodeid !== '' ? parseInt(item.subscriptionmodeid) : parseInt(nullint),  
      pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring,  
      pPrice: item.value !== undefined && item.value !== '' ? parseInt(item.value) : parseInt(nullint), 
      // pFinalizat: false
    },}
      // console.log(`[dbSubscriptions.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[dbSubscriptions.table.ondbUpd] - db Upd: {subscriptionid, columnId, value}: ${JSON.stringify({subscriptionid: field.subscriptionid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId  !== 'patient'&& field.columnId  !== 'therapyprice'){
      const input = {input: {  pKeyfield: field.columnId, 
        pValue: field.columnId === 'abonamentfinalizat' ? field.value.toString() : field.value, 
        pSubscriptionid: parseInt(field.subscriptionid)},}
      // console.log(`[dbSubscriptions.table.ondbUpd]- subscriptionid: ${field.subscriptionid}, input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError), ondbRefresh)  
    }  
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {subscriptionid}} = selectedFlatRow
      const input = {input: {pSubscriptionid: parseInt(subscriptionid)},}
      // console.log(`[dbSubscriptions.table.ondbDel]- subscriptionid: ${subscriptionid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[dbSubscriptions.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ndefaultgroup = 0
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[dbSubscriptions.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[dbSubscriptions.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
  // console.log(`[dbSubscriptions.table] - isresponsabilbusiness: ${isresponsabilbusiness}, rols: ${JSON.stringify(rols)}`) 

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>abonamente</h3> */}
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(therapyid), onrowSelected,
          EditableCell: EditableCell, editabledata: isresponsabilbusiness && appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilbusiness ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilbusiness) ? AddDialog : undefined, 
          importxlsfields: (isresponsabilbusiness) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          exportxlsfields: isresponsabilbusiness ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isresponsabilbusiness ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: isresponsabilbusiness && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[12]-Subscriptions', wsnames: wsnames}} headers={headers} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
