/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddtherapysessionInput = {|
  clientMutationId?: ?string,
  pPatientid: number,
  pFirstname: string,
  pLastname: string,
  pTherapisttherapypriceid: number,
  pDate: any,
  pFromid: number,
  pCalendarid: number,
  pRoomid: number,
  pPatientnotes: string,
  pTherapistnotes: string,
  pCollectionid: number,
  pPrice: number,
  pSubscriptionid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddtherapysessionInput
|};
export type mutationAddMutationResponse = {|
  +fnAddtherapysession: ?{|
    +therapysession: ?{|
      +calendarid: number,
      +collectionid: number,
      +date: any,
      +finalizat: boolean,
      +fromid: number,
      +patientid: number,
      +patientnotes: ?string,
      +percent: any,
      +roomid: ?number,
      +therapistid: ?number,
      +therapistnotes: ?string,
      +therapy2Id: ?number,
      +therapyid: number,
      +therapypriceid: number,
      +therapysessionid: any,
      +therapytypeid: number,
      +toid: number,
      +value: any,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddtherapysessionInput!
) {
  fnAddtherapysession(input: $input) {
    therapysession {
      calendarid
      collectionid
      date
      finalizat
      fromid
      patientid
      patientnotes
      percent
      roomid
      therapistid
      therapistnotes
      therapy2Id
      therapyid
      therapypriceid
      therapysessionid
      therapytypeid
      toid
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddtherapysessionPayload",
    "kind": "LinkedField",
    "name": "fnAddtherapysession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapysession",
        "kind": "LinkedField",
        "name": "therapysession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientnotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roomid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistnotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapy2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapyid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapypriceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapysessionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapytypeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e14f650635e2559dd125ea4bf7d27a28",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddtherapysessionInput!\n) {\n  fnAddtherapysession(input: $input) {\n    therapysession {\n      calendarid\n      collectionid\n      date\n      finalizat\n      fromid\n      patientid\n      patientnotes\n      percent\n      roomid\n      therapistid\n      therapistnotes\n      therapy2Id\n      therapyid\n      therapypriceid\n      therapysessionid\n      therapytypeid\n      toid\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '423185141517146c64646803527438bb';

module.exports = node;
