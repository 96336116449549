import React from 'react'
import { Link } from 'react-router-dom' 
// import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuReport, } from '../../../../styles/styles/reportstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabil = false 
    // && rols !== undefined ? (
    //     // rols.filter(item => parseInt(item.rolid) === enumRols.contabil.key).length > 0 ||
    //     rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 ||
    //     rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0
    //     ) 
    //     : false
    const visible = isresponsabil && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuReport>
                {
                    visible ?
                        <> 
                            <Link to='/reportmappOscar'>
                                <TextIcon params = {{ text: visible ? 'report 4/ '   : '', title: 'reportmappOscar', 
                                    width: theme.theme_main.withTextIconReportMenu, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuReport>
        </>
    )
}