// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4}) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'therapyprices',
      columns: [
        { id: 'name', Header: 'name', accessor: 'name', width: 30, type: 'text',},
        { id: 'therapy', Header: 'therapy', accessor: 'therapy', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege terapie ..'},},
        { id: 'therapy2', Header: 'therapy2', accessor: 'therapy2', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege terapie ..'},},
        { id: 'therapytype', Header: 'therapytype', accessor: 'therapytype', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '12.3rem', text: 'Alege fel terapie ..'},},
        { id: 'dtime', Header: 'dtime', accessor: 'dtime', width: 12, type: '', align: 'right',
          autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '12.3rem', text: 'Alege durata terapie 1 ..'},},
        { id: 'dtime2', Header: 'dtime2', accessor: 'dtime2', width: 12, type: '', align: 'right',
          autocomplete: {autocomplete: true, dbOptions: dbOptions4, widthautocomplete: '12.3rem', text: 'Alege darata terapie 2 ..'},},

        { id: 'time', Header: 'time', accessor: 'time', width: 12, type: 'text', align: 'right',},
        { id: 'price', Header: 'price', accessor: 'price', width: 10, type: 'number', align: 'right',},
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 12, type: 'number', align: 'right',},
        { id: 'activ', Header: 'activ', accessor: 'activ', width: 30, radiobuttondanu: true, type: '', align: 'left',},
        ]
    },
    {
      Header: 'description',
      columns: [
        { id: 'description', Header: 'description', accessor: 'description', width: 80, type: 'text',},
        ]
    },
    {
      Header: 'therapy 1',
      columns: [
        { id: 'nameRotherapy', Header: 'nameRotherapy', accessor: 'nameRotherapy', width: 30, type: 'text', },
        { id: 'nameEntherapy', Header: 'nameEntherapy', accessor: 'nameEntherapy', width: 30, type: 'text',},
        { id: 'symboltherapy', Header: 'symboltherapy', accessor: 'symboltherapy', width: 30, type: 'text',},
        { id: 'nordertherapy', Header: 'nordertherapy', accessor: 'nordertherapy', width: 30,  },
        { id: 'descriptiontherapy', Header: 'descriptiontherapy', accessor: 'descriptiontherapy', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'therapy 2',
      columns: [
        { id: 'nameRo2Therapy', Header: 'nameRo2Therapy', accessor: 'nameRo2Therapy', width: 30, type: 'text',},
        { id: 'nameEn2Therapy', Header: 'nameEn2Therapy', accessor: 'nameEn2Therapy', width: 30, type: 'text',},
        { id: 'symbol2Therapy', Header: 'symbol2Therapy', accessor: 'symbol2Therapy', width: 30, type: 'text',},
        { id: 'norder2Therapy', Header: 'norder2Therapy', accessor: 'norder2Therapy', width: 30, type: 'text',},
        { id: 'description2Therapy', Header: 'description2Therapy', accessor: 'description2Therapy', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'time 1',
      columns: [
        { id: 'symboltime', Header: 'symboltime', accessor: 'symboltime', width: 30, type: '', align: 'right',},
        { id: 'minutestime', Header: 'minutestime', accessor: 'minutestime', width: 30, type: 'text', align: 'right',},
        { id: 'unitstime', Header: 'unitstime', accessor: 'unitstime', width: 30, type: 'text', align: 'right',},
        ]
    },
    {
      Header: 'time 2',
      columns: [
        { id: 'symboltime2', Header: 'symboltime2', accessor: 'symboltime2', width: 30, type: '', align: 'right',},
        { id: 'minutestime2', Header: 'minutestime2', accessor: 'minutestime2', width: 30, type: 'text', align: 'right',},
        { id: 'unitstime2', Header: 'unitstime2', accessor: 'unitstime2', width: 30, type: 'text', align: 'right',},
        ]
    },
    {
      Header: 'therapytype',
      columns: [
        { id: 'nameRotherapytype', Header: 'nameRotherapytype', accessor: 'nameRotherapytype', width: 30, type: 'text',},
        { id: 'nameEntherapytype', Header: 'nameEntherapytype', accessor: 'nameEntherapytype', width: 30, type: 'text',},
        { id: 'symboltherapytype', Header: 'symboltherapytype', accessor: 'symboltherapytype', width: 30, type: 'text',},
        { id: 'ntherapysessiontherapytype', Header: 'ntherapysessiontherapytype', accessor: 'ntherapysessiontherapytype', width: 30, type: 'text', align: 'right',},
        { id: 'ntherapysession1Therapytype', Header: 'ntherapysession1Therapytype', accessor: 'ntherapysession1Therapytype', width: 30, type: 'text', align: 'right',},
        ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapypriceid', Header: 'therapypriceid', accessor: 'therapypriceid', width: 30, align: 'right',  },
    { id: 'therapyid', Header: 'therapyid', accessor: 'therapyid', width: 30, type: 'text', align: 'right',},
    { id: 'therapy2Id', Header: 'therapy2Id', accessor: 'therapy2Id', width: 30, type: 'text', align: 'right',},
    { id: 'therapytypeid', Header: 'therapytypeid', accessor: 'therapytypeid', width: 30, align: 'right',  },
    { id: 'timeid', Header: 'timeid', accessor: 'timeid', width: 30, align: 'right',  },
    { id: 'time2Id', Header: 'time2Id', accessor: 'time2Id', width: 30, align: 'right',  },
  ],
},
])
