import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $condition: VrregistrucasaCondition
  $conditionyear: VmngmentoperationmonthCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh, condition: $condition, conditionyear: $conditionyear) 
}
`

