import { StyleMenuRapoarte1, StyleMenuRapoarte2, StyleMenuRapoarte3, StyleMenuRapoarte4, StyleMenuReport, StyleReport } from '../../../styles/styles/reportstyle'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Reportoscar100 from './reportslinks1/reportoscar'
import Reportoscar101 from './reportslinks1/reportoscar1'
import Reportoscar200 from './reportslinks2/reportoscar'
import Reportoscar201 from './reportslinks2/reportoscar1'
import Reportoscar300 from './reportslinks3/reportoscar'
import Reportoscar301 from './reportslinks3/reportoscar1'
import Reportoscar400 from './reportslinks4/reportoscar'
import Reportoscar401 from './reportslinks4/reportoscar1'

// rapoarte coloana 1



// rapoarte coloana 2



// rapoarte coloana 3



// rapoarte coloana 4



export default ({dbdata, params, events}) => {
    // const {appid} = params
    // console.log(`[reports.page] - appid: ${appid}, params{ ${JSON.stringify(params)}}`)
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
                <h3 style={{margin: '1.875rem'}}>menu reports page</h3>
                <StyleReport>
                    <StyleMenuReport><StyleMenuRapoarte1>
                    <fieldset>
                        <legend>terapii:</legend>
                        <Reportoscar100 params = {params} events = {events} />
                        <Reportoscar101 params = {params} events = {events} />
                    </fieldset>
                    </StyleMenuRapoarte1></StyleMenuReport>
                    <StyleMenuReport><StyleMenuRapoarte2>
                    <fieldset>
                        <legend>management:</legend>
                        <Reportoscar200 params = {params} events = {events} />
                        <Reportoscar201 params = {params} events = {events} />
                    </fieldset>
                    </StyleMenuRapoarte2></StyleMenuReport>
                    <StyleMenuReport><StyleMenuRapoarte3>
                    {/* <fieldset>
                        <legend>other1:</legend> */}
                        <Reportoscar300 params = {params} events = {events} />
                        <Reportoscar301 params = {params} events = {events} />
                        {/* </fieldset> */}
                    </StyleMenuRapoarte3></StyleMenuReport>
                    <StyleMenuReport><StyleMenuRapoarte4>
                    {/* <fieldset>
                        <legend>other2:</legend> */}
                        <Reportoscar400 params = {params} events = {events} />
                        <Reportoscar401 params = {params} events = {events} />
                        {/* </fieldset> */}
                    </StyleMenuRapoarte4></StyleMenuReport>
                </StyleReport>
            </div>
        </div>
    )
}

