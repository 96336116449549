import { Environment, Network, RecordSource, Store } from 'relay-runtime' // , QueryResponseCache

import db from '../app/db'

export default (dbkey) => {
    // console.log(`[environment] dbkey: ${dbkey}`)

    // // const oneMinute = 60 * 1000;
    // // const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });

    function fetchQuery(operation, variables, ) {
        // const queryID = operation.text;
        // const isMutation = operation.operationKind === 'mutation';
        // const isQuery = operation.operationKind === 'query';
        // const forceFetch = cacheConfig && cacheConfig.force;

        // // Try to get data from cache on queries
        // const fromCache = cache.get(queryID, variables);
        // if (
        //     isQuery &&
        //     fromCache !== null &&
        //     !forceFetch
        // ) {
        //     return fromCache;
        // }

        const {targetUrl} = db(dbkey)
        // console.log(`[environment] targetUrl: ${targetUrl}`)
        return fetch(targetUrl, {
            method: 'POST', //mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: operation.text,
                variables,
            }),
        }).then(function(response) {
            return response.json()                
        })
        // .then(json => {
        //     // Update cache on queries
        //     if (isQuery && json) {
        //       cache.set(queryID, variables, json);
        //     }
        //     // Clear cache on mutations
        //     if (isMutation) {
        //       cache.clear();
        //     }
        
        //     return json;
        //   })

        // .then(function(myJson) {
        // console.log(JSON.stringify(myJson))
        //   })
        
        // .then(function(myJson) {
            // console.log('myJson', myJson)
        //   })
        // .catch((e) => {
        //     // console.log('error', e)
        // })
    }
    
    return new Environment({
        network: Network.create(fetchQuery),
        store: new Store(new RecordSource()),
    })
}
