import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import collections from '../../../enums/collections'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyear, selmonth, } = params

  const dbvdata = dbdata.vtherapysessions === undefined || dbdata.vtherapysessions.edges.length === 0 ? 
  [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }]
  : dbdata.vtherapysessions.edges
  .map((edge, index) => {
    return{
      ...edge.node, 
      selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
      patient: `${edge.node.firstnamepatient} ${edge.node.lastnamepatient} ${edge.node.extensivepatient !== null ? edge.node.extensivepatient : ''}`,
      therapist: `${edge.node.firstname !== null ? edge.node.firstname : '__'} ${edge.node.lastname !== null ? edge.node.lastname : ''} ${edge.node.extensive !== null ? edge.node.extensive : ''}`,
      // therapisttherapyprice: `${edge.node.nametherapyprice}, ${edge.node.price}RON, ${edge.node.time}, ${edge.node.nameRotherapy},`,//[${edge.node.therapypriceid}]
      therapyprice: `${edge.node.nametherapyprice}, ${edge.node.price}RON, ${edge.node.time}, ${edge.node.nameRotherapy},`,//[${edge.node.therapypriceid}]
      therapy: `${edge.node.nameRotherapy}`,  //[${edge.node.therapyid}]
      room: `${edge.node.nameRoroom !== null ? edge.node.nameRoroom : ''}`, calendar: `${edge.node.nameRocalendar}`,
      subscription: edge.node.namesessionsubscription,
      }
  })
  // console.log(`[dbTherapysessions.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
    ...dbdata.vpatients.edges.map(edge => {
      const {patientid, firstname, lastname, extensive, } = edge.node
      return {id: 'patientid', patientid, patient: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}`}
  })
  ]
  // // console.log(`[dbTherapysessions.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
  // const dbOptions1 = [
  //   ...dbdata.vtherapists.edges.map(edge => {
  //     const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
  //     return {id: 'therapistid', therapistid, therapist: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}[${symbol}, ${code}]`}
  // })
  // ]
  // // console.log(`[dbTherapysessions.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  const dbOptions2 = [
    ...dbdata.vhours.edges.map(edge => {
      const {hourid, name, symbol, } = edge.node
      return {id: 'fromid', fromid: hourid, fromhour: `${name} ${symbol !== null ? `[${symbol}]` : ''}`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
  const dbOptions3 = [
    ...dbdata.vhours.edges.map(edge => {
      const {hourid, name, symbol, } = edge.node
      return {id: 'toid', toid: hourid, tohour: `${name} ${symbol !== null ? `[${symbol}]` : ''}`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )
  // const dbOptions4 = [
  //   ...dbdata.vtherapisttherapyprices.edges.map(edge => {
  //     const {therapypriceid, nametherapyprice, nameRotherapy, time, price, firstname, lastname, symbol, percent, } = edge.node
  //     return {id: 'therapypriceid', therapypriceid, therapyprice: `${lastname}, ${firstname}, ${symbol}, ${nametherapyprice}, ${price}RON, ${time}, ${nameRotherapy}, ${percent}% [${therapypriceid}]`, }  //
  // })
  // ]
  // // console.log(`[dbTherapysessions.page] - dbOptions4: ${JSON.stringify(dbOptions4)}` )
  const dbOptions4 = [
    ...dbdata.vtherapisttherapyprices.edges.map(edge => {
      const {therapisttherapypriceid, nametherapyprice, nameRotherapy, time, price, firstname, lastname, symbol, percent, therapypriceid, } = edge.node//, therapypriceid
      return {id: 'therapisttherapypriceid', therapisttherapypriceid, therapyprice: `${lastname}, ${firstname}, ${symbol}, ${nametherapyprice}, ${price}RON, ${time}, ${nameRotherapy}, ${percent}% [${therapypriceid}]`, }  //
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions4: ${JSON.stringify(dbOptions4)}` )
  const dbOptions5 = [
    ...dbdata.vcalendars.edges.map(edge => {
      const {calendarid, nameRo, } = edge.node
      return {id: 'calendarid', calendarid, calendar: `${nameRo}`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions5: ${JSON.stringify(dbOptions5)}` )
  const dbOptions6 = [
    // {id: 'roomid', roomid: 0, room: `null`},
    ...dbdata.vrooms.edges.map(edge => {
      const {roomid, nameRo, } = edge.node
      return {id: 'roomid', roomid, room: `${nameRo}`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions6: ${JSON.stringify(dbOptions6)}` )
  const dbOptions7 = [
    ...dbdata.vbusinesscollections.edges
      .filter(edge => parseInt(edge.node.collectionid) === collections.cash.key 
        ||parseInt(edge.node.collectionid) === collections.POS.key
        ||parseInt(edge.node.collectionid) === collections.wallet.key
        ||parseInt(edge.node.collectionid) === collections.none.key)
      .map(edge => {
        const {collectionid, nameRo, } = edge.node
        return {id: 'collectionid', collectionid, nameRocollection: `${nameRo}`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions7: ${JSON.stringify(dbOptions7)}` )
  const dbOptions8 = [
    ...dbdata.vtherapies.edges
    // .filter(edge => edge.node.therapyid === 2)
    .map(edge => {
      const {therapyid, nameRo, } = edge.node
      return {id: 'therapyid', therapyid, therapy: `${nameRo}[${therapyid}]`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions8: ${JSON.stringify(dbOptions8)}` )

  const dbOptions9 = [
    ...dbdata.vtherapysessionyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions9: ${JSON.stringify(dbOptions9)}` )

  const dbOptions10 = [
    ...dbdata.vtherapysessionmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions10: ${JSON.stringify(dbOptions10)}` )

  const dbOptions11 = [
    ...dbdata.vsubscriptions.edges.map(edge => {
      const { subscriptionid, firstnamepatient, lastnamepatient, //therapysessionid, extensivepatient, 
        // firstname, lastname, extensive, description, 
        nametherapyprice, nameRotherapy, subscriptioncount, } = edge.node
      return {id: 'subscriptionid', subscriptionid: subscriptionid, subscription: `${nametherapyprice}, ${nameRotherapy}, ${firstnamepatient} ${lastnamepatient} [${subscriptioncount}]`}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions11: ${JSON.stringify(dbOptions11)}` )

  const dbvtotalpos = dbdata.vtotalpos === undefined ? null : dbdata.vtotalpos.edges.map(edge => {return{...edge.node, }})
  // console.log(`[dbTherapysessions.page] - dbvtotalpos: ${JSON.stringify(dbvtotalpos)}` )
  // console.log(dbvtotalpos)

  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbvtotalpos, dbOptions, dbOptions2, dbOptions3, dbOptions4, dbOptions5, 
          dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VmngmentoperationCondition, defaultValue: {}, }
        conditionyear: {type: VtherapysessionmonthCondition, defaultValue: {}, }
        conditiontherapysession: {type: VtherapysessionCondition, defaultValue: {}, }
        conditionpos: {type: VtotalpoCondition, defaultValue: {}, }

    ) 
    {
      vtherapysessions(condition: $conditiontherapysession) { #orderBy: THERAPYSESSIONID_ASC, 
        edges {
          node {
            therapysessionid
            patientid
            firstnamepatient
            lastnamepatient
            extensivepatient
            birthyearpatient
            weightpatient
            heightpatient
            phonepatient
            emailpatient
            cnppatient
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percenttherapists
            therapypriceid
            therapyidtherapyprice
            therapy2Idtherapyprice
            timeid
            symboltime
            minutestime
            unitstime
            time2Id
            symboltime2
            minutestime2
            unitstime2
            time
            price
            nametherapyprice
            date
            month
            year
            monthname
            shortmonthname
            fromid
            fromhour
            symbolfromhour
            toid
            tohour
            symboltohour
            therapyid
            nameRotherapy
            nameEntherapy
            symboltherapy
            nordertherapy
            descriptiontherapy
            therapy2Id
            nameRotherapy2
            nameEntherapy2
            symboltherapy2
            nordertherapy2
            descriptiontherapy2
            therapytypeid
            nameRotherapytype
            nameEntherapytype
            symboltherapytype
            ntherapysessiontherapytype
            ntherapysession1Therapytype
            namesubscription
            ntherapysessionsubscription
            mofntherapysessionsubscription
            nbmonthssubscription
            finalizatsubscription
            # mofntherapysession
            # subscriptionsessionid
            timesubscription
            subscriptionid
            percent
            calendarid
            nameRocalendar
            nameEncalendar
            symbolcalendar
            roomid
            nameRoroom
            nameEnroom
            symbolroom
            etaj
            patientnotes
            therapistnotes
            collectionid
            nameRocollection
            nameEncollection
            symbolcollection
            value
            finalizat
            thereistherapistpatient
            therapistoperationcode
            operationcodeid
            nameoperationcode
            symboloperationcode
            codeoperationcode
            sessionsubscriptionid
            sessiontherapysessionid
            namesessionsubscription
            descriptionsessionsubscription
          }
        }
      }
      vtherapysessionyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vtherapysessionmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      vpatients { #(orderBy: PATIENTID_ASC)
        edges {
          node {
            patientid
            firstname
            lastname
            extensive
            # birthyear
            # weight
            # height
            # phone
            # email
            # cnp
          }
        }
      }
      # vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
      #   edges {
      #     node {
      #       therapistid
      #       firstname
      #       lastname
      #       extensive
      #       symbol
      #       code
      #       # phone
      #       # email
      #       # cnp
      #       # percent
      #     }
      #   }
      # } 
      vhours(orderBy: HOURID_ASC) {
        edges {
          node {
            hourid
            name
            symbol
          }
        }
      }
      vtherapisttherapyprices(condition: {activtherapists: true therapytypeid: 1}) {
        edges {
          node {
            therapisttherapypriceid
            # therapistid
            firstname
            lastname
            # extensive
            symbol
            # code
            # phone
            # email
            # cnp
            # percenttherapists
            therapypriceid
            # therapyid
            nameRotherapy
            # therapy2Id
            therapytypeid
            # timeid
            # symboltime
            # minutestime
            # unitstime
            # time2Id
            time
            price
            nametherapyprice
            # descriptiontherapyprice
            # nordertherapyprice
            percent
          }
        }
      }      
      vcalendars(orderBy: CALENDARID_ASC) {
        edges {
          node {
            calendarid
            nameRo
            # nameEn
            # symbol
          }
        }
      }
      vrooms(orderBy: ROOMID_ASC) {
        edges {
          node {
            roomid
            nameRo
            # nameEn
            # symbol
            # etaj
          }
        }
      }
      vbusinesscollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }
      vtherapies(orderBy: THERAPYID_ASC) {
        edges {
          node {
            therapyid
            nameRo
            # nameEn
            # symbol
            # norder
            # description
          }
        }
      }
      vsubscriptions(condition: {subscriptionnotfull: true}) {
          edges {
            node {
              subscriptionid
              therapysessionid
              # date
              # patientid
              firstnamepatient
              lastnamepatient
              extensivepatient
              # birthyearpatient
              # weightpatient
              # heightpatient
              # phonepatient
              # emailpatient
              # cnppatient
              # therapistid
              firstname
              lastname
              extensive
              # symbol
              # code
              # phone
              # email
              # cnp
              # percenttherapists
              # therapypriceid
              nametherapyprice
              # therapyid
              nameRotherapy
              # nameEntherapy
              # symboltherapy
              # nordertherapy
              # descriptiontherapy
              # therapy2Id
              # nameRo2Therapy
              # nameEn2Therapy
              # symbol2Therapy
              # norder2Therapy
              # description2Therapy
              # therapytypeid
              # nameRotherapytype
              # nameEntherapytype
              # symboltherapytype
              # ntherapysessiontherapytype
              # ntherapysession1Therapytype
              # fromid
              # fromhour
              # symbolfromhour
              # toid
              # tohour
              # symboltohour
              # timeid
              # symboltime
              # minutestime
              # unitstime
              # time2Id
              # symboltime2
              # minutestime2
              # unitstime2
              # time
              # name
              # collectionid
              # nameRocollection
              # nameEncollection
              # symbolcollection
              # ntherapysession
              # ntherapysession1
              # mofntherapysession
              # nbmonths
              # finalizat
              # abonamentfinalizat
              # value
              # subscriptionmodeid
              # namesubscriptionmodes
              description
              subscriptioncount
            }
          }
        }
      vtotalpos(condition: $conditionpos) {
          edges {
            node {
              date
              collectionid
              value
            }
          }
        }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

