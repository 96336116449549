import { Document, PDFViewer, } from '@react-pdf/renderer'

import Icon from '@mdi/react'
import PdfPage from './pdfpage'
import React from 'react'
import enumfeldocumentpdf from '../../enums/feldocumentpdf'
import { mdiClose, } from '@mdi/js';
import theme from '../../styles/themes/defaultTheme/theme'

export default ({data, data0, params}) => {
  const {pdftitles, pdfheaders0, pdfheaders1, pdfheaders, index, feldocumentpdf} = params  
  const _feldocumentpdf = feldocumentpdf ?? enumfeldocumentpdf.multiple.key
  // console.log(`[pdf.pdfDocument] - _feldocumentpdf: ${_feldocumentpdf}`)
  const [visible,setvisible] = React.useState(true)
  // console.log(pdfheaders)
  // console.log(`[pdf.pdfDocument] - pdftitles: ${JSON.stringify(pdftitles)}, data: ${JSON.stringify(data)}`)
  // console.log(pdftitles)
  // console.log(data)
  // console.log(data0)

  // console.log(`[pdf.pdfDocument] - theme.gridrows.heightpdf: ${theme.gridrows.heightpdf}. theme.gridrows.widthpdf: ${theme.gridrows.widthpdf}`)
  // console.log(`[pdf.pdfDocument] - `)
  // console.log(data)
  // console.log(`[pdf.pdfDocument] - pdfheaders0: `)
  // console.log(pdfheaders0)
  // console.log(`[pdf.pdfDocument] - pdfheaders1: `)
  // console.log(pdfheaders1)
  // console.log(`[pdf.pdfDocument] - pdfheaders: `)
  // console.log(pdfheaders)

  // console.log(`[pdflib.pdfDocument] - params: `)
  // console.log(params)

  return(
    <>
      {visible ?
          <>
            <PDFViewer height={theme.gridrows.heightpdf} width={theme.gridrows.widthpdf}>
                <Document>
                    {
                      _feldocumentpdf === enumfeldocumentpdf.multiple.key ?
                        <PdfPage 
                          params = {{...params, pdftitles: pdftitles[index], 
                            header0: pdfheaders0 !== undefined ? pdfheaders0[index] : undefined, 
                            header1: pdfheaders1 !== undefined ? pdfheaders1[index] : undefined, 
                            header: pdfheaders[index], 
                          }} 
                          data={data} data0={data0} />
                      :<>
                        {data.data.map((data, index) => 
                          <PdfPage key = {`pdfpage${index}`} 
                            params = {{...params, pdftitles: pdftitles[index], 
                              header0: pdfheaders0 !== undefined ? pdfheaders0[index] : undefined, 
                              header1: pdfheaders1 !== undefined ? pdfheaders1[index] : undefined, 
                              header: pdfheaders[index], 
                            }} 
                            data={data} data0={data0} />
                        )}
                      </>
                    }
                </Document>
            </PDFViewer>  
            <Icon path={mdiClose} title="refresh" onClick={() => setvisible(false)} size={1} horizontal vertical rotate={180} color="black"/>
          </>
      : <></>
      }
    </>
  )}

