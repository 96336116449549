import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdutilizatorInput!)
{
  fnUpdutilizator(input: $input) {
    utilizator {
      activ
      cnp
      email
      parola
      telefon
      username
      utilizator
      utilizatorid
    }
  }
}
`

