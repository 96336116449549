
export default [
    [
        {field: 'roomid', value: 'roomid'},
        {field: 'nameRo', value: 'nameRo'},
        {field: 'nameEn', value: 'nameEn'},
        {field: 'symbol', value: 'symbol'},
        {field: 'etaj', value: 'etaj'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

