
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', ] }, 
    {label: 'therapysessions', text: 'therapysessions', groupcol: [ 'therapy', 'therapist', 'therapyprice', 'date', 'fromhour', 'symbolfromhour', 'tohour', 'symboltohour', 'nameRocollection', 'percent', 'room', 'value', 'finalizat', 'thereistherapistpatient', 'therapistoperationcode', 'subscription', ] }, //, 'calendar'
    {label: 'abonament', text: 'abonament', groupcol: [ 'namesessionsubscription', 'descriptionsessionsubscription', ] },
    {label: 'therapydetails', text: 'therapydetails', groupcol: [ 'patientnotes', 'therapistnotes', ] }, 
    {label: 'patient', text: 'patient', groupcol: [ 'firstnamepatient', 'lastnamepatient', 'extensivepatient', 'birthyearpatient', 'weightpatient', 'heightpatient', 'phonepatient', 'emailpatient', 'cnppatient', ] }, 
    {label: 'therapist', text: 'therapist', groupcol: [ 'firstname', 'lastname', 'extensive', 'symbol', 'code', 'phone', 'email', 'cnp', 'percenttherapists', 'operationcodeid', 'nameoperationcode', 'symboloperationcode', 'codeoperationcode',  ]},
    {label: 'therapyprice', text: 'therapyprice', groupcol: [ 'symboltime', 'minutestime', 'unitstime', 'symboltime2', 'minutestime2', 'unitstime2', 'time', 'price', 'nametherapyprice',  ]},
    {label: 'therapy', text: 'therapy', groupcol: [ 'nameRotherapy', 'nameEntherapy', 'symboltherapy', 'nordertherapy', 'descriptiontherapy', 'nameEncollection', 'symbolcollection',  ]},
    {label: 'subscription', text: 'subscription', groupcol: [ 'namesubscription', 'mofntherapysession', 'ntherapysessionsubscription', 'mofntherapysessionsubscription', 'nbmonthssubscription', 'timesubscription', 'finalizatsubscription',  ]},
    {label: 'room', text: 'room', groupcol: [ 'nameRoroom', 'nameEnroom', 'symbolroom', 'etaj', 'nameRocalendar', 'nameEncalendar', 'symbolcalendar', ]},
    {label: 'dbinfo', text: 'db info', groupcol: [ 'therapysessionid', 'patientid', 'therapistid', 'therapisttherapypriceid', 'therapypriceid', 'therapyid', 'therapy2Id', 'therapytypeid', 'sessionsubscriptionId', 'sessiontherapysessionId', ]},
    {label: 'dbinfo2', text: 'db info 2', groupcol: [ 'fromid', 'toid', 'timeid', 'time2Id', 'subscriptionid', 'subscriptionsessionId', 'calendarid', 'roomid', 'collectionid',  ]},//, 'subscriptiondetailid'
    {label: 'toate', text: 'toate', groupcol: []},
  ]
