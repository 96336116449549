import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $condition: VmngmentoperationCondition
  $conditionyear: VtherapysessionmonthCondition
  $conditiontherapysession: VtherapysessionCondition
  $conditionpos: VtotalpoCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh, condition: $condition
    conditionyear: $conditionyear
    conditiontherapysession: $conditiontherapysession 
    conditionpos: $conditionpos
  )
}
`

