import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

export default ({params: {isDeveloper}}) => 
<div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
    <ul>
        <li>administrare drepturi acordate utilizatorilor</li>
        <li>simbol <b>*</b> semnifica drept acordat, iar <b>__</b> semnifica lipsa drept</li>
        <li>adauga drepturi pentru aplicatie selectata [<b>+</b>], acorda / retrage drepturi prin click pe <b>*</b> sau <b>__</b></li>
        <li>Nu exista buton de refresh, deoarece la un click pe acest buton se revine la pagina anterioara, administrare utilizatori</li>
        <li> nu exista import din fisier xls</li>
        <li>este posibil export in fisier xls si listare in format pdf</li>
        <li>Nu se poate ascunde <b>help</b> administrare drepturi acordate utilizatilor</li>
        { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.utilizatoridrepturi</b>, subcomponent: components.<b>utilizatorcomponents.utilizatori(utilizatorid)</b> </li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.AppRolUtilizators</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>AppRolUtilizatorId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>AppId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>RolId</td>
                            <td className='td'>INTEGER</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vUtilizatorPivotRols(orderBy: -, condition: $condition) (utilizatorid, utilizator, username, email, cnp, telefon, datainceput, datasfarsit, activ, parola, app, appid, adminapp, contabil, developer, liber, responsabilutilizatori, modelare, pacient, responsabilbusiness, responsabilmanagement, superadmin, terapeut, tester)</td>
                        <td className='td'>, unde &#x0007B; condition: &#x0007B; utilizatorid: utilizatorid &#x0007D;  &#x0007D; </td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vapps(appid, denumire, simbol, link, ordonare)</td>
                        <td className='td'>dbOptions</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vrols(rolid, denumire, simbol, observatie)</td>
                        <td className='td'>rols: dbvrols</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addAppRolUtilizator(p_utilizatorid, p_AppId, p_RolId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updAppRolUtilizator(p_keyfield, p_value, p_AppRolUtilizatorId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_AppRolUtilizatorId)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </>
            : <></>
            }
    </ul>
</div>


