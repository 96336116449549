import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddutilizatorInput!)
{
    fnAddutilizator(input: $input) {
        utilizator {
          activ
          cnp
          email
          parola
          telefon
          username
          utilizator
          utilizatorid
        }
    }
}
`
