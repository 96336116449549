
import webconfig from '../backend/webconfig.json'
import dbservices from './dbservices'
import packageJson from '../../package.json'

export default (dbkey) =>{
    // const server = packageJson.server
    // console.log(`[db] - server }: ${JSON.stringify(server)}, webconfig[server]: ${JSON.stringify(webconfig[`server${server}`])}`)
    
    // const {HOST, postgraphilePORT, }  = process.env.NODE_ENV === 'production' ? webconfig.server : webconfig.serverLocalhost 
    const {HOST, postgraphilePORT, }  = process.env.NODE_ENV === 'production' ? webconfig[`server${packageJson.server}`] : webconfig.serverLocalhost 
    const _dbservice = dbservices().filter(service => service.key === dbkey) 
    const {service: dbservice, cluster, dbNAME, key, }  = _dbservice.length > 0 ? _dbservice[0] : {service: 'default', key: 0, cluster: 'happyworld', dbNAME: 'happyworld', }  
    const graphql = '/graphql'
    const baseurl = process.env.NODE_ENV === 'production' ? `${HOST}` : `${HOST}/${cluster}/${dbNAME}`   // pentru productie nu exista port
    const targetUrl = process.env.NODE_ENV === 'production' ? `${HOST}${graphql}` : `${HOST}:${postgraphilePORT}/${cluster}/${dbNAME}${graphql}`   // pentru productie nu exista port
    // console.log(`[db] - _service }: ${JSON.stringify(dbservice)}, dbcount: ${dbservices().length}`)
    // console.log(`[db] - dbkey: ${dbkey}, {service, key, cluster, dbNAME, }: ${JSON.stringify({service, key, cluster, dbNAME, })}, postgraphilePORT: ${postgraphilePORT}`)  
    // console.log(`[db] - targetUrl: ${targetUrl}`)
    return {dbservice, key, cluster, dbNAME, baseurl, targetUrl, dbcount: dbservices().length}
}
