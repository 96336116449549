import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  // console.log(`[raportRegistruCasa.page] - params: ${JSON.stringify(params)}`)
  const {selyear, selmonth, } = params

  const dbvdata = dbdata.vrregistrucasas === undefined || dbdata.vrregistrucasas.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: selmonth.monthname, selmonthid: '',  }]
    : dbdata.vrregistrucasas.edges.map((edge, index) => {
      return{
        ...edge.node, 
        nrcrt: edge.node.nameoperationcode !== 'Sold precedent' 
          && edge.node.nameoperationcode !== 'Sold final' 
          && edge.node.nameoperationcode !== 'Total incasari' 
          && edge.node.nameoperationcode !== 'Total plati' ? index : '', 
        //date: edge.node.nameoperationcode === 'Sold final' ? edge.node.date : '',
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? selmonth.monthname : '', selmonthid: '',
        }
      })
  // console.log(`[raportRegistruCasa.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  
  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[raportRegistruCasa.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[raportRegistruCasa.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vmonthkindofs.edges.map(edge => {
      const { monthkindofid, name, } = edge.node
      return {id: 'monthkindofid', monthkindofid: monthkindofid, namemonthkindof: name}
  })
  ]
  // console.log(`[raportRegistruCasa.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

//   const dbOptions4 = [
//     ...dbdata.vcollections.edges.map(edge => {
//       const {collectionid, nameRo, } = edge.node
//       return {id: 'collectionid', collectionid, collection: `${nameRo}`}
//   })
// ]
// // console.log(`[dbMngementOperationDetails.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  const dbOptions3 = [
    ...dbdata.voperationcodes.edges.map(edge => {
      const {operationcodeid, name, symbol, code, nameoperationkindof, } = edge.node
      return {id: 'operationcodeid', operationcodeid, code: `${nameoperationkindof}: ${name}(${symbol !== null ? symbol : ''})(${code})`}
  })
]
// console.log(`[dbMngementOperationDetails.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, dbOptions3, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VrregistrucasaCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
    ) 
    {
      vrregistrucasas(condition: $condition) {
        edges {
          node {
            date
            # month
            # year
            # monthname
            # houseregisterprevious
            # houseregistercurrent
            ndocument
            # codeoperationcode
            nameoperationcode
            debit
            credit
            sold
            # norderoperationcode
            # operationkindofid
            # nameoperationkindof
            # norderoperationkindof
            # norder
            # kindofid
            # namekindofs
          }
        }
      }
      # vcollections(orderBy: COLLECTIONID_ASC) {
      #   edges {
      #     node {
      #       collectionid
      #       nameRo
      #       # nameEn
      #       # symbol
      #     }
      #   }
      # }           
      voperationcodes(condition: {operationkindofid: 2} orderBy: OPERATIONCODEID_ASC) {
        edges {
          node {
            operationcodeid
            operationkindofid
            nameoperationkindof
            operationkindofsymbol
            norderoperationkindof
            name
            symbol
            code
            norder
          }
        }
      }
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
        edges {
          node {
            monthkindofid
            name
            # symbol
          }
        }
      }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

