import React from 'react'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import query from './query'
import Component from './footer'
import Componentmocha from './footermocha'
import FooterStyle from '../../../styles/styles/footerstyle'

export default ({params, events, }) => {
    const {existaDB, dbkey} = params 
    return(
    <FooterStyle>
        {!existaDB ? <Componentmocha params = {params} events={events} / >
        :<QueryComponent query = {query} dbkey={dbkey} >
            { props => <Component dbdata={props} params = {params} events={events} / > }
        </QueryComponent>}
    </FooterStyle>
)}

