import React from 'react'
import RadioButtonInput from './radioButtonInput'

export default ({params: { title, groupcol, groupcols }, events: {onsetcells}}) => 
    <>
        <span style={{color:"green"}}><b>{title}</b></span><br/>
        { groupcols.map((item, index) => <RadioButtonInput key={`groupcols${index}`}
        params={{node: {label:`_Cells${title}`, value: groupcol, text: item.text, defaultChecked: item.label, }}} 
        events={{onset: onsetcells}} />)
        }          
    </>
