import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyearmng: selyear, selmonthmng: selmonth, } = params

  // console.log(`[importextrasconts.page] - dbdata.vextrasconts: ${JSON.stringify(dbdata.vextrasconts)}` )
  const dbvdata = dbdata.vextrasconts === undefined || dbdata.vextrasconts.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }] 
    : dbdata.vextrasconts.edges
    .map((edge, index) => {
      return{
        ...edge.node, 
        finalizat: edge.node.procesat,
        operationcode: `${edge.node.nameoperationcode !== null ? `${edge.node.nameoperationcode}(${edge.node.codeoperationcode})` : ''}${edge.node.operationcodeid ? `[${edge.node.operationcodeid}]` : ''}`,  // [${edge.node.namekindofs}
        collection: `${edge.node.nameRocollection !== null ? `${edge.node.nameRocollection}` : ''}${edge.node.operationcodeid ? `[${edge.node.collectionid}]` : ''}`,  
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
    })
  // console.log(`[importextrasconts.page] - dbvdata: ${JSON.stringify(dbvdata)}` )

  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[importextrasconts.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[importextrasconts.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    {id: 'operationcodeid', operationcodeid: 0, operationcode: 'null'},
    ...dbdata.voperationcodes.edges.map(edge => {
    const {operationcodeid, name, symbol, code, nameoperationkindof, } = edge.node
    return {id: 'operationcodeid', operationcodeid, operationcode: `${nameoperationkindof}: ${name}(${symbol !== null ? symbol : ''})(${code})(${operationcodeid})`}
  })
  ]
  // console.log(`[importextrasconts.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
  const dbOptions3 = [
    ...dbdata.vcollections.edges.map(edge => {
      const {collectionid, nameRo, } = edge.node
      return {id: 'collectionid', collectionid, collection: `${nameRo}`}
  })
  ]
  // console.log(`[dbMngementOperationDetails.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )

  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, dbOptions3, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
  # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
  fragment page_dbdata on Query @argumentDefinitions(
      pRefresh: {type: Boolean, defaultValue: false, }
      condition: {type: VextrascontCondition, defaultValue: {}, }
      conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
  ) 
  {
    vextrasconts(condition: $condition) {
      edges {
        node {
          extrascontid
          date
          procesat
          month
          year
          monthname
          shortmonthname
          operatiune
          norder
          debit
          credit
          beneficiar
          ordonator
          contul
          banca
          detalii
          referinta
          detalii2
          operationcodeid
          nameoperationcode
          symboloperationcode
          codeoperationcode
          norderoperationcode
          operationkindofid
          nameoperationkindof
          symboloperationkindof
          norderoperationkindof
          kindofid
          namekindofs
          symbolkindofs
          collectionid
          nameRocollection
          nameEncollection
          symbolcollection
          mngmentoperationdetailid
          ndocument
          description
          value
        }
      }
    }
    vmngmentoperationyears(orderBy: YEAR_DESC) {
      edges {
        node {
          year
        }
      }
    }
    vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
      edges {
        node {
          month
          year
          monthname
          shortmonthname
        }
      }
    }
    voperationcodes(orderBy: OPERATIONCODEID_ASC) {
      edges {
        node {
          operationcodeid
          operationkindofid
          nameoperationkindof
          operationkindofsymbol
          norderoperationkindof
          name
          symbol
          code
          norder
        }
      }
    }
    vcollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }           

  }
  `
}
export default Relay.createFragmentContainer(Page, fragments)

