import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpsoperationkindofInput!)
{
  fnUpsoperationkindof(input: $input) {
    operationkindof {
      name
      norder
      operationkindofid
      symbol
      kindofid
    }
  }
}
`
 