import { editablecolumnstyle, inputstyle, styleformControltextarea, uneditablecolumnstyle } from '../../styles/styles/inputstyle'

import Autocomplete from './AutoComplete'
import RadioButtonInput from './radioButtonInput'
import React from 'react'
import ReactHtmlParser from '../reactHtmlParser'
import calendarcell from './calendarcell'

// import {stringify} from 'flatted'

// Create an editableColumns cell renderer
export default ({ cell: { value: initialValue }, row: { index, original}, 
    column: { id, autocomplete, radiobuttondanu=false, type='text', textarea=false, textarearows=3, html=false, },  
    updateMyData, nonApiFields, }) => {
    // This is a custom function that we supplied to our table instance
  // We need to keep and update the state of the cell normally

  
  const [value, setValue] = React.useState(initialValue !== null ? initialValue : '')
  const [editable, seteditable] = React.useState(false)
  const {editableColumns, editabledata, rols=[], onClickFunction} = nonApiFields
  const {autocomplete: _autocomplete, dbOptions, widthautocomplete: width, text} = autocomplete !== undefined ? autocomplete : {autocomplete: false, dbOptions: null, widthautocomplete: '100%', text: 'Alege ..'}
  const isrol = rols.filter(item => item.rol === id).length !== 0
  // console.log(`[editableCellComponent] - id: ${id}, isrol: ${isrol}, rols: ${JSON.stringify(rols)}`)
  const editablerow = (original.finalizat !== undefined && !original.finalizat) || original.finalizat === undefined//) && !original.inregistrareeditabila //+ original.inregistrareeditabila === undefined
  // console.log(`[editableCellComponent] - id: ${id}, _editablerowf: ${_editablerowf}, _editablerowp: ${_editablerowp}, editablerow: ${editablerow}, `)
  // console.log(`[editableCellComponent] - id: ${id}, editablerow: ${editablerow}, `)
  // const editableColumn = editableColumns.filter(editable => editable === id ? editable : null).length === 1
  const editableselyearmonth = id === 'selyear' || id === 'selmonth' // selyear sau selmonth sunt editabile intodeauna
  const editableColumn = editableselyearmonth || editableColumns.filter(editable => editable === id ? editablerow : false).length === 1
  //   if(id === 'h0845'){
  //   // console.log(`[editableCellComponent] - id: ${id}, initialValue: ${JSON.stringify(initialValue)}, `)
  //   // console.log(`[editableCellComponent] - id: ${id}, style: ${JSON.stringify(style)}, `)
  // console.log(`[editableCellComponent] - id: ${id}, original: ${JSON.stringify(original)}, `)
  // console.log(`[editableCellComponent] - id: ${id}, original.inregistrareeditabila: ${JSON.stringify(original.inregistrareeditabila)}, `)
  // }
  
  // let Input
  const onChange = e => {
    e.preventDefault()
    if(!editabledata) return
    setValue(e.target.value)
  // console.log(`[editableCellComponent.onChange] - id: ${id}, editableColumns: ${JSON.stringify(editableColumns)}, editable: ${editable}, initialValue: ${initialValue}, e.target.value: ${e.target.value}`)
  }
  const onClick = e => {
    e.preventDefault()
    if (!editabledata && onClickFunction !== undefined) onClickFunction(original)
    if(!editabledata) return

    if(isrol) // drepturi utilizatori
    {
      const rolid = rols.filter(item => item.rol === id)[0].rolid 
      // console.log(`[editableCellComponent.onClick] - index: ${index}, id: ${id}, rolid: ${JSON.stringify(rolid)},  value: ${value} typeof(value): ${typeof(value)}`)
      updateMyData(index, id, value === null ? rolid : '', {rolid: rolid} )
    }
    else{
      seteditable(editabledata && editableColumn)
    // console.log(`[editableCellComponent.onChange] - id: ${id}, editableColumns: ${JSON.stringify(editableColumns)}, editable: ${editable}, initialValue: ${initialValue}, e.target.value: ${e.target.value}`)
    }
  }

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    e.preventDefault()
    if(!editabledata) return
    // console.log(`[editableCellComponent.onBlur] - id: ${id}, index: ${index}, value: ${value}, initialValue: ${initialValue}`)
    if(initialValue !== value) {
      seteditable(false)
      updateMyData(index, id, value)
    }
  }

  const onChangeAutocomplete = newValue => {
    // console.log(`[editableCellComponent.onchange] - id: ${id}, newValue: ${stringify(newValue)}`)
    if(initialValue !== newValue[id]) {
      seteditable(false)
      updateMyData(index, id, newValue[id])
      // console.log(`[editableCellComponent.onchange] - id: ${id}, newValue['id']: ${newValue['id']}, newValue[newValue['id']]: ${newValue[newValue['id']]}`)
      updateMyData(index, newValue['id'], newValue[newValue['id']].toString())
    }
  }
  const onsetcells = () => {
    const _editable = editabledata && editableColumns.filter(col => col === id ? col : null).length === 1
    // console.log(`[editableCellComponent.onsetcells] - id: ${id}, initialValue: ${initialValue}, editabledata: ${editabledata}, _editable: ${_editable}, editableColumns: ${JSON.stringify(editableColumns)}`)
    if(_editable) updateMyData(index, id, !initialValue) 
  }

  const nullvalue = '__'
  const {iscalendar, _initialValue, cellstyle} = calendarcell(id, initialValue, nonApiFields, original, nullvalue)

  React.useEffect(() => {setValue(initialValue) }, [initialValue])
  const simbol = value => value === null ? '-' : value === '' ? '-' : '*'
  const _value = `${initialValue!== undefined && initialValue!== null && initialValue !== '' ? !iscalendar ? initialValue : _initialValue : nullvalue }` // atentie: iesire daca nu e editable!
  //https://react-refs-cheatsheet.netlify.com/#dynamic-refs-hooks
  
  return (
    <>        
      { 
        editable && !radiobuttondanu ? 
        <>  
          {_autocomplete 
            ? <Autocomplete key={id} data={dbOptions} defaultValue={initialValue} width={width} onChangeAutocomplete={onChangeAutocomplete} id = {id} text={text} /> // width={'12.3rem'} //style={{width:width, height:height}}
            // : _datecontrol ? <input defaultValue={value} type='date' style={inputstyle} onFocus={input => input.target.select()} onChange={onChange} onBlur={onBlur}/>  // ref = {input => {Input = input}}
            : textarea ? <textarea textarearows={textarearows} style= {styleformControltextarea} //readOnly={readOnly} placeholder={placeholder} type="text" ref = {input => {Generic = input}}
              onFocus={input => input.target.select()} onChange={onChange} onBlur={onBlur} defaultValue = {value} />  // ref = {input => {Input = input}}
            : <input defaultValue={value} type={type} style={inputstyle} onFocus={input => input.target.select()} 
              onChange={onChange} onBlur={onBlur}/>  // ref = {input => {Input = input}}
          }
        </>
      : radiobuttondanu ? // editable / noneditable radiobutton da/nu 
        <>
          <RadioButtonInput key={`RadioButtonInputda`} 
            params={{node: {label:`${id}${index}`, accesor: id, value: initialValue, text: 'da', defaultChecked: true, }}} 
            events={{onset: onsetcells}} />
          <RadioButtonInput key={`RadioButtonInputnu`} 
            params={{node: {label:`${id}${index}`, accesor: id, value: initialValue, text: 'nu', defaultChecked: false, }}} 
            events={{onset: onsetcells}} />
        </>
      : html ? <div onClick={onClick}>{ReactHtmlParser(_value)}</div>
      : isrol ? <div onClick={onClick} >{simbol(initialValue)}</div>
      : iscalendar ? <div style={id.slice(0, 1) === 'h' ? cellstyle : editableColumn ? editablecolumnstyle : uneditablecolumnstyle} 
        onClick={onClick} >{_value}</div>
      : <div style={editableColumn ? editablecolumnstyle : uneditablecolumnstyle} 
        onClick={onClick}>{_value}</div>
    }      
  </>
  )
}

