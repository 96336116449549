/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddappmenuInput = {|
  clientMutationId?: ?string,
  pAppid: number,
  pDenumire: string,
  pSimbol: string,
  pOrdonare: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddappmenuInput
|};
export type mutationAddMutationResponse = {|
  +fnAddappmenu: ?{|
    +appmenu: ?{|
      +appid: any,
      +appmenuid: any,
      +denumire: string,
      +ordonare: ?any,
      +simbol: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddappmenuInput!
) {
  fnAddappmenu(input: $input) {
    appmenu {
      appid
      appmenuid
      denumire
      ordonare
      simbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddappmenuPayload",
    "kind": "LinkedField",
    "name": "fnAddappmenu",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appmenu",
        "kind": "LinkedField",
        "name": "appmenu",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appmenuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "denumire",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordonare",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7855958ee9da20db3dc5ff4b48b3d186",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddappmenuInput!\n) {\n  fnAddappmenu(input: $input) {\n    appmenu {\n      appid\n      appmenuid\n      denumire\n      ordonare\n      simbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ebfc96697c7d76e8e4ad8d2f304b468';

module.exports = node;
