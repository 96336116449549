
export default ({dbOptions, dbOptions1, }) => ({
  AddDialogtitle: ['Adauga codificare extras cont', ''],
    columns: [
      {header: 'key word', accessor: 'key', type: 'text', show: 'true', },
      {header: 'nameoperationcode', accessor: 'nameoperationcode', accessorid: 'operationcodeid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege cod operatie..',}},

      {header: 'symbolcollection', accessor: 'symbolcollection', accessorid: 'collectionid', type: '', show: true, 
      autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege fel cont..',}},

    ],
    initialdata: {
      operationcodeid: '', 
      collectionid: '', 
      key: '', 
      subRows: undefined,
    },    
  })

  
// extrascontcodificareid
// 
// 
// 
// symboloperationcode
// codeoperationcode
// norderoperationcode
// 
// nameRocollection
// nameEncollection
// 