
export default [
    [
        {field: 'idserverid', value: 'idserverid'},
        {field: 'denumire', value: 'denumire'},
        {field: 'simbol', value: 'simbol'},
    ],

]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
