import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'


const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vtherapyprices === undefined ? null : dbdata.vtherapyprices.edges
    .map(edge => {
      return{
        ...edge.node, 
        therapy: `${edge.node.nameRotherapy}`,
        therapy2: `${edge.node.nameRo2Therapy !== null ? edge.node.nameRo2Therapy : ''}`,
        therapytype: `${edge.node.nameRotherapytype}`,
        dtime: `${edge.node.symboltime} [${edge.node.minutestime} min]`,
        dtime2: `${edge.node.time2Id !== undefined ? `${edge.node.symboltime2} [${edge.node.minutestime2} min]` : ``}`,
        
      }
    })
  // console.log(`[configTherapyprices.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [    
      ...dbdata.vtherapies.edges.map(edge => {
        const {therapyid, nameRo, } = edge.node
        return {id: 'therapyid', therapyid, therapy: `${nameRo}`}
    })
  ]
  //console.log(`[configTherapyprices.page] - dbOptions1: ${JSON.stringify(dbOptions)}` )
  const dbOptions1 = [
    {id: 'therapy2Id', therapy2Id: 0, therapy2: `null`},
      ...dbdata.vtherapies.edges.map(edge => {
        const {therapyid, nameRo, } = edge.node
        return {id: 'therapy2Id', therapy2Id: therapyid, therapy2: `${nameRo}`}
    })
  ]
  // console.log(`[configTherapyprices.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  const dbOptions2 = [
      ...dbdata.vtherapytypes.edges.map(edge => {
        const {therapytypeid, nameRo, } = edge.node
        return {id: 'therapytypeid', therapytypeid: therapytypeid, therapytype: `${nameRo}`}
    })
  ]
  // console.log(`[configTherapyprices.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
  const dbOptions3 = [
      ...dbdata.vtimes.edges.map(edge => {
        const {timeid, symbol, minutes, } = edge.node
        return {id: 'timeid', timeid: timeid, dtime: `${symbol}[${minutes}]`}
    })
  ]
  // console.log(`[configTherapyprices.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )
  const dbOptions4 = [
    {id: 'time2id', time2id: 0, dtime2: `null`},
      ...dbdata.vtimes.edges.map(edge => {
        const {timeid, symbol, minutes, } = edge.node
        return {id: 'time2id', time2id: timeid, dtime2: `${symbol}[${minutes}]`}
    })
  ]
  // console.log(`[configTherapyprices.page] - dbOptions4: ${JSON.stringify(dbOptions4)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VtherapypriceCondition, defaultValue: {}, }
    ) 
    {
      vtherapyprices(orderBy: NORDER_ASC, condition: $condition) {
        edges {
          node {
            therapypriceid
            norder
            therapyid
            nameRotherapy
            nameEntherapy
            symboltherapy
            nordertherapy
            descriptiontherapy
            therapy2Id
            nameRo2Therapy
            nameEn2Therapy
            symbol2Therapy
            norder2Therapy
            description2Therapy
            therapytypeid
            nameRotherapytype
            nameEntherapytype
            symboltherapytype
            ntherapysessiontherapytype
            ntherapysession1Therapytype
            timeid
            symboltime
            minutestime
            unitstime
            time2Id
            symboltime2
            minutestime2
            unitstime2
            time
            price
            name
            description
            activ
            }
        }
      }
      vtherapies(orderBy: THERAPYID_ASC) {
        edges {
          node {
            therapyid
            nameRo
            # nameEn
            # symbol
            # norder
            # description
          }
        }
      }
      vtimes(orderBy: TIMEID_ASC) {
        edges {
          node {
            timeid
            symbol
            minutes
            # units
          }
        }
      }
      vtherapytypes(orderBy: THERAPYTYPEID_ASC) {
        edges {
          node {
            therapytypeid
            nameRo
            # nameEn
            # symbol
            # ntherapysession
            # ntherapysession1
          }
        }
      }      
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

