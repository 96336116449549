import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'


const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.voperationkindofs === undefined ? null : dbdata.voperationkindofs.edges.map(edge => {return{...edge.node, }})
  // console.log(`[adminOperationkindofs.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
    ...dbdata.vkindofs.edges.map(edge => {
      const {kindofid, name, } = edge.node
      return {id: 'kindofid', kindofid: kindofid, namekindof: name}
  })
  ]
  // console.log(`[dbTherapysessions.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, }} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
      voperationkindofs(orderBy: OPERATIONKINDOFID_ASC) {
        edges {
          node {
            operationkindofid
            name
            symbol
            norder
            kindofid
            namekindof
            symbolkindof
          }
        }
      }
      vkindofs(orderBy: KINDOFID_ASC) {
        edges {
          node {
            kindofid
            name
            # symbol
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

