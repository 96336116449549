import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddoperationcodeInput!)
{
  fnAddoperationcode(input: $input) {
    operationcode {
      code
      name
      norder
      operationcodeid
      operationkindofid
      symbol
    }
  }
}
`
