
export const DialogFixedStyle = {
    position:'fixed',
    top: '56px',
    // bottom: '200px',
    left: '50px',
    zIndex:'1000',
    color: '#fff',
    backgroundColor: '#5cb85c',
    borderColor: '#4cae4c',
  }

  export const ErrorFixedStyle = {
    position:'fixed',
    top: '50px',
    // bottom: '200px',
    right: '50px',
    zIndex:'1000',
    color: '#fff',
    backgroundColor: '#af117c',
    borderColor: '#4cae4c',
  }

  