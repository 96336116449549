import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdtimeInput!)
{
  fnUpdtime(input: $input) {
    dtime {
      minutes
      symbol
      timeid
      units
    }  }
}
`

