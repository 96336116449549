import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddrolInput!)
{
  fnAddrol(input: $input) {
    rol {
      rolid
      denumire
      simbol
      observatie
    }
  }
}
`
