import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vtherapistcollections === undefined ? null : dbdata.vtherapistcollections.edges.map(edge => {return{...edge.node, }})
  // console.log(`[configTherapistcollections.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
      ...dbdata.vtherapists.edges.map(edge => {
        const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
        return {id: 'therapistid', therapistid, firstname: `${firstname} ${lastname} ${extensive}[${symbol}, ${code}]`}
    })
  ]
  // console.log(`[configTherapistcollections.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
  const dbOptions1 = [
      ...dbdata.vcollections.edges.map(edge => {
        const {collectionid, nameRo, } = edge.node
        return {id: 'collectionid', collectionid, nameRo: `${nameRo}`}
    })
  ]
  // console.log(`[configTherapistcollections.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VtherapistcollectionCondition, defaultValue: {}, }
    ) 
    {
      vtherapistcollections(
        orderBy: THERAPISTCOLLECTIONID_ASC
        condition: $condition
      ) {
        edges {
          node {
            therapistcollectionid
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percenttherapists
            collectionid
            nameRo
            nameEn
            symbolcollections
          }
        }
      }
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            activ
            # phone
            # email
            # cnp
            # percent
          }
        }
      } 
      vcollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }           
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

