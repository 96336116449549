/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdoperationkindofInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pOperationkindofid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdoperationkindofInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdoperationkindof: ?{|
    +operationkindof: ?{|
      +name: string,
      +norder: number,
      +operationkindofid: any,
      +symbol: ?string,
      +kindofid: number,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdoperationkindofInput!
) {
  fnUpdoperationkindof(input: $input) {
    operationkindof {
      name
      norder
      operationkindofid
      symbol
      kindofid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdoperationkindofPayload",
    "kind": "LinkedField",
    "name": "fnUpdoperationkindof",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Operationkindof",
        "kind": "LinkedField",
        "name": "operationkindof",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationkindofid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kindofid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b50ef4c119762bfc26a0f1920220a4c5",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdoperationkindofInput!\n) {\n  fnUpdoperationkindof(input: $input) {\n    operationkindof {\n      name\n      norder\n      operationkindofid\n      symbol\n      kindofid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5dc524a9c7f89adad24a96d49d79d09';

module.exports = node;
