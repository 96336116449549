import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'


const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vextrascontcodificares === undefined ? null : dbdata.vextrascontcodificares.edges
    .map(edge => {
      return{
        ...edge.node,         
      }
    })
  // console.log(`[dbextrascontsCodificare.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
    // {id: 'operationcodeid', operationcodeid: 0, nameoperationcode: 'null'},
    ...dbdata.voperationcodes.edges.map(edge => {
    const {operationcodeid, name, symbol, code, nameoperationkindof, } = edge.node
    return {id: 'operationcodeid', operationcodeid, nameoperationcode: `${nameoperationkindof}: ${name}(${symbol !== null ? symbol : ''})(${code})(${operationcodeid})`}
  })
  ]
  // console.log(`[dbextrascontsCodificare.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
  const dbOptions1 = [
    ...dbdata.vcollections.edges
    .filter(edge => parseInt(edge.node.collectionid) === 2 || parseInt(edge.node.collectionid) === 3)
    .map(edge => {
      const {collectionid, nameRo, } = edge.node
      return {id: 'collectionid', collectionid, symbolcollection: `${nameRo}`}
  })
  ]
  // console.log(`[dbextrascontsCodificare.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, }} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VtherapypriceCondition, defaultValue: {}, }
    ) 
    {
      vextrascontcodificares(orderBy: NAME_ROCOLLECTION_ASC) {
        edges {
          node {
            extrascontcodificareid
            key
            operationcodeid
            nameoperationcode
            symboloperationcode
            codeoperationcode
            norderoperationcode
            collectionid
            nameRocollection
            nameEncollection
            symbolcollection
          }
        }
      }
      voperationcodes(orderBy: OPERATIONCODEID_ASC) {
        edges {
          node {
            operationcodeid
            operationkindofid
            nameoperationkindof
            operationkindofsymbol
            norderoperationkindof
            name
            symbol
            code
            norder
          }
        }
      }
      vcollections(orderBy: COLLECTIONID_ASC) {
          edges {
            node {
              collectionid
              nameRo
              # nameEn
              # symbol
            }
          }
        }           

    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

