import React from 'react'
import Help from './help'

import Component from './page'
import enumRols from '../../../enums/rols'

export default ({params, events}) => {
    const {toggleHelp, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <Component params = {params} events={events} / >
        </>
    </>)
    }
        
