import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isadminApp, isDeveloper}}) => 
    <>
        { isadminApp ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>administrare utilizatori amos</li>
                    <li>campurile <b>UtilizatorId, utilizator, CNP, username, email, telefon, datainceput, datasfarsit, activ, parola</b> sunt specifice utilizator independent de micro applicatie, date format: YYYY-MM-DD</li>
                    <li>pentru campurile <u>CNP, username, email</u> exista unicitate in tabela, cu alte cuvinte nu pot fi inregistrati doi utilizatori cu acelasi CNP, username sau email</li>
                    <li>campul telefon este optional</li>
                    <li>sunt posibile urmatoarele operatii: </li>
                    <ul>
                        <li>adauga utilizator nou [<b>+</b>], actualizeaza campuri utilizator, sterge utilizator, refresh date utilizatori</li>
                        <li>preluare date utilizatori din tabela de import. Dupa preluare este necesar click pe buton <b>Refresh</b> pentru vizualizarea datelor </li>
                        <li><b>odata cu stergerea unui utilizator</b> vor fi sterse si drepturilor acestuia</li>
                        <li>resetare parola de catre adminapp <span style={{color:'red'}}><b>NEIMPLEMENTAT</b>: reset parola si trimite email la utilizator</span> </li>
                        <li style={{color:'violet', }}>schimb date: preluarea datelor din tabela de import, inclusiv utilizatorid, vezi 2 mai jos</li>
                    </ul>
                    <li>click pe <span role="img" aria-label="point_right">👉</span> permite administrare drepturi utilizator selectat pentru aplicatiile inregistrate in baza de date, fiind posibile operatii de acordare sau retragere drepturi</li>
                    <ul>
                        <li>pentru a vizualiza help administrare drepturi acordate utilizatorilor, click meniu <b>help</b> inainte de click buton <b>DREPTURI UTILIZATORI</b></li>
                    </ul>
                    <li>schimb date cu alte microaplicatii sau aplicatii externe</li>
                    <ul>
                        <li>
                            1. appid=3 : preluare date din tabela <b>importUtilizatori</b> in tabela <b>utilizatori</b> (specific pentru preluare date din Oscar[oracle] sau fisier xls)
                        </li>
                        <li>
                            2. <span style={{color:'violet', }}>link <u>administrare.utilizatori</u> permite pentru toate microaplicatiile import fisier <b>utilizatori.xls: sheets[utilizatori, drepturiUtilizatori, modelare]</b> - direct in tabelele <b>Utilizatori, AppRolUtilizatori, modelare Utilizators</b> cu pastrare UtilizatorId si actualizare sequence(specific pentru preluare date din Oscar[oracle] sau din microaplicatie utilizatori(appid = 3)</span>
                        </li>
                        <li>
                            3. <span style={{color:'deeppink', }}>link <u>administrare.utilizatori</u> permite pentru microaplicatia utilizatori(appi=3) sincronizare cu AD prin import fisier <b>utilizatori_syncAD.xls: sheets[syncAd]</b> si actualizare camp email direct in tabela <b>Utilizatori, WHERE fie pCNP fie pUsername</b> </span>
                        </li>
                    </ul>
                    <li>este posibil export in fisier xls <b>utilizatori.xls: sheets[utilizatori, drepturiUtilizatori, modelare]</b> utilizat in schimbul de date de catre restul microaplicatiilor si listare in format pdf</li>
                    <li>click link <b>help</b> pentru a ascunde help text </li>
                </ul>
            </div>
        : 
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>utilizatori amos</li>
                    <li>campurile utilizator', cnp, email, telefon, parola sunt editabile</li>
                    <li>click pe <span role="img" aria-label="point_right">👉</span> permite vizualizare drepturi utilizator pentru aplicatiile inregistrate in baza de date</li>
                    <li>pentru a vizualiza help administrare drepturi acordate utilizatorilor, click meniu <b>help</b> inainte de click buton <b>DREPTURI UTILIZATORI</b></li>
                    <li>click link <b>help</b> pentru a ascunde help text </li>
                </ul>
            </div>
        }
            { isDeveloper ?
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.utilizatori</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.Utilizators</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>UtilizatorId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Utilizator</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>CNP</td>
                            <td className='td'>VARCHAR(13)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>username</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>email</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>telefon</td>
                            <td className='td'>VARCHAR(20)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>DataInceput</td>
                            <td className='td'>Date</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>DataSfarsit</td>
                            <td className='td'>Date</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Activ</td>
                            <td className='td'>BOOLEAN</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>parola</td>
                            <td className='td'>VARCHAR(1024)</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vUtilizators(orderBy: UTILIZATORID_ASC, condition: $condition) (utilizatorid,  utilizator, username, email, cnp, telefon, activ, parola, datainceput, datasfarsit)</td>
                        <td className='td'>, unde &#x0007B; condition: &#x0007B; utilizatorid: utilizatorid &#x0007D;  &#x0007D; </td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vutilizatorapprols(orderBy: UTILIZATORID_ASC) (utilizatorid, appid, rolid)</td>
                        <td className='td'>dbvdata1</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vutilizatormodelats(orderBy: UTILIZATORID_ASC) (utilizatormodeleazaid, utilizatorid)</td>
                        <td className='td'>dbvdata2</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addUtilizator(p_utilizator, p_CNP, p_username, p_email, p_telefon, p_parola)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updUtilizator(p_keyfield, p_value, p_UtilizatorId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_UtilizatorId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI[xls]</td>
                        <td className='td'>ups</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_upsUtilizator(p_utilizatorId, p_utilizator, p_CNP, p_username, p_email, p_telefon, p_parola)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </div>
            : <></>
            }
    </>
