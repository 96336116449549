import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapistpatientInput!)
{
  fnUpstherapistpatient(input: $input) {
    therapistpatient {
      therapistpatientid
      therapistid
      patientid
    }
  }
}
`
 