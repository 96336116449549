import styled from 'styled-components'

export const StyleReport = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`
export const StyleMenuRapoarte1 = styled.div`
  display: grid;
  grid-column: 1;
  grid-template-rows: repeat(1, 1fr);
`
export const StyleMenuRapoarte2 = styled.div`
  grid-column: 2;
  height: 365px;
`
export const StyleMenuRapoarte3 = styled.div`
  grid-column: 3;
`
export const StyleMenuRapoarte4 = styled.div`
  display: grid;
  grid-column: 4;
  grid-template-rows: repeat(1, 1fr);
`

export const StyleMenuReport = styled.div`
  grid-row: 1;
`
export const StyleMenuReport1 = styled.div`
  grid-row: 2;
`
export const StyleMenuReport2 = styled.div`
  grid-row: 3;
`
export const StyleMenuReport3 = styled.div`
  grid-row: 4;
`
export const StyleMenuReport4 = styled.div`
  grid-row: 5;
`
export const StyleMenuReport5 = styled.div`
  grid-row: 6;
`
export const StyleMenuReport6 = styled.div`
  grid-row: 7;
`
export const StyleMenuReport7 = styled.div`
  grid-row: 8;
`
export const StyleMenuReport8 = styled.div`
  grid-row: 9;
`
export const StyleMenuReport9 = styled.div`
  grid-row: 10;
`
export const StyleMenuReport10 = styled.div`
  grid-row: 11;
`
export const StyleMenuReport11 = styled.div`
  grid-row: 12;
`
export const StyleMenuReport12 = styled.div`
  grid-row: 13;
`
export const StyleMenuReport13 = styled.div`
  grid-row: 14;
`
export const StyleMenuReport14 = styled.div`
  grid-row: 15;
`
export const StyleMenuReport15 = styled.div`
  grid-row: 16;
`
export const StyleMenuReport16 = styled.div`
  grid-row: 17;
`
export const StyleMenuReport17 = styled.div`
  grid-row: 18;
`
export const StyleMenuReport18 = styled.div`
  grid-row: 19;
`
