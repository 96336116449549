import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Styles from '../../../uioscar/tablelib/styleTable'
import SubComponent from '../dbTherapysessions/component'
import SubComponentCalendarSessionTerapeut from './component'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import calendars from '../../../enums/calendars'
import collections from '../../../enums/collections'
import configxlsreport from '../../../uioscar/xls/configxlsreport'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import formatdateymd from "../../../uioscar/formatdateymd"
import getWeek from '../../../uioscar/getWeek'
import groupcols from './groupcols'
import initiall from '../dbTherapysessions/initiall'
import months from '../../../enums/months'
import mutation from '../../../uioscar/mutation'
import mutationAdd from '../dbTherapysessions/mutationAdd'
import mutationUpdcurrentmonth from './mutationUpdcurrentmonth'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import search from './search'
import subcomponenttitle from './subcomponenttitle'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import xlsheaders from '../../../uioscar/xls/xlsheaders'

// import wsnames from './xls/wsnames'
// import headers from './xls/headers'
// import Readxlsfile from '../../../uioscar/readxlsfile'
// import Toxlsfile from '../../../uioscar/toxlsfile'
// import ToPdf from '../../../uioscar/pdf'
// import Readxlsfile from '../../../uioscar/readxlsfile'
// import mutationUps from './mutationUps'
// // import mutationAdds from './mutationAdds'
// import mutationUpd from './mutationUpd'
// import mutationDel from './mutationDel'
// import SubComponentCalendarSessionTerapeut from './component'
// import nulldate from '../../../enums/nulldate'
// import initiall from './initiall'
//import {stringify} from 'flatted'
// import enumcollections from '../../../enums/collections'


export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, therapyid} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[dbCalendarsessions.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({ dbOptions1: dbdata.dbOptions1, dbOptions9: dbdata.dbOptions9,   }), 
    [ dbdata.dbOptions1, dbdata.dbOptions9, ])   
    const searchAddDialog = React.useMemo(() => search(), [])
    const configreportAddDialog = React.useMemo(() => configxlsreport(xlsheaders([dbdata.dbvdata[0]])), [dbdata.dbvdata, ])
    const initialAddDialog = React.useMemo( () => initiall({therapyid, dbOptions: dbdata.dbOptions, 
      dbOptions2: dbdata.dbOptions2, dbOptions4: dbdata.dbOptions4, //dbOptions3: dbdata.dbOptions3, 
      dbOptions5: dbdata.dbOptions5, dbOptions6: dbdata.dbOptions6, dbOptions7: dbdata.dbOptions7, 
      dbOptions11: dbdata.dbOptions11,      
    }), 
    [therapyid, dbdata.dbOptions, dbdata.dbOptions2, dbdata.dbOptions4, 
      dbdata.dbOptions5, dbdata.dbOptions6, dbdata.dbOptions7, dbdata.dbOptions11, ]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[dbCalendarsessions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[dbCalendarsessions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {subscriptionid} = d.original
    //     // console.log(`[dbCalendarsessions.table.onrowSelected.map] - subscriptionid: ${subscriptionid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({subscriptionid})
    //   })
    // console.log(`[dbCalendarsessions.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
        {!params.isTherapysession ?
          <Dialog key={`SubComponent`} 
            params = {{...params, ...subcomponenttitle(params.isTherapysession, row.values.date),
              date: row.values.date, isTherapysession: !params.isTherapysession}} // false: apel calendar care va vizualiza lista terapii
            events={{onCloseDialog, ...events}} subComponent={SubComponentCalendarSessionTerapeut} />
          :<Dialog key={`SubComponent`} 
            params = {{...params, ...subcomponenttitle(params.isTherapysession, row.values.date), 
              date: row.values.date }} // true: va vizualiza lista terapii
            events={{onCloseDialog, ...events}} subComponent={SubComponent} />
        }
      </>
    ),
    [params, events]
  )

  const onError = errors => {
      // console.log(`[dbCalendarsessions.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[dbCalendarsessions.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  // const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[dbCalendarsessions.table] - format: ${format}`)

  const onSearch = (item) => {      
      const arrdate = formatdateymd(new Date(item.data)).split('-')
      const _date = new Date(arrdate[0], arrdate[1]-1, arrdate[2])
      const selweek = _date !== undefined ? parseInt(getWeek(_date)) : 1        
      params.setselweek(selweek.toString())
      const input = {input: {  pKeyfield: 'week', pValue: selweek.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) ) 
      
      const {selyear} = params
      if(selyear !== parseInt(arrdate[0])) { // anul selectat
        // const nextyear = parseInt(arrdate[0])
        params.setselyear(arrdate[0])
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: {  pKeyfield: 'year', pValue: arrdate[0], pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.onSearch] - nextyear: ${arrdate[0]}`)
      }

      
      }
  
    const [newxlsheaders, setnewxlsheaders] = React.useState(xlsheaders([dbdata.dbvdata[0]]))
    const onConfigReport = (item) => {
      setnewxlsheaders([xlsheaders([dbdata.dbvdata[0]])[0].filter(header => item[header.field])])
    }
  
    // const [n, setn] = React.useState(0)
  // // const [inputs, setinputs] = React.useState([])
  // // const [drinputs, setdrinputs] = React.useState([])
  // const ondbExcellAdd = (xls) => {
  //   // console.log(`[dbCalendarsessions.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
  //   const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
  //   // console.log(`[dbCalendarsessions.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

  //   const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
  //   sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  // }

  // const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
  //   async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
  //   index => setn(index),
  //   () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  // )

  // const getinputs = ({sheet, xls}) => {
  //   // fiecare sheet devine un inputs array pentru inregistrare in baza de date
  //   switch(sheet) {
  //     case wsnames[0]:
  //       // console.log(`[dbCalendarsessions.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
  //       return xls.map(item => {
  //         // console.log(`[dbCalendarsessions.table.getinputs.map] - item: ${JSON.stringify(item)}`)
  //         return {input: {
  //           pSubscriptionid: parseInt(item.subscriptionid), 
  //           pPatientid: parseInt(item.patientid), pTherapypriceid: parseInt(item.therapypriceid), 
  //           pDate: item.date, pTherapyid: parseInt(item.therapyid), 
  //           pTherapy2Id: item.therapy2Id !== undefined && item.therapy2Id !== null ? parseInt(item.therapy2Id) : parseInt(nullint), 
  //           pTherapytypeid: parseInt(item.therapytypeid), pTimeid: parseInt(item.timeid), 
  //           pTime2Id: item.time2Id !== undefined && item.time2Id !== '' ? parseInt(item.time2Id) : parseInt(nullint), 
  //           pTime: parseInt(item.time), pPrice: parseInt(item.price), pName: item.nametherapyprice, 
  //           pNtherapysession: parseInt(item.ntherapysession), 
  //           pNtherapysession1: item.ntherapysession1 !== undefined && item.ntherapysession1 !== '' ? parseInt(item.ntherapysession1) : parseInt(nullint), 
  //           pMofntherapysession: parseInt(item.mofntherapysession), pNbmonths: parseInt(item.nbmonths), pFinalizat: item.finalizat           
  //           pSubscriptionid: item.subscriptionid !== undefined && item.subscriptionid !== '' ? parseInt(item.subscriptionid) : parseInt(nullint), 
  //         }}
  //         })
  //     default:
  //   }     
  // }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pSubscriptionid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[dbCalendarsessions.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pPatientid: item.patientid !== undefined && item.patientid !== '' ? parseInt(item.patientid) : parseInt(nullint), 
      pFirstname: item.firstname !== undefined && item.firstname !== '' ? item.firstname : nullstring, 
      pLastname: item.lastname !== undefined && item.lastname !== '' ? item.lastname : nullstring, 
      // pTherapistid: item.therapistid !== undefined && item.therapistid !== '' ? parseInt(item.therapistid) : parseInt(nullint), 
      pTherapisttherapypriceid: item.therapisttherapypriceid !== undefined && item.therapisttherapypriceid !== '' ? parseInt(item.therapisttherapypriceid) : parseInt(nullint), 
      pDate: item.date, 
      pFromid: item.fromid !== undefined && item.fromid !== '' ? parseInt(item.fromid) : 33, // default ora 8:00  //pTherapypriceid: parseInt(item.therapypriceid), 
      // pSubscriptiondetailid: item.subscriptiondetailid !== undefined && item.subscriptiondetailid !== '' ? parseInt(item.subscriptiondetailid) : parseInt(nullint), 
      pCalendarid: item.calendarid !== undefined && item.calendarid && item.calendarid !== '' ? parseInt(item.calendarid) : calendars.sedinta.key , 
      pRoomid: item.roomid !== undefined && item.roomid !== '' ? parseInt(item.roomid) : parseInt(nullint),  
      pPatientnotes: item.patientnotes !== undefined && item.patientnotes !== '' ? item.patientnotes : nullstring, 
      pTherapistnotes: item.therapistnotes !== undefined && item.therapistnotes !== '' ? item.therapistnotes : nullstring, 
      pCollectionid: item.collectionid !== undefined && item.collectionid !== '' ? parseInt(item.collectionid) : collections.cash.key, 
      pPrice: item.value !== undefined && item.value !== '' ? parseInt(item.value) : parseInt(nullint), 
      pSubscriptionid: item.subscriptionid !== undefined && item.subscriptionid !== '' ? parseInt(item.subscriptionid) : parseInt(nullint), 
    },}
      // console.log(`[dbCalendarsessions.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError), ondbRefresh)
  }

  const ondbUpd = (field) => {
    // // console.log(`[dbCalendarsessions.table.ondbUpd] - db Upd: {subscriptionid, columnId, value}: ${JSON.stringify({subscriptionid: field.subscriptionid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId === 'selyear'  || field.columnId === 'selmonth') return
    if(field.columnId === 'selyearid') {
      params.setselyear(field.value.toString())
      params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'selmonthid') {
      params.setselmonth({selmonthid: field.value.toString(), monthname: months[ field.value]})
      const input = {input: {  pKeyfield: 'month', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'selweek') {
      params.setselweek(field.value.toString())
      const input = {input: {  pKeyfield: 'week', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'therapistid') {
      params.setbusinesstherapistid(parseInt(field.value))
    }
    // if(field.columnId  !== 'patient'&& field.columnId  !== 'therapyprice'){
    //   const input = {input: {  pKeyfield: field.columnId, 
    //     pValue: field.columnId === 'finalizat' ? field.value.toString() : field.value, 
    //     pSubscriptionid: parseInt(field.subscriptionid)},}
    //   // console.log(`[dbCalendarsessions.table.ondbUpd]- subscriptionid: ${field.subscriptionid}, input: ${JSON.stringify(input)}`)  
    //   asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError), ondbRefresh)  
    // }  
  }

  const ondbDel = (selectedFlatRows) => {
    // selectedFlatRows.map(selectedFlatRow => {
    //   const {original: {date}} = selectedFlatRow
    //   params.setseldate(date) // set condition pentru query: date
    //   // console.log(`[dbCalendarsessions.table.ondbDel]- date: ${date}, `)  
    //   return true
    //   // const input = {input: {pSubscriptionid: parseInt(subscriptionid)},}
    //   // // console.log(`[dbCalendarsessions.table.ondbDel]- subscriptionid: ${subscriptionid}, input: ${JSON.stringify(input)}`)  
    //   // return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    // })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[dbCalendarsessions.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    // params.setseldate(null) // set condition pentru query: date = null
    if(!visibleerror)  onRefresh() 
  }
  const ondbNext = () => { 
    const {selweek, selyear} = params
    const week = parseInt(selweek)

    const weeksyear = parseInt(getWeek(new Date(`${selyear}-12-31`)))
    // console.log(`[dbCalendarsessions.table.ondbNext] - year: ${selyear}, weeksyear: ${weeksyear}`)

    const nextweek = week < weeksyear ? week + 1 : 1
    params.setselweek(nextweek.toString())
    const input = {input: {  pKeyfield: 'week', pValue: nextweek.toString(), pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  

    if(week >= weeksyear) { // anul urmator
      const {selyear} = params
      // params.setselyear(nextyear)
      const nextyear = parseInt(selyear) + 1
      const nextweek = 1
      // params.setselweek(nextweek)
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', pValue: nextyear.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbNext] - nextyear: ${nextyear}`)
      const winput = {input: { pKeyfield: 'week', pValue: nextweek.toString(), pCurrentmonthid: 1},}
      asyncForEach([winput], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbPrev] - nextweek: ${nextweek}`)

      const minput = {input: { pKeyfield: 'month', pValue: '1', pCurrentmonthid: 1},}
      asyncForEach([minput], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbPrev] - nextweek: ${nextweek}`)
    }
  }
  
  const ondbPrev = () => { 
    const {selweek} = params
    const week = parseInt(selweek)
    const {selyear} = params
    const weeksyear = parseInt(getWeek(new Date(`${selyear}-12-31`)))
    if((selyear === 2021 && week > 1) || selyear > 2021){
      const prevweek = week > 1 ? week - 1 : weeksyear
      params.setselweek(prevweek.toString())
      const input = {input: {  pKeyfield: 'week', pValue: prevweek.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  
    
      if(week === 1) { // anul precedent
        const prevyear = parseInt(selyear) - 1
        // params.setselyear(prevyear)
        const prevweek = parseInt(getWeek(new Date(`${prevyear}-12-31`)))
        // params.setselweek(prevweek)
        // console.log(`[dbCalendarsessions.table.ondbNext] - prevyear: ${prevyear}, prevweek: ${prevweek}`)
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: { pKeyfield: 'year', pValue: prevyear.toString(), pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbPrev] - prevyear: ${prevyear}`)
        const winput = {input: { pKeyfield: 'week', pValue: prevweek.toString(), pCurrentmonthid: 1},}
        asyncForEach([winput], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbPrev] - prevweek: ${prevweek}`)

        const minput = {input: { pKeyfield: 'month', pValue: '12', pCurrentmonthid: 1},}
        asyncForEach([minput], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbPrev] - nextweek: ${nextweek}`)
        }
    }
  }

  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[dbCalendarsessions.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[dbCalendarsessions.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
  // console.log(`[dbCalendarsessions.table] - isresponsabilbusiness: ${isresponsabilbusiness}, rols: ${JSON.stringify(rols)}`) 

  const prevcalendarcell = (id, original) => {
    
    if(id.slice(0, 1) === 'h'){
    // if(id.slice(0, 1) === 'h' && original[id] === 'AB/Rt'){
      const _previdquoters = parseInt(id.slice(3, 5))-15
      const previdquoters = _previdquoters >= 0 ? _previdquoters : 45
      const previdhour = parseInt(id.slice(1, 3)) - (_previdquoters >= 0 ? 0 : 1 )
      const newid = `h${previdhour<10?'0':''}${previdhour}${previdquoters=== 0 ? '00' : previdquoters}`
      // const originalvalue = original[id]
      // const originalnewvalue = original[newid]
      // console.log(`[dbCalendarsessions.table.prevcalendarcell] - id: ${id}, newid: ${newid}, original[id]: ${originalvalue}, original[newid]: ${originalnewvalue}`) 
      // // console.log(`[dbCalendarsessions.table.prevcalendarcell] - id: ${id}, newid: ${newid}, original[id]: ${original[id]}, original[newid]: ${original[newid]}`) 
      // // console.log(`[dbCalendarsessions.table.prevcalendarcell] - id: ${id}, newid: ${newid}, original[id]: ${original[id]}, previdhour: ${previdhour}, previdquoters: ${previdquoters}`) 
      return original[newid]
    }
    return null
  } 

// // console.log(`[dbTherapysessions.page] - params.dbvtotalpos: ${JSON.stringify(params.dbvtotalpos)}` )
// // console.log(params.dbvtotalpos)
//   const totalpos = params.date !== undefined && params.dbvtotalpos !== undefined ? params.dbvtotalpos.filter(item => item.collectionid === enumcollections.POS.key).length !== 0 ? params.dbvtotalpos[0].value : 0 : null
//   const totalcash = params.date !== undefined && params.dbvtotalpos !== undefined ? params.dbvtotalpos.filter(item => item.collectionid === enumcollections.cash.key).length !== 0 ? params.dbvtotalpos[0].value : 0 : null

// // console.log(`[dbTherapysessions.page] - totalpos: ${totalpos} totalcash: ${totalcash}, params.dbvtotalpos: ${JSON.stringify(params.dbvtotalpos)}` )
// // console.log(params.dbvtotalpos)

const isresponsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false
const visible = params.subcompomponent === undefined

const issearch = true
const isconfigreport = visible && isresponsabilmanagement

return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>calendar saptamanal terapeut</h3> */}
      <GroupcolumnsRadioButtonInput params = {{title: title(params.therapist, params.selyear, params.selmonth.monthname, params.selweek), groupcol, groupcols}} events={{onsetcells}} />
      {/* { params.totalpos !== null ? <h4 style={{margin: '1.875rem'}}>TOTAL POS: {totalpos}, TOTAL cash: {totalcash}</h4> : <></>} */}
      
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(isresponsabilbusiness), onrowSelected,
          EditableCell: EditableCell, editabledata: appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilbusiness ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key) ? AddDialog : undefined, 
          searchDialogComponent: issearch ? {AddDialog, onSearch, searchAddDialog: searchAddDialog, 
            // handlenextPage: handlenextSearchPage, handleprevPage: handleprevSearchPage 
          } : undefined, 
          configreportDialogComponent: isconfigreport ? {AddDialog, onConfigReport, configreportAddDialog: configreportAddDialog, 
            // handlenextPage: handlenextReportConfigPage, handleprevPage: handleprevReportConfigPage 
          } : undefined, 
          // importxlsfields: isresponsabilbusiness ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          // exportxlsfields: isresponsabilbusiness ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          // pdf: isresponsabilbusiness ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbNext, ondbPrev, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          prevcalendarcell, therapistbackgroundColors: dbdata.dbvdata1,
          skipPageReset, setSkipPageReset, renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: false, //isresponsabilbusiness && appid === enummapps.utilizatoriapp.key, 
              visibleselectedFlatRows: true, visibleFilter: false,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true, visiblenexprev: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: 'Calendarsessions', wsnames: ['calendarsessions', ]}} 
          headers={newxlsheaders} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} //.map(item => item.h0900 !== null ? '*' : null)
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
