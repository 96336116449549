import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import formatdateymd from "../../../uioscar/formatdateymd"
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'



export default ({params, events}) => {
    const {dbkey, toggleHelp, selyear, selmonth, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // console.log(`[configTherapistDialogReports.component] - {selyear, selmonth}: ${JSON.stringify({selyear, selmonth})}`)
    // console.log(`[configTherapistDialogReports.component] - {params}: ${JSON.stringify(params)}`)
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    const lastDayOfMonth = formatdateymd(new Date(selyear, selmonth.selmonthid, 0))
    const [search, setsearch] = React.useState({data: formatdateymd(lastDayOfMonth), })
    const arrdate = search.data.split('-')
    // console.log(`[configTherapistDialogReports.component.component] - search: ${JSON.stringify(search)}, search.data: ${search.data} typeof(search.data): ${typeof(search.data)}, `)
    // console.log(`[configTherapistDialogReports.component.component] - arrdate: ${JSON.stringify(arrdate)} `)
    // console.log(`[configTherapistDialogReports.component] - {_selyear, _selmonth}: ${JSON.stringify({_selyear, _month})}`)

    // console.log(`[configTherapistDialogReports.component] - {selyear, selmonth}: ${JSON.stringify({selyear, selmonth})}`)
    const variables = { pRefresh: refresh, 
        condition: {year: parseInt(arrdate[0]), month: parseInt(arrdate[1])},
        // condition: {year: parseInt(selyear), month: parseInt(selmonth.selmonthid)},
        conditionyear:{year: parseInt(arrdate[0])} 
    }
    // console.log(`[configTherapistDialogReports.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[configTherapistDialogReports.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[configTherapistDialogReports.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} 
                    params = {{...params, onRefresh, refresh, search, setsearch,}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
