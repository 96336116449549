/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DtSyncadutilizatorInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pUsername: string,
  pCnp: string,
|};
export type mutationSyncADMutationVariables = {|
  input: DtSyncadutilizatorInput
|};
export type mutationSyncADMutationResponse = {|
  +dtSyncadutilizator: ?{|
    +utilizator: ?{|
      +activ: boolean,
      +cnp: string,
      +datainceput: any,
      +datasfarsit: ?any,
      +email: string,
      +parola: string,
      +telefon: ?string,
      +username: string,
      +utilizator: string,
      +utilizatorid: any,
    |}
  |}
|};
export type mutationSyncADMutation = {|
  variables: mutationSyncADMutationVariables,
  response: mutationSyncADMutationResponse,
|};
*/


/*
mutation mutationSyncADMutation(
  $input: DtSyncadutilizatorInput!
) {
  dtSyncadutilizator(input: $input) {
    utilizator {
      activ
      cnp
      datainceput
      datasfarsit
      email
      parola
      telefon
      username
      utilizator
      utilizatorid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DtSyncadutilizatorPayload",
    "kind": "LinkedField",
    "name": "dtSyncadutilizator",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Utilizator",
        "kind": "LinkedField",
        "name": "utilizator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datainceput",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datasfarsit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parola",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "telefon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utilizatorid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationSyncADMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationSyncADMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "281e04b6fc7fa257e29cfa574af2c5db",
    "id": null,
    "metadata": {},
    "name": "mutationSyncADMutation",
    "operationKind": "mutation",
    "text": "mutation mutationSyncADMutation(\n  $input: DtSyncadutilizatorInput!\n) {\n  dtSyncadutilizator(input: $input) {\n    utilizator {\n      activ\n      cnp\n      datainceput\n      datasfarsit\n      email\n      parola\n      telefon\n      username\n      utilizator\n      utilizatorid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a647ef1d06af01bc7744354a6acfbce';

module.exports = node;
