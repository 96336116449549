
export default ({dbOptions2, dbOptions3, }) => ({
  AddDialogtitle: ['Adauga extras cont', ''],
    columns: [
      {header: 'date', accessor: 'date', type: 'date', show: 'true', },
      {header: 'collection', accessor: 'collection', accessorid: 'collectionid', type: '', show: true,
        autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '33.3rem', text: 'Alege collection ..'},},
      {header: 'operatiune', accessor: 'operatiune', type: 'text', show: true,},
      {header: 'debit', accessor: 'debit', type: 'number', show: 'true', },
      {header: 'credit', accessor: 'credit', type: 'number', show: 'true', },    
      {header: 'beneficiar', accessor: 'beneficiar', type: 'text', show: 'true', },
      {header: 'ordonator', accessor: 'ordonator', type: 'text', show: 'true', },
      {header: 'contul', accessor: 'contul', type: 'text', show: 'true', },
      {header: 'banca', accessor: 'banca', type: 'text', show: 'true', },
      {header: 'detalii', accessor: 'detalii', type: 'text', show: 'true', },
      {header: 'referinta', accessor: 'referinta', type: 'text', show: 'true', },
      {header: 'detalii2', accessor: 'detalii2', type: 'text', show: 'true', },
      {header: 'operationcode', accessor: 'operationcode', accessorid: 'operationcodeid', type: '', show: true,
        autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '33.3rem', text: 'Alege tip operatiune ..'},},
    ],
    initialdata: {
      date: '', 
      operatiune: '',  
      debit: '', 
      credit: '', 
      beneficiar: '',  
      ordonator: '', 
      contul: '', 
      banca: '', 
      detalii: '', 
      referinta: '', 
      detalii2: '', 
      operationcodeid: '', 
      collectionid: '', 
      subRows: undefined,
    },    
  })
