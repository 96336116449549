
export default {
    AddDialogtitle: ['Adauga therapy types', ''],
    columns: [
      {header: 'nameRo', accessor: 'nameRo', type: 'text', show: 'true', },
      {header: 'nameEn', accessor: 'nameEn', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'ntherapysession', accessor: 'ntherapysession', type: 'number', show: 'true', },
      {header: 'ntherapysession1', accessor: 'ntherapysession1', type: 'number', show: 'true', },
    ],
    initialdata: {
      nameRo: '',
      nameEn: '',
      symbol: '',
      ntherapysession: '',
      ntherapysession1: '',
      subRows: undefined,
    },    
  }

