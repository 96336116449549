import React from 'react'
import Button from '@material-ui/core/Button'
import sdbtnstyle from '../styles/styles/sdbtnstyle'


// import { makeStyles } from '@material-ui/core/styles';
// const useStyles = makeStyles({
//   root: {
//     // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     background: 'linear-gradient(45deg, #026B8B 30%, #FF8E53 90%)',
//     border: 0,
//     borderRadius: 3,
//     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//     color: 'white',
//     height: 32,
//     // width: 400,
//     padding: '0 5px',
//   },
// });

 
export default ({exportxlsfields: {title, ondbExcellExport, }}) => 
    <Button size="small" variant="contained" color="secondary" className={sdbtnstyle().xls} component="span" onClick={() => ondbExcellExport()} >
      {title} 
    </Button>
