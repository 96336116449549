import './index.css'

import * as serviceWorker from './serviceWorker'

import App from './App'
// import React from 'react'
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')

// react v 17.0.2
ReactDOM.render(<App tab="home" />, container)

// react v 18 => nu merge din cauza lui cors
// const root = createRoot(container)
// root.render(<App tab="home" />)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
