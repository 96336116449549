import React from 'react'
import { Link } from 'react-router-dom' 
import enumAuth from '../../../../enums/authenticated'
import enummapps from '../../../../enums/mapps'

import Icon from '@mdi/react'
import { mdiAccount,  } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/

export default ({params, events}) => {
    const {appid, } = params
    const { utilizatorOscar: { utilizatormodelat: { isauthenticated, rols}}, } = params
    const rolslength = rols !== undefined ? rols.length : 0

    const visible = appid !== enummapps.mhappyworld.key && rolslength > 0 &&
        (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
    const rotating = false 
    
    return (
        <>
            {visible ? 
                <>
                    <Link to='/adminUtilizatori' title='utilizator'>
                        {!rotating ? <Icon path={mdiAccount} title="utilizator" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                        {rotating ? <Icon path={mdiAccount} title="utilizator" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                    </Link> 
                </>
                : <> </>
            } 
        </>
    )
}