
export default [
    {
        collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
        nameRo: {field: 'nameRo', values: ['', '', 'nameRo'], width: 30, isLastRow : false},
        nameEn: {field: 'nameEn', values: ['', '', 'nameEn'], width: 30, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 30, isLastRow : false},
    }, 
]

