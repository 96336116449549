import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyearmng: selyear, selmonthmng: selmonth, } = params

  const dbvdata = dbdata.vfrincasaridetails === undefined || dbdata.vfrincasaridetails.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }]
    : dbdata.vfrincasaridetails.edges.map((edge, index) => {
      return{
        ...edge.node, //date: edge.node.nameoperationcode === 'Sold final' ? edge.node.date : '',
        data: edge.node.level === 0 ? `${edge.node.day}/${edge.node.month < 10 ? `0${edge.node.month}` : edge.node.month}` 
          : edge.node.level === 1 ? 'Total'
          : edge.node.level === 2 ? 'T.incasari'
          : edge.node.level === 3 ? 'T.plati'
          : '',
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
      })
  // console.log(`[raportIncasariDetails.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  
  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[raportIncasariDetails.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[raportIncasariDetails.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vmonthkindofs.edges.map(edge => {
      const { monthkindofid, name, } = edge.node
      return {id: 'monthkindofid', monthkindofid: monthkindofid, namemonthkindof: name}
  })
  ]
  // console.log(`[raportIncasariDetails.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        pMonth: {type: Int, defaultValue: 1, }
        pYear: {type: Int, defaultValue: 2021, }
        # condition: {type: VrincasaridetailCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
    ) 
    {
      vfrincasaridetails(pMonth: $pMonth, pYear: $pYear) {
        edges {
          node {
            month
            day
            year
            monthname
            date
            ncode
            code
            symbol
            collectionid
            collection
            percent
            value
            therapistvalue
            level
          }
        }
      }
      # vrincasaridetails(condition: $condition) {
      #   edges {
      #     node {
      #       month
      #       day
      #       year
      #       monthname
      #       date
      #       ncode
      #       code
      #       symbol
      #       collectionid
      #       collection
      #       percent
      #       value
      #       therapistvalue
      #       level
      #     }
      #   }
      # }
        vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
        edges {
          node {
            monthkindofid
            name
            # symbol
          }
        }
      }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

