
import webconfig from '../backend/webconfig.json'
import packageJson from '../../package.json'

const productionkey = 0
const productionkey1 = 0
export default () =>
    webconfig[`db${packageJson.server}`].services.filter(service => (process.env.NODE_ENV   === 'production' ? service.key ===  productionkey || service.key === productionkey1 : true)) 
    // webconfig[`db${packageJson.dbserver}`].services.filter(service => (process.env.NODE_ENV   === 'production' ? service.key ===  productionkey || service.key === productionkey1 : true)) 
    // webconfig.db.services.filter(service => (process.env.NODE_ENV   === 'production' ? service.key ===  productionkey || service.key === productionkey1 : true)) 
