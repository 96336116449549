/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdhourInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pHourid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdhourInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdhour: ?{|
    +hour: ?{|
      +hourid: any,
      +name: string,
      +symbol: ?string,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdhourInput!
) {
  fnUpdhour(input: $input) {
    hour {
      hourid
      name
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdhourPayload",
    "kind": "LinkedField",
    "name": "fnUpdhour",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Hour",
        "kind": "LinkedField",
        "name": "hour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hourid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fdad2791efde57dc0941841def7d79fe",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdhourInput!\n) {\n  fnUpdhour(input: $input) {\n    hour {\n      hourid\n      name\n      symbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e10295bded969ac079e70c6b4dd0214f';

module.exports = node;
