import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'

export default ({params, events}) => {
    // const {toggleHelp, refresh, dbkey, date, selyear, selmonth} = params
    // const {dbkey, toggleHelp, date, selyear, selmonth, utilizatorOscar: {utilizatormodelat: {rols, therapistid}}, pTherapistid, ptherapysessionid, psubscriptionid, } = params
    const {dbkey, toggleHelp, date, utilizatorOscar: {utilizatormodelat: {rols, therapistid}}, pTherapistid, ptherapysessionid, psubscriptionid, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    const dates = date !== undefined ? date.split('-') : null
    // console.log(`[dbTherapysessions.component] - date: ${date}, typeof(date): ${typeof(date)}, dates: ${dates}`)
    const pYear = date !== undefined ? parseInt(dates[0]) : 0
    const pMonth = date !== undefined ? parseInt(dates[1]) : 0
    // const pDay = date !== undefined ? parseInt(dates[2]) : 1
    // console.log(`[dbTherapysessions.component] - {pMonth, pDay, pYear, selweek, selyear}: ${JSON.stringify({pMonth, pDay, pYear, selweek, selyear})}`)
    
    const variablesresponsabilbusiness = !isresponsabilbusiness? {therapistid: pTherapistid !== undefined ? pTherapistid : therapistid}: {} // usecase ...
    const variablestherapysubscription = isresponsabilbusiness? {therapysessionid: ptherapysessionid !== undefined ? ptherapysessionid : ptherapysessionid, }: {} // usecase abonamente
    const variablessubscription = isresponsabilbusiness? {sessionsubscriptionid: psubscriptionid!== undefined ? parseInt(psubscriptionid) : parseInt(psubscriptionid)}: {} // usecase abonamente
    // const variables = { pRefresh: refresh, 
    //     conditiontherapysession: 
    //         params.backup ? {}
    //         :ptherapysessionid !== undefined ? variablestherapysubscription
    //         : psubscriptionid !== undefined ? variablessubscription
    //         : date !== undefined ? 
    //             {...{date: date, year: parseInt(selyear), month: parseInt(selmonth.selmonthid)}, ...variablesresponsabilbusiness, }
    //             : {...{year: parseInt(selyear), month: parseInt(selmonth.selmonthid)}, ...variablesresponsabilbusiness, ...variablestherapysubscription,},
    //     condition: {year: parseInt(selyear), month: parseInt(selmonth.selmonthid), },
    //     conditionyear:{year: parseInt(selyear)}, 
    //     conditionpos: {date: date !== undefined ? date : '17-feb-2021'},
    // }
    const variables = { pRefresh: refresh, 
        conditiontherapysession: 
            params.backup ? {}
            :ptherapysessionid !== undefined ? variablestherapysubscription
            : psubscriptionid !== undefined ? variablessubscription
            : date !== undefined ? 
                {...{date: date, year: pYear, month: pMonth}, ...variablesresponsabilbusiness, }
                : {...{}, ...variablesresponsabilbusiness, ...variablestherapysubscription,},
        condition: date !== undefined ? {year: pYear, month: pMonth, }: {},
        conditionyear:date !== undefined ? {year: pYear} : {}, 
        conditionpos: {date: date !== undefined ? date : '17-feb-2021'},
    }

    // console.log(`[dbTherapysessions.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[dbTherapysessions.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[dbTherapysessions.component] - isresponsabilbusiness: ${isresponsabilbusiness}, variablesresponsabilbusiness: ${JSON.stringify(variablesresponsabilbusiness)}, variables: ${JSON.stringify(variables)}`)
    // console.log(`[dbTherapysessions.component] - pTherapistid: ${pTherapistid}`)
    // console.log(`[dbTherapysessions.component] - ptherapysessionid: ${ptherapysessionid}, psubscriptionid: ${psubscriptionid}`)
    // console.log(`[dbTherapysessions.component] - isresponsabilbusiness: ${isresponsabilbusiness}, variablessubscription: ${JSON.stringify(variablessubscription)}, variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
