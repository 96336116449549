
export default [
    [
        {field: 'utilizatorid', value: 'utilizatorid'},
        {field: 'utilizator', value: 'utilizator'},
        {field: 'cnp', value: 'cnp'},
        {field: 'username', value: 'username'},
        {field: 'email', value: 'email'},
        {field: 'telefon', value: 'telefon'},
        {field: 'datainceput', value: 'datainceput'},
        {field: 'datasfarsit', value: 'datasfarsit'},
        {field: 'activ', value: 'activ'},
        {field: 'parola', value: 'parola'},

        {field: 'app', value: 'app'},
        {field: 'appid', value: 'appid'},

        {field: 'responsabilbusiness', value: 'responsabilbusiness'},
        {field: 'responsabilmanagement', value: 'responsabilmanagement'},
        {field: 'terapeut', value: 'terapeut'},
        {field: 'pacient', value: 'pacient'},
        {field: 'contabil', value: 'contabil'},
        {field: 'liber', value: 'liber'},
        {field: 'responsabilutilizatori', value: 'responsabilutilizatori'},
        {field: 'adminapp', value: 'adminapp'},
        {field: 'superadmin', value: 'superadmin'},
        {field: 'modelare', value: 'modelare'},
        {field: 'tester', value: 'tester'},
        {field: 'developer', value: 'developer'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)













