import { MenuCenterStyle, Menuitem, Oscar3ColumnStyle } from '../../../../styles/styles/headerstyle'

import { Link } from 'react-router-dom'
import React from 'react'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import fmenuapps from '../../../../app/menuapps'
import menuappOscars from '../../../../app/menuappOscars'
import theme from '../../../../styles/themes/defaultTheme/theme'

// import Icon from '@mdi/react'
// import { mdiAccount,  } from '@mdi/js';
// import { mdiCalendarRange ,  } from '@mdi/js';

export default ({params, events}) => {
    const {appid, menuorder, utilizatorOscar: {utilizatormodelat: { apps, menuapps, rols }} } = params
    const _menuapps = menuappOscars(appid, apps, fmenuapps(menuapps))
    const visible = _menuapps.length > menuorder
    // console.log(`[header.header.oscar3] - menuorder: ${menuorder}, apps: ${JSON.stringify(apps)}, apps.length: ${apps !== undefined ? apps.length : 0}`)
    // console.log(`[header.header.oscar3] - menuorder: ${menuorder}, _menuapps: ${JSON.stringify(_menuapps)}, _menuapps[menuorder]: ${_menuapps[menuorder]}, _menuapps.length: ${_menuapps !== undefined ? _menuapps.length : 0}`)

    // const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
    const isTherapist = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.terapeut.key).length > 0 : false
    // console.log(`[header.header.oscar3] - isTherapist: ${isTherapist}`)

    const texticon = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].app   : '' 
    const url = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].link   : '' 
    // console.log(`[header.header.oscar3] - menuorder: ${menuorder}, texticon: ${texticon}, url: ${url}`)

    // const rotating = false 

    return (
        <>
            <Oscar3ColumnStyle><MenuCenterStyle><Menuitem>
                {
                    visible ?
                        <> 
                            {appid === enummapps.mhappyworld.key ?
                                <a href={url}>
                                    <TextIcon params = {{ text: texticon, title: texticon, 
                                        width: theme.theme_main.withTextIconMenu, 
                                        fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                        color: theme.theme_main.headercolor}} />
                                </a>
                            : appid === enummapps.utilizatoriapp.key ?
                            <>
                                {isTherapist ? 
                                    <Link to='/calendarsessions'>
                                        <TextIcon params = {{ text: texticon, title: 'calendar sessions', 
                                            width: theme.theme_main.withTextIconMenu*0.7, 
                                            fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                            color: theme.theme_main.headercolor}} />
                                    </Link>
                                : <></>
                                }
                                {/* {isresponsabilbusiness ? 
                                <>
                                <Link to='/calendarsessionsresponsabilbusiness'>
                                    {!rotating ? <Icon path={mdiCalendarRange } title="calendar sessions" size={1} horizontal vertical rotate={180} color="black"/> : <></>}
                                    {rotating ? <Icon path={mdiAccount} title="calendar sessions" size={1} horizontal vertical rotate={180} color="black" spin/> : <></>}
                                </Link> 
                                 </>
                                : <></>
                                } */}
                            </>
                        : 
                            <Link to='/oscar3'>
                                        <TextIcon params = {{ text: texticon, title: 'oscar3', 
                                            width: theme.theme_main.withTextIconMenu, 
                                            fill: theme.theme_main.backgroundTextMenuWorking, 
                                            color: theme.theme_main.headercolor}} />
                                </Link>}
                        </>
                    : <> </>
                }
            </Menuitem></MenuCenterStyle></Oscar3ColumnStyle>
        </>
    )
}