import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare5, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isadminApp = rols !== undefined ? (
        // rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 || 
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0) 
        : false
        const visibleadmin = isadminApp && appid === enummapps.utilizatoriapp.key
        const isresponsabilmanagement = rols !== undefined ? (
            rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0) 
            : false
        const visible = isresponsabilmanagement && appid === enummapps.utilizatoriapp.key 
    
    return (
        <>
            <StyleMenuAdministrare5>
                {
                    visible ?
                        <> 
                            <Link to='/kindofs'>
                                <TextIcon params = {{ text: 'tip operatiuni[013]', title: 'kindofs', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
                {
                    visibleadmin ?
                        <> 
                            <Link to='/monthkindofs'>
                                <TextIcon params = {{ text: 'fel luna[013]', title: 'monthkindofs', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 1/2, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare5>
        </>
    )
}

