import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddhourInput!)
{
  fnAddhour(input: $input) {
    hour {
      hourid
      name
      symbol
    }
  }
}
`
