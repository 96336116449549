import AddDialog from '../../../uioscar/tablelib/addDialog'
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import React from 'react'
import Styles from '../../../uioscar/tablelib/styleTable'
import SubComponent from '../../reportcomponents/raportTherapistsessions/component'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enummapps from '../../../enums/mapps'
import formatdateymd from "../../../uioscar/formatdateymd"
import groupcols from './groupcols'
import months from '../../../enums/months'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUpdcurrentmonth from '../../admincomponents/dbTherapysessions/mutationUpdcurrentmonth'
import search from './search'
import subcomponenttitle from './subcomponenttitle'
import title from './title'

// import Readxlsfile from '../../../uioscar/readxlsfile'
// import Toxlsfile from '../../../uioscar/toxlsfile'
// import ToPdf from '../../../uioscar/pdf'

// import Readxlsfile from '../../../uioscar/readxlsfile'



// import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
// import Button from '@material-ui/core/Button';


// import mutationUps from './mutationUps'
// import mutationAdds from './mutationAdds'



// import validation from './validation'

// import nulldate from '../../../enums/nulldate'
// import nullstring from '../../../enums/nullstring'
// import nullint from '../../../enums/nullint'

// import headers from './xls/headers'
// import XLS from '../../../uioscar/xls/xls'
// import xlsheaders from '../../../uioscar/xls/xlsheaders'

// import pdftitles from './pdf/titles'
// import pdfheaders from './pdf/headers'
// import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
// import titlepdf from './pdf/subComponent'



// import configxlsreport from '../../../uioscar/xls/configxlsreport'
// import initiall from './initiall'




// import enumRols from '../../../enums/rols'

// import wsnames from './xls/wsnames'
//import {stringify} from 'flatted'

// import pdforientation from '../../../enums/pdforientation'


export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, } = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[configTherapistDialogReports.table] - data: ${JSON.stringify(data)}`)

  // const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({dbOptions: dbdata.dbOptions, dbOptions1: dbdata.dbOptions1, 
    dbOptions2: dbdata.dbOptions2, }), [dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, ])   
  const searchAddDialog = React.useMemo(() => search(), [])
  // const configreportAddDialog = React.useMemo(() => configxlsreport(xlsheaders([dbdata.dbvdata[0]])), [xlsheaders, ])
  // const initialAddDialog = React.useMemo( () => initiall(), []) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[configTherapistDialogReports.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[configTherapistDialogReports.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {mngmentoperationid} = d.original
    //     // console.log(`[configTherapistDialogReports.table.onrowSelected.map] - mngmentoperationid: ${mngmentoperationid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({mngmentoperationid})
    //   })
    // console.log(`[configTherapistDialogReports.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      // console.log(`[configTherapistDialogReports.table.renderRowSubComponent] - params: ${JSON.stringify(params)}, `)    
      return  (
        <>
          {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
          <Dialog key={`SubComponent`} 
            params = {{...params, ...subcomponenttitle(`${row.values.firstname} ${row.values.lastname}`), 
              therapistid: row.values.therapistid, 
            }} 
            events={{onCloseDialog, ...events}} 
            subComponent={SubComponent} />
        </>
      )
    },
    [params, events]
  )

  const onError = errors => {
      // console.log(`[configTherapistDialogReports.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[configTherapistDialogReports.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  // const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  // const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[configTherapistDialogReports.table] - format: ${format}`)

  const onSearch = (item) => {
   
    // alert(`[configTherapistDialogReports.table.onSearch] - : item: ${JSON.stringify(item)}`)
    // console.log(`[configTherapistDialogReports.table.onSearch] - : item: ${JSON.stringify(item)}`)
    // params.setparentid(item.unitateid)     // unitates.unitates
    // const _search = {data: item.data, } 

    const _date = new Date(item.data)
    const lastDayOfMonth = formatdateymd(new Date(_date.getFullYear(), _date.getMonth()+1, 0))
    const _search = {data: lastDayOfMonth, } 
    params.setsearch({ ...search, ..._search })
    ondbUpd({columnId: 'selyearid', value: _date.getFullYear()})
    ondbUpd({columnId: 'selmonthid', value: _date.getMonth()+1})
  
  }

  // const [newxlsheaders, setnewxlsheaders] = React.useState(xlsheaders([dbdata.dbvdata[0]]))
  // const onConfigReport = (item) => {
  //   setnewxlsheaders([xlsheaders([dbdata.dbvdata[0]])[0].filter(header => item[header.field])])
  // }

  // const [n, setn] = React.useState(0)
  // // const [inputs, setinputs] = React.useState([])
  // // const [drinputs, setdrinputs] = React.useState([])
  // const ondbExcellAdd = (xls) => {
  //   // console.log(`[configTherapistDialogReports.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
  //   const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
  //   // console.log(`[configTherapistDialogReports.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

  //   const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
  //   sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  // }

  // const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
  //   async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
  //   index => setn(index),
  //   () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  // )

  // const getinputs = ({sheet, xls}) => {
  //   // fiecare sheet devine un inputs array pentru inregistrare in baza de date
  //   switch(sheet) {
  //     case wsnames[0]:
  //       // console.log(`[configTherapistDialogReports.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
  //       return xls.map(item => {
  //         // console.log(`[configTherapistDialogReports.table.getinputs.map] - item: ${JSON.stringify(item)}`)
  //         return {input: {
  //           pMngmentoperationid: parseInt(item.mngmentoperationid), 
  //           pDate: item.date, 
  //           pHouseregisterprevious: parseInt(item.houseregisterprevious), 
  //           pHouseregistercurrent: parseInt(item.houseregistercurrent), 
  //           pMonthkindofid: parseInt(item.monthkindofid), 
  //         }}
  //         })
  //     default:
  //   }     
  // }


  // const ondbDragandDropdb = () => {
  //   const input = {input: {pMngmentoperationid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[configTherapistDialogReports.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pDate: item.date, 
      pHouseregisterprevious: parseInt(item.houseregisterprevious), 
      pHouseregistercurrent: parseInt(item.houseregistercurrent), 
      pMonthkindofid: 0,  //parseInt(item.monthkindofid), 
    },}
    // console.log(`[configTherapistDialogReports.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[configTherapistDialogReports.table.ondbUpd] - db Upd: {mngmentoperationid, columnId, value}: ${JSON.stringify({mngmentoperationid: field.mngmentoperationid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId === 'selyear'  || field.columnId === 'selmonth') return
    if(field.columnId === 'selyearid') {
      params.setselyear(field.value.toString())
      params.setselmonth({selmonthid: '', monthname: ''})
    }
    else if(field.columnId === 'selmonthid') 
      params.setselmonth({selmonthid: field.value.toString(), monthname: months[ field.value]})
    else {
      const input = {input: {  pKeyfield: field.columnId, pValue: field.value, 
      pMngmentoperationid: parseInt(field.mngmentoperationid)},}
      // console.log(`[configTherapistDialogReports.table.ondbUpd]- mngmentoperationid: ${field.mngmentoperationid}, input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError), ondbRefresh)  
    }
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {mngmentoperationid}} = selectedFlatRow
      const input = {input: {pMngmentoperationid: parseInt(mngmentoperationid)},}
      // console.log(`[configTherapistDialogReports.table.ondbDel]- mngmentoperationid: ${mngmentoperationid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[configTherapistDialogReports.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }
  const ondbNext = () => { 
    const {selmonth: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const nextmonth = month < 12 ? month + 1 : 1
    // params.setselmonth(nextmonth.toString())
    params.setselmonth({selmonthid: nextmonth, monthname: months[ nextmonth+1]})
    const input = {input: {  pKeyfield: 'month', pValue: nextmonth.toString(), pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  

    if(month >= 12) { // anul urmator
      const {selyear} = params
      const nextyear = parseInt(selyear) + 1
      params.setselyear(nextyear.toString())
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', pValue: nextyear.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbCalendarsessions.table.ondbNext] - nextyear: ${nextyear}`)
    }

  }
  const ondbPrev = () => { 
    const {selmonth: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const {selyear} = params
    if((selyear === 2021 && month > 1) || selyear > 2021){
      const prevmonth = month > 1 ? month - 1 : 12
      // params.setselmonth(prevmonth.toString())
      params.setselmonth({selmonthid: prevmonth, monthname: months[ prevmonth+1]})
      const input = {input: {  pKeyfield: 'month', pValue: prevmonth.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  
    
      if(month === 1) { // anul precedent
        const prevyear = parseInt(selyear) - 1
        params.setselyear(prevyear.toString())
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: { pKeyfield: 'year', pValue: prevyear.toString(), pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbCalendarsessions.table.ondbNext] - prevyear: ${prevyear}`)
      }
    }
  }


  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[configTherapistDialogReports.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[configTherapistDialogReports.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  // const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  // const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
  // console.log(`[configTherapistDialogReports.table] - isadminApp: ${isadminApp}, rols: ${JSON.stringify(rols)}`) 

  const arrdate = params.search.data.split('-')
  const [selyear, selmonth, ] = arrdate

  // const {selyear, selmonth: {monthname}} = params

  const issearch = true
  // const isconfigreport = true

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>raport terapeut</h3> */}
      <GroupcolumnsRadioButtonInput params = {{title: title(selyear, months[parseInt(selmonth)]), groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns, onrowSelected,
          EditableCell: EditableCell, editabledata: appid === enummapps.utilizatoriapp.key, //editabledata: isadminApp ? appid === enummapps.utilizatoriapp.key : true,
          // dialogComponent: (appid === enummapps.utilizatoriapp.key && isadminApp) ? AddDialog : undefined, 
          searchDialogComponent: issearch ? {AddDialog, onSearch, searchAddDialog: searchAddDialog, 
            // handlenextPage: handlenextSearchPage, handleprevPage: handleprevSearchPage 
          } : undefined, 
          // configreportDialogComponent: isconfigreport ? {AddDialog, onConfigReport, configreportAddDialog: configreportAddDialog, 
          //   // handlenextPage: handlenextReportConfigPage, handleprevPage: handleprevReportConfigPage 
          // } : undefined, 
          // importxlsfields: (isadminApp) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          // exportxlsfields: isadminApp ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          // pdf: isadminApp ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          // initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbNext, ondbPrev, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: false, //isadminApp && appid === enummapps.utilizatoriapp.key, 
              visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true, visiblenexprev: true}}}
      />
      {/* {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: 'Mngmentoperations', wsnames: wsnames}} headers={headers} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.portrait.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      } */}
    </Styles>
  )
}
