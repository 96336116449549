import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddsMutation($input: FnExtrascontintomngmentInput!)
{
  fnExtrascontintomngment(input: $input) {
    boolean
  }
}
`
