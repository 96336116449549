
export default [
    [
        {field: 'therapistid', value: 'therapistid'},
        {field: 'firstname', value: 'firstname'},
        {field: 'lastname', value: 'lastname'},
        {field: 'extensive', value: 'extensive'},
        {field: 'symbol', value: 'symbol'},
        {field: 'code', value: 'code'},
        {field: 'phone', value: 'phone'},
        {field: 'email', value: 'email'},
        {field: 'cnp', value: 'cnp'},
        {field: 'percent', value: 'percent'},
        {field: 'calendarcss', value: 'calendarcss'},
        {field: 'activ', value: 'activ'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

