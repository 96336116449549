import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelextrascontcodificareInput!)
{
  fnDelextrascontcodificare(input: $input) {
    extrascontcodificare {
      collectionid
      extrascontcodificareid
      key
      operationcodeid
    }
  }
}
`
