/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type queryQueryVariables = {|
  pUtilizatorid: any,
  pRefresh: boolean,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $pUtilizatorid: BigInt!
) {
  ...page_dbdata_3yoqXG
}

fragment page_dbdata_3yoqXG on Query {
  vutilizatorpivotrols(condition: {utilizatorid: $pUtilizatorid}) {
    edges {
      node {
        utilizatorid
        utilizator
        username
        email
        cnp
        telefon
        datainceput
        datasfarsit
        activ
        parola
        app
        appid
        adminapp
        contabil
        developer
        liber
        responsabilutilizatori
        modelare
        pacient
        responsabilbusiness
        responsabilmanagement
        superadmin
        terapeut
        tester
      }
    }
  }
  vapps {
    edges {
      node {
        appid
        denumire
        simbol
        link
        ordonare
      }
    }
  }
  vrols {
    edges {
      node {
        rolid
        denumire
        simbol
        observatie
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pUtilizatorid"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "denumire",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "simbol",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          },
          {
            "kind": "Variable",
            "name": "pUtilizatorid",
            "variableName": "pUtilizatorid"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "utilizatorid",
                "variableName": "pUtilizatorid"
              }
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "VutilizatorpivotrolsConnection",
        "kind": "LinkedField",
        "name": "vutilizatorpivotrols",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorpivotrolsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatorpivotrol",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizatorid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "telefon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datainceput",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datasfarsit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parola",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "app",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminapp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contabil",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "developer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "liber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responsabilutilizatori",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modelare",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pacient",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responsabilbusiness",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responsabilmanagement",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "superadmin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "terapeut",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tester",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VappsConnection",
        "kind": "LinkedField",
        "name": "vapps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VappsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vapp",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ordonare",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VrolsConnection",
        "kind": "LinkedField",
        "name": "vrols",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VrolsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vrol",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolid",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "observatie",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a85d275af38967e90329ad56249470dd",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $pUtilizatorid: BigInt!\n) {\n  ...page_dbdata_3yoqXG\n}\n\nfragment page_dbdata_3yoqXG on Query {\n  vutilizatorpivotrols(condition: {utilizatorid: $pUtilizatorid}) {\n    edges {\n      node {\n        utilizatorid\n        utilizator\n        username\n        email\n        cnp\n        telefon\n        datainceput\n        datasfarsit\n        activ\n        parola\n        app\n        appid\n        adminapp\n        contabil\n        developer\n        liber\n        responsabilutilizatori\n        modelare\n        pacient\n        responsabilbusiness\n        responsabilmanagement\n        superadmin\n        terapeut\n        tester\n      }\n    }\n  }\n  vapps {\n    edges {\n      node {\n        appid\n        denumire\n        simbol\n        link\n        ordonare\n      }\n    }\n  }\n  vrols {\n    edges {\n      node {\n        rolid\n        denumire\n        simbol\n        observatie\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7346590f2cc95b4f7cc659f92b9ea39';

module.exports = node;
