import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import collections from '../../../enums/collections'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vsubscriptions === undefined ? null : dbdata.vsubscriptions.edges
  .map(edge => {
    return{
      ...edge.node, 
      therapist: `${edge.node.firstname !== null ? edge.node.firstname : '__'} ${edge.node.lastname !== null ? edge.node.lastname : ''} ${edge.node.extensive !== null ? edge.node.extensive : ''}`,
      patient: `${edge.node.firstnamepatient} ${edge.node.lastnamepatient} ${edge.node.extensivepatient !== null ? edge.node.extensivepatient : ''}`,
      therapyprice: `${edge.node.nametherapyprice}, ${edge.node.time}, ${edge.node.value}RON [${edge.node.therapypriceid}]`,
      }
  })
  // console.log(`[dbSubscriptions.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
    ...dbdata.vpatients.edges.map(edge => {
      const {patientid, firstname, lastname, extensive, } = edge.node
      return {id: 'patientid', patientid, patient: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}`}
  })
]
// console.log(`[dbSubscriptions.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
const dbOptions1 = [
  ...dbdata.vtherapists.edges.map(edge => {
    const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
    return {id: 'therapistid', therapistid, therapist: `${firstname} ${lastname} ${extensive !==  null ? extensive : ''}[${symbol}, ${code}]`}
})
]
// console.log(`[dbTherapysessions.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
// const dbOptions2 = [
//   ...dbdata.vtherapyprices.edges.map(edge => {
//     const {therapypriceid, name, time, price, } = edge.node
//     return {id: 'therapypriceid', therapypriceid, therapyprice: `${name}, ${time}, ${price}RON [${therapypriceid}]`}
// })
// ]
// // console.log(`[dbTherapysessions.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
const dbOptions2 = [
  ...dbdata.vtherapisttherapyprices.edges
  .filter(edge => edge.node.therapytypeid === 2 || edge.node.therapytypeid === 3)
  .map(edge => {
    const {therapisttherapypriceid, nametherapyprice, nameRotherapy, time, price, firstname, lastname, symbol, percent, therapypriceid, } = edge.node
    return {id: 'therapisttherapypriceid', therapisttherapypriceid, therapyprice: `${lastname}, ${firstname}, ${symbol}, ${nametherapyprice}, ${price}RON, ${time}, ${nameRotherapy}, ${percent}% [${therapypriceid}]`, }  //
})
]
// console.log(`[dbTherapysessions.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )
const dbOptions3 = [
  ...dbdata.vcollections.edges
    .filter(edge => parseInt(edge.node.collectionid) !== collections.decont.key)
    .map(edge => {
      const {collectionid, nameRo, } = edge.node
      return {id: 'collectionid', collectionid, nameRocollection: `${nameRo}`}
})
]
// console.log(`[dbSubscriptions.page] - dbOptions3: ${JSON.stringify(dbOptions3)}` )
const dbOptions4 = [
  ...dbdata.vhours.edges.map(edge => {
    const {hourid, name, symbol, } = edge.node
    return {id: 'fromid', fromid: hourid, fromhour: `${name} ${symbol !== null ? `[${symbol}]` : ''}`}
})
]
// console.log(`[dbTherapysessions.page] - dbOptions4: ${JSON.stringify(dbOptions4)}` )

const dbOptions5 = [
  ...dbdata.vsubscriptionmodes.edges.map(edge => {
    const {subscriptionmodeid, name, } = edge.node
    return {id: 'subscriptionmodeid', subscriptionmodeid: subscriptionmodeid, namesubscriptionmodes: `${name} : ''}`}
})
]
// console.log(`[dbTherapysessions.page] - dbOptions5: ${JSON.stringify(dbOptions5)}` )

  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4, dbOptions5, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VsubscriptionCondition, defaultValue: {}, }
    ) 
    {
      vsubscriptions(condition: $condition) {
        edges {
          node {
            subscriptionid
            therapysessionid
            date
            patientid
            firstnamepatient
            lastnamepatient
            extensivepatient
            birthyearpatient
            weightpatient
            heightpatient
            phonepatient
            emailpatient
            cnppatient
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percenttherapists
            therapypriceid
            nametherapyprice
            therapyid
            nameRotherapy
            nameEntherapy
            symboltherapy
            nordertherapy
            descriptiontherapy
            therapy2Id
            nameRo2Therapy
            nameEn2Therapy
            symbol2Therapy
            norder2Therapy
            description2Therapy
            therapytypeid
            nameRotherapytype
            nameEntherapytype
            symboltherapytype
            ntherapysessiontherapytype
            ntherapysession1Therapytype
            fromid
            fromhour
            symbolfromhour
            toid
            tohour
            symboltohour
            timeid
            symboltime
            minutestime
            unitstime
            time2Id
            symboltime2
            minutestime2
            unitstime2
            time
            name
            collectionid
            nameRocollection
            nameEncollection
            symbolcollection
            ntherapysession
            ntherapysession1
            mofntherapysession
            nbmonths
            finalizat
            abonamentfinalizat
            value
            subscriptionmodeid
            namesubscriptionmodes
            description
            subscriptioncount
            subscriptionnotfull
            nsubscriptiontherapysession
          }
        }
      }
      vpatients { #(orderBy: PATIENTID_ASC)
        edges {
          node {
            patientid
            firstname
            lastname
            extensive
            # birthyear
            # weight
            # height
            # phone
            # email
            # cnp
          }
        }
      }
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            # phone
            # email
            # cnp
            # percent
          }
        }
      } 
      # vtherapyprices(orderBy: THERAPYPRICEID_ASC) {
      #   edges {
      #     node {
      #       therapypriceid
      #       # norder
      #       # therapyid
      #       # nameRotherapy
      #       # nameEntherapy
      #       # symboltherapy
      #       # nordertherapy
      #       # descriptiontherapy
      #       # therapy2Id
      #       # nameRo2Therapy
      #       # nameEn2Therapy
      #       # symbol2Therapy
      #       # norder2Therapy
      #       # description2Therapy
      #       # therapytypeid
      #       # nameRotherapytype
      #       # nameEntherapytype
      #       # symboltherapytype
      #       # ntherapysessiontherapytype
      #       # ntherapysession1Therapytype
      #       # timeid
      #       # symboltime
      #       # minutestime
      #       # unitstime
      #       # time2Id
      #       # symboltime2
      #       # minutestime2
      #       # unitstime2
      #       time
      #       price
      #       name
      #       # description          
      #     }
      #   }
      # }
      vtherapisttherapyprices(condition: {activtherapists: true }) {
        edges {
          node {
            therapisttherapypriceid
            # therapistid
            firstname
            lastname
            # extensive
            symbol
            # code
            # phone
            # email
            # cnp
            # percenttherapists
            therapypriceid
            # therapyid
            nameRotherapy
            # therapy2Id
            therapytypeid
            # timeid
            # symboltime
            # minutestime
            # unitstime
            # time2Id
            time
            price
            nametherapyprice
            # descriptiontherapyprices
            # nordertherapyprices
            percent
          }
        }
      }      

      vcollections(orderBy: COLLECTIONID_ASC) {
        edges {
          node {
            collectionid
            nameRo
            # nameEn
            # symbol
          }
        }
      }
      vhours(orderBy: HOURID_ASC) {
        edges {
          node {
            hourid
            name
            symbol
          }
        }
      }
      vsubscriptionmodes {
        edges {
          node {
            subscriptionmodeid
            name
            # symbol
          }
        }
      }

    } 
    `
}
export default Relay.createFragmentContainer(Page, fragments)

