import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyearmng: selyear, selmonthmng: selmonth, } = params

  const dbvdata = dbdata.vfrincasaris === undefined || dbdata.vfrincasaris.edges.length === 0 ? 
    [{selyear, selyearid: '', selmonth: months[parseInt(selmonth)], selmonthid: '',  }]
    : dbdata.vfrincasaris.edges.map((edge, index) => {
      return{
        ...edge.node, //date: edge.node.nameoperationcode === 'Sold final' ? edge.node.date : '',
        // data: edge.node.level === 0 ? `${edge.node.day}/${edge.node.month < 10 ? `0${edge.node.month}` : edge.node.month}` 
          // : edge.node.level === 1 ? 'Total'
          // : edge.node.level === 2 ? 'T.incasari'
          // : edge.node.level === 3 ? 'T.plati'
          // : '',
        selyear: index === 0 ? selyear : '', selyearid: '', selmonth: index === 0 ? months[parseInt(selmonth)] : '', selmonthid: '',
        }
      })
      // .filter((item, index) => index === 1)
  // console.log(`[raportIncasariPivots.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  
  const dbOptions = [
    ...dbdata.vmngmentoperationyears.edges.map(edge => {
      const {year, } = edge.node
      return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  })
  ]
  // console.log(`[raportIncasariPivots.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.vmngmentoperationmonths.edges.map(edge => {
      const { month, monthname, } = edge.node
      return {id: 'selmonthid', selmonthid: month, selmonth: monthname}
  })
  ]
  // console.log(`[raportIncasariPivots.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vmonthkindofs.edges.map(edge => {
      const { monthkindofid, name, } = edge.node
      return {id: 'monthkindofid', monthkindofid: monthkindofid, namemonthkindof: name}
  })
  ]
  // console.log(`[raportIncasariPivots.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1, dbOptions2, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        pMonth: {type: Int, defaultValue: 1, }
        pYear: {type: Int, defaultValue: 2021, }
        # condition: {type: VrincasariCondition, defaultValue: {}, }
        conditionyear: {type: VmngmentoperationmonthCondition, defaultValue: {}, }
    ) 
    {
      vfrincasaris(pMonth: $pMonth, pYear: $pYear) {
        edges {
          node {
            month
            year
            monthname
            code
            symbol
            collection
            percent
            nbr
            collectionid
            zi01
            zi02
            zi03
            zi04
            zi05
            zi06
            zi07
            zi08
            zi09
            zi10
            zi11
            zi12
            zi13
            zi14
            zi15
            zi16
            zi17
            zi18
            zi19
            zi20
            zi21
            zi22
            zi23
            zi24
            zi25
            zi26
            zi27
            zi28
            zi29
            zi30
            zi31
            pzi01
            pzi02
            pzi03
            pzi04
            pzi05
            pzi06
            pzi07
            pzi08
            pzi09
            pzi10
            pzi11
            pzi12
            pzi13
            pzi14
            pzi15
            pzi16
            pzi17
            pzi18
            pzi19
            pzi20
            pzi21
            pzi22
            pzi23
            pzi24
            pzi25
            pzi26
            pzi27
            pzi28
            pzi29
            pzi30
            pzi31
            total
            totalcode
            totaltherapist
            totaltherapistcode
          }
        }
      }
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vmngmentoperationmonths(condition: $conditionyear, orderBy: MONTH_DESC) {
        edges {
          node {
            month
            year
            monthname
            shortmonthname          }
        }
      }
      vmonthkindofs(orderBy: MONTHKINDOFID_ASC) {
        edges {
          node {
            monthkindofid
            name
            # symbol
          }
        }
      }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

