/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpstherapistcollectionInput = {|
  clientMutationId?: ?string,
  pTherapistcollectionid: number,
  pTherapistid: number,
  pCollectionid: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpstherapistcollectionInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpstherapistcollection: ?{|
    +therapistcollection: ?{|
      +collectionid: number,
      +therapistcollectionid: any,
      +therapistid: number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpstherapistcollectionInput!
) {
  fnUpstherapistcollection(input: $input) {
    therapistcollection {
      collectionid
      therapistcollectionid
      therapistid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpstherapistcollectionPayload",
    "kind": "LinkedField",
    "name": "fnUpstherapistcollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapistcollection",
        "kind": "LinkedField",
        "name": "therapistcollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistcollectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e159f6132bf14838840f76a877d7d59",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpstherapistcollectionInput!\n) {\n  fnUpstherapistcollection(input: $input) {\n    therapistcollection {\n      collectionid\n      therapistcollectionid\n      therapistid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b6ff11295f70e9cb9233599c0c64701';

module.exports = node;
