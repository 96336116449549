import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelapprolutilizatorInput!)
{
  fnDelapprolutilizator(input: $input) {
    approlutilizator {
      appid
      approlutilizatorid
      rolid
      utilizatorid
    }
  }
}
`
