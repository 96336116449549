
// pagina principala:  grid_template_rows (heights, width)
export default {
  widthpage: '99.25%',
  // heights  
  heightheader: "5.6rem",  
  heightpdf: "750.0rem",  
  // widthpdf: "950.0rem",  
  widthpdf: "1500.0rem",  
  // heightcontent: "350.0rem", 
  heightfooter: "2.5rem",   
}