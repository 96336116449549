import React from 'react'
import { Page, StyleSheet, } from '@react-pdf/renderer'
import pdforientation from '../../enums/pdforientation'
import RaportAntet from './pdflib/pdfAntet'
import RaportTitle from './pdflib/pdfTitle'
import RaportTable from './pdflib/pdfTable'
// import RaportTable from './pdflib/pdfFormulareComunicareTable'
import RaportFooter from './pdflib/pdfFooter'


export default ({data, data0, params}) => {
  // console.log(`[pdf.pdfpage] - params: `)
  // console.log(params)
  // console.log(`[pdf.pdfpage] - params: ${JSON.stringify(params)}`)
  // console.log(`[pdf.pdfpage] - data: ${JSON.stringify(data)}`)
  const {pageSizes, index} = params
  const{size="A4", orientation=pdforientation.portrait.value, } = pageSizes !== undefined ? pageSizes[index] : {}

  return (
        <Page size={size} orientation = {orientation} style={styles.body}>

          <RaportAntet key = {Math.random()} params = {params}  />
          <RaportTitle key = {Math.random()} params = {params}  />
          <RaportTable  key = {Math.random()} params = {params}  data ={{raportitems: data, raportitems0: data0}} />
          <RaportFooter  key = {Math.random()} params = {params} data ={data} />
        </Page>
  )}
  
//  Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
//   })

  // https://github.com/diegomura/react-pdf/issues/402
//  fontFamily:  'Courier',
//  fontFamily:  'Courier-Bold',
//  fontFamily:  'Courier-Oblique',
//  fontFamily:  'Helvetica',
//  fontFamily:  'Helvetica-Bold',
//  fontFamily:  'Helvetica-Oblique',
//  fontFamily: 'Times-Roman',
//  fontFamily: 'Times-Bold',
//  fontFamily: 'Times-Italic',
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: 'Times-Roman',
      fontSize: 12,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      // fontFamily: 'Oswald'
    },
    text: {
      margin: 4,
      fontSize: 12,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
  })

