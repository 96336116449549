import React from 'react'

export default [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'rooms',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30, type: 'text',},
        { id: 'birthyear', Header: 'birthyear', accessor: 'birthyear', width: 30, type: 'number',},
        { id: 'weight', Header: 'weight', accessor: 'weight', width: 30, type: 'number',},
        { id: 'height', Header: 'height', accessor: 'height', width: 30, type: 'number',},
        { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        { id: 'cnp', Header: 'ID', accessor: 'cnp', width: 30, type: 'text',},
        { id: 'dataadaugare', Header: 'data add', accessor: 'dataadaugare', width: 15, type: 'text',},
        { id: 'thereistherapistpatient', Header: 'exista terapeut asociat?', accessor: 'thereistherapistpatient', width: 30, radiobuttondanu: true, type: '', align: 'left',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'patientid', Header: 'patientid', accessor: 'patientid', width: 30,  },
  ],
},
]
