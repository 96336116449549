import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelutilizatorInput!)
{
    fnDelutilizator(input: $input) {
        utilizator {
          activ
          cnp
          email
          parola
          telefon
          username
          utilizator
          utilizatorid
        }
    }
}
`
