
export default ({therapistid, dbOptions, dbOptions1, }) => ({
  AddDialogtitle: ['Adauga mod incasare terapeut', ''],
    columns: [
      {header: 'firstname', accessor: 'firstname', accessorid: 'therapistid', type: '', show: therapistid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'nameRo', accessor: 'nameRo', accessorid: 'collectionid', type: '', show: true, 
        autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege mod incasare terapeut ..',}},
    ],
    initialdata: {
      therapistid: '',
      collectionid: '',
      subRows: undefined,
    },    
  })

// 
// 
