import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>abonamente, campurile atribut fiind: <b>subscriptionId, patientId, date, therapypriceId, therapyId, therapy2Id, therapytypeId, timeid, time2id, time, price, name, ntherapysession, ntherapysession1, mofntherapysession, nbmonths, finalizat</b> </li>
            <ul>
                <li>abonament <b>pacient</b> pentru o anumita <b>terapie, pret</b>, incheiat la <b>date</b></li>
                <li><b>ntherapysession</b> - numar total de sendinte ce se vor efectua in <b>nbmonths</b> luni, din care <b>ntherapysession1</b> sendinte therapia 1 </li>
                <li><b>mofntherapysession</b> - numar sedinte efectuate</li>
            </ul>
            <li>pentru campurile <u>patientId, date</u> exista unicitate in tabela, campurile therapy2Id si time2is sunt optionale</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga abonament nou [<b>+</b>], actualizare oricare dintre campurile <i>patientId, therapypriceId, date, mofntherapysession, nbmonths, finalizat</i>, sterge abonament si refresh date </li>
                <li> pentru campurile <b>patientId si therapypriceId</b> se foloseste cate un autocomplete care se populeaza fiecare cu o lista de valori. </li>
            </ul>
            <li>este posibil export in fisier xls <b>xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.dbSubscriptions</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.subscriptions</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>subscriptionId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>patientId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>date</td>
                            <td className='td'>DATE</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapistId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapypriceId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>therapyId, therapy2Id, timeid, time2id, price</td>
                        </tr>
                        <tr>
                            <td className='td'>therapyId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapy2Id</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapytypeId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>abonament 5/10 sedinte</td>
                        </tr>
                        <tr>
                            <td className='td'>timeid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>time2id</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>time</td>
                            <td className='td'>VARCHAR</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>durata sedinta</td>
                        </tr>
                        <tr>
                            <td className='td'>price</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>pret abonament</td>
                        </tr>
                        <tr>
                            <td className='td'>collectionId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>name</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>name therapyprice</td>
                        </tr>
                        <tr>
                            <td className='td'>ntherapysession</td>
                            <td className='td'>TEXT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>numar total de sendinte</td>
                        </tr>
                        <tr>
                            <td className='td'>ntherapysession1</td>
                            <td className='td'>TEXT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>din care numar sendinte therapy1</td>
                        </tr>
                        <tr>
                            <td className='td'>mofntherapysession</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>numar sedinte efectuate</td>
                        </tr>
                        <tr>
                            <td className='td'>nbmonths</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>numar de luni pentru finalizare abonament</td>
                        </tr>
                        <tr>
                            <td className='td'>finalizat</td>
                            <td className='td'>BOOLEAN</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vsubscriptions(orderBy: date DESC, lastname, firstname, condition: $condition) (subscriptionid, 
                                <div>patientId, firstnamepatient, lastnamepatient, extensivepatient, birthyearpatient, weightpatient, heightpatient, phonepatient, emailpatient, CNPpatient, </div>
                                <div>therapypriceid, nametherapyprice,</div>
                                <div>therapyId, name_rotherapy, name_entherapy, symboltherapy, nordertherapy, descriptiontherapy, </div>
                                <div>therapy2Id, name_ro2therapy, name_en2therapy, symbol2therapy, norder2therapy, description2therapy, </div>
                                <div>therapytypeId, name_rotherapytype, name_entherapytype, symboltherapytype, ntherapysessiontherapytype, ntherapysession1therapytype, </div>
                                <div>timeid, symboltime, minutestime, unitstime,</div>
                                <div>time2id, symboltime2, minutestime2, unitstime2, </div>
                                <div>time, price, name, </div>
                                <div>, name_rocollection, name_encollection, symbolcollection, </div>
                                <div>date, ntherapysession, ntherapysession1, mofntherapysession, nbmonths, finalizat</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: patientid !== undefined ? &#x0007B; patientid: parseInt(patientid) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vpatients(orderBy: PATIENTID_ASC) (patientid, firstname, lastname, extensive)</td>
                            <td className='td'>lista de valori [patientid, firstname, lastname, extensive]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapyprices(orderBy: THERAPYPRICEID_ASC) (therapypriceid, time, price, name)</td>
                            <td className='td'>lista de valori [therapypriceid, time, price, name ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addsubscription(p_patientId, p_therapypriceId, p_date, p_therapistid, p_mofntherapysession, p_nbmonths, p_finalizat)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_updsubscription(p_keyfield, p_value, p_subscriptionId)</td>
                            <td className='td'>finalizat = true : inregistrare in db.managementdetail incasarea, finalizat = false : rollback</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_delsubscription(p_subscriptionId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_upssubscription(p_subscriptionId, p_patientId, p_date, p_therapistid, p_ntherapysession, p_therapypriceId, p_therapyid, p_therapy2id, p_timeid, p_time2id, p_time, p_price, p_collectionid, p_name, p_ntherapysession, p_ntherapysession1, , p_mofntherapysession, p_nbmonths, p_finalizat)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


