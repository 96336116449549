
export default [
    {
        appid: {field: 'appid', values: ['', '', 'appid'], width: 5, isLastRow : false},
        denumire: {field: 'denumire', values: ['', '', 'denumire'], width: 20, isLastRow : false},
        simbol: {field: 'simbol', values: ['', '', 'simbol'], width: 15, isLastRow : false},
        link: {field: 'link', values: ['', '', 'link'], width: 40, isLastRow : false},
        ordonare: {field: 'ordonare', values: ['', '', 'ordonare'], width: 10, isLastRow : false},    
    }, 
]
