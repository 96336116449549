import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelmonthkindofInput!)
{
  fnDelmonthkindof(input: $input) {
    monthkindof {
      monthkindofid
      name
      symbol
    }
  }
}
`
