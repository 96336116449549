import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeltimeInput!)
{
  fnDeltime(input: $input) {
    dtime {
      minutes
      symbol
      timeid
      units
    }  }
}
`
