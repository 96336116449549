import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'

export default ({params, }) => {
  const {pdftitles, coddocument, } = params
  // daca pdftitles.isArray => scenariul cu titlu pe mai multe randuri 
  // console.log(`[uioscar.pdf.pdflib.pdfTitles] - pdftitles: ${JSON.stringify(pdftitles)}`)
  // console.log(`[uioscar.pdf.pdflib.pdfTitles] - coddocument: ${JSON.stringify(coddocument)}`)
  return (
    <>
        <View key={Math.random()} style={styles.titlestyle} fixed>
          {
            Array.isArray(pdftitles) ? pdftitles.map(item => <Text key={Math.random()} >{item.title} </Text>)
            : <Text key={Math.random()} >{pdftitles.title} </Text>
          }
        </View>
        { coddocument !== undefined ? <Text key={Math.random()} style={styles.coddocumentstyle}>{coddocument} </Text> : <></>}
    </>    
  )}
  
  const styles = StyleSheet.create({
    titlestyle: {
      fontSize: 18,
      // textAlign: 'left',
      textAlign: 'center',
      marginTop: 5,
      marginBottom: 2,
    },
    coddocumentstyle: {
      fontSize: 12,
      // textAlign: 'left',
      textAlign: 'right',
      marginTop: 1,
      marginBottom: 1,
    },
})

  