
import * as pdfstyles from '../../../../uioscar/pdf/pdflib/pdfstyles'

export default () => {   

    const headertableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '12',
    }
    const bodytableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '12',
    }

    return [
        {
            date: {field: 'date', values: ['Data'], width: 13, isLastRow : false, ...pdfstyles.styleLeft({headertableCell, bodytableCell, }), },
            // month: {field: 'month', values: ['month'], width: 4, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            // monthname: {field: 'monthname', values: ['monthname'], width: 4, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            shortmonthname: {field: 'shortmonthname', values: ['Luna'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            // year: {field: 'year', values: ['year'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            codeoperationcode: {field: 'codeoperationcode', values: ['Code'], width: 10, isLastRow : false, ...pdfstyles.styleAlignCenter({headertableCell, bodytableCell, }), },
            nameoperationcode: {field: 'nameoperationcode', values: ['Operatiune'], width: 57, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            // level: {field: 'level', values: ['level'], width: 4, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            // feloperationcodeid: {field: 'feloperationcodeid', values: ['feloperationcodeid'], width: 4, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            value: {field: 'value', values: ['Valoare'], width: 10, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
        }, 
    ]}
