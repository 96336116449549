
export default ({therapistid, dbOptions, dbOptions1, }) => ({
  AddDialogtitle: ['Adauga terapeut, tarepie pret', ''],
    columns: [
      {header: 'therapist', accessor: 'therapist', accessorid: 'therapistid', type: '', show: therapistid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'therapyprice', accessor: 'therapyprice', accessorid: 'therapypriceid', type: '', show: true,
        autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege terapie pret ..',}},
      {header: 'percent', accessor: 'percent', type: 'text', show: 'true', },
    ],
    initialdata: {
      // therapistid: '',
      therapist: '',
      therapyprice: '',
      percent: '',
      subRows: undefined,
    },    
  })
