
export default [
    {
        therapist: {field: 'therapist', values: ['', '', 'therapist'], width: 20, isLastRow : false},
        date: {field: 'date', values: ['', '', 'date'], width: 10, isLastRow : false},
        fromhour: {field: 'fromhour', values: ['', '', 'de la'], width: 10, isLastRow : false},
        tohour: {field: 'tohour', values: ['', '', 'pana la'], width: 10, isLastRow : false},
        patient: {field: 'patient', values: ['', '', 'patient'], width: 25, isLastRow : false},
        therapy: {field: 'therapy', values: ['', '', 'therapy'], width: 25, isLastRow : false},

        // therapysessionid: {field: 'therapysessionid', values: ['', '', 'therapysessionid'], width: 10, isLastRow : false},
        // therapistid: {field: 'therapistid', values: ['', '', 'therapistid'], width: 10, isLastRow : false},
        // therapypriceid: {field: 'therapypriceid', values: ['', '', 'therapypriceid'], width: 10, isLastRow : false},
        // therapyidtherapyprices: {field: 'therapyidtherapyprices', values: ['', '', 'therapyidtherapyprices'], width: 10, isLastRow : false},
        // therapyid: {field: 'therapyid', values: ['', '', 'therapyid'], width: 10, isLastRow : false},
        // nametherapyprice: {field: 'nametherapyprice', values: ['', '', 'nametherapyprice'], width: 10, isLastRow : false},
        // patientid: {field: 'patientid', values: ['', '', 'patientid'], width: 10, isLastRow : false},
        // patientid: {field: 'patient', values: ['', '', 'patient'], width: 10, isLastRow : false},
        // firstnamepatient: {field: 'firstnamepatient', values: ['', '', 'firstnamepatient'], width: 10, isLastRow : false},
        // lastnamepatient: {field: 'lastnamepatient', values: ['', '', 'lastnamepatient'], width: 10, isLastRow : false},
        // extensivepatient: {field: 'extensivepatient', values: ['', '', 'extensivepatient'], width: 10, isLastRow : false},
        // birthyearpatient: {field: 'birthyearpatient', values: ['', '', 'birthyearpatient'], width: 10, isLastRow : false},
        // weightpatient: {field: 'weightpatient', values: ['', '', 'weightpatient'], width: 10, isLastRow : false},
        // heightpatient: {field: 'heightpatient', values: ['', '', 'heightpatient'], width: 10, isLastRow : false},
        // phonepatient: {field: 'phonepatient', values: ['', '', 'phonepatient'], width: 10, isLastRow : false},
        // emailpatient: {field: 'emailpatient', values: ['', '', 'emailpatient'], width: 10, isLastRow : false},
        // cnppatient: {field: 'cnppatient', values: ['', '', 'cnppatient'], width: 10, isLastRow : false},
        // subscriptiondetailid: {field: 'subscriptiondetailid', values: ['', '', 'subscriptiondetailid'], width: 10, isLastRow : false},
        // fromid: {field: 'fromid', values: ['', '', 'fromid'], width: 10, isLastRow : false},
        // toid: {field: 'toid', values: ['', '', 'toid'], width: 10, isLastRow : false},
        // percent: {field: 'percent', values: ['', '', 'percent'], width: 10, isLastRow : false},
        // calendarid: {field: 'calendarid', values: ['', '', 'calendarid'], width: 10, isLastRow : false},
        // roomid: {field: 'roomid', values: ['', '', 'roomid'], width: 10, isLastRow : false},
        // patientnotes: {field: 'patientnotes', values: ['', '', 'patientnotes'], width: 10, isLastRow : false},
        // therapistnotes: {field: 'therapistnotes', values: ['', '', 'therapistnotes'], width: 10, isLastRow : false},
        // value: {field: 'value', values: ['', '', 'value'], width: 10, isLastRow : false},
        // collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
        // finalizat: {field: 'finalizat', finalizats: ['', '', 'value'], width: 10, isLastRow : false},

        // therapy2Id: {field: 'therapy2Id', values: ['', '', 'therapy2Id'], width: 10, isLastRow : false},
        // nameRotherapy: {field: 'nameRotherapy', values: ['', '', 'nameRotherapy'], width: 20, isLastRow : false},
        // nameEntherapy: {field: 'nameEntherapy', values: ['', '', 'nameEntherapy'], width: 20, isLastRow : false},
        // // nameRo2Therapy: {field: 'nameRo2Therapy', values: ['', '', 'nameRo2Therapy'], width: 20, isLastRow : false},
        // // nameEn2Therapy: {field: 'nameEn2Therapy', values: ['', '', 'nameEn2Therapy'], width: 20, isLastRow : false},
        // // symboltherapy: {field: 'symboltherapy', values: ['', '', 'symboltherapy'], width: 10, isLastRow : false},
        // // symbol2Therapy: {field: 'symbol2Therapy', values: ['', '', 'symbol2Therapy'], width: 10, isLastRow : false},
        // // norder: {field: 'norder', values: ['', '', 'norder'], width: 10, isLastRow : false},
        // // // norder2Therapy: {field: 'norder2Therapy', values: ['', '', 'norder2Therapy'], width: 10, isLastRow : false},
        // price: {field: 'price', values: ['', '', 'price'], width: 10, isLastRow : false},
        // // timeid: {field: 'timeid', values: ['', '', 'timeid'], width: 10, isLastRow : false},
        // // time2id: {field: 'time2id', values: ['', '', 'time2id'], width: 10, isLastRow : false},
        // time: {field: 'time', values: ['', '', 'time'], width: 10, isLastRow : false},
        // // therapytypeid: {field: 'therapytypeid', values: ['', '', 'therapytypeid'], width: 10, isLastRow : false},
        // // descriptiontherapy: {field: 'descriptiontherapy', values: ['', '', 'descriptiontherapy'], width: 10, isLastRow : false},
        // // name: {field: 'name', values: ['', '', 'name'], width: 20, isLastRow : false},
        // // nordertherapy: {field: 'nordertherapy', values: ['', '', 'nordertherapy'], width: 20, isLastRow : false},
        // // description: {field: 'description', values: ['', '', 'description'], width: 20, isLastRow : false},
        // // description2Therapy: {field: 'description2Therapy', values: ['', '', 'description2Therapy'], width: 20, isLastRow : false},
    }, 
]
