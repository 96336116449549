import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDeloperationcodeInput!)
{
  fnDeloperationcode(input: $input) {
    operationcode {
      code
      name
      norder
      operationcodeid
      operationkindofid
      symbol
    }
  }
}
`
