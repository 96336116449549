import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstimeInput!)
{
  fnUpstime(input: $input) {
    dtime {
      minutes
      symbol
      timeid
      units
    }  }
}
`
 