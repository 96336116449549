
export default {
    AddDialogtitle: ['Adauga rol', 'Adauga inregistrare in tabela Rolss.'],
    columns: [
      {header: 'denumire', accessor: 'denumire', type: 'text', show: 'true', },
      {header: 'simbol', accessor: 'simbol', type: 'text', show: 'true', },
      // {header: 'observatie', accessor: 'observatie', type: 'text', show: 'true', },
    ],
    initialdata: {
      denumire: '',
      simbol: '',
      // observatie: '',
      subRows: undefined,
    },    
  }
