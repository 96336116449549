
export default [
    {
        therapisttherapypriceid: {field: 'therapisttherapypriceid', values: ['', '', 'therapisttherapypriceid'], width: 10, isLastRow : false},
        therapistid: {field: 'therapistid', values: ['', '', 'therapistid'], width: 10, isLastRow : false},
        firstname: {field: 'firstname', values: ['', '', 'firstname'], width: 10, isLastRow : false},
        lastname: {field: 'lastname', values: ['', '', 'lastname'], width: 10, isLastRow : false},
        extensive: {field: 'extensive', values: ['', '', 'extensive'], width: 10, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        // code: {field: 'code', values: ['', '', 'code'], width: 10, isLastRow : false},
        // phone: {field: 'phone', values: ['', '', 'phone'], width: 10, isLastRow : false},
        // email: {field: 'email', values: ['', '', 'email'], width: 10, isLastRow : false},
        // cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
        // percenttherapists: {field: 'percenttherapists', values: ['', '', 'percenttherapists'], width: 10, isLastRow : false},
        therapypriceid: {field: 'therapypriceid', values: ['', '', 'therapypriceid'], width: 10, isLastRow : false},
        therapyid: {field: 'therapyid', values: ['', '', 'therapyid'], width: 10, isLastRow : false},
        therapy2Id: {field: 'therapy2Id', values: ['', '', 'therapy2Id'], width: 10, isLastRow : false},
        price: {field: 'price', values: ['', '', 'price'], width: 10, isLastRow : false},
        // therapytypeid: {field: 'therapytypeid', values: ['', '', 'therapytypeid'], width: 10, isLastRow : false},
        // timeid: {field: 'timeid', values: ['', '', 'timeid'], width: 10, isLastRow : false},
        // time2Id: {field: 'time2Id', values: ['', '', 'time2Id'], width: 10, isLastRow : false},
        // time: {field: 'time', values: ['', '', 'time'], width: 10, isLastRow : false},
        // percent: {field: 'percent', values: ['', '', 'percent'], width: 10, isLastRow : false},
        // nametherapyprice: {field: 'nametherapyprice', values: ['', '', 'nametherapyprice'], width: 10, isLastRow : false},
        // descriptiontherapyprices: {field: 'descriptiontherapyprices', values: ['', '', 'descriptiontherapyprices'], width: 10, isLastRow : false},
        // nordertherapyprices: {field: 'nordertherapyprices', values: ['', '', 'nordertherapyprices'], width: 10, isLastRow : false},
    }, 
]
