// import React from 'react'

export default ({dbOptions, dbOptions3, dbOptions5, }) => ([
  {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', width: 7, // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter to build the expander.
      <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    ), 
  },      
  {
    Header: 'session',
    columns: [
      // { id: 'nametherapyprice', Header: 'nametherapyprice', accessor: 'nametherapyprice', width: 30, type: 'text'},
      { id: 'patient', Header: 'pacient', accessor: 'patient', width: 35, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '25rem', text: 'Alege pacient ..'},},
      { id: 'therapist', Header: 'terapeut', accessor: 'therapist', width: 30, type: 'text', },
          // autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '25rem', text: 'Alege terapeut ..'},},
      { id: 'therapyprice', Header: 'terapie, pret', accessor: 'therapyprice', width: 100, type: 'text',},
          // autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '25rem', text: 'Alege abonament ..'},},
      { id: 'date', Header: 'data', accessor: 'date', width: 25, type: 'date', align: 'right',},
      { id: 'fromhour', Header: 'De la', accessor: 'fromhour', width: 15, type: 'text', align: 'right',},
      // { id: 'symbolfromhour', Header: 'De la', accessor: 'symbolfromhour', width: 15, type: 'text', align: 'right',},
      { id: 'tohour', Header: 'Pana la', accessor: 'tohour', width: 15, type: 'text', align: 'right',},
      // { id: 'symboltohour', Header: 'De la', accessor: 'symboltohour', width: 15, type: 'text', align: 'right',},
      { id: 'time', Header: 'Durata', accessor: 'time', width: 15, type: 'text', align: 'right',},
      { id: 'ntherapysession', Header: 'numar terapii', accessor: 'ntherapysession', width: 30, type: 'number',align: 'right', },
      { id: 'ntherapysession1', Header: 'numar terapii 1', accessor: 'ntherapysession1', width: 30, type: 'number',align: 'right', },
      { id: 'mofntherapysession', Header: 'numar sedinte efectuate', accessor: 'mofntherapysession', width: 35, type: 'text', align: 'left', },
      { id: 'nbmonths', Header: 'numar luni abonament', accessor: 'nbmonths', width: 30, type: 'text', align: 'left', },
      { id: 'value', Header: 'value', accessor: 'value', width: 30, type: 'number', align: 'right', },
      { id: 'nameRocollection', Header: 'fel incasare', accessor: 'nameRocollection', width: 20, type: 'text', align: 'right',
            autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},       
      // { id: 'namesubscriptionmodes', Header: 'mod abonament', accessor: 'namesubscriptionmodes', width: 30, type: 'text', align: 'left', },
      { id: 'description', Header: 'descriere', accessor: 'description', width: 70, type: 'text', align: 'left', },
      { id: 'finalizat', Header: 'finalizat', accessor: 'finalizat', width: 30, radiobuttondanu: true, type: '', align: 'left',},
    ]   
  },
  {
    Header: 'abonament',
    columns: [
      { id: 'namesubscriptionmodes', Header: 'fel abonament', accessor: 'namesubscriptionmodes', width: 100, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions5, widthautocomplete: '25rem', text: 'Alege fel abonament ..'},},
      { id: 'subscriptioncount', Header: 'sedinte planificate', accessor: 'subscriptioncount', width: 50, type: 'number', align: 'right',},
      // { id: 'nsubscriptiontherapysession', Header: 'numar sedinte abonament', accessor: 'nsubscriptiontherapysession', width: 30, type: 'number', align: 'right',},
      { id: 'subscriptionnotfull', Header: 'exista sedinte neplanificate', accessor: 'subscriptionnotfull', width: 30, type: 'number', radiobuttondanu: true, align: 'right',},
      // { id: 'nameEncollection', Header: 'nameEncollection', accessor: 'nameEncollection', width: 30, type: 'text', align: 'right',},
      // { id: 'symbolcollection', Header: 'symbolcollection', accessor: 'symbolcollection', width: 30, type: 'text',},
      { id: 'abonamentfinalizat', Header: 'abonament finalizat', accessor: 'abonamentfinalizat', width: 50, radiobuttondanu: true, type: '', align: 'left',},
    ]   
  },
  {
    Header: 'patient',
    columns: [
      { id: 'firstnamepatient', Header: 'firstnamepatient', accessor: 'firstnamepatient', width: 30, type: 'text',},
      { id: 'lastnamepatient', Header: 'lastnamepatient', accessor: 'lastnamepatient', width: 30, type: 'text',},
      { id: 'extensivepatient', Header: 'extensivepatient', accessor: 'extensivepatient', width: 30, type: 'text',},
      { id: 'birthyearpatient', Header: 'birthyearpatient', accessor: 'birthyearpatient', width: 30, type: 'text',},
      { id: 'weightpatient', Header: 'weightpatient', accessor: 'weightpatient', width: 30, type: 'text',},
      { id: 'heightpatient', Header: 'heightpatient', accessor: 'heightpatient', width: 30, type: 'text',},
      { id: 'phonepatient', Header: 'phonepatient', accessor: 'phonepatient', width: 30, type: 'text',},
      { id: 'emailpatient', Header: 'emailpatient', accessor: 'emailpatient', width: 30, type: 'text',},
      { id: 'cnppatient', Header: 'cnppatient', accessor: 'cnppatient', width: 30, type: 'text',},
      ]
  },
  {
    Header: 'therapist',
    columns: [
      { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',},
      { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
      { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30,  },
      { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
      { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
      { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
      { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
      { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
      { id: 'percenttherapists', Header: 'percenttherapists', accessor: 'percenttherapists', width: 30, type: 'number',},
]
  },
{
    Header: 'therapy 1',
    columns: [
      { id: 'nameRotherapy', Header: 'nameRotherapy', accessor: 'nameRotherapy', width: 30, type: 'text', align: 'right',},
      { id: 'nameEntherapy', Header: 'nameEntherapy', accessor: 'nameEntherapy', width: 30, type: 'text', align: 'right',},
      { id: 'symboltherapy', Header: 'symboltherapy', accessor: 'symboltherapy', width: 30, type: 'text',},
      { id: 'nordertherapy', Header: 'nordertherapy', accessor: 'nordertherapy', width: 30,  },
      { id: 'descriptiontherapy', Header: 'descriptiontherapy', accessor: 'descriptiontherapy', width: 30, type: 'text',},
    ]
  },
  {
    Header: 'therapy 2',
    columns: [
      { id: 'nameRo2Therapy', Header: 'nameRo2Therapy', accessor: 'nameRo2Therapy', width: 30, type: 'text',},
      { id: 'nameEn2Therapy', Header: 'nameEn2Therapy', accessor: 'nameEn2Therapy', width: 30, type: 'text',},
      { id: 'symbol2Therapy', Header: 'symbol2Therapy', accessor: 'symbol2Therapy', width: 30, type: 'text',},
      { id: 'norder2Therapy', Header: 'norder2Therapy', accessor: 'norder2Therapy', width: 30, type: 'text',},
      { id: 'description2Therapy', Header: 'description2Therapy', accessor: 'description2Therapy', width: 30, type: 'text',},
      ]
  },
  {
    Header: 'time 1',
    columns: [
      { id: 'symboltime', Header: 'symboltime', accessor: 'symboltime', width: 30, type: '', align: 'right',},
      { id: 'minutestime', Header: 'minutestime', accessor: 'minutestime', width: 30, type: 'text', align: 'right',},
      { id: 'unitstime', Header: 'unitstime', accessor: 'unitstime', width: 30, type: 'text', align: 'right',},
      ]
  },
  {
    Header: 'time 2',
    columns: [
      { id: 'symboltime2', Header: 'symboltime2', accessor: 'symboltime2', width: 30, type: '', align: 'right',},
      { id: 'minutestime2', Header: 'minutestime2', accessor: 'minutestime2', width: 30, type: 'text', align: 'right',},
      { id: 'unitstime2', Header: 'unitstime2', accessor: 'unitstime2', width: 30, type: 'text', align: 'right',},
      ]
  },
  {
    Header: 'therapytype',
    columns: [
      { id: 'nameRotherapytype', Header: 'nameRotherapytype', accessor: 'nameRotherapytype', width: 30, type: 'text',},
      { id: 'nameEntherapytype', Header: 'nameEntherapytype', accessor: 'nameEntherapytype', width: 30, type: 'text',},
      { id: 'symboltherapytype', Header: 'symboltherapytype', accessor: 'symboltherapytype', width: 30, type: 'text',},
      { id: 'ntherapysessiontherapytype', Header: 'ntherapysessiontherapytype', accessor: 'ntherapysessiontherapytype', width: 30, type: 'text', align: 'right',},
      { id: 'ntherapysession1Therapytype', Header: 'ntherapysession1Therapytype', accessor: 'ntherapysession1Therapytype', width: 30, type: 'text', align: 'right',},
      ]
  },
  {
    Header: 'db info', columns: [     
    { id: 'subscriptionid', Header: 'subscriptionid', accessor: 'subscriptionid', width: 30, type: 'number', align: 'right',},
    { id: 'therapysessionid', Header: 'therapysessionid', accessor: 'therapysessionid', width: 30, type: 'number', align: 'right',},
    { id: 'patientid', Header: 'patientid', accessor: 'patientid', width: 30, type: 'number', align: 'right',},
    { id: 'therapypriceid', Header: 'therapypriceid', accessor: 'therapypriceid', width: 30, type: 'number', align: 'right',  },
    { id: 'therapyid', Header: 'therapyid', accessor: 'therapyid', width: 30, type: 'number', align: 'right',  },
    { id: 'therapy2Id', Header: 'therapy2Id', accessor: 'therapy2Id', width: 30, type: 'number', align: 'right',  },
    { id: 'therapytypeid', Header: 'therapytypeid', accessor: 'therapytypeid', width: 30, align: 'right',  },
  ],
  },
  {
    Header: 'db info 2', columns: [     
    { id: 'fromid', Header: 'fromid', accessor: 'fromid', width: 30, align: 'right',  },
    { id: 'toid', Header: 'toid', accessor: 'toid', width: 30, align: 'right',  },
    { id: 'timeid', Header: 'timeid', accessor: 'timeid', width: 30, align: 'right',  },
    { id: 'time2Id', Header: 'time2Id', accessor: 'time2Id', width: 30, align: 'right',  },
    { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'number', align: 'right',  },
    { id: 'subscriptionmodeid', Header: 'subscriptionmodeid', accessor: 'subscriptionmodeid', width: 30, type: 'number', align: 'right',  },
  ],
  },
])


