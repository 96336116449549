import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddpatientInput!)
{
  fnAddpatient(input: $input) {
    patient {
      birthyear
      cnp
      email
      extensive
      firstname
      height
      lastname
      patientid
      phone
      weight
    }
  }
}
`
