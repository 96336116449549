
export default [
    [
        // {field: 'month', value: 'month'},
        // {field: 'monthname', value: 'monthname'},
        // {field: 'year', value: 'year'},
        {field: 'date', value: 'date'},
        // {field: 'houseregisterprevious', value: 'houseregisterprevious'},
        // {field: 'houseregistercurrent', value: 'houseregistercurrent'},
        {field: 'ndocument', value: 'ndocument'},
        // {field: 'codeoperationcode', value: 'codeoperationcode'},
        {field: 'nameoperationcode', value: 'nameoperationcode'},
        {field: 'debit', value: 'debit'},
        {field: 'credit', value: 'credit'},
        {field: 'sold', value: 'sold'},
        // {field: 'norderoperationcode', value: 'norderoperationcode'},
        // {field: 'operationkindofid', value: 'operationkindofid'},
        // {field: 'nameoperationkindof', value: 'nameoperationkindof'},
        // {field: 'norderoperationkindof', value: 'norderoperationkindof'},
        // {field: 'norder', value: 'norder'},
        // {field: 'kindofid', value: 'kindofid'},
        // {field: 'namekindofs', value: 'namekindofs'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

