/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vtherapisttherapyprices: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapisttherapypriceid: ?any,
        +therapistid: ?number,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +symbol: ?string,
        +code: ?string,
        +phone: ?string,
        +email: ?string,
        +cnp: ?string,
        +percenttherapists: ?any,
        +therapypriceid: ?number,
        +therapyid: ?number,
        +therapy2Id: ?number,
        +therapytypeid: ?number,
        +timeid: ?number,
        +time2Id: ?number,
        +time: ?string,
        +price: ?number,
        +nametherapyprice: ?string,
        +descriptiontherapyprices: ?string,
        +nordertherapyprices: ?number,
        +percent: ?any,
      |}
    |}>
  |},
  +vtherapists: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapistid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +symbol: ?string,
        +code: ?string,
      |}
    |}>
  |},
  +vtherapyprices: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapypriceid: ?any,
        +nameRotherapy: ?string,
        +time: ?string,
        +price: ?number,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapypriceid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "condition"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPISTTHERAPYPRICEID_ASC"
        }
      ],
      "concreteType": "VtherapisttherapypricesConnection",
      "kind": "LinkedField",
      "name": "vtherapisttherapyprices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapisttherapypricesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapisttherapyprice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapisttherapypriceid",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cnp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percenttherapists",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapyid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapy2Id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapytypeid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time2Id",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nametherapyprice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "descriptiontherapyprices",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nordertherapyprices",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percent",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "activ": true
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPISTID_ASC"
        }
      ],
      "concreteType": "VtherapistsConnection",
      "kind": "LinkedField",
      "name": "vtherapists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapistsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapist",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPYPRICEID_ASC"
        }
      ],
      "concreteType": "VtherapypricesConnection",
      "kind": "LinkedField",
      "name": "vtherapyprices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapypricesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapyprice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRotherapy",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapyprices(orderBy:\"THERAPYPRICEID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3506a4340805bb0da0636449f91f252';

module.exports = node;
