import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'


export default ({params, events}) => {
    const {dbkey, toggleHelp, date, selyearmng: selyear, selmonthmng: selmonth, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    // {month: 12, date: "1-dec-2020"}
    const variables = { pRefresh: refresh, 
        condition: params.backup ? {}
        :{ year: parseInt(selyear), month: parseInt(selmonth.selmonthid),
            ...date !== undefined ? {date: parseInt(date)} : {},
        }, 
        conditionyear:{year: parseInt(selyear)} 
    }
    // console.log(`[importextrasconts.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[importextrasconts.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[importextrasconts.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh, }} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
