import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationSyncADMutation($input: DtSyncadutilizatorInput!)
{
  dtSyncadutilizator(input: $input) 
  {
    utilizator {
      activ
      cnp
      datainceput
      datasfarsit
      email
      parola
      telefon
      username
      utilizator
      utilizatorid
    }
  }
}
`
 