/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddoperationcodeInput = {|
  clientMutationId?: ?string,
  pOperationkindofid: number,
  pName: string,
  pSymbol: string,
  pCode: string,
  pNorder: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddoperationcodeInput
|};
export type mutationAddMutationResponse = {|
  +fnAddoperationcode: ?{|
    +operationcode: ?{|
      +code: ?string,
      +name: string,
      +norder: number,
      +operationcodeid: any,
      +operationkindofid: number,
      +symbol: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddoperationcodeInput!
) {
  fnAddoperationcode(input: $input) {
    operationcode {
      code
      name
      norder
      operationcodeid
      operationkindofid
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddoperationcodePayload",
    "kind": "LinkedField",
    "name": "fnAddoperationcode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Operationcode",
        "kind": "LinkedField",
        "name": "operationcode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationkindofid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e399c86f06c6a0ecee57e88b7916cb81",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddoperationcodeInput!\n) {\n  fnAddoperationcode(input: $input) {\n    operationcode {\n      code\n      name\n      norder\n      operationcodeid\n      operationkindofid\n      symbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5d49302035db168eb1fc4476224ecb6';

module.exports = node;
