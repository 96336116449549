import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelAllMutation($input: FnDelextrascontsInput!)
{
  fnDelextrasconts(input: $input) {
    boolean
  }
}
`
