/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddappInput = {|
  clientMutationId?: ?string,
  pDenumire: string,
  pSimbol: string,
  pLink: string,
  pOrdonare: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddappInput
|};
export type mutationAddMutationResponse = {|
  +fnAddapp: ?{|
    +app: ?{|
      +appid: any,
      +denumire: string,
      +link: ?string,
      +ordonare: number,
      +simbol: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddappInput!
) {
  fnAddapp(input: $input) {
    app {
      appid
      denumire
      link
      ordonare
      simbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddappPayload",
    "kind": "LinkedField",
    "name": "fnAddapp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "app",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "denumire",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "link",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordonare",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3b5c7e63be14991f182b3b624947976d",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddappInput!\n) {\n  fnAddapp(input: $input) {\n    app {\n      appid\n      denumire\n      link\n      ordonare\n      simbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0dfefb362d00c0b8c4cb513ad98ec51d';

module.exports = node;
