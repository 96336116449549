import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdmngmentoperationInput!)
{
  fnUpdmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
    }
  }
}
`

