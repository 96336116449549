import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery($pRefresh: Boolean!,
  $condition: VutilizatornemodelatCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(condition: $condition, pRefresh: $pRefresh) 
}
`

