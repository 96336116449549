import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdtherapistpatientInput!)
{
  fnUpdtherapistpatient(input: $input) {
    therapistpatient {
      therapistpatientid
      therapistid
      patientid
    }
  }
}
`

