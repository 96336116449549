
export default (therapistid) =>  [ therapistid === undefined ? 'firstname'  : '', 'nameRo', ]

// therapistcollectionid
// 
// firstname
// lastname
// extensive
// symbol
// code
// phone
// email
// cnp
// percenttherapists
// 
// nameRo
// nameEn
// symbolcollections
