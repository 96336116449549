import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import SubComponent from '../utilizatoridrepturi/component'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import UtilizatorModelats from '../utilizatorModelats/component'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import formatdate from '../../../uioscar/formatdate'
import groupcols from './groupcols'
import headers from './xls/headers'
import initiall from './initiall'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationAddDrepturi from './mutationAddDrepturi'
import mutationAddModelareUtilizatori from './mutationAddModelareUtilizatori'
import mutationAdds from './mutationAdds'
import mutationDel from './mutationDel'
import mutationSyncAD from './mutationSyncAD'
import mutationUpd from './mutationUpd'
import mutationUps from './mutationUps'
import nulldate from '../../../enums/nulldate'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import subcomponenttitle from './subcomponenttitle'
import subcomponenttitlemodelare from './subcomponenttitlemodelare'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'
import wsnamesyncADs from './syncAD/wsnames'

// import Pg from '../../../uioscar/pg'
// import Ora from '../../../uioscar/ora'











// import mutationpgfdw from './mutationpgfdw'































//import {stringify} from 'flatted'



export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, modelareUtilizatori=false} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // const [originalData] = React.useState(dbdata.dbvdata)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[utilizatori.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns, [])     
  const initialAddDialog = React.useMemo( () => initiall, []) 

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isresponsabilutilizatori = rols !== undefined ? (
    rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0) 
    : false

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[utilizatori.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[utilizatori.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {utilizatorid} = d.original
    //     // console.log(`[utilizatori.table.onrowSelected.map] - utilizatorid: ${utilizatorid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({utilizatorid})
    //   })
    // console.log(`[utilizatori.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
        {
          !modelareUtilizatori ?
            <Dialog key={`SubComponent`} 
              params = {{...params, ...subcomponenttitle(row.values.username), utilizatorid: row.values.utilizatorid, }} 
              events={{onCloseDialog, ...events}} subComponent={SubComponent} />
          : 
            <Dialog key={`UtilizatorModelats`} 
              params = {{...params, ...subcomponenttitlemodelare(row.values.username), utilizatorid: row.values.utilizatorid, }} 
              events={{onCloseDialog, ...events}} subComponent={UtilizatorModelats} />
          }
      </>
    ),
    [params, events, modelareUtilizatori]
  )

  const onError = errors => {
      // console.log(`[utilizatori.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[utilizatori.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
 // console.log(`[utilizatori.table] - format: ${format}`)
 
  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls, nsheets) => {
    // console.log(`[utilizatori.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[utilizatori.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, mutationAddDrepturi, mutationAddModelareUtilizatori] // list mutations pentru tratare sheets
    const mutationxlss2 = [mutationSyncAD,] // list mutations pentru tratare sheets
    // console.log(`[utilizatori.table.ondbExcellAdd] - nsheets: ${nsheets}, inputs: ${JSON.stringify(inputs)}`)
    sheettodb(inputs, nsheets===1 ? mutationxlss2: mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }
  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[utilizatori.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          return {input: {pUtilizatorid: parseInt(item.utilizatorid), pUtilizator: item.utilizator, pCnp: item.cnp.toString(), pUsername: item.username, pEmail: item.email, 
            pTelefon: (item.telefon!== undefined ? item.telefon : nullstring), // pdiffTimeZone: item.datainceput.getTimezoneOffset().toString(),
            pDatainceput: item.datainceput, pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
            // pDatainceput: item.datainceput.toLocaleDateString("ro-RO", {timeZone: "Europe/Istanbul"}), pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
            // pDatainceput: item.datainceput.toLocaleDateString("en-US", {timeZone: "Europe/Istanbul"}), pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
            pParola: item.parola.toString(), pActiv: item.activ},}
          })
      case wsnames[1]:
        // console.log(`[utilizatori.table.getinputs] - appid: ${appid}, enummapps.utilizatoriapp.key: ${enummapps.utilizatoriapp.key}, sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.filter(item => appid === enummapps.utilizatoriapp.key || appid === parseInt(item.appid)) // // microaplicatie utilizatori sau numai pentru enummapps = appid face schimb de date
          .map(item => { return {input: {pUtilizatorid: parseInt(item.utilizatorid), pAppid: parseInt(item.appid), pRolid: parseInt(item.rolid)},} })
      case wsnames[2]:
        // console.log(`[utilizatori.table.getinputs] - appid: ${appid}, enummapps.utilizatoriapp.key: ${enummapps.utilizatoriapp.key}, sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.filter(item => appid === enummapps.utilizatoriapp.key || appid === parseInt(item.appid)) // // microaplicatie utilizatori sau numai pentru enummapps = appid face schimb de date
          .map(item => { return {input: {pUtilizatormodeleazaid: parseInt(item.utilizatormodeleazaid), pUtilizatormodelatid: parseInt(item.utilizatormodelatid), },} })
      case wsnamesyncADs[0]:
        // console.log(`[utilizatori.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          return {input: {pKeyfield: 'email', pValue: item.email, pCnp: item.cnp.toString(), pUsername: item.username === undefined ? nullstring : item.username, },}
          })
      default:
    }     
  }

  // const input = {input: {pUtilizatormodeleazaid: parseInt(utilizatormodeleazaid), pUtilizatormodelatid: parseInt(utilizatormodelatid)},}

  const ondbDragandDropdb = () => {
    const input = {input: {pUtilizatorid: 0},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  }

  // const ondbpg = () => {
  //// console.log(`[utilizatori.table.ondbpg] - schimb date utilizatori: get utilizatori din microaplicatie Utilizatori`)
  //   const input = {input: {pAppid: appid},}
  //// console.log(`[utilizatori.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationpgfdw, input, onCompleted, onError), () => onRefresh())
  // }

  const ondbAdd = (item) => {
    // console.log(`[utilizatori.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const { utilizator, cnp, username, email, telefon, datainceput, datasfarsit, parola } = item
    const input = {input: {pUtilizator: utilizator, pCnp: cnp, pUsername: username, pEmail: email, 
      pTelefon: telefon, pDatainceput: formatdate(datainceput), 
      pDatasfarsit: datasfarsit !== undefined && datasfarsit !== "" ? formatdate(datasfarsit) : nulldate, 
      pParola: parola},}
    // console.log(`[utilizatori.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    const {utilizatorid, columnId, value} = field
    // console.log(`[utilizatori.table.ondbUpd] - db Upd: field: ${JSON.stringify({utilizatorid, columnId, value})}`)
    const input = {input: {pKeyfield: columnId, pValue: columnId === 'activ' ? value.toString() : value, pUtilizatorid: parseInt(utilizatorid)},}
    // console.log(`[utilizatori.table.ondbUpd]- utilizatorid: ${utilizatorid}, input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))   
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {utilizatorid}} = selectedFlatRow
      const input = {input: {pUtilizatorid: parseInt(utilizatorid)},}
      // console.log(`[utilizatori.table.ondbDel]- utilizatorid: ${utilizatorid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  const ondbDelAll = () => {
    // const input = {input: {pImportutilizatorid: 0},}
    // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  }
  const ondbRefresh = () => { 
    // console.log(`[utilizatori.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ndefaultgroup = 0
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[utilizatori.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[utilizatori.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const onClickFunction = (original) => events.onUtilizatorModelat(original.username)

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>administrare si modelare utilizatori</h3> */}
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: (() => editableColumns({params: {isresponsabilutilizatori}}))(), onrowSelected,
          EditableCell: EditableCell, editabledata: !modelareUtilizatori && appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilutilizatori ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilutilizatori) ? AddDialog : undefined, 
          importxlsfields: isresponsabilutilizatori ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          // pg: isresponsabilutilizatori && appid !== enummapps.utilizatoriapp.key ? {Pg, ondbpg, title:'pg »', } : undefined, 
          // ora: isresponsabilutilizatori ? {Ora, ondbora, title:'ora »', } : undefined, 
          exportxlsfields: isresponsabilutilizatori ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isresponsabilutilizatori ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          // importsyncADxlsfields: (appid === enummapps.utilizatoriapp.key && isresponsabilutilizatori) ? {Readxlsfile, ondbExcellAdd, title:'AD »', n, } : undefined, 
          initialAddDialog: initialAddDialog, validation,  
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, renderRowSubComponent, onClickFunction, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
            visibleSelectedrow: isresponsabilutilizatori && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
            visibledeleteall: false, visibledragDB: isresponsabilutilizatori && appid === enummapps.utilizatoriapp.key, visibleRefresh: true, 
            visualAD: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[1]-utilizatori', wsnames: wsnames}} headers={headers} 
            dbdata ={{data: [dbdata.dbvdata, dbdata.dbvdata1, dbdata.dbvdata2]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, {size:'A4', orientation: pdforientation.portrait.value}, {size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, dbdata.dbvdata1, dbdata.dbvdata2]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
