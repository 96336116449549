import React from 'react'

export default ({params: {errors}, }) => {
   return(
    <div>
        ERROR:
        {JSON.stringify(errors)}
    </div>
    )
}
