import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>cash flow: detaliere operatii de management, campurile atribut fiind: <b>mngmentoperationdetailId, mngmentoperationId, operatiune, norder, operationcodeId, collectionId, ndocument, description, value, debit, credit, businessid, </b> </li>
            <li>an, luna specifica cash flow, </li>
            <li>campul norder este stabilit automat la momentul adaugarii in baza de date prin incrementarea valorii precedente pentru a realiza unicitatea <b>mngmentoperationId, operatiune, norder, collectionid</b></li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga detaliere operatii de management noua [<b>+</b>], actualizare oricare dintre campurile <i>mngmentoperationId(date), operatiune, operationcodeId, collectionId, ndocument, description, value </i>, sterge inregistrare si refresh date </li>
                <li>actualizarea nu este posibila pentru campurile care modifica soldul zilei precedente sau al zilei curente la registul de casa. Pentru aceste situatii se recomanda stergerea si adaugarea altei operatii.</li>
                <li> pentru campurile <b>operationcodeId, collectionId</b> se foloseste cate un autocomplete care se populeaza fiecare cu o lista de valori. </li>
            </ul>
            <li>aplicatii utile</li>
            <ul>
                <li>registru de casa</li>
                <li>prelucrare extras cont</li>
                <li>..</li>
            </ul>
            <li>este posibil export in fisier xls <b>Mngmentoperationdetails.xlsx</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.dbmngementOperationDetails</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: db.mngmentoperationdetails</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>mngmentoperationdetailId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>mngmentoperationId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>operatiune</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>norder</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>operationcodeId</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>code + &#x0007B;incasare, plata&#x0007D;</td>
                        </tr>
                        <tr>
                            <td className='td'>collectionId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>cash, POS, cont curent, wallet</td>
                        </tr>
                        <tr>
                            <td className='td'>ndocument</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>numar act casa</td>
                        </tr>
                        <tr>
                            <td className='td'>description</td>
                            <td className='td'>VARCHAR(255</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>explicatie</td>
                        </tr>
                        <tr>
                            <td className='td'>value</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>debit</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>credit</td>
                            <td className='td'>FLOAT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>businessid</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>fie therapysessionid fie subscriptionid</td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vrcashflows(condition: $conditioncashflow) (year,                             
                                <div>codeoperationcode, nameoperationcode, norderoperationcode, </div>
                                <div>operationkindofid, nameoperationkindof, norderoperationkindof, kindofid, namekindofs, </div>
                                <div>sumjan, sumfeb, summar, sumapr, summay, sumjun, sumjul, sumaug, sumsep, sumoct, sumnov, sumdec, total</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: &#x0007B;year: year&#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationdetails(condition: $condition) (mngmentoperationdetailid, norder, operatiune,
                                <div>mngmentoperationId, date,</div>
                                <div>month, year, monthname, shortmonthname,</div>
                                <div>houseregisterprevious, houseregistercurrent,</div>
                                <div>operationcodeId, nameoperationcode, symboloperationcode, codeoperationcode, norderoperationcode, </div>
                                <div>operationkindofId, nameoperationkindof, symboloperationkindof, norderoperationkindof,</div>
                                <div>kindofId, namekindofs, symbolkindofs,</div>
                                <div>collectionId, name_rocollection, name_encollection, symbolcollection,</div>
                                <div>ndocument, description, value</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: &#x0007B;year: year&#x0007D;, &#x0007B;month: month&#x0007D;, mngmentoperationId !== undefined ? &#x0007B; mngmentoperationId: parseInt(mngmentoperationId) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationyears(orderBy: YEAR_DESC) (year)</td>
                            <td className='td'>lista de valori [year]</td>
                        </tr> 
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vmngmentoperationmonths(orderBy: MONTH_DESC, condition(year)) (month, year, monthname, shortmonthname)</td>
                            <td className='td'>lista de valori [month ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vcollections(orderBy: COLLECTIONID_ASC) (collectionid, nameRo)</td>
                            <td className='td'>lista de valori [fel operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>voperationcodes(orderBy: OPERATIONCODEID_ASC) (operationcodeid, nameoperationkindof, name, symbol, code)</td>
                            <td className='td'>lista de valori [cod operatii]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_addmngmentoperationdetail(p_mngmentoperationId, p_date, p_operatiune, p_operationcodeId, p_collectionId, p_ndocument, p_description, p_value, p_businessid)</td>
                            <td className='td'>intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_updmngmentoperationdetail(p_keyfield, p_value, p_mngmentoperationdetailId)</td>
                            <td className='td'>NU intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_delmngmentoperationdetail(p_mngmentoperationdetailId)</td>
                            <td className='td'>intretine soldul zilei precedente si al zilei curente pentru registrul de casa</td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>db, main</td>
                            <td className='td'>fn_upsmngmentoperationdetail(p_mngmentoperationdetailId, p_mngmentoperationId, p_operatiune, p_norder, p_operationcodeId, p_kindofId,p_collectionId, p_ndocument, p_description, p_value)</td>
                            <td className='td'>NU este accesibila. Se recomanda utilizarea la instantierea bazei de date. NU exista interfata</td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>db, main</td>
                            <td className='td'>UI: db, main: fn_extrascontintomngment(p_extrascontid INTEGER)</td>
                            <td className='td'>import extras cont: p_extrascontid = 0 WHERE procesat = false / p_extrascontid !== 0 - o singura inregistrare</td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


