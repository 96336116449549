
export default {
    AddDialogtitle: ['Adauga utilizator', 'Adauga inregistrare in tabela utilizatori.'],
    columns: [
      {header: 'utilizator', accessor: 'utilizator', type: 'text', show: 'true', required:true, },
      {header: 'username', accessor: 'username', type: 'text', show: 'true', required:true, },
      {header: 'email', accessor: 'email', type: 'text', show: 'true', required:true, },
      {header: 'cnp', accessor: 'cnp', type: 'text', show: 'true', required:true, },
      {header: 'telefon', accessor: 'telefon', type: 'text', show: 'true', required:true, },
      {header: 'datainceput', accessor: 'datainceput', type: 'date', show: 'true', required:true, },
      // {header: 'datasfarsit', accessor: 'datasfarsit', type: 'date', show: 'true', },
      {header: 'parola', accessor: 'parola', type: 'text', show: 'true', required:true, },
    ],
    initialdata: {
      utilizator: '',
      username: '',
      email: '',
      cnp: '',
      telefon: '',
      datainceput: '',
      // datasfarsit: '',
      parola: '',
      subRows: undefined,
    },    
  }