import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vtherapisttherapyprices === undefined ? null : dbdata.vtherapisttherapyprices.edges
    .map(edge => {
      return{
        ...edge.node, 
        therapist: `${edge.node.firstname} ${edge.node.lastname} ${edge.node.extensive !== null ? edge.node.extensive : ''}`,
        therapyprice: `${edge.node.nametherapyprice} ${edge.node.time} ${edge.node.price}`,
        nordertherapyprice: `${edge.node.nordertherapyprices}`,
        }
    })
  // console.log(`[configTherapisttherapyprices.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  const dbOptions = [
      ...dbdata.vtherapists.edges.map(edge => {
        const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
        return {id: 'therapistid', therapistid, therapist: `${firstname} ${lastname} ${extensive}[${symbol}, ${code}]`}
    })
  ]
  // console.log(`[configTherapisttherapyprices.page] - dbOptions: ${JSON.stringify(dbOptions)}` )
  // console.log(`[configTherapisttherapyprices.page] - dbdata.vtherapyprices: ${JSON.stringify(dbdata.vtherapyprices)}` )
  
  const dbOptions1 = [
      ...dbdata.vtherapyprices.edges.map(edge => {
        const {therapypriceid, nameRotherapy, time, price, } = edge.node
        return {id: 'therapypriceid', therapypriceid, therapyprice: `${nameRotherapy}, ${time}, ${price}RON`}
    })
  ]  
  // console.log(`[configTherapisttherapyprices.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )
  
    return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions, dbOptions1}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        condition: {type: VtherapisttherapypriceCondition, defaultValue: {}, }
    ) 
    {
      vtherapisttherapyprices(
        orderBy: THERAPISTTHERAPYPRICEID_ASC
        condition: $condition
      ) {
        edges {
          node {
            therapisttherapypriceid
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            phone
            email
            cnp
            percenttherapists
            therapypriceid
            therapyid
            therapy2Id
            therapytypeid
            timeid
            time2Id
            time
            price
            nametherapyprice
            descriptiontherapyprices
            nordertherapyprices
            percent
          }
        }
      }
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            # phone
            # email
            # cnp
            # percent
          }
        }
      } 
      vtherapyprices(orderBy: THERAPYPRICEID_ASC) {
        edges {
          node {
            therapypriceid
            # norder
            # therapyid
            nameRotherapy
            # nameEntherapy
            # symboltherapy
            # nordertherapy
            # descriptiontherapy
            # therapy2Id
            # nameRo2Therapy
            # nameEn2Therapy
            # symbol2Therapy
            # norder2Therapy
            # description2Therapy
            # therapytypeid
            # nameRotherapytype
            # nameEntherapytype
            # symboltherapytype
            # ntherapysessiontherapytype
            # ntherapysession1Therapytype
            # timeid
            # symboltime
            # minutestime
            # unitstime
            # time2Id
            # symboltime2
            # minutestime2
            # unitstime2
            time
            price
            # name
            # description          
          }
        }
      }
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

