import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const {selyearmng: selyear, } = params

  const dbvdata = dbdata.voperationcodespecificraports === undefined || dbdata.voperationcodespecificraports.edges.length === 0 ? 
    [{selyear, selyearid: '', }]
    : dbdata.voperationcodespecificraports.edges.map((edge, index) => {
      return{
        ...edge.node, 
        selyear: index === 0 ? selyear : '', selyearid: '', 
        }
      })
  // console.log(`[raportIncasaris.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  const dbvdata1 = dbdata.vrraportlorenas.edges.map(edge => edge.node)
  // console.log(`[raportIncasaris.page] - dbvdata1: ${JSON.stringify(dbvdata1)}` )
 
  // const dbOptions = [
  //   ...dbdata.vmngmentoperationyears.edges.map(edge => {
  //     const {year, } = edge.node
  //     return {id: 'selyearid', selyearid: year, selyear: year.toString()}
  // })
  // ]
  // console.log(`[raportIncasaris.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

  const dbOptions1 = [
    ...dbdata.voperationcodes.edges.map(edge => {
    const {operationcodeid, name, symbol, code, nameoperationkindof, } = edge.node
    return {id: 'operationcodeid', operationcodeid, nameoperationcode: `${nameoperationkindof}: ${name}(${symbol !== null ? symbol : ''})(${code})(${operationcodeid})`}
  })
  ]
  // console.log(`[raportIncasaris.page] - dbOptions1: ${JSON.stringify(dbOptions1)}` )

  const dbOptions2 = [
    ...dbdata.vfeloperationcodes.edges.map(edge => {
      const { feloperationcodeid, name, } = edge.node
      return {id: 'feloperationcodeid', feloperationcodeid: feloperationcodeid, namefeloperationcode: name}
  })
  ]
  // console.log(`[raportIncasaris.page] - dbOptions2: ${JSON.stringify(dbOptions2)}` )

  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
  
  return(
    <div style={backgrdStyle}>
      <div style={transbox} >
        <Table dbdata={{dbvdata: dbvdata, dbvdata1, dbOptions1, dbOptions2, }} params = {{...params, titles}} events={events} />
      </div>
    </div>
  )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }
        conditionyear: {type: VrraportlorenaCondition, defaultValue: {}, }
    ) 
    {
      voperationcodespecificraports {
        edges {
          node {
            operationcodespecificraportid
            operationcodeid
            nameoperationcode
            symboloperationcode
            code
            feloperationcodeid
            namefeloperationcode
            symbolfeloperationcode
          }
        }
      }
      vmngmentoperationyears(orderBy: YEAR_DESC) {
        edges {
          node {
            year
          }
        }
      }
      vrraportlorenas(condition: $conditionyear) {
        edges {
          node {
            date
            month
            monthname
            shortmonthname
            year
            codeoperationcode
            nameoperationcode
            level
            feloperationcodeid
            value
          }
        }
      }
      voperationcodes(orderBy: OPERATIONCODEID_ASC) {
      edges {
        node {
          operationcodeid
          operationkindofid
          nameoperationkindof
          operationkindofsymbol
          norderoperationkindof
          name
          symbol
          code
          norder
        }
      }
    }
    vfeloperationcodes(orderBy: FELOPERATIONCODEID_ASC) {
    edges {
      node {
        feloperationcodeid
        name
        symbol
      }
    }
  }
    }
    `
}
export default Relay.createFragmentContainer(Page, fragments)

