import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'

export default ({params, events}) => {
    // params.isTherapysession: false: calendar saptmanal terapeut / true - calendar terapeut la data ...
        // 1. apel din meniu.din lista terapeuti: params.isTherapysession: false => calendar saptmanal terapeut
        // 2. apel subcomponent din calendar: params.isTherapysession: true => calendar terapeut la data ...
        // 3. calendar va apela Therapysessions
    // console.log(`[dbCalendarsessions.component] - params.isTherapysession: ${params.isTherapysession}`)
    const {dbkey, toggleHelp, selyear, selweek, date, utilizatorOscar: {utilizatormodelat: {rols, therapistid}, }, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // const isterapeut = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.terapeut.key).length > 0 : false
    const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
    // const [seldate, setseldate] = React.useState(formatdateymd(new Date()))
    // const [seldate, setseldate] = React.useState(date !== undefined ? date : null)
    // console.log(`[dbCalendarsessions.component] - seldate: ${seldate}`)

    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }
    
    const {businesstherapistid, } = params // daca    e responsabil business =>   terapeut selectat in pas anterior
    // const {therapistid: businesstherapistid} = params    // daca    e responsabil business =>   terapeut selectat in pas anterior
    // console.log(`[dbCalendarsessions.component] - therapistid: ${therapistid}, businesstherapistid: ${businesstherapistid}`)
    const pIsresponsabilbusiness = isresponsabilbusiness ? 1 : 0

    const dates = date !== undefined ? date.split('-') : null
    // console.log(`[dbCalendarsessions.component] - date: ${date}, typeof(date): ${typeof(date)}, dates: ${dates}`)
    // const pYear = date !== undefined ? parseInt(dates[0]) : 0
    const pMonth = date !== undefined ? parseInt(dates[1]) : 0
    const pDay = date !== undefined ? parseInt(dates[2]) : 1
    // console.log(`[dbCalendarsessions.component] - {pMonth, pDay, pYear, selweek, selyear}: ${JSON.stringify({pMonth, pDay, pYear, selweek, selyear})}`)
    
    const pTherapistid = isresponsabilbusiness && isresponsabilbusiness ? parseInt(businesstherapistid) : therapistid
    // const pTherapistid = params.idtherapistbusiness && isresponsabilbusiness ? parseInt(businesstherapistid) : therapistid
    // const pTherapistid = params.idtherapistbusiness && isresponsabilbusiness ? null : therapistid
    // console.log(`[dbCalendarsessions.component] - pTherapistid: ${pTherapistid}`)
    const variables = { pRefresh: refresh, 
        pWeek: parseInt(selweek), 
        pYear: selweek === 52 && pMonth === 1 ? parseInt(selyear)+1: parseInt(selyear), 
        pMonth: pMonth, pDay: pDay,
        pTherapistid: pTherapistid, // cum este pentru businesstherapistid?
        pIsresponsabilbusiness: pIsresponsabilbusiness,
    }
    // console.log(`[dbCalendarsessions.component] - {utilizatormodelat: {rols}: ${JSON.stringify({utilizatormodelat: {rols}})}`)
    // console.log(`[dbCalendarsessions.component] - isresponsabilbusiness: ${isresponsabilbusiness}, isTherapist: ${isTherapist},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[dbCalendarsessions.component] - {selyear, selmonth, selweek}: ${JSON.stringify({selyear, selmonth, selweek})}`)
    // console.log(`[dbCalendarsessions.component] - variables: ${JSON.stringify(variables)}`)
    // console.log(`[dbCalendarsessions.component] - selweek: ${selweek}`)
    // console.log(`[dbCalendarsessions.component] - date: ${date}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} 
                params = {{...params, pTherapistid, date, isresponsabilbusiness, onRefresh, refresh, }} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
