/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddtherapyInput = {|
  clientMutationId?: ?string,
  pNameRo: string,
  pNameEn: string,
  pSymbol: string,
  pShortsymbol: string,
  pNorder: number,
  pDescription: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddtherapyInput
|};
export type mutationAddMutationResponse = {|
  +fnAddtherapy: ?{|
    +therapy: ?{|
      +description: ?string,
      +nameEn: string,
      +nameRo: string,
      +norder: ?number,
      +symbol: ?string,
      +shortsymbol: ?string,
      +therapyid: any,
      +activ: boolean,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddtherapyInput!
) {
  fnAddtherapy(input: $input) {
    therapy {
      description
      nameEn
      nameRo
      norder
      symbol
      shortsymbol
      therapyid
      activ
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddtherapyPayload",
    "kind": "LinkedField",
    "name": "fnAddtherapy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapy",
        "kind": "LinkedField",
        "name": "therapy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameRo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortsymbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapyid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01e8e47b95cf23f0b56e673b9bc93604",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddtherapyInput!\n) {\n  fnAddtherapy(input: $input) {\n    therapy {\n      description\n      nameEn\n      nameRo\n      norder\n      symbol\n      shortsymbol\n      therapyid\n      activ\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b148c4c91da433e6c1dc01001d1c08a';

module.exports = node;
