
export default [
    [
        {field: 'operationcodeid', value: 'operationcodeid'},
        {field: 'operationkindofid', value: 'operationkindofid'},
        {field: 'name', value: 'name'},
        {field: 'symbol', value: 'symbol'},
        {field: 'code', value: 'code'},
        {field: 'norder', value: 'norder'},
        {field: 'nameoperationkindof', value: 'nameoperationkindof'},
        {field: 'operationkindofsymbol', value: 'operationkindofsymbol'},
        {field: 'norderoperationkindof', value: 'norderoperationkindof'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

// 
// 
// 
