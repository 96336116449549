import React from 'react'
import { Link } from 'react-router-dom' 
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuReport1, } from '../../../../styles/styles/reportstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isterapeut = rols !== undefined ? 
        rols.filter(item => parseInt(item.rolid) === enumRols.terapeut.key).length > 0
        : false
        const isresponsabilbusiness = rols !== undefined ? 
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0
        : false
    const isresponsabil = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.terapeut.key).length > 0 ||
        // rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0
        ) 
        : false
    const visible = isresponsabil && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuReport1>
                {
                    visible ?
                        <> 
                            { isresponsabilbusiness ? 
                                <Link to='/raporttherapistsessions'>
                                    <TextIcon params = {{ text: visible ? 'raport terapeut' : '', title: 'raport terapeut', 
                                        width: theme.theme_main.withTextIconReportMenu, 
                                        fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                        color: theme.theme_main.headercolor}} />
                                </Link>
                              : isterapeut ? 
                              <Link to='/raportsingletherapistsessions'>
                                  <TextIcon params = {{ text: visible ? 'raport terapeut' : '', title: 'raport terapeut', 
                                      width: theme.theme_main.withTextIconReportMenu, 
                                      fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                      color: theme.theme_main.headercolor}} />
                              </Link>
                              : <></>
                            }
                        </>
                    : <> </>
                }
            </StyleMenuReport1>
        </>
    )
}