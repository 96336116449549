import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnAddmodelareutilizatorInput!)
{
  fnAddmodelareutilizator(input: $input) {
    modelareutilizator {
      modelareutilizatorid
      utilizatormodelatid
      utilizatormodeleazaid
    }
  }
}
`

