import formatdateymd from "../../../uioscar/formatdateymd"

export default () => {
  const columnDatas = [
    { header: 'data', accessor: 'data', prefix: 'data: ', show: 'true', type: 'date', },
  ]
  // const nextpagescolumnOperatiunes = [
  //   [
  //     {header: 'unitateid', accessor: 'unitateid', type: 'text', show: 'true', tree:true, },
  //   ],
  // ]

  return ({
  SearchDialogtitle: ['Data', 'Data'],  
  columns: columnDatas, //nextpagescolumns: raport === constraport ? nextpagescolumnOperatiunes : [],
  initialdata: {
    data: formatdateymd(new Date()),
    // unitateid: '1',
    subRows: undefined,
    },
  })
}
