import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import groupcols from './groupcols'
import headers from './xls/headers'
import initiall from './initiall'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUps from './mutationUps'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'

// import Readxlsfile from '../../../uioscar/readxlsfile'








// import mutationAdds from './mutationAdds'



// import SubComponent from '../utilizatoridrepturi/component'
// import nulldate from '../../../enums/nulldate'
















// import subcomponenttitle from './subcomponenttitle'



//import {stringify} from 'flatted'


export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, therapyid} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[configTherapyprices.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({dbOptions: dbdata.dbOptions, dbOptions1: dbdata.dbOptions1, dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, dbOptions4: dbdata.dbOptions4}), 
    [dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, dbdata.dbOptions3, dbdata.dbOptions4])   
  const initialAddDialog = React.useMemo( () => initiall({therapyid, dbOptions: dbdata.dbOptions, dbOptions1: dbdata.dbOptions1, dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, dbOptions4: dbdata.dbOptions4}), 
    [therapyid, dbdata.dbOptions, dbdata.dbOptions1, dbdata.dbOptions2, dbdata.dbOptions3, dbdata.dbOptions4]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[configTherapyprices.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[configTherapyprices.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {therapypriceid} = d.original
    //     // console.log(`[configTherapyprices.table.onrowSelected.map] - therapypriceid: ${therapypriceid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({therapypriceid})
    //   })
    // console.log(`[configTherapyprices.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       <Dialog key={`SubComponent`} 
  //         params = {{...params, ...subcomponenttitle, utilizatorid: row.values.utilizatorid, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} />
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[configTherapyprices.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[configTherapyprices.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[configTherapyprices.table] - format: ${format}`)

  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls) => {
    // console.log(`[configTherapyprices.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[configTherapyprices.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }

  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[configTherapyprices.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[configTherapyprices.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          return {input: {
            pTherapypriceid: parseInt(item.therapypriceid), 
            pTherapyid: parseInt(item.therapyid), pActiv: item.activ,
            pTherapy2Id: item.therapy2Id !== undefined && item.therapy2Id !== '' ? parseInt(item.therapy2Id) : parseInt(nullint), 
            pTherapytypeid: parseInt(item.therapytypeid), 
            pTimeid: parseInt(item.timeid), pNorder: parseInt(item.norder),
            pTime2Id: item.time2Id !== undefined && item.time2Id !== '' ? parseInt(item.time2Id) : parseInt(nullint), 
            pTime: item.time !== undefined && item.time !== '' ? item.time : nullint,
            pPrice: item.price !== undefined && parseInt(item.price) !== '' ? item.price : nullint,
            pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring, 
            pName: item.name,
          }}
        })
      default:
    }     
  }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pTherapypriceid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[configTherapyprices.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pTherapyid: parseInt(item.therapyid), 
      pTherapy2Id: item.therapy2Id !== undefined && item.therapy2Id !== '' ? parseInt(item.therapy2Id) : parseInt(nullint), 
      pTherapytypeid: parseInt(item.therapytypeid), pActiv: item.activ,
      pTimeid: parseInt(item.timeid), pNorder: parseInt(item.norder),
      pTime2Id: item.time2Id !== undefined && item.time2Id !== '' ? parseInt(item.time2Id) : parseInt(nullint), 
      pTime: item.time !== undefined && item.time !== '' ? item.time : nullint,
      pPrice: item.price !== undefined && item.price !== '' ? parseInt(item.price) : nullint,
      pDescription: item.description !== undefined && item.description !== '' ? item.description : nullstring, 
      pName: item.name,
    },}
    // console.log(`[configTherapyprices.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    if(field.columnId === 'therapy' || field.columnId === 'therapy2' || field.columnId === 'therapytype' || field.columnId === 'dtime' || field.columnId === 'dtime2') return
    // console.log(`[configTherapyprices.table.ondbUpd] - db Upd: field: ${JSON.stringify({therapypriceid: field.therapypriceid, columnId: field.columnId, value: field.value})}`)
    const input = {input: {pKeyfield: field.columnId, 
      pValue: field.columnId === 'activ' ? field.value.toString() : field.value, // pValue: field.value, 
      pTherapypriceid: parseInt(field.therapypriceid)},}
    // console.log(`[configTherapyprices.table.ondbUpd]- therapypriceid: ${field.therapypriceid}, input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))   
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {therapypriceid}} = selectedFlatRow
      const input = {input: {pTherapypriceid: parseInt(therapypriceid)},}
      // console.log(`[configTherapyprices.table.ondbDel]- therapypriceid: ${therapypriceid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[configTherapyprices.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ndefaultgroup = 0
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[configTherapyprices.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[configTherapyprices.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isadminApp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
  // console.log(`[configTherapyprices.table] - isadminApp: ${isadminApp}, rols: ${JSON.stringify(rols)}`) 

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      <h3 style={{margin: '1.875rem'}}>configurare lista preturi</h3>
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(therapyid), onrowSelected,
          EditableCell: EditableCell, editabledata: isadminApp && appid === enummapps.utilizatoriapp.key, //editabledata: isadminApp ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isadminApp) ? AddDialog : undefined, 
          importxlsfields: (isadminApp) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          exportxlsfields: isadminApp ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isadminApp ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: isadminApp && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[4]-Therapyprices', wsnames: wsnames}} headers={headers} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.portrait.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
