import React from 'react'
import asyncForEach from '../../../uioscar/asyncForEach'
import mutation from '../../../uioscar/mutation'

import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
// import Readxlsfile from '../../../uioscar/readxlsfile'
import Toxlsfile from '../../../uioscar/toxlsfile'
import ToPdf from '../../../uioscar/pdf'
// import AddDialog from '../../../uioscar/tablelib/addDialog'
// import Readxlsfile from '../../../uioscar/readxlsfile'
import Dialog from '../../../uioscar/fullscreenDialog'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import Button from '@material-ui/core/Button';

import mutationAdd from './mutationAdd'
// import mutationUps from './mutationUps'
// import mutationAdds from './mutationAdds'
import mutationUpd from './mutationUpd'
import mutationDel from './mutationDel'
import validation from './validation'
// import SubComponent from '../utilizatoridrepturi/component'
// import nulldate from '../../../enums/nulldate'
import nullstring from '../../../enums/nullstring'

import headers from './xls/headers'
import XLS from '../../../uioscar/xls/xls'

import pdftitles from './pdf/titles'
import pdfheaders from './pdf/headers'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import titlepdf from './pdf/subComponent'

import Columns from './columns'
import initiall from './initiall'
import editableColumns from './editableColumns'
import groupcols from './groupcols'
import title from './title'
// import subcomponenttitle from './subcomponenttitle'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import wsnames from './xls/wsnames'
//import {stringify} from 'flatted'
import pdforientation from '../../../enums/pdforientation'

export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, } = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[adminApp.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns, [])     
  const initialAddDialog = React.useMemo( () => initiall, []) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[adminApp.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[adminApp.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {appid} = d.original
    //     // console.log(`[adminApp.table.onrowSelected.map] - appid: ${appid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({appid})
    //   })
    // console.log(`[adminApp.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       <Dialog key={`SubComponent`} 
  //         params = {{...params, ...subcomponenttitle, utilizatorid: row.values.utilizatorid, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} />
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[adminApp.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[adminApp.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[adminApp.table] - format: ${format}`)

  // const [n, setn] = React.useState(0)
  // // const [inputs, setinputs] = React.useState([])
  // // const [drinputs, setdrinputs] = React.useState([])
  // const ondbExcellAdd = (xls) => {
  //   // console.log(`[adminApp.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
  //   const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
  //   // console.log(`[adminApp.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

  //   const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
  //   sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  // }

  // const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
  //   async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
  //   index => setn(index),
  //   () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  // )

  // const getinputs = ({sheet, xls}) => {
  //   // fiecare sheet devine un inputs array pentru inregistrare in baza de date
  //   switch(sheet) {
  //     case wsnames[0]:
  //       // console.log(`[adminApp.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
  //       return xls.map(item => {
  //         return {input: {pAppid: parseInt(item.appid), pDenumire: item.denumire, pSimbol: (item.simbol!== undefined ? item.simbol : nullstring), pLink: item.link, 
  //           pOrdonare: item.ordonare, },}
  //         })
  //     default:
  //   }     
  // }


  // const ondbDragandDropdb = () => {
  //   const input = {input: {pAppid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[adminApp.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const { denumire, simbol, link, ordonare } = item
    const input = {input: {pDenumire: denumire, pSimbol: simbol!== undefined ? simbol : nullstring, 
      pLink: link!== undefined ? link : nullstring, pOrdonare: parseInt(ordonare)},}
    // console.log(`[adminApp.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    const {appid, columnId, value} = field
    // console.log(`[adminApp.table.ondbUpd] - db Upd: field: ${JSON.stringify({appid, columnId, value})}`)
    const input = {input: {pKeyfield: columnId, pValue: value, pAppid: parseInt(appid)},}
    // console.log(`[adminApp.table.ondbUpd]- appid: ${appid}, input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))   
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {appid}} = selectedFlatRow
      const input = {input: {pAppid: parseInt(appid)},}
      // console.log(`[adminApp.table.ondbDel]- appid: ${appid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[adminApp.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }

  const ndefaultgroup = 0
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[adminApp.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[adminApp.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const issuperadmin = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 : false
  // console.log(`[adminApp.table] - issuperadmin: ${issuperadmin}, rols: ${JSON.stringify(rols)}`) 

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      <h3 style={{margin: '1.875rem'}}>administrare meniuri portal microaplicatii</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns, onrowSelected,
          EditableCell: EditableCell, editabledata: appid === enummapps.utilizatoriapp.key, //editabledata: issuperadmin ? appid === enummapps.utilizatoriapp.key : true,
          // dialogComponent: (appid === enummapps.utilizatoriapp.key && issuperadmin) ? AddDialog : undefined, 
          // importxlsfields: (issuperadmin) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          exportxlsfields: issuperadmin ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: issuperadmin ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: false && issuperadmin && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: 'apps', wsnames: wsnames}} headers={headers} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.portrait.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
