import Component from './page'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import React from 'react'
import enumRols from '../../../enums/rols'
import query from './query'

// import pageStyle from '../../../styles/styles/pagestyle'

export default ({params, events}) => {
    const {dbkey, toggleHelp, mngmentoperationid, selyearmng: selyear, selmonthmng: selmonth, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // onRefresh, refresh, -------------------------------------------------------------------------------
    const [refresh, setrefresh] = React.useState(true)
    const onRefresh = () => {
    const _refresh = !refresh
    setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
    }

    const variables = { pRefresh: refresh, 
        condition: params.backup ? {}
        // :{ year: parseInt(selyear), month: parseInt(selmonth.selmonthid),
        :{ year: parseInt(selyear), month: parseInt(selmonth.selmonthid),
            ...mngmentoperationid !== undefined ? {mngmentoperationid: parseInt(mngmentoperationid)} : {},
        }, 
        conditionyear:{year: parseInt(selyear)}, 
        conditionVenituriIncasariyear:{year: parseInt(selyear)}, 
        conditioncashflow:{year: parseInt(selyear)} 
    }
    // console.log(`[dbMngementOperationDetails.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[dbMngementOperationDetails.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)
    // console.log(`[dbMngementOperationDetails.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, onRefresh, refresh, }} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
