import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
    const dbvdata = dbdata.vutilizatornemodelats === undefined ? null : 
      dbdata.vutilizatornemodelats.edges.map(edge => {return{adauga: false, ...edge.node, }})
    // console.log(`[utilizatorNemodelats.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
    
    return(
      <div style={backgrdStyle}>
        <div style={transbox}>
          <Table dbdata={{dbvdata: dbvdata}} params = {{...params, titles}} events={events} />
        </div>
      </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        condition: {type: VutilizatornemodelatCondition, defaultValue: {grefresh: false} },
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
      vutilizatornemodelats(condition: $condition, orderBy: UTILIZATORMODELATID_ASC){
        edges {
          node {
            utilizatormodeleazaid
            utilizatormodelatid
            utilizator
            username
            email
            cnp
            telefon
            datainceput
            datasfarsit
            activ
            parola
            #grefresh
          }
        }
      }
    }
    `
}

export default Relay.createFragmentContainer(Page, fragments)

