
export default [
    [
        {field: 'monthkindofid', value: 'monthkindofid'},
        {field: 'name', value: 'name'},
        {field: 'symbol', value: 'symbol'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

