
export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'Utilizator',
      columns: [
        { id: 'mapp', Header: 'micro applicatie', accessor: 'app', width: 50, },
        { id: 'utilizator', Header: 'utilizator', accessor: 'utilizator', width: 50},
     ],
    },
    {
      Header: 'Date utilizator',
      columns: [
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 20,},
        { id: 'username', Header: 'username', accessor: 'username', width: 20,},
        { id: 'email', Header: 'email', accessor: 'email', width: 30,},
        { id: 'telefon', Header: 'telefon', accessor: 'telefon', width: 15,},
        { id: 'activ', Header: 'activ', accessor: 'activ', width: 30, align:'center', radiobuttondanu: true},
        { id: 'parola', Header: 'parola', accessor: 'parola', width: 20,},
      ]
    },      
    {
      Header: 'Drepturi', columns: [
        { id: 'responsabilbusiness', Header: 'responsabilbusiness', accessor: 'responsabilbusiness', width: 60,},
        { id: 'responsabilmanagement', Header: 'responsabilmanagement', accessor: 'responsabilmanagement', width: 60,},
        { id: 'terapeut', Header: 'terapeut', accessor: 'terapeut', width: 30,},
        { id: 'pacient', Header: 'pacient', accessor: 'pacient', width: 30,},
        // { id: 'liber', Header: 'liber', accessor: 'liber', width: 30,},
        { id: 'responsabilutilizatori', Header: 'responsabilutilizatori', accessor: 'responsabilutilizatori', width: 60,},
        { id: 'contabil', Header: 'contabil', accessor: 'contabil', width: 30,},
        { id: 'superadmin', Header: 'superadmin', accessor: 'superadmin', width: 30,},
        { id: 'adminapp', Header: 'adminapp', accessor: 'adminapp', width: 30,},
        { id: 'tester', Header: 'tester', accessor: 'tester', width: 30,},
        { id: 'developer', Header: 'developer', accessor: 'developer', width: 30,},
        { id: 'modelare', Header: 'modelare', accessor: 'modelare', width: 30,},
      ],
    },
    { 
      Header: 'db info', columns: [
        { id: 'appid', Header: 'appid', accessor: 'appid', width: 30,  },
        { id: 'utilizatorid', Header: 'utilizatorid', accessor: 'utilizatorid', width: 30,  },
      ],
    },
  ]
