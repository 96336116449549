import AddDialog from '../../../uioscar/tablelib/addDialog'
import Button from '@material-ui/core/Button';
import Columns from './columns/columns'
import Dialog from '../../../uioscar/fullscreenDialog'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import React from 'react'
import Readxlsfile from '../../../uioscar/readxlsfile'
import Styles from '../../../uioscar/tablelib/styleTable'
import SubComponent from './component'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import ToPdf from '../../../uioscar/pdf'
import Toxlsfile from '../../../uioscar/toxlsfile'
import XLS from '../../../uioscar/xls/xls'
import asyncForEach from '../../../uioscar/asyncForEach'
import configxlsreport from '../../../uioscar/xls/configxlsreport'
import editableColumns from './editableColumns'
import enumRols from '../../../enums/rols'
import enumcalendars from '../../../enums/calendars'
import enumcollections from '../../../enums/collections'
import enummapps from '../../../enums/mapps'
import formatdateymd from "../../../uioscar/formatdateymd"
import groupcols from './groupcols'
import initiall from './initiall'
import months from '../../../enums/months'
import mutation from '../../../uioscar/mutation'
import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import mutationUpd from './mutationUpd'
import mutationUpdcurrentmonth from './mutationUpdcurrentmonth'
import mutationUps from './mutationUps'
import nullint from '../../../enums/nullint'
import nullstring from '../../../enums/nullstring'
import pdfheaders from './pdf/headers'
import pdforientation from '../../../enums/pdforientation'
import pdftitles from './pdf/titles'
import search from './search'
import subcomponenttitle from './subcomponenttitle'
import title from './title'
import titlepdf from './pdf/subComponent'
import validation from './validation'
import wsnames from './xls/wsnames'
import xlsheaders from '../../../uioscar/xls/xlsheaders'

// import headers from './xls/headers'
// import Readxlsfile from '../../../uioscar/readxlsfile'
// import mutationAdds from './mutationAdds'
// import nulldate from '../../../enums/nulldate'
//import {stringify} from 'flatted'

export default ({dbdata, params, events}) => {
  const {appid, onRefresh, visibleerror, setvisibleerror, errors, seterrors, therapyid} = params
  const [data, setData] = React.useState(() => dbdata.dbvdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[dbTherapysessions.table] - data: ${JSON.stringify(data)}`)

  const {utilizatorOscar: {utilizatormodelat: {rols}}, ptherapysessionid, psubscriptionid, } = params
  const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns({isresponsabilbusiness, ptherapysessionid, psubscriptionid, 
      dbOptions: dbdata.dbOptions, //dbOptions1: dbdata.dbOptions1, 
      dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, //dbOptions4: dbdata.dbOptions4, 
      dbOptions5: dbdata.dbOptions5, dbOptions6: dbdata.dbOptions6, dbOptions7: dbdata.dbOptions7, 
      dbOptions8: dbdata.dbOptions8, 
      dbOptions9: dbdata.dbOptions9, dbOptions10: dbdata.dbOptions10, dbOptions11: dbdata.dbOptions11, }), 
    [dbdata.dbOptions, dbdata.dbOptions2, dbdata.dbOptions3, //dbdata.dbOptions4, //dbdata.dbOptions1, 
      dbdata.dbOptions5, dbdata.dbOptions6, dbdata.dbOptions7, dbdata.dbOptions8, dbdata.dbOptions9, 
      dbdata.dbOptions10, dbdata.dbOptions11,
      isresponsabilbusiness, psubscriptionid, ptherapysessionid, ])   
    const searchAddDialog = React.useMemo(() => search(), [])
    const configreportAddDialog = React.useMemo(() => configxlsreport(xlsheaders([dbdata.dbvdata[0]])), [dbdata.dbvdata, ])
    const initialAddDialog = React.useMemo( () => initiall({therapyid, dbOptions: dbdata.dbOptions, //dbOptions1: dbdata.dbOptions1, 
      dbOptions2: dbdata.dbOptions2, dbOptions3: dbdata.dbOptions3, dbOptions4: dbdata.dbOptions4, 
      dbOptions5: dbdata.dbOptions5, dbOptions6: dbdata.dbOptions6, dbOptions7: dbdata.dbOptions7, 
      dbOptions11: dbdata.dbOptions11,
    }), 
    [therapyid, dbdata.dbOptions, dbdata.dbOptions2, dbdata.dbOptions3, dbdata.dbOptions4, //dbdata.dbOptions1, 
      dbdata.dbOptions5, dbdata.dbOptions6, dbdata.dbOptions7, dbdata.dbOptions11, ]) 

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // console.log(`[dbTherapysessions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, `)    
    // console.log(`[dbTherapysessions.table.onrowSelected] - selectedRowIds: ${JSON.stringify(selectedRowIds)}, selectedFlatRows: ${stringify(selectedFlatRows)}`)    
    // const selectedRows = 
    //   selectedFlatRows.map(d => {
    //     const  {therapysessionid} = d.original
    //     // console.log(`[dbTherapysessions.table.onrowSelected.map] - therapysessionid: ${therapysessionid}, d.original: ${JSON.stringify(d.original)}`)    
    //     return ({therapysessionid})
    //   })
    // console.log(`[dbTherapysessions.table.onrowSelected2] - selectedRows: ${stringify(selectedRows)},
      // selectedRows: ${stringify(selectedRows)}`)    
  }

  const onCloseDialog = () => true

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
        <Dialog key={`SubComponent`} 
          params = {{...params, ...subcomponenttitle(ptherapysessionid), ptherapysessionid: undefined, psubscriptionid: ptherapysessionid !== undefined ? row.values.subscriptionid : undefined, }} 
          events={{onCloseDialog, ...events}} subComponent={SubComponent} />
      </>
    ),
    [params, events, ptherapysessionid]
  )

  const onError = errors => {
      // console.log(`[dbTherapysessions.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[dbTherapysessions.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[dbTherapysessions.table] - format: ${format}`)
  const onSearch = (item) => {
    const _date = new Date(item.data)
    const arrdate = formatdateymd(new Date(_date.getFullYear(), _date.getMonth()+1, 0)).split('-')
    // console.log(`[dbTherapysessions.table.onSearch] - arrdate: `, arrdate)

    params.setselmonth({selmonthid: parseInt(arrdate[1]), monthname: months[parseInt(arrdate[1])+1]})
    const input = {input: {  pKeyfield: 'month', pValue: arrdate[1], pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  

    const {selyear} = params
    if(selyear !== parseInt(arrdate[0])) { // anul selectat
      params.setselyear(arrdate[0])
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', pValue: arrdate[0], pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbTherapysessions.table.onSearch] - {selyear, arrdate[0]}: ${JSON.stringify({selyear, arrdate[0]})}`)
      // console.log(`[dbTherapysessions.table.onSearch] - input: `, input)
    }
  
  }

  const [newxlsheaders, setnewxlsheaders] = React.useState(xlsheaders([dbdata.dbvdata[0]]))
  const onConfigReport = (item) => {
    setnewxlsheaders([xlsheaders([dbdata.dbvdata[0]])[0].filter(header => item[header.field])])
  }


  const [n, setn] = React.useState(0)
  // const [inputs, setinputs] = React.useState([])
  // const [drinputs, setdrinputs] = React.useState([])
  const ondbExcellAdd = (xls) => {
    // console.log(`[dbTherapysessions.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
    const inputs = xls.map (item => getinputs(item)) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
    // console.log(`[dbTherapysessions.table.ondbExcellAdd] - appid: ${appid}, inputs: ${JSON.stringify(inputs)}`)

    const mutationxlss = [mutationUps, ] // list mutations pentru tratare sheets
    sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  }

  const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
    async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
    index => setn(index),
    () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  )

  const getinputs = ({sheet, xls}) => {
    // fiecare sheet devine un inputs array pentru inregistrare in baza de date
    switch(sheet) {
      case wsnames[0]:
        // console.log(`[dbTherapysessions.table.getinputs] - sheet: ${sheet}, xls: ${JSON.stringify(xls)}, typeof(xls): ${typeof(xls)}`)
        return xls.map(item => {
          // console.log(`[dbTherapysessions.table.getinputs.map] - item: ${JSON.stringify(item)}`)
          return {input: {
            pTherapysessionid: parseInt(item.therapysessionid), 
            pPatientid: parseInt(item.patientid), 
            pTherapistid: parseInt(item.therapistid), 
            pDate: item.date, 
            pFromid: item.fromid !== undefined && item.fromid !== '' ? parseInt(item.fromid) : 33, // default ora 8:00  
            pToid: parseInt(item.toid), 
            pTherapypriceid: parseInt(item.therapypriceid), pTherapyid: parseInt(item.therapyid), 
            pTherapy2Id: item.therapy2id !== undefined && item.therapy2id !== '' ? parseInt(item.therapy2id) : parseInt(nullint), 
            pTherapytypeid: item.therapytypeid !== undefined && item.therapytypeid !== '' ? parseInt(item.therapytypeid) : parseInt(nullint), 
            pPercent: parseInt(item.percent), pCalendarid: parseInt(item.calendarid), 
            pRoomid: item.roomid !== undefined && item.roomid !== '' ? parseInt(item.roomid) : parseInt(nullint),  
            pPatientnotes: item.patientnotes !== undefined && item.patientnotes !== '' ? item.patientnotes : nullstring, 
            pTherapistnotes: item.therapistnotes !== undefined && item.therapistnotes !== '' ? item.therapistnotes : nullstring, 
            pValue: parseInt(item.value), pCollectionid: parseInt(item.collectionid), pFinalizat: item.finalizat,
            pSubscriptionid: item.subscriptionid !== undefined && item.subscriptionid !== '' ? parseInt(item.subscriptionid) : parseInt(nullint), 
          }}
          })
      default:
    }     
  }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pTherapysessionid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[dbTherapysessions.table.ondbAdd] - db Add: item: ${JSON.stringify(item)}`)
    const input = {input: {
      pPatientid: item.patientid !== undefined && item.patientid !== '' ? parseInt(item.patientid) : parseInt(nullint), 
      pFirstname: item.firstname !== undefined && item.firstname !== '' ? item.firstname : nullstring, 
      pLastname: item.lastname !== undefined && item.lastname !== '' ? item.lastname : nullstring, 
      pTherapisttherapypriceid: item.therapisttherapypriceid !== undefined && item.therapisttherapypriceid !== '' ? parseInt(item.therapisttherapypriceid) : parseInt(nullint), 
      // pTherapistid: item.therapistid !== undefined && item.therapistid !== '' ? parseInt(item.therapistid) : parseInt(nullint), 
      pDate: item.date, 
      pFromid: item.fromid !== undefined && item.fromid !== '' ? parseInt(item.fromid) : 33, // default ora 8:00 //pTherapypriceid: parseInt(item.therapypriceid), 
      // pTherapy2id: item.therapy2id !== undefined && item.therapy2id !== '' ? parseInt(item.therapy2id) : parseInt(nullint), 
      // pTherapytypeid: item.therapytypeid !== undefined && item.therapytypeid !== '' ? parseInt(item.therapytypeid) : parseInt(nullint), 
      pCalendarid: item.calendarid !== undefined && item.calendarid && item.calendarid !== '' ? parseInt(item.calendarid) : enumcalendars.sedinta.key , 
      pRoomid: item.roomid !== undefined && item.roomid !== '' ? parseInt(item.roomid) : parseInt(nullint),  
      pPatientnotes: item.patientnotes !== undefined && item.patientnotes !== '' ? item.patientnotes : nullstring, 
      pTherapistnotes: item.therapistnotes !== undefined && item.therapistnotes !== '' ? item.therapistnotes : nullstring, 
      pCollectionid: item.collectionid !== undefined && item.collectionid !== '' ? parseInt(item.collectionid) : enumcollections.cash.key, 
      pPrice: item.value !== undefined && item.value !== '' ? parseInt(item.value) : parseInt(nullint), 
      pSubscriptionid: item.subscriptionid !== undefined && item.subscriptionid !== '' ? parseInt(item.subscriptionid) : parseInt(nullint), 
    },}
    // console.log(`[dbTherapysessions.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[dbTherapysessions.table.ondbUpd] - db Upd: field.finalizat: ${field.finalizat},`)
    // console.log(`[dbTherapysessions.table.ondbUpd] - db Upd: field: ${JSON.stringify(field)},`)
    // console.log(`[dbTherapysessions.table.ondbUpd] - db Upd: {therapysessionid, columnId, value}: ${JSON.stringify({therapysessionid: field.therapysessionid, columnId: field.columnId, value: field.value})},`)
    if(field.columnId === 'selyear'  || field.columnId === 'selmonth') return
    if(field.columnId === 'selyearid') {
      params.setselyear(field.value.toString())
      params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId === 'selmonthid') {
      params.setselmonth({selmonthid: field.value.toString(), monthname: months[ field.value]})
      const input = {input: {  pKeyfield: 'month', //field.columnId, 
        pValue: field.value, pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
    }
    else if(field.columnId  === 'finalizat' && field.value  === 'false' ) return // daca acum e false => anterior a fost true si nu permite update
    else if(field.columnId  === 'subscription' ) return 
    else if(field.columnId  !== 'fromhour' && field.columnId  !== 'tohour' && field.columnId  !== 'therapyprice'){
      const input = {input: {  pKeyfield: field.columnId, 
        pValue: field.columnId === 'finalizat' ? field.value.toString() : field.value, 
        pTherapysessionid: parseInt(field.therapysessionid)},}
      // console.log(`[dbTherapysessions.table.ondbUpd]- therapysessionid: ${field.therapysessionid}, input: ${JSON.stringify(input)}`)  
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError)) //, ondbRefresh 
      if(field.columnId === 'fromid') {
        const input = {input: {pKeyfield: 'toid', pValue: (parseInt(field.value) + field.unitstime).toString(), pTherapysessionid: parseInt(field.therapysessionid)},}
        // console.log(`[dbTherapysessions.table.ondbUpd]- therapysessionid: ${field.therapysessionid}, input: ${JSON.stringify(input)}`)  
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpd, input, onCompleted, onError))    
      }
    }  
  }

  const ondbDel = (selectedFlatRows) => {
    selectedFlatRows.map(selectedFlatRow => {
      const {original: {therapysessionid}} = selectedFlatRow
      const input = {input: {pTherapysessionid: parseInt(therapysessionid)},}
      // console.log(`[dbTherapysessions.table.ondbDel]- therapysessionid: ${therapysessionid}, input: ${JSON.stringify(input)}`)  
      return asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))    
    })
  }

  // const ondbDelAll = () => {
  //   // const input = {input: {pImportappid: 0},}
  //   // asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  const ondbRefresh = () => { 
    // console.log(`[dbTherapysessions.table.ondbRefresh] - visibleerror: ${visibleerror}`)
    if(!visibleerror)  onRefresh() 
  }
  const ondbNext = () => { 
    const {selmonth: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const nextmonth = month < 12 ? month + 1 : 1
    // params.setselmonth(nextmonth.toString())
    params.setselmonth({selmonthid: nextmonth, monthname: months[ nextmonth+1]})
    const input = {input: {  pKeyfield: 'month', pValue: nextmonth.toString(), pCurrentmonthid: 1},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  

    if(month >= 12) { // anul urmator
      const {selyear} = params
      const nextyear = parseInt(selyear) + 1
      params.setselyear(nextyear)
      // params.setselmonth({selmonthid: '', monthname: ''})
      const input = {input: {  pKeyfield: 'year', pValue: nextyear.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
      // console.log(`[dbTherapysessions.table.ondbNext] - nextyear: ${nextyear}`)
    }

  }
  const ondbPrev = () => { 
    const {selmonth: {selmonthid}} = params
    const month = parseInt(selmonthid)
    const {selyear} = params
    if((selyear === 2021 && month > 1) || selyear > 2021){
      const prevmonth = month > 1 ? month - 1 : 12
      // params.setselmonth(prevmonth.toString())
      params.setselmonth({selmonthid: prevmonth, monthname: months[ prevmonth+1]})
      const input = {input: {  pKeyfield: 'month', pValue: prevmonth.toString(), pCurrentmonthid: 1},}
      asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError) )  
    
      if(month === 1) { // anul precedent
        const prevyear = parseInt(selyear) - 1
        params.setselyear(prevyear)
        // params.setselmonth({selmonthid: '', monthname: ''})
        const input = {input: { pKeyfield: 'year', pValue: prevyear.toString(), pCurrentmonthid: 1},}
        asyncForEach([input], async input => await mutation(params.dbkey, mutationUpdcurrentmonth, input, onCompleted, onError), ondbRefresh)  
        // console.log(`[dbTherapysessions.table.ondbNext] - prevyear: ${prevyear}`)
      }
    }
  }


  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[dbTherapysessions.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[dbTherapysessions.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const istherapist = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.terapeut.key).length > 0 : false
  // console.log(`[dbTherapysessions.table] - isresponsabilbusiness: ${isresponsabilbusiness}, rols: ${JSON.stringify(rols)}`) 
  const {selmonth: {monthname}} = params

  // // console.log(`[dbTherapysessions.page] - dbdata.dbvtotalpos: ${JSON.stringify(dbdata.dbvtotalpos)}` )
  // // console.log(dbdata.dbvtotalpos)
  const totalpos = params.date !== undefined && dbdata.dbvtotalpos !== undefined ? dbdata.dbvtotalpos.filter(item => item.collectionid === enumcollections.POS.key).length !== 0 ? dbdata.dbvtotalpos.filter(item => item.collectionid === enumcollections.POS.key)[0].value : 0 : null
  const totalcash = params.date !== undefined && dbdata.dbvtotalpos !== undefined ? dbdata.dbvtotalpos.filter(item => item.collectionid === enumcollections.cash.key).length !== 0 ? dbdata.dbvtotalpos.filter(item => item.collectionid === enumcollections.cash.key)[0].value : 0 : null

  // console.log(`[dbTherapysessions.page] - totalpos: ${totalpos} totalcash: ${totalcash}, dbdata.dbvtotalpos: ${JSON.stringify(dbdata.dbvtotalpos)}` )
  // console.log(dbdata.dbvtotalpos)

  const isresponsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false
  const visible = params.subcompomponent === undefined

  const issearch = true
  const isconfigreport = visible && isresponsabilmanagement

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      {/* <h3 style={{margin: '1.875rem'}}>sedinte terapie</h3> */}
      <h3 style={{margin: '1.875rem'}}>{params.backup ? 'derulare procedura backup' : ''}</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title(ptherapysessionid, psubscriptionid, params.selyear, monthname), groupcol, groupcols}} events={{onsetcells}} />
      { params.totalpos !== null ? <h4 style={{margin: '1.875rem'}}>TOTAL POS: {totalpos}, TOTAL cash: {totalcash}</h4> : <></>}
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns: editableColumns(therapyid), onrowSelected,
          EditableCell: EditableCell, editabledata: isresponsabilbusiness && appid === enummapps.utilizatoriapp.key, //editabledata: isresponsabilbusiness ? appid === enummapps.utilizatoriapp.key : true,
          dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilbusiness) ? AddDialog : undefined, 
          searchDialogComponent: issearch ? {AddDialog, onSearch, searchAddDialog: searchAddDialog, 
            // handlenextPage: handlenextSearchPage, handleprevPage: handleprevSearchPage 
          } : undefined, 
          configreportDialogComponent: isconfigreport ? {AddDialog, onConfigReport, configreportAddDialog: configreportAddDialog, 
            // handlenextPage: handlenextReportConfigPage, handleprevPage: handleprevReportConfigPage 
          } : undefined, 
          importxlsfields: (isresponsabilbusiness) ? {Readxlsfile, ondbExcellAdd, title:'xls »', n, } : undefined, 
          exportxlsfields: isresponsabilbusiness ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isresponsabilbusiness || istherapist ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, ondbRefresh, ondbNext, ondbPrev, //ondbDragandDropdb, //, ondbDelAll
          //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: (istherapist || isresponsabilbusiness) && appid === enummapps.utilizatoriapp.key, visibleselectedFlatRows: true, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: true, visiblenexprev: true}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: '[11]-Therapysessions', wsnames: wsnames}} 
          headers={newxlsheaders} dbdata ={{data: [dbdata.dbvdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, pdfheaders, 
            pageSizes: [{size:'A4', orientation: pdforientation.landscape.value}, ], 
            dbdata: {data: [dbdata.dbvdata, ]} }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
