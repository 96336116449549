
export default ({therapistid, dbOptions, dbOptions1, }) => ({
  AddDialogtitle: ['Adauga pacienti', ''],
    columns: [
      {header: 'firstname', accessor: 'firstname', type: 'text', show: 'true', },
      {header: 'lastname', accessor: 'lastname', type: 'text', show: 'true', },
      {header: 'therapist', accessor: 'therapist', accessorid: 'therapistid', type: '', show: therapistid === undefined, 
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'extensive', accessor: 'extensive', type: 'text', show: 'true', },
      {header: 'birthyear', accessor: 'birthyear', type: 'number', show: 'true', },
      {header: 'weight', accessor: 'weight', type: 'number', show: 'true', },
      {header: 'height', accessor: 'height', type: 'number', show: 'true', },
      {header: 'phone', accessor: 'phone', type: 'text', show: 'true', },
      {header: 'email', accessor: 'email', type: 'text', show: 'true', },
      {header: 'cnp', accessor: 'cnp', type: 'text', show: 'true', },
    ],
    initialdata: {
      firstname: '',
      lastname: '',
      therapist: '',
      extensive: '',
      birthyear: '',
      weight: '',
      height: '',
      phone: '',
      email: '',
      cnp: '',
      percent: '',
      subRows: undefined,
    },    
  })
