/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vtherapists: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapistid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +symbol: ?string,
        +code: ?string,
        +phone: ?string,
        +email: ?string,
        +cnp: ?string,
        +percent: ?any,
        +activ: ?boolean,
        +calendarcss: ?string,
        +therapistoperationcode: ?boolean,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPISTID_ASC"
        }
      ],
      "concreteType": "VtherapistsConnection",
      "kind": "LinkedField",
      "name": "vtherapists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapistsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapist",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "extensive",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cnp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percent",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activ",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarcss",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistoperationcode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapists(orderBy:\"THERAPISTID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8f9c936539e6a212d6bd4a946dfc0216';

module.exports = node;
