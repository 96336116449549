import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelkindofInput!)
{
  fnDelkindof(input: $input) {
    kindof {
      kindofid
      name
      symbol
    }
  }
}
`
