/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdtherapytypeInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pTherapytypeid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdtherapytypeInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdtherapytype: ?{|
    +therapytype: ?{|
      +nameEn: string,
      +nameRo: string,
      +ntherapysession: number,
      +ntherapysession1: ?number,
      +symbol: ?string,
      +therapytypeid: any,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdtherapytypeInput!
) {
  fnUpdtherapytype(input: $input) {
    therapytype {
      nameEn
      nameRo
      ntherapysession
      ntherapysession1
      symbol
      therapytypeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdtherapytypePayload",
    "kind": "LinkedField",
    "name": "fnUpdtherapytype",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapytype",
        "kind": "LinkedField",
        "name": "therapytype",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameRo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapytypeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42872aa380f3fe46a2675c2c7c90f427",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdtherapytypeInput!\n) {\n  fnUpdtherapytype(input: $input) {\n    therapytype {\n      nameEn\n      nameRo\n      ntherapysession\n      ntherapysession1\n      symbol\n      therapytypeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7450c1bd25d4882397de68ffeacf870';

module.exports = node;
