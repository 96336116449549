/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vmngmentoperationdetails: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +mngmentoperationdetailid: ?any,
        +operatiune: ?string,
        +norder: ?number,
        +mngmentoperationid: ?number,
        +date: ?any,
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
        +houseregisterprevious: ?any,
        +houseregistercurrent: ?any,
        +operationcodeid: ?number,
        +nameoperationcode: ?string,
        +symboloperationcode: ?string,
        +codeoperationcode: ?string,
        +norderoperationcode: ?number,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +symboloperationkindof: ?string,
        +norderoperationkindof: ?number,
        +kindofid: ?number,
        +namekindofs: ?string,
        +symbolkindofs: ?string,
        +collectionid: ?number,
        +nameRocollection: ?string,
        +nameEncollection: ?string,
        +symbolcollection: ?string,
        +ndocument: ?string,
        +description: ?string,
        +value: ?any,
        +debit: ?any,
        +credit: ?any,
        +businessid: ?number,
      |}
    |}>
  |},
  +vmngmentoperationyears: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any
      |}
    |}>
  |},
  +vmngmentoperationmonths: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +month: ?any,
        +year: ?any,
        +monthname: ?string,
        +shortmonthname: ?string,
      |}
    |}>
  |},
  +vcollections: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +collectionid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +voperationcodes: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operationcodeid: ?any,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +operationkindofsymbol: ?string,
        +norderoperationkindof: ?number,
        +name: ?string,
        +symbol: ?string,
        +code: ?string,
        +norder: ?number,
      |}
    |}>
  |},
  +vrcashflows: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any,
        +codeoperationcode: ?string,
        +nameoperationcode: ?string,
        +norderoperationcode: ?number,
        +operationkindofid: ?number,
        +nameoperationkindof: ?string,
        +norderoperationkindof: ?number,
        +kindofid: ?number,
        +namekindofs: ?string,
        +sumjan: ?any,
        +sumfeb: ?any,
        +summar: ?any,
        +sumapr: ?any,
        +summay: ?any,
        +sumjun: ?any,
        +sumjul: ?any,
        +sumaug: ?any,
        +sumsep: ?any,
        +sumoct: ?any,
        +sumnov: ?any,
        +sumdec: ?any,
        +total: ?any,
      |}
    |}>
  |},
  +vrvenituricheltuielis: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +date: ?any,
        +year: ?any,
        +codeoperationcode: ?string,
        +nameoperationcode: ?string,
        +cash: ?any,
        +pos: ?any,
        +contcurent: ?any,
        +wallet: ?any,
        +none: ?any,
        +description: ?string,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norder",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortmonthname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationcodeid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationcode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codeoperationcode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norderoperationcode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "operationkindofid",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameoperationkindof",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "norderoperationkindof",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kindofid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "namekindofs",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collectionid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionVenituriIncasariyear"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditioncashflow"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "conditionyear"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "condition"
        }
      ],
      "concreteType": "VmngmentoperationdetailsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationdetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationdetailsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationdetail",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mngmentoperationdetailid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operatiune",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mngmentoperationid",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "houseregisterprevious",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "houseregistercurrent",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationcode",
                  "storageKey": null
                },
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symboloperationkindof",
                  "storageKey": null
                },
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolkindofs",
                  "storageKey": null
                },
                (v15/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRocollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameEncollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolcollection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ndocument",
                  "storageKey": null
                },
                (v16/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "debit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "credit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "businessid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "YEAR_DESC"
        }
      ],
      "concreteType": "VmngmentoperationyearsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationyears",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationyearsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationyear",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vmngmentoperationyears(orderBy:\"YEAR_DESC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionyear"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "MONTH_DESC"
        }
      ],
      "concreteType": "VmngmentoperationmonthsConnection",
      "kind": "LinkedField",
      "name": "vmngmentoperationmonths",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VmngmentoperationmonthsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vmngmentoperationmonth",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "COLLECTIONID_ASC"
        }
      ],
      "concreteType": "VcollectionsConnection",
      "kind": "LinkedField",
      "name": "vcollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcollectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v15/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vcollections(orderBy:\"COLLECTIONID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "OPERATIONCODEID_ASC"
        }
      ],
      "concreteType": "VoperationcodesConnection",
      "kind": "LinkedField",
      "name": "voperationcodes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VoperationcodesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Voperationcode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operationkindofsymbol",
                  "storageKey": null
                },
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "voperationcodes(orderBy:\"OPERATIONCODEID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditioncashflow"
        }
      ],
      "concreteType": "VrcashflowsConnection",
      "kind": "LinkedField",
      "name": "vrcashflows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VrcashflowsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vrcashflow",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v8/*: any*/),
                (v7/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumjan",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumfeb",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "summar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumapr",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "summay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumjun",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumjul",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumaug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumsep",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumoct",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumnov",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sumdec",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "conditionVenituriIncasariyear"
        }
      ],
      "concreteType": "VrvenituricheltuielisConnection",
      "kind": "LinkedField",
      "name": "vrvenituricheltuielis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VrvenituricheltuielisEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vrvenituricheltuieli",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v8/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cash",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pos",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contcurent",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "wallet",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "none",
                  "storageKey": null
                },
                (v16/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4bab5b78c2ea4a723fde9dc3c52d060';

module.exports = node;
