import React from 'react'
import asyncForEach from '../../../uioscar/asyncForEach'
import mutation from '../../../uioscar/mutation'

import Styles from '../../../uioscar/tablelib/styleTable'
import Table from '../../../uioscar/tablelib/tableComponentExpandedEditableCellsPaginationResizeble'
import EditableCell from '../../../uioscar/tablelib/editableCellComponent'
// import Readxlsfile from '../../../uioscar/readxlsfile'
import Toxlsfile from '../../../uioscar/toxlsfile'
import ToPdf from '../../../uioscar/pdf'
// import AddDialog from '../../../uioscar/tablelib/addDialog'
// import Readxlsfile from '../../../uioscar/readxlsfile'
import Dialog from '../../../uioscar/fullscreenDialog'
import Error from '../../../uioscar/error'
import GroupcolumnsRadioButtonInput from '../../../uioscar/tablelib/groupcolumnsRadioButtonInput'
import { DialogFixedStyle } from '../../../styles/styles/fullscreendialogstyle'
import Button from '@material-ui/core/Button';

import mutationAdd from './mutationAdd'
import mutationDel from './mutationDel'
import validation from './validation'
// import SubComponent from '../../../app/components/../component'
// import nulldate from '../../../enums/nulldate'
// import nullstring from '../../../enums/nullstring'

import headers from './xls/headers'
import XLS from '../../../uioscar/xls/xls'

import pdftitles from './pdf/titles'
import pdfheaders from './pdf/headers'
import Pdf from '../../../uioscar/pdf/pdflib/subComponentpdf'
import titlepdf from './pdf/subComponent'

import Columns from './columns'
import initiall from './initiall'
import editableColumns from './editableColumns'
import groupcols from './groupcols'
import title from './title'
// import subcomponenttitle from './subcomponenttitle'
import enummapps from '../../../enums/mapps'
import enumRols from '../../../enums/rols'

export default ({dbdata, dbdataOptions, params, events}) => {
  const {appid, visibleerror, setvisibleerror, errors, seterrors, dbvrols} = params
  const [data, setData] = React.useState(() => dbdata)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  // const [originalData] = React.useState(dbdata)
  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // console.log(`[utilizatoridrepturi.table] - data: ${JSON.stringify(data)}`)

  const [format, setformat] = React.useState('html')
  const columns = React.useMemo( () => Columns, [])      
  const initialAddDialog = initiall({dbdataOptions})

  // We need to keep the table from resetting the pageIndex when we Update data. So we can keep track of that flag with a ref.
  // const skipResetRef = React.useRef(false)

  // After data chagnes, we turn the flag back off so that if data actually changes when we're not editing it, the page is reset
  React.useEffect(() => { setSkipPageReset(false) }, [setSkipPageReset])

  // Let's add a data resetter/randomizer to help illustrate that flow...
  // const resetData = () => {
  //   // Don't reset the page when we do this
  //   skipResetRef.current = true
  //   // setData(originalData)
  // }

  const onrowSelected = (selectedFlatRows) => {
    // const selectedRows = 
    // selectedFlatRows.map(d => {
    //     const  {angajatid, anangajatid} = d.original
    //     return ({angajatid, anangajatid})
    //   })
  }

  const onCloseDialog = () => true

  // // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => (
  //     <>
  //       {/* <pre style={{ fontSize: '10px', }}><code>{JSON.stringify({ values: row.values }, null, 2)}</code></pre> */}
  //       {/* <Dialog key={`SubComponent`} 
  //         params = {{...params, title:'COMPONENT FORM', buttontitle:'OPEN COMPONENT FORM', componnenid: row.values.componnenid, email: row.values.email, telefon: row.values.telefon, }} 
  //         events={{onCloseDialog, ...events}} subComponent={SubComponent} /> */}
  //     </>
  //   ),
  //   [params, events]
  // )

  const onError = errors => {
      // console.log(`[utilizatoridrepturi.table.onError] - errors: ${JSON.stringify(errors)}`)
      seterrors(errors)
      setvisibleerror(!visibleerror)    
  }
  const onCompleted = (response, errors) => {
      // console.log(`[utilizatoridrepturi.table.onCompleted] - response: ${JSON.stringify(response)}`)
      if(errors)  
          onError(errors)
      else if(response) { 
          // onRefetch(response)
      }
  }

  const ondbPdf = () => format === 'html' ? setformat('pdf') : setformat('html')
  const ondbExcellExport = () => format === 'html' ? setformat('xls') : setformat('html')
  // console.log(`[utilizatori.table] - format: ${format}`)

  // const [n, setn] = React.useState(0)
  // const ondbExcellAdd = (xls) => {
  //   // console.log(`[utilizatori.table.ondbExcellAdd] - xls: ${JSON.stringify(xls)}`)
    
  //   const inputs = xls.map (item => {return getinputs(item)}) // construieste array inputs cu doua componente, cate una pentru fiecare sheet
  //   // console.log(`[utilizatori.table.ondbExcellAdd] - inputs: ${JSON.stringify(inputs)}`)

  // //   sheetUtilizatori(inputs) // executa mutation pentru sheet utilizatori(inputs[0]), iar apoi la final mutation pentru sheetDrepturi(inputs[1])

  // const mutationxlss = [mutationAdd, ] // list mutations pentru tratare sheets
  //   sheettodb(inputs, mutationxlss) // executa mutation pentru primul sheet, iar la final recursiv mutation pentru urmatorul sheet
  // }

  // const sheettodb = (inputs, mutationxlss, i=0) => asyncForEach(inputs[i], 
  //   async input => await mutation(params.dbkey, mutationxlss[i], input, onCompleted, onError), 
  //   index => setn(index),
  //   () => mutationxlss.length-i-1 > 0 ? sheettodb(inputs, mutationxlss, i+1) : setTimeout(() =>  ondbRefresh(), 10) // apel recursiv finalizat cu setTimeout
  // )

  // // const sheetUtilizatori = inputs => asyncForEach(inputs[0], 
  // //   async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError), 
  // //   index => setn(index),
  // //   () => setTimeout(() =>  ondbRefresh(), 10)
  // // )

  // const getinputs = ({sheet, xls}) => {
  //   // fiecare sheet devine un inputs array pentru inregistrare in baza de date
  //   switch(sheet) {
  //     case 'utilizatori':
  //       return xls.map(item => {
  //         return {input: {pUtilizatorid: item.utilizatorid, pUtilizator: item.utilizator, pCnp: item.cnp.toString(), pUsername: item.username, pEmail: item.email, 
  //           pTelefon: (item.telefon!== undefined ? item.telefon : nullstring), // pdiffTimeZone: item.datainceput.getTimezoneOffset().toString(),
  //           pDatainceput: item.datainceput, pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
  //           // pDatainceput: item.datainceput.toLocaleDateString("ro-RO", {timeZone: "Europe/Istanbul"}), pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
  //           // pDatainceput: item.datainceput.toLocaleDateString("en-US", {timeZone: "Europe/Istanbul"}), pDatasfarsit: (item.datasfarsit!== undefined ? item.datasfarsit : nulldate), 
  //           pParola: item.parola.toString()},}
  //         })
  //         // console.log(`[utilizatori.table.ondbExcellAdd]- inputs: ${JSON.stringify(inputs)}, typeof(inputs): ${typeof(inputs)}`)  
  //     default:
  //   }     
  // }

  // const ondbDragandDropdb = () => {
  //   const input = {input: {pUtilizatorid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationAdds, input, onCompleted, onError), () => ondbDelAll())   
  // }

  const ondbAdd = (item) => {
    // console.log(`[utilizatoridrepturi.table.ondbAdd] - item: ${JSON.stringify(item)}`)
    const { utilizatorid, appid, rolid } = item
    const input = {input: {pUtilizatorid: utilizatorid, pAppid: appid, pRolid: rolid},}
    // console.log(`[utilizatoridrepturi.table.ondbAdd]- input: ${JSON.stringify(input)}`)  
    asyncForEach([input], async input => await mutation(params.dbkey, mutationAdd, input, onCompleted, onError))
  }

  const ondbUpd = (field) => {
    // console.log(`[utilizatoridrepturi.table.ondbUpd] - field: ${JSON.stringify(field)}`)
    const {utilizatorid, appid, value, objvalue: {rolid}} = field
    // console.log(`[utilizatoridrepturi.table.ondbUpd] - {utilizatorid, appid, value, rolid}: ${JSON.stringify({utilizatorid, appid, value, rolid})}`)
    if(appid !== null) value === "" ? ondbDel({utilizatorid: parseInt(utilizatorid), appid: parseInt(appid), rolid: parseInt(rolid)}) : ondbAdd({utilizatorid: parseInt(utilizatorid), appid: parseInt(appid), rolid: parseInt(value)})
  }

  const ondbDel = (item) => {
    // console.log(`[utilizatoridrepturi.table.ondbDel] - item: ${JSON.stringify(item)}`)
    const { utilizatorid, appid, rolid } = item
    const input = {input: {pUtilizatorid: utilizatorid, pAppid: appid, pRolid: rolid},}
    asyncForEach([input], async input => await mutation(params.dbkey, mutationDel, input, onCompleted, onError))
  }

  // const ondbDelAll = () => {
  //   const input = {input: {pImportutilizatorid: 0},}
  //   asyncForEach([input], async input => await mutation(params.dbkey, mutationDelAll, input, onCompleted, onError), () => ondbRefresh() )       
  // }
  // const ondbRefresh = () => { 
  //   // console.log(`[utilizatoridrepturi.table.ondbRefresh] - visibleerror: ${visibleerror}`)
  //   if(!visibleerror)  onRefresh() 
  // }

  const ndefaultgroup = 1
  const [groupcol, setgroupcol] = React.useState(groupcols[ndefaultgroup].label)
  const allcolumns = groupcols.map(item => item.groupcol).flat()
  // console.log(`[utilizatoridrepturi.table] - allcolumns: ${JSON.stringify(allcolumns)}`) 
  const fhiddencolumns = cols => allcolumns.filter(item => cols.filter(hc => hc === item).length === 0) 
  const hiddenColumns = groupcol === groupcols[groupcols.length-1].label ? []
    : groupcols.map(item => groupcol === item.label ? fhiddencolumns(item.groupcol) : []).filter(item => item.length > 0)[0] 
  // console.log(`[utilizatoridrepturi.table] - hiddenColumns: ${JSON.stringify(hiddenColumns)}`) 
  const onsetcells = (value) => groupcols.filter(item => item.label === value).map(item => setgroupcol(item.label))

  const {utilizatorOscar: {utilizatormodelat: {rols}}, } = params
  const isresponsabilutilizatori = rols !== undefined ? (
    rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0) 
    : false

  return (
    <Styles>
      {visibleerror ? <Error params = {{errors, setvisibleerror, seterrors, }} />: <></>}
      <h3 style={{margin: '1.875rem'}}>admin drepturi utilizatori microaplicatii</h3>
      <GroupcolumnsRadioButtonInput params = {{title: title, groupcol, groupcols}} events={{onsetcells}} />
        
      <Table 
        columns={columns} data={data} setData={setData} 
        nonApiFields = {{hiddenColumns, editableColumns, onrowSelected,
          EditableCell: EditableCell, editabledata: isresponsabilutilizatori && appid === enummapps.utilizatoriapp.key, rols: dbvrols,
          // dialogComponent: (appid === enummapps.utilizatoriapp.key && isresponsabilutilizatori) ? AddDialog : undefined, 
          // importxlsfields: isresponsabilutilizatori ? {Readxlsfile, ondbExcellAdd, title:'xls »', wsnames: ['drepturiUtilizatori'], n, } : undefined, 
          exportxlsfields: isresponsabilutilizatori ? {Toxlsfile, ondbExcellExport, title:'» xls', } : undefined, 
          pdf: isresponsabilutilizatori ? {ToPdf, ondbPdf, title:'pdf', } : undefined, 
          initialAddDialog: initialAddDialog, validation,
          ondbAdd, ondbUpd, ondbDel, //ondbRefresh, //, ondbDelAll, ondbDragandDropdb, //refsArray, maxindex, 
          skipPageReset, setSkipPageReset, //renderRowSubComponent, 
          visibles: {visiblePaginationTable: true, visibleFooterTable: false, visibleToolbar: true, visibleGroup: false,
              visibleSelectedrow: false, visibleselectedFlatRows: false, visibleFilter: true,
              visibledeleteall: false, visibledragDB: false, visibleRefresh: false}}}
      />
      {format === 'xls' ? 
        <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={ondbExcellExport}>
          <XLS key={`xls`} params = {{...params, filename: 'drepturiUtilizatori', wsnames: ['drepturiUtilizatori']}} headers={headers} dbdata ={{data: [dbdata]}} />
        </Button>
      : <></>
      }
      {format === 'pdf' ? 
        <Dialog key={`SubComponent`} 
          params = {{...params, pdftitles, ...titlepdf, dbdata: {data: [dbdata, ]}, pdfheaders }} 
          events={{onCloseDialog, ...events}} subComponent={Pdf} />
      : <></>
      }
    </Styles>
  )
}
