// import React from 'react'

export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'times',
      columns: [
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'minutes', Header: 'minutes', accessor: 'minutes', width: 30, type: ' number',},
        { id: 'units', Header: 'units', accessor: 'units', width: 30, type: ' number',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'timeid', Header: 'timeid', accessor: 'timeid', width: 30,  },
  ],
},
]
