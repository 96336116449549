
export default {
    AddDialogtitle: ['Adauga times', ''],
    columns: [
      {header: 'minutes', accessor: 'minutes', type: 'number', show: 'true', },
      {header: 'units', accessor: 'units', type: 'number', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
    ],
    initialdata: {
      minutes: '',
      units: '',
      symbol: '',
      subRows: undefined,
    },    
  }

