import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'

// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

export default ({params: {isDeveloper}}) => 
    <>
        
            <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
                <ul>
                    <li>administrare meniu micro aplicatii</li>
                    <li>campurile <b>appmenuid, denumire, simbol, ordonare</b> </li>
                    <li>pentru campurile <u>denumire, simbol, ordonare</u> exista unicitate in tabela, pe grupele [appid, denumire, ordonare] si respectiv [appid, simbol,ordonare] </li>
                    <li>campul simbol este optional</li>
                    <li>sunt posibile urmatoarele operatii: </li>
                    {/* {false ?
                    <>
                        <ul>
                            <li>adauga element meniu [<b>+</b>], actualizeaza campuri denumire, simbol, sterge element meniu, refresh date </li>
                            <li>preluare date element meniu din tabela de import. Dupa preluare este necesar click pe buton <b>Refresh</b> pentru vizualizarea datelor </li>
                        </ul>
                    </>
                    : <></>} */}
                    <li>este posibil export in fisier xls si listare in format pdf</li>
                    <li>click link <b>help</b> pentru a ascunde help text </li>
                    { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>utilizatorcomponents.adminMenuApp</b></li>
                <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: private.appmenus</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>appmenuId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>appId</td>
                            <td className='td'>INT</td>
                            <td className='td'>Fk</td>
                            <td className='td'>uk1</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Denumire</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>Simbol</td>
                            <td className='td'>VARCHAR(250)</td>
                            <td className='td'>-</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>ordonare</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1, uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                    <caption><b><u>views, functions</u></b></caption>
                    <tr>
                        <th className='th'>facility</th>
                        <th className='th'>fn</th>
                        <th className='th'>scheme</th>
                        <th className='th'>function</th>
                        <th className='th'>condition</th>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>VIEW</td>
                        <td className='td'>private, mainview</td>
                        <td className='td'>vappmenus(orderBy: ORDONARE_ASC, condition: {}) (appmenuId, Denumire, Simbol, ordonare)</td>
                        <td className='td'>-</td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>add</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_addappmenu(p_denumire, p_simbol)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>upd</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_updappmenu(p_keyfield, p_value, p_appmenuId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI</td>
                        <td className='td'>del</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_del(p_appmenuId)</td>
                        <td className='td'></td>
                    </tr>
                    <tr>
                        <td className='td'>UI[xls]</td>
                        <td className='td'>ups</td>
                        <td className='td'>private, main</td>
                        <td className='td'>fn_upsappmenu(p_appmenuId, p_denumire, p_simbol, p_ordonare)</td>
                        <td className='td'></td>
                    </tr>
                </table>
                </HelpTableStyle>
                </>
            : <></>
            }
                </ul>
            </div>
    </>
