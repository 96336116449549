import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $pMonth: Int!
  $pYear: Int!
  # $condition: VrincasaridetailCondition
  $conditionyear: VmngmentoperationmonthCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh, pMonth: $pMonth, pYear: $pYear, 
  # condition: $condition, 
  conditionyear: $conditionyear) 
}
`

