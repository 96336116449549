
export default {
    AddDialogtitle: ['Adauga calendar', 'Adauga inregistrare in tabela apps.'],
    columns: [
      {header: 'nameRo', accessor: 'nameRo', type: 'text', show: 'true', },
      {header: 'nameEn', accessor: 'nameEn', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
    ],
    initialdata: {
      nameRo: '',
      nameEn: '',
      symbol: '',
      subRows: undefined,
    },    
  }
