
export default [
    {
        extrascontid: {field: 'extrascontid', values: ['', '', 'extrascontid'], width: 10, isLastRow : false},
        date: {field: 'date', values: ['', '', 'date'], width: 10, isLastRow : false},
        operationcodeid: {field: 'operationcodeid', values: ['', '', 'operationcodeid'], width: 10, isLastRow : false},
        collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
        ndocument: {field: 'ndocument', values: ['', '', 'ndocument'], width: 10, isLastRow : false},
        detaliitranzactie: {field: 'detaliitranzactie', values: ['', '', 'detaliitranzactie'], width: 10, isLastRow : false},
        debit: {field: 'debit', values: ['', '', 'debit'], width: 10, isLastRow : false},
        credit: {field: 'credit', values: ['', '', 'credit'], width: 10, isLastRow : false},
        month: {field: 'month', values: ['', '', 'month'], width: 10, isLastRow : false},
        year: {field: 'year', values: ['', '', 'year'], width: 10, isLastRow : false},
    }, 
]

// monthname
// shortmonthname
