import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DialogFixedStyle } from '../styles/styles/fullscreendialogstyle'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({nonApiFields}) => {
  const { externComponentparams } = nonApiFields
  const {params, onCloseDialog, subComponent: SubComponent, subcomponenttitle: {title, buttontitle}} = externComponentparams
  // console.log(`[externComponent] - {title, buttontitle}: ${JSON.stringify({title, buttontitle})}, `)
  // console.log(`[externComponent] - subcomponenttitle: ${JSON.stringify(subcomponenttitle)}, `)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(onCloseDialog !== undefined) onCloseDialog()
  };

  // console.log(`[Dialog] - params: ${JSON.stringify(params)}, events: ${JSON.stringify(events)} `)
  return (
    <div>
      <Button  style={DialogFixedStyle} variant="outlined" color="secondary" onClick={handleClickOpen}>
        {buttontitle}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              close
            </Button>
          </Toolbar>
        </AppBar>
        <SubComponent params = {params} events={{onCloseDialog}} / >
      </Dialog>
    </div>
  );
}
