
export default  [
    {label: 'yearmonth', text: 'an, luna', groupcol: [ 'selyear', 'selmonth', 'therapistreport', ] }, 
    {label: 'Therapistsessions', text: 'raport terapeut', groupcol: [ 'patient', 'nametherapyprice', 'date', 'sfrom', 'sto', 'ora', 'percent', 'namerocollection', 'value', 'therapistvalue',  ] }, //, 'month', 'monthname', 'year' 
    {label: 'dbinfo', text: 'db info', groupcol: [ 'therapistid', 'therapistreportid',   ]}, //'selyearid', 'selmonthid', 'operationkindofid', 'kindofid',
    {label: 'toate', text: 'toate', groupcol: []},
  ]

  
  
  

  
  
  