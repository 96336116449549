import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare1, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}}, events}) => {
    const issuperadmin = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 : false
    const visible = issuperadmin && appid === enummapps.utilizatoriapp.key
    return (
        <>
            <StyleMenuAdministrare1>
                {
                    visible ?
                        <> 
                            <Link to='/adminrols'>
                                <TextIcon params = {{ text: visible ? 'drepturi[-]' : '', title: 'adminrols', 
                                     width: theme.theme_main.widthTextIconAdminMenu*19/41, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/adminapps'>
                                <TextIcon params = {{ text: "amos[-]", title: 'adminapps', 
                                     width: theme.theme_main.widthTextIconAdminMenu*20/41, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare1>
        </>
    )
}