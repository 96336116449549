import React from 'react'
import logo from '../../../../static/logo/logo.jpg'
import { LogoStyle, logocontainer, LogoColumnStyle } from '../../../../styles/styles/headerstyle'

export default () => 
    <LogoColumnStyle>
        <div style={logocontainer}>
          <img src={logo} alt="amos" style={LogoStyle} />
        </div>                     
    </LogoColumnStyle>                    
