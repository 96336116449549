
export default [
    [
        {field: 'therapistcollectionid', value: 'therapistcollectionid'},
        {field: 'therapistid', value: 'therapistid'},
        {field: 'firstname', value: 'firstname'},
        {field: 'lastname', value: 'lastname'},
        {field: 'extensive', value: 'extensive'},
        {field: 'symbol', value: 'symbol'},
        {field: 'code', value: 'code'},
        {field: 'phone', value: 'phone'},
        {field: 'email', value: 'email'},
        {field: 'cnp', value: 'cnp'},
        {field: 'percenttherapists', value: 'percenttherapists'},
        {field: 'collectionid', value: 'collectionid'},
        {field: 'nameRo', value: 'nameRo'},
        {field: 'nameEn', value: 'nameEn'},
        {field: 'symbolcollections', value: 'symbolcollections'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
