
export default {
    AddDialogtitle: ['Adauga fel luna', ''],
    columns: [
      {header: 'name', accessor: 'name', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
    ],
    initialdata: {
      name: '',
      symbol: '',
      subRows: undefined,
    },    
  }
