// import React from 'react'

export default ({ dbOptions1, dbOptions2}) => ([
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: 'number', align: 'left', },
        // autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        // { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        // autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'nameoperationcode', Header: 'operationcode', accessor: 'nameoperationcode', width: 15, type: '',
              autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '25rem', text: 'Alege cod operatiune ..'},},
        { id: 'symboloperationcode', Header: 'symboloperationcode', accessor: 'symboloperationcode', width: 10, type: 'number', align: 'left', },
        { id: 'code', Header: 'code', accessor: 'code', width: 10, type: 'number', align: 'left', },
        { id: 'namefeloperationcode', Header: 'fel', accessor: 'namefeloperationcode', width: 10, type: 'text',align: 'left', 
              autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '25rem', text: 'Alege fel (suma/luna, detail/data) ..'},},
        // { id: 'symbolfeloperationcode', Header: 'symbolfeloperationcode', accessor: 'symbolfeloperationcode', width: 10, type: 'number', align: 'right', },
      ]
    },
    {
      Header: 'db info', columns: [     
      { id: 'operationcodespecificraportid', Header: 'operationcodespecificraportid', accessor: 'operationcodespecificraportid', width: 30, type: 'number', align: 'right',  },
      { id: 'operationcodeid', Header: 'operationcodeid', accessor: 'operationcodeid', width: 30, type: 'number', align: 'right',  },
      { id: 'feloperationcodeid', Header: 'feloperationcodeid', accessor: 'feloperationcodeid', width: 30, type: 'number', align: 'right',  },
      ],
    },
])

