import React from 'react'
import { Link } from 'react-router-dom' 

import TextIcon from '../../../../static/svgr/icontext'
import { HomeColumnStyle, MenuCenterStyle, Menuitem } from '../../../../styles/styles/headerstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params}) => {
    const visible = true
    return (
        <HomeColumnStyle>
            <MenuCenterStyle><Menuitem>
                {visible ?
                    <Link to='/'> 
                        <b><TextIcon params = {{ text: `home`, title:'home', 
                            width: '60', 
                            fill: theme.theme_main.backgroundTextMenuUnterminated, 
                            color: theme.theme_main.headercolor}} /></b>
                    </Link> : <> </> }
            </Menuitem></MenuCenterStyle>
        </HomeColumnStyle>
    )
}
