/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpssubscriptionInput = {|
  clientMutationId?: ?string,
  pSubscriptionid: number,
  pPatientid: number,
  pDate: any,
  pTherapistid: number,
  pTherapypriceid: number,
  pTherapyid: number,
  pTherapy2Id: number,
  pTherapytypeid: number,
  pTimeid: number,
  pTime2Id: number,
  pTime: number,
  pPrice: number,
  pCollectionid: number,
  pName: string,
  pNtherapysession: number,
  pNtherapysession1: number,
  pMofntherapysession: number,
  pNbmonths: number,
  pFinalizat: boolean,
  pSubscriptionmodeid: number,
  pDescription: string,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpssubscriptionInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpssubscription: ?{|
    +subscription: ?{|
      +finalizat: boolean,
      +mofntherapysession: number,
      +name: string,
      +nbmonths: number,
      +ntherapysession: number,
      +ntherapysession1: ?number,
      +subscriptionid: any,
      +therapysessionid: number,
      +time: string,
      +time2Id: ?number,
      +timeid: number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpssubscriptionInput!
) {
  fnUpssubscription(input: $input) {
    subscription {
      finalizat
      mofntherapysession
      name
      nbmonths
      ntherapysession
      ntherapysession1
      subscriptionid
      therapysessionid
      time
      time2Id
      timeid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpssubscriptionPayload",
    "kind": "LinkedField",
    "name": "fnUpssubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mofntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nbmonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ntherapysession1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapysessionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "time2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bcf8e5faa61b97733e90706bfd632d2e",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpssubscriptionInput!\n) {\n  fnUpssubscription(input: $input) {\n    subscription {\n      finalizat\n      mofntherapysession\n      name\n      nbmonths\n      ntherapysession\n      ntherapysession1\n      subscriptionid\n      therapysessionid\n      time\n      time2Id\n      timeid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4117872d9b481e991185663ea1955cc7';

module.exports = node;
