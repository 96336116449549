import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare6, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabilmanagement = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0) 
        : false
    const visible = isresponsabilmanagement && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuAdministrare6>
                {
                    visible ?
                        <> 
                            <Link to='/extrascont'>
                                <TextIcon params = {{ text: 'extras cont[15]', title: 'extras cont', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 12/31, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/codificareextrascont'>
                                <TextIcon params = {{ text: 'codificare extras cont[015]', title: 'codificare extras cont', 
                                    width: theme.theme_main.widthTextIconAdminMenu* 19/31, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare6>
        </>
    )
}

