import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelroomInput!)
{
  fnDelroom(input: $input) {
    room {
      etaj
      nameEn
      nameRo
      roomid
      symbol
    }
  }
}
`
