
export default [
    [
        {field: 'therapyid', value: 'therapyid'},
        {field: 'nameRo', value: 'nameRo'},
        {field: 'nameEn', value: 'nameEn'},
        {field: 'symbol', value: 'symbol'},
        {field: 'shortsymbol', value: 'shortsymbol'},
        {field: 'description', value: 'description'},
        {field: 'norder', value: 'norder'},
        {field: 'activ', value: 'activ'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

