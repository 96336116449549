// import React from 'react'

export default [
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'Roluri',
      columns: [
        { id: 'denumire', Header: 'denumire', accessor: 'denumire', width: 30,},
        { id: 'simbol', Header: 'simbol', accessor: 'simbol', width: 30,},
        { id: 'observatie', Header: 'observatie', accessor: 'observatie', width: 30,},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'rolid', Header: 'rolid', accessor: 'rolid', width: 30,  },
  ],
},
]
