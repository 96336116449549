
export default [
    {
        therapistpatientid: {field: 'therapistpatientid', values: ['', '', 'therapistpatientid'], width: 10, isLastRow : false},
        therapistid: {field: 'therapistid', values: ['', '', 'therapistid'], width: 10, isLastRow : false},
        patientid: {field: 'patientid', values: ['', '', 'patientid'], width: 10, isLastRow : false},
        firstname: {field: 'firstname', values: ['', '', 'firstname'], width: 10, isLastRow : false},
        lastname: {field: 'lastname', values: ['', '', 'lastname'], width: 10, isLastRow : false},
        extensive: {field: 'extensive', values: ['', '', 'extensive'], width: 10, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        // code: {field: 'code', values: ['', '', 'code'], width: 10, isLastRow : false},
        // phone: {field: 'phone', values: ['', '', 'phone'], width: 10, isLastRow : false},
        // email: {field: 'email', values: ['', '', 'email'], width: 10, isLastRow : false},
        // cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
        // percenttherapists: {field: 'percenttherapists', values: ['', '', 'percenttherapists'], width: 10, isLastRow : false},
        firstnamepatient: {field: 'firstnamepatient', values: ['', '', 'firstnamepatient'], width: 10, isLastRow : false},
        lastnamepatient: {field: 'lastnamepatient', values: ['', '', 'lastnamepatient'], width: 10, isLastRow : false},
        extensivepatient: {field: 'extensivepatient', values: ['', '', 'extensivepatient'], width: 10, isLastRow : false},
    }, 
]

// birthyearpatient
// weightpatient
// heightpatient
// phonepatient
// emailpatient
// cnppatient