import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuAdministrare4, } from '../../../../styles/styles/adminstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabilmanagement = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0) 
        : false
    const visible = isresponsabilmanagement && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuAdministrare4>
                {
                    visible ?
                        <> 
                            <Link to='/management'>
                                <TextIcon params = {{ text: visible ? 'management[13]'   : '', title: 'management', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 4/9, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                            <Link to='/managementdetail'>
                                <TextIcon params = {{ text: visible ? 'mngment op. detail[14]'   : '', title: 'cash flow', 
                                    width: theme.theme_main.widthTextIconAdminMenu * 5/9, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare4>
        </>
    )
}

