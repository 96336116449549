
export default [
    {
        therapistcollectionid: {field: 'therapistcollectionid', values: ['', '', 'therapistcollectionid'], width: 10, isLastRow : false},
        therapistid: {field: 'therapistid', values: ['', '', 'therapistid'], width: 10, isLastRow : false},
        collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
        firstname: {field: 'firstname', values: ['', '', 'firstname'], width: 10, isLastRow : false},
        lastname: {field: 'lastname', values: ['', '', 'lastname'], width: 10, isLastRow : false},
        extensive: {field: 'extensive', values: ['', '', 'extensive'], width: 10, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        // code: {field: 'code', values: ['', '', 'code'], width: 10, isLastRow : false},
        // phone: {field: 'phone', values: ['', '', 'phone'], width: 10, isLastRow : false},
        // email: {field: 'email', values: ['', '', 'email'], width: 10, isLastRow : false},
        // cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
        // percenttherapists: {field: 'percenttherapists', values: ['', '', 'percenttherapists'], width: 10, isLastRow : false},
        nameRo: {field: 'nameRo', values: ['', '', 'nameRo'], width: 10, isLastRow : false},
        nameEn: {field: 'nameEn', values: ['', '', 'nameEn'], width: 10, isLastRow : false},
        symbolcollections: {field: 'symbolcollections', values: ['', '', 'symbolcollections'], width: 10, isLastRow : false},
    }, 
]
