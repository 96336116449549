import * as pdfstyles from '../../../../uioscar/pdf/pdflib/pdfstyles'

export default () => {   

    const headertableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }
    const bodytableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '10',
    }

    return [
        {
            day: {field: 'day', values: ['ziua'], width: 6, isLastRow : false, ...pdfstyles.styleLeft({headertableCell, bodytableCell, }), },
            month: {field: 'month', values: ['luna'], width: 4, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            monthname: {field: 'monthname', values: ['luna'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            year: {field: 'year', values: ['an'], width: 4, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            date: {field: 'data', values: ['data'], width: 8, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), textAlign:'left', },
            ncode: {field: 'ncode', values: ['cod'], width: 4, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            code: {field: 'code', values: ['cod'], width: 16, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), textAlign:'left', },
            symbol: {field: 'symbol', values: ['simbol'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), textAlign:'left', },
            // collectionid: {field: 'collectionid', values: ['collectionid'], width: 4, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            collection: {field: 'collection', values: ['fel'], width: 10, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), textAlign:'left', },
            percent: {field: 'percent', values: ['procent'], width: 6, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            value: {field: 'value', values: ['valoare'], width: 8, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            therapistvalue: {field: 'therapistvalue', values: ['din care terapeut'], width: 14, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            // level: {field: 'level', values: ['level'], width: 4, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
        }, 
    ]
}
