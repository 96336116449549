/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdtherapistpatientInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pTherapistpatientid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdtherapistpatientInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdtherapistpatient: ?{|
    +therapistpatient: ?{|
      +therapistpatientid: any,
      +therapistid: number,
      +patientid: number,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdtherapistpatientInput!
) {
  fnUpdtherapistpatient(input: $input) {
    therapistpatient {
      therapistpatientid
      therapistid
      patientid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdtherapistpatientPayload",
    "kind": "LinkedField",
    "name": "fnUpdtherapistpatient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapistpatient",
        "kind": "LinkedField",
        "name": "therapistpatient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistpatientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b248920b813ce374e5a7f658c7f91bb1",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdtherapistpatientInput!\n) {\n  fnUpdtherapistpatient(input: $input) {\n    therapistpatient {\n      therapistpatientid\n      therapistid\n      patientid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39113a841192a3961e263678590d4de6';

module.exports = node;
