import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdtherapyInput!)
{
  fnUpdtherapy(input: $input) {
    therapy {
      description
      nameEn
      nameRo
      norder
      symbol
      shortsymbol
      therapyid
      activ
    }
  }
}
`

