import React from 'react'
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes
} from "react-html-parser"
// import Button from "@material-ui/core/Button"
// import { btnstyleSuccess, btnstyleSend} from '../../styles/registration/style'

export default (html) => {

    const transform = (node, index) => {
    // return null to block certain elements
    // don't allow <span> elements
    // if (node.type === "tag" && node.name === "span") {
    //     return null
    // }

    // Transform <ul> into <ol>
    // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
    if (node.type === "tag" && node.name === "ul") {
        node.name = "ol"
        return convertNodeToElement(node, index, transform)
    }

    // return an <i> element for every <b>
    // a key must be included for all elements
    if (node.type === "tag" && node.name === "b") {
        return <strong key={`childrenb`}>{processNodes(node.children, transform)}</strong>
    }

    // all links must open in a new window
    if (node.type === "tag" && node.name === "a") {
        node.attribs.target = "_blank"
        // console.log(node)
        // console.log(index)
        return convertNodeToElement(node, index, transform)
    }

    }

    const options = {
    decodeEntities: true,
    transform
    }

    return(
        <>
            {ReactHtmlParser(html, options)}
        </>)
    }
    