
export default [
    [
        // {field: 'month', value: 'month'},
        // {field: 'monthname', value: 'monthname'},
        // {field: 'year', value: 'year'},
        {field: 'code', value: 'Cod'},
        // {field: 'symbol', value: 'symbol'},
        {field: 'collection', value: 'Mod Incasare'},
        {field: 'percent', value: 'Procent'},
        // {field: 'nbr', value: 'nbr'},
        // {field: 'collectionid', value: 'collectionid'},
        {field: 'zi01', value: '1'},
        {field: 'pzi01', value: '%'},
        {field: 'zi02', value: '2'},
        {field: 'pzi02', value: '%'},
        {field: 'zi03', value: '3'},
        {field: 'pzi03', value: '%'},
        {field: 'zi04', value: '4'},
        {field: 'pzi04', value: '%'},
        {field: 'zi05', value: '5'},
        {field: 'pzi05', value: '%'},
        {field: 'zi06', value: '6'},
        {field: 'pzi06', value: '%'},
        {field: 'zi07', value: '7'},
        {field: 'pzi07', value: '%'},
        {field: 'zi08', value: '8'},
        {field: 'pzi08', value: '%'},
        {field: 'zi09', value: '9'},
        {field: 'pzi09', value: '%'},
        {field: 'zi10', value: '10'},
        {field: 'pzi10', value: '%'},

        {field: 'zi11', value: '11'},
        {field: 'pzi11', value: '%'},
        {field: 'zi12', value: '12'},
        {field: 'pzi12', value: '%'},
        {field: 'zi13', value: '13'},
        {field: 'pzi13', value: '%'},
        {field: 'zi14', value: '14'},
        {field: 'pzi14', value: '%'},
        {field: 'zi15', value: '15'},
        {field: 'pzi15', value: '%'},
        {field: 'zi16', value: '16'},
        {field: 'pzi16', value: '%'},
        {field: 'zi17', value: '17'},
        {field: 'pzi17', value: '%'},
        {field: 'zi18', value: '18'},
        {field: 'pzi18', value: '%'},
        {field: 'zi19', value: '19'},
        {field: 'pzi19', value: '%'},
        {field: 'zi20', value: '20'},
        {field: 'pzi20', value: '%'},

        {field: 'zi21', value: '21'},
        {field: 'pzi21', value: '%'},
        {field: 'zi22', value: '22'},
        {field: 'pzi22', value: '%'},
        {field: 'zi23', value: '23'},
        {field: 'pzi23', value: '%'},
        {field: 'zi24', value: '24'},
        {field: 'pzi24', value: '%'},
        {field: 'zi25', value: '25'},
        {field: 'pzi25', value: '%'},
        {field: 'zi26', value: '26'},
        {field: 'pzi26', value: '%'},
        {field: 'zi27', value: '27'},
        {field: 'pzi27', value: '%'},
        {field: 'zi28', value: '28'},
        {field: 'pzi28', value: '%'},
        {field: 'zi29', value: '29'},
        {field: 'pzi29', value: '%'},
        {field: 'zi30', value: '30'},
        {field: 'pzi30', value: '%'},
        {field: 'zi31', value: '31'},
        {field: 'pzi31', value: '%'},
        
        {field: 'total', value: 'Total Incasari'},
        {field: 'totalcode', value: 'Grand Total'},
        {field: 'totaltherapist', value: 'Total Procent'},
        {field: 'totaltherapistcode', value: 'Grand Total Procent'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

/*
// month
// year
// monthname
*/
