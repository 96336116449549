import React from 'react'

import {CompanyColumnStyle, DateColumnStyle, UsermodelatColumnStyle, VersionColumnStyle, DBColumnStyle} from '../../../styles/styles/footerstyle'
import packageJson from '../../../../package.json'
import enummapps from '../../../enums/mapps'
import company from '../../../static/company/company'

export default ({params}) => {
    // const {utilizatorOscar: {utilizatorlogin: {modeleaza, }, utilizatormodelat: { isauthenticated, username: usernamemodelat, } }, } = params
    const {utilizatorOscar: {utilizatorlogin: {username}, }, } = params
    const { appid, setappid} = params
    const idservers = [
        {"node":{"idserverid":"7","denumire":"3600","simbol":"portgraphQL"}},
        {"node":{"idserverid":"8","denumire":"postgraphile","simbol":"graphQL"}},
        {"node":{"idserverid":"1","denumire":"oscar2dezv.infofer.ro","simbol":"host"}},
        {"node":{"idserverid":"4","denumire":"[ dezvoltare ]","simbol":"cluster"}},
        {"node":{"idserverid":"2","denumire":"172.19.96.110","simbol":"hostIP"}},
        {"node":{"idserverid":"5","denumire":"utilizatori","simbol":"DB"}},
        {"node":{"idserverid":"3","denumire":"5434","simbol":"port"}},
        {"node":{"idserverid":"6","denumire":"dezvoltare5434","simbol":"passwd"}},
        {"node":{"idserverid":"9","denumire":"WWoriginal","simbol":"clona"}},
        {"node":{"idserverid":"10","denumire":"dezvoltare:5434","simbol":"serverDB"}},
        {"node":{"idserverid":"0","denumire":"03-10-2020","simbol":"datainstantiere"}},
        {"node":{"idserverid":"11","denumire":"3","simbol":"appid"}}
    ].map(edge => edge.node)
    // console.log(`[footermocha] - idservers: ${JSON.stringify(idservers)}` )
        
    const idserverelem = (elem) =>  idservers.filter(item => item.simbol === elem)
    const idserveritem = (elem) =>  elem.length > 0 ? elem[0].denumire: ''
    const host = idserveritem(idserverelem('host'))
    const hostIP = idserveritem(idserverelem('hostIP'))
    const port = idserveritem(idserverelem('port'))
    const cluster = idserveritem(idserverelem('cluster'))
    const DB = idserveritem(idserverelem('DB'))
    const felclona = idserveritem(idserverelem('clona'))
    // const passwd = idserveritem(idserverelem('passwd'))
    const portgraphQL = idserveritem(idserverelem('portgraphQL'))
    const _appid = appid === enummapps.mhappyworld.key ? appid : parseInt(idserveritem(idserverelem('appid')))

    React.useEffect(() => {
        // console.log(`[footer] - gappid: ${appid}: ${typeof(appid)}, _appid[DB]: ${_appid}: ${typeof(_appid)}, enummapps.mhappyworld.key: ${enummapps.mhappyworld.key} ${typeof(enummapps.mhappyworld.key)}, enummapps.mapps.key: ${enummapps.mapps.key} ${typeof(enummapps.mapps.key)}` )
        if(appid === enummapps.mapps.key) setappid(parseInt(_appid))
    },
    [_appid, appid, setappid])      

    // console.log(`[footermocha] company: ${company}`)
    return(
    <>
        <CompanyColumnStyle><span>{company}</span></CompanyColumnStyle>
        {/* <span>FAQ</span> */}
        <>
            <DBColumnStyle>
                <span>db: [<b>{felclona}</b>/{hostIP}:{port}] <b>{host}:{cluster}@{DB}:{portgraphQL}</b>, appid:<b>{_appid}</b> </span>
            </DBColumnStyle>
        </>

        <UsermodelatColumnStyle><span>{`user: ${username}`} </span></UsermodelatColumnStyle>
        {/* <UsermodelatColumnStyle><span>{`user: ${username} ${modeleaza ? `/: ${usernamemodelat}`: ''}`} </span></UsermodelatColumnStyle> */}

        <VersionColumnStyle>
            <span>{packageJson.status}: </span> 
            <span>{packageJson.version} </span>
        </VersionColumnStyle>
        <DateColumnStyle>
            <span>{packageJson.date} </span>
        </DateColumnStyle>
    </>
    )
}
