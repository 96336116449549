import React, { useState } from 'react'

import AddIcon from '@material-ui/icons/Add'
import Autocomplete from './AutoComplete'
import Button from '@material-ui/core/Button'
import ConfigReportIcon from '@material-ui/icons/PermDataSetting'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Draggable from 'react-draggable';
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types'
import RadioButtonInput from './radioButtonInput'
import ReactHtmlParser from '../reactHtmlParser'
import SearchIcon from '@material-ui/icons/Search'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Tree from '../unitatetrees/tree'
import invalidcolumns from './invalidcolumns'
import invalidrequiredcolumns from './invalidrequiredcolumns'

// import InputLabel from '@material-ui/core/InputLabel'

// import InputLabel from '@material-ui/core/InputLabel'








// culegere date single page
//     single registration
//     multiple registration
// culegere date multiple page - single registration
//     - se completeaza in script initiall.js array [columns, ...nextpagescolumns]  
//        - columns: pentru prima pagina
//        - nextpagescolumns: pentru paginile urmatoare
// fullWidth - controlul TextField pe intraga latime a paginii de dialog, 
// fullScreenDialog - pe intreagul browser, 
// withinput - true - controale inpul, false - controale TextField
// prefix, sufix, - texte (inclusiv tag-uri HTML inaint si dupa contrul TextField/input)
// handlenextPage, handleprevPage - evenimentele care se apeleaza dupa butonul nexpPage/prevpage si care sunt returnate in pagina apelanta


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const AddDialog = props => {
  const { addHandler, nonApiFields } = props
  const [open, setOpen] = React.useState(false)
  const { initialAddDialog, validation, dbtreevdata, fullScreenDialog, handlenextPage, handleprevPage, withinput, } = nonApiFields
  // console.log(`[tablelib.addDialog] - initialAddDialog: ${JSON.stringify(initialAddDialog)}`)
  const { AddDialogtitle: AddDialogtitle1, SearchDialogtitle, ConfigReportDialogtitle, columns, initialdata, nextpagescolumns} = initialAddDialog
  const isSearch = SearchDialogtitle !== undefined
  const isConfigReport = ConfigReportDialogtitle !== undefined
  // console.log(`[tablelib.addDialog] - {isSearch, AddDialogtitle1, SearchDialogtitle}: ${JSON.stringify({isSearch, AddDialogtitle1, SearchDialogtitle})}`)
  // console.log(`[tablelib.addDialog] - {isSearch, isConfigReport, AddDialogtitle1, SearchDialogtitle, SearchDialogtitle}: ${JSON.stringify({isSearch, isConfigReport, AddDialogtitle1, SearchDialogtitle, ConfigReportDialogtitle})}`)
  const AddDialogtitle = isConfigReport ? ConfigReportDialogtitle 
    : isSearch ? SearchDialogtitle 
    : AddDialogtitle1
  // console.log(`[tablelib.addDialog] - {AddDialogtitle}: ${JSON.stringify({AddDialogtitle})}`)
  // const { SearchDialogtitle, columns, initialdata, nextpagescolumns} = searchAddDialog
  const allcolumns = nextpagescolumns  !== undefined ? [columns, ...nextpagescolumns] : [columns]
  const [data, setData] = useState(initialdata)

  // console.log(`[tablelib.addDialog] - dbtreevdata: ${JSON.stringify(dbtreevdata)}`)
  const [columnsState, setcolumnsState] = React.useState(0)
  const [switchState, setSwitchState] = React.useState({
    addMultiple: false ,
    addMultiplePage: allcolumns.length > 1 ,
  })

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked })
  }

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    resetSwitch()
  }

  const handleprevpage = () => {
    // console.log(`[tablelib.AddDialog.handleprevpage] - data: ${JSON.stringify(data)}, Object.entries(data): ${JSON.stringify(Object.entries(data))}`)
    if (validation !== undefined && invalidcolumns(data, validation).length > 0)
      // alert(`date incorecte - `)
       alert(`date incorecte - : ${JSON.stringify(invalidcolumns(data, validation).map(([key, value]) => (`${key}: ${value}`)))}`)
    else if(invalidrequiredcolumns(allcolumns, data).length !== 0)  
      alert(`date incorecte - campuri obligatorii: ${JSON.stringify(invalidrequiredcolumns(allcolumns, data).map(item => item.field))}`)
    else {
      // console.log(`[tablelib.AddDialog.handleprevpage.else] - switchState.addMultiplePage: ${switchState.addMultiplePage}`)
      if (switchState.addMultiplePage) 
        if(columnsState > 0 ) {
          setcolumnsState(columnsState-1) // pagina precedenta
          if(handleprevPage !== undefined) handleprevPage(columnsState-1)
        }
        else{
          // handleAdd() // finalizat si ultima pagina => trimite la baza de date
          setOpen(false)
        }
      // else { 
      //   //switchState.addMultiple
      //   handleAdd()
      //   switchState.addMultiple ? setOpen(true) : setOpen(false) 
      // }
    }
  }
  const handlenextpage = () => {
    // console.log(`[tablelib.AddDialog.handlenextpage] - data: ${JSON.stringify(data)}, Object.entries(data): ${JSON.stringify(Object.entries(data))}`)
    if (validation !== undefined && invalidcolumns(data, validation).length > 0)
      // alert(`date incorecte - `)
       alert(`date incorecte - : ${JSON.stringify(invalidcolumns(data, validation).map(([key, value]) => (`${key}: ${value}`)))}`)
    else if(invalidrequiredcolumns(allcolumns, data).length !== 0)  
      alert(`date incorecte - campuri obligatorii: ${JSON.stringify(invalidrequiredcolumns(allcolumns, data).map(item => item.field))}`)
    else {
      // console.log(`[tablelib.AddDialog.handlenextpage.else] - switchState.addMultiplePage: ${switchState.addMultiplePage}`)
      if (switchState.addMultiplePage) 
        if(columnsState < allcolumns.length-1 ) {
          setcolumnsState(columnsState+1) // pagina urmatoare
          if(handlenextPage !== undefined) handlenextPage(columnsState+1)
        }
        else{
          handleAdd() // finalizat si ultima pagina => trimite la baza de date
          setOpen(false)
        }
      else { 
        //switchState.addMultiple
        handleAdd()
        switchState.addMultiple ? setOpen(true) : setOpen(false) 
      }
    }
  }
  const handleAdd = () => {
    // console.log(`[tablelib.AddDialog.handleAdd] - data: ${JSON.stringify(data)}`)
    addHandler(data)
    if(!isSearch && !isConfigReport)
      setData(initialdata)
  }

  const onsetdata = (name, value) => {
    // value - este intreg item unitate selectat
    // console.log(`[tablelib.AddDialog.onsetdata] - name: ${name}, value: ${JSON.stringify(value)}`)
    setData({ ...data, [name]: value.unitateid, unitate: value.denumire, simbolunitate: value.simbol })
  }

  const handleChange = name => ({ target: { value } }) => {
    setData({ ...data, [name]: value })
  }

  const onChangeAutocomplete = (name, nameid, newValue) => {
    setData({ ...data, [nameid]: newValue[newValue['id']], [name]: newValue[name] })
  }
  // console.log(`[tablelib.AddDialog] - data: ${JSON.stringify(data)}`)

  const onsetcells = (value, accessor) => {
    // console.log(`[tablelib.AddDialog.onsetcells] - {value, accessor}: ${JSON.stringify({value, accessor})}`)
    setData({ ...data, [accessor]: value })
    // setData({ ...data, [accessor]: !data[accessor] })
}

  return (
    <div>
      <Tooltip title={isSearch ? "Cauta" : isConfigReport ? "configurare raport" : "Add" }>
        <IconButton aria-label="add" onClick={handleClickOpen}>
          {
            isSearch ? <SearchIcon /> : isConfigReport ? <ConfigReportIcon/> : <AddIcon />  
          }
        </IconButton>
      </Tooltip>
      <Dialog fullScreen= {fullScreenDialog !== undefined ? fullScreenDialog : false}
        open={open}
        onClose={handleClose}
        scroll='paper'
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">{AddDialogtitle[0]}</DialogTitle>
        {
          switchState.addMultiplePage && allcolumns.length-1 !== columnsState ? <DialogTitle id="form-dialog-title"> pagina {columnsState} din {allcolumns.length}</DialogTitle> : <></>
        }
        <DialogContent>
          <DialogContentText>{AddDialogtitle[1]}</DialogContentText>
          {
            allcolumns.map((_columns, index) => 
              _columns.map(item => {
                const _radiobuttondanu = item.radiobuttondanu !== undefined ? item.radiobuttondanu : false
                const required = item.required
                return(
                  item.show && columnsState === index ?
                    <React.Fragment key={item.header}> 
                      {
                        item.prefix !== undefined ? <span> {ReactHtmlParser(item.prefix)} </span> : <></>
                        // item.prefix !== undefined ? <InputLabel for={`addDialog${item.header}`}> {ReactHtmlParser(item.prefix)} </InputLabel> : <></>
                        // item.prefix !== undefined ? <DialogTitle> {item.prefix} </DialogTitle> : <></>
                      } 
                      {
                        item.autocomplete !== undefined ?
                          <Autocomplete  key={`addDialog${item.header}`} data={item.autocomplete.dbOptions} required = {required}
                            defaultValue={data.sectiune} width={item.autocomplete.widthautocomplete}
                            onChangeAutocomplete={newValue => onChangeAutocomplete(item.accessor, item.accessorid, newValue)} 
                            id = {item.accessor} text = {item.autocomplete.text} />
                        : 
                          _radiobuttondanu ? 
                            <React.Fragment key={`addDialog${item.header}`}>
                              <RadioButtonInput key={`RadioButtonInputda`} 
                                params={{node: {label:`${item.header}${index}`, accessor: item.accessor, value: data[item.accessor], text: 'da', defaultChecked: true, }}} 
                                events={{onset: onsetcells}} />
                              <RadioButtonInput key={`RadioButtonInputnu`} 
                                params={{node: {label:`${item.header}${index}`, accessor: item.accessor, value: data[item.accessor], text: 'nu', defaultChecked: false, }}} 
                                events={{onset: onsetcells}} />
                             {item.fullWidth ? <br /> : <></>}
                            </React.Fragment>
                        : item.tree !== undefined ?
                            <React.Fragment key={`addDialog${item.header}`}> 
                              <Tree dbdata = {{dbvdata: dbtreevdata}} events={{onsetdata: newValue => onsetdata(item.accessor, newValue)}} /> 
                            </React.Fragment>
                          : 
                          <React.Fragment key={item.header}>
                            {/* {item.type === 'date' ? <InputLabel key={`addDialogdate${item.header}`} required = {required}>{item.header}</InputLabel> : <></>}                */}
                            {
                              withinput !== undefined && withinput ?
                                <input key={`addDialog${item.header}`} id={`addDialog${item.header}`} margin="dense" label={`${item.type === 'date' ? '' : item.header}`}
                                  required = {required} type={item.type} fullWidth={item.fullWidth !== undefined ? item.fullWidth : true} value={data[item.accessor]} onChange={handleChange(item.accessor)}/>
                              :
                                <TextField key={`addDialog${item.header}`} id={`addDialog${item.header}`} margin="dense" label={`${item.type === 'date' ? '' : item.header}`}
                                  required = {required} type={item.type} fullWidth={item.fullWidth !== undefined ? item.fullWidth : true} value={data[item.accessor]} onChange={handleChange(item.accessor)}/>
                                }
                          </React.Fragment>
                      }
                      {
                        item.sufix !== undefined ? <span> {ReactHtmlParser(item.sufix)} </span> : <></>
                        // item.sufix !== undefined ? <DialogTitle> {item.sufix} </DialogTitle> : <></>
                      } 
                    </React.Fragment >
                  : <></>
                  )
                }
              )
            )
          }
        </DialogContent>
        <DialogActions>
          <Tooltip title={switchState.addMultiplePage ? "Add multiple pages" : "Add multiple"}>
            {
              switchState.addMultiplePage ?
              <Switch
                checked={switchState.addMultiplePage}
                value="addMultiplePage"
                color= "primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            :
              <Switch
                checked={switchState.addMultiple}
                onChange={handleSwitchChange('addMultiple')}
                color= "secondary"
                value="addMultiple"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          </Tooltip>
          <Button onClick={handleClose} color="primary">
            Renunta
          </Button>
          <Button onClick={handleprevpage} color="primary">
          {
            switchState.addMultiplePage && columnsState > 0 ? <span> {ReactHtmlParser('<u>pagina precedenta</u>')} </span> : <> </>
          }
          </Button>
          <Button onClick={handlenextpage} color="primary">
          {
            switchState.addMultiplePage && allcolumns.length-1 !== columnsState ? 
              <span> {ReactHtmlParser('<u>pagina urmatoare</u>')} </span> 
              : <span> {ReactHtmlParser(isSearch ? '<u>Cauta</u>' : isConfigReport ? '<u>salveaza configurare raport</u>' : '<u>salveaza in baza de date</u>')} </span>
          }
          </Button>            
        </DialogActions>
      </Dialog>
    </div>
  )
}

AddDialog.propTypes = {
  addHandler: PropTypes.func.isRequired,
}

export default AddDialog
