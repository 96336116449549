/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDeloperationcodeInput = {|
  clientMutationId?: ?string,
  pOperationcodeid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDeloperationcodeInput
|};
export type mutationDelMutationResponse = {|
  +fnDeloperationcode: ?{|
    +operationcode: ?{|
      +code: ?string,
      +name: string,
      +norder: number,
      +operationcodeid: any,
      +operationkindofid: number,
      +symbol: ?string,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDeloperationcodeInput!
) {
  fnDeloperationcode(input: $input) {
    operationcode {
      code
      name
      norder
      operationcodeid
      operationkindofid
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDeloperationcodePayload",
    "kind": "LinkedField",
    "name": "fnDeloperationcode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Operationcode",
        "kind": "LinkedField",
        "name": "operationcode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationkindofid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b84c60da1d097a396218f13503a6e3cb",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDeloperationcodeInput!\n) {\n  fnDeloperationcode(input: $input) {\n    operationcode {\n      code\n      name\n      norder\n      operationcodeid\n      operationkindofid\n      symbol\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd17dfe2c88cfc1c903cdd3f0f04e4e9';

module.exports = node;
