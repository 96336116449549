import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdoperationkindofInput!)
{
  fnUpdoperationkindof(input: $input) {
    operationkindof {
      name
      norder
      operationkindofid
      symbol
      kindofid
    }
  }
}
`

