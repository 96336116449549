import React from 'react'

export default ({dbOptions1, dbOptions9,}) => ([
  {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', width: 7, // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter to build the expander.
      <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    ), 
  },      
  {
    Header: 'saptamana',
    columns: [
      { id: 'selyear', Header: 'an', accessor: 'selyear', width: 10, type: '', align: 'left',
      autocomplete: {autocomplete: true, dbOptions: dbOptions9, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
      // { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
      // autocomplete: {autocomplete: true, dbOptions: dbOptions10, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
      { id: 'selweek', Header: 'saptamana', accessor: 'week', width: 10, type: 'number', align: 'left', },
      // autocomplete: {autocomplete: true, dbOptions: dbOptions11, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
      { id: 'firstdayweek', Header: 'de la', accessor: 'firstdayweek', width: 15, type: 'number', align: 'left', },
      { id: 'lastdayweek', Header: 'pana la', accessor: 'lastdayweek', width: 15, type: 'number', align: 'left', },
      // { id: 'year', Header: 'year', accessor: 'year', width: 25, type: 'number',},
      // { id: 'month', Header: 'month', accessor: 'month', width: 25, type: 'number',},
      // { id: 'dow', Header: 'dow', accessor: 'dow', width: 25, type: 'number',},
      // { id: 'monthname', Header: 'monthname', accessor: 'monthname', width: 25, type: 'text',},
      // { id: 'shortmonthname', Header: 'shortmonthname', accessor: 'shortmonthname', width: 25, type: 'text',},
      { id: 'date', Header: 'date', accessor: 'date', width: 25, type: 'date',},
      { id: 'therapist', Header: 'therapist', accessor: 'therapist', width: 30, type: '', 
      autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '25rem', text: 'Alege terapeut ..'},},
]
  },  
// {
//     Header: 'date/room',
//     columns: [
//       { id: 'data', Header: 'll-dd', accessor: 'monthday', width: 18, type: 'date',},
//       { id: 'symbolroom', Header: 'sala', accessor: 'symbolroom', width: 12, type: 'number', align: 'right',},
//       // { id: 'nameRocalendar', Header: 'calendar', accessor: 'nameRocalendar', width: 15, type: 'number', align: 'center',},
//       // { id: 'terapeut', Header: 'fel', accessor: 'terapeut', width: 20, type: 'number',align: 'right', },
//       // { id: 'therapistsymbol', Header: 'therapist', accessor: 'therapistsymbol', width: 12, type: 'number',align: 'center', },
//     ]
//   },
  {
    Header: 'data', columns: [     
      { id: 'data', Header: 'll-dd', accessor: 'monthday', width: 18, type: 'date', align: 'left',},
      { id: 'symbolroom', Header: 'sala', accessor: 'symbolroom', width: 12, type: 'number', align: 'right',},
    ]
  },
  // {
  //   Header: '07', columns: [     
  //     { id: 'h0700', Header: ':00', accessor: 'h0700', width: 10, type: 'text', align: 'left',},
  //     { id: 'h0715', Header: ':15', accessor: 'h0715', width: 10, type: 'text', align: 'left',},
  //     { id: 'h0730', Header: ':30', accessor: 'h0730', width: 10, type: 'text', align: 'left',},
  //     { id: 'h0745', Header: ':45', accessor: 'h0745', width: 10, type: 'text', align: 'left',},
  //   ]
  // },
  {
    Header: '08', columns: [     
      { id: 'h0800', Header: ':00', accessor: 'h0800', width: 10, type: 'text', align: 'left',},
      { id: 'h0815', Header: ':15', accessor: 'h0815', width: 10, type: 'text', align: 'left',},
      { id: 'h0830', Header: ':30', accessor: 'h0830', width: 10, type: 'text', align: 'left',},
      { id: 'h0845', Header: ':45', accessor: 'h0845', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '09', columns: [     
      { id: 'h0900', Header: ':00', accessor: 'h0900', width: 10, type: 'text', align: 'left',},
      { id: 'h0915', Header: ':15', accessor: 'h0915', width: 10, type: 'text', align: 'left',},
      { id: 'h0930', Header: ':30', accessor: 'h0930', width: 10, type: 'text', align: 'left',},
      { id: 'h0945', Header: ':45', accessor: 'h0945', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '10', columns: [     
      { id: 'h1000', Header: ':00', accessor: 'h1000', width: 10, type: 'text', align: 'left',},
      { id: 'h1015', Header: ':15', accessor: 'h1015', width: 10, type: 'text', align: 'left',},
      { id: 'h1030', Header: ':30', accessor: 'h1030', width: 10, type: 'text', align: 'left',},
      { id: 'h1045', Header: ':45', accessor: 'h1045', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '11', columns: [     
      { id: 'h1100', Header: ':00', accessor: 'h1100', width: 10, type: 'text', align: 'left',},
      { id: 'h1115', Header: ':15', accessor: 'h1115', width: 10, type: 'text', align: 'left',},
      { id: 'h1130', Header: ':30', accessor: 'h1130', width: 10, type: 'text', align: 'left',},
      { id: 'h1145', Header: ':45', accessor: 'h1145', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '12', columns: [     
      { id: 'h1200', Header: ':00', accessor: 'h1200', width: 10, type: 'text', align: 'left',},
      { id: 'h1215', Header: ':15', accessor: 'h1215', width: 10, type: 'text', align: 'left',},
      { id: 'h1230', Header: ':30', accessor: 'h1230', width: 10, type: 'text', align: 'left',},
      { id: 'h1245', Header: ':45', accessor: 'h1245', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '13', columns: [     
      { id: 'h1300', Header: ':00', accessor: 'h1300', width: 10, type: 'text', align: 'left',},
      { id: 'h1315', Header: ':15', accessor: 'h1315', width: 10, type: 'text', align: 'left',},
      { id: 'h1330', Header: ':30', accessor: 'h1330', width: 10, type: 'text', align: 'left',},
      { id: 'h1345', Header: ':45', accessor: 'h1345', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '14', columns: [     
      { id: 'h1400', Header: ':00', accessor: 'h1400', width: 10, type: 'text', align: 'left',},
      { id: 'h1415', Header: ':15', accessor: 'h1415', width: 10, type: 'text', align: 'left',},
      { id: 'h1430', Header: ':30', accessor: 'h1430', width: 10, type: 'text', align: 'left',},
      { id: 'h1445', Header: ':45', accessor: 'h1445', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '15', columns: [     
      { id: 'h1500', Header: ':00', accessor: 'h1500', width: 10, type: 'text', align: 'left',},
      { id: 'h1515', Header: ':15', accessor: 'h1515', width: 10, type: 'text', align: 'left',},
      { id: 'h1530', Header: ':30', accessor: 'h1530', width: 10, type: 'text', align: 'left',},
      { id: 'h1545', Header: ':45', accessor: 'h1545', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '16', columns: [     
      { id: 'h1600', Header: ':00', accessor: 'h1600', width: 10, type: 'text', align: 'left',},
      { id: 'h1615', Header: ':15', accessor: 'h1615', width: 10, type: 'text', align: 'left',},
      { id: 'h1630', Header: ':30', accessor: 'h1630', width: 10, type: 'text', align: 'left',},
      { id: 'h1645', Header: ':45', accessor: 'h1645', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '17', columns: [     
      { id: 'h1700', Header: ':00', accessor: 'h1700', width: 10, type: 'text', align: 'left',},
      { id: 'h1715', Header: ':15', accessor: 'h1715', width: 10, type: 'text', align: 'left',},
      { id: 'h1730', Header: ':30', accessor: 'h1730', width: 10, type: 'text', align: 'left',},
      { id: 'h1745', Header: ':45', accessor: 'h1745', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '18', columns: [     
      { id: 'h1800', Header: ':00', accessor: 'h1800', width: 10, type: 'text', align: 'left',},
      { id: 'h1815', Header: ':15', accessor: 'h1815', width: 10, type: 'text', align: 'left',},
      { id: 'h1830', Header: ':30', accessor: 'h1830', width: 10, type: 'text', align: 'left',},
      { id: 'h1845', Header: ':45', accessor: 'h1845', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '19', columns: [     
      { id: 'h1900', Header: ':00', accessor: 'h1900', width: 10, type: 'text', align: 'left',},
      { id: 'h1915', Header: ':15', accessor: 'h1915', width: 10, type: 'text', align: 'left',},
      { id: 'h1930', Header: ':30', accessor: 'h1930', width: 10, type: 'text', align: 'left',},
      { id: 'h1945', Header: ':45', accessor: 'h1945', width: 10, type: 'text', align: 'left',},
    ]
  },
  {
    Header: '20', columns: [     
      { id: 'h2000', Header: ':00', accessor: 'h2000', width: 10, type: 'text', align: 'left',},
      { id: 'h2015', Header: ':15', accessor: 'h2015', width: 10, type: 'text', align: 'left',},
      // { id: 'h2030', Header: ':30', accessor: 'h2030', width: 10, type: 'text', align: 'left',},
      // { id: 'h2045', Header: ':45', accessor: 'h2045', width: 10, type: 'text', align: 'left',},
    ]
  },
  // {
  //   Header: '21', columns: [     
  //     { id: 'h2100', Header: ':00', accessor: 'h2100', width: 10, type: 'text', align: 'left',},
  //     { id: 'h2115', Header: ':15', accessor: 'h2115', width: 10, type: 'text', align: 'left',},
  //     { id: 'h2130', Header: ':30', accessor: 'h2130', width: 10, type: 'text', align: 'left',},
  //     { id: 'h2145', Header: ':45', accessor: 'h2145', width: 10, type: 'text', align: 'left',},
  //     ]
  // },
  {
    Header: 'db info', columns: [     
    { id: 'norder', Header: 'norder', accessor: 'norder', width: 20, type: 'text', align: 'right'},
    { id: 'roomid', Header: 'roomid', accessor: 'roomid', width: 30, type: 'number', align: 'right',},
    { id: 'calendarid', Header: 'calendarid', accessor: 'calendarid', width: 30, type: 'number', align: 'right',},
    { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 10, type: 'number',},
    // { id: 'hourid', Header: 'hourid', accessor: 'hourid', width: 30, type: 'text', align: 'left',},
    // { id: 'fromid', Header: 'fromid', accessor: 'fromid', width: 30, type: 'text', align: 'left',},
    // { id: 'toid', Header: 'toid', accessor: 'toid', width: 30, type: 'text', align: 'left',},
  ],
  },
])

