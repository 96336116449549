
export default [
    {
        extrascontcodificareid: {field: 'extrascontcodificareid', values: ['', '', 'extrascontcodificareid'], width: 10, isLastRow : false},
        key: {field: 'key', values: ['', '', 'key'], width: 10, isLastRow : false},
        operationcodeid: {field: 'operationcodeid', values: ['', '', 'operationcodeid'], width: 10, isLastRow : false},
        nameoperationcode: {field: 'nameoperationcode', values: ['', '', 'nameoperationcode'], width: 20, isLastRow : false},
        codeoperationcode: {field: 'codeoperationcode', values: ['', '', 'codeoperationcode'], width: 20, isLastRow : false},
        collectionid: {field: 'collectionid', values: ['', '', 'collectionid'], width: 10, isLastRow : false},
        symbolcollection: {field: 'symbolcollection', values: ['', '', 'symbolcollection'], width: 20, isLastRow : false},
    }, 
]

// 
// 
// 
// 
// symboloperationcode
// 
// norderoperationcode
// 
// nameRocollection
// nameEncollection
// 