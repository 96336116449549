/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelappInput = {|
  clientMutationId?: ?string,
  pAppid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelappInput
|};
export type mutationDelMutationResponse = {|
  +fnDelapp: ?{|
    +app: ?{|
      +simbol: ?string,
      +link: ?string,
      +appid: any,
      +denumire: string,
      +ordonare: number,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelappInput!
) {
  fnDelapp(input: $input) {
    app {
      simbol
      link
      appid
      denumire
      ordonare
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelappPayload",
    "kind": "LinkedField",
    "name": "fnDelapp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "app",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "link",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "denumire",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordonare",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83a3b95e8740dbbc009907f99a2b6a97",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelappInput!\n) {\n  fnDelapp(input: $input) {\n    app {\n      simbol\n      link\n      appid\n      denumire\n      ordonare\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '036f6538176fedbfdb3917b98fce27c7';

module.exports = node;
