import React from 'react'
import { Link } from 'react-router-dom' 

import TextIcon from '../../../../static/svgr/icontext'

import { Oscar1ColumnStyle, MenuCenterStyle, Menuitem } from '../../../../styles/styles/headerstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'
import enummapps from '../../../../enums/mapps'
import menuappOscars from '../../../../app/menuappOscars'
import fmenuapps from '../../../../app/menuapps'
import enumRols from '../../../../enums/rols'

export default ({params, events}) => {
    const {appid, menuorder, utilizatorOscar: {utilizatormodelat: { apps, menuapps, rols }} } = params
    const _menuapps = menuappOscars(appid, apps, fmenuapps(menuapps))
    const visible = _menuapps.length > menuorder
    // console.log(`[header.header.oscar1] - menuorder: ${menuorder}, apps: ${JSON.stringify(apps)}, apps.length: ${apps !== undefined ? apps.length : 0}`)
    // console.log(`[header.header.oscar1] - menuorder: ${menuorder}, _menuapps: ${JSON.stringify(_menuapps)}, _menuapps[menuorder]: ${_menuapps[menuorder]}, _menuapps.length: ${_menuapps !== undefined ? _menuapps.length : 0}`)

    const responsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false

    const texticon = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].app   : '' 
    const url = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].link   : '' 
    // console.log(`[header.header.oscar1] - menuorder: ${menuorder}, texticon: ${texticon}, url: ${url}`)
    
    return (
        <>
            <Oscar1ColumnStyle><MenuCenterStyle><Menuitem>
                {
                    visible ?
                        <> 
                            {appid === enummapps.mhappyworld.key ?
                                <a href={url}>
                                    <TextIcon params = {{ text: texticon, title: texticon, 
                                        width: theme.theme_main.withTextIconMenu, 
                                        fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                        color: theme.theme_main.headercolor}} />
                                </a>
                            : appid === enummapps.utilizatoriapp.key ?
                                <>
                                    {responsabilmanagement ? 
                                        <Link to='/cashflow'>
                                            <TextIcon params = {{ text: texticon, title: 'cash flow', 
                                                width: theme.theme_main.withTextIconMenu*0.75, 
                                                fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                                color: theme.theme_main.headercolor}} />
                                        </Link>
                                    : <></>
                                    }
                                </>
                            : 
                                <Link to='/oscar1'>
                                        <TextIcon params = {{ text: texticon, title: 'oscar1', 
                                            width: theme.theme_main.withTextIconMenu, 
                                            fill: theme.theme_main.backgroundTextMenuWorking, 
                                            color: theme.theme_main.headercolor}} />
                                </Link>}
                        </>
                    : <> </>
                }
            </Menuitem></MenuCenterStyle></Oscar1ColumnStyle>
        </>
    )
}