import { lighten, makeStyles } from '@material-ui/core/styles'
import { mdiDelete, mdiDeleteForever, mdiDrag, mdiRefresh, mdiSkipNext, mdiSkipPrevious } from '@mdi/js';

import Icon from '@mdi/react'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

// https://materialdesignicons.com/cdn/2.0.46/

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%', color: 'green'
  },
}))

const TableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected,
    addHandler, deleteHandler, deleteallHandler, refreshHandler, dragdbHandler, 
    nonApiFields = {}
  } = props
  const {visibles: {visibledeleteall, visibledragDB, visibleRefresh, visualAD, visiblenexprev, }, title, 
    importxlsfields, importxlsextrasconts, importxlsPOSconts, pg, ora, exportxlsfields, pdf, importsyncADxlsfields, 
    searchDialogComponent, configreportDialogComponent,
    dialogComponent: DialogComponent, externComponent: ExternComponent, ondbNext, ondbPrev, } = nonApiFields

  const {Readxlsfile } = importxlsfields !== undefined ? importxlsfields : {Readxlsfile: undefined}
  const {Readxlsfile: Readxlsfile1 } = importxlsextrasconts !== undefined ? importxlsextrasconts : {Readxlsfile1: undefined}
  const {Readxlsfile: Readxlsfile2 } = importxlsPOSconts !== undefined ? importxlsPOSconts : {Readxlsfile2: undefined}
  const {Pg } = pg !== undefined ? pg : {Pg: undefined}
  const {Ora } = ora !== undefined ? ora : {Ora: undefined}
  const {Toxlsfile } = exportxlsfields !== undefined ? exportxlsfields : {Toxlsfile: undefined}
  const {ToPdf } = pdf !== undefined ? pdf : {ToPdf: undefined}
  const {AddDialog: SearchDialog, onSearch, } = searchDialogComponent !== undefined ? searchDialogComponent : {SearchDialog: undefined}
  const {AddDialog: ConfigReportDialog, onConfigReport, } = configreportDialogComponent !== undefined ? configreportDialogComponent : {SearchDialog: undefined}
  // const {AddDialog: ConfigReportDialog, onSearch: onConfigReport, } = configreportDialogComponent !== undefined ? configreportDialogComponent : {ConfigReportDialog: undefined}

  // console.log(`[tableToolbar] - nonApiFields: `, nonApiFields)

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      { DialogComponent !== undefined ? <DialogComponent addHandler={addHandler} nonApiFields={nonApiFields} /> : <></>}
      { Readxlsfile !== undefined ? <Readxlsfile importxlsfields={importxlsfields} /> : <></>}
      { Readxlsfile1 !== undefined && importxlsextrasconts !== undefined ? <Readxlsfile1 importxlsfields={importxlsextrasconts} /> : <></>}
      { Readxlsfile2 !== undefined && importxlsPOSconts !== undefined ? <Readxlsfile2 importxlsfields={importxlsPOSconts} /> : <></>}
      { Pg !== undefined ? <Pg pg={pg} /> : <></>}
      { Ora !== undefined ? <Ora ora={ora} /> : <></>}
      { Toxlsfile !== undefined ? <Toxlsfile exportxlsfields={exportxlsfields} /> : <></>}
      { ToPdf !== undefined ? <ToPdf pdf={pdf} /> : <></>}
      { visualAD && Readxlsfile !== undefined && importsyncADxlsfields !== undefined ? <Readxlsfile importxlsfields={importsyncADxlsfields} /> : <></>}
      { ExternComponent !== undefined ? <ExternComponent nonApiFields={nonApiFields} /> : <></>}

      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {title}
        </Typography>
      )}
      {visiblenexprev? <Icon path={mdiSkipPrevious} title="prev" onClick={ondbPrev} size={1} horizontal vertical rotate={180} color="black"/> : <></>}
      {visiblenexprev? <Icon path={mdiSkipNext} title="next" onClick={ondbNext} size={1} horizontal vertical rotate={180} color="black"/> : <></>}

      {onConfigReport!== undefined? <ConfigReportDialog addHandler={nonApiFields.configreportDialogComponent.onConfigReport} 
        nonApiFields={{...nonApiFields, 
          initialAddDialog: nonApiFields.configreportDialogComponent.configreportAddDialog,
          handlenextPage: nonApiFields.configreportDialogComponent.handlenextPage, 
          handleprevPage: nonApiFields.configreportDialogComponent.handleprevPage
        }} /> : <></>}
      {onSearch!== undefined? <SearchDialog addHandler={nonApiFields.searchDialogComponent.onSearch} 
        nonApiFields={{...nonApiFields, 
        initialAddDialog: nonApiFields.searchDialogComponent.searchAddDialog,
        handlenextPage: nonApiFields.searchDialogComponent.handlenextPage, handleprevPage: nonApiFields.searchDialogComponent.handleprevPage
        }} /> : <></>}
      {visibleRefresh? <Icon path={mdiRefresh} title="refresh" onClick={refreshHandler} size={1} horizontal vertical rotate={180} color="black"/> : <></>}
      {numSelected > 0 ? <Icon path={mdiDelete} title="delete" onClick={deleteHandler} size={1} horizontal vertical rotate={180} color="black"/>
      : <>
          {visibledragDB ? <Icon path={mdiDrag} title="adauga in DB" onClick={dragdbHandler} size={1} horizontal vertical rotate={180} color="black"/>: <></>}
          {visibledeleteall ? <Icon path={mdiDeleteForever} title="delete all" onClick={deleteallHandler} size={1} horizontal vertical rotate={180} color="black"/> : <></>}
        </>
      }
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  addHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}

export default TableToolbar
