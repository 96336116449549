import React from 'react'
import Help from './help'

import Component from './page'
import ContentStyle from '../../../styles/styles/contentstyle'

export default ({data, params, events}) => {
    const {toggleHelp, } =  params

    return(
        <ContentStyle>
            { toggleHelp ? <Help params={params} /> : <></> }
            <Component params = {params} events={events} / >
        </ContentStyle>)
    }
        
