import React from 'react'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import query from './query'
import Component from './page'
import enumRols from '../../../enums/rols'

export default ({params, events}) => {
    // in scenariul modelarii de catre responsabilutilizatori utilizatorid din params - finalizat
    // in scenariul modelarii de catre utilizator utilizatorid: utilizatormodelatid din utilizatorOscar
    const {appid, dbkey, toggleHelp, utilizatorid, utilizatorOscar: {utilizatormodelat: {utilizatorid: utilizatormodelatid, rols}},  } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    const isresponsabilutilizatori = rols !== undefined ? (
        // rols.filter(item => parseInt(item.rolid) === enumRols.superadmin.key).length > 0 ||
        // rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0
        ) 
        : false
    // console.log(`[utilizatorModelats.component] - isresponsabilutilizatori: ${isresponsabilutilizatori}, utilizatorid: ${utilizatorid}, utilizatormodelatid: ${JSON.stringify(utilizatormodelatid)}, rols: ${JSON.stringify(rols)}`)
    const putilizatormodeleazaid= isresponsabilutilizatori ? (utilizatorid !== undefined ? utilizatorid : 0) : utilizatormodelatid
    // const utilizatormodeleazaid= isresponsabilutilizatori ? utilizatorid : utilizatormodelatid

    // onRefresh, refresh, -------------------------------------------------------------------------------
const [refresh, setrefresh] = React.useState(true)
const onRefresh = () => {
  const _refresh = !refresh
  setrefresh(_refresh) // refresh: !refresh // se modifica variabila refresh din query pentru a face refetch
}

    const variables = {pRefresh: refresh, putilizatormodeleazaid: putilizatormodeleazaid, pAppid: appid }
    // console.log(`[utilizatorModelats.component] - putilizatormodeleazaid: ${putilizatormodeleazaid}`)
    // console.log(`[utilizatorModelats.component] - {utilizatorid, isresponsabilutilizatori, rols}: ${JSON.stringify({utilizatorid, isresponsabilutilizatori, rols})}`)
    // console.log(`[utilizatorModelats.component] - utilizatorid: ${utilizatorid}, utilizatormodelatid: ${utilizatormodelatid}`)
    // console.log(`[utilizatorModelats.component] - variables: ${JSON.stringify(variables)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isresponsabilutilizatori:isresponsabilutilizatori, isDeveloper:isDeveloper}} /> : <></> }
        <>
            <QueryComponent query = {query} variables={variables} dbkey={dbkey} >
                { props => <Component dbdata={props} variables={variables} params = {{...params, utilizatormodeleazaid: putilizatormodeleazaid, onRefresh, refresh}} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
