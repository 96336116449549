import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddcalendarInput!)
{
  fnAddcalendar(input: $input) {
    calendar {
      calendarid
      nameEn
      nameRo
      symbol
    }
  }
}
`
