/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vcalendarfreeroomsessionsfunction: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +monthdayroomcalendarid: ?number,
        +date: ?any,
        +year: ?number,
        +month: ?number,
        +week: ?number,
        +day: ?number,
        +dow: ?number,
        +h0700: ?string,
        +h0715: ?string,
        +h0730: ?string,
        +h0745: ?string,
        +h0800: ?string,
        +h0815: ?string,
        +h0830: ?string,
        +h0845: ?string,
        +h0900: ?string,
        +h0915: ?string,
        +h0930: ?string,
        +h0945: ?string,
        +h1000: ?string,
        +h1015: ?string,
        +h1030: ?string,
        +h1045: ?string,
        +h1100: ?string,
        +h1115: ?string,
        +h1130: ?string,
        +h1145: ?string,
        +h1200: ?string,
        +h1215: ?string,
        +h1230: ?string,
        +h1245: ?string,
        +h1300: ?string,
        +h1315: ?string,
        +h1330: ?string,
        +h1345: ?string,
        +h1400: ?string,
        +h1415: ?string,
        +h1430: ?string,
        +h1445: ?string,
        +h1500: ?string,
        +h1515: ?string,
        +h1530: ?string,
        +h1545: ?string,
        +h1600: ?string,
        +h1615: ?string,
        +h1630: ?string,
        +h1645: ?string,
        +h1700: ?string,
        +h1715: ?string,
        +h1730: ?string,
        +h1745: ?string,
        +h1800: ?string,
        +h1815: ?string,
        +h1830: ?string,
        +h1845: ?string,
        +h1900: ?string,
        +h1915: ?string,
        +h1930: ?string,
        +h1945: ?string,
        +h2000: ?string,
        +h2015: ?string,
        +h2030: ?string,
        +h2045: ?string,
        +h2100: ?string,
        +h2115: ?string,
        +h2130: ?string,
        +h2145: ?string,
      |}
    |}>
  |},
  +vcalendarsessionsfunction: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +norder: ?number,
        +date: ?any,
        +terapeut: ?string,
        +therapistid: ?number,
        +therapistsymbol: ?string,
        +roomid: ?number,
        +calendarid: ?number,
        +symbolroom: ?string,
        +nameRocalendar: ?string,
        +month: ?number,
        +year: ?number,
        +monthname: ?string,
        +shortmonthname: ?string,
        +week: ?number,
        +dow: ?number,
        +doy: ?number,
        +day: ?number,
        +h0700: ?string,
        +h0715: ?string,
        +h0730: ?string,
        +h0745: ?string,
        +h0800: ?string,
        +h0815: ?string,
        +h0830: ?string,
        +h0845: ?string,
        +h0900: ?string,
        +h0915: ?string,
        +h0930: ?string,
        +h0945: ?string,
        +h1000: ?string,
        +h1015: ?string,
        +h1030: ?string,
        +h1045: ?string,
        +h1100: ?string,
        +h1115: ?string,
        +h1130: ?string,
        +h1145: ?string,
        +h1200: ?string,
        +h1215: ?string,
        +h1230: ?string,
        +h1245: ?string,
        +h1300: ?string,
        +h1315: ?string,
        +h1330: ?string,
        +h1345: ?string,
        +h1400: ?string,
        +h1415: ?string,
        +h1430: ?string,
        +h1445: ?string,
        +h1500: ?string,
        +h1515: ?string,
        +h1530: ?string,
        +h1545: ?string,
        +h1600: ?string,
        +h1615: ?string,
        +h1630: ?string,
        +h1645: ?string,
        +h1700: ?string,
        +h1715: ?string,
        +h1730: ?string,
        +h1745: ?string,
        +h1800: ?string,
        +h1815: ?string,
        +h1830: ?string,
        +h1845: ?string,
        +h1900: ?string,
        +h1915: ?string,
        +h1930: ?string,
        +h1945: ?string,
        +h2000: ?string,
        +h2015: ?string,
        +h2030: ?string,
        +h2045: ?string,
        +h2100: ?string,
        +h2115: ?string,
        +h2130: ?string,
        +h2145: ?string,
      |}
    |}>
  |},
  +vtherapysessionyears: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +year: ?any
      |}
    |}>
  |},
  +vpatients: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +patientid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
      |}
    |}>
  |},
  +vtherapistpatients: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +patientid: ?number,
        +firstnamepatient: ?string,
        +lastnamepatient: ?string,
        +extensivepatient: ?string,
      |}
    |}>
  |},
  +vtherapists: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapistid: ?any,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +symbol: ?string,
        +code: ?string,
        +calendarcss: ?string,
      |}
    |}>
  |},
  +vhours: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +hourid: ?any,
        +name: ?string,
        +symbol: ?string,
      |}
    |}>
  |},
  +vtherapisttherapyprices: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapisttherapypriceid: ?any,
        +therapistid: ?number,
        +firstname: ?string,
        +lastname: ?string,
        +symbol: ?string,
        +therapypriceid: ?number,
        +nameRotherapy: ?string,
        +therapytypeid: ?number,
        +time: ?string,
        +price: ?number,
        +nametherapyprice: ?string,
        +percent: ?any,
      |}
    |}>
  |},
  +vcalendars: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +calendarid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vrooms: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +roomid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vbusinesscollections: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +collectionid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vtherapies: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +therapyid: ?any,
        +nameRo: ?string,
      |}
    |}>
  |},
  +vsubscriptions: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +subscriptionid: ?any,
        +therapysessionid: ?number,
        +firstnamepatient: ?string,
        +lastnamepatient: ?string,
        +extensivepatient: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +extensive: ?string,
        +nametherapyprice: ?string,
        +nameRotherapy: ?string,
        +description: ?string,
        +subscriptioncount: ?any,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Variable",
  "name": "pTherapistid",
  "variableName": "pTherapistid"
},
v1 = {
  "kind": "Variable",
  "name": "pYear",
  "variableName": "pYear"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "week",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dow",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0700",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0715",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0730",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0745",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0800",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0815",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0830",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0845",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0900",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0915",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0930",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h0945",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1000",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1015",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1030",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1045",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1100",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1115",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1130",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1145",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1200",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1215",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1230",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1245",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1300",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1315",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1330",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1345",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1400",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1415",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1430",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1445",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1500",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1515",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1530",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1545",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1600",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1615",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1630",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1645",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1700",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1715",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1730",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1745",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1800",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1815",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1830",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1845",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1900",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1915",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1930",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h1945",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2000",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2015",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2030",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2045",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2100",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2115",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2130",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "h2145",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapistid",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomid",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarid",
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientid",
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensive",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstnamepatient",
  "storageKey": null
},
v76 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastnamepatient",
  "storageKey": null
},
v77 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensivepatient",
  "storageKey": null
},
v78 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v79 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRotherapy",
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nametherapyprice",
  "storageKey": null
},
v81 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRo",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "pDay"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "pIsresponsabilbusiness"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "pMonth"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "pTherapistid"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "pWeek"
    },
    {
      "defaultValue": 2021,
      "kind": "LocalArgument",
      "name": "pYear"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "pWeek",
          "variableName": "pWeek"
        },
        (v1/*: any*/)
      ],
      "concreteType": "VcalendarfreeroomsessionstypesConnection",
      "kind": "LinkedField",
      "name": "vcalendarfreeroomsessionsfunction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcalendarfreeroomsessionstypesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcalendarfreeroomsessionstype",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthdayroomcalendarid",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/),
                (v27/*: any*/),
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/),
                (v32/*: any*/),
                (v33/*: any*/),
                (v34/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v39/*: any*/),
                (v40/*: any*/),
                (v41/*: any*/),
                (v42/*: any*/),
                (v43/*: any*/),
                (v44/*: any*/),
                (v45/*: any*/),
                (v46/*: any*/),
                (v47/*: any*/),
                (v48/*: any*/),
                (v49/*: any*/),
                (v50/*: any*/),
                (v51/*: any*/),
                (v52/*: any*/),
                (v53/*: any*/),
                (v54/*: any*/),
                (v55/*: any*/),
                (v56/*: any*/),
                (v57/*: any*/),
                (v58/*: any*/),
                (v59/*: any*/),
                (v60/*: any*/),
                (v61/*: any*/),
                (v62/*: any*/),
                (v63/*: any*/),
                (v64/*: any*/),
                (v65/*: any*/),
                (v66/*: any*/),
                (v67/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "pDay",
          "variableName": "pDay"
        },
        {
          "kind": "Variable",
          "name": "pIsresponsabilbusiness",
          "variableName": "pIsresponsabilbusiness"
        },
        {
          "kind": "Variable",
          "name": "pMonth",
          "variableName": "pMonth"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "concreteType": "VcalendarsessionstypesConnection",
      "kind": "LinkedField",
      "name": "vcalendarsessionsfunction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcalendarsessionstypesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcalendarsessionstype",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "norder",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terapeut",
                  "storageKey": null
                },
                (v68/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapistsymbol",
                  "storageKey": null
                },
                (v69/*: any*/),
                (v70/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbolroom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameRocalendar",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monthname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shortmonthname",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "doy",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/),
                (v27/*: any*/),
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/),
                (v32/*: any*/),
                (v33/*: any*/),
                (v34/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v39/*: any*/),
                (v40/*: any*/),
                (v41/*: any*/),
                (v42/*: any*/),
                (v43/*: any*/),
                (v44/*: any*/),
                (v45/*: any*/),
                (v46/*: any*/),
                (v47/*: any*/),
                (v48/*: any*/),
                (v49/*: any*/),
                (v50/*: any*/),
                (v51/*: any*/),
                (v52/*: any*/),
                (v53/*: any*/),
                (v54/*: any*/),
                (v55/*: any*/),
                (v56/*: any*/),
                (v57/*: any*/),
                (v58/*: any*/),
                (v59/*: any*/),
                (v60/*: any*/),
                (v61/*: any*/),
                (v62/*: any*/),
                (v63/*: any*/),
                (v64/*: any*/),
                (v65/*: any*/),
                (v66/*: any*/),
                (v67/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "YEAR_DESC"
        }
      ],
      "concreteType": "VtherapysessionyearsConnection",
      "kind": "LinkedField",
      "name": "vtherapysessionyears",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapysessionyearsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapysessionyear",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapysessionyears(orderBy:\"YEAR_DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VpatientsConnection",
      "kind": "LinkedField",
      "name": "vpatients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VpatientsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vpatient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v71/*: any*/),
                (v72/*: any*/),
                (v73/*: any*/),
                (v74/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "therapistid",
              "variableName": "pTherapistid"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "VtherapistpatientsConnection",
      "kind": "LinkedField",
      "name": "vtherapistpatients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapistpatientsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapistpatient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v71/*: any*/),
                (v75/*: any*/),
                (v76/*: any*/),
                (v77/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "activ": true
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPISTID_ASC"
        }
      ],
      "concreteType": "VtherapistsConnection",
      "kind": "LinkedField",
      "name": "vtherapists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapistsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapist",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v68/*: any*/),
                (v72/*: any*/),
                (v73/*: any*/),
                (v74/*: any*/),
                (v78/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarcss",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapists(condition:{\"activ\":true},orderBy:\"THERAPISTID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "HOURID_ASC"
        }
      ],
      "concreteType": "VhoursConnection",
      "kind": "LinkedField",
      "name": "vhours",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VhoursEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vhour",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hourid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v78/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vhours(orderBy:\"HOURID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "activtherapists": true,
            "therapytypeid": 1
          }
        }
      ],
      "concreteType": "VtherapisttherapypricesConnection",
      "kind": "LinkedField",
      "name": "vtherapisttherapyprices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapisttherapypricesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapisttherapyprice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapisttherapypriceid",
                  "storageKey": null
                },
                (v68/*: any*/),
                (v72/*: any*/),
                (v73/*: any*/),
                (v78/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapypriceid",
                  "storageKey": null
                },
                (v79/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapytypeid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                (v80/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percent",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapisttherapyprices(condition:{\"activtherapists\":true,\"therapytypeid\":1})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CALENDARID_ASC"
        }
      ],
      "concreteType": "VcalendarsConnection",
      "kind": "LinkedField",
      "name": "vcalendars",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VcalendarsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vcalendar",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v70/*: any*/),
                (v81/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vcalendars(orderBy:\"CALENDARID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ROOMID_ASC"
        }
      ],
      "concreteType": "VroomsConnection",
      "kind": "LinkedField",
      "name": "vrooms",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VroomsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vroom",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v69/*: any*/),
                (v81/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vrooms(orderBy:\"ROOMID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "COLLECTIONID_ASC"
        }
      ],
      "concreteType": "VbusinesscollectionsConnection",
      "kind": "LinkedField",
      "name": "vbusinesscollections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VbusinesscollectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vbusinesscollection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "collectionid",
                  "storageKey": null
                },
                (v81/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vbusinesscollections(orderBy:\"COLLECTIONID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "THERAPYID_ASC"
        }
      ],
      "concreteType": "VtherapiesConnection",
      "kind": "LinkedField",
      "name": "vtherapies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VtherapiesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vtherapy",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapyid",
                  "storageKey": null
                },
                (v81/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vtherapies(orderBy:\"THERAPYID_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "subscriptionnotfull": true
          }
        }
      ],
      "concreteType": "VsubscriptionsConnection",
      "kind": "LinkedField",
      "name": "vsubscriptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VsubscriptionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vsubscription",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriptionid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "therapysessionid",
                  "storageKey": null
                },
                (v75/*: any*/),
                (v76/*: any*/),
                (v77/*: any*/),
                (v72/*: any*/),
                (v73/*: any*/),
                (v74/*: any*/),
                (v80/*: any*/),
                (v79/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriptioncount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vsubscriptions(condition:{\"subscriptionnotfull\":true})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcc01cc6e8f44d254d3d497d27406541';

module.exports = node;
