import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li>administrare fel operatiune sedinte, campurile atribut fiind: <b>therapypriceId, therapyId, therapy2Id, therapytypeId, timeid, time2id</b> </li>
            <li>pentru campurile <u>therapy2Id, therapytypeId, timeid, time2id</u> exista unicitate in tabela, campul therapytypeId este optional</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga fel operatiune sedinte noua [<b>+</b>], actualizare oricare dintre campurile <i>therapy2Id, therapytypeId</i>, sterge fel operatiune sedinte si refresh date </li>
                <li><b>stergerea</b> este posibila doar daca fel operatiune sedinte nu este utilizata</li>
                <li> pentru campurile <b>therapyId, therapy2Id, therapytypeId, timeid si time2id</b> se foloseste cate un autocomplete care se populeaza fiecare cu o  lista de valori. Deoarece campurile therapy2Id si time2id sunt nullable la lista se adauga o prima inregistare cu valoarea <b>null</b> care permite stergerea valorii campului din baza de date. </li>
            </ul>
            <li>este posibil export in fisier xls <b>Operationkindofs.xls</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.configTherapyprices</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: config.therapyprices</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>otherchecks</th>
                        </tr>
                        <tr>
                            <td className='td'>therapypriceId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapyId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1, uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapy2Id</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1</td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapytypeId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1, uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>1- session, 2 - abonament 5 sedinte, 3 - abonament 10 sedinte, 4 - packet 3+3, 5 - packet 5+5</td>
                        </tr>
                        <tr>
                            <td className='td'>timeid</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk1</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>[¼, ½, ¾, 1, 1¼, 1½, 1¾, 2]</td>
                        </tr>
                        <tr>
                            <td className='td'>time2id</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'>[¼, ½, ¾, 1, 1¼, 1½, 1¾, 2]</td>
                        </tr>
                        <tr>
                            <td className='td'>time</td>
                            <td className='td'>VARCHAR(25)</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>price</td>
                            <td className='td'>INT</td>
                            <td className='td'></td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>name</td>
                            <td className='td'>VARCHAR(100)</td>
                            <td className='td'>uk2</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>description</td>
                            <td className='td'>text</td>
                            <td className='td'></td>
                            <td className='td'>NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>norder</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk3</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapyprices(orderBy: NORDER_ASC, condition: $condition) (therapypriceId, norder, 
                                <div>therapyId, name_rotherapy, name_entherapy, symboltherapy, nordertherapy, descriptiontherapy,</div>
                                <div>therapy2Id, name_ro2therapy, name_en2therapy, symbol2therapy, norder2therapy, description2therapy, </div>
                                <div>therapytypeId, name_rotherapytype, name_entherapytype, symboltherapytype, ntherapysessiontherapytype, ntherapysession1therapytype, </div>
                                <div>timeid, symboltime, minutestime, unitstime, </div>
                                <div>time2id, symboltime2, minutestime2, unitstime2, </div>
                                <div>time, price, name, description)</div>
                            </td>
                            <td className='td'>, unde &#x0007B; condition: therapyid !== undefined ? &#x0007B; therapyid: parseInt(therapyid) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapies(orderBy: THERAPYID_ASC) (therapyid, nameRo)</td>
                            <td className='td'>lista de valori [therapyId, therapy2Id]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapytypes(orderBy: THERAPYTYPEID_ASC) (therapytypeid, nameRo)</td>
                            <td className='td'>lista de valori [therapytypeId ]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtimes(orderBy: TIMEID_ASC) (timeid, symbol, minutes)</td>
                            <td className='td'>lista de valori [timeid, time2id]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_addtherapyprice(p_therapyid, p_therapy2Id, p_therapytypeId, p_timeid, p_time2id, p_time, p_price, p_name, p_description, p_norder)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_updtherapyprice(p_keyfield, p_value, p_therapyId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_deloperationkindof(p_therapyId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>config, main</td>
                            <td className='td'>fn_upstherapyprice(p_therapypriceId, p_therapyid, p_therapy2Id, p_therapytypeId, p_timeid, p_time2id, p_time, p_price, p_name, p_description, p_norder)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


