
export default [
    {
        nrcrt: {field: 'nrcrt', values: ['', '', 'nrcrt'], width: 5, isLastRow : false},
        utilizator: {field: 'utilizator', values: ['', '', 'utilizator'], width: 15, isLastRow : false},
        cnp: {field: 'cnp', values: ['', '', 'cnp'], width: 10, isLastRow : false},
        email: {field: 'email', values: ['', '', 'email'], width: 15, isLastRow : false},    
        telefon: {field: 'telefon', values: ['', '', 'telefon'], width: 10, isLastRow : false},    
        datainceput: {field: 'datainceput', values: ['', '', 'datainceput'], width: 15, isLastRow : false},    
        datasfarsit: {field: 'datasfarsit', values: ['', '', 'datasfarsit'], width: 15, isLastRow : false},    
        parola: {field: 'parola', values: ['', '', 'parola'], width: 15, isLastRow : false},    
    }, 
    {
        nrcrt: {field: 'nrcrt', values: ['', '', 'nrcrt'], width: 5, isLastRow : false},
        utilizatorid: {field: 'utilizatorid', values: ['', '', 'utilizatorid'], width: 40, isLastRow : false},
        appid: {field: 'appid', values: ['', '', 'appid'], width: 30, isLastRow : false},
        rolid: {field: 'rolid', values: ['', '', 'rolid'], width: 25, isLastRow : false},    
    }, 
    {
        nrcrt: {field: 'nrcrt', values: ['', '', 'nrcrt'], width: 5, isLastRow : false},
        utilizatormodeleazaid: {field: 'utilizatormodeleazaid', values: ['', '', 'utilizatormodeleazaid'], width: 50, isLastRow : false},
        utilizatormodelatid: {field: 'utilizatorid', values: ['', '', 'utilizatormodelatid'], width: 45, isLastRow : false},    
    }, 
]
