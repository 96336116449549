/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type page_dbdata$ref: FragmentReference;
declare export opaque type page_dbdata$fragmentType: page_dbdata$ref;
export type page_dbdata = {|
  +vutilizators: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +utilizatorid: ?any,
        +utilizator: ?string,
        +username: ?string,
        +email: ?string,
        +cnp: ?string,
        +telefon: ?string,
        +activ: ?boolean,
        +parola: ?string,
        +datainceput: ?any,
        +datasfarsit: ?any,
      |}
    |}>
  |},
  +vutilizatorapprols: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +utilizatorid: ?any,
        +appid: ?any,
        +rolid: ?any,
      |}
    |}>
  |},
  +vutilizatormodelats: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +utilizatormodeleazaid: ?any,
        +utilizatorid: ?any,
      |}
    |}>
  |},
  +$refType: page_dbdata$ref,
|};
export type page_dbdata$data = page_dbdata;
export type page_dbdata$key = {
  +$data?: page_dbdata$data,
  +$fragmentRefs: page_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "UTILIZATORID_ASC"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utilizatorid",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": {
        "grefresh": false
      },
      "kind": "LocalArgument",
      "name": "condition"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "pRefresh"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "page_dbdata",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "condition"
        },
        (v0/*: any*/)
      ],
      "concreteType": "VutilizatorsConnection",
      "kind": "LinkedField",
      "name": "vutilizators",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizator",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "utilizator",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cnp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "telefon",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activ",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parola",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "datainceput",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "datasfarsit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "VutilizatorapprolsConnection",
      "kind": "LinkedField",
      "name": "vutilizatorapprols",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatorapprolsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizatorapprol",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rolid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vutilizatorapprols(orderBy:\"UTILIZATORID_ASC\")"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "VutilizatormodelatsConnection",
      "kind": "LinkedField",
      "name": "vutilizatormodelats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VutilizatormodelatsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vutilizatormodelat",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "utilizatormodeleazaid",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "vutilizatormodelats(orderBy:\"UTILIZATORID_ASC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6476339fb1af3d67786a8becb25f32c6';

module.exports = node;
