
export default {
    AddDialogtitle: ['Adauga operation kind of', ''],
    columns: [
      {header: 'nameRo', accessor: 'nameRo', type: 'text', show: 'true', },
      {header: 'nameEn', accessor: 'nameEn', type: 'text', show: 'true', },
      {header: 'symbol', accessor: 'symbol', type: 'text', show: 'true', },
      {header: 'etaj', accessor: 'etaj', type: 'number', show: 'true', },
    ],
    initialdata: {
      nameRo: '',
      nameEn: '',
      symbol: '',
      etaj: '',
      subRows: undefined,
    },    
  }
