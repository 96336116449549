/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpdmngmentoperationdetailInput = {|
  clientMutationId?: ?string,
  pKeyfield: string,
  pValue: string,
  pMngmentoperationdetailid: number,
|};
export type mutationUpdFieldMutationVariables = {|
  input: FnUpdmngmentoperationdetailInput
|};
export type mutationUpdFieldMutationResponse = {|
  +fnUpdmngmentoperationdetail: ?{|
    +mngmentoperationdetail: ?{|
      +collectionid: number,
      +description: ?string,
      +mngmentoperationdetailid: any,
      +mngmentoperationid: number,
      +ndocument: string,
      +operatiune: ?string,
      +norder: number,
      +operationcodeid: number,
      +value: any,
      +debit: ?any,
      +credit: ?any,
      +businessid: ?number,
    |}
  |}
|};
export type mutationUpdFieldMutation = {|
  variables: mutationUpdFieldMutationVariables,
  response: mutationUpdFieldMutationResponse,
|};
*/


/*
mutation mutationUpdFieldMutation(
  $input: FnUpdmngmentoperationdetailInput!
) {
  fnUpdmngmentoperationdetail(input: $input) {
    mngmentoperationdetail {
      collectionid
      description
      mngmentoperationdetailid
      mngmentoperationid
      ndocument
      operatiune
      norder
      operationcodeid
      value
      debit
      credit
      businessid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpdmngmentoperationdetailPayload",
    "kind": "LinkedField",
    "name": "fnUpdmngmentoperationdetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperationdetail",
        "kind": "LinkedField",
        "name": "mngmentoperationdetail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationdetailid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ndocument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatiune",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpdFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "315f270c815fa8aed838c73bfd31a34d",
    "id": null,
    "metadata": {},
    "name": "mutationUpdFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpdFieldMutation(\n  $input: FnUpdmngmentoperationdetailInput!\n) {\n  fnUpdmngmentoperationdetail(input: $input) {\n    mngmentoperationdetail {\n      collectionid\n      description\n      mngmentoperationdetailid\n      mngmentoperationid\n      ndocument\n      operatiune\n      norder\n      operationcodeid\n      value\n      debit\n      credit\n      businessid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87373827eea08f47082f73af18f67b17';

module.exports = node;
