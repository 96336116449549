  export default  Object.freeze({
    responsabilbusiness : {key: 1, value: 'responsabilbusiness'},
    responsabilmanagement : {key: 2, value: 'responsabil management'},
    terapeut: {key: 3, value: 'terapeut'},
    pacient: {key: 4, value: 'pacient'},
    // liber: {key: 5, value: 'director sucursalaliber'},
    contabil: {key: 6, value: 'contabil'},
    responsabilutilizatori: {key: 7, value: 'responsabilutilizatori'},
    superadmin: {key: 8, value: 'superadmin'},
    AdminApp: {key: 9, value: 'adminApp'},
    Developer: {key: 10, value: 'developer'},
    Tester: {key: 11, value: 'tester'},
    Modelare: {key: 12, value: 'modelare'},
})

