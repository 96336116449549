/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VutilizatorCondition = {|
  utilizatorid?: ?any,
  utilizator?: ?string,
  username?: ?string,
  email?: ?string,
  cnp?: ?string,
  telefon?: ?string,
  datainceput?: ?any,
  datasfarsit?: ?any,
  activ?: ?boolean,
  parola?: ?string,
  grefresh?: ?boolean,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VutilizatorCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VutilizatorCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vutilizators(condition: $condition, orderBy: UTILIZATORID_ASC) {
    edges {
      node {
        utilizatorid
        utilizator
        username
        email
        cnp
        telefon
        activ
        parola
        datainceput
        datasfarsit
      }
    }
  }
  vutilizatorapprols(orderBy: UTILIZATORID_ASC) {
    edges {
      node {
        utilizatorid
        appid
        rolid
      }
    }
  }
  vutilizatormodelats(orderBy: UTILIZATORID_ASC) {
    edges {
      node {
        utilizatormodeleazaid
        utilizatorid
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v3 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "UTILIZATORID_ASC"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utilizatorid",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "concreteType": "VutilizatorsConnection",
        "kind": "LinkedField",
        "name": "vutilizators",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizator",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "telefon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parola",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datainceput",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datasfarsit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "VutilizatorapprolsConnection",
        "kind": "LinkedField",
        "name": "vutilizatorapprols",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatorapprolsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatorapprol",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vutilizatorapprols(orderBy:\"UTILIZATORID_ASC\")"
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "VutilizatormodelatsConnection",
        "kind": "LinkedField",
        "name": "vutilizatormodelats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatormodelatsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatormodelat",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizatormodeleazaid",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vutilizatormodelats(orderBy:\"UTILIZATORID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "353d43e51771391f9f4bdd936847e4dd",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VutilizatorCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vutilizators(condition: $condition, orderBy: UTILIZATORID_ASC) {\n    edges {\n      node {\n        utilizatorid\n        utilizator\n        username\n        email\n        cnp\n        telefon\n        activ\n        parola\n        datainceput\n        datasfarsit\n      }\n    }\n  }\n  vutilizatorapprols(orderBy: UTILIZATORID_ASC) {\n    edges {\n      node {\n        utilizatorid\n        appid\n        rolid\n      }\n    }\n  }\n  vutilizatormodelats(orderBy: UTILIZATORID_ASC) {\n    edges {\n      node {\n        utilizatormodeleazaid\n        utilizatorid\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '771b7e7c6bc7c77b0544c9763695d18b';

module.exports = node;
