import formatdateymd from "../../../uioscar/formatdateymd"

export default ({dbOptions, dbOptions1, dbOptions2, dbOptions3, dbOptions4, dbOptions5, }) => ({
  AddDialogtitle: ['Adauga sedinta terapie', ''],
    columns: [
      {header: 'namesubscriptionmodes', accessor: 'namesubscriptionmodes', accessorid: 'subscriptionmodeid', type: 'text', show: true, required:true,
        autocomplete: {dbOptions: dbOptions5, widthautocomplete: '33.7rem', text: 'Alege fel abonament ..',}},
      {header: 'pacient', accessor: 'patient', accessorid: 'patientid', type: 'text', show: true, required:true,
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege pacient ..',}},
      {header: 'firstname', accessor: 'firstname', type: 'text', show: 'true', },
      {header: 'lastname', accessor: 'lastname', type: 'text', show: 'true', },
      // {header: 'therapist', accessor: 'therapist', accessorid: 'therapistid', type: '', show: true, 
      //   autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'therapyprice', accessor: 'therapyprice', accessorid: 'therapisttherapypriceid', type: 'text', show: true, required:true,  
        autocomplete: {dbOptions: dbOptions2, widthautocomplete: '33.7rem', text: 'Alege abonament(terapeut, terapie, pret) ..',}},

      {header: 'data', accessor: 'date', type: 'date', show: 'true', required:true, },
      {header: 'De la', accessor: 'fromhour', accessorid: 'fromid', type: 'text', show: true, required:false, 
        autocomplete: {dbOptions: dbOptions4, widthautocomplete: '33.7rem', text: 'Alege de la ..',}},
      // {header: 'room', accessor: 'room', accessorid: 'roomid', type: 'text', show: true, 
      //   autocomplete: {dbOptions: dbOptions6, widthautocomplete: '33.7rem', text: 'Alege sala ..',}},
      {header: 'price', accessor: 'value', type: 'number', show: 'true', required:false, },
      {header: 'Mod incasare', accessor: 'nameRocollection', accessorid: 'collectionid', type: 'text', show: true, required:true, 
        autocomplete: {dbOptions: dbOptions3, widthautocomplete: '33.7rem', text: 'Alege mod incasare ..',}},
      {header: 'numar sedinte', accessor: 'ntherapysession', type: 'number', show: 'true', required:true, },
      {header: 'perioada [nr.luni]', accessor: 'nbmonths', type: 'number', show: 'true', required:true, },
      {header: 'descriere', accessor: 'description', type: 'text', show: 'true', required:false, },
    ],
    initialdata: {
      subscriptionmodeid: '', 
      patientid: '', 
      firstname: '', 
      lastname: '', 
      therapisttherapypriceid: '', 
      date: formatdateymd(new Date()), 
      fromid: '', 
      // roomid: '',  
      value: '', 
      collectionid: '', 
      ntherapysession: '', 
      nbmonths: '', 
      description: '', 
      subRows: undefined,
    },    
  })
