import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vappmenus === undefined ? null : dbdata.vappmenus.edges.map(edge => {return{...edge.node, }})
    // console.log(`[adminMenuApp.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
              <Table dbdata={{dbvdata: dbvdata, }} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        condition: {type: VappmenuCondition, defaultValue: {grefresh: false} },
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
  
      vappmenus(condition: $condition) {
        edges {
          node {
            appmenuid
            appid
            denumire
            simbol
            ordonare
          }
        }
      }

    }
    `
}

export default Relay.createFragmentContainer(Page, fragments)

