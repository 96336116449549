import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapyInput!)
{
  fnUpstherapy(input: $input) {
    therapy {
      description
      nameEn
      nameRo
      norder
      symbol
      shortsymbol
      therapyid
      activ
    }
  }
}
`
 