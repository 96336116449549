/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type footer_dbdata$ref: FragmentReference;
declare export opaque type footer_dbdata$fragmentType: footer_dbdata$ref;
export type footer_dbdata = {|
  +vidservers: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +idserverid: ?any,
        +denumire: ?string,
        +simbol: ?string,
      |}
    |}>
  |},
  +$refType: footer_dbdata$ref,
|};
export type footer_dbdata$data = footer_dbdata;
export type footer_dbdata$key = {
  +$data?: footer_dbdata$data,
  +$fragmentRefs: footer_dbdata$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "footer_dbdata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VidserversConnection",
      "kind": "LinkedField",
      "name": "vidservers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VidserversEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Vidserver",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "idserverid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "denumire",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "simbol",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e29212f8c17599cbb082e2f426cf609b';

module.exports = node;
