import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'
import Relay from 'react-relay'
import Table from './table'
import graphql from 'babel-plugin-relay/macro'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
  const dbvdata = dbdata.vpatients === undefined ? null : dbdata.vpatients.edges.map(edge => {return{...edge.node, }})
  // console.log(`[configTherapists.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
  // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
    
  const dbOptions = [
    ...dbdata.vtherapists.edges.map(edge => {
      const {therapistid, firstname, lastname, extensive, symbol, code, } = edge.node
      return {id: 'therapistid', therapistid, therapist: `${firstname} ${lastname} ${extensive}[${symbol}, ${code}]`}
  })
]
// console.log(`[configTherapisttherapyprices.page] - dbOptions: ${JSON.stringify(dbOptions)}` )

return(
        <div style={backgrdStyle}>
            <div style={transbox} >
              <Table dbdata={{dbvdata: dbvdata, dbOptions}} params = {{...params, titles}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
      vpatients { #(orderBy: LASTNAME_ASC)
        edges {
          node {
            patientid
            firstname
            lastname
            extensive
            birthyear
            weight
            height
            phone
            email
            cnp
            thereistherapistpatient
            dataadaugare          }
        }
      }
      vtherapists(condition: {activ: true} orderBy: THERAPISTID_ASC) {
        edges {
          node {
            therapistid
            firstname
            lastname
            extensive
            symbol
            code
            # phone
            # email
            # cnp
            # percent
          }
        }
      } 
    }

    `
}
export default Relay.createFragmentContainer(Page, fragments)

