import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelcalendarInput!)
{
  fnDelcalendar(input: $input) {
    calendar {
      calendarid
      nameEn
      nameRo
      symbol
    }
  }
}
`
