
export default [
    [
        {field: 'hourid', value: 'hourid'},
        {field: 'name', value: 'name'},
        {field: 'symbol', value: 'symbol'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)

