// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions2}) => ([
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'date', Header: 'date', accessor: 'date', width: 15, type: 'date',},
        { id: 'ndocument', Header: 'ndocument', accessor: 'ndocument', width: 10, type: 'number', align: 'left', },
        { id: 'nameoperationcode', Header: 'nameoperationcode', accessor: 'nameoperationcode', width: 10, type: 'number',align: 'right', },
        { id: 'debit', Header: 'debit', accessor: 'debit', width: 10, type: 'number', align: 'right', },
        { id: 'credit', Header: 'credit', accessor: 'credit', width: 10, type: 'number', align: 'right', },
        { id: 'sold', Header: 'sold', accessor: 'sold', width: 10, type: 'number', align: 'right', },
        // { id: 'month', Header: 'month', accessor: 'month', width: 10, type: 'number', align: 'left', },
        // { id: 'monthname', Header: 'monthname', accessor: 'monthname', width: 10, type: 'number', align: 'left', },
        // { id: 'norder', Header: 'norder', accessor: 'norder', width: 10, type: 'number', align: 'right',},
        // { id: 'shortmonthname', Header: 'shortmonthname', accessor: 'shortmonthname', width: 10, type: 'number', align: 'left', },
        // { id: 'year', Header: 'year', accessor: 'year', width: 10, type: 'number', align: 'left', },
      ]
    },

    // {
    //   Header: 'sold registru casa',
    //   columns: [
    //     { id: 'houseregisterprevious', Header: 'houseregisterprevious', accessor: 'houseregisterprevious', width: 15, type: 'date',},
    //     { id: 'houseregistercurrent', Header: 'houseregistercurrent', accessor: 'houseregistercurrent', width: 15, type: 'date',},
    //   ]
    // },
    // {
    //   Header: 'code',
    //   columns: [
    //     // { id: 'symboloperationcode', Header: 'symboloperationcode', accessor: 'symboloperationcode', width: 30, type: 'text', align: 'left', },
    //     // { id: 'codeoperationcode', Header: 'codeoperationcode', accessor: 'codeoperationcode', width: 30, type: 'text', align: 'left', },
    //     // { id: 'norderoperationcode', Header: 'norderoperationcode', accessor: 'norderoperationcode', width: 30, type: 'text', align: 'left', },
    //     ]
    // },
    // {
    //   Header: 'operationkindof',
    //   columns: [
    //     { id: 'nameoperationkindof', Header: 'nameoperationkindof', accessor: 'nameoperationkindof', width: 30, type: 'text',},
    //     // { id: 'symboloperationkindof', Header: 'symboloperationkindof', accessor: 'symboloperationkindof', width: 30, type: 'text',},
    //     { id: 'norderoperationkindof', Header: 'norderoperationkindof', accessor: 'norderoperationkindof', width: 30, type: 'text',},
    //   ]
    // },
    // {
    //   Header: 'kindof',
    //   columns: [
    //     { id: 'namekindofs', Header: 'namekindofs', accessor: 'namekindofs', width: 30, type: 'text',},
    //     // { id: 'symbolkindofs', Header: 'symbolkindofs', accessor: 'symbolkindofs', width: 30, type: 'text',},
    //     ]
    // },


    {
      Header: 'db info', columns: [     
      { id: 'mngmentoperationid', Header: 'mngmentoperationid', accessor: 'mngmentoperationid', width: 30, type: 'number', align: 'right',  },
      // { id: 'operationkindofid', Header: 'operationkindofid', accessor: 'operationkindofid', width: 30, type: 'number', align: 'right',},
      // { id: 'kindofid', Header: 'kindofid', accessor: 'kindofid', width: 30, type: 'number', align: 'right',},
      // { id: 'selyearid', Header: 'selyearid', accessor: 'selyearid', width: 30, type: 'number', align: 'right',  },
      // { id: 'selmonthid', Header: 'selmonthid', accessor: 'selmonthid', width: 30, type: 'number', align: 'right',  },
      ],
    },
])


