import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'

const Page = ({dbdata, params, events}) => {
    const dbvdata = dbdata.vutilizators === undefined ? null : dbdata.vutilizators.edges.map(edge => {return{...edge.node, }})
    // console.log(`[utilizatori.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
    const dbvdata1 = dbdata.vutilizatorapprols === undefined ? null : dbdata.vutilizatorapprols.edges.map(edge => {return{...edge.node, }})
    // console.log(`[utilizatori.page] - dbvdata1: ${JSON.stringify(dbvdata1)}` )
    const dbvdata2 = dbdata.vutilizatormodelats === undefined ? null : dbdata.vutilizatormodelats.edges.map(edge => {return{...edge.node, }})
    // console.log(`[utilizatori.page] - dbvdata1: ${JSON.stringify(dbvdata2)}` )
    
    return(
      <div style={backgrdStyle}>
        <div style={transbox}>
          <Table dbdata={{dbvdata: dbvdata, dbvdata1: dbvdata1, dbvdata2: dbvdata2}} params = {{...params, titles}} events={events} />
        </div>
      </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        condition: {type: VutilizatorCondition, defaultValue: {grefresh: false} },
        pRefresh: {type: Boolean, defaultValue: false, }      ) 
    {
      vutilizators(condition: $condition, orderBy: UTILIZATORID_ASC){
        edges {
          node {
            utilizatorid
            utilizator
            username
            email
            cnp
            telefon
            activ
            parola
            datainceput
            datasfarsit
            #grefresh
          }
        }
      }
      vutilizatorapprols(orderBy: UTILIZATORID_ASC){
        edges {
          node {
            # approlutilizatorid
            utilizatorid
            # utilizator
            # username
            # email
            # cnp
            # telefon
            # activ
            # parola
            appid
            # app
            # link
            # ordonare
            rolid
            # rol
          }
        }
      }
      vutilizatormodelats(orderBy: UTILIZATORID_ASC){
        edges {
          node {
            utilizatormodeleazaid
            utilizatorid
            # utilizator
            # username
            # email
            # cnp
            # telefon
            # datainceput
            # datasfarsit
            # activ
            # parola
            #grefresh
          }
        }
      }
    }
    `
}

export default Relay.createFragmentContainer(Page, fragments)

