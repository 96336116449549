// import React from 'react'

export default ({dbOptions, dbOptions1, dbOptions3}) => ([
    // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        { id: 'therapistreport', Header: 'perioada raport terapeut', accessor: 'therapistreport', width: 15, type: 'text',
        autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '12.3rem', text: 'Alege mod raport terapeut ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'date', Header: 'Data', accessor: 'date', width: 15, type: 'date',},
        { id: 'patient', Header: 'Beneficiar', accessor: 'patient', width: 10, type: 'number', align: 'left', },
        { id: 'nametherapyprice', Header: 'Terapie', accessor: 'nametherapyprice', width: 10, type: 'number',align: 'right', },
        { id: 'sfrom', Header: 'De la', accessor: 'sfrom', width: 10, type: 'number', align: 'right', },
        { id: 'sto', Header: 'Pana la', accessor: 'sto', width: 10, type: 'number', align: 'right', },
        { id: 'ora', Header: 'Ora', accessor: 'ora', width: 10, type: 'number', align: 'right', },
        { id: 'percent', Header: 'Procent', accessor: 'percent', width: 10, type: 'number', align: 'right', },
        { id: 'namerocollection', Header: 'mod incasare', accessor: 'namerocollection', width: 10, type: 'number', align: 'right', },
        { id: 'value', Header: 'Valoare', accessor: 'value', width: 10, type: 'number', align: 'right', },
        { id: 'therapistvalue', Header: 'Factura', accessor: 'therapistvalue', width: 10, type: 'number', align: 'right', },
      ]
    },
    {
      Header: 'db info', columns: [     
      { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30, type: 'number', align: 'right',  },
      { id: 'therapistreportid', Header: 'therapistreportid', accessor: 'therapistreportid', width: 30, type: 'number', align: 'right',  },
      ],
    },
])


