import { Text, View } from '@react-pdf/renderer'

import React from 'react'

// import { StyleSheet } from '@react-pdf/renderer'

export default ({params}) => {
  const {pdffooter, } = params
  // console.log(pdffooter)
 return (
    <>
      {pdffooter !== undefined ?
        <View fixed>
          <Text key={Math.random()} style={pdffooter.notastyles.date} 
            render={
              () => (pdffooter.date)
            } 
          />
          <Text key={Math.random()} style={pdffooter.notastyles.intocmit} 
            render={
              () => (pdffooter.intocmit)
            } 
          />
        </View> 
      : <></>
      }
    </>
  )}
    
  // const notastyles = StyleSheet.create({
  //   note: {
  //     // position: 'absolute',
  //     // fontSize: 9,
  //     // bottom: 136,
  //     top: 20,
  //     left: 435,
  //     // right: 0,
  //     // textAlign: 'center',
  //     color: 'black',
  //   },
  // })
