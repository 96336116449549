import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddapprolutilizatorInput!)
{
  fnAddapprolutilizator(input: $input) {
    approlutilizator {
      appid
      approlutilizatorid
      rolid
      utilizatorid
    }
  }
}
`
