import * as pdfstyles from '../../../../uioscar/pdf/pdflib/pdfstyles'

export default () => {   

    const headertableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '12',
    }
    const bodytableCell = { 
        marginTop: 1, //4, 
        marginLeft: 1, //4, 
        marginRight: 4, //12, 
        //margin: "auto", 
        color:'black',
        // borderTop: 0, // borderTopColor: 'red',
        // justifyContent: 'center',
        // fontweight: 'bolder',
        // textAlign: 'center',
        fontSize: '12',
    }

    return [
        {
            date: {field: 'nrcrt', values: ['Nr.crt.', ], width: 14, isLastRow : false, ...pdfstyles.styleLeft({headertableCell, bodytableCell, }), },
            ndocument: {field: 'ndocument', values: ['Nr. act casa', ], width: 15, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            nameoperationcode: {field: 'nameoperationcode', values: ['Explicatii', ], width: 30, isLastRow : false, ...pdfstyles.styleAlignLeft({headertableCell, bodytableCell, }), },
            credit: {field: 'credit', values: ['Incasari', ], width: 13, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            debit: {field: 'debit', values: ['Plati', ], width: 13, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
            sold: {field: 'sold', values: ['Sold', ], width: 15, isLastRow : false, ...pdfstyles.styleAlignRight({headertableCell, bodytableCell, }), },
        }, 
    ]}

// month
// year
// monthname


// codeoperationcode



// norderoperationcode
// operationkindofid
// nameoperationkindof
// norderoperationkindof
// norder
// kindofid
// namekindofs
