/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VutilizatornemodelatCondition = {|
  utilizatormodeleazaid?: ?any,
  utilizatormodelatid?: ?any,
  utilizator?: ?string,
  username?: ?string,
  email?: ?string,
  cnp?: ?string,
  telefon?: ?string,
  datainceput?: ?any,
  datasfarsit?: ?any,
  activ?: ?boolean,
  parola?: ?string,
  grefresh?: ?boolean,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VutilizatornemodelatCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VutilizatornemodelatCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vutilizatornemodelats(condition: $condition, orderBy: UTILIZATORMODELATID_ASC) {
    edges {
      node {
        utilizatormodeleazaid
        utilizatormodelatid
        utilizator
        username
        email
        cnp
        telefon
        datainceput
        datasfarsit
        activ
        parola
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "UTILIZATORMODELATID_ASC"
          }
        ],
        "concreteType": "VutilizatornemodelatsConnection",
        "kind": "LinkedField",
        "name": "vutilizatornemodelats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VutilizatornemodelatsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vutilizatornemodelat",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizatormodeleazaid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizatormodelatid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utilizator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cnp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "telefon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datainceput",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datasfarsit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parola",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "160402439fd0a3e700205789e751798c",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VutilizatornemodelatCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vutilizatornemodelats(condition: $condition, orderBy: UTILIZATORMODELATID_ASC) {\n    edges {\n      node {\n        utilizatormodeleazaid\n        utilizatormodelatid\n        utilizator\n        username\n        email\n        cnp\n        telefon\n        datainceput\n        datasfarsit\n        activ\n        parola\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3bfb88c2aafd50a2f8ca231610a1b42d';

module.exports = node;
