
export default [
    {
        therapytypeid: {field: 'therapytypeid', values: ['', '', 'therapytypeid'], width: 10, isLastRow : false},
        nameRo: {field: 'nameRo', values: ['', '', 'nameRo'], width: 30, isLastRow : false},
        nameEn: {field: 'nameEn', values: ['', '', 'nameEn'], width: 30, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 10, isLastRow : false},
        ntherapysession: {field: 'ntherapysession', values: ['', '', 'ntherapysession'], width: 10, isLastRow : false},
        ntherapysession1: {field: 'ntherapysession1', values: ['', '', 'ntherapysession1'], width: 10, isLastRow : false},
    }, 
]
