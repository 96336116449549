import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdtherapisttherapypriceInput!)
{
  fnUpdtherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
`

