/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelmngmentoperationdetailInput = {|
  clientMutationId?: ?string,
  pMngmentoperationdetailid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelmngmentoperationdetailInput
|};
export type mutationDelMutationResponse = {|
  +fnDelmngmentoperationdetail: ?{|
    +mngmentoperationdetail: ?{|
      +collectionid: number,
      +description: ?string,
      +mngmentoperationdetailid: any,
      +mngmentoperationid: number,
      +ndocument: string,
      +operatiune: ?string,
      +norder: number,
      +operationcodeid: number,
      +value: any,
      +debit: ?any,
      +credit: ?any,
      +businessid: ?number,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelmngmentoperationdetailInput!
) {
  fnDelmngmentoperationdetail(input: $input) {
    mngmentoperationdetail {
      collectionid
      description
      mngmentoperationdetailid
      mngmentoperationid
      ndocument
      operatiune
      norder
      operationcodeid
      value
      debit
      credit
      businessid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelmngmentoperationdetailPayload",
    "kind": "LinkedField",
    "name": "fnDelmngmentoperationdetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperationdetail",
        "kind": "LinkedField",
        "name": "mngmentoperationdetail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationdetailid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ndocument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatiune",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fb0400ec90da419e3d6876be9b41069",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelmngmentoperationdetailInput!\n) {\n  fnDelmngmentoperationdetail(input: $input) {\n    mngmentoperationdetail {\n      collectionid\n      description\n      mngmentoperationdetailid\n      mngmentoperationid\n      ndocument\n      operatiune\n      norder\n      operationcodeid\n      value\n      debit\n      credit\n      businessid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f63cbe6e7ccb10a6dc7c2158081452e1';

module.exports = node;
