import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapytypeInput!)
{
  fnUpstherapytype(input: $input) {
    therapytype {
      nameEn
      nameRo
      ntherapysession
      ntherapysession1
      symbol
      therapytypeid
    }
  }
}
`
 