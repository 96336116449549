import React from 'react'
// import backgrd from '../../../static/backgrd/oscar5-backgrd.jpg'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

export default ({dbdata, params}) => {
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
                <h3 style={{margin: '1.875rem'}}>Search component page</h3>
            </div>
        </div>
    )
}

