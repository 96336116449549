import React from 'react'

import Styles from '../../../uioscar/tablelib/styleTable'

export default ({params, events}) => {
  return (
    <Styles>
      {/* <h3 style={{margin: '1.875rem'}}>configurare aplicatie diagram</h3> */}
    </Styles>
  )
}
