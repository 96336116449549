import styled from 'styled-components'

export default styled.div`
  padding: 1px;

  .table {
    ${'' /* These styles are suggested for the table fill all available space in its containing element */}
    display: block;
    ${'' /* These styles are required for a horizontaly scrollable table overflow */}
    // // // overflow: auto;

    border-spacing: 0;
    border: 1px solid silver;

    .thead {
      ${'' /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
      ${'' /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
      overflow-y: hidden;
      // height: 250px;
      // background: red;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid silver;
      // background: gray;
    }

    .th,
    .td {
      margin: 0;
      padding: 1px;
      border-right: 0px solid black;

      ${'' /* in this example we use an absolutely position resizer, so this is required. */}
      position: relative;
      // background: green;

      :last-child {
        border-right: 0;
      }

      // .tfoot {
      //   ${'' /* These styles are required for a scrollable body to align with the header properly */}
      //   height: 20px;
      // }
      tfoot {
        tr:first-child {
          td {
            border-top: 2px solid black;
          }
        }
        font-weight: bolder;
        // background: green;
      }

      .resizer {
        right: 0;
        background: sienna;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }

    .td1 {
      margin: 0;
      padding: 1px;
      border-right: 0px solid black;

      ${'' /* in this example we use an absolutely position resizer, so this is required. */}
      //position: relative;
      background: green;
    }
  }
`

