
export default [
    [
        {field: 'subscriptionid', value: 'subscriptionid'},
        {field: 'therapysessionid', value: 'therapysessionid'},
        {field: 'patient', value: 'patient'},
        {field: 'patientid', value: 'patientid'},
        {field: 'firstnamepatient', value: 'firstnamepatient'},
        {field: 'lastnamepatient', value: 'lastnamepatient'},
        {field: 'extensivepatient', value: 'extensivepatient'},
        {field: 'birthyearpatient', value: 'birthyearpatient'},
        {field: 'weightpatient', value: 'weightpatient'},
        {field: 'heightpatient', value: 'heightpatient'},
        {field: 'phonepatient', value: 'phonepatient'},
        {field: 'emailpatient', value: 'emailpatient'},
        {field: 'cnppatient', value: 'cnppatient'},
        {field: 'date', value: 'date'},


        {field: 'therapistid', value: 'therapistid'},
        {field: 'therapist', value: 'therapist'},
        {field: 'firstname', value: 'firstname'},
        {field: 'lastname', value: 'lastname'},
        {field: 'extensive', value: 'extensive'},
        {field: 'symbol', value: 'symbol'},
        {field: 'code', value: 'code'},
        {field: 'phone', value: 'phone'},
        {field: 'email', value: 'email'},
        {field: 'cnp', value: 'cnp'},
        {field: 'percenttherapists', value: 'percenttherapists'},

        {field: 'therapypriceid', value: 'therapypriceid'},
        {field: 'nametherapyprice', value: 'nametherapyprice'},
        {field: 'therapyid', value: 'therapyid'},
        {field: 'nameRotherapy', value: 'nameRotherapy'},
        {field: 'nameEntherapy', value: 'nameEntherapy'},
        {field: 'symboltherapy', value: 'symboltherapy'},
        {field: 'nordertherapy', value: 'nordertherapy'},
        {field: 'descriptiontherapy', value: 'descriptiontherapy'},
        {field: 'therapy2Id', value: 'therapy2Id'},
        {field: 'nameRo2Therapy', value: 'nameRo2Therapy'},
        {field: 'nameEn2Therapy', value: 'nameEn2Therapy'},
        {field: 'symbol2Therapy', value: 'symbol2Therapy'},
        {field: 'norder2Therapy', value: 'norder2Therapy'},
        {field: 'description2Therapy', value: 'description2Therapy'},
        {field: 'therapytypeid', value: 'therapytypeid'},
        {field: 'nameRotherapytype', value: 'nameRotherapytype'},
        {field: 'nameEntherapytype', value: 'nameEntherapytype'},
        {field: 'symboltherapytype', value: 'symboltherapytype'},
        {field: 'ntherapysessiontherapytype', value: 'ntherapysessiontherapytype'},
        {field: 'ntherapysession1Therapytype', value: 'ntherapysession1Therapytype'},
        {field: 'timeid', value: 'timeid'},
        {field: 'symboltime', value: 'symboltime'},
        {field: 'minutestime', value: 'minutestime'},
        {field: 'unitstime', value: 'unitstime'},
        {field: 'time2Id', value: 'time2Id'},
        {field: 'symboltime2', value: 'symboltime2'},
        {field: 'minutestime2', value: 'minutestime2'},
        {field: 'unitstime2', value: 'unitstime2'},
        {field: 'time', value: 'time'},
        {field: 'collectionId', value: 'collectionId'},
        {field: 'collectionid', value: 'collectionid'},
        {field: 'namerocollection', value: 'namerocollection'},
        {field: 'nameencollection', value: 'nameencollection'},
        {field: 'symbolcollection', value: 'symbolcollection'},
        {field: 'name', value: 'name'},
        {field: 'ntherapysession', value: 'ntherapysession'},
        {field: 'ntherapysession1', value: 'ntherapysession1'},
        {field: 'mofntherapysession', value: 'mofntherapysession'},
        {field: 'nbmonths', value: 'nbmonths'},
        {field: 'namesubscriptionmodes', value: 'namesubscriptionmodes'},
        {field: 'description', value: 'description'},
        {field: 'subscriptionmodeid', value: 'subscriptionmodeid'},
        {field: 'finalizat', value: 'finalizat'},
        {field: 'subscriptioncount', value: 'subscriptioncount'},
    ],
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
