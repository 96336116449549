/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDelmngmentoperationInput = {|
  clientMutationId?: ?string,
  pMngmentoperationid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDelmngmentoperationInput
|};
export type mutationDelMutationResponse = {|
  +fnDelmngmentoperation: ?{|
    +mngmentoperation: ?{|
      +date: any,
      +houseregistercurrent: any,
      +houseregisterprevious: any,
      +mngmentoperationid: any,
      +monthkindofid: number,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDelmngmentoperationInput!
) {
  fnDelmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDelmngmentoperationPayload",
    "kind": "LinkedField",
    "name": "fnDelmngmentoperation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperation",
        "kind": "LinkedField",
        "name": "mngmentoperation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregistercurrent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregisterprevious",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthkindofid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "858888033987360ac35dd89c14640e7f",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDelmngmentoperationInput!\n) {\n  fnDelmngmentoperation(input: $input) {\n    mngmentoperation {\n      date\n      houseregistercurrent\n      houseregisterprevious\n      mngmentoperationid\n      monthkindofid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f42f620d604530bd8ae5f215854d8b36';

module.exports = node;
