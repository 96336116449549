import React from 'react'
import Style from './style.js'
import AmurItem from './amuritem'
import AmurInfo from './amurinfo'

// https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units
// https://styled-components.com/docs/basics
// https://styled-components.com/docs/advanced
// https://styled-components.com/docs/api

export default ({images, }) => {
    const [nimg, setnimg] = React.useState(0)
   
    return(
        <Style>
            <div>
                <section className="gallery">
                    { images.map((image, index) => <AmurItem key={`Amur${index}`} params={{image, index, nimg, setnimg}} />) }
                </section>
                <AmurInfo />
            </div>
        </Style>
    )
}
