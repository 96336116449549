import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: DtUpsutilizatorInput!)
{
  dtUpsutilizator(input: $input) {
        utilizator {
          activ
          cnp
          email
          parola
          telefon
          username
          utilizator
          utilizatorid
        }
    }
}
`
 