import styled from 'styled-components'

export const StyleAdministrare = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`
export const StyleMenuAdmin1 = styled.div`
  display: grid;
  grid-column: 1;
  grid-template-rows: repeat(1, 1fr);
`
export const StyleMenuAdmin2 = styled.div`
  grid-column: 2;
  height: 365px;
`
export const StyleMenuAdmin3 = styled.div`
  grid-column: 3;
`
export const StyleMenuAdmin4 = styled.div`
  display: grid;
  grid-column: 4;
  grid-template-rows: repeat(1, 1fr);
`

export const StyleMenuAdministrare = styled.div`
  grid-row: 1;
`
export const StyleMenuAdministrare1 = styled.div`
  grid-row: 2;
`
export const StyleMenuAdministrare2 = styled.div`
  grid-row: 3;
`
export const StyleMenuAdministrare3 = styled.div`
  grid-row: 4;
`
export const StyleMenuAdministrare4 = styled.div`
  grid-row: 5;
`
export const StyleMenuAdministrare5 = styled.div`
  grid-row: 6;
`
export const StyleMenuAdministrare6 = styled.div`
  grid-row: 7;
`
export const StyleMenuAdministrare7 = styled.div`
  grid-row: 8;
`
export const StyleMenuAdministrare8 = styled.div`
  grid-row: 9;
`
export const StyleMenuAdministrare9 = styled.div`
  grid-row: 10;
`
export const StyleMenuAdministrare10 = styled.div`
  grid-row: 11;
`
export const StyleMenuAdministrare11 = styled.div`
  grid-row: 12;
`
export const StyleMenuAdministrare12 = styled.div`
  grid-row: 13;
`
export const StyleMenuAdministrare13 = styled.div`
  grid-row: 14;
`
export const StyleMenuAdministrare14 = styled.div`
  grid-row: 15;
`
export const StyleMenuAdministrare15 = styled.div`
  grid-row: 16;
`
export const StyleMenuAdministrare16 = styled.div`
  grid-row: 17;
`
export const StyleMenuAdministrare17 = styled.div`
  grid-row: 18;
`
export const StyleMenuAdministrare18 = styled.div`
  grid-row: 19;
`
