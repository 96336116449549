import React from 'react'
import { Link } from 'react-router-dom' 
import TextIcon from '../../../../static/svgr/icontext'
import enummapps from '../../../../enums/mapps'
import enumRols from '../../../../enums/rols'

import { StyleMenuAdministrare, } from '../../../../styles/styles/adminstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'
// import enummapps from '../../../../enums/mapps'
// import enumRols from '../../../../enums/rols'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabilutilizatori = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0) 
        : false
    const visible = isresponsabilutilizatori && appid === enummapps.utilizatoriapp.key
    // console.log(`[administrare.adminmenu.adminlinks4.adminoscar] - rols: ${JSON.stringify(rols)} => isresponsabilutilizatori: ${isresponsabilutilizatori}, visibleutilizatoriimportfromxls: ${visibleutilizatoriimportfromxls}`)
  
    return (
        <>
            <StyleMenuAdministrare>
                {
                    visible ?
                        <> 
                            <Link to='/adminUtilizatori'>
                                <TextIcon params = {{ text: visible ? 'Utilizatori[1]' : '', title: 'adminUtilizatori', 
                                    width: theme.theme_main.widthTextIconAdminMenu, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare>
        </>
    )
}