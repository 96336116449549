/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpstherapisttherapypriceInput = {|
  clientMutationId?: ?string,
  pTherapisttherapypriceid: number,
  pTherapistid: number,
  pTherapypriceid: number,
  pPercent: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpstherapisttherapypriceInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpstherapisttherapyprice: ?{|
    +therapisttherapyprice: ?{|
      +percent: any,
      +therapistid: number,
      +therapisttherapypriceid: any,
      +therapypriceid: number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpstherapisttherapypriceInput!
) {
  fnUpstherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpstherapisttherapypricePayload",
    "kind": "LinkedField",
    "name": "fnUpstherapisttherapyprice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapisttherapyprice",
        "kind": "LinkedField",
        "name": "therapisttherapyprice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapisttherapypriceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapypriceid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db44161f6368263016ab7152b49d232a",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpstherapisttherapypriceInput!\n) {\n  fnUpstherapisttherapyprice(input: $input) {\n    therapisttherapyprice {\n      percent\n      therapistid\n      therapisttherapypriceid\n      therapypriceid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b132accf04c7afee0f06efabac887275';

module.exports = node;
