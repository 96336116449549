import LoginForm from './loginForm'
import Logout from './logout'
import React from 'react'
import Relay from 'react-relay'
import db from '../../../app/db'
import debugDeveloperUser from './debugDeveloperUser'
import enumAuth from '../../../enums/authenticated'
import enumRols from '../../../enums/rols'
import enummapps from '../../../enums/mapps'
import graphql from 'babel-plugin-relay/macro'
import months from '../../../enums/months'

const UtilizatorComponent = ({dbdata, params, events }) => {
  const {appid, dbkey, utilizatorOscar: {utilizatorlogin, utilizatormodelat: { isauthenticated, username, therapistid, } }, usernamemodelat, setutilizatorOscar, setbusinesstherapistid, } = params
  // console.log(`[utilizatorlogin] - { isauthenticated, username, }: ${JSON.stringify({ isauthenticated, username, })}`)

  // utilizator care se conecteaza la aplicatie cu [username, passwd]
  const dbutilizators = dbdata.loginutilizators === undefined ? null
      : dbdata.loginutilizators.edges.length !== 0 ? dbdata.loginutilizators.edges[0].node : null
  // console.log(`[utilizatorlogin.dbdata.loginutilizators] - dbdata.loginutilizators: ${JSON.stringify(dbdata.loginutilizators)}, username: ${JSON.stringify(username)}`)
  // console.log(`[utilizatorlogin.dbutilizators] - dbutilizators: ${JSON.stringify(dbutilizators)}, username: ${JSON.stringify(username)}`)
  
  const dbutilizatorModelat = dbdata.vutilizators === undefined ? null
      : dbdata.vutilizators.edges.length !== 0 ? dbdata.vutilizators.edges[0].node : null
  // console.log(`[utilizatorlogin] - dbutilizatorModelat: ${JSON.stringify(dbutilizatorModelat)}` )
  
  // // lista aplicatii utilizator care se conecteaza la aplicatie
  // const dbvutilizatorapps = appid !== enummapps.mhappyworld.key ? [] 
  //   : dbdata.vutilizatorapps === undefined ? null : dbdata.vutilizatorapps.edges.map(edge => edge.node)
  // // console.log(`[utilizatorlogin] - dbvutilizatorapps: ${JSON.stringify(dbvutilizatorapps)}` )

  // menu mapp(appid)
  const dbvappmenus = dbdata.vappmenus === undefined ? null : dbdata.vappmenus.edges.map(edge => edge.node)
  // console.log(`[utilizatorlogin] - dbvappmenus: ${JSON.stringify(dbvappmenus)}` )

  const dbvutilizatortherapists = dbdata.vutilizatortherapists === undefined ? null : dbdata.vutilizatortherapists.edges.map(edge => edge.node)
  const dbtherapistid = dbvutilizatortherapists[0] !== undefined ? parseInt(dbvutilizatortherapists[0].therapistid) : -10
  // console.log(`[utilizatorlogin] - dbtherapistid: ${dbtherapistid}, dbvutilizatortherapists: ${JSON.stringify(dbvutilizatortherapists)}, dbvutilizatortherapists[0]: ${JSON.stringify(dbvutilizatortherapists[0])}` )

  // roluri(drepturi) utilizator care se conecteaza la aplicatie
  const rols = dbdata.vutilizatorapprols.edges.map(edge => ({rolid: edge.node.rolid}))
  // console.log(`[utilizatorlogin] - rols: ${JSON.stringify(rols)}`)
  const visible = isauthenticated === enumAuth.isauthenticated.value

  // vcurrentmonths
  if(dbdata.vcurrentmonths !== undefined)
    dbdata.vcurrentmonths.edges.map(edge => {
      // params.setselmonth(edge.node.month)
      if(params.selyear !== edge.node.year.toString()) params.setselyear(edge.node.year)
      if(params.selmonth.selmonthid !== edge.node.month.toString()) params.setselmonth({selmonthid: edge.node.month.toString(), monthname: months[ edge.node.month]})
      if(params.selweek !== edge.node.week.toString()) params.setselweek(edge.node.week)

      if(params.selyearmng !== edge.node.yearmng.toString()) params.setselyearmng(edge.node.yearmng)
      if(params.selmonthmng.selmonthid !== edge.node.monthmng.toString())params.setselmonthmng({selmonthid: edge.node.monthmng.toString(), monthname: months[ edge.node.monthmng]})
      if(params.selweekmng !== edge.node.weekmng.toString())params.setselweekmng(edge.node.weekmng)

      return true
    })
  // console.log(`[utilizatorlogin] - vcurrentmonths: ${JSON.stringify(dbdata.vcurrentmonths.edges)}` )

  // login utilizator
  React.useEffect(() => {
    if (dbutilizators !== null) {  // date utilizator din db
      // console.log(`[utilizatorlogin.dbutilizators - ghita] - dbutilizators: ${JSON.stringify(dbutilizators)}, username: ${JSON.stringify(username)},  dbtherapistid: ${dbtherapistid}, therapistid: ${therapistid}`)
      if (dbutilizators.username.toLowerCase() === username.toLowerCase())  {        
        // lista aplicatii utilizator care se conecteaza la aplicatie
        const dbvutilizatorapps = appid !== enummapps.mhappyworld.key ? [] 
          : dbdata.vutilizatorapps === undefined ? null : dbdata.vutilizatorapps.edges.map(edge => edge.node)
        // console.log(`[utilizatorlogin] - dbvutilizatorapps: ${JSON.stringify(dbvutilizatorapps)}` )

        // utilizator autentificat : se completeaza restul datelor din DB: {rols, apps, menuapps}
        const _utilizatorOscar = {
          utilizatorlogin: {...dbutilizators, isauthenticated: enumAuth.isauthenticated.value, }, 
          utilizatormodelat: { ...dbutilizatorModelat, isauthenticated: enumAuth.isauthenticated.value, rols, 
            apps: dbvutilizatorapps, menuapps: dbvappmenus, therapistid : dbtherapistid,
            modeleaza: rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Modelare.key).length > 0 : false, },
          }
        // console.log(`[utilizatorlogin.useEffect.login utilizator] - therapistid: ${therapistid}, _utilizatorOscar: ${JSON.stringify(_utilizatorOscar)}`)
        setutilizatorOscar(_utilizatorOscar)
        setbusinesstherapistid(dbtherapistid)
      }
    }
  },
  [dbutilizators, dbvappmenus, rols, setutilizatorOscar, username, 
    appid, dbdata.vutilizatorapps,
    dbutilizatorModelat, therapistid, dbtherapistid, setbusinesstherapistid])      

  // login utilizator modelat
  React.useEffect(() => {
  if (usernamemodelat !== null) {  // date utilizator din db
    if (username.toLowerCase() !== usernamemodelat.toLowerCase())  {  
        // lista aplicatii utilizator care se conecteaza la aplicatie
        const dbvutilizatorapps1 = appid !== enummapps.mhappyworld.key ? [] 
          : dbdata.vutilizatorapps === undefined ? null : dbdata.vutilizatorapps.edges.map(edge => edge.node)
        // console.log(`[utilizatorlogin] - dbvutilizatorapps: ${JSON.stringify(dbvutilizatorapps)}` )
            
      // utilizator modelat : se actualizeaza restul datelor din DB: {rols, apps, menuapps}
      const _utilizatorOscar = {
        utilizatorlogin: utilizatorlogin, 
        utilizatormodelat: { ...dbutilizatorModelat, isauthenticated: enumAuth.isauthenticated.value, rols, 
          apps: dbvutilizatorapps1, menuapps: dbvappmenus, therapistid: dbtherapistid, }}
      // console.log(`[utilizatorlogin.useEffect.login utilizator modelat - ghita] - _utilizatorOscar: ${JSON.stringify(_utilizatorOscar)}`)
      setutilizatorOscar(_utilizatorOscar)
      setbusinesstherapistid(dbtherapistid)
    }
  }
},
[dbvappmenus, rols, setutilizatorOscar, username, dbutilizatorModelat, 
  appid, dbdata.vutilizatorapps,
  usernamemodelat, utilizatorlogin, therapistid, dbtherapistid, setbusinesstherapistid])      

// const dbkey = 0
const {cluster, dbNAME, dbcount} = db(dbkey)
const dbservice = `[${cluster}]@${dbNAME}`
// console.log(`[utilizatorlogin] - dbcount: ${dbcount}`)
  return (
    visible ? <Logout params = {{dbcount, dbservice, ...params}} events ={events} />
      : <LoginForm params = {{dbcount, dbservice, Username: debugDeveloperUser.username, Parola: debugDeveloperUser.parola}} events ={events} />            
  )
}

const fragments = {
  dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment utilizatorlogin_dbdata on Query @argumentDefinitions(
      pUsername: {type: String, defaultValue: "", } 
      pParola: {type: String, defaultValue: "", }
      pUsernamermodelat: {type: String, defaultValue: "", } 
      pAppid: {type: BigInt}
    )
    {
      loginutilizators(pUsername: $pUsername, pParola: $pParola) {
        edges {
          node {
            # activ
            # cnp
            # email
            #parola
            # telefon
            username
            # utilizator
            # utilizatorid
            # datainceput
            # datasfarsit
          }
        }
      }
      vutilizators(condition: {username: $pUsernamermodelat}) { 
        edges {
          node {
            # activ
            # cnp
            # email
            #parola
            # telefon
            username
            # utilizator
            utilizatorid
            # datainceput
            # datasfarsit
            #grefresh
          }
        }
      }
      vutilizatorapps(condition: {username: $pUsernamermodelat}) {
        edges {
          node {
            # utilizatorid
            # utilizator
            # username
            # email
            # cnp
            # telefon
            # activ
            # parola
            appid
            app
            link
            # ordonare
          }
        }      
      }
      vutilizatorapprols(condition: {username: $pUsernamermodelat, appid: $pAppid}){
        edges {
          node {
            # approlutilizatorid
            # utilizatorid
            # utilizator
            username
            # email
            # cnp
            # telefon
            # activ
            # parola
            appid
            # app
            # link
            # ordonare
            rolid
            # rol
          }
        }
      }
      vutilizatortherapists(condition: {username: $pUsernamermodelat}) {
        edges {
          node {
            utilizatorid
            username
            therapistid
            lastname
            firstname
          }
        }
      }
      vappmenus(condition: {appid: $pAppid}, orderBy: ORDONARE_ASC) {
        edges {
          node {
            # appmenuid
            # appid
            denumire
            # simbol
            # ordonare
          }
        }
      }      
      vcurrentmonths {
        edges {
          node {
            currentmonthid
            year
            month
            week
            day
            yearmng
            monthmng
            weekmng
            daymng
          }
        }
      }
    }
    `
}

export default Relay.createFragmentContainer(UtilizatorComponent, fragments)
