/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddoperationcodespecificraportInput = {|
  clientMutationId?: ?string,
  pOperationcodeid: number,
  pFeloperationcodeid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddoperationcodespecificraportInput
|};
export type mutationAddMutationResponse = {|
  +fnAddoperationcodespecificraport: ?{|
    +operationcodespecificraport: ?{|
      +feloperationcodeid: number,
      +operationcodeid: number,
      +operationcodespecificraportid: any,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddoperationcodespecificraportInput!
) {
  fnAddoperationcodespecificraport(input: $input) {
    operationcodespecificraport {
      feloperationcodeid
      operationcodeid
      operationcodespecificraportid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddoperationcodespecificraportPayload",
    "kind": "LinkedField",
    "name": "fnAddoperationcodespecificraport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Operationcodespecificraport",
        "kind": "LinkedField",
        "name": "operationcodespecificraport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feloperationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodespecificraportid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b888ecbb3adac931f00ac2d25e61a21d",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddoperationcodespecificraportInput!\n) {\n  fnAddoperationcodespecificraport(input: $input) {\n    operationcodespecificraport {\n      feloperationcodeid\n      operationcodeid\n      operationcodespecificraportid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49d082eae9bb64aaf21b8f92db259d0c';

module.exports = node;
