/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpsoperationcodespecificraportInput = {|
  clientMutationId?: ?string,
  pOperationcodespecificraportid: number,
  pOperationcodeid: number,
  pFeloperationcodeid: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpsoperationcodespecificraportInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpsoperationcodespecificraport: ?{|
    +operationcodespecificraport: ?{|
      +feloperationcodeid: number,
      +operationcodeid: number,
      +operationcodespecificraportid: any,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpsoperationcodespecificraportInput!
) {
  fnUpsoperationcodespecificraport(input: $input) {
    operationcodespecificraport {
      feloperationcodeid
      operationcodeid
      operationcodespecificraportid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpsoperationcodespecificraportPayload",
    "kind": "LinkedField",
    "name": "fnUpsoperationcodespecificraport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Operationcodespecificraport",
        "kind": "LinkedField",
        "name": "operationcodespecificraport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feloperationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodespecificraportid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cbd5b4f60931d77113baec0fb4a22c5",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpsoperationcodespecificraportInput!\n) {\n  fnUpsoperationcodespecificraport(input: $input) {\n    operationcodespecificraport {\n      feloperationcodeid\n      operationcodeid\n      operationcodespecificraportid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47fb5b862103c46e3072d9dda376af3a';

module.exports = node;
