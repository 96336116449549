/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddtherapistInput = {|
  clientMutationId?: ?string,
  pFirstname: string,
  pLastname: string,
  pExtensive: string,
  pSymbol: string,
  pCode: string,
  pPhone: string,
  pEmail: string,
  pCnp: string,
  pPercent: number,
  pCalendarcss: string,
|};
export type mutationAddMutationVariables = {|
  input: FnAddtherapistInput
|};
export type mutationAddMutationResponse = {|
  +fnAddtherapist: ?{|
    +therapist: ?{|
      +cnp: string,
      +code: ?string,
      +email: ?string,
      +extensive: ?string,
      +firstname: string,
      +lastname: string,
      +percent: any,
      +phone: string,
      +symbol: string,
      +therapistid: any,
      +activ: boolean,
      +calendarcss: ?string,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddtherapistInput!
) {
  fnAddtherapist(input: $input) {
    therapist {
      cnp
      code
      email
      extensive
      firstname
      lastname
      percent
      phone
      symbol
      therapistid
      activ
      calendarcss
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddtherapistPayload",
    "kind": "LinkedField",
    "name": "fnAddtherapist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapist",
        "kind": "LinkedField",
        "name": "therapist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarcss",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb48e9366a0c287ef85f5a0c32981fc9",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddtherapistInput!\n) {\n  fnAddtherapist(input: $input) {\n    therapist {\n      cnp\n      code\n      email\n      extensive\n      firstname\n      lastname\n      percent\n      phone\n      symbol\n      therapistid\n      activ\n      calendarcss\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13d69031a5e256929f467cf4d1da897c';

module.exports = node;
