import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $condition: VrtherapistsessionCondition
  $conditiontherapist: VtherapistCondition
  $conditionyear: VmngmentoperationmonthCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh 
  condition: $condition
  conditiontherapist: $conditiontherapist
  conditionyear: $conditionyear) 
}
`

