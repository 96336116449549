import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationDelMutation($input: FnDelappInput!)
{
  fnDelapp(input: $input) {
    app {
      simbol
      link
      appid
      denumire
      ordonare
    }
  }
}
`
