import React from 'react'
import { Link } from 'react-router-dom' 
import enummapps from '../../../../enums/mapps'
import enumRols from '../../../../enums/rols'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuAdministrare3, } from '../../../../styles/styles/adminstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}}, events}) => {
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    const visible = isDeveloper && appid === enummapps.utilizatoriapp.key

    return (
        <>
            <StyleMenuAdministrare3>
                {
                    visible ?
                        <Link to='/TODOs'>
                            <TextIcon params = {{ text: "TODOs", title: 'TODOs', 
                                width: theme.theme_main.widthTextIconAdminMenu, 
                                fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                color: theme.theme_main.headercolor}} />
                        </Link>
                    : <></>
                }
            </StyleMenuAdministrare3>
        </>
    )
}