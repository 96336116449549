
export default [
    [
        {field: 'utilizatorid', value: 'utilizatorid'},
        {field: 'utilizator', value: 'utilizator'},
        {field: 'cnp', value: 'cnp'},
        {field: 'username', value: 'username'},
        {field: 'email', value: 'email'},
        {field: 'telefon', value: 'telefon'},
        {field: 'datainceput', value: 'datainceput'},
        {field: 'datasfarsit', value: 'datasfarsit'},
        {field: 'activ', value: 'activ'},
        {field: 'parola', value: 'parola'},
    ],
    [
        {field: 'utilizatorid', value: 'utilizatorid'},
        {field: 'appid', value: 'appid'},
        {field: 'rolid', value: 'rolid'},
    ],
    [
        {field: 'utilizatormodeleazaid', value: 'utilizatormodeleazaid'},
        {field: 'utilizatorid', value: 'utilizatormodelatid'},
    ]
]
// console.log(`[raportxls] - headers: ${JSON.stringify(headers)}`)
