import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpsroomInput!)
{
  fnUpsroom(input: $input) {
    room {
      etaj
      nameEn
      nameRo
      roomid
      symbol
    }
  }
}
`
 