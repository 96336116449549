import React from 'react'
import { Link } from 'react-router-dom' 

import TextIcon from '../../../../static/svgr/icontext'

import { OscarColumnStyle, MenuCenterStyle, Menuitem } from '../../../../styles/styles/headerstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'
import enummapps from '../../../../enums/mapps'
import menuappOscars from '../../../../app/menuappOscars'
import fmenuapps from '../../../../app/menuapps'
// import enumAuth from '../../../../enums/authenticated'
import enumRols from '../../../../enums/rols'

export default ({params, events}) => {
    const {appid, menuorder, utilizatorOscar: {utilizatormodelat: { apps, menuapps, rols}} } = params
    // console.log(`[header.header.oscar] - appid: ${appid} typeof(appid): ${typeof(appid)}, menuorder: ${menuorder}, {apps, menuapps}: ${JSON.stringify({apps, menuapps})}`)
    const _menuapps = menuappOscars(appid, apps, fmenuapps(menuapps))
    const visible = _menuapps.length > menuorder
    // console.log(`[header.header.oscar] - appid: ${appid}, menuorder: ${menuorder}, _menuapps: ${JSON.stringify(_menuapps)}, 
    //     {apps, menuapps}: ${JSON.stringify({apps, menuapps})}`)

    const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
    // console.log(`[header.header.oscar] - appid: ${appid} typeof(appid): ${typeof(appid)}, menuorder: ${menuorder}, rols: ${JSON.stringify(rols)} => isresponsabilbusiness ${isresponsabilbusiness}`)
  
    
    //     && (isauthenticated === enumAuth.isauthenticated.value)             // utilizator autentificat
    //     // && (rols.filter((item) => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0)  // care are rol 'AdminApp'
    //     && _menuapps.length > menuorder


    // console.log(`[header.header.oscar] - menuorder: ${menuorder}, menuapps: ${JSON.stringify(menuapps)}, menuapps.length: ${menuapps !== undefined ? menuapps.length : 0}`)
    // console.log(`[header.header.oscar] - menuorder: ${menuorder}, _menuapps: ${JSON.stringify(_menuapps)}, _menuapps[menuorder]: ${_menuapps[menuorder]}, _menuapps.length: ${_menuapps !== undefined ? _menuapps.length : 0}`)
    const texticon = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].app   : '' 
    const url = visible ? _menuapps.length === 0 ? '' : _menuapps[menuorder].link   : '' 
    // console.log(`[header.header.oscar] - visible: ${visible}, appid: ${appid}, enummapps.utilizatoriapp.key: ${enummapps.utilizatoriapp.key}, isresponsabilbusiness: ${isresponsabilbusiness}, menuorder: ${menuorder}, texticon: ${texticon}, url: ${url}`)

    return (
        <>
            <OscarColumnStyle><MenuCenterStyle><Menuitem>
                {
                    visible ?
                        <> 
                            {appid === enummapps.mhappyworld.key ?
                                <a href={url}>
                                    <TextIcon params = {{ text: texticon, title: texticon, 
                                        width: theme.theme_main.withTextIconMenu, 
                                        fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                        color: theme.theme_main.headercolor}} />
                                </a>
                            : appid === enummapps.utilizatoriapp.key ?
                                <>
                                    {isresponsabilbusiness ? 
                                        <Link to='/patients'>
                                            <TextIcon params = {{ text: texticon, title: 'pacienti', 
                                                width: theme.theme_main.withTextIconMenu*0.65, 
                                                fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                                color: theme.theme_main.headercolor}} />
                                        </Link>
                                    : <></>
                                    }
                                </>
                            : 
                                <Link to='/oscar'>
                                    <TextIcon params = {{ text: texticon, title: 'oscar', 
                                        width: theme.theme_main.withTextIconMenu, 
                                        fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                        color: theme.theme_main.headercolor}} />
                                </Link>}
                        </>
                    : <> </>
                }
            </Menuitem></MenuCenterStyle></OscarColumnStyle>
        </>
    )
}