import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdoperationcodeInput!)
{
  fnUpdoperationcode(input: $input) {
    operationcode {
      code
      name
      norder
      operationcodeid
      operationkindofid
      symbol
    }
  }
}
`

