import React from 'react'
import { AdministrareColumnStyle, MenuitemIcon } from '../../../../styles/styles/headerstyle'
import Adminicon from './adminicon'
// import AdminMenuAppicon from './adminMenuAppicon'
import Modelareicon from './modelareicon'
// import Flag from './flags'

export default ({params, events}) => {

    return (
        <AdministrareColumnStyle><MenuitemIcon>
            {/* <Flag params = {params} events={events} /> */}
            <Adminicon params = {params} events={events} />
            {/* <AdminMenuAppicon params = {params} events={events} /> */}
            <Modelareicon params = {params} events={events} />
        </MenuitemIcon></AdministrareColumnStyle> 
    )
}
