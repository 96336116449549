import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpdFieldMutation($input: FnUpdhourInput!)
{
  fnUpdhour(input: $input) {
    hour {
      hourid
      name
      symbol
    }
  }
}
`

