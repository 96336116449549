import { Link } from 'react-router-dom'
import React from 'react'
import { StyleMenuReport1, } from '../../../../styles/styles/reportstyle'
import TextIcon from '../../../../static/svgr/icontext'
import enumRols from '../../../../enums/rols'
import enummapps from '../../../../enums/mapps'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}, }, events}) => {
    const isresponsabil = rols !== undefined ? (
        rols.filter(item => parseInt(item.rolid) === enumRols.contabil.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 ||
        rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0
        ) 
        : false
    const visible = isresponsabil && appid === enummapps.utilizatoriapp.key 

    return (
        <>
            <StyleMenuReport1>
                {
                    visible ?
                        <> 
                            <Link to='/raportLorena'>
                                <TextIcon params = {{ text: visible ? 'raport Lorena'   : '' ,title: 'raportLorena', 
                                    width: theme.theme_main.withTextIconReportMenu, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuReport1>
        </>
    )
}