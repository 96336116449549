import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationUpsMutation($input: FnUpstherapistcollectionInput!)
{
  fnUpstherapistcollection(input: $input) {
    therapistcollection {
      collectionid
      therapistcollectionid
      therapistid
    }
  }
}
`
 