
  export default (id, initialValue, nonApiFields, original, nullvalue) => {
  // valoare de afisat pentru calendar

  const {prevcalendarcell, therapistbackgroundColors} = nonApiFields
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, therapistbackgroundColors: ${JSON.stringify(therapistbackgroundColors)}`)

    const iscalendar = prevcalendarcell !== undefined
    const ishourcalendar = iscalendar && id.slice(0, 1) === 'h'
    const prevcalendarcellvalue = iscalendar ? prevcalendarcell(id, original) : nullvalue
    // if(id === 'h0845')
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, id.slice(0, 1): ${id.slice(0, 1)}, iscalendar: ${iscalendar}, ishourcalendar: ${ishourcalendar}, prevcalendarcellvalue: ${prevcalendarcellvalue}, `)
    
    const initialValues = ishourcalendar ? initialValue!== undefined && initialValue !== null ? initialValue.split('/', 2) : [] : []
    // const initialValueslength = initialValues.length
    
    // if(id === 'h0845')
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, ishourcalendar: ${ishourcalendar}, initialValue: ${initialValue}, initialValue: ${initialValue}, 
    //     initialValues: ${JSON.stringify(initialValues)}, initialValueslength: ${initialValueslength}`)
    const therapistcode = initialValues[0] !== undefined ?  (initialValues[0] + (initialValues[0].length === 2 ? '_' : '' )) : ''
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, ishourcalendar: ${ishourcalendar}, initialValue: ${initialValue}, 
    //   initialValues: ${JSON.stringify(initialValues)}, therapistcode: ${therapistcode}, `)
    const therapycode = initialValues.length === 1 ?  initialValues[0] 
      : initialValues.length === 2 ?  initialValues[1] + (initialValues[1].length === 1 ? '__' : initialValues[1].length === 2 ? '_' : '') : ''

    // if(id === 'h0845')
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, ishourcalendar: ${ishourcalendar}, initialValue: ${initialValue}, 
    //     initialValues: ${JSON.stringify(initialValues)}, therapistcode: ${therapistcode}, therapycode: ${therapycode}`)
    
    const _initialValue = ishourcalendar ? initialValue !== prevcalendarcellvalue && initialValue !== null ? therapistcode : therapycode 
      : initialValue
    
    const isinitialValue = initialValue!== undefined && initialValue!== null && initialValue !== ''
    const _backgroundColor = ishourcalendar && isinitialValue ? therapistbackgroundColors.filter(item => item.symbol === initialValues[0])[0] : null
    const backgroundColor = _backgroundColor !== undefined && _backgroundColor !== null ? _backgroundColor.therapiststyle : {}
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, initialValue: ${initialValue}, backgroundColor: ${JSON.stringify(backgroundColor)}, therapistbackgroundColors: ${JSON.stringify(therapistbackgroundColors)}`)
    
    const therapiststyle = {backgroundColor: backgroundColor, color: 'black', fontWeight: 'bold', }
    const cellstyle = ishourcalendar && isinitialValue ? therapiststyle : undefined
    
    // if(initialValue === 'AB/Rt')
    // console.log(`[uioscar.tablelib.calendarcell] - id: ${id}, initialValues: ${JSON.stringify(initialValues)}, typeof(initialValues): ${typeof(initialValues)}, initialValue: ${initialValue}, prevcalendarcellvalue: ${prevcalendarcellvalue}, backgroundColor: ${backgroundColor}, cellstyle: ${JSON.stringify(cellstyle)}`)

    return {iscalendar, _initialValue, cellstyle}
  }
  
