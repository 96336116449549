import React from 'react'
import { Link } from 'react-router-dom' 
import enumRols from '../../../../enums/rols'

import TextIcon from '../../../../static/svgr/icontext'

import { StyleMenuAdministrare10, } from '../../../../styles/styles/adminstyle'
import theme from '../../../../styles/themes/defaultTheme/theme'

export default ({params: {appid, utilizatorOscar: {utilizatormodelat: {rols}}}, events}) => {
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false
    // const isAdminapp = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.AdminApp.key).length > 0 : false
    // const isresponsabilbusiness = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilbusiness.key).length > 0 : false
    // const isresponsabilmanagement = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.responsabilmanagement.key).length > 0 : false
    // const visible = isDeveloper || isAdminapp || isresponsabilbusiness || isresponsabilmanagement
    const visible = isDeveloper
    // const title = visible ? 'admin utilizator 3/1'   : '' 
    // console.log(`[administrare.adminmenu.adminmappOscar] - title: ${title}`)

    return (
        <>
            <StyleMenuAdministrare10>
                {
                    visible ?
                        <> 
                            <Link to='/pacientdiagram'>
                                <TextIcon params = {{ text: 'usecase diagram pacient', title: 'usecase diagram pacient', 
                                    width: theme.theme_main.widthTextIconAdminMenu, 
                                    fill: theme.theme_main.backgroundTextMenuUnterminated, 
                                    color: theme.theme_main.headercolor}} />
                            </Link>
                        </>
                    : <> </>
                }
            </StyleMenuAdministrare10>
        </>
    )
}

