import React from 'react'

import TextIcon from '../../../static/svgr/icontext'
import { MenuCenterStyle, UtilizatorColumnStyle, Menuitem, } from '../../../styles/styles/headerstyle'
import theme from '../../../styles/themes/defaultTheme/theme'

import Icon from '@mdi/react'
import { mdiLogout } from '@mdi/js';
// https://materialdesignicons.com/cdn/2.0.46/

export default ({params, events}) => {
  const {utilizatorOscar: {utilizatormodelat : {username }}, dbservice, dbcount, } = params
  const {onLogout} = events 
  const dbservicevisible = dbcount > 1 && process.env.NODE_ENV !== 'production'

  return (
    <>
      <>            
        <UtilizatorColumnStyle>
          <Menuitem>
            <MenuCenterStyle>
              {dbservicevisible ? <span style={{color:"red"}} >{dbservice}</span> : <></>}
              <TextIcon params = {{text: username, title: 'username',  width: '250', 
              fill: theme.theme_main.backgroundTextMenuUnterminated, 
              color: theme.theme_main.headercolor}} /> 
              <Icon path={mdiLogout} title="logout" onClick={onLogout} size={1} style={{marginTop:'.1rem'}} horizontal vertical rotate={180} color="black" />
            </MenuCenterStyle>
          </Menuitem>  
        </UtilizatorColumnStyle>      
      </>             
    </>
  )  
}