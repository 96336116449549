
export default [
    {
        operationkindofid: {field: 'operationkindofid', values: ['', '', 'operationkindofid'], width: 10, isLastRow : false},
        name: {field: 'name', values: ['', '', 'name'], width: 40, isLastRow : false},
        symbol: {field: 'symbol', values: ['', '', 'symbol'], width: 40, isLastRow : false},
        norder: {field: 'norder', values: ['', '', 'norder'], width: 10, isLastRow : false},
    }, 
]

