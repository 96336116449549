import React from 'react'
// import './style.css'

export default ({params }) => {
    const [info, setinfo] = React.useState(false)

    return(
        <section className="info">
            <input id="show" type="checkbox"   />
            <label htmlFor="show" onClick={() => setinfo(!info)}>show info</label>
            <section className="info__content">
                <p >Amur leopards are relatively easy to distinguish from other leopard subspecies because, in order to survive the long cold winter, they have a soft coat with long and thick hair. This gives them a fluffy and extremely aesthetically pleasing appearance - just look at them!</p>
                <p>Their beauty has been both a curse and a blessing.</p>
                <p>Their gorgeous coats have made them poached to such an extent that, ten years ago, only about 20 of them were still to be found in the wild.</p>
                <p>At the same time, their charisma has made them popular in zoos or as vanity "pets" for rich people and had raised the interest in saving them from extinction.</p>
                <p>For the past ten years however, their numbers have climbed slowly. There are about 50- 60 cats living in the wild today.</p>
            </section>
        </section>
    )
}
