import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddtherapistpatientInput!)
{
  fnAddtherapistpatient(input: $input) {
    therapistpatient {
      therapistpatientid
      therapistid
      patientid
    }
  }
}
`
