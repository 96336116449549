import React from "react";
import ReactExport from "react-data-export";
// import headers from './headers'

// https://securedeveloper.github.io/react-data-export/examples/simple_excel_export_01.html
// https://www.npmjs.com/package/react-data-export
export default ({dbdata, headers, params}) => {
    const {filename, wsnames} = params
    const {ExcelFile, } = ReactExport
    const {ExcelSheet, ExcelColumn} = ExcelFile
    // console.log(`[xls] - filename: ${filename} wsnames: ${JSON.stringify(wsnames)}`)
    // console.log(`[xls] - filename: ${filename} dbdata.data: ${JSON.stringify(dbdata.data)}`)
    // console.log(`[xls] - filename: ${filename} headers: ${JSON.stringify(headers)}`)

    return (
        <ExcelFile element={<button>Download data</button>} filename={filename}>
            {dbdata.data.map((data, index) => {
                // console.log(`[xls.map] - index: ${index}, wsnames: ${wsnames[index]}, 
                    // data: ${JSON.stringify(data)}, 
                    // headers[index]: ${JSON.stringify(headers[index])}`)
                return (<ExcelSheet key = {`xls${index}`} data={data} name={wsnames[index]}>
                    {headers[index].map(header => <ExcelColumn key={`ExcelColumn`} label={header.value} value={header.field}/>)}
                </ExcelSheet>)
                }
            )}
        </ExcelFile>
    );
}
