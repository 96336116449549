/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { page_dbdata$ref } from "./page_dbdata.graphql";
export type VtherapypriceCondition = {|
  therapypriceid?: ?any,
  norder?: ?number,
  therapyid?: ?number,
  nameRotherapy?: ?string,
  nameEntherapy?: ?string,
  symboltherapy?: ?string,
  nordertherapy?: ?number,
  descriptiontherapy?: ?string,
  activtherapy?: ?boolean,
  therapy2Id?: ?number,
  nameRo2Therapy?: ?string,
  nameEn2Therapy?: ?string,
  symbol2Therapy?: ?string,
  norder2Therapy?: ?number,
  description2Therapy?: ?string,
  activ2Therapy?: ?boolean,
  therapytypeid?: ?number,
  nameRotherapytype?: ?string,
  nameEntherapytype?: ?string,
  symboltherapytype?: ?string,
  ntherapysessiontherapytype?: ?number,
  ntherapysession1Therapytype?: ?number,
  timeid?: ?number,
  symboltime?: ?string,
  minutestime?: ?string,
  unitstime?: ?number,
  time2Id?: ?number,
  symboltime2?: ?string,
  minutestime2?: ?string,
  unitstime2?: ?number,
  time?: ?string,
  price?: ?number,
  name?: ?string,
  description?: ?string,
  activ?: ?boolean,
|};
export type queryQueryVariables = {|
  pRefresh: boolean,
  condition?: ?VtherapypriceCondition,
|};
export type queryQueryResponse = {|
  +$fragmentRefs: page_dbdata$ref
|};
export type queryQuery = {|
  variables: queryQueryVariables,
  response: queryQueryResponse,
|};
*/


/*
query queryQuery(
  $condition: VtherapypriceCondition
) {
  ...page_dbdata_2lJSvL
}

fragment page_dbdata_2lJSvL on Query {
  vtherapyprices(orderBy: NORDER_ASC, condition: $condition) {
    edges {
      node {
        therapypriceid
        norder
        therapyid
        nameRotherapy
        nameEntherapy
        symboltherapy
        nordertherapy
        descriptiontherapy
        therapy2Id
        nameRo2Therapy
        nameEn2Therapy
        symbol2Therapy
        norder2Therapy
        description2Therapy
        therapytypeid
        nameRotherapytype
        nameEntherapytype
        symboltherapytype
        ntherapysessiontherapytype
        ntherapysession1Therapytype
        timeid
        symboltime
        minutestime
        unitstime
        time2Id
        symboltime2
        minutestime2
        unitstime2
        time
        price
        name
        description
        activ
      }
    }
  }
  vtherapies(orderBy: THERAPYID_ASC) {
    edges {
      node {
        therapyid
        nameRo
      }
    }
  }
  vtimes(orderBy: TIMEID_ASC) {
    edges {
      node {
        timeid
        symbol
        minutes
      }
    }
  }
  vtherapytypes(orderBy: THERAPYTYPEID_ASC) {
    edges {
      node {
        therapytypeid
        nameRo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pRefresh"
},
v2 = {
  "kind": "Variable",
  "name": "condition",
  "variableName": "condition"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapyid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "therapytypeid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "pRefresh",
            "variableName": "pRefresh"
          }
        ],
        "kind": "FragmentSpread",
        "name": "page_dbdata"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "NORDER_ASC"
          }
        ],
        "concreteType": "VtherapypricesConnection",
        "kind": "LinkedField",
        "name": "vtherapyprices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapypricesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapyprice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapypriceid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norder",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRotherapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEntherapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboltherapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nordertherapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "descriptiontherapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "therapy2Id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRo2Therapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEn2Therapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol2Therapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "norder2Therapy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description2Therapy",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameRotherapytype",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEntherapytype",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboltherapytype",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ntherapysessiontherapytype",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ntherapysession1Therapytype",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboltime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutestime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitstime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time2Id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symboltime2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutestime2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitstime2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activ",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPYID_ASC"
          }
        ],
        "concreteType": "VtherapiesConnection",
        "kind": "LinkedField",
        "name": "vtherapies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapy",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapies(orderBy:\"THERAPYID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "TIMEID_ASC"
          }
        ],
        "concreteType": "VtimesConnection",
        "kind": "LinkedField",
        "name": "vtimes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtimesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtime",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutes",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtimes(orderBy:\"TIMEID_ASC\")"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "THERAPYTYPEID_ASC"
          }
        ],
        "concreteType": "VtherapytypesConnection",
        "kind": "LinkedField",
        "name": "vtherapytypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VtherapytypesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vtherapytype",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "vtherapytypes(orderBy:\"THERAPYTYPEID_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "5c6abcae1487b3f0125efc9470b0187d",
    "id": null,
    "metadata": {},
    "name": "queryQuery",
    "operationKind": "query",
    "text": "query queryQuery(\n  $condition: VtherapypriceCondition\n) {\n  ...page_dbdata_2lJSvL\n}\n\nfragment page_dbdata_2lJSvL on Query {\n  vtherapyprices(orderBy: NORDER_ASC, condition: $condition) {\n    edges {\n      node {\n        therapypriceid\n        norder\n        therapyid\n        nameRotherapy\n        nameEntherapy\n        symboltherapy\n        nordertherapy\n        descriptiontherapy\n        therapy2Id\n        nameRo2Therapy\n        nameEn2Therapy\n        symbol2Therapy\n        norder2Therapy\n        description2Therapy\n        therapytypeid\n        nameRotherapytype\n        nameEntherapytype\n        symboltherapytype\n        ntherapysessiontherapytype\n        ntherapysession1Therapytype\n        timeid\n        symboltime\n        minutestime\n        unitstime\n        time2Id\n        symboltime2\n        minutestime2\n        unitstime2\n        time\n        price\n        name\n        description\n        activ\n      }\n    }\n  }\n  vtherapies(orderBy: THERAPYID_ASC) {\n    edges {\n      node {\n        therapyid\n        nameRo\n      }\n    }\n  }\n  vtimes(orderBy: TIMEID_ASC) {\n    edges {\n      node {\n        timeid\n        symbol\n        minutes\n      }\n    }\n  }\n  vtherapytypes(orderBy: THERAPYTYPEID_ASC) {\n    edges {\n      node {\n        therapytypeid\n        nameRo\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a2d5f988762ec3ff17a5063fa13750d';

module.exports = node;
