import styled from 'styled-components'
import nimages from '../../app/nimages'
import entitiesdiagram from '../../static/backgrd/entitiesdiagram.jpg'
import sitemapdiagram from '../../static/backgrd/sitemapdiagram.jpg'
import developerdiagram from '../../static/backgrd/UCd_developer.jpg'
import adminappdiagram from '../../static/backgrd/UCd_adminapp.jpg'
import configurareaplicatiediagram from '../../static/backgrd/UCd_configurareaplicatie.jpg'
import contabildiagram from '../../static/backgrd/UCd_contabil.jpg'
import pacientdiagram from '../../static/backgrd/UCd_pacient.jpg'
import responsabilutilizatoridiagram from '../../static/backgrd/UCd_responsabilutilizatori.jpg'
import responsabilmanagementdiagram from '../../static/backgrd/UCd_responsabilmanagement.jpg'
import responsabilbusinessdiagram from '../../static/backgrd/UCd_responsabilbusiness.jpg'
import superadmindiagram from '../../static/backgrd/UCd_superadmin.jpg'
import terapeutdiagram from '../../static/backgrd/UCd_terapeut.jpg'
import TODOs from '../../static/backgrd/TODOs.jpg'

// backdroung images of all page
export const backgrdStyle = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  backgroundSize: '80%', 
  backgroundRepeat: 'no-repeat',
  backgroundColor: `${props => props.theme.theme_main.contentbg}`,

  backgroundImage: `url(/images/_ATO${Math.round(Math.random()*nimages)}.jpg)`, 
  color:'blue', 
  opacity: '100%'
}
export const transbox  = {
  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '95%', 
}
export const transboxentitiesdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${entitiesdiagram})`, 
  backgroundSize: '70%',
  backgroundRepeat: 'no-repeat',
}
export const transboxsitemapdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${sitemapdiagram})`, 
  backgroundSize: '101%',
  backgroundRepeat: 'no-repeat',
}
export const transboxdeveloperdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${developerdiagram})`, 
  backgroundSize: '60%',
  backgroundRepeat: 'no-repeat',
}

export const transboxsuperadmindiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${superadmindiagram})`, 
  backgroundSize: '40%',
  backgroundRepeat: 'no-repeat',
}
export const transboxresponsabilutilizatoridiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${responsabilutilizatoridiagram})`, 
  backgroundSize: '50%',
  backgroundRepeat: 'no-repeat',
}
export const transboxresponsabilbusinessdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${responsabilbusinessdiagram})`, 
  backgroundSize: '75%',
  backgroundRepeat: 'no-repeat',
}
export const transboxresponsabilmanagementdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${responsabilmanagementdiagram})`, 
  backgroundSize: '75%',
  backgroundRepeat: 'no-repeat',
}
export const transboxterapeutdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${terapeutdiagram})`, 
  backgroundSize: '60%',
  backgroundRepeat: 'no-repeat',
}
export const transboxpacientdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${pacientdiagram})`, 
  backgroundSize: '70%',
  backgroundRepeat: 'no-repeat',
}
export const transboxcontabildiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${contabildiagram})`, 
  backgroundSize: '60%',
  backgroundRepeat: 'no-repeat',
}
export const transboxadminappdiagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${adminappdiagram})`, 
  backgroundSize: '99%',
  backgroundRepeat: 'no-repeat',
}
export const transboxconfigurareaplicatiediagram  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${configurareaplicatiediagram})`, 
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
}
export const transboxTODOs  = {
  height: '76.5vh',  // height of page images
  marginTop: '0.25vh',
  marginBottom: '0.25vh',
  backgroundPosition:'center', 
  // backgroundSize: '80%', 

  margin: '1.875rem', 
  backgroundColor: '#eee', 
  border: '1px solid white', 
  opacity: '100%', 
  backgroundImage: `url(${TODOs})`, 
  backgroundSize: '70%',
  backgroundRepeat: 'no-repeat',
}
export const ContainerStyle = styled.div` 
  position: relative;
  width: ${props => props.theme.gridrows.widthpage};
  margin: auto;
`
//ContainerStyle exte peste HeaderStyle, ContentStyle, FooterStyle
export const HeaderStyle = styled.div`
  display: grid;
  grid-template-rows : ${props => props.theme.gridrows.heightheader};
  background: ${props => props.theme.theme_main.headerbg}; 
`
export const ContentStyle = styled.div`
  background: ${props => props.theme.theme_main.contentbg};
`
export const FooterStyle = styled.div`
  display: 'grid';
  grid-template-rows : ${props => props.theme.gridrows.heightfooter};
  background: ${props => props.theme.theme_main.footerbg};
`

