import formatdateymd from "../../../uioscar/formatdateymd"

export default ({dbOptions, dbOptions2, dbOptions4, dbOptions5, dbOptions6, dbOptions7, dbOptions11, }) => ({
  AddDialogtitle: ['Adauga sedinta terapie', ''],
    columns: [
      {header: 'patient', accessor: 'patient', accessorid: 'patientid', type: 'text', show: true, required:true,
        autocomplete: {dbOptions: dbOptions, widthautocomplete: '33.7rem', text: 'Alege pacient ..',}},
      {header: 'firstname', accessor: 'firstname', type: 'text', show: 'true', },
      {header: 'lastname', accessor: 'lastname', type: 'text', show: 'true', },
      // {header: 'therapist', accessor: 'therapist', accessorid: 'therapistid', type: '', show: true, 
      //   autocomplete: {dbOptions: dbOptions1, widthautocomplete: '33.7rem', text: 'Alege terapeut ..',}},
      {header: 'therapyprice', accessor: 'therapyprice', accessorid: 'therapisttherapypriceid', type: 'text', show: true, required:true,  
        autocomplete: {dbOptions: dbOptions4, widthautocomplete: '33.7rem', text: 'Alege terapeut, terapie, pret ..',}},

      {header: 'date', accessor: 'date', type: 'date', show: 'true', required:true, },
      {header: 'fromhour', accessor: 'fromhour', accessorid: 'fromid', type: 'text', show: true, required:true, 
        autocomplete: {dbOptions: dbOptions2, widthautocomplete: '33.7rem', text: 'Alege de la ..',}},
      {header: 'room', accessor: 'room', accessorid: 'roomid', type: 'text', show: true, 
        autocomplete: {dbOptions: dbOptions6, widthautocomplete: '33.7rem', text: 'Alege sala ..',}},
      {header: 'price', accessor: 'value', type: 'number', show: 'true', },
      {header: 'nameRocollection', accessor: 'nameRocollection', accessorid: 'collectionid', type: 'text', show: true, 
        autocomplete: {dbOptions: dbOptions7, widthautocomplete: '33.7rem', text: 'Alege mod incasare ..',}},

      // {header: 'patientnotes', accessor: 'patientnotes', type: 'text', show: 'true', },
      // {header: 'calendar', accessor: 'calendar', accessorid: 'calendarid', type: 'text', show: true, 
      //   autocomplete: {dbOptions: dbOptions5, widthautocomplete: '33.7rem', text: 'Alege calendar ..',}},
      
      // {header: 'therapistnotes', accessor: 'therapistnotes', type: 'text', show: 'true', },
      {header: 'subscription', accessor: 'subscription', accessorid: 'subscriptionid', type: 'text', show: true, 
        autocomplete: {dbOptions: dbOptions11, widthautocomplete: '33.7rem', text: 'Alege abonament ..',}},

    ],
    initialdata: {
      patientid: '', 
      firstname: '', 
      lastname: '', 
      therapisttherapypriceid: '', 
      date: formatdateymd(new Date()), 
      fromid: '', 
      roomid: '',  
      value: '', 
      collectionid: '', 
      // patientnotes: '', 
      // calendarid: '', 
      // therapistnotes: '', 
      subscriptionid: '', 
      subRows: undefined,
    },    
  })
