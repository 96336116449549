import React from 'react'

export default [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', width: 7, // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter to build the expander.
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
      ), 
    },      
    {
      Header: 'therapies',
      columns: [
        { id: 'nameRo', Header: 'nameRo', accessor: 'nameRo', width: 30, type: 'text',},
        { id: 'nameEn', Header: 'nameEn', accessor: 'nameEn', width: 30, type: 'text',},
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'shortsymbol', Header: 'shortsymbol', accessor: 'shortsymbol', width: 30, type: 'text',},
        { id: 'description', Header: 'description', accessor: 'description', width: 30, type: 'text',},
        { id: 'norder', Header: 'norder', accessor: 'norder', width: 30, type: 'number',},
        { id: 'activ', Header: 'activ', accessor: 'activ', width: 30, radiobuttondanu: true, type: '', align: 'left',},
      ]
    },
    {
    Header: 'db info', columns: [     
    { id: 'therapyid', Header: 'therapyid', accessor: 'therapyid', width: 30,  },
  ],
},
]

