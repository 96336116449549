// import React from 'react'

export default ({dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }) => ([
  // {
    //   // Make an expander cell
    //   Header: () => null, // No header
    //   id: 'expander', width: 7, // It needs an ID
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
    //   ), 
    // },      
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions9, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions10, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
      {
      Header: 'session',
      columns: [
        { id: 'therapist', Header: 'therapist', accessor: 'therapist', width: 30, type: 'text', },
              // autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '25rem', text: 'Alege terapeut ..'},},
        { id: 'patient', Header: 'patient', accessor: 'patient', width: 30, type: '',
              autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '25rem', text: 'Alege pacient ..'},},
        // { id: 'therapisttherapyprice', Header: 'therapisttherapyprice', accessor: 'therapisttherapyprice', width: 30, type: 'number', align: 'right',},
        { id: 'therapyprice', Header: 'therapyprice', accessor: 'therapyprice', width: 50, type: 'text', align: 'left', },
              // autocomplete: {autocomplete: true, dbOptions: dbOptions4, widthautocomplete: '45rem', text: 'Alege terapeut, terapie & pret ..'},},
        { id: 'therapy', Header: 'therapy', accessor: 'therapy', width: 30, type: 'text', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions8, widthautocomplete: '25rem', text: 'Alege terapia ..'},},

        { id: 'date', Header: 'date', accessor: 'date', width: 20, type: 'date',},
        { id: 'fromhour', Header: 'from', accessor: 'fromhour', width: 10, type: '',
              autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '12.3rem', text: 'Alege de la ..'},},
        { id: 'tohour', Header: 'to', accessor: 'tohour', width: 10, type: '',
              autocomplete: {autocomplete: true, dbOptions: dbOptions3, widthautocomplete: '12.3rem', text: 'Alege pana la ..'},},

        { id: 'room', Header: 'room', accessor: 'room', width: 20, type: 'text',align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions6, widthautocomplete: '12.3rem', text: 'Alege sala ..'}, },
        { id: 'nameRocollection', Header: 'incasare', accessor: 'nameRocollection', width: 15, type: 'text', align: 'left',
              autocomplete: {autocomplete: true, dbOptions: dbOptions7, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},       
        // { id: 'percent', Header: 'percent', accessor: 'percent', width: 10, type: 'number',align: 'right', },
        { id: 'value', Header: 'value', accessor: 'value', width: 10, type: 'number', align: 'left', },
        // { id: 'calendar', Header: 'calendar', accessor: 'calendar', width: 10, type: 'text',align: 'left',
        // autocomplete: {autocomplete: true, dbOptions: dbOptions5, widthautocomplete: '12.3rem', text: 'Alege calendar ..'}, },
        // { id: 'finalizat', Header: 'finalizat', accessor: 'finalizat', width: 15, radiobuttondanu: true, type: '', align: 'left',},
        // { id: 'thereistherapistpatient', Header: 'exista terapeut asociat?', accessor: 'thereistherapistpatient', width: 15, radiobuttondanu: true, type: '', align: 'left',},
        // { id: 'therapistoperationcode', Header: 'exista code terapeut asociat?', accessor: 'therapistoperationcode', width: 15, radiobuttondanu: true, type: '', align: 'left',},
        { id: 'subscription', Header: 'abonament', accessor: 'subscription', width: 30, type: 'text', align: 'left',
            autocomplete: {autocomplete: true, dbOptions: dbOptions11, widthautocomplete: '12.3rem', text: 'Alege mod incasare ..'},},       
        ]
    },
    {
      Header: 'thereapy details',
      columns: [
        { id: 'patientnotes', Header: 'patientnotes', accessor: 'patientnotes', width: 30, type: 'text', align: 'left', },
        { id: 'therapistnotes', Header: 'therapistnotes', accessor: 'therapistnotes', width: 30, type: 'text', align: 'left', },
      ]
    },
    {
      Header: 'abonament',
      columns: [
        { id: 'namesessionsubscription', Header: 'namesessionsubscription', accessor: 'namesessionsubscription', width: 30, type: 'text', align: 'left', },
        { id: 'descriptionsessionsubscription', Header: 'descriptionsessionsubscription', accessor: 'descriptionsessionsubscription', width: 30, type: 'text', align: 'left', },
      ]
    },
    {
      Header: 'patient',
      columns: [
        { id: 'firstnamepatient', Header: 'firstnamepatient', accessor: 'firstnamepatient', width: 30, type: 'text',},
        { id: 'lastnamepatient', Header: 'lastnamepatient', accessor: 'lastnamepatient', width: 30, type: 'text',},
        { id: 'extensivepatient', Header: 'extensivepatient', accessor: 'extensivepatient', width: 30, type: 'text',},
        { id: 'birthyearpatient', Header: 'birthyearpatient', accessor: 'birthyearpatient', width: 30, type: 'text',},
        { id: 'weightpatient', Header: 'weightpatient', accessor: 'weightpatient', width: 30, type: 'text',},
        { id: 'heightpatient', Header: 'heightpatient', accessor: 'heightpatient', width: 30, type: 'text',},
        { id: 'phonepatient', Header: 'phonepatient', accessor: 'phonepatient', width: 30, type: 'text',},
        { id: 'emailpatient', Header: 'emailpatient', accessor: 'emailpatient', width: 30, type: 'text',},
        { id: 'cnppatient', Header: 'cnppatient', accessor: 'cnppatient', width: 30, type: 'text',},
        ]
    },
    {
      Header: 'therapist',
      columns: [
        { id: 'firstname', Header: 'firstname', accessor: 'firstname', width: 30, type: 'text',},
        { id: 'lastname', Header: 'lastname', accessor: 'lastname', width: 30, type: 'text',},
        { id: 'extensive', Header: 'extensive', accessor: 'extensive', width: 30,  },
        { id: 'symbol', Header: 'symbol', accessor: 'symbol', width: 30, type: 'text',},
        { id: 'code', Header: 'code', accessor: 'code', width: 30, type: 'text',},
        { id: 'phone', Header: 'phone', accessor: 'phone', width: 30, type: 'text',},
        { id: 'email', Header: 'email', accessor: 'email', width: 30, type: 'text',},
        { id: 'cnp', Header: 'cnp', accessor: 'cnp', width: 30, type: 'text',},
        { id: 'percenttherapists', Header: 'percenttherapists', accessor: 'percenttherapists', width: 30, type: 'number',},
]
    },
    {
      Header: 'therapyprice',
      columns: [
        { id: 'nametherapyprice', Header: 'nametherapyprice', accessor: 'nametherapyprice', width: 30, type: 'text', align: 'left',},
        // { id: 'symboltime', Header: 'symboltime', accessor: 'symboltime', width: 30, type: 'text', align: 'right',},
        { id: 'minutestime', Header: 'minutestime', accessor: 'minutestime', width: 30, type: 'text', align: 'right',},
        { id: 'unitstime', Header: 'unitstime', accessor: 'unitstime', width: 30, type: 'text', align: 'right',},
        // { id: 'symboltime2', Header: 'symboltime2', accessor: 'symboltime2', width: 30, type: 'text', align: 'right',},
        { id: 'minutestime2', Header: 'minutestime2', accessor: 'minutestime2', width: 30, type: 'text', align: 'right',},
        { id: 'unitstime2', Header: 'unitstime2', accessor: 'unitstime2', width: 30, type: 'text', align: 'right',},
        { id: 'time', Header: 'time', accessor: 'time', width: 30, type: 'text', align: 'right',},
        { id: 'price', Header: 'price', accessor: 'price', width: 30, type: 'text', align: 'right',},
      ]
    },
    {
      Header: 'therapy',
      columns: [
        { id: 'nameRotherapy', Header: 'nameRotherapy', accessor: 'nameRotherapy', width: 30, type: 'text', align: 'right',},
        { id: 'nameEntherapy', Header: 'nameEntherapy', accessor: 'nameEntherapy', width: 30, type: 'text', align: 'right',},
        { id: 'symboltherapy', Header: 'symboltherapy', accessor: 'symboltherapy', width: 30, type: 'text',},
        { id: 'nordertherapy', Header: 'nordertherapy', accessor: 'nordertherapy', width: 30,  },
        { id: 'descriptiontherapy', Header: 'descriptiontherapy', accessor: 'descriptiontherapy', width: 30, type: 'text',},
        // { id: 'nameEncollection', Header: 'nameEncollection', accessor: 'nameEncollection', width: 30, type: 'text', align: 'right',},
        { id: 'symbolcollection', Header: 'symbolcollection', accessor: 'symbolcollection', width: 30, type: 'text',},
]
    },
    {
      Header: 'subscription',
      columns: [
        { id: 'namesubscription', Header: 'namesubscription', accessor: 'namesubscription', width: 30, type: 'text',},
        { id: 'mofntherapysession', Header: 'mofntherapysession', accessor: 'mofntherapysession', width: 30, type: 'text',},
        { id: 'ntherapysessionsubscription', Header: 'ntherapysessionsubscription', accessor: 'ntherapysessionsubscription', width: 30, type: 'text', align: 'right',},
        { id: 'mofntherapysessionsubscription', Header: 'mofntherapysessionsubscription', accessor: 'mofntherapysessionsubscription', width: 30, type: 'text', align: 'right',},
        { id: 'nbmonthssubscription', Header: 'nbmonthssubscription', accessor: 'nbmonthssubscription', width: 30, type: 'text', align: 'right',},
        { id: 'timesubscription', Header: 'timesubscription', accessor: 'timesubscription', width: 30, type: 'text', align: 'right',},
        { id: 'finalizatsubscription', Header: 'finalizatsubscription', accessor: 'finalizatsubscription', width: 30, type: 'text', align: 'right',},
      ]
    },
    {
      Header: 'room',
      columns: [
        { id: 'nameRoroom', Header: 'nameRoroom', accessor: 'nameRoroom', width: 30, type: 'text',},
        { id: 'nameEnroom', Header: 'nameEnroom', accessor: 'nameEnroom', width: 30, type: 'text', align: 'right',},
        { id: 'symbolroom', Header: 'symbolroom', accessor: 'symbolroom', width: 30, type: 'text', align: 'right',},
        { id: 'etaj', Header: 'etaj', accessor: 'etaj', width: 30, type: 'text', align: 'right',},
        
        { id: 'nameRocalendar', Header: 'nameRocalendar', accessor: 'nameRocalendar', width: 30, type: 'text',},

        { id: 'nameEncalendar', Header: 'nameEncalendar', accessor: 'nameEncalendar', width: 30, type: 'text', align: 'right',},
        { id: 'symbolcalendar', Header: 'symbolcalendar', accessor: 'symbolcalendar', width: 30, type: 'text', align: 'right',},
]
    },
    {
      Header: 'db info', columns: [     
      { id: 'therapysessionid', Header: 'therapysessionid', accessor: 'therapysessionid', width: 30, type: 'number', align: 'right',  },
      { id: 'patientid', Header: 'patientid', accessor: 'patientid', width: 30, type: 'number', align: 'right',  },
      { id: 'therapistid', Header: 'therapistid', accessor: 'therapistid', width: 30, type: 'number', align: 'right',},
      { id: 'sessionsubscriptionId', Header: 'sessionsubscriptionId', accessor: 'sessionsubscriptionId', width: 30, type: 'number', align: 'right',},
      { id: 'sessiontherapysessionId', Header: 'sessiontherapysessionId', accessor: 'sessiontherapysessionId', width: 30, type: 'number', align: 'right',},
      // { id: 'therapisttherapypriceid', Header: 'therapisttherapypriceid', accessor: 'therapisttherapypriceid', width: 30, type: 'number', align: 'right',},
      { id: 'therapypriceid', Header: 'therapypriceid', accessor: 'therapypriceid', width: 30, type: 'number', align: 'right',},
      { id: 'therapyid', Header: 'therapyid', accessor: 'therapyid', width: 30, type: 'number', align: 'right',},
      { id: 'therapy2Id', Header: 'therapy2Id', accessor: 'therapy2Id', width: 30, type: 'number', align: 'right',  },
      { id: 'therapytypeid', Header: 'therapytypeid', accessor: 'therapytypeid', width: 30, type: 'number', align: 'right',},
    ],
  },
    {
      Header: 'db info 2', columns: [     
      { id: 'fromid', Header: 'fromid', accessor: 'fromid', width: 30, type: 'number', align: 'right',},
      { id: 'toid', Header: 'toid', accessor: 'toid', width: 30, type: 'number', align: 'right',},
      { id: 'timeid', Header: 'timeid', accessor: 'timeid', width: 30, type: 'number', align: 'right',},
      { id: 'time2Id', Header: 'time2Id', accessor: 'time2Id', width: 30, type: 'number', align: 'right',},
      { id: 'subscriptionid', Header: 'subscriptionid', accessor: 'subscriptionid', width: 30, type: 'number', align: 'right',},
      { id: 'subscriptionsessionId', Header: 'subscriptionsessionId', accessor: 'subscriptionsessionId', width: 30, type: 'number', align: 'right',},
      { id: 'calendarid', Header: 'calendarid', accessor: 'calendarid', width: 30, type: 'number', align: 'right',  },
      { id: 'roomid', Header: 'roomid', accessor: 'roomid', width: 30, type: 'number', align: 'right',  },
      { id: 'collectionid', Header: 'collectionid', accessor: 'collectionid', width: 30, type: 'number', align: 'right',  },
    ],
  },
])

