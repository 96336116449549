import React from 'react'
import Relay from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'
import Table from './table'
import titles from './pdf/titles'
import enumRols from '../../../enums/rols'

const Page = ({dbdata, params, events}) => {
    const dbvdata = dbdata.vutilizatormodelats === undefined ? null : 
      dbdata.vutilizatormodelats.edges.map(edge => {return{elimina: false, ...edge.node, }})

  // console.log(`[utilizatorModelats.page] - dbvdata: ${JSON.stringify(dbvdata)}` )
    const rols = dbdata.vutilizatorapprols.edges.map(edge => edge.node)
    const isResponsabilUtilizatoriGlobal = rols.filter(item => parseInt(item.rolid) === enumRols.responsabilutilizatori.key).length > 0
    // console.log(`[utilizatorModelats.page] - isResponsabilUtilizatoriGlobal: ${isResponsabilUtilizatoriGlobal}, rols: ${JSON.stringify(rols)}` )
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
              <Table dbdata={{dbvdata: dbvdata}} params = {{...params, titles, isResponsabilUtilizatoriGlobal}} events={events} />
            </div>
        </div>
    )
}
const fragments = {
    dbdata: graphql`
    # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
    fragment page_dbdata on Query @argumentDefinitions(
        putilizatormodeleazaid: {type: BigInt, defaultValue:0 },
        pAppid: {type: BigInt },
        pRefresh: {type: Boolean, defaultValue: false, }      
    ) 
    {
      vutilizatormodelats(condition: {utilizatormodeleazaid: $putilizatormodeleazaid}, orderBy: UTILIZATORID_ASC){
        edges {
          node {
            utilizatormodeleazaid
            utilizatorid
            utilizator
            username
            email
            cnp
            telefon
            datainceput
            datasfarsit
            activ
            parola
            #grefresh
          }
        }
      }
      vutilizatorapprols(condition: {utilizatorid: $putilizatormodeleazaid, appid: $pAppid}){
        edges {
          node {
            # approlutilizatorid
            utilizatorid
            # utilizator
            # username
            # email
            # cnp
            # telefon
            # activ
            # parola
            appid
            # app
            # link
            # ordonare
            rolid
            # rol
          }
        }
      }
    }
    `
}

export default Relay.createFragmentContainer(Page, fragments)

