/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpstherapistInput = {|
  clientMutationId?: ?string,
  pTherapistid: number,
  pFirstname: string,
  pLastname: string,
  pExtensive: string,
  pSymbol: string,
  pCode: string,
  pPhone: string,
  pEmail: string,
  pCnp: string,
  pPercent: number,
  pActiv: boolean,
  pCalendarcss: string,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpstherapistInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpstherapist: ?{|
    +therapist: ?{|
      +cnp: string,
      +code: ?string,
      +email: ?string,
      +extensive: ?string,
      +firstname: string,
      +lastname: string,
      +percent: any,
      +phone: string,
      +symbol: string,
      +therapistid: any,
      +activ: boolean,
      +calendarcss: ?string,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpstherapistInput!
) {
  fnUpstherapist(input: $input) {
    therapist {
      cnp
      code
      email
      extensive
      firstname
      lastname
      percent
      phone
      symbol
      therapistid
      activ
      calendarcss
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpstherapistPayload",
    "kind": "LinkedField",
    "name": "fnUpstherapist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapist",
        "kind": "LinkedField",
        "name": "therapist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cnp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activ",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarcss",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3698f39f445e6e07b83f14ea4f15ac35",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpstherapistInput!\n) {\n  fnUpstherapist(input: $input) {\n    therapist {\n      cnp\n      code\n      email\n      extensive\n      firstname\n      lastname\n      percent\n      phone\n      symbol\n      therapistid\n      activ\n      calendarcss\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f64872f4de92ac9ca255fd2c9b384d6f';

module.exports = node;
