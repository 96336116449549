// import React from 'react'
import columnsabonament from './columnsabonament'
import columnsbusinessresponsabil from './columnsbusinessresponsabil'
import columnstherapist from './columnstherapist'

export default ({isresponsabilbusiness, ptherapysessionid, psubscriptionid, dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }) => 
  ( ptherapysessionid !== undefined ? columnsabonament({dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, })
    :psubscriptionid !== undefined ? columnsbusinessresponsabil({dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }) 
    :isresponsabilbusiness ? columnsbusinessresponsabil({dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }) 
    :columnstherapist({dbOptions, dbOptions2, dbOptions3, dbOptions5, dbOptions6, dbOptions7, dbOptions8, dbOptions9, dbOptions10, dbOptions11, }))
