import graphql from 'babel-plugin-relay/macro';

export default graphql`
query queryQuery(
  $pRefresh: Boolean!
  $condition: VmngmentoperationdetailCondition
  $conditionyear: VmngmentoperationmonthCondition
  $conditionVenituriIncasariyear: VrvenituricheltuieliCondition
  $conditionVenituriIncasariyeardesc: VrvenituricheltuielidescCondition
  $conditioncashflow: VrcashflowCondition
) 
{
  # Re-use the fragment here
  ...page_dbdata @arguments(pRefresh: $pRefresh, condition: $condition, 
    conditionyear: $conditionyear 
    conditionVenituriIncasariyear: $conditionVenituriIncasariyear
    conditionVenituriIncasariyeardesc: $conditionVenituriIncasariyeardesc
    conditioncashflow: $conditioncashflow) 
}
`

