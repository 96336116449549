/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnAddmngmentoperationInput = {|
  clientMutationId?: ?string,
  pDate: any,
  pHouseregisterprevious: number,
  pHouseregistercurrent: number,
  pMonthkindofid: number,
|};
export type mutationAddMutationVariables = {|
  input: FnAddmngmentoperationInput
|};
export type mutationAddMutationResponse = {|
  +fnAddmngmentoperation: ?{|
    +mngmentoperation: ?{|
      +date: any,
      +houseregistercurrent: any,
      +houseregisterprevious: any,
      +mngmentoperationid: any,
      +monthkindofid: number,
    |}
  |}
|};
export type mutationAddMutation = {|
  variables: mutationAddMutationVariables,
  response: mutationAddMutationResponse,
|};
*/


/*
mutation mutationAddMutation(
  $input: FnAddmngmentoperationInput!
) {
  fnAddmngmentoperation(input: $input) {
    mngmentoperation {
      date
      houseregistercurrent
      houseregisterprevious
      mngmentoperationid
      monthkindofid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnAddmngmentoperationPayload",
    "kind": "LinkedField",
    "name": "fnAddmngmentoperation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperation",
        "kind": "LinkedField",
        "name": "mngmentoperation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregistercurrent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "houseregisterprevious",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monthkindofid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "31ab03985a86e25126706a7e16518968",
    "id": null,
    "metadata": {},
    "name": "mutationAddMutation",
    "operationKind": "mutation",
    "text": "mutation mutationAddMutation(\n  $input: FnAddmngmentoperationInput!\n) {\n  fnAddmngmentoperation(input: $input) {\n    mngmentoperation {\n      date\n      houseregistercurrent\n      houseregisterprevious\n      mngmentoperationid\n      monthkindofid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd19f370af193d0f25fbbf5ead6088665';

module.exports = node;
