import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddtherapisttherapypriceInput!)
{
  fnAddtherapisttherapyprice(input: $input) {
    therapisttherapyprice {
      percent
      therapistid
      therapisttherapypriceid
      therapypriceid
    }
  }
}
`
