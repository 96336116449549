import React from 'react'

export default (subcompomponent, {dbOptions, dbOptions1, dbOptions2}) => (
  subcompomponent !== undefined ? [...expanded, ...columns({dbOptions, dbOptions1, dbOptions2})] : columns({dbOptions, dbOptions1, dbOptions2})
)


const expanded =     [{
  // Make an expander cell
  Header: () => null, // No header
  id: 'expander', width: 7, // It needs an ID
  Cell: ({ row }) => (
    // Use Cell to render an expander for each row.
    // We can use the getToggleRowExpandedProps prop-getter to build the expander.
    <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>
  ), 
}]      

  const columns =  ({dbOptions, dbOptions1, dbOptions2}) => ([
    {
      Header: 'year',
      columns: [
        { id: 'selyear', Header: 'selyear', accessor: 'selyear', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions, widthautocomplete: '12.3rem', text: 'Alege anul ..'}, },
        { id: 'selmonth', Header: 'selmonth', accessor: 'selmonth', width: 15, type: '', align: 'left',
        autocomplete: {autocomplete: true, dbOptions: dbOptions1, widthautocomplete: '12.3rem', text: 'Alege luna ..'}, },
        ]
    },
    {
      Header: 'management',
      columns: [
        { id: 'date', Header: 'date', accessor: 'date', width: 15, type: 'date',},
        { id: 'houseregisterprevious', Header: 'sold ziua precedenta', accessor: 'houseregisterprevious', width: 10, type: 'number', align: 'left', },
        { id: 'houseregistercurrent', Header: 'sold ziua curenta', accessor: 'houseregistercurrent', width: 10, type: 'number', align: 'left', },
        { id: 'zndocument', Header: 'zndocument', accessor: 'zndocument', width: 10, type: 'number', align: 'left', },
        // { id: 'monthkindofid', Header: 'monthkindofid', accessor: 'monthkindofid', width: 10, type: 'number', align: 'left', },
        // { id: 'namemonthkindof', Header: 'namemonthkindof', accessor: 'namemonthkindof', width: 10, type: 'number', align: 'left', },
        // { id: 'symbolmonthkindof', Header: 'symbolmonthkindof', accessor: 'symbolmonthkindof', width: 10, type: 'number', align: 'left', },
        { id: 'namemonthkindof', Header: 'fel luna', accessor: 'namemonthkindof', width: 30, type: '',
          autocomplete: {autocomplete: true, dbOptions: dbOptions2, widthautocomplete: '12.3rem', text: 'Alege fel luna ..'},},

        { id: 'month', Header: 'month', accessor: 'month', width: 10, type: 'number', align: 'left', },
        { id: 'monthname', Header: 'luna', accessor: 'monthname', width: 10, type: 'number', align: 'left', },
        { id: 'shortmonthname', Header: 'luna', accessor: 'shortmonthname', width: 10, type: 'number', align: 'left', },
        { id: 'year', Header: 'an', accessor: 'year', width: 10, type: 'number', align: 'left', },
      ]
    },
    {
      Header: 'db info', columns: [     
      { id: 'mngmentoperationid', Header: 'mngmentoperationid', accessor: 'mngmentoperationid', width: 30, type: 'number', align: 'right',  },
      // { id: 'selyearid', Header: 'selyearid', accessor: 'selyearid', width: 30, type: 'number', align: 'right',  },
      // { id: 'selmonthid', Header: 'selmonthid', accessor: 'selmonthid', width: 30, type: 'number', align: 'right',  },
      ],
    },
])

