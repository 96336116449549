import React from 'react'
import HelpTableStyle from '../../../styles/styles/helptable'
// https://hotemoji.com/finger-pointing-down-emoji.html
// https://emojipedia.org/emoji/
// https://emojipedia.org/emoji/%F0%9F%91%89/
// https://www.emojiall.com/en/emoji/%F0%9F%91%87#:~:text=%F0%9F%91%87%20This%20is%20a%20right,%2C%20negative%2C%20and%20bad%20mood.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md

// { - LEFT CURLY BRACKET :  &#x0007B, } - RIGHT CURLY BRACKET :  &#x0007D

export default ({params: {isDeveloper}}) => 
    <div style={{background:'linear-gradient(0deg, rgba(0,172,255,1) 0%, rgba(255,254,178,1) 0%)'}}>
        <ul>
            <li style={{color:'red'}}>pagina sa poata fi accesata din terapeuti cu buton subcomponenta </li>
            <li>administrare fel incasare terapeui, campurile atribut fiind: <b>therapisttherapypriceId, therapistId, therapypriceId, percent</b> </li>
            <li>pentru campurile <u>therapistId, therapypriceId, percent</u> exista unicitate in tabela</li>
            <li>pentru actualizare date sunt posibile urmatoarele operatii: </li>
            <ul>
                <li>adauga fel operatiune sedinte noua [<b>+</b>], actualizare oricare dintre campurile <i>therapistId, therapypriceId</i>, sterge fel operatiune sedinte si refresh date </li>
                <li><b>stergerea</b> este posibila doar daca fel operatiune sedinte nu este utilizata</li>
                <li> pentru campurile <b>therapistId, therapypriceId</b> se foloseste autocomplete care se populeaza fiecare cu o lista de valori preluata din views. </li>
                <li style={{color:'red'}}>campul <b>procent</b> se completeaza implicit cu procentul terapeutului. In cazuri speciale poate fi modoficat manual fie la insert fie la update </li>
            </ul>
            <li>este posibil export in fisier xls <b>Therapisttherapyprices.xls</b> si listare in format pdf, cu recomandare ca dupa o operatie de actualizare date este necesar un refresh al datelor.</li>
            <li>click link <b>help</b> pentru a ascunde help text </li>
            { isDeveloper ?
                <>
                    <li>detalii pentru programator: react component: components.<b>admincomponents.configTherapisttherapyprices</b></li>
                    <HelpTableStyle>
                    <table className='table'>
                        <caption><b><u>table: config.therapisttherapyprices</u></b></caption>
                        <tr>
                            <th className='th'>column</th>
                            <th className='th'>datatype</th>
                            <th className='th'>uk</th>
                            <th className='th'>checks</th>
                            <th className='th'>other checks</th>
                        </tr>
                        <tr>
                            <td className='td'>therapisttherapypriceId</td>
                            <td className='td'>BIGINT</td>
                            <td className='td'>Pk</td>
                            <td className='td'></td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapistId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>therapypriceId</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>percent</td>
                            <td className='td'>INT</td>
                            <td className='td'>uk</td>
                            <td className='td'>NOT NULL</td>
                            <td className='td'>BETWEEN 0 and 100</td>
                        </tr>
                    </table>
                    <hr className='hr' />
                    <table className='table'>
                        <caption><b><u>views, functions</u></b></caption>
                        <tr>
                            <th className='th'>facility</th>
                            <th className='th'>fn</th>
                            <th className='th'>scheme</th>
                            <th className='th'>function</th>
                            <th className='th'>condition</th>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapisttherapyprices(orderBy: THERAPISTTHERAPYPRICEID_ASC, condition: $condition) (therapisttherapypriceid, therapistid, firstname, lastname, extensive, symbol, code, phone, email, cnp, percenttherapists, therapypriceid, therapyid, therapy2Id, therapytypeid, timeid, time2Id, time, price, nametherapyprice, descriptiontherapyprices, nordertherapyprices, percent)</td>
                            <td className='td'>, unde &#x0007B; condition: therapistId !== undefined ? &#x0007B; therapistId: parseInt(therapistId) &#x0007D; : &#x0007B; &#x0007D;  &#x0007D; </td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapists(orderBy: THERAPISTID_ASC) (therapistid, firstname, lastname, extensive, symbol, code)</td>
                            <td className='td'>lista de valori [therapistId]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI, autocomplete</td>
                            <td className='td'>VIEW</td>
                            <td className='td'>db, mainview</td>
                            <td className='td'>vtherapyprices(orderBy: THERAPYPRICEID_ASC) (therapypriceId, nameRotherapy)</td>
                            <td className='td'>lista de valori [therapistId]</td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>add</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_addtherapisttherapyprice(p_therapistId, p_therapypriceId, p_percent)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>upd</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_updtherapisttherapyprice(p_keyfield, p_value, p_therapistId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI</td>
                            <td className='td'>del</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_deltherapisttherapyprice(p_therapistId)</td>
                            <td className='td'></td>
                        </tr>
                        <tr>
                            <td className='td'>UI[xls]</td>
                            <td className='td'>ups</td>
                            <td className='td'>admin, main</td>
                            <td className='td'>fn_upstherapisttherapyprice(p_therapisttherapypriceId, p_therapistId, p_therapypriceId, p_percent)</td>
                            <td className='td'></td>
                        </tr>
                    </table>
                </HelpTableStyle>
                </>
            : <></>
            }
        </ul>
    </div>


