/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnDeltherapysessionInput = {|
  clientMutationId?: ?string,
  pTherapysessionid: number,
|};
export type mutationDelMutationVariables = {|
  input: FnDeltherapysessionInput
|};
export type mutationDelMutationResponse = {|
  +fnDeltherapysession: ?{|
    +therapysession: ?{|
      +calendarid: number,
      +collectionid: number,
      +date: any,
      +finalizat: boolean,
      +fromid: number,
      +patientid: number,
      +patientnotes: ?string,
      +percent: any,
      +roomid: ?number,
      +therapistid: ?number,
      +therapistnotes: ?string,
      +therapy2Id: ?number,
      +therapyid: number,
      +therapypriceid: number,
      +therapysessionid: any,
      +therapytypeid: number,
      +toid: number,
      +value: any,
    |}
  |}
|};
export type mutationDelMutation = {|
  variables: mutationDelMutationVariables,
  response: mutationDelMutationResponse,
|};
*/


/*
mutation mutationDelMutation(
  $input: FnDeltherapysessionInput!
) {
  fnDeltherapysession(input: $input) {
    therapysession {
      calendarid
      collectionid
      date
      finalizat
      fromid
      patientid
      patientnotes
      percent
      roomid
      therapistid
      therapistnotes
      therapy2Id
      therapyid
      therapypriceid
      therapysessionid
      therapytypeid
      toid
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnDeltherapysessionPayload",
    "kind": "LinkedField",
    "name": "fnDeltherapysession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Therapysession",
        "kind": "LinkedField",
        "name": "therapysession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calendarid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientnotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roomid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapistnotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapy2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapyid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapypriceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapysessionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "therapytypeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationDelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bfb1efb1d3c419af682c92f10c517ceb",
    "id": null,
    "metadata": {},
    "name": "mutationDelMutation",
    "operationKind": "mutation",
    "text": "mutation mutationDelMutation(\n  $input: FnDeltherapysessionInput!\n) {\n  fnDeltherapysession(input: $input) {\n    therapysession {\n      calendarid\n      collectionid\n      date\n      finalizat\n      fromid\n      patientid\n      patientnotes\n      percent\n      roomid\n      therapistid\n      therapistnotes\n      therapy2Id\n      therapyid\n      therapypriceid\n      therapysessionid\n      therapytypeid\n      toid\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac9161abc4ed592a45e25bf4014e78a4';

module.exports = node;
