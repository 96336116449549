import {backgrdStyle, transbox} from '../../../styles/styles/StandardLayoutStyle'

import React from 'react'

// import backgrd from '../../../static/backgrd/oscar3-backgrd.jpg'


export default ({dbdata, params}) => {
    
    return(
        <div style={backgrdStyle}>
            <div style={transbox}>
                <h3 style={{margin: '1.875rem'}}>Amos component page</h3>
            </div>
        </div>
    )
}
