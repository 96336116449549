import {commitMutation} from 'react-relay'
import environment from './environment'

export default async (dbkey, mutation, input, onCompleted, onError) =>  
  // Now we just call commitMutation with the appropriate parameters
  await new Promise((resolve, reject) => {
    // console.log(`[mutation] - input: ${JSON.stringify(input)}`)
    return (commitMutation(
      environment(dbkey),
      {
        mutation,
        variables: input,
        onCompleted: (response, errors) => {
          // console.log(`[mutation.onCompleted]- input: ${JSON.stringify(input)}, 
          //   response: ${JSON.stringify(response)}, 
          //     errors: ${JSON.stringify(errors)}`)          
          if(errors) console.log(`[mutation.onCompleted]- errors: ${JSON.stringify(errors)}`)

          if (response) resolve(response)
          if(onCompleted !== undefined) onCompleted(response, errors) 
        },
        onError: err => {
          console.error("ERR", err)
          // console.log(`[mutation.onError]- input: ${JSON.stringify(input)}, err: ${JSON.stringify(err)}`)
          
          reject(err)
          if(onError !== undefined) onError(err)
        },
      }
    )
  )}
)


