/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FnUpsmngmentoperationdetailInput = {|
  clientMutationId?: ?string,
  pMngmentoperationdetailid: number,
  pMngmentoperationid: number,
  pOperatiune: string,
  pNorder: number,
  pOperationcodeid: number,
  pCollectionid: number,
  pNdocument: string,
  pDescription: string,
  pValue: number,
  pDebit: number,
  pCredit: number,
|};
export type mutationUpsMutationVariables = {|
  input: FnUpsmngmentoperationdetailInput
|};
export type mutationUpsMutationResponse = {|
  +fnUpsmngmentoperationdetail: ?{|
    +mngmentoperationdetail: ?{|
      +collectionid: number,
      +description: ?string,
      +mngmentoperationdetailid: any,
      +mngmentoperationid: number,
      +ndocument: string,
      +operatiune: ?string,
      +norder: number,
      +operationcodeid: number,
      +value: any,
      +debit: ?any,
      +credit: ?any,
      +businessid: ?number,
    |}
  |}
|};
export type mutationUpsMutation = {|
  variables: mutationUpsMutationVariables,
  response: mutationUpsMutationResponse,
|};
*/


/*
mutation mutationUpsMutation(
  $input: FnUpsmngmentoperationdetailInput!
) {
  fnUpsmngmentoperationdetail(input: $input) {
    mngmentoperationdetail {
      collectionid
      description
      mngmentoperationdetailid
      mngmentoperationid
      ndocument
      operatiune
      norder
      operationcodeid
      value
      debit
      credit
      businessid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FnUpsmngmentoperationdetailPayload",
    "kind": "LinkedField",
    "name": "fnUpsmngmentoperationdetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Mngmentoperationdetail",
        "kind": "LinkedField",
        "name": "mngmentoperationdetail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationdetailid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mngmentoperationid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ndocument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatiune",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "norder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operationcodeid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationUpsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ee22b80e25df1ae7bad85366f016770",
    "id": null,
    "metadata": {},
    "name": "mutationUpsMutation",
    "operationKind": "mutation",
    "text": "mutation mutationUpsMutation(\n  $input: FnUpsmngmentoperationdetailInput!\n) {\n  fnUpsmngmentoperationdetail(input: $input) {\n    mngmentoperationdetail {\n      collectionid\n      description\n      mngmentoperationdetailid\n      mngmentoperationid\n      ndocument\n      operatiune\n      norder\n      operationcodeid\n      value\n      debit\n      credit\n      businessid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8de4339629912334c8049c0960e021b1';

module.exports = node;
