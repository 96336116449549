import React from 'react'
import Help from './help'
import QueryComponent from '../../../routes/layouts/QueryComponent'
import Component from './page'
import enumRols from '../../../enums/rols'

export default ({params, events}) => {
    const {dbkey, toggleHelp, utilizatorOscar: {utilizatormodelat: {rols}}, } = params
    const isDeveloper = rols !== undefined ? rols.filter(item => parseInt(item.rolid) === enumRols.Developer.key).length > 0 : false

    // console.log(`[entitiesdiagram.component] - {utilizatormodelat: {utilizatorid, rols}: ${JSON.stringify({utilizatormodelat: {utilizatorid, rols})}`)
    // console.log(`[entitiesdiagram.component] - utilizatorid: ${utilizatorid} isadminApp: ${JSON.stringify(isadminApp)},  rols: ${JSON.stringify(rols)}`)

    return(
    <>
        { toggleHelp ? <Help params={{isDeveloper: isDeveloper}} /> : <></> }
        <>
            <QueryComponent dbkey={dbkey} >
                { props => <Component dbdata={props} params = {params} events={events} / > }
            </QueryComponent>
        </>
    </>)
    }
        
