import graphql from 'babel-plugin-relay/macro'

export default graphql`
mutation mutationAddMutation($input: FnAddcollectionInput!)
{
  fnAddcollection(input: $input) {
    collection {
      collectionid
      nameEn
      nameRo
      symbol
    }
  }
}
`
