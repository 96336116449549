import styled from 'styled-components'

export default styled.div`
  padding: 0.5em;
  display: grid;
  grid-template-columns: repeat(21, 1fr) 1fr;
`
export const CompanyColumnStyle = styled.footer`
  grid-column: 1 / 6;
`
  export const DBColumnStyle = styled.footer`
  grid-column: 6 / 15;
`
  export const UsermodelatColumnStyle = styled.footer`
  grid-column: 15 / 17;
`
  export const VersionColumnStyle = styled.footer`
  grid-column: 17 / 19;
`
  export const DateColumnStyle = styled.footer`
  grid-column: 19 / 21;
`
